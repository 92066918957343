import { LinearProgress, Stack, Typography } from "@mui/material";
import { IChecklistItem } from "../../../../models/ticketChecklist";

interface IChecklistProgressProps {
  ticketChecklistItems: IChecklistItem[];
}

const ChecklistProgress = ({
  ticketChecklistItems,
}: IChecklistProgressProps) => {
  const itemsChecked = ticketChecklistItems.filter((item) => item.isChecked);
  const percentage = (itemsChecked.length / ticketChecklistItems.length) * 100;

  return (
    <>
      {ticketChecklistItems.length !== 0 && (
        <Stack flexDirection={"row"} alignItems={"center"} gap={2} mb={1}>
          <Typography
            variant="body2"
            sx={(t) => ({ color: t.palette.text.primary })}
          >
            {percentage.toFixed(0)}%
          </Typography>
          <LinearProgress
            variant="determinate"
            value={percentage}
            sx={{ width: "100%", height: 5, borderRadius: 2 }}
          />
        </Stack>
      )}
    </>
  );
};

export default ChecklistProgress;
