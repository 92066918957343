import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { IContact } from "../../../../models/ticketDialogContacts";
import { FC, memo } from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailIcon from "@mui/icons-material/Mail";
import LinkIcon from "@mui/icons-material/Link";
import { useTranslation } from "react-i18next";
import SharedContactItemProperty from "./SharedContactItemProperty";

const translationPath = "components.sharedContacts.";

interface SharedContactItemProps {
  contact: IContact;
}

const SharedContactItem: FC<SharedContactItemProps> = ({ contact }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={(t) => ({
        p: 1,
        boxShadow: 2,
        backgroundColor:
          t.palette.mode === "light"
            ? t.palette.grey["100"]
            : t.palette.grey["900"],
        borderRadius: 1,
      })}
    >
      <Grid direction={"row"}>
        <Grid item xs={12}>
          <SharedContactItemProperty
            propertyName={t(translationPath + "formatted_name")}
            propertyValue={contact.name.formatted_name}
          />
          <SharedContactItemProperty
            propertyName={t(translationPath + "first_name")}
            propertyValue={contact.name.first_name}
          />
          {contact.name.middle_name && (
            <SharedContactItemProperty
              propertyName={t(translationPath + "last_name")}
              propertyValue={contact.name.middle_name}
            />
          )}
          {contact.name.last_name && (
            <SharedContactItemProperty
              propertyName={t(translationPath + "last_name")}
              propertyValue={contact.name.last_name}
            />
          )}
          {contact.name.prefix && (
            <SharedContactItemProperty
              propertyName={t(translationPath + "prefix_name")}
              propertyValue={contact.name.prefix}
            />
          )}
          {contact.name.suffix && (
            <SharedContactItemProperty
              propertyName={t(translationPath + "sufix_name")}
              propertyValue={contact.name.suffix}
            />
          )}
        </Grid>
        

        <Grid item xs={12}>
          {contact.phones && (
            <>
              <Divider style={{ marginBlock: 10 }} />
              {contact.phones.map((phone) => (
                <SharedContactItemProperty
                  propertyName={t(
                    translationPath + (phone.wa_id ? "whatsapp" : "phone")
                  )}
                  propertyValue={phone.phone}
                  icon={phone.wa_id ? <WhatsAppIcon /> : <LocalPhoneIcon />}
                />
              ))}
            </>
          )}

          {contact.emails && (
            <>
              <Divider style={{ marginBlock: 10 }} />
              {contact.emails.map((email) => (
                <SharedContactItemProperty
                  propertyName={t(translationPath + "email")}
                  propertyValue={email.email}
                  icon={<MailIcon />}
                />
              ))}
            </>
          )}

          {contact.addresses && (
            <>
              <Divider style={{ marginBlock: 10 }} />
              {contact.addresses.map((address, index) => (
                <Stack>
                  <Typography variant="h6">
                    <LocationOnIcon /> {t(translationPath + "address")}{" "}
                    {index + 1}
                  </Typography>
                  {address.type && (
                    <SharedContactItemProperty
                      propertyName={t(translationPath + "address_type")}
                      propertyValue={address.type}
                    />
                  )}
                  {address.zip && (
                    <SharedContactItemProperty
                      propertyName={t(translationPath + "zip_code")}
                      propertyValue={address.zip}
                    />
                  )}
                  {address.street && (
                    <SharedContactItemProperty
                      propertyName={t(translationPath + "street")}
                      propertyValue={address.street}
                    />
                  )}
                  {address.city && (
                    <SharedContactItemProperty
                      propertyName={t(translationPath + "city")}
                      propertyValue={address.city}
                    />
                  )}
                  {address.state && (
                    <SharedContactItemProperty
                      propertyName={t(translationPath + "state")}
                      propertyValue={address.state}
                    />
                  )}
                  {address.country && (
                    <SharedContactItemProperty
                      propertyName={t(translationPath + "country")}
                      propertyValue={address.country}
                    />
                  )}
                </Stack>
              ))}
            </>
          )}

          {contact.urls && (
            <>
              <Divider style={{ marginBlock: 10 }} />
              {contact.urls.map((url) => (
                <SharedContactItemProperty
                  propertyName={t(translationPath + "url")}
                  propertyValue={url.url}
                  icon={<LinkIcon />}
                />
              ))}
            </>
          )}

          {contact.birthday && (
            <>
              <SharedContactItemProperty
                propertyName={t(translationPath + "birthday")}
                propertyValue={contact.birthday}
              />
              <Divider style={{ marginBlock: 10 }} />
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(SharedContactItem);
