import { Stack, Typography } from "@mui/material";
import { useFetch } from "@4uhub/lib4uhub";

import { ITicketDialogMediaFile } from "../../../models/ticketDialogs";
import { getFileUrl } from "../../../services/fileManager.service";

const FileMessage: React.FC<ITicketDialogMediaFile & { me: boolean }> = ({
  fileName,
  fileSize,
  filePath,
  mimeType,
  me,
}) => {
  const isPdf = mimeType === "application/pdf";
  const { sendRequest } = useFetch(getFileUrl);

  const handleDownload = async () => {
    const { data, success } = await sendRequest(filePath);
    if (data && success) {
      const url = data;
      window.open(url, "_blank");
    }
  };

  const fileSizeInKB = fileSize / 1000;

  return (
    <Stack
      flexDirection="row"
      gap={1}
      padding={1}
      alignItems={"center"}
      pr={3}
      sx={(t) => ({
        border: `1px solid ${
          me ? t.palette.primary.contrastText : t.palette.divider
        }`,
        borderRadius: 1,
        "&:hover": {
          cursor: "pointer",
          opacity: 0.8,
        },
      })}
      onClick={handleDownload}
    >
      {isPdf && (
        <img
          src="/documents/pdf.png"
          width={30}
          style={{ objectFit: "contain" }}
          alt="pdf"
        />
      )}
      {!isPdf && (
        <img
          src="/documents/text.png"
          width={30}
          style={{ objectFit: "contain" }}
          alt="generic document"
        />
      )}
      <Stack>
        <Typography
          color={(t) =>
            me ? t.palette.primary.contrastText : t.palette.text.primary
          }
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            wordBreak: 'break-all',
            WebkitBoxOrient: "vertical",
          }}
        >
          {fileName}
        </Typography>
        <Typography
          variant="caption"
          color={(t) =>
            me ? t.palette.primary.contrastText : t.palette.text.primary
          }
        >
          {fileSizeInKB < 1000
            ? fileSizeInKB.toFixed(2) + " KB"
            : (fileSizeInKB / 1000).toFixed(2) + " MB"}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default FileMessage;
