import { Box, Stack } from "@mui/material";
import { memo } from "react";

import CardChecklistContainer from "./Checklist/CardChecklistContainer";
import useBoardRole from "../../../hooks/useBoardRole";
import useTicket from "../../../hooks/useTask";
import CreateChecklistAction from "../CreateChecklistAction/CreateChecklistAction";
import ChecklistsProvider from "../../../store/context/ChecklistContext/checklist-context";

interface ICardChecklistList {}

const CardChecklistList: React.FC<ICardChecklistList> = () => {
  const {
    isUserTicketMember,
    ticket: { id },
  } = useTicket();

  const permission = useBoardRole("2");

  return (
    <ChecklistsProvider ticketId={id}>
      <Box margin={2} height={"calc(100% - 32px)"} overflow={"hidden"}>
        <Stack
          flexDirection={"column"}
          gap={4}
          pr={2}
          overflow={"auto"}
          height={"100%"}
          sx={(t) => ({
            "&::-webkit-scrollbar": {
              width: "0.4em",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              backgroundColor:
                t.palette.mode === "light"
                  ? t.palette.grey[400]
                  : t.palette.grey[900],
              borderRadius: 5,
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor:
                t.palette.mode === "light"
                  ? t.palette.grey[200]
                  : t.palette.grey[700],
              borderRadius: 5,
            },
          })}
        >
          {permission && isUserTicketMember && (
            <CreateChecklistAction id={id} />
          )}

          <CardChecklistContainer />
        </Stack>
      </Box>
    </ChecklistsProvider>
  );
};

export default memo(CardChecklistList);
