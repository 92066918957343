import {
  Box,
  MenuItem,
  Popover,
  PopoverProps,
  Typography,
} from "@mui/material";
import { memo, useCallback } from "react";
import { useFetch } from "@4uhub/lib4uhub";

import { ISelectType } from "../../../../models/selects";
import Status from "./Status";
import { editTicketStatus } from "../../../../services/ticket.service";
import { useTranslation } from "react-i18next";

interface ICardStatusSelectorProps extends Omit<PopoverProps, "onChange"> {
  onChange: (v: string) => void;
  data: ISelectType[];
  ticketId: string;
  selected: string;
}

const CardStatusSelector: React.FC<ICardStatusSelectorProps> = ({
  onChange,
  data,
  ticketId,
  onClose,
  id,
  open,
  anchorEl,
  selected,
}) => {
  const { sendRequest } = useFetch(editTicketStatus);

  const { t } = useTranslation();

  const handleChange = useCallback(
    (value: string) => {
      sendRequest({
        statusId: value,
        id: ticketId,
      });

      onChange(value);
    },
    [ticketId, sendRequest, onChange]
  );

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Box p={1}>
        <Typography variant="subtitle1">
          {t("page.card.selectStatus")}
        </Typography>
        {data.map((d) => (
          <MenuItem
            value={d.id}
            key={d.id}
            selected={d.id === selected}
            onClick={() => handleChange(d.id)}
          >
            <Status data={d} />
          </MenuItem>
        ))}
      </Box>
    </Popover>
  );
};

export default memo(CardStatusSelector);
