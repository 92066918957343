import { Dispatch, SetStateAction, useCallback } from "react";

import { IGetColumn } from "../../../../../models/columns";
import { IGetTicket } from "../../../../../models/ticket";
import { IArchivedColumn } from "../../../../../store/context/board-context";
import { SignalRContext } from "../../../../SignalR/SignalRProvider";

interface IuseSignalRProps {
  setArquivedColumns: Dispatch<SetStateAction<IArchivedColumn[]>>;
  setArchivedTasks: Dispatch<SetStateAction<IGetTicket[]>>;
}

const useSignalRArchivedItems = ({
  setArchivedTasks,
  setArquivedColumns,
}: IuseSignalRProps) => {
  const disableColumn = useCallback(
    (columnId: string) => {
      setArquivedColumns((old) => old.filter((o) => o.id !== columnId));
    },
    [setArquivedColumns]
  );

  const handleDelete = useCallback((id: string) => {
    setArchivedTasks((at) => {
      const newTasks = at.filter((t) => t.id !== id);
      return newTasks;
    });
  }, [setArchivedTasks]);

  const deleteFromList = useCallback(
    (id: string) => {
      setArquivedColumns((c) => c.filter((c) => c.id !== id));
    },
    [setArquivedColumns]
  );

  //Card é arquivado
  SignalRContext.useSignalREffect(
    "ArchiveTicket", // Your Event Key
    (userId: string, data: IGetTicket) => {
      console.log("ArchiveTicket", data);
      setArchivedTasks((old) => [...old, data]);
    },
    [setArchivedTasks]
  );
  //

  SignalRContext.useSignalREffect(
    "UnarchiveTicket", // Your Event Key
    (userId: string, data) => {
      console.log("UnarchiveTicket",data)
      setArchivedTasks((at) => {
        const newTasks = at.filter((t) => t.id !== data.id);
        return newTasks;
      });
    },
    [setArchivedTasks]
  );

  // Fechar ticket
  SignalRContext.useSignalREffect(
    "DisableTicket", // Your Event Key
    (userId: string, data: { id: string }) => {
      console.log("DisableTicket", data);
      handleDelete(data.id);
    },
    [handleDelete]
  );

  SignalRContext.useSignalREffect(
    "UnarchiveTicketChannelStep", // Your Event Key
    (userId: string, data: IGetColumn) => {
      console.log("UnarchiveTicketChannelStep", data);
      deleteFromList(data.id);
    },
    [deleteFromList]
  );

  // Ticket channel step é arquivado
  SignalRContext.useSignalREffect(
    "ArchiveTicketChannelStep", // Your Event Key
    (userId: string, data: IArchivedColumn) => {
      console.log("ArchiveTicketChannelStep", data);
      setArquivedColumns((old) => [...old, data]);
    },
    [setArquivedColumns]
  );
  //

  // Ticket channel step é desabilitado
  SignalRContext.useSignalREffect(
    "DisabledTicketChannelStep", // Your Event Key
    (userId: string, data: { id: string }) => {
      disableColumn(data.id);
    },
    [disableColumn]
  );
  //
};

export default useSignalRArchivedItems;
