import { GlobalAxios } from "../axios/axios-config";
import { ITag, ITicketTag } from "../models/tags";

const TAG_TICKET_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketChannelTagTicket/";

export class TicketChannelTagService {
  create = (item: ITicketTag) => {
    return GlobalAxios.post(TAG_TICKET_ROUTE, item);
  };

  getItemById = (id: string) => {
    return GlobalAxios.get<ITag[]>(TAG_TICKET_ROUTE + id);
  };

  deleteItemById = ({ id, item }: { id: string; item: ITicketTag }) => {
    return GlobalAxios.delete<ITicketTag>(TAG_TICKET_ROUTE + id, {
      data: item,
    });
  };
}

export default TicketChannelTagService;
