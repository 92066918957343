import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@4uhub/lib4uhub";
import {
  IconButton,
  Popover,
  PopoverVirtualElement,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

interface IPopoverDeleteProps {
  id?: string;
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  anchorEl?:
    | Element
    | (() => Element)
    | PopoverVirtualElement
    | (() => PopoverVirtualElement)
    | null
    | undefined;
  title: string;
  description: string;
  buttonDescription: string;
  secureDelete?: boolean;
  secureReferenceText?: string;
  secureLabel?: string;
  loading?: boolean;
}

const PopoverDelete: React.FC<IPopoverDeleteProps> = ({
  onClose,
  open,
  anchorEl,
  id,
  title,
  description,
  onDelete,
  buttonDescription,
  secureDelete = false,
  secureReferenceText,
  secureLabel,
  loading,
}) => {
  const [name, setName] = useState("");
  const [disable, setDisable] = useState(secureDelete);

  useEffect(() => {
    if (secureReferenceText) {
      if (name === secureReferenceText) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    }
  }, [name, secureReferenceText]);

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{ mt: 1 }}
    >
      <Stack
        p={2}
        style={{
          maxWidth: 300,
        }}
      >
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography fontWeight={"bold"}>{title}</Typography>
          <IconButton size="small" onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Stack>
        <Stack mb={1}>
          <Typography>{description}</Typography>
        </Stack>

        {secureDelete && (
          <Stack mb={1}>
            <Typography variant="caption">{secureLabel}</Typography>
            <TextField
              variant="outlined"
              size="small"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Stack>
        )}

        <Stack>
          <Button
            variant="contained"
            color="error"
            loading={loading}
            onClick={onDelete}
            disabled={disable}
          >
            {buttonDescription}
          </Button>
        </Stack>
      </Stack>
    </Popover>
  );
};

export default PopoverDelete;
