import { GlobalAxios } from "../axios/axios-config";
import { INewTicketHistory, ITicketHistory } from "../models/card-histories";

const BASE_URL = process.env.REACT_APP_TICKETS + "/api/v1/TicketHistory";

export const addHistoryComment = (item: INewTicketHistory) => {
  return GlobalAxios.post<ITicketHistory>(BASE_URL, item);
};

export const loadHistories = ({
  ticketId,
  detail,
}: {
  ticketId: string;
  detail?: boolean;
}) => {
  return GlobalAxios.get<ITicketHistory[]>(BASE_URL + "/Ticket", {
    params: { id: ticketId, detail },
  });
};
