import {
  AutoComplete,
  Form,
  FormInput,
  useFetch,
  Button,
} from "@4uhub/lib4uhub";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { InputAdornment, Stack } from "@mui/material";
import { z } from "zod";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { getGenericList } from "../../../../services/generic.service";
import {
  editAnswer,
  postAnswer,
} from "../../../../services/quickReplay.service";
import { useWorkspaceContext } from "../../../../store/context/workspace-context";
import { useQuickAnswer } from "../../../../store/context/quickAnswers-context";

import EmojiPickerComponent, { Emoji } from "../../EmojiPicker";

const shareType = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: "O tipo de compartilhamento é obrigatório" }
);

const QuickReplaySchema = z.object({
  title: z.string().min(1, "O título é obrigatório"),
  message: z.string().min(1, "A mensagem é obrigatória"),
  shortCut: z.string().min(3, "O atalho precisa ter no mínimo 3 caracteres"),
  shareType: shareType,
});

export type TQuickReplayType = z.infer<typeof QuickReplaySchema>;

const QuickReplayForm = () => {
  const { back, canGoBack, answer } = useQuickAnswer();

  const { t } = useTranslation();

  const [cursorPosition, setCursorPosition] = useState<number>(0);

  const { actualWorkspace } = useWorkspaceContext();

  const methods = useForm<TQuickReplayType>({
    resolver: zodResolver(QuickReplaySchema),
  });

  const { reset, setValue, getValues } = methods;

  const isEdit = !!answer;

  const { loading, sendRequest } = useFetch(postAnswer);

  const { loading: editLoading, sendRequest: editRequest } =
    useFetch(editAnswer);

  const ref = useRef<HTMLInputElement>();

  const edit = useCallback(
    async (data: TQuickReplayType) => {
      if (!actualWorkspace || !answer) return;

      const { success } = await editRequest({
        data: {
          ...data,
          shareTypeId: data.shareType.id,
          ticketWorkspaceId: actualWorkspace?.id,
          id: answer.id,
        },
        id: answer?.id,
      });

      if (success) {
        back();
      }
    },
    [actualWorkspace, answer, back, editRequest]
  );

  const create = useCallback(
    async (data: TQuickReplayType) => {
      if (!actualWorkspace) return;

      const { success } = await sendRequest({
        ...data,
        shareTypeId: data.shareType.id,
        ticketWorkspaceId: actualWorkspace?.id,
      });

      if (success) {
        back();
      }
    },
    [actualWorkspace, back, sendRequest]
  );

  const handleSubmit = (data: TQuickReplayType) => {
    if (isEdit) {
      edit(data);
    } else {
      create(data);
    }
  };

  useEffect(() => {
    if (answer) {
      reset(answer);
    }
  }, [answer, reset]);

  const handleEmojiOpen = () => {
    ref.current?.focus();
  };

  const handleEmojiClick = (emoji: Emoji) => {
    const rf = ref.current;
    const message = getValues("message");
    if (rf) {
      rf.focus();
      if (rf.selectionStart) {
        const start = message.substring(0, rf.selectionStart);
        const end = message.substring(rf.selectionStart);
        const text = start + emoji.native + end;
        setValue("message", text);

        setCursorPosition(start.length + emoji.native.length);
      } else {
        setValue("message", message + emoji.native);
      }
    }
  };

  useEffect(() => {
    if (ref && ref.current && cursorPosition) {
      ref.current.selectionEnd = cursorPosition;
    }
  }, [cursorPosition, ref]);

  return (
    <Form {...methods}>
      <Stack>
        <Stack gap={3} overflow={"auto"} py={1}>
          <FormInput
            name="title"
            size="small"
            label={t("components.chat.quickReplay.form.title")}
            autoFocus
          />

          <Stack flexDirection={"row"} alignItems={"flex-start"} gap={1}>
            <FormInput
              multiline
              name="message"
              size="small"
              label={t("components.chat.quickReplay.form.message")}
              inputProps={{
                style: { minHeight: 100 },
              }}
              inputRef={ref}
              style={{ width: "90%" }}
            />
            <EmojiPickerComponent
              onEmojiClick={handleEmojiClick}
              onEmojiOpen={handleEmojiOpen}
            />
          </Stack>

          <FormInput
            name="shortCut"
            size="small"
            label={t("components.chat.quickReplay.form.shortCut")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">/</InputAdornment>
              ),
            }}
          />

          <AutoComplete
            params={{ identifier: "shareType" }}
            getOptionLabel={(option) => {
              return option.name || option.value || "";
            }}
            size="small"
            label={t("components.chat.quickReplay.form.shareType")}
            name="shareType"
            request={getGenericList}
          />
        </Stack>

        <Stack
          gap={1}
          direction={"row"}
          justifyContent={"end"}
          p={1}
          borderTop={(t) => `1px solid ${t.palette.divider}`}
        >
          {canGoBack && (
            <Button onClick={back} variant="outlined">
              {t("components.chat.quickReplay.form.cancel")}
            </Button>
          )}
          <Button
            variant="contained"
            onClick={methods.handleSubmit(handleSubmit)}
            loading={loading || editLoading}
          >
            {t(
              isEdit
                ? "components.chat.quickReplay.form.edit"
                : "components.chat.quickReplay.form.create"
            )}
          </Button>
        </Stack>
      </Stack>
    </Form>
  );
};

export default memo(QuickReplayForm);
