export const convertRgbToHex = (rgb: string): string => {
  const rgbRegex = /^rgb\s*\((\d+),\s*(\d+),\s*(\d+)\)$/;
  const match = rgb.match(rgbRegex);

  if (!match) {
    return "";
  }

  let [, r, g, b] = match.map(Number);

  r = Math.min(255, Math.max(0, r));
  g = Math.min(255, Math.max(0, g));
  b = Math.min(255, Math.max(0, b));

  const hex = `#${r.toString(16).padStart(2, "0")}${g
    .toString(16)
    .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;

  return hex.toUpperCase();
};

export const hexToRgbA = (hex: string, opacity: number) => {
  let c: string;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("").join("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]].join("");
    }
    c = "0x" + c;
    return (
      "rgba(" +
      [
        (parseInt(c) >> 16) & 255,
        (parseInt(c) >> 8) & 255,
        parseInt(c) & 255,
      ].join(",") +
      `,${opacity})`
    );
  }
  throw new Error("Bad Hex");
};
