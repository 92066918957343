import { motion } from "framer-motion";
import useEvents from "../../../hooks/useEvents";
import { EventsList } from "../EventsList/EventsList";
import { Event } from "../Event/Event";
import { memo } from "react";

export const EventsContent = memo(() => {
  const { mode } = useEvents();

  return (
    <motion.div
      key={mode}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, ease: [0.6, -0.05, 0.01, 0.99] }}
      style={{ height: "100%" }}
    >
      {mode === "list" && <EventsList />}

      {mode !== "list" && <Event />}
    </motion.div>
  );
});
