import {
  AutoComplete,
  Button,
  FormCheckBox,
  FormInput,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";
import { memo, useCallback, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useWatch } from "react-hook-form";

import { TCardForm } from "./AddCardSchema";
import { getTicketServiceListByComapnyGroup } from "../../../../../services/ticketService.service";
import { ITicketService } from "../../../../../models/ticketService";
import TypeSelector from "./TypeSelector";
import RequestSelector from "./RequesterSelector";
import ClientSelector from "./ClientSelector/ClientSelector";
import PhoneInput from "./PhoneInput";
import OriginSelector from "./OriginSelector";
import ListSelector from "./ListSelector";
interface IAddCardFormProps {
  onSubmit: (data: TCardForm) => void;
  isTable?: boolean;
  loading: boolean;
}

const AddCardForm: React.FC<IAddCardFormProps> = ({
  onSubmit,
  isTable = false,
  loading,
}) => {
  const {
    formState: { isValid, errors },
    handleSubmit,
    setValue,
    control,
    setValue: setInputValue,
    resetField,
  } = useFormContext<TCardForm>();

  const client = useWatch({
    name: "client",
    control,
  });

  const clientType = useWatch({
    name: "clientType",
    control,
  });

  const [serviceList, setServiceList] = useState<ITicketService[]>([]);

  const { sendRequest } = useFetch(getTicketServiceListByComapnyGroup);

  const fetchServiceList = useCallback(async () => {
    const { data } = await sendRequest(null);
    if (data) {
      setServiceList(data);
      if (data.length === 1) {
        setValue("ticketService", data[0]);
      }
    }
  }, [sendRequest, setValue]);

  useEffect(() => {
    fetchServiceList();
  }, [fetchServiceList]);

  const handleSubmitForm = (data: TCardForm) => {
    onSubmit(data);
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (clientType === "pf") {
      setInputValue("informRequest", false);
      if (client) {
        setInputValue("requester", client);
      } else {
        resetField("requester");
      }
    } else {
      setInputValue("informRequest", true);
      resetField("requester");
    }
  }, [clientType, client, setInputValue, resetField]);

  return (
    <Grid container rowGap={2}>
      <TypeSelector />

      {isTable && <ListSelector />}

      <ClientSelector />

      {clientType !== "none" && (
        <>
          <Grid item xs={12} sm={12}>
            <FormCheckBox
              size="small"
              name="informRequest"
              label={`${t("page.board.createCard.inform_request")}`}
              disabled={clientType === "pj"}
            />
          </Grid>

          <RequestSelector />
        </>
      )}

      {clientType === "none" && (
        <>
          <Grid item xs={12} sm={12}>
            <FormInput
              name={"notIdentifiedName"}
              size="small"
              label={t("page.board.createCard.not_identified_name")}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormInput
              name={"notIdenfiedPhone"}
              size="small"
              label={t("page.board.createCard.not_identified_phone")}
              fullWidth
              mask={"(00)000000000"}
            />
          </Grid>
        </>
      )}

      <Grid item xs={12} sm={12}>
        <FormInput
          name={"title"}
          size="small"
          label={t("page.board.createCard.title")}
          fullWidth
        />
      </Grid>

      <PhoneInput />

      {serviceList.length > 1 && (
        <Grid item xs={12}>
          <AutoComplete
            getOptionLabel={(option) => {
              return option.name || option.value || "";
            }}
            name="ticketService"
            label={t("page.board.createCard.service")}
            size="small"
            options={serviceList}
            fullWidth
          />
        </Grid>
      )}

      <OriginSelector />

      {serviceList.length === 0 && (
        <Typography color={(t) => t.palette.error.main}>
          {t("page.board.createCard.noService")}
        </Typography>
      )}

      <Typography>{errors.phone?.message}</Typography>

      <Grid item xs={12} sm={12}>
        <Button
          variant="contained"
          size="medium"
          fullWidth
          onClick={handleSubmit(handleSubmitForm)}
          disabled={!isValid}
          loading={loading}
        >
          {t("page.board.createCard.create")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default memo(AddCardForm);
