import { GlobalAxios } from "../axios/axios-config";
import { IGetTicketDialog, ISendTicketDialog } from "../models/ticketDialogs";

const TICKET_DIALOG = process.env.REACT_APP_TICKETS + "/api/v1/TicketDialogs";

export const sendMessage = (message: ISendTicketDialog) => {
  return GlobalAxios.post<IGetTicketDialog>(TICKET_DIALOG + "/Send", message);
};

export const getMessages = (ticketId: string) => {
  return GlobalAxios.get<IGetTicketDialog[]>(
    TICKET_DIALOG + `/Ticket/${ticketId}`
  );
};

export const readMessages = (ticketId: string) => {
  return GlobalAxios.put<{ id: string }>(TICKET_DIALOG + `/${ticketId}`, {
    ticketId,
  });
};
