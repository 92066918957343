import { Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { memo } from "react";

import { formatTime } from "../../Kamban/Task/TaskHeader";

interface IDateTimeProps {
  created: string;
  name?: string;
}

const DateTime: React.FC<IDateTimeProps> = ({ created, name }) => {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <Grid item>
      <Stack
        width={"fit-content"}
        flexDirection={"column"}
        columnGap={1}
        alignItems={name ? "end" : "start"}
      >
        <Typography variant="caption">
          {formatTime(created, language)}
        </Typography>
        <Typography variant="caption" color={(t) => t.palette.grey[600]}>
          {new Date(created).toLocaleDateString(language)}
        </Typography>
      </Stack>
    </Grid>
  );
};

export default memo(DateTime);
