import { useForm } from "react-hook-form";
import { Box, Stack, Typography } from "@mui/material";
import { Button, DateTime, Form, FormInput, useFetch } from "@4uhub/lib4uhub";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import TicketChecklistItemService from "../../../../services/ticketChecklistItem.service";
import useChecklists from "../../../../hooks/useChecklists";

const ticketChecklistItemService = new TicketChecklistItemService();

const translationPath = "components.checklistAddItemForm.";

const checklistItemSchema = z.object({
  description: z.string().min(1, translationPath + "errors.description"),
  forecastStartDate: z.date().nullable(),
  estimatedTime: z
    .string()
    .optional()
    .nullable()
    .refine(
      (val) => {
        if (Number(val) < 0 || val === "0") {
          return false;
        } else {
          return true;
        }
      },
      { message: translationPath + "errors.estimatedTime" }
    ),
  startDate: z.date().nullable().optional(),
  endDate: z.date().nullable().optional(),
});

export type TChecklistItem = z.infer<typeof checklistItemSchema>;

const CardChecklistAddItemForm = ({
  ticketChecklistId,
  onCloseHandler,
}: {
  ticketChecklistId: string;
  onCloseHandler: () => void;
}) => {
  const { t } = useTranslation();

  const { checklistItems } = useChecklists();

  const { sendRequest } = useFetch(
    ticketChecklistItemService.createChecklistItem
  );

  const methods = useForm<TChecklistItem>({
    resolver: zodResolver(checklistItemSchema),
  });

  const addItemHandler = useCallback(
    async (form: TChecklistItem) => {
      const { data, success } = await sendRequest({
        description: form.description,
        displayOrder:
          checklistItems.length === 0
            ? 1
            : checklistItems[checklistItems.length - 1].displayOrder + 1,
        isChecked: false,
        ticketChecklistId: ticketChecklistId,
        forecastStartDate: form.forecastStartDate
          ? form.forecastStartDate.toISOString()
          : null,
        estimatedTime: form.estimatedTime ? +form.estimatedTime : null,
        startDate: null,
        endDate: null,
      });
      if (data && success) {
        methods.reset();
        onCloseHandler();
      }
    },
    [sendRequest, ticketChecklistId, checklistItems, methods, onCloseHandler]
  );

  const submitHandler = useCallback(
    (form: TChecklistItem) => {
      addItemHandler(form);
    },
    [addItemHandler]
  );

  return (
    <Form {...methods}>
      <Box
        component="form"
        onSubmit={methods.handleSubmit(submitHandler, (err) =>
          console.log(err)
        )}
      >
        <Stack p={1} minWidth={250} gap={1}>
          <Typography mb={1}>{t(translationPath + "title")}</Typography>

          <FormInput
            autoFocus
            name="description"
            label={t(translationPath + "description")}
            size="small"
            fullWidth
          />

          <DateTime
            name="forecastStartDate"
            label={t(translationPath + "forecastStartDate")}
          />

          <FormInput
            name="estimatedTime"
            label={t(translationPath + "estimatedTime")}
            size="small"
            type="number"
            autoComplete="off"
          />

          <Button variant="contained" size="small" type="submit">
            {t(translationPath + "button")}
          </Button>
        </Stack>
      </Box>
    </Form>
  );
};

export default CardChecklistAddItemForm;
