import RemoveIcon from "@mui/icons-material/Remove";
import { useFetch, useRole } from "@4uhub/lib4uhub";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import MenuItem from "../MenuItem";

import PopoverDelete from "../../../UI/PopoverDelete";
import { closeBoardById } from "../../../../services/board.service";
import { useWorkspaceContext } from "../../../../store/context/workspace-context";
import usePopover from "../../../../hooks/usePopover";
import useBoardRole from "../../../../hooks/useBoardRole";

const CloseBoard = () => {
  const { anchorEl, id, handleClick, handleClose, open } = usePopover(
    "close-board-popover"
  );
  const { sendRequest, loading } = useFetch(closeBoardById);

  const navigate = useNavigate();

  const permission = useBoardRole("2")

  const updatePermission = useRole(["Update"]);

  const { t } = useTranslation();

  const { actualBoard } = useWorkspaceContext();

  const onClose = async () => {
    if (!actualBoard) return;
    const { success } = await sendRequest(actualBoard.id);
    if (success) {
      handleClose();
      navigate("..", { replace: true });
    }
  };

  return (
    <>
      {(updatePermission || permission ) && (
        <MenuItem
          onClick={handleClick}
          title={t("components.boards.settingsMenu.closeBoard")}
          icon={<RemoveIcon fontSize="small" color="primary" />}
        />
      )}

      {open && (
        <PopoverDelete
          title={t("components.boards.settingsMenu.closeBoard")}
          description={t(
            "components.boards.settingsMenu.closeBoardDescription"
          )}
          buttonDescription={t("components.boards.settingsMenu.closeBoard")}
          onDelete={onClose}
          onClose={handleClose}
          open={open}
          anchorEl={anchorEl}
          id={id}
          loading={loading}
        />
      )}
    </>
  );
};

export default CloseBoard;
