import parse from "html-react-parser";
import sanitizeHtml from 'sanitize-html';

const boldRegex = /\*([^ ].*?)\*/g;
const italicRegex = /_([^ ].*?)_/g;
const tachadoRegex = /~([^ ].*?)~/g;
const monospaceRegex = /```([^ ].*?)```/g;
const codeRegex = /`([^ ].*?)`/g;
const quoteRegex = /(?:\n|^)> (.*)/gm;

export const FormatMessage = (msg: string) => {
  const bold = msg.replace(boldRegex, "<strong>$1</strong>");
  const italic = bold.replace(italicRegex, "<em>$1</em>");
  const tachado = italic.replace(tachadoRegex, "<s>$1</s>");
  const monospace = tachado.replace(
    monospaceRegex,
    '<span style="font-family:monospace">$1</span>'
  );

  const code = monospace.replace(codeRegex, "<code>$1</code>");

  const quote = code.replace(
    quoteRegex,
    `<blockquote style="display:inline">$1</blockquote>`
  );

  const lista = detectarEConverterListasMarkdownParaHTML(quote);
  const listaOl = detectarEConverterListasOrdenadasMarkdownParaHTML(lista);

  const sanitized = sanitizeHtml(listaOl,{disallowedTagsMode:'escape'})

  return parse(sanitized);
};

function detectarEConverterListasMarkdownParaHTML(texto: string) {
  const padrao = /^[*-] .+?$/gm;
  const linhas = texto.split("\n");

  let dentroDaLista = false;
  let resultadoFinal = "";

  linhas.forEach((linha) => {
    if (linha.match(padrao)) {
      if (!dentroDaLista) {
        resultadoFinal += '<ul style="margin:0px">';
        dentroDaLista = true;
      }

      const textoItem = linha.substring(2).trim();
      resultadoFinal += `<li>${textoItem}</li>`;
    } else if (dentroDaLista) {
      resultadoFinal += "</ul> \n";
      dentroDaLista = false;
    } else {
      resultadoFinal += linha + "\n";
    }
  });

  if (dentroDaLista) {
    resultadoFinal += "</ul>";
  }

  return resultadoFinal;
}

function detectarEConverterListasOrdenadasMarkdownParaHTML(texto: string) {
  const padrao = /^\d+\. .+?$/gm;
  const linhas = texto.split("\n");

  let dentroDaLista = false;
  let resultadoFinal = "";

  linhas.forEach((linha) => {
    if (linha.match(padrao)) {
      if (!dentroDaLista) {
        resultadoFinal += "<ol style='margin:0px'>";
        dentroDaLista = true;
      }

      const indicePonto = linha.indexOf(".");
      const textoItem = linha.substring(indicePonto + 2).trim();
      resultadoFinal += `<li>${textoItem}</li>`;
    } else if (dentroDaLista) {
      resultadoFinal += "</ol> \n";
      dentroDaLista = false;
    } else {
      resultadoFinal += linha + "\n";
    }
  });

  if (dentroDaLista) {
    resultadoFinal += "</ol>";
  }

  return resultadoFinal;
}
