import { z } from "zod";

const typeSchema = z.object({
  id: z.string().optional(),
  name: z.string().optional().nullable(),
  code: z.string().optional().nullable(),
});

const requesterAndClientSchema = z
  .object({
    id: z.string(),
    individualName: z.string().optional().nullable(),
    document: z.string().optional().nullable(),
  })
  .or(
    z.object({
      corporateName: z.string().optional().nullable(),
      id: z.string(),
      document: z.string().optional().nullable(),
    })
  );

const ticketServiceSchema = z.object(
  {
    id: z.string(),
    name: z.string().optional().nullable(),
  },
  {
    required_error: "page.board.createCard.form.service",
    invalid_type_error: "page.board.createCard.form.service",
  }
);

const ticketOriginSchema = z.object(
  {
    id: z.string(),
    name: z.string().optional().nullable(),
    code: z.string().optional().nullable(),
  },
  {
    required_error: "page.board.createCard.form.origin",
    invalid_type_error: "page.board.createCard.form.origin",
  }
);

const listSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

const phoneRegex = /\(\d{2}\) \d{5}-\d{4}$/;

export const cardSchema = z
  .object({
    title: z.string().min(1, "page.board.createCard.form.title"),
    type: typeSchema,
    isTable: z.boolean().optional(),
    requester: requesterAndClientSchema.optional().nullable(),
    ticketOrigin: ticketOriginSchema,
    client: requesterAndClientSchema.optional().nullable(),
    list: listSchema.optional().nullable(),
    clientType: z.string(),
    phone: z.string().optional(),
    ticketService: ticketServiceSchema,
    informRequest: z.boolean(),
    notIdentifiedName: z.string().optional(),
    notIdenfiedPhone: z.string().optional(),
  })
  .superRefine((val, ctx) => {
    if (val.clientType === "pf" || val.clientType === "pj") {
      if (!val.requester) {
        ctx.addIssue({
          code: "custom",
          path: ["requester"],
          message: "page.board.createCard.form.requester",
        });
      }
      if (!val.client) {
        ctx.addIssue({
          code: "custom",
          path: ["client"],
          message: "page.board.createCard.form.client",
        });
      }
    }
    if (val.phone && !phoneRegex.test(val.phone)) {
      ctx.addIssue({
        code: "custom",
        path: ["phone"],
        message: "page.board.createCard.form.phoneValid",
      });
    }
    if (val.isTable && !val.list) {
      ctx.addIssue({
        code: "custom",
        path: ["list"],
        message: "page.board.createCard.form.listRequired",
      });
    }
    if (val.type.code === "1" && !val.phone) {
      ctx.addIssue({
        code: "custom",
        path: ["phone"],
        message: "page.board.createCard.form.phoneRequired",
      });
    }
  });

export type TCardForm = z.infer<typeof cardSchema>;
