import { z } from "zod";

export const filterSchema = z
  .object({
    keyword: z.string(),
    membersIds: z.array(z.string()).default([]),
    createdBy: z.array(z.string()).default([]),
    initialDate: z.string().nullable(),
    endDate: z.string().nullable(),
    status: z.string().nullable(),
  })
  .superRefine((val, ctx) => {
    if ((val.status === "3" || val.status === "4") && !val.endDate) {
      ctx.addIssue({
        code: "custom",
        path: ["endDate"],
        message: "components.boards.filters.invalid_endDate",
      });
    }
    if ((val.status === "3" || val.status === "4") && !val.initialDate) {
      ctx.addIssue({
        code: "custom",
        path: ["initialDate"],
        message: "components.boards.filters.invalid_initialDate",
      });
    }
  });
export type TFilterSchema = z.infer<typeof filterSchema>;
