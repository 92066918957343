import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ChatIcon from "@mui/icons-material/Chat";
import { SvgIconProps, Tooltip } from "@mui/material";
import { ISelectType } from "@4uhub/lib4uhub";
import { memo } from "react";

interface IServiceTypeIcon {
  type?: ISelectType;
  iconProps: SvgIconProps;
}

const ServiceTypeIcon: React.FC<IServiceTypeIcon> = ({ type, iconProps }) => {
  let content = <></>;
  switch (type?.code) {
    case "1": {
      content = <WhatsAppIcon {...iconProps} color="action"/>;
      break;
    }
    default: {
      content = <ChatIcon {...iconProps} color="action"/>;
      break;
    }
  }

  return <Tooltip title={type?.name}>{content}</Tooltip>;
};

export default memo(ServiceTypeIcon);
