import { Typography } from "@mui/material";
import { IDetailFileDescriptionProps } from "./models";
import { motion } from "framer-motion";
import { memo } from "react";

const DetailFileDescription = ({
  description,
}: IDetailFileDescriptionProps) => {
  const descriptionParts = description.split("\n");

  return (
    <motion.div
      style={{
        paddingInline: 10,
        height: "100%",
        width: "100%",
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, ease: [0.6, -0.05, 0.01, 0.99] }}
    >
      {descriptionParts.map((part, index) => (
        <Typography
          variant="subtitle2"
          sx={(t) => ({
            color:
              t.palette.mode === "light"
                ? t.palette.grey[600]
                : t.palette.grey[400],
          })}
          key={index}
        >
          {part.length === 0 ? "\u00A0" : part}
        </Typography>
      ))}
    </motion.div>
  );
};

export default memo(DetailFileDescription);
