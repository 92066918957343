import { IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import PopoverDelete from "../../../../UI/PopoverDelete";
import { useTranslation } from "react-i18next";
import usePopover from "../../../../../hooks/usePopover";

interface IDeleteColumnProps {
  onDelete: () => void;
  loading: boolean;
  reference: string;
}

const DeleteColumn: React.FC<IDeleteColumnProps> = ({
  onDelete,
  loading,
  reference,
}) => {
  const { anchorEl, id, handleClick, handleClose, open } = usePopover(
    "delete-column-popover"
  );

  const { t } = useTranslation();

  const handleDelete = () => {
    onDelete();
    handleClose();
  };

  return (
    <>
      <Tooltip title={t("components.boards.settingsMenu.archived.remove")}>
        <IconButton onClick={handleClick}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      {open && (
        <PopoverDelete
          title={t(
            "components.boards.settingsMenu.archived.secureRemove.title",
            { reference }
          )}
          description={t(
            "components.boards.settingsMenu.archived.secureRemove.description"
          )}
          buttonDescription={t(
            "components.boards.settingsMenu.archived.secureRemove.button"
          )}
          onDelete={handleDelete}
          onClose={handleClose}
          open={open}
          anchorEl={anchorEl}
          id={id}
          secureDelete
          secureLabel={
            t(
              "components.boards.settingsMenu.archived.secureRemove.secureLabel"
            ) + ""
          }
          secureReferenceText={reference}
          loading={loading}
        />
      )}
    </>
  );
};

export default DeleteColumn;
