import { stringToColor } from "@4uhub/lib4uhub";
import { Avatar } from "@mui/material";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";

const ProfileImage = ({ fullName }: { fullName?: string }) => {
  const nameInitials = fullName
    ? fullName.charAt(0) + fullName.charAt(fullName?.lastIndexOf(" ") + 1)
    : "";

  const avatarBackgroundColor = stringToColor(fullName || "");

  return (
    <Avatar
      sx={(theme) => ({
        height: 30,
        width: 30,
        fontSize: 14,
        bgcolor: avatarBackgroundColor,
        color: theme.palette.getContrastText(avatarBackgroundColor),
        alignItems: "center",
        justifyContent: "center",
      })}
    >
      {fullName ? nameInitials : <PersonRoundedIcon />}
    </Avatar>
  );
};

export default ProfileImage;
