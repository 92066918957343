import { memo, useCallback, useEffect, useState } from "react";
import { ISelectType, useFetch } from "@4uhub/lib4uhub";
import { useAdvancedSearchContext } from "../../../store/context/advanced-search-context";
import { workspaceMembers } from "../../../services/workspace.service";
import SelectMembers from "../../UI/SelectMembers";
import { useTranslation } from "react-i18next";

const translationPath = "components.advancedSearchFields.";

const SearchFilterCreatedBy = () => {
  const { t } = useTranslation();

  const { workspace, createdBy, onChangeCreatedBy } =
    useAdvancedSearchContext();

  const [members, setMembers] = useState<ISelectType[]>([]);

  const { sendRequest } = useFetch(workspaceMembers);

  const fetchMembers = useCallback(async () => {
    if (!workspace) {
      setMembers([]);
      return;
    }
    const systemUser = {
      id: "00000000-0000-0000-0000-000000000000",
      name: t("components.boards.filters.openedBySystem"),
    };
    const { data, success } = await sendRequest(workspace.id);
    if (data && success) {
      setMembers([
        systemUser,
        ...data.map((member) => ({
          id: member.user.id,
          name: member.user.fullName,
        })),
      ]);
    }
  }, [sendRequest, t, workspace]);

  useEffect(() => {
    fetchMembers();
  }, [fetchMembers]);

  return (
    <SelectMembers<ISelectType>
      value={createdBy || []}
      onChange={onChangeCreatedBy}
      getOptionLabel={(option) => option.name}
      options={members}
      showPlaceholder={false}
      marginTop={false}
      label={`${t(translationPath + "created_by")}`}
    />
  );
};

export default memo(SearchFilterCreatedBy);
