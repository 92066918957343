import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { useFormContext } from "@4uhub/lib4uhub";

import DateInput from "./DateInput";
import DateTypeSelector from "./DateTypeSelector";
import { TFilterSchema } from "../filterSchema";

const DateFilters = () => {
  const { watch } = useFormContext<TFilterSchema>();

  const { t } = useTranslation();

  const status = watch("status");

  const showInputs = useMemo(() => status === "3" || status === "4", [status]);

  return (
    <Stack width={"100%"} gap={1}>
      <DateTypeSelector />

      {showInputs && (
        <Typography variant="subtitle2">
          {t("components.boards.filters.date")}
        </Typography>
      )}
      {showInputs && (
        <DateInput
          label={t("components.boards.filters.initialDate")}
          name="initialDate"
        />
      )}

      {showInputs && (
        <DateInput
          label={t("components.boards.filters.endDate")}
          name="endDate"
        />
      )}
    </Stack>
  );
};

export default DateFilters;
