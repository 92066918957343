import { Box, Typography } from "@mui/material";

import { memo } from "react";
import { IResultCard } from "./models";

const SearchResultCard = ({
  title,
  description,
  active = false,
  onClick,
}: IResultCard) => {
  return (
    <Box
      sx={(t) => ({
        p: 1,
        cursor: onClick && !active ? "pointer" : undefined,
        boxShadow: active ? 2 : 0,
        backgroundColor:
          t.palette.mode === "light"
            ? t.palette.grey["100"]
            : t.palette.grey["900"],
        borderRadius: 1,
        "&:hover":
          onClick && !active
            ? {
                backgroundColor:
                  t.palette.mode === "light"
                    ? t.palette.grey["200"]
                    : t.palette.grey["800"],
              }
            : undefined,
      })}
      onClick={onClick}
    >
      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box>
          <Typography variant="subtitle2" fontWeight={"bold"}>
            {title}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={(t) => ({
              color:
                t.palette.mode === "light"
                  ? t.palette.grey[600]
                  : t.palette.grey[400],
            })}
          >
            {description}
          </Typography>
        </Box>
        {active && (
          <Box
            sx={(t) => ({
              width: 10,
              height: 10,
              backgroundColor: t.palette.primary.light,
              borderRadius: 5,
            })}
          />
        )}
      </Box>
    </Box>
  );
};

export default memo(SearchResultCard);
