import {
  Box,
  Drawer,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { memo } from "react";
import { IStatusDrawerProps } from "./models";
import CloseIcon from "@mui/icons-material/Close";
import { StatusData } from "./StatusData/StatusData";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { useTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";

const translationPath = "components.statusDrawer.";

export const StatusDrawer = memo(
  ({
    open,
    dateSend,
    deliveryDate,
    readDate,
    deliveryReason,
    onClose,
  }: IStatusDrawerProps) => {
    const { t } = useTranslation();

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("sm"));

    return (
      <Drawer
        open={open}
        onClose={onClose}
        anchor={matches ? "bottom" : "right"}
      >
        <Box
          sx={{
            py: 1,
            px: 2,
            mb: matches ? 5 : 0,
            width: matches ? "100%" : 300,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              mb: 2,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>{t(translationPath + "message_status")}</Typography>
            <IconButton sx={{ p: 0, m: 0 }} size="small" onClick={onClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          {!deliveryReason && (
            <>
              <StatusData
                label={t(translationPath + "sent")}
                data={dateSend}
                icon={<CheckIcon fontSize="small" color="action" />}
              />
              <StatusData
                label={t(translationPath + "delivered")}
                data={deliveryDate}
                icon={<DoneAllIcon fontSize="small" color="action" />}
              />
              <StatusData
                label={t(translationPath + "read")}
                data={readDate}
                icon={<DoneAllIcon fontSize="small" color="info" />}
              />
            </>
          )}
          {deliveryReason && (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <ErrorIcon color="error" />
                <Typography fontWeight="bold">
                  {t(translationPath + "error")}
                </Typography>
              </Box>
              <Typography
                variant="subtitle2"
                sx={(t) => ({
                  pl: 4,
                  lineHeight: 1.2,
                  color: t.palette.grey[t.palette.mode === "light" ? 600 : 400],
                })}
              >
                {deliveryReason ?? "-"}
              </Typography>
            </Box>
          )}
        </Box>
      </Drawer>
    );
  }
);
