import { Box, Typography } from "@mui/material";
import { useAdvancedSearchContext } from "../../store/context/advanced-search-context";
import SearchResultCard from "./SearchResultCards";
import SearchResultsListContainer from "./SearchResultsListContainer";
import { useTranslation } from "react-i18next";
import ViewMoreButton from "./ViewMoreButton";
import { memo } from "react";

const translationPath = "components.searchResultsList.";

const SearchResultsList = () => {
  const { t } = useTranslation();

  const { searchResult } = useAdvancedSearchContext();

  if (!searchResult || searchResult.length === 0) return null;

  return (
    <SearchResultsListContainer>
      <>
        <Typography
          variant="h6"
          fontWeight={"bold"}
          sx={(t) => ({
            color:
              t.palette.mode === "light"
                ? t.palette.grey["800"]
                : t.palette.grey["600"],
          })}
        >
          {t(translationPath + "search_results")}
        </Typography>
        <Box mt={2} display={"flex"} flexDirection={"column"} gap={1}>
          {searchResult.map((result) => (
            <SearchResultCard data={result} key={result.id} />
          ))}
        </Box>
        <ViewMoreButton />
      </>
    </SearchResultsListContainer>
  );
};

export default memo(SearchResultsList);
