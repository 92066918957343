import { Stack } from "@mui/material";
import { memo, useMemo, useRef, useState } from "react";
import { Draggable } from "react-beautiful-dnd";

import { IChecklistItem } from "../../../../models/ticketChecklist";
import ChecklistItemDelete from "./ChecklistItemDelete";
import ChecklistItemName from "./ChecklistItemName";
import ChecklistItemCheck from "./ChecklistItemCheck";
import CardChecklistItemTime from "./Time/CardChecklistItemTime";
import useTicket from "../../../../hooks/useTask";

interface ICardChecklistItem {
  checklistItem: IChecklistItem;
  checklistId: string;
  hideCheckedItem: boolean;
  index: number;
}

const CardChecklistItem = ({
  checklistItem,
  checklistId,
  index,
  hideCheckedItem,
}: ICardChecklistItem) => {
  const [hover, setHover] = useState(false);

  const {
    ticket: { status },
  } = useTicket();

  const [inputFocus, setInputFocus] = useState(false);

  const checklistItemContainer = useRef<HTMLDivElement | null>(null);

  const actualHeight = useMemo(
    () => checklistItemContainer.current?.offsetHeight ?? 0,
    []
  );

  const mouseEnterHandler = () => setHover(true);

  const mouseLeaveHandler = () => setHover(false);

  const largeTitle = useMemo(() => actualHeight > 40, [actualHeight]);

  return (
    <Stack
      key={checklistItem.id}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
    >
      <Draggable
        draggableId={checklistItem.id}
        index={index}
        isDragDisabled={status.code === "3"}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <Stack
              minHeight={35}
              flexDirection={"row"}
              alignItems={largeTitle ? "start" : "center"}
              ref={checklistItemContainer}
              sx={(t) => ({
                display:
                  hideCheckedItem && checklistItem.isChecked
                    ? "none"
                    : undefined,
                backgroundColor: t.palette.background.default,
                boxShadow: snapshot.isDragging
                  ? "0px 0px 11px -1px rgba(0,0,0,0.35)"
                  : undefined,
                borderRadius: snapshot.isDragging ? 1 : 0,
              })}
            >
              <ChecklistItemCheck
                checklistId={checklistId}
                isChecked={checklistItem.isChecked}
                actualHeight={actualHeight}
                checklistItem={checklistItem}
              />
              <Stack
                width={"100%"}
                minHeight={35}
                flexDirection={"row"}
                alignItems={largeTitle ? "start" : "center"}
                justifyContent={"space-between"}
                sx={(t) => ({
                  paddingLeft: 1,
                  "&:hover":
                    status.code === "3"
                      ? undefined
                      : {
                          backgroundColor: t.palette.divider,
                          borderRadius: 1,
                        },
                  ...(inputFocus && {
                    backgroundColor: t.palette.divider,
                    borderRadius: 1,
                  }),
                })}
              >
                <ChecklistItemName
                  checklistId={checklistId}
                  checklistItem={checklistItem}
                  onBlur={() => setInputFocus(false)}
                  onFocus={(e) => {
                    setInputFocus(true);
                    e.currentTarget.setSelectionRange(
                      e.currentTarget.value.length,
                      e.currentTarget.value.length
                    );
                  }}
                />
                {hover && !inputFocus && status.code !== "3" && (
                  <Stack flexDirection={"row"} mt={largeTitle ? 1 : 0}>
                    <CardChecklistItemTime checklistItem={checklistItem} />
                    <ChecklistItemDelete
                      checklistItemId={checklistItem.id}
                      checklistId={checklistId}
                    />
                  </Stack>
                )}
              </Stack>
            </Stack>
          </div>
        )}
      </Draggable>
    </Stack>
  );
};

export default memo(CardChecklistItem);
