import { useConfig } from "@4uhub/lib4uhub";
import SettingsIcon from "@mui/icons-material/Settings";
import { IconButton, Tooltip } from "@mui/material";
import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getEstToken } from "../../../../services/establishment.service";

const isLocalHost = window.location.hostname === "localhost";

export const SettingsButton = memo(() => {
  const { eI } = useParams();

  const { t } = useTranslation();

  const APP_4UHUB_LINK = isLocalHost
    ? "http://localhost:3006"
    : process.env.REACT_APP_4UHUB;

  const { establishments } = useConfig();

  const actualEstablishment = useMemo(
    () => establishments.find((e) => e.tenantSequential === Number(eI)),
    [establishments, eI]
  );

  const clickHandler = () => {
    window.open(
      `${APP_4UHUB_LINK}/login/?token=${getEstToken()?.token}&establishment=${
        actualEstablishment?.id
      }&code=${eI}&app=dashboard/settings`
    );
  };

  return (
    <Tooltip title={t("components.settings")} placement="right">
      <IconButton color="inherit" onClick={clickHandler}>
        <SettingsIcon />
      </IconButton>
    </Tooltip>
  );
});
