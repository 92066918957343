import { GlobalAxios } from "../axios/axios-config";

const WHATSAPP_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Whatsapp";

export interface IPhones {
  type: string;
  value: string;
}

interface IWhatsappNumber {
  individualId: string | null;
  legalEntityId: string | null;
  value: "";
  phones: IPhones[];
}

export const getWhatsappNumber = ({
  individualId,
  legalEntityId,
}: {
  individualId?: string;
  legalEntityId?: string;
}) => {
  return GlobalAxios.get<IWhatsappNumber>(WHATSAPP_ROUTE, {
    params: { IndividualId: individualId, LegalEntityId: legalEntityId },
  });
};
