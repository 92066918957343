import { Box, Button, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { IStartChatProps } from "./models";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import useTicket from "../../../../hooks/useTask";

const translationPath = "page.card.chat.noMessages.startChat.";

export const StartChat = ({ onNextStep }: IStartChatProps) => {
  const { t } = useTranslation();

  const { isUserTicketMember } = useTicket();

  const clickHandler = () => {
    onNextStep(1);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, ease: [0.6, -0.05, 0.01, 0.99] }}
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={(t) => ({
          p: 2,
          height: "50%",
          width: "50%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          backgroundColor: t.palette.background.default,
          borderRadius: 2,
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ marginBottom: 5, maxWidth: 200 }}
            src="/whatsapp-inline-logo.png"
            alt="Logo WhatsApp"
          />

          <Typography fontWeight="bold" variant="h6">
            {t(translationPath + "title")}
          </Typography>

          <Typography
            sx={(t) => ({
              color: t.palette.grey[t.palette.mode === "light" ? 600 : 400],
            })}
          >
            {t(translationPath + "subtitle")}
          </Typography>
        </Box>

        <Button
          variant="contained"
          endIcon={<ArrowForwardIcon />}
          sx={{ mt: 2 }}
          onClick={clickHandler}
          disabled={!isUserTicketMember}
        >
          {t(translationPath + "button")}
        </Button>
      </Box>
    </motion.div>
  );
};
