import { useContext } from "react";
import { MessageInputContext } from "../store/context/messageInput-context";


const useMessageInput = () => {
  const board = useContext(MessageInputContext);

  return board;
};

export default useMessageInput;
