import { z } from "zod";

const boardSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

const listSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

const workspaceSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export const moveSchema = z.object({
  board: boardSchema,
  list: listSchema.optional().nullable(),
  workspace: workspaceSchema.optional().nullable(),
});

export type TMoveSchema = z.infer<typeof moveSchema>;
