import { IconButton, Stack } from "@mui/material";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import StartIcon from "@mui/icons-material/Start";
import { useTranslation } from "react-i18next";

import NumberContainer from "./NumberContainer";
import ColumMenu from "./Menu/ColumnMenu";
import ColumnTitle from "./ColumnTitle";
import { memo, useMemo } from "react";

interface IColumHeaderProps {
  ColumnOpen: boolean;
  onColumToggle: () => any;
  id: string;
  columnHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  isView?: boolean;
  tasksLength?: number;
  contrastColor?: string | null;
}

const ColumnHeader: React.FC<IColumHeaderProps> = ({
  ColumnOpen,
  onColumToggle,
  id,
  columnHandleProps,
  isView,
  tasksLength,
  contrastColor,
}) => {
  const { t } = useTranslation();

  const showNumber = useMemo(
    () => !!tasksLength && (tasksLength > 0),
    [tasksLength]
  );

  return (
      <Stack
        {...columnHandleProps}
        mb={2}
        alignItems={"center"}
        direction={ColumnOpen ? "row" : "column-reverse"}
        justifyContent={"space-between"}
      >
        <Stack
          direction={ColumnOpen ? "row" : "column-reverse"}
          width={"90%"}
          gap={1}
          alignItems={"center"}
        >
          <ColumnTitle ColumnOpen={ColumnOpen} />

          {showNumber && <NumberContainer>{tasksLength!}</NumberContainer>}
          {ColumnOpen && <ColumMenu id={id} isView={isView} />}
        </Stack>

        <IconButton
          aria-label={
            ColumnOpen
              ? t("components.column.minimize") + ""
              : t("components.column.maximize") + ""
          }
          sx={{
            width: "10%",
          }}
          size="small"
          onClick={onColumToggle}
        >
          <StartIcon
            fontSize="inherit"
            sx={(t) => ({
              transform: ColumnOpen ? "rotate(180deg)" : "rotate(0deg)",
              color: contrastColor || t.palette.primary.main,
            })}
          />
        </IconButton>
      </Stack>
  );
};

export default memo(ColumnHeader);
