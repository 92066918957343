import qs from "qs";
import { GlobalAxios } from "../axios/axios-config";
import {
  IAdvancedSearchParams,
  IAdvancedSearchResult,
} from "../models/advanced-search";
import { ISearchReturn } from "@4uhub/lib4uhub/dist/types/models/requests";

const BASE_URL = process.env.REACT_APP_TICKETS;

const ADVANCED_SEARCH_ROUTE = BASE_URL + "/api/v1/Ticket/search";

export const search = (params?: IAdvancedSearchParams) => {
  return GlobalAxios.get<ISearchReturn<IAdvancedSearchResult>>(
    ADVANCED_SEARCH_ROUTE,
    {
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
    }
  );
};
