import { Button } from "@4uhub/lib4uhub";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { INoMessagesActionsProps } from "./models";

const translationPath = "page.card.chat.noMessages.noMessagesActions.";

export const NoMessagesActions = memo(
  ({ loading, restartChat, onCancel }: INoMessagesActionsProps) => {
    const { t } = useTranslation();

    return (
      <Box
        sx={{
          mt: 1,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "end",
          gap: 1,
        }}
      >
        <Button
          variant="outlined"
          onClick={onCancel}
          endIcon={<ArrowBackIcon />}
          disabled={loading}
        >
          {t(translationPath + "back")}
        </Button>
        <Button
          type="submit"
          variant="contained"
          endIcon={<ArrowForwardIcon />}
          disabled={loading}
          loading={loading}
        >
          {restartChat
            ? t(translationPath + "restart")
            : t(translationPath + "next")}
        </Button>
      </Box>
    );
  }
);
