import { useTranslation } from "react-i18next";
import { Popover, Stack, Typography } from "@mui/material";
import { Button, Form } from "@4uhub/lib4uhub";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { memo, useEffect } from "react";

import usePopover from "../../../../hooks/usePopover";
import GeneralFilter from "./GeneralFilter";
import FiltersButton from "./FiltersButton";
import MembersFilter from "./Members/MembersFilters";
import DateFilters from "./Date/DateFilters";
import HistoryFilters from "./HistoryFilter/HistoryFilters";
import { TFilterSchema, filterSchema } from "./filterSchema";
import useBoard from "../../../../hooks/useBoard";

const FiltersPopover = () => {
  const { anchorEl, handleClick, handleClose, id, open } =
    usePopover("filters-popover");

  const { setFilter, filter } = useBoard();

  const methods = useForm<TFilterSchema>({
    resolver: zodResolver(filterSchema),
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
      methods.reset(filter);
    }
  }, [open, methods, filter]);

  const applyFilter = (values: TFilterSchema) => {
    setFilter(values);
    methods.reset(values);
    handleClose();
  };

  return (
    <>
      <FiltersButton onClick={handleClick} />
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Form {...methods}>
          <Stack p={3} width={320} gap={1}>
            <Typography variant="h6" mb={1}>
              {t("components.boards.filters.filters")}
            </Typography>
            <GeneralFilter />
            <MembersFilter />
            <MembersFilter isCreatedBy={true} />
            <DateFilters />
            <HistoryFilters />
            <Button
              variant="contained"
              onClick={methods.handleSubmit(applyFilter)}
              disabled={
                !methods.formState.isValid || !methods.formState.isDirty
              }
            >
              {t("components.boards.filters.apply")}
            </Button>
          </Stack>
        </Form>
      </Popover>
    </>
  );
};

export default memo(FiltersPopover);
