import { ISelectType } from "@4uhub/lib4uhub";
import { Typography } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";

import useGeneric from "../../../../hooks/useGeneric";
import usePopover from "../../../../hooks/usePopover";
import HoverOpacity from "../../../UI/HoverOpacity";
import Property from "../../Property";
import CardOriginSelector from "./CustomerServiceTypeSelector";

interface ICustomerServiceTypeProps {
  ticketCustomerServiceType: ISelectType;
  id: string;
  loading?: boolean;
  onEditCustomerServiceType: ((requester: ISelectType) => void) | undefined;
}

const CustomerServiceType: React.FC<ICustomerServiceTypeProps> = ({
  id,
  ticketCustomerServiceType,
  loading,
}) => {
  const { t } = useTranslation();

  const {
    anchorEl,
    handleClick,
    handleClose,
    id: popOverId,
    open,
  } = usePopover("ticket-customer-service-type-popover");

  const { data } = useGeneric("TicketCustomerServiceType");

  const handleChange = () => {
    handleClose();
  };

  return (
    <>
      <HoverOpacity onClick={handleClick} disabled={true}>
        <Property
          label={t("page.card.customerServiceType")}
          loading={loading}
          content={
            <Typography color={(t) => t.palette.text.primary} fontSize={14}>
              {ticketCustomerServiceType.name}
            </Typography>
          }
        />
      </HoverOpacity>
      <CardOriginSelector
        data={data}
        selected={ticketCustomerServiceType.id}
        ticketId={id}
        anchorEl={anchorEl}
        id={popOverId}
        open={open}
        onChange={handleChange}
        onClose={handleClose}
      />
    </>
  );
};

export default memo(CustomerServiceType);
