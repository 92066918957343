import { IconButton, Stack, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import { useFetch } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";

import Task from "../../../../Kamban/Task/Task";
import { IGetTicket } from "../../../../../models/ticket";
import {
  disableTicketById,
  unarchiveTicketById,
} from "../../../../../services/ticket.service";
import PopoverDelete from "../../../../UI/PopoverDelete";
import usePopover from "../../../../../hooks/usePopover";
import { memo } from "react";

type TArchivedTicketProps = {
  onUnarchive?: (ticket: IGetTicket) => void;
  onDelete?: (ticketId: string) => void;
  canUnarchive?: boolean;
  canDelete?: boolean;
} & IGetTicket;

const ArchivedTicket: React.FC<TArchivedTicketProps> = ({
  onUnarchive,
  onDelete,
  canDelete,
  canUnarchive,
  ...ticket
}) => {
  const { anchorEl, id, handleClick, handleClose, open } = usePopover(
    "archive-ticket--popover"
  );

  const { sendRequest } = useFetch(unarchiveTicketById);

  const { t } = useTranslation();

  const { sendRequest: disableRequest, loading } = useFetch(disableTicketById);

  const unarchiveTicket = async () => {
    const { data, success } = await sendRequest(ticket.id);

    if (data && success) {
      onUnarchive && onUnarchive(ticket);
    }
  };

  const deleteTicket = async () => {
    const { data, success } = await disableRequest(ticket.id);

    if (data && success) {
      onDelete && onDelete(ticket.id);
    }
  };

  return (
    <Stack>
      <Task {...ticket} />
      <Stack flexDirection={"row"} gap={1}>
        {canUnarchive && (
          <Tooltip title={t("page.board.settings.archived.cards.unarchive")}>
            <IconButton size="small" onClick={unarchiveTicket}>
              <UnarchiveIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}

        {canDelete && (
          <Tooltip title={t("page.board.settings.archived.cards.delete")}>
            <IconButton size="small" onClick={handleClick}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}

        {open && (
          <PopoverDelete
            title={t("page.board.settings.archived.cards.title")}
            description={t("page.board.settings.archived.cards.description")}
            buttonDescription={"Apagar"}
            onDelete={deleteTicket}
            onClose={handleClose}
            secureDelete
            secureReferenceText={ticket.title}
            secureLabel={
              t("page.board.settings.archived.cards.secureLabel") + ""
            }
            open={open}
            anchorEl={anchorEl}
            id={id}
            loading={loading}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default memo(ArchivedTicket);
