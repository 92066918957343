import {
  FormInput,
  ISelectCountry,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { usePhone } from "../../../../hooks/usePhone";
import useTicket from "../../../../hooks/useTask";
import {
  getWhatsappNumber,
  IPhones,
} from "../../../../services/whatsapp.service";
import { TWhatsappNumberForm } from "./whatsappNumberSchema";

const translationPath = "page.card.chat.noMessages.whatsappNumber.";

export const NumberField = memo(
  ({
    country,
    countries,
    whatsappNumberTexted,
    onSelectPhoneNumbers,
  }: {
    country: ISelectCountry | null;
    countries: ISelectCountry[];
    whatsappNumberTexted: string;
    onSelectPhoneNumbers: (value: IPhones[]) => void;
  }) => {
    const { t } = useTranslation();

    const [searchParams] = useSearchParams();

    const countryOptions = useMemo(() => countries, [countries]);

    const phoneOption = useMemo(
      () => searchParams.get("phoneOption"),
      [searchParams]
    );

    const paramsPhone = useMemo(
      () => searchParams.get("phone"),
      [searchParams]
    );

    const brasil = useMemo(
      () => countryOptions.find((country) => country.code === "BR"),
      [countryOptions]
    );

    const { getDDI, getPhoneNumberWithoutDDI } = usePhone(countryOptions);

    const { control, setValue } = useFormContext<TWhatsappNumberForm>();

    const selectedCountry = useWatch({
      name: "country",
      control,
    });

    const selectedPhone = useWatch({
      name: "phoneOption",
      control,
    });

    const { ticket } = useTicket();

    const { individualCustomer, legalEntityCustomer } = ticket;

    const { sendRequest } = useFetch(getWhatsappNumber);

    const getPhoneOptions = useCallback(async () => {
      const { data, success } = await sendRequest({
        individualId: individualCustomer ? individualCustomer.id : undefined,
        legalEntityId: legalEntityCustomer ? legalEntityCustomer.id : undefined,
      });

      if (data && success) {
        onSelectPhoneNumbers(data.phones);

        if (data.phones.length > 0) {
          if (phoneOption) {
            setValue("phoneOption", phoneOption);
            if (phoneOption === "1" && paramsPhone) {
              setValue(
                "phone",
                paramsPhone
                  .split("</>")[1]
                  .replaceAll("(", "")
                  .replaceAll(")", "")
              );
              const paramsCountry = getDDI(paramsPhone);
              if (paramsCountry) {
                setValue("country", paramsCountry);
              }
            }
            return;
          }
          const firstPhone = data.phones[0].value;
          const firstPhoneType = data.phones[0].type;
          const country = getDDI(firstPhone);
          const phone = getPhoneNumberWithoutDDI(firstPhone);
          setValue("phoneOption", firstPhoneType + "</>" + firstPhone);
          if (!country) return;
          setValue("country", country);
          if (!phone) return;
          setValue("phone", phone);
        }
      }
    }, [
      individualCustomer,
      legalEntityCustomer,
      phoneOption,
      paramsPhone,
      sendRequest,
      setValue,
      onSelectPhoneNumbers,
      getDDI,
      getPhoneNumberWithoutDDI,
    ]);

    const onPhoneOptionChange = useCallback(() => {
      if (!selectedPhone) return;

      if (selectedPhone === "1" && brasil) {
        if (paramsPhone) {
          const paramsCountry = getDDI(paramsPhone.replaceAll("</>", ""));
          if (paramsCountry) {
            setValue("country", paramsCountry);
          }
        } else {
          setValue("country", brasil);
        }
        if (phoneOption !== "1") {
          setValue("phone", "");
        }
        return;
      }
      const phoneWithoutType = selectedPhone.split("</>")[1];
      const country = getDDI(phoneWithoutType);
      const phone = getPhoneNumberWithoutDDI(phoneWithoutType);
      if (!country) return;
      setValue("country", country);
      if (!phone) return;
      setValue("phone", phone);
    }, [
      selectedPhone,
      brasil,
      phoneOption,
      paramsPhone,
      setValue,
      getDDI,
      getPhoneNumberWithoutDDI,
    ]);

    useEffect(() => {
      getPhoneOptions();
    }, [getPhoneOptions]);

    useEffect(() => {
      onPhoneOptionChange();
    }, [onPhoneOptionChange]);

    return (
      <Grid item xs={12} sm={8}>
        <FormInput
          label={t(translationPath + "phone")}
          name="phone"
          size="small"
          mask={selectedCountry ? selectedCountry.phoneMask : undefined}
          disabled={
            !selectedCountry || !!(selectedPhone && selectedPhone !== "1")
          }
          fullWidth
        />
      </Grid>
    );
  }
);
