import { useFetch } from "@4uhub/lib4uhub";
import { memo, useCallback } from "react";

import TicketChecklistService from "../../../../services/ticketChecklist.service";
import EditableTypography from "../../../UI/EditableTypography";

import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import useTicket from "../../../../hooks/useTask";

const ticketChecklistService = new TicketChecklistService();

interface ICardChecklistTitleProps {
  checklistId: string;
  checklistName: string;
}

const CardChecklistTitle = ({
  checklistId,
  checklistName,
}: ICardChecklistTitleProps) => {
  const { sendRequest } = useFetch(ticketChecklistService.updateChecklist);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const {
    ticket: { status },
  } = useTicket();

  const updateChecklistHandler = useCallback(
    async (id: string, name: string) => {
      if (name && name.length > 1) {
        sendRequest({
          id,
          item: { id, name },
        });
      }
    },
    [sendRequest]
  );

  return (
    <Stack flexDirection={"row"} alignItems={"center"} gap={2} width={"100%"}>
      {matches && <TaskAltIcon color="action" />}
      <EditableTypography
        textfieldProps={{
          fullWidth: true,
          sx: (t) => ({
            input: {
              color: t.palette.text.primary,
            },
          }),
        }}
        typographyProps={{
          sx: {
            width: "100%",
            writingMode: "horizontal-tb",
            textOrientation: "inherit",
          },
          color: (t) => t.palette.text.primary,
          variant: "subtitle1",
          lineHeight: 1.2,
        }}
        disabled={status.code === "3"}
        onEdit={(value) => updateChecklistHandler(checklistId, value)}
        value={checklistName}
      />
    </Stack>
  );
};

export default memo(CardChecklistTitle);
