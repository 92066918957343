import { Dispatch, SetStateAction, useCallback } from "react";
import { IGetColumn } from "../../../../models/columns";
import {
  IOnColorChangeProps,
  IOnAddCardProps,
  IOnNameChangeProps,
  IOnParentChangeProps,
  IOnRemoveCardProps,
  IOnSetTasksProps,
  IOnStatusChangeProps,
  IOnTicketOrderChangeProps,
} from "./models";

interface IUseEditColumnsProps {
  setColumns: Dispatch<SetStateAction<IGetColumn[]>>;
  setColumnOrder: Dispatch<SetStateAction<string[]>>;
}

export interface IEditColumnFunctions extends IBasicEditColumnFunctions {
  onColumnTicketOrderChange: (props: IOnTicketOrderChangeProps) => void;
  addColumn: (column: IGetColumn, position?: number | undefined) => void;
  onAddCard: (props: IOnAddCardProps) => void;
  removeColumn: (id: string) => void;
  onRemoveCard: (props: IOnRemoveCardProps) => void;
}

export interface IBasicEditColumnFunctions {
  onSetTasks: (props: IOnSetTasksProps) => void;
  onColumnColorChange: ({ color, id }: IOnColorChangeProps) => void;
  onColumnStatusChange: (props: IOnStatusChangeProps) => void;
  onParentChange: (props: IOnParentChangeProps) => void;
  onColumnNameChange: ({ name, id }: IOnNameChangeProps) => void;
}

const useEditColumns = ({
  setColumnOrder,
  setColumns,
}: IUseEditColumnsProps): IEditColumnFunctions => {
  const onColumnColorChange = useCallback(
    ({ color, id }: IOnColorChangeProps) => {
      setColumns((oldc) =>
        oldc.map((c) => {
          if (c.id === id) {
            c.backgroundColor = color;
            return c;
          }
          return c;
        })
      );
    },
    [setColumns]
  );

  const onColumnNameChange = useCallback(
    ({ name, id }: IOnNameChangeProps) => {
      setColumns((oldc) =>
        oldc.map((c) => {
          if (c.id === id) {
            c.name = name;
            return c;
          }
          return c;
        })
      );
    },
    [setColumns]
  );

  // Column actions
  const addColumn = useCallback(
    (column: IGetColumn, position?: number) => {
      setColumnOrder((old) => {
        old.splice(position || old.length, 0, column.id);
        return old;
      });
      setColumns((old) => [...old, column]);
    },
    [setColumns, setColumnOrder]
  );

  const removeColumn = useCallback(
    (id: string) => {
      setColumnOrder((order) => {
        const newOrder = order.filter((item) => item !== id);
        return newOrder;
      });

      setColumns((columns) => {
        const newColumns = columns.filter((c) => c.id !== id);
        return newColumns;
      });
    },
    [setColumns, setColumnOrder]
  );

  const onSetTasks = useCallback(
    (props: IOnSetTasksProps) => {
      setColumns((c) =>
        c.map((c) => {
          if (c.id === props.id) {
            c.tasks = props.tasks;
          }
          return c;
        })
      );
    },
    [setColumns]
  );

  const onRemoveCard = useCallback(
    (props: IOnRemoveCardProps) => {
      setColumns((columns) => {
        const newColumns = columns.map((c) => {
          if (c.id === props.columnId) {
            c.tasks = c.tasks?.filter((t) => t.id !== props.cardId);
          }
          return c;
        });

        return newColumns;
      });
    },
    [setColumns]
  );

  const onAddCard = useCallback(
    (props: IOnAddCardProps) => {
      setColumns((columns) => {
        const newColumns = columns.map((c) => {
          if (c.id === props.columnId) {
            if (c.tasks) {
              c.tasks?.push(props.ticket);
            } else {
              c.tasks = [props.ticket];
            }
          }
          return c;
        });

        return newColumns;
      });
    },
    [setColumns]
  );

  const onParentChange = useCallback(
    (props: IOnParentChangeProps) => {
      setColumns((c) => {
        const column = c.find((c) => c.id === props.id);

        if (column) {
          column.parent = props.parent;
          return [...c, column];
        }

        return c;
      });
    },
    [setColumns]
  );

  const onColumnStatusChange = useCallback(
    (props: IOnStatusChangeProps) => {
      setColumns((c) => {
        const column = c.find((c) => c.id === props.id);

        if (column) {
          column.status = props.status;
          return [...c, column];
        }

        return c;
      });
    },
    [setColumns]
  );

  const onColumnTicketOrderChange = useCallback(
    (props: IOnTicketOrderChangeProps) => {
      setColumns((c) => {
        const column = c.find((c) => c.id === props.id);

        if (column) {
          column.ticketOrder = props.order;
          return [...c];
        }

        return c;
      });
    },
    [setColumns]
  );

  return {
    addColumn,
    onAddCard,
    onColumnColorChange,
    onColumnNameChange,
    onColumnStatusChange,
    onColumnTicketOrderChange,
    onParentChange,
    onRemoveCard,
    onSetTasks,
    removeColumn,
  };
};

export default useEditColumns;
