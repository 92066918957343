import { InputBase, Tooltip } from "@mui/material";
import {
  ClipboardEventHandler,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useFetch, useDebounce } from "@4uhub/lib4uhub";

import useMessageInput from "../../hooks/useMessageInput";
import QuickReplayPopover from "./QuickReplay/QuickReplayPopover";
import { getAnswers } from "../../services/quickReplay.service";
import useWorkspace from "../../hooks/useWorkspace";
import { uploadFile } from "../../services/fileManager.service";
import { useChat } from "../../store/context/chat-context";
import { useTranslation } from "react-i18next";

interface IMessageInput {
  placeholder: string;
  disabled?: boolean;
}

const MessageInput = ({ placeholder, disabled }: IMessageInput) => {
  const { t } = useTranslation();

  const {
    message,
    inputRef,
    setMessage,
    onMessageSend,
    setCursorPosition,
    setAnswers,
    answers,
    setFiles,
    setLoading,
  } = useMessageInput();

  const { messages } = useChat();

  const hasRequesterMessages = !!messages.find(
    (m) => m.whoSendType.code === "2"
  );

  const { actualWorkspace } = useWorkspace();

  const handleChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    setCursorPosition(0);

    if (e.target.value.startsWith("/")) {
      setAnchorEl(e.currentTarget);
    } else {
      setAnchorEl(null);
    }

    setMessage(e.target.value);
  };

  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & (HTMLInputElement | HTMLTextAreaElement)) | null
  >(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { sendRequest } = useFetch(getAnswers);

  const { sendRequest: upload } = useFetch(uploadFile);

  const mesageTocomando = useDebounce(message.substring(1), 500);

  const fetchAnswers = useCallback(async () => {
    if (!mesageTocomando || mesageTocomando.length < 3) return;
    const { data } = await sendRequest({
      SearchBy: mesageTocomando,
      TicketWorspaceId: actualWorkspace?.id,
    });
    if (data) {
      setAnswers(data.items);
    }
  }, [sendRequest, mesageTocomando, setAnswers, actualWorkspace?.id]);

  const onPaste: ClipboardEventHandler<HTMLDivElement> = useCallback(
    async (ev) => {
      setLoading(true);

      const files = Array.from(ev.clipboardData.files);

      await Promise.all(
        files.map(async (f) => {
          const { data, success } = await upload({
            File: f,
            type: "Private",
            ParentName: "TicketDialog",
            MediaTypeIdentifier: "SiteAppMediaType",
            MediaTypecode: "2",
          });
          if (data && success) {
            setFiles((files) => [...files, { file: f, id: data.id }]);
          }
        })
      );

      setLoading(false);
    },
    [setFiles, upload, setLoading]
  );

  useEffect(() => {
    fetchAnswers();
  }, [fetchAnswers]);

  const open = Boolean(anchorEl);

  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Tooltip
        title={t("page.card.chat.hasRequesterMessage")}
        disableHoverListener={hasRequesterMessages}
        disableFocusListener={hasRequesterMessages}
      >
        <InputBase
          aria-describedby={id}
          inputRef={inputRef}
          value={message}
          onChange={handleChange}
          sx={(t) => ({
            border: "none",
            borderWidth: "0px",
            outline: "none",
            paddingLeft: 1,
            width: "100%",
            "*::-webkit-scrollbar": {
              width: "0.4em",
            },
            "*::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              backgroundColor:
                t.palette.mode === "light"
                  ? t.palette.grey[400]
                  : t.palette.grey[900],
              borderRadius: 5,
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            },
            "*::-webkit-scrollbar-thumb": {
              backgroundColor:
                t.palette.mode === "light"
                  ? t.palette.grey[200]
                  : t.palette.grey[700],
              borderRadius: 5,
            },
          })}
          disabled={disabled || !hasRequesterMessages}
          multiline
          onPaste={onPaste}
          onKeyDown={(ev) => {
            if (ev.key === "Enter" && !ev.shiftKey) {
              onMessageSend(message);
              ev.preventDefault();
              setAnchorEl(null);
            }
          }}
          size="small"
          placeholder={placeholder}
          fullWidth
          maxRows={4}
        />
      </Tooltip>
      <QuickReplayPopover
        open={open}
        answers={answers}
        anchorEl={anchorEl}
        onClose={handleClose}
      />
    </>
  );
};

export default memo(MessageInput);
