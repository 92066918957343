import { memo } from "react";
import ProtocolProvider from "../../../../store/context/protocol-context";
import { IProtocolDefaultModalProps } from "./models";
import ProtocolDefaultContent from "./ProtocolDefaultContent";

const ProtocolDefaultModal = ({ onClose }: IProtocolDefaultModalProps) => {
  return (
    <ProtocolProvider>
      <ProtocolDefaultContent onClose={onClose} />
    </ProtocolProvider>
  );
};

export default memo(ProtocolDefaultModal);
