import { Box } from "@mui/material";
import { memo } from "react";
import { EventsHeader } from "./EventsHeader/EventsHeader";
import EventsProvider from "../../store/context/EventsContext/events-context";
import { EventsContent } from "./EventsContent/EventsContent";

export const Events = memo(() => {
  return (
    <EventsProvider>
      <Box sx={{ p: 1, height: "100%" }}>
        <EventsHeader />

        <EventsContent />
      </Box>
    </EventsProvider>
  );
});
