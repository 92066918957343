import { ISearchReturn } from "@4uhub/lib4uhub/dist/types/models";
import { GlobalAxios } from "../axios/axios-config";
import {
  IEvent,
  IEvents,
  ISearchEventsParams,
  IUpdateEvent,
} from "../models/events";

const TICKET_EVENTS_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketEventMovement";

export const getAllEvents = (serchParams?: ISearchEventsParams) => {
  return GlobalAxios.get<ISearchReturn<IEvents>>(TICKET_EVENTS_ROUTE, {
    params: serchParams,
  });
};

export const createEvent = (item: IEvent) => {
  return GlobalAxios.post(TICKET_EVENTS_ROUTE, item);
};

export const updateEventById = ({
  item,
  id,
}: {
  item: IUpdateEvent;
  id: string;
}) => {
  return GlobalAxios.put(TICKET_EVENTS_ROUTE + "/" + id, item);
};

export const getEventById = (id: string) => {
  return GlobalAxios.get<IEvents>(TICKET_EVENTS_ROUTE + "/" + id);
};
