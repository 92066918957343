import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { memo, useCallback } from "react";

import useWorkspace from "../../hooks/useWorkspace";
import WorkspacesItem from "./WorkspacesItem";

const CurrentWorkspace = () => {
  const { actualWorkspace } = useWorkspace();

  const { eI } = useParams();

  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    if (!actualWorkspace) return;
    navigate(`/e/${eI}/w/${actualWorkspace.ticketWorkspaceSequence}/boards`);
  }, [actualWorkspace, eI, navigate]);

  return (
    <Box
      sx={(t) => ({
        padding: t.spacing(0.5),
        paddingX: t.spacing(1),
      })}
    >
      {actualWorkspace && (
        <WorkspacesItem name={actualWorkspace.name} onClick={handleClick} />
      )}
    </Box>
  );
};

export default memo(CurrentWorkspace);
