const es = {
  translations: {
    page: {
      noAccess: {
        message:
          "¡Ups! ¡Parece que no tienes permiso para acceder a esta página!",
        button: "volver arriba",
      },
      login: {
        login: "Acceso",
        accessing: "Accesorio",
        welcome: "Bienvenido de nuevo",
        rememberme: "Acuérdate de mí",
        forget_password: "Olvido la contraseña?",
        username: "CPF",
        password: "Contraseña",
      },
      home: {
        Workspaces: "Tus áreas de trabajo",
        seeArchived: "Visualizar el trabajo archivado",
        archived: {
          title: "Áreas de trabajo archivadas",
          empty: "No encontramos ningún área de trabajo archivada",
        },
      },
      workspace: {
        title: "Tus tableros",
        archived: {
          button: "Mostrar marcos cerrados",
          title: "Marcos cerrados",
          empty: "No se encontró un marco cerrado",
          unarchive: "Descubrir",
          delete: "Borrar",
        },
        create: "Crear un nuevo marco",
      },
      board: {
        createCard: {
          modalTitle: "Agregar ticket(card) a la lista {{name}}",
          title: "Título",
          type: "Tipo",
          clientType: "Tipo de cliente",
          client: "Cliente",
          pf: "Pessoa Física",
          pj: "Pessoa Jurídica",
          not_identified: "No identificado",
          not_identified_name: "Nombre (opcional)",
          not_identified_phone: "Teléfono (opcional)",
          pf_client: "Cliente Pessoa Física",
          pj_client: "Cliente pessoa Jurídica",
          service: "Serviço",
          origin: "Origen",
          requester: "Solicitante",
          create: "Agregar ticket(card)",
          noService: "Sin servicio de tickets(cards) disponibles",
          inform_request: "Informar un solicitante diferente",
          form: {
            client: "Seleccione un cliente",
            requester: "Seleccione un solicitante",
            service: "Seleccione un servicio",
            title: "Ingrese un título",
            phoneValid: "Ingrese un número de teléfono valioso",
            phoneRequired: "Ingresa un número de teléfono",
            origin: "Seleccione un origen",
          },
        },
        settings: {
          noSelected: "No hay menú seleccionado",
          archived: {
            changeToCards: "Tarjeta",
            changeToColumns: "Intercambio",
            search: "Buscar archivados",
            cards: {
              unarchive: "Descubrir",
              delete: "Borrar",
              title: "Apagar ticket(card)",
              description:
                "Al borrar el ticket(card), se borran todos sus datos.Esta acción no se puede deshacer",
              secureLabel: "Ingrese el nombre del ticket(card) para eliminar",
            },
          },
        },
      },
      card: {
        internal_template: "Plantilla interna",
        events: "Eventos",
        activity: "Actividad",
        history: "Historial",
        finish: "Finalizar",
        noClient: "No se encontraron clientes",
        join: "Unirse",
        unreadMessage: "{{number}} mensajes no completados",
        client_sales_contract: "Contrato de venta al cliente",
        sales_contract: "Contratos",
        inList: "En la lista:  {{list}}",
        type: "Tipo",
        detail: "Detalles del ticket(card)",
        client: "Cliente",
        client_not_identified: "Cliente (No identificado PF/PJ)",
        requester: "Solicitante",
        clientEntityHelperText:
          "Escriba al menos 3 caracteres para buscar empresas",
        clientIndividualHelperText:
          "Escriba al menos 3 caracteres para buscar individuos",
        selectStatus: "Seleccione un estado",
        selectOrigin: "Seleccione un origen",
        selectCustomerServiceType: "Seleccione un tipo de servicio",
        content: "Descripción",
        whatsappNumber: "Número de Whatsapp",
        whatsappName: "Nombre de Whatsapp",
        edit: "Editar",
        moreDetailedDescription: "Agregue una descripción más detallada",
        save: "Guardar",
        cancel: "Cancelar",
        status: "Estado",
        origin: "Origen",
        protocol: "Protocolo",
        copied: "Protocolo copiado exitosamente",
        copyTooltip: "Copiar protocolo",
        number: "Número",
        customerServiceType: "Tipo de Servicio",
        view_more: "Ver más",
        view_less: "Ver menos",
        ticket_external_process_type: "Tipo de Proceso Externo",
        ticket_external_identifier: "Identificador de Proceso Externo",
        services: "Servicios",
        ticket_external_identifier_error:
          "El identificador de proceso externo es obligatorio",
        ticket_external_process_type_error:
          "Primero debe seleccionar un tipo de proceso externo",
        actions: {
          title: "Acciones",
          archive: "Archivar",
          move: "Mover",
          leave: "Salir del Ticket",
          move_and_leave: "Mover y Salir del Ticket",
          reopen: {
            reopen_successfully: "Ticket(card) reabierto con éxito",
            button: "Reabrir",
          },
          moveTicket: "Mover ticket(card)",
          list: "Lista",
          board: "Tablero",
          order: "Orden",
          workspace: "Área de Trabajo",
        },
        chat: {
          title: "Chat",
          file: "Adjuntar archivo",
          emoji: "Emojis",
          refresh: "Actualizar",
          quickReplay: "Respuestas rápidas",
          chatPlaceholder: "Escribe tu mensaje...",
          hasRequesterMessage:
            "Para enviar un mensaje, el solicitante debe enviar al menos un mensaje",
          noMessages: {
            startChat: {
              title: "No se encontraron conversaciones",
              subtitle:
                "Haga clic en el botón de abajo para iniciar una conversación.",
              button: "Iniciar conversación",
            },
            whatsappNumber: {
              title: "Introduce un número de WhatsApp",
              subtitle:
                "El número de WhatsApp se utilizará para enviar mensajes.",
              country: "DDI",
              phone: "Número de whatsap",
              phone_options: "Seleccione un número o agregue uno nuevo",
              errors: {
                country: "El DDI es obligatorio",
                phone: "El número de WhatsApp es obligatorio",
              },
            },
            selectAutomationRule: {
              title: "Ingrese una regla de automatización",
              subtitle:
                "La regla de automatización se utilizará para enviar mensajes automáticos.",
              restart_title: "Reiniciar conversación",
              restart_subtitle:
                "La regla de automatización se utilizará para reiniciar la conversación.",
              automation_rule: "Regla de automatización",
              chat_started: "La conversación comenzó exitosamente.",
              errors: {
                automation_rule: "La regla de automatización es obligatoria",
              },
            },
            noMessagesActions: {
              back: "Volver",
              next: "Próximo",
              finish: "Finalizar",
              restart: "Reanudar",
            },
          },
        },
        flow: {
          openTickets: "Tickets(cards) abiertos",
          userTickets: "Mis tickets(cards)",
          filter: "Filtrar por Lista",
          noTickets: "No se encontraron tickets(cards) abiertos",
          noTicketFound:
            "No se encontró ticket(card).Ingrese cualquier ticket(card)",
          noTicketSelected:
            "No hay ticket(card) seleccionado.Seleccione un ticket(card)",
          addTicketButton: {
            btn_label: "Ticket(card)",
            tooltip:
              "Para agregar un ticket (tarjeta) es necesario tener una lista con estado 'Abierto'",
          },
        },
      },
    },
    components: {
      dragScreen: {
        message: "Arrastre el archivo aquí",
      },
      table: {
        no_finish_date: "Sin fecha de finalización",
        status: "Estado",
        protocolNumber: "Número de Protocolo",
        ticketService: "Servicio de tickets(card)",
        ticketSequential: "ID interno",
        ticket: "Ticket(card)",
        column: "Lista",
        individualRequester: "Solicitante",
        unreadMessages: "Mensajes no completados",
        individual: "Individual",
        created: "Creado en",
        finishDate: "Terminar",
        ticketChannelTags: "Etiquetas",
        ticketMembers: "Miembros",
        empty: "No se encuentra la tarea",
      },
      empty: {
        title: "Nada Encontrado",
      },
      menuBoards: {
        your_boards: "Tus tableros",
        current_workspace: "Área de trabajo",
        minimize: "Minimizar",
        view_boards: "Visualización",
      },
      BoardNotFound: {
        title: "No encontramos el tablero que estás buscando",
        button: "Volver a la lista de tableros",
      },
      workspacesPopover: {
        current_workspace: "Escritorio actual",
        workspaces: "Espacios de trabajo",
        create_workspace: "Crear espacio de trabajo",
      },
      workspace: {
        workspaces: "Áreas de trabajo",
        title2: "Editar escritorio",
        see_more: "Ver más",
        create_workspace: "Crear espacio de trabajo",
        edit: "Editar",
        title: "Crear nuevo espacio de trabajo",
        subtitle:
          "El escritorio fue diseñado exclusivamente para ayudarle a tener un día más productivo. ¡Crea un nuevo espacio de trabajo y sé más productivo!",
        name: "Nombre del escritorio",
        establishments: "Establecimientos",
        create: "Crear",
        delete: {
          title:
            'Ingrese el nombre del escritorio "{{secureReferenceText}}" para eliminar',
          description:
            "Al eliminar todos los marcos y tickets(cards), se perderán. Esta acción es irreversible.",
          label: "Ingrese el nombre del escritorio para eliminar",
        },
        archive: {
          title: "Archivo",
          description:
            "Puede encontrar y desarchive el trabajo y el trabajo en la parte inferior de la pantalla de su escritorio",
          button: "Archivar",
        },
      },
      boards: {
        errors: {
          finishedColumn:
            "No es posible terminar un ticket(card) sin primero pasar por el estado en el proceso.",
        },
        filters: {
          filters: "Filtros",
          clearAll: "Limpiar filtros",
          title: "Título",
          helperText: "Buscar por títulos de tickets(cards)",
          alreadyParticipated: "Ya he participado",
          history: "Historial",
          date: "Fechas",
          endDate: "Fecha final",
          status: "Situación",
          initialDate: "Fecha inicial",
          status_helper:
            "Agregue la fecha de inicio y finalización para este filtro",
          asignToMe: "Asignado a mí",
          members: "Miembros",
          createdBy: "Abierto por",
          createdByMe: "Abierto por mi",
          noMembers: "Sin miembros",
          selectMembers: "Seleccionar miembros",
          selectMember: "Seleccionar un miembro",
          apply: "Aplicar Filtros",
          invalid_endDate: "Fecha final no válida",
          invalid_initialDate: "Fecha de inicio no válida",
          openedBySystem: "Abierto por el sistema",
        },
        delete: {
          title:
            'Ingrese el nombre de la tabla "{{secureReferenceText}}" para eliminar',
          description:
            "Al eliminar todos los tickets(cards) se perderán.Esta acción es irreversible",
          label: "Ingrese el nombre del tablero para eliminar",
        },
        form: {
          edit: "Editar marco",
          create: "Crear tablero ",
          title: "Título",
          type: "Tipo",
          parent: "Marco principal",
        },
        settingsMenu: {
          archivedItem: "Artículos archivados",
          closeBoard: "Cerca",
          editBoard: "Editar marco",
          pages: {
            menu: "Menú",
            archived: "Archivado",
          },
          selectParent: "Seleccionar tablero de vista",
          select: "Seleccionar",
          archived: {
            unarchive: "Descubrir",
            remove: "apagar",
            secureRemove: {
              title:
                'Ingrese el nombre de la lista "{{reference}}" para eliminar',
              description:
                "Al borrar la lista, todas sus tareas también serán eliminadas.",
              button: "Cerca",
              secureLabel: "Ingrese el nombre de la lista para eliminar",
            },
          },
          closeBoardDescription:
            "Puede encontrar y reabrir sus marcos en la pantalla de escritorio",
        },
      },
      chat: {
        quickReplay: {
          send: "Enviar",
          delete: "Borrar",
          edit: "Editar",
          popover: {
            title: "Respuestas rápidas (escriba al menos 3 letras)",
            empty: "No se encontró respuesta",
          },
          modal: {
            title: "Respuestas Rápidas",
          },
          list: {
            empty: "No se encontró una respuesta rápida",
            register: "Registro",
          },
          deleteModal: {
            title: "Respiración rápida",
            description:
              "La respuesta rápida se eliminará. Esta acción no puede ser deshecha",
            delete: "Borrar",
          },
          form: {
            edit: "Editar respuesta",
            create: "Crear Respuesta",
            cancel: "Cancelar",
            shareType: "Intercambio",
            shortCut: "Atajo",
            message: "Mensaje",
            title: "Título",
          },
        },
        restartMessagePopUp: {
          title: "Conversación caducada!",
          subtitle:
            "Esta conversación no ha tenido interacciones en las últimas 24 horas. Haga clic en el botón para reiniciarlo.",
          buttonRestart: "Reanudar",
          buttonFinish: "Finalizar",
          finishConfirm: {
            modal_title: "¿Finalizar el Ticket?",
            cancel: "Cancelar",
            confirm: "Confirmar",
            finish_ticket:
              "¿Estás seguro de que deseas finalizar este ticket (tarjeta)? No será posible entregar el mensaje de finalización porque se excedió el período de 24 horas. Si deseas continuar esta conversación, haz clic en el botón Reiniciar.",
          },
        },
      },
      column: {
        transferred: "Lista transferida",
        minimize: "Minimizar",
        maximize: "Maximizar",
        menu: {
          title: "Lista común",
          transferred: {
            mark: "Marcar como una lista de transferidos",
            unmark: "Desactivar como una lista de transferidos",
          },
          changeParent: {
            title: "Cambiar la columna de visualización",
            change: "Columna de cambio",
            button: "Cambiar",
          },
          changeDefaultStatus: {
            title: "Cambiar el estado estándar",
            change: "Cambiar estado",
            button: "Cambiarr",
          },
          changeColor: {
            title: "Cambiar",
            change_color: "Cambiar el color",
            select: "Seleccionar el color",
          },
          archive: {
            title: "Lista de archivo",
          },
        },
        task: {
          addTask: "Agregar ticket(card)",
        },
        addColumn: {
          addColumn: "Añadir columna",
          name: "Nombre",
          nameRequired: "El nombre es obligatorio",
          parentRequired: "La columna principal es obligatoria",
          parent: "Columna principal",
        },
      },
      ticketTagsList: {
        tags: "Tags",
        add_tag: "Añadir Tag",
      },
      cardTagSelector: {
        tags: "Tags",
        tag: "Tag",
        create_tag: "Crear Tag",
      },
      tagDeleteButton: {
        delete: "Borrar Tag",
        description:
          "Esta acción hará que la Etiqueta se elimine de todas las tarjetas a las que fue asignada.",
        delete_button: "Borrar",
      },
      tagItemActions: {
        edit: "Editar Tag",
      },
      tagForm: {
        name: "Nombre",
        enable: "Permitir",
        display_order: "Orden de visualización",
        ticket_channel: "Tipo de ticket(card)",
        color: "Seleccionar color",
        errors: {
          name: "El nombre es obligatorio",
          display_order: "La orden de visualización es obligatoria",
          ticket_channel: "El tipo de ticket(card) es obligatorio",
          color: "El color es obligatorio",
        },
      },
      tagFormActions: {
        back: "Volver",
        edit: "Editar",
        create: "Crear",
      },
      boardHeader: {
        invite: "Invitar",
        boardMode: {
          table: "Tabla",
        },
        invite_member: "Miembro invitado",
        member: {
          user_added: "{{user}} agregado(a) exitosamente.",
          function_update:
            "La función de {{fullName}} se cambió correctamente.",
          remove_successfully: "{{fullName}} eliminado correctamente.",
          leave_board: "Has abandonado con éxito el marco.",
          you: "(tú)",
          removing_board: "Quitando marco...",
          leave_board_option: "Dejar el marco",
          remove_user_option: "Quitar del marco",
          select_user: "Seleccionar usuario",
          invite_user: "Invitar",
          nickname: "Apellido",
          member_added: "Miembro agregado con éxito",
          member_updated: "Miembro actualizado con éxito",
          member_removed: "Miembro eliminado con éxito",
          errors: {
            user: "El usuario es obligatorio",
            profile: "El perfil es obligatorio",
          },
        },
      },
      createChecklist: {
        checklist: "Checklist",
        create_checklist_button: "Crear checklist",
        name: "Nombre",
        display_order: "Orden",
        errors: {
          name: "El nombre es obligatorio",
          display_order: "La orden es obligatoria",
        },
      },
      deleteChecklist: {
        delete: "Borrar",
        title: "Borrar checklist",
        description: "¿Está seguro de que desea eliminar el checklist?",
      },
      cardChecklistToggleItems: {
        hide: "Ocultar elementos marcados",
        show: "Mostrar elementos marcados",
      },
      cardChecklistAddItem: {
        button: "Añadir elemento",
      },
      checklistAddItemForm: {
        title: "Añadir elemento",
        description: "Elemento",
        forecastStartDate: "Fecha de inicio esperada",
        estimatedTime: "Tiempo estimado en minutos",
        button: "Añadir",
        errors: {
          description: "El elemento es obligatorio",
          estimatedTime:
            "El tiempo estimado no puede ser un valor negativo o cero",
        },
      },
      checklistItemDelete: {
        title: "Remover elemento",
        description: "Esta acción eliminará el elemento del checklist.",
        button: "Borrar",
        tooltip: "Borrar elemento",
      },
      chcecklistItemTime: {
        tooltip: "Establecer horarios",
        title: "Establecer horarios",
        forecast_start_date: "Fecha de inicio esperada",
        estimated_time: "Tiempo estimado en minutos",
        start_date: "Fecha de inicio",
        end_date: "Fecha final",
        save: "Guardar",
        errors: {
          estimated_time:
            "El tiempo estimado no puede ser un valor negativo o cero",
        },
      },
      cardMembers: {
        members: "Miembros",
        add_member: "Añadir miembro",
        join: "Introduce el ticket(card)",
      },
      cardMembersSelector: {
        members: "Miembros",
        search: "Buscar miembros",
        member_added: "Miembro agregado exitosamente",
        member_removed: "Miembro eliminado exitosamente",
      },
      cardHistories: {
        activity: "Actividad",
        show_details: "Mostrar detalles",
        hide_details: "Ocultar detalles",
        internal: "Interno",
        external: "Externo",
      },
      historyComments: {
        placeholder: "Escribir un comentario...",
        save: "Ahorrar",
        cancel: "Cancelar",
        history_type: "Tipo de histórico",
      },
      errors: {
        message: "La mensaje es obligatoria",
        history_type: "El tipo de histórico es obligatorio",
      },
      cardPeriod: {
        created: "Fecha de creación",
        finish_date: "Fecha final",
        started: "Fecha de inicio",
      },
      joinAction: {
        finish: "Finalizar",
        join: "Unirse",
        no_lists:
          "No es posible unirse a este ticket(card), ya que no existen listas con el estado 'En proceso'",
        select: "Seleccionar",
      },
      finishActionPopover: {
        no_list:
          "No es posible finalizar este ticket(card) ya que no hay una lista con el estado 'Terminado'",
      },
      advancedSearchHeader: {
        search: "Buscar",
        protocol_or_id: "Protocolo o Id interno",
        button: "Buscar",
        advanced_search: "Búsqueda avanzada",
        errors: {
          protocol_or_id: "El protocolo o id interno es obligatorio",
        },
      },
      advancedSearchViewMore: {
        button: "Ver más",
      },
      searchResultCards: {
        no_access: "No tienes acceso a este ticket(card)",
      },
      searchResults: {
        no_workspace_dates: {
          title: "Área de trabajo y fechas no informadas!",
          subtitle:
            "Es necesario proporcionar al menos el área de trabajo y las fechas de inicio y finalización para realizar una búsqueda.",
        },
        no_workspace: {
          title: "¡Escritorio no seleccionado!",
          subtitle:
            "Seleccione al menos el escritorio para realizar una búsqueda",
        },
        no_dates: {
          title: "Las fechas de inicio y finalización son obligatorias",
          subtitle:
            "Debes seleccionar una fecha de inicio y finalización para iniciar una búsqueda",
        },
        range_bigger: {
          title: "¡Consulta fechas seleccionadas!",
          subtitle:
            "La fecha de finalización no puede tener más de 180 días de diferencia con la fecha de inicio",
        },
        filter_click: {
          title:
            "¡Ahora todo lo que tienes que hacer es hacer clic en filtrar!",
          subtitle: "Debes hacer clic en 'filtrar' para iniciar una búsqueda",
        },
        no_results: {
          title: "¡Ningún resultado encontrado!",
          subtitle:
            "Cambie los parámetros del filtro para obtener un resultado diferente",
        },
      },
      searchResultsList: {
        search_results: "Resultados de la búsqueda:",
      },
      advancedSearchFields: {
        filters: "Filtros",
        clean_filters: "Limpiar filtros",
        filter: "Filtrar",
        workspace: "Área de trabajo",
        start_date: "Fecha de inicio",
        end_date: "Fecha final",
        protocol_or_id: "Protocolo o Id interno",
        title: "Título",
        individual_requester_name: "Nombre del solicitante",
        individual_client_name: "Nombre del cliente",
        created_by: "Abierto por",
        created_by_no_options: "Sin opciones",
        status: "Status",
        board: "Tablero",
        filter_by: "Filtrar por",
        create: "Creación",
        finish: "Finalización",
        errors: {
          range: "Búsqueda máxima de 180 días.",
        },
      },
      protocolDefault: {
        protocol_default: "Protocolos de Orientación Estándar",
      },
      protocolsDefaultList: {
        no_results: "Ningún resultado encontrado",
        viw_more: "Ver más",
      },
      protocolDefaultModal: {
        search: "Buscar un protocolo",
      },
      sharedContacts: {
        title: "Contacto(s) compartido(s):",
        another_contact: "y un contacto mas",
        another_contacts: "y otros contactos",
        copy_tooltip: "Copiar al portapapeles",
        copied: "Copiado al portapapeles",
        formatted_name: "Nombre",
        first_name: "Primer Nombre",
        last_name: "Apellido",
        prefix_name: "Prefijo",
        sufix_name: "Sufijo",
        whatsapp: "WhatsApp",
        phone: "Teléfono",
        email: "Correo electrónico",
        address: "Dirección",
        address_type: "Tipo de dirección",
        zip_code: "Código Postal",
        street: "Calle",
        city: "Ciudad",
        state: "Estado",
        country: "País",
        url: "Enlace",
        birthday: "Fecha de Nacimiento",
      },
      sharedLocation: {
        title: "Ubicación compartida:",
        show: "Ver ubicación",
      },
      detailFiles: {
        files: "Archivos:",
        no_protocol_selected: "Ningún protocolo seleccionado",
        no_data: "No se encontraron archivos",
      },
      detailFile: {
        no_data: "No se encontraron archivos",
      },
      detailFileActions: {
        view_file: "Ver archivo",
        view_more: "Ver más",
      },
      detailMessage: {
        message: "Mensaje:",
        no_protocol_selected: "Ningún protocolo seleccionado",
        no_data: "No se encontraron mensajes",
      },
      detailSend: {
        send: "Enviar",
        sent_successfully: "Protocolo enviado exitosamente",
      },
      events: {
        event_type: "Tipo de evento",
        event: "Evento",
        initial_date: "Fecha de inicio",
        end_date: "Fecha final",
        created_by: "Operador",
        no_data: "No se encontraron eventos",
        event_created: "Evento creado con éxito",
        event_updated: "Evento actualizado con éxito",
        select_event_hint:
          "Debes seleccionar un tipo de evento para seleccionar un evento",
        header: {
          search: "Buscar un evento",
          add_event: "Añadir evento",
          back: "Volver",
        },
        actions: {
          save: "Ahorrar",
          cancel: "Cancelar",
        },
        errors: {
          event: "El evento es obligatorio",
          initial_date: "La fecha de inicio es obligatoria",
          end_date:
            "La fecha de finalización no puede ser inferior a la fecha de inicio",
        },
      },
      internalTemplate: {
        internal_template: "Plantilla Interna",
        link_template: "Vincular Plantilla",
        change_template: "Cambiar Plantilla",
        error: "Este campo es obligatorio",
        change: "Cambiar",
        there_isnt_template: "No hay ninguna plantilla interna vinculada.",
        modal_title: "Cambiar Plantilla?",
        delete_template: "Esto eliminará todos los valores de las variables.",
        update_successfully: "Valores de variables actualizados exitosamente",
        actions: {
          save: "Ahorrar",
          cancel: "Cancelar",
        },
        errors: {
          event: "El evento es obligatorio",
          initial_date: "La fecha inicial es obligatoria",
          end_date:
            "La fecha de finalización no puede ser anterior a la fecha de inicio",
        },
      },
      statusMessage: {
        tooltip: "Ver status",
      },
      statusDrawer: {
        message_status: "Status del mensaje",
        delivered: "Entregado",
        read: "Leído",
        sent: "Enviado",
        error: "¡Ups, ocurrió un error!",
      },
      logout: "Cerrar sesión",
      settings: "Ajustes",
      cardServices: {
        whatsapp_number: "Número de WhatsApp",
        requester: "Solicitante",
        no_data_dialog: "Ningún mensaje encontrado para este ticket",
        client: "Cliente",
        no_data: "No se encontraron tickets(cards)",
        history: "Historia",
        go_to_ticket: "Ir al ticket(card)",
      },
      view_more: "Ver más",
    },
    general: {
      login_expired: "Inicie de sesión caducado",
      archive: "Archivar",
    },
  },
};

export default es;
