import { Stack } from "@mui/material";
import SearchFilterContainer from "../components/AdvancedSearch/SearchFilter/SearchFilterContainer";
import SearchResults from "../components/AdvancedSearch/SearchResults";
import AdvancedSearchProvider from "../store/context/advanced-search-context";
import { motion } from "framer-motion";

const SearchPage = () => {
  return (
    <AdvancedSearchProvider>
      <Stack direction={"row"} width={"100%"} height={"100%"}>
        <SearchFilterContainer />
        <motion.div
          style={{
            height: "100%",
            overflow: "auto",
            width: "100%",
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99] }}
        >
          <SearchResults />
        </motion.div>
      </Stack>
    </AdvancedSearchProvider>
  );
};

export default SearchPage;
