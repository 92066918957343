import {
  Button,
  Form,
  FormToggleButton,
  ISelectType,
  useFetch,
} from "@4uhub/lib4uhub";
import { Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import { addHistoryComment } from "../../../../services/cardHistories.service";
import MainApiService from "../../../../services/mainApi.service";
import {
  THistoryCommentForm,
  historyCommentSchema,
} from "./HistoryCommentSchema";
import FormTextEditor from "../../../UI/TextEditor/FormTextEditor";
import useTicket from "../../../../hooks/useTask";

const translationPath = "components.historyComments.";

const GENERIC_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const genericService = new MainApiService<ISelectType>(GENERIC_ROUTE);

const HistoryCommentInput = ({ onCancel }: { onCancel: () => void }) => {
  const { t } = useTranslation();

  const { ticket } = useTicket();

  const [historyTypes, setHistoryTypes] = useState<ISelectType[]>([]);

  const methods = useForm<THistoryCommentForm>({
    resolver: zodResolver(historyCommentSchema),
  });

  const { sendRequest, loading } = useFetch(addHistoryComment);

  const { sendRequest: loadHistoryTypes } = useFetch(genericService.list);

  const historyTypesOptions = useCallback(async () => {
    const { data, success } = await loadHistoryTypes({
      Identifier: "HistoryType",
      Codes: ["1", "2"],
    });
    if (data && success) {
      setHistoryTypes(data);
      methods.setValue("historyType", data[0].id);
    }
  }, [loadHistoryTypes, methods]);

  useEffect(() => {
    historyTypesOptions();
  }, [historyTypesOptions]);

  const newCommentHandler = useCallback(
    async (v: THistoryCommentForm) => {
      if (ticket?.id) {
        const { data, success } = await sendRequest({
          ticketId: ticket?.id,
          detail: false,
          historyTypeId: v.historyType,
          message: v.message,
        });
        if (data && success) {
          onCancel();
        }
      }
    },
    [sendRequest, ticket?.id, onCancel]
  );

  const submitHandler = useCallback(() => {
    methods.handleSubmit(newCommentHandler)();
  }, [methods, newCommentHandler]);

  return (
    <Stack width={"100%"}>
      <Form {...methods}>
        <FormToggleButton name="historyType" options={historyTypes} />
        <FormTextEditor name="message" />
        <Stack mt={1} flexDirection={"row"} justifyContent={"start"} gap={1}>
          <Button
            size="small"
            variant="contained"
            loading={loading}
            onClick={submitHandler}
          >
            {t(translationPath + "save")}
          </Button>
          <Button size="small" variant="text" onClick={onCancel}>
            {t(translationPath + "cancel")}
          </Button>
        </Stack>
      </Form>
    </Stack>
  );
};

export default HistoryCommentInput;
