import { AutoComplete, useFormContext } from "@4uhub/lib4uhub";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useWorkspaceContext } from "../../../store/context/workspace-context";
import { TCreateBoardType } from "./AddBoardSchema";

interface IParentBoardSelectorProps {
  disable: boolean;
}

const ParentBoardSelector: React.FC<IParentBoardSelectorProps> = ({
  disable,
}) => {
  const { control } = useFormContext<TCreateBoardType>();

  const show = useWatch({ name: "isView", control });

  const { t } = useTranslation();

  const { viewboards } = useWorkspaceContext();

  if (show) return <></>;

  return (
    <AutoComplete
      getOptionLabel={(option) => {
        return option.name;
      }}
      size="small"
      disable={disable}
      options={viewboards}
      label={t("components.boards.form.parent")}
      name="parent"
    />
  );
};

export default ParentBoardSelector;
