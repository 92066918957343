import {
  IRequestItem,
  ISearchParams,
  ISearchReturn,
} from "@4uhub/lib4uhub/dist/types/models";
import { GlobalAxios } from "../axios/axios-config";
import { ISelectType } from "../models/selects";

export class MainApiService<T extends IRequestItem> {
  route_path: string;

  constructor(rp: string) {
    this.route_path = rp;
  }

  create = (item: T) => {
    return GlobalAxios.post(this.route_path, item);
  };

  getAll = (serchParams?: ISearchParams) => {
    return GlobalAxios.get<ISearchReturn<T>>(this.route_path, {
      params: serchParams,
    });
  };

  getItemById = (id: string) => {
    return GlobalAxios.get<T>(this.route_path + id);
  };

  updateItemById = ({ item, id }: { item: T; id: string }) => {
    return GlobalAxios.put(this.route_path + id, item);
  };

  deleteItemById = (id: string) => {
    return GlobalAxios.delete(this.route_path + id);
  };

  list = (params?: any) => {
    return GlobalAxios.get<ISelectType[]>(this.route_path + "list", {
      params: params,
      paramsSerializer: { indexes: null },
    });
  };
}

export default MainApiService;
