import { GlobalAxios } from "../axios/axios-config";
import { IListQueryParams, IListReturn } from "../models/ListParams";
import { IQuickAnswer, IQuickAnswerPost } from "../models/QuickAnswers";

const BASE_URL = process.env.REACT_APP_TICKETS;

interface IGetAnswersParams extends IListQueryParams {
  TicketWorspaceId?: string;
}

const QUICK_ANSWERS_ROUTE = BASE_URL + "/api/v1/TicketChannelQuickAnswer";

export const getAnswers = (params?: IGetAnswersParams) => {
  return GlobalAxios.get<IListReturn<IQuickAnswer>>(QUICK_ANSWERS_ROUTE, {
    params,
  });
};

export const postAnswer = (data: IQuickAnswerPost) => {
  return GlobalAxios.post(QUICK_ANSWERS_ROUTE, data);
};

export const editAnswer = ({
  data,
  id,
}: {
  data: IQuickAnswerPost;
  id: string;
}) => {
  return GlobalAxios.put(QUICK_ANSWERS_ROUTE + `/${id}`, data);
};

export const deleteAnswer = (id: string) => {
  return GlobalAxios.delete(QUICK_ANSWERS_ROUTE + `/${id}`);
};
