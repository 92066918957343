import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { IconButton } from "@mui/material";
import { Dispatch, SetStateAction, memo } from "react";

import FiltersPopover from "./Filter/FiltersPopover";

interface IActionsProps {
  SetSettingsMenu: Dispatch<SetStateAction<boolean>>;
}

const Actions: React.FC<IActionsProps> = ({ SetSettingsMenu }) => {
  return (
    <>
      <FiltersPopover />

      <IconButton
        size="small"
        color={"primary"}
        onClick={() => SetSettingsMenu(true)}
      >
        <MoreHorizIcon />
      </IconButton>
    </>
  );
};

export default memo(Actions);
