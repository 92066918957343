import { useFetch, usePopover } from "@4uhub/lib4uhub";
import { Box, Popover, TextField, Tooltip, Typography } from "@mui/material";
import {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import useTicket from "../../../../hooks/useTask";
import { externalProcessTypeTicket } from "../../../../services/ticket.service";
import { useExternalProcessContext } from "../../../../store/context/external-process-context";
import { SignalRContext } from "../../../SignalR/SignalRProvider";
import HoverOpacity from "../../../UI/HoverOpacity";
import Property from "../../Property";

const translationPath = "page.card.";

export const TicketExternalIdentifier = memo(() => {
  const { t } = useTranslation();

  const props = usePopover("ticket-external-process-type-popover");

  const { handleClose, handleClick } = props;

  const {
    externalProcessTypeControl,
    externalProcessType,
    onChangeExternalProcessTypeControl,
  } = useExternalProcessContext();

  const { sendRequest } = useFetch(externalProcessTypeTicket);

  const { isUserTicketMember, ticket } = useTicket();

  const [text, setText] = useState<string | null>(
    ticket.externalProcessTypeControl
  );

  const [hasEllipsis, setHasEllipsis] = useState(false);

  const textRef = useRef<HTMLDivElement>(null);

  SignalRContext.useSignalREffect(
    "TicketUpdated", // Your Event Key
    (userId: string, data) => {
      if (data.id === ticket?.id) {
        if (data.externalProcessTypeControl) {
          onChangeExternalProcessTypeControl(data.externalProcessTypeControl);
        }
      }
    },
    [ticket?.id, onChangeExternalProcessTypeControl]
  );

  const changeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setText(event.target.value);
    },
    []
  );

  const valueChangeHandler = useCallback(async () => {
    if (text) {
      if (ticket.externalProcessTypeControl !== text) {
        const { data, success } = await sendRequest({
          id: ticket.id,
          externalProcessTypeControl: text ?? undefined,
          ticketExternalProcessTypeId: externalProcessType
            ? externalProcessType.id
            : undefined,
        });

        if (data && success) {
          handleClose();
        }
        return;
      }

      handleClose();
      return;
    }

    handleClose();
  }, [
    sendRequest,
    handleClose,
    externalProcessType,
    ticket.id,
    text,
    ticket.externalProcessTypeControl,
  ]);

  const openPopover = useCallback(
    (event: React.MouseEvent<Element, MouseEvent>) => {
      if (!text || text.length === 0) {
        setText(externalProcessTypeControl ?? "");
      }
      handleClick(event);
    },
    [handleClick, text, externalProcessTypeControl]
  );

  useEffect(() => {
    if (textRef.current) {
      setHasEllipsis(textRef.current.scrollWidth > textRef.current.clientWidth);
    }
  }, [externalProcessTypeControl]);

  return (
    <>
      <HoverOpacity onClick={openPopover} disabled={!isUserTicketMember}>
        <Property
          content={
            <Tooltip
              title={text ?? externalProcessTypeControl ?? ""}
              disableHoverListener={!hasEllipsis}
              disableFocusListener={!hasEllipsis}
            >
              <Typography
                ref={textRef}
                color={(t) => t.palette.text.primary}
                fontSize={14}
                noWrap
              >
                {externalProcessTypeControl ?? "-"}
              </Typography>
            </Tooltip>
          }
          label={t(translationPath + "ticket_external_identifier")}
        />
      </HoverOpacity>

      <Popover
        {...props}
        onClose={handleClose}
        title={"Tipo de Processo Externo"}
      >
        <Box p={2} minWidth={250}>
          <TextField
            value={text}
            onChange={externalProcessType ? changeHandler : undefined}
            onBlur={valueChangeHandler}
            size="small"
            fullWidth
            error={!text || text.length === 0 || !externalProcessType}
            helperText={
              !externalProcessType
                ? t(translationPath + "ticket_external_process_type_error")
                : !text || text.length === 0
                ? t(translationPath + "ticket_external_identifier_error")
                : ""
            }
          />
        </Box>
      </Popover>
    </>
  );
});
