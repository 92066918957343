import { Stack } from "@mui/material";

import CardInfos from "../CardPage/CardInfos";
import Customer from "../CardPage/Customer";

const TicketDetail: React.FC = () => {
  return (
    <Stack gap={1} p={2} height={"100%"}>
      <Customer />

      <CardInfos  />
    </Stack>
  );
};

export default TicketDetail;
