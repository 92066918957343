import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { ChangeEvent, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "@4uhub/lib4uhub";

import { TFilterSchema } from "../filterSchema";

const AlreadyParticipated = () => {
  const { setValue, watch } = useFormContext<TFilterSchema>();

  const members = watch("membersIds");

  const checked = useMemo(
    () => members.includes("alreadyParticipated"),
    [members]
  );

  const { t } = useTranslation();

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      setValue("membersIds", [...members, "alreadyParticipated"], {
        shouldDirty: true,
      });
    }
    if (!checked) {
      setValue(
        "membersIds",
        members?.filter((member) => member !== "alreadyParticipated"),
        {
          shouldDirty: true,
        }
      );
    }
  };

  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={handleChange} />}
      label={
        <Typography variant="body2">
          {t("components.boards.filters.alreadyParticipated")}
        </Typography>
      }
    />
  );
};

export default memo(AlreadyParticipated);
