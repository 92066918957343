import {
  AuthBetwheenApps,
  AuthContextProvider,
  Environment,
  ThemeContextProvider,
  useNotificationContext,
} from "@4uhub/lib4uhub";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Provider } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import "./App.css";
import {
  GlobalAxios,
  InterceptorRefresh,
  removeUserData,
} from "./axios/axios-config";
import routes from "./routes";
import { tokenByToken } from "./services/auth.service";
import { saveTokens } from "./services/token.service";
import store from "./store/store";
import { themeOptions } from "./theme";
import InstalledExtensionContainer from "./components/InstalledExtensionsContainer";

const router = createBrowserRouter(routes);

const environmentType = process.env.REACT_APP_ENVIRONMENT;

function App() {
  const { setMessage } = useNotificationContext();

  const { t } = useTranslation();

  const logoutUser = useCallback(() => {
    removeUserData();
    router.navigate("/login");
    setMessage({
      message: t("general.login_expired"),
      notificationKey: new Date().getMilliseconds(),
      type: "error",
    });
  }, [setMessage, t]);

  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {environmentType && environmentType !== "production" && (
          <Environment environment={environmentType} />
        )}
        <AuthContextProvider
          identityApiPath={process.env.REACT_APP_IDENTIY!}
          mainApiPath={process.env.REACT_APP_MAIN!}
          onLogoutUser={logoutUser}
          refreshToken={InterceptorRefresh}
          axios={GlobalAxios}
        >
          <InstalledExtensionContainer>
            <Router />
          </InstalledExtensionContainer>
        </AuthContextProvider>
      </LocalizationProvider>
    </Provider>
  );
}

const Router = () => {
  return (
    <ThemeContextProvider themeOptions={themeOptions}>
      <AuthBetwheenApps
        router={router}
        saveTokens={saveTokens}
        tokenByToken={tokenByToken}
        redirectRoute={(code, _, redirect) =>
          redirect ? `/e/${code}${redirect}` : `/e/${code}`
        }
      >
        <RouterProvider router={router} />
      </AuthBetwheenApps>
    </ThemeContextProvider>
  );
};

export default memo(App);
