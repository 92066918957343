import { ISelectType, IUser } from "@4uhub/lib4uhub";
import DeleteIcon from "@mui/icons-material/Delete";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  Autocomplete,
  Button,
  Paper,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { IMember } from "../../../../models/members";
import { HTMLAttributes } from "react";

const translationPath = "components.boardHeader.member.";

const AutoCompleteProfile = ({
  profilesOptions,
  updateHandler,
  removeHandler,
  member,
  user,
  dLoading,
}: {
  profilesOptions: ISelectType[];
  updateHandler: ({
    ticketProfileId,
  }: {
    ticketProfileId: string;
  }) => Promise<void>;
  removeHandler: (id: string) => Promise<void>;
  member: IMember;
  user: IUser;
  dLoading: boolean;
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const { t } = useTranslation();

  const isMemberEqualUser = member.user.id === user.id;

  const paperOptions = (options: HTMLAttributes<HTMLElement> | any) => {
    const { containerProps, children } = options;
    return (
      <Paper {...containerProps}>
        {children}
        <Button
          startIcon={
            dLoading ? undefined : isMemberEqualUser ? (
              <LogoutIcon />
            ) : (
              <DeleteIcon />
            )
          }
          size="medium"
          fullWidth
          onMouseDown={(e) => {
            e.preventDefault();
            removeHandler(member.id);
          }}
        >
          {dLoading
            ? t(translationPath + "removing_board")
            : isMemberEqualUser
            ? t(translationPath + "leave_board_option")
            : t(translationPath + "remove_user_option")}
        </Button>
      </Paper>
    );
  };

  return (
    <Autocomplete
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      size="small"
      options={profilesOptions}
      fullWidth
      sx={{ width: matches ? 220 : "100%" }}
      defaultValue={member.ticketProfile}
      onChange={(event, value) => {
        if (value) {
          updateHandler({
            ticketProfileId: value.id,
          });
        }
      }}
      clearIcon={false}
      PaperComponent={paperOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          // onChange={(e) => setValue(e.target.value)}
          fullWidth
        />
      )}
    />
  );
};

export default AutoCompleteProfile;
