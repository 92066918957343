import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { memo, useCallback } from "react";

import Property from "../../Property";

interface ICardPeriodProps {
  created?: string;
  startDate?: string;
  finishDate: string | null;
}

const translationPath = "components.cardPeriod.";

const CardPeriod: React.FC<ICardPeriodProps> = ({
  created,
  finishDate,
  startDate,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const formatDate = useCallback(
    (date: string) =>
      new Date(date)
        .toLocaleString(language, {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: undefined,
        })
        .replaceAll(",", ""),
    [language]
  );

  return (
    <Stack flexDirection={"row"} gap={2}>
      {created && (
        <Property
          content={
            <Typography color={(t) => t.palette.text.primary} fontSize={14}>
              {formatDate(created)}
            </Typography>
          }
          label={t(translationPath + "created")}
        />
      )}

      {startDate && (
        <Property
          content={
            <Typography color={(t) => t.palette.text.primary} fontSize={14}>
              {formatDate(startDate)}
            </Typography>
          }
          label={t(translationPath + "started")}
        />
      )}

      {finishDate && (
        <Property
          content={
            <Typography color={(t) => t.palette.text.primary} fontSize={14}>
              {formatDate(finishDate)}
            </Typography>
          }
          label={t(translationPath + "finish_date")}
        />
      )}
    </Stack>
  );
};

export default memo(CardPeriod);
