import { Grid } from "@mui/material";
import { ICardMember } from "../../../models/card-members";
import AvatarStack from "../../Board/Header/AvatarStack";
import { memo } from "react";

interface IMembersProps {
  ticketMembers: ICardMember[];
}
const Members: React.FC<IMembersProps> = ({ ticketMembers }) => {
  if (ticketMembers.length === 0) {
    return <></>;
  }

  return (
    <Grid item xs={"auto"} justifySelf={"end"}>
      <AvatarStack
        members={ticketMembers.map((user) => ({
          id: user.user.id,
          name: user.user.fullName,
        }))}
        max={4}
        size={25}
      />
    </Grid>
  );
};
export default memo(Members);
