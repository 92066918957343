import { Box, Typography } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";

import { useSettingsMenu } from "../../../store/context/settingsMenu-context";

const SettingsMenuBody = () => {
  const { page } = useSettingsMenu();

  const { t } = useTranslation();

  if (page) {
    return <Box height={'calc(100% - 50px)'}>{page.content}</Box>;
  }

  return <Typography  color={(t) => t.palette.text.primary}>{t("page.board.settings.noSelected")}</Typography>;
};

export default memo(SettingsMenuBody);
