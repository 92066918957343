import { memo } from "react";
import { Draggable } from "react-beautiful-dnd";

import { useNavigate } from "react-router-dom";
import { IGetTicket } from "../../../models/ticket";
import Task from "./Task";
import useBoardRole from "../../../hooks/useBoardRole";

interface ITaskProps extends IGetTicket {
  children?: any;
  index: number;
  disableDrag?: boolean;
}

const TaskDraggable: React.FC<ITaskProps> = (props) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`card/${props.id}`);
  };

  const permission = useBoardRole("2");

  return (
    <Draggable
      draggableId={props.id}
      index={props.index}
      isDragDisabled={!permission || props.disableDrag}
    >
      {({ draggableProps, dragHandleProps, innerRef }, snapshot) => (
        <div {...draggableProps} {...dragHandleProps} ref={innerRef}>
          <Task
            {...props}
            onClick={handleClick}
            isDragging={snapshot.isDragging}
          />
        </div>
      )}
    </Draggable>
  );
};

export default memo(TaskDraggable);
