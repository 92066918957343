import {
  Avatar,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import { getInitials, useFormContext } from "@4uhub/lib4uhub";
import { ChangeEvent, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { TFilterSchema } from "../filterSchema";

const SystemUser = () => {
  const { setValue, watch } = useFormContext<TFilterSchema>();

  const createdByIds = watch("createdBy");

  const systemId = "00000000-0000-0000-0000-000000000000";

  const checked = useMemo(
    () => createdByIds.includes(systemId),
    [createdByIds]
  );

  const { t } = useTranslation();

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      return setValue("createdBy", [...createdByIds, systemId], {
        shouldDirty: true,
      });
    }
    if (!checked) {
      return setValue(
        "createdBy",
        createdByIds.filter((id) => id !== systemId),
        {
          shouldDirty: true,
        }
      );
    }
  };

  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={handleChange} />}
      label={
        <Stack direction={"row"} gap={1} alignItems={"center"}>
          <Avatar
            sx={(t) => ({
              width: 23,
              height: 23,
              backgroundColor: t.palette.primary.main,
              fontSize: 10,
            })}
          >
            {getInitials("Sistema")}
          </Avatar>

          <Typography variant="body2">
            {t("components.boards.filters.openedBySystem")}
          </Typography>
        </Stack>
      }
    />
  );
};

export default SystemUser;
