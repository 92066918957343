import { Button, Menu, MenuItem } from "@mui/material";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";

import AddColumnModal from "./AddColumn/AddColumnModal";
import AddCardModal from "../Kamban/Column/AddCard/AddCardModal";
import useBoardRole from "../../hooks/useBoardRole";

const AddButton: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [AddColumnOpen, setAddColumnSetOpen] = useState(false);

  const [AddTaskOpen, setAddTaskSetOpen] = useState(false);

  const addPermission = useBoardRole("1");

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //Task
  const handleAddTaskOpen = () => {
    setAddTaskSetOpen(open);
  };

  const handleAddTaskClose = () => {
    setAddTaskSetOpen(false);
  };

  // COlumn
  const handleAddColumnOpen = () => {
    setAddColumnSetOpen(open);
  };

  const handleAddColumnClose = () => {
    setAddColumnSetOpen(false);
  };

  const onAddHandler = () => {
    handleAddTaskClose();
  };

  const { t } = useTranslation();

  return (
    <>
      <AddColumnModal open={AddColumnOpen} onClose={handleAddColumnClose} />
      <AddCardModal
        open={AddTaskOpen}
        onClose={handleAddTaskClose}
        onAdd={onAddHandler}
        isTable
      />
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="contained"
        color="inherit"
      >
        Add
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={handleAddTaskOpen}>Card</MenuItem>
        {addPermission && <MenuItem onClick={handleAddColumnOpen}>
          {t("components.column.addColumn.addColumn")}
        </MenuItem>}
      </Menu>
    </>
  );
};
export default memo(AddButton);
