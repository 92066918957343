import { stringToColor } from "@4uhub/lib4uhub";
import { Grid, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { memo } from "react";

import { IGetWorksSpace } from "../../models/workspaces";

const WorspaceItem: React.FC<IGetWorksSpace> = ({
  name,
  ticketWorkspaceSequence,
}) => {
  const backgroundColor = stringToColor(name);

  const navigate = useNavigate();

  return (
    <Grid item sm={2} xs={6}>
      <Stack
        p={3}
        onClick={() => navigate(`w/${ticketWorkspaceSequence}/boards`)}
        sx={(t) => ({
          backgound: backgroundColor,
          backgroundImage: `linear-gradient(180deg, ${backgroundColor},${
            t.palette.augmentColor({
              color: {
                main: backgroundColor,
              },
            }).light
          })`,
          height: 104,
          borderRadius: t.shape.borderRadius / 10,
          "&:hover": {
            cursor: "pointer",
            opacity: 0.8,
          },
          alignItems: "start",
        })}
      >
        <Typography
          color={(t) => t.palette.getContrastText(backgroundColor)}
          width={"100%"}
          textOverflow={"ellipsis"}
          overflow={"hidden"}
        >
          {name}
        </Typography>
      </Stack>
    </Grid>
  );
};

export default memo(WorspaceItem);
