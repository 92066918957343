import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import { IconButton, Stack, Typography } from "@mui/material";
import { memo } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const ServiceListMessageTicketInfo = memo(
  ({
    ticketProtocol,
    ticketCreated,
    ticketId,
  }: {
    ticketProtocol: string;
    ticketCreated: string;
    ticketId: string;
  }) => {
    const { eI, ticketWorkspaceSequence, boardId } = useParams();

    const navigate = useNavigate();

    const redirectToTicket = (ticketId: string) => {
      if (!ticketId) return;
      navigate(
        `/e/${eI}/w/${ticketWorkspaceSequence}/boards/${boardId}/card/${ticketId}`
      );
    };

    return (
      <Stack
        direction="row"
        justifyContent="center"
        sx={{
          marginBottom: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={(t) => ({
            backgroundColor:
              t.palette.grey[t.palette.mode === "light" ? 500 : 800],
            color: t.palette.grey[100],
            p: 1,
            mt: 1,
            borderRadius: 1,
            boxShadow: 1,
            height: "35px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: 0.5,
            whiteSpace: "nowrap",
            overflow: "hidden",
          })}
        >
          <Typography variant="subtitle2" color="inherit" sx={{ pt: 0.2 }}>
            #{ticketProtocol} - {new Date(ticketCreated).toLocaleDateString()}
          </Typography>
          <IconButton
            size="small"
            onClick={() => redirectToTicket(ticketId)}
            sx={{
              p: 0,
              color: "inherit",
            }}
          >
            <OpenInNewRoundedIcon fontSize="small" />
          </IconButton>
        </Stack>
      </Stack>
    );
  }
);
