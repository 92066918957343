import { useTranslation } from "react-i18next";
import { Loading, useRole } from "@4uhub/lib4uhub";
import { memo } from "react";

import MenuSection from "../../UI/MenuSection";
import BoardAdd from "../AddBoard/BoardAdd";
import { useWorkspaceContext } from "../../../store/context/workspace-context";
import MenuBoard from "./MenuBoard";

const translationPath = "components.menuBoards.";

const UserBoards: React.FC = () => {
  const createPermission = useRole(["Create"]);

  const { boards, loading: boardsLoading } = useWorkspaceContext();

  const { t } = useTranslation();

  return (
    <MenuSection
      title={t(translationPath + "your_boards")}
      rightContent={createPermission ? <BoardAdd isView={false} /> : <></>}
    >
      {!boardsLoading &&
        boards.map((b) => (
          <MenuBoard name={b.name} to={b.id + "?status=1"} key={b.id} />
        ))}
      {boardsLoading && <Loading sx={{ position: "relative" }} size={30} />}
    </MenuSection>
  );
};

export default memo(UserBoards);
