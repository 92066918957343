import { Grid, Typography } from "@mui/material";
import { memo, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import useTicket from "../../../hooks/useTask";
import { IOtherInfos } from "../../../models/ticket";
import { SignalRContext } from "../../SignalR/SignalRProvider";
import { CardExternalInfo } from "../CardExternalInfo/CardExternalInfo";
import Property from "../Property";
import CardOrigin from "./CardOrigin/CardOrigin";
import CardPeriod from "./CardPeriod/CardPeriod";
import CustomerServiceType from "./CustomerServiceType/CustomerServiceType";
import CardRequester from "./Requester/CardRequester";
import CardStatus from "./Status/CardStatus";
import TicketProtocol from "./TicketProtocol";
import TicketSequential from "./TicketSequential";
import CardType from "./Type/CardType";
import SalesContractInfo from "./SalesContractInfo";

const CardOtherInfo = () => {
  const {
    ticket: {
      protocolNumber,
      ticketSequential,
      created,
      finishDate,
      startDate,
      id,
      status,
      ticketCustomerServiceType,
      ticketOriginType,
      individualRequester,
      type,
      notIdentifiedName,
      ticketOrigins,
      ticketRelationships,
    },
    onEditRequester,
    onEditCustomerServiceType,
    onGetWhatsappNumber,
    loading,
  } = useTicket();

  const [whatsappNumber, setWhatsappNumber] = useState<string>(
    ticketOrigins.length > 0 ? ticketOrigins[0].phoneNumber : ""
  );
  const [whatsappName, setWhatsappName] = useState<string>(
    ticketOrigins.length > 0 && ticketOrigins[0].name
      ? ticketOrigins[0].name
      : ""
  );

  const { t } = useTranslation();

  SignalRContext.useSignalREffect(
    "UpdateTicketOrigin", // Your Event Key
    (userId: string, data: IOtherInfos) => {
      console.log("UpdateTicketOrigin", data);
      setWhatsappNumber(data.phoneNumber);
      setWhatsappName(data.name);
    },
    []
  );

  useEffect(() => {
    onGetWhatsappNumber(whatsappNumber);
  }, [whatsappNumber, onGetWhatsappNumber]);

  return (
    <>
      <Grid container direction={"row"} gap={2}>
        <Grid item container direction={"row"} xs={12} gap={2}>
          <CardOrigin
            id={id}
            loading={loading}
            ticketOriginType={ticketOriginType}
          />
          <CardType id={id} type={type} loading={loading} />
          <TicketProtocol protocolNumber={protocolNumber} />
          <CustomerServiceType
            onEditCustomerServiceType={onEditCustomerServiceType}
            id={id}
            loading={loading}
            ticketCustomerServiceType={ticketCustomerServiceType}
          />
        </Grid>
        <Grid item container direction={"row"} xs={12} gap={2}>
          <CardStatus id={id} status={status} loading={loading} />
          <TicketSequential sequential={ticketSequential} />
          <SalesContractInfo
            id={id}
            ticketRelationships={ticketRelationships}
          />
        </Grid>
        <CardRequester
          id={id}
          loading={loading}
          individualRequester={individualRequester}
          onEditRequester={onEditRequester}
          notIdentifiedName={notIdentifiedName}
        />
        {whatsappName.length > 0 && (
          <Property
            label={t("page.card.whatsappName")}
            loading={loading}
            content={
              <Typography color={(t) => t.palette.text.primary} fontSize={14}>
                {whatsappName}
              </Typography>
            }
          />
        )}
        {whatsappNumber.length > 0 && (
          <Property
            label={t("page.card.whatsappNumber")}
            loading={loading}
            content={
              <Typography color={(t) => t.palette.text.primary} fontSize={14}>
                {whatsappNumber}
              </Typography>
            }
          />
        )}
        <CardPeriod
          created={created}
          startDate={startDate}
          finishDate={finishDate || null}
        />
        <Grid item container direction={"row"} xs={12} gap={2}>
          <CardExternalInfo />
        </Grid>
      </Grid>
    </>
  );
};

export default memo(CardOtherInfo);
