import {
  Box,
  MenuItem,
  Popover,
  PopoverProps,
  Typography,
} from "@mui/material";
import { ISelectType, useFetch } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";

import { editTicketOrigin } from "../../../../services/ticket.service";

interface ICardOriginSelectorProps extends Omit<PopoverProps, "onChange"> {
  onChange: (v?: ISelectType) => void;
  data: ISelectType[];
  ticketId: string;
  selected: string;
}

const CardOriginSelector: React.FC<ICardOriginSelectorProps> = ({
  data,
  onChange,
  open,
  selected,
  ticketId,
  id,
  anchorEl,
  onClose,
}) => {
  const { sendRequest } = useFetch(editTicketOrigin);

  const { t } = useTranslation();

  const handleChange = useCallback(
    async (value: string) => {
      const { success } = await sendRequest({
        ticketOriginTypeId: value,
        id: ticketId,
      });

      if (success) {
        const findedOrigin = data.find((d) => d.id === value);
        onChange(findedOrigin);
      }
    },
    [data, ticketId, sendRequest, onChange]
  );
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Box p={1}>
        <Typography variant="subtitle1">
          {t("page.card.selectOrigin")}
        </Typography>
        {data.map((d) => (
          <MenuItem
            value={d.id}
            key={d.id}
            selected={d.id === selected}
            onClick={() => handleChange(d.id)}
          >
            <Typography>{d.name}</Typography>
          </MenuItem>
        ))}
      </Box>
    </Popover>
  );
};

export default CardOriginSelector;
