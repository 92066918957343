import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import NoMembers from "./NoMembers";
import ActualUser from "./ActualUser";
import MultiSelectMembers from "./MultiSelectMembers";
import SystemUser from "./SystemUser";

interface IMembersFilterProps {
  isCreatedBy?: boolean;
}

const MembersFilter = ({ isCreatedBy = false }: IMembersFilterProps) => {
  const { t } = useTranslation();

  return (
    <Stack width={"100%"}>
      <Typography variant="subtitle2">
        {isCreatedBy
          ? t("components.boards.filters.createdBy")
          : t("components.boards.filters.members")}
      </Typography>
      {!isCreatedBy && <NoMembers />}
      {isCreatedBy && <SystemUser />}
      <ActualUser isCreatedBy={isCreatedBy} />
      <MultiSelectMembers isCreatedBy={isCreatedBy} />
    </Stack>
  );
};

export default MembersFilter;
