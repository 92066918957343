import {
  AutoCompleteCountry,
  Form,
  ISelectCountry,
  useFetch,
} from "@4uhub/lib4uhub";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Grid } from "@mui/material";
import { motion } from "framer-motion";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { countriesList } from "../../../../services/country.service";
import { IPhones } from "../../../../services/whatsapp.service";
import { NoMessagesActions } from "../NoMessagesActions/NoMessagesActions";
import { NoMessagesHeader } from "../NoMessagesHeader/NoMessagesHeader";
import { PhoneNumbers } from "../PhoneNumbers/PhoneNumbers";
import { IWhatsAppNumberProps } from "./models";
import { NumberField } from "./NumberField";
import {
  TWhatsappNumberForm,
  whatsappNumberSchema,
} from "./whatsappNumberSchema";

const translationPath = "page.card.chat.noMessages.whatsappNumber.";

export const WhatsAppNumber = memo(
  ({
    selectedCountry,
    whatsappNumberTexted,
    onChangeStep,
    onGetWhatsappNumber,
    onGetSelectedCountry,
  }: IWhatsAppNumberProps) => {
    const { t } = useTranslation();

    const [countries, setCountries] = useState<ISelectCountry[]>([]);

    const [phoneNumbers, setPhoneNumbers] = useState<IPhones[]>([]);

    const countryOptions = useMemo(() => countries, [countries]);

    const [, setSearchParams] = useSearchParams();

    const { sendRequest } = useFetch(countriesList);

    const methods = useForm<TWhatsappNumberForm>({
      resolver: zodResolver(whatsappNumberSchema),
    });

    const { handleSubmit, watch } = methods;

    const backHandler = useCallback(() => {
      onChangeStep(0);
    }, [onChangeStep]);

    const onSubmit = useCallback(
      (form: TWhatsappNumberForm) => {
        onGetWhatsappNumber(form.country.ddiCode + form.phone);
        setSearchParams((p) => {
          if (form.phoneOption) {
            p.set("phoneOption", form.phoneOption);
          }
          p.set(
            "phone",
            `${form.country.ddiCode}</>${form.phone
              .replaceAll("(", "")
              .replaceAll(")", "")}`
          );
          return p;
        });
        onChangeStep(2);
      },
      [onChangeStep, onGetWhatsappNumber, setSearchParams]
    );

    const fetchCountries = useCallback(async () => {
      const { data, success } = await sendRequest(null);
      if (data && success) {
        setCountries(data);
      }
    }, [sendRequest]);

    useEffect(() => {
      fetchCountries();
    }, [fetchCountries]);

    const changeCountryHandler = useCallback(
      (value: ISelectCountry | null) => {
        onGetSelectedCountry(value);
      },
      [onGetSelectedCountry]
    );

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, ease: [0.6, -0.05, 0.01, 0.99] }}
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Form {...methods}>
          <Box
            component="form"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
            sx={(t) => ({
              p: 2,
              width: "50%",
              height: "fit-content",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: t.palette.background.default,
              borderRadius: 2,
            })}
          >
            <NoMessagesHeader
              title={t(translationPath + "title")}
              subtitle={t(translationPath + "subtitle")}
            />
            {countryOptions.length > 0 && (
              <Grid container spacing={1}>
                <PhoneNumbers phoneNumbers={phoneNumbers} />
                <Grid item xs={12} sm={4}>
                  <AutoCompleteCountry
                    name="country"
                    label={t(translationPath + "country")}
                    size="small"
                    clearIcon={false}
                    getOptionLabel={(option) =>
                      option.code + " +" + option.ddiCode
                    }
                    disable={
                      !!(watch("phoneOption") && watch("phoneOption") !== "1")
                    }
                    onValueChange={changeCountryHandler}
                    options={countryOptions}
                  />
                </Grid>
                <NumberField
                  countries={countryOptions}
                  onSelectPhoneNumbers={setPhoneNumbers}
                  country={selectedCountry}
                  whatsappNumberTexted={whatsappNumberTexted}
                />
              </Grid>
            )}
            <NoMessagesActions onCancel={backHandler} />
          </Box>
        </Form>
      </motion.div>
    );
  }
);
