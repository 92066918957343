import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGetWorksSpace } from "../../models/workspaces";

interface IWorspaceState {
  workspaces: IGetWorksSpace[];
  loading: boolean;
}

const WORKSPACES_INITIAL_STATE: IWorspaceState = {
  workspaces: [],
  loading: false,
};

export const slice = createSlice({
  name: "workspaces",
  initialState: WORKSPACES_INITIAL_STATE,
  reducers: {
    saveWorkspaces(
      state: IWorspaceState,
      action: PayloadAction<IGetWorksSpace[]>
    ) {
      return { ...state, workspaces: action.payload };
    },
    addWorkspace(state: IWorspaceState, action: PayloadAction<IGetWorksSpace>) {
      return { ...state, workspaces: [action.payload, ...state.workspaces] };
    },
    removeWorkspace(state: IWorspaceState, action: PayloadAction<string>) {
      return {
        ...state,
        workspaces: state.workspaces.filter((w) => w.id !== action.payload),
      };
    },
    setLoadinState(state: IWorspaceState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
      return state;
    },
    editWorskspace(
      state: IWorspaceState,
      action: PayloadAction<{ data: IGetWorksSpace; id: string }>
    ) {
      return {
        ...state,
        workspaces: state.workspaces.map((w) => {
          if (w.id === action.payload.id) {
            w = { ...w, ...action.payload.data };
          }
          return w;
        }),
      };
    },
  },
});

export const workspacesSliceActions = slice.actions;

export default slice.reducer;
