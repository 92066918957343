import {
  IconButton,
  MenuItem,
  Popover,
  PopoverProps,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useTranslation } from "react-i18next";
import {
  ISelectType,
  useFetch,
  QPJQuickRegister,
  QPFQuickRegister,
  IIndividualList,
  AddIdField,
  ILegalEntityList,
} from "@4uhub/lib4uhub";

import {
  editIndividualCustomer,
  editLegalEntityCustomer,
} from "../../../../../services/ticket.service";

interface ICustomerChangePopoverProps extends Omit<PopoverProps, "onChange"> {
  ticketId: string;
}

const CustomerChangePopover: React.FC<ICustomerChangePopoverProps> = ({
  open,
  id,
  anchorEl,
  onClose,
  ticketId,
}) => {
  const [type, setType] = useState<"PF" | "PJ">();

  const [pf, setPF] = useState<AddIdField<IIndividualList> | null>(null);

  const [pj, setPJ] = useState<AddIdField<ILegalEntityList> | null>(null);

  const { sendRequest: entityRequest } = useFetch(editLegalEntityCustomer);

  const { sendRequest: individualRequest } = useFetch(editIndividualCustomer);

  const { t } = useTranslation();

  const handleClose = useCallback(
    (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
      onClose && onClose(event, reason);
      setType(undefined);
    },
    [onClose]
  );

  const onPFChange = (v: AddIdField<IIndividualList> | null) => {
    setPF(v);
    setPJ(null);
    if (v) {
      individual({ id: v.id, name: v.individualName, code: v.document });
    }
  };

  const onPJChange = (v: AddIdField<ILegalEntityList> | null) => {
    setPJ(v);
    setPF(null);
    if (v) {
      entity({ id: v.id, name: v.corporateName, code: v.document });
    }
  };

  const entity = useCallback(
    async (entity: ISelectType) => {
      const { success } = await entityRequest({
        id: ticketId,
        legalEntityCustomerId: entity.id,
      });

      if (success) {
        handleClose({}, "escapeKeyDown");
      }
    },
    [entityRequest, handleClose, ticketId]
  );

  const individual = useCallback(
    async (requester: ISelectType) => {
      const { success } = await individualRequest({
        id: ticketId,
        individualCustomerId: requester.id,
      });

      if (success) {
        handleClose({}, "escapeKeyDown");
      }
    },
    [handleClose, ticketId, individualRequest]
  );

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      {!type && (
        <Stack p={2}>
          <Typography>{t("page.card.changeCustomer.select")}</Typography>
          <MenuItem onClick={() => setType("PF")}>
            <Typography>{t("page.card.changeCustomer.pf")}</Typography>
          </MenuItem>
          <MenuItem onClick={() => setType("PJ")}>
            <Typography>{t("page.card.changeCustomer.pj")}</Typography>
          </MenuItem>
        </Stack>
      )}
      {type && (
        <Stack p={2}>
          <Stack direction={"row"} alignItems={"center"} gap={2} mb={2}>
            <IconButton
              onClick={() => setType(undefined)}
              size="small"
              sx={{ justifySelf: "flex-start" }}
            >
              <ArrowBackIosIcon fontSize="small" />
            </IconButton>
            <Typography justifySelf={"center"}>
              {t(
                type === "PF"
                  ? "page.card.changeCustomer.pf"
                  : "page.card.changeCustomer.pj"
              )}
            </Typography>
          </Stack>

          {type === "PF" && (
            <QPFQuickRegister
              onChange={onPFChange}
              value={pf}
              label={t("page.card.client")}
            />
          )}

          {type === "PJ" && (
            <QPJQuickRegister
              value={pj}
              onChange={onPJChange}
              label={t("page.card.client")}
            />
          )}
        </Stack>
      )}
    </Popover>
  );
};

export default CustomerChangePopover;
