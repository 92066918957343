import { ISelectType, useFetch } from "@4uhub/lib4uhub";
import {
  Box,
  MenuItem,
  Popover,
  PopoverProps,
  Stack,
  Typography,
} from "@mui/material";
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";

import ServiceTypeIcon from "../../../Kamban/ServiceTypeIcon";
import { editTicketType } from "../../../../services/ticket.service";

interface ICardTypeSelectorProps extends Omit<PopoverProps, "onChange"> {
  selected: string;
  data: ISelectType[];
  ticketId: string;
  onChange: (v: string) => void;
}
const CardTypeSelector: React.FC<ICardTypeSelectorProps> = ({
  anchorEl,
  onClose,
  open,
  id,
  selected,
  data,
  ticketId,
  onChange,
}) => {

  const { sendRequest } = useFetch(editTicketType);

  const { t } = useTranslation();

  const handleChange = useCallback(
    async (value: string) => {
      const { success } = await sendRequest({
        typeId: value,
        id: ticketId,
      });

      if (success) {
        onChange(value);
      }
    },
    [ticketId, sendRequest, onChange]
  );

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Box p={1}>
        <Typography variant="subtitle1">{t("page.card.type")}</Typography>
        {data.map((d) => (
          <MenuItem
            value={d.id}
            key={d.id}
            selected={d.id === selected}
            onClick={() => handleChange(d.id)}
          >
            <Stack gap={1} alignItems={"center"} direction={"row"}>
              <ServiceTypeIcon
                type={d}
                iconProps={{ fontSize: "medium", color: "inherit" }}
              />
              <Typography>{d.name}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </Box>
    </Popover>
  );
};

export default memo(CardTypeSelector);
