import { Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../store/store";
import WorkspacesItem from "../WorkspacesItem";

const translationPath = "components.workspacesPopover.";

const CurrentWorkspacePopover = () => {
  const { t } = useTranslation();

  const { ticketWorkspaceSequence } = useParams();

  const workspaces = useAppSelector((state) => state.workSpaces.workspaces);

  const actualWorkspace = workspaces.find(
    (w) => w.ticketWorkspaceSequence === Number(ticketWorkspaceSequence)
  );

  if(!actualWorkspace){
    return null;
  }

  return (
    <Stack sx={(t) => ({ padding: t.spacing(2) })}>
      <Typography
        sx={(theme) => ({
          marginBottom: theme.spacing(1),
          color:
            theme.palette.mode === "light"
              ? theme.palette.grey[700]
              : theme.palette.grey[400],
        })}
        variant="subtitle2"
      >
        {t(translationPath + "current_workspace")}
      </Typography>
      {actualWorkspace && (
        <WorkspacesItem
          name={actualWorkspace.name}
        />
      )}
    </Stack>
  );
};

export default CurrentWorkspacePopover;
