import { memo, useCallback, useEffect, useState } from "react";
import { AutoComplete, useFetch, useFormContext } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";

import { TMoveSchema } from "./moveSchema";
import { IGetColumn } from "../../../../../models/columns";
import { getColumnsByBoardId } from "../../../../../services/column.serivice";

const SelectList: React.FC = () => {
  const [lists, setLists] = useState<IGetColumn[]>([]);

  const { t } = useTranslation();
  const { control, resetField } = useFormContext<TMoveSchema>();
  const boardId = useWatch({ name: "board.id", control });
  const { sendRequest: getColumn } = useFetch(getColumnsByBoardId);

  const fetchColumns = useCallback(async () => {
    resetField("list");
    setLists([]);

    if (boardId) {
      const { data, success } = await getColumn({
        all: true,
        bId: boardId,
        statusCode: "1",
      });
      if (data && success) {
        setLists(data);
      }
    }
  }, [setLists, boardId, getColumn, resetField]);

  useEffect(() => {
    fetchColumns();
  }, [fetchColumns]);

  return (
    <AutoComplete
      name="list"
      options={lists}
      size="small"
      label={t("page.card.actions.list")}
      getOptionLabel={({ name }) => name}
    />
  );
};

export default memo(SelectList);
