import { Grid, Stack, Tooltip, Typography, alpha } from "@mui/material";
import { memo } from "react";

import { IGetTicketDialog } from "../../../models/ticketDialogs";
import {
  IContactWrapper,
  IContact,
} from "../../../models/ticketDialogContacts";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import TicketDialogMediaFile from "./TicketDialogMediaFile";
import { FormatMessage } from "../../../utils/FormatMessage";
import { PhotoProvider } from "react-photo-view";
import { Status } from "./Status/Status";
import SharedContacts from "./SharedContacts/SharedContacts";
import {
  ILocation,
  ILocationWrapper,
} from "../../../models/ticketDialogLocation";
import SharedLocation from "./Location/SharedLocation";

const formatDate = (dateS: string) => {
  const date = new Date(dateS);

  let hours = "";
  let minutes = "";
  let seconds = "";

  if (date.getHours() < 10) {
    hours = "0" + date.getHours().toString();
  } else {
    hours = date.getHours().toString();
  }

  if (date.getMinutes() < 10) {
    minutes = "0" + date.getMinutes().toString();
  } else {
    minutes = date.getMinutes().toString();
  }

  if (date.getSeconds() < 10) {
    seconds = "0" + date.getSeconds().toString();
  } else {
    seconds = date.getSeconds().toString();
  }

  return `${hours}:${minutes}:${seconds}`;
};

const Message: React.FC<IGetTicketDialog> = ({
  message,
  ticketDialogMediaFiles,
  whoSendType,
  id,
  dateSend,
  isDelivered,
  deliveryDate,
  isRead,
  readDate,
  metadata,
  deliveryReason,
}) => {
  const me = whoSendType.code === "1" || whoSendType.code === "3";
  const isBot = whoSendType.code === "3";

  const metadataHasContacts = metadata
    ? metadata?.startsWith('{"Contacts"')
    : false;

  let contacts: IContact[] | null = null;

  if (metadataHasContacts) {
    const parsedWrapper: IContactWrapper = JSON.parse(String(metadata));
    contacts = JSON.parse(parsedWrapper.Contacts);
  }

  const metadataHasLocation = metadata
    ? metadata?.startsWith('{"Location"')
    : false;

  let location: ILocation | null = null;
  if (metadataHasLocation) {
    const parsedWrapper: ILocationWrapper = JSON.parse(String(metadata));
    location = parsedWrapper.Location;
  }

  return (
    <Stack
      direction={"row"}
      alignSelf={me ? "end" : "start"}
      sx={(t) => ({
        width: "fit-content",
        maxWidth: "80%",
        [t.breakpoints.down("md")]: {
          maxWidth: "100%",
        },
      })}
      gap={2}
    >
      {isBot && (
        <Tooltip title={"Esta mensagem foi envida pelo Bot"}>
          <SmartToyIcon color="action" />
        </Tooltip>
      )}
      <Stack
        direction={"row"}
        width={"fit-content"}
        sx={(t) => ({
          backgroundColor: me
            ? alpha(t.palette.primary.main, 0.5)
            : t.palette.background.paper,
          padding: 1,
          paddingBottom: 0.5,
          borderBottomLeftRadius: t.shape.borderRadius,
          boxShadow: t.shadows[1],
          borderBottomRightRadius: t.shape.borderRadius,
          borderTopRightRadius: me ? 0 : t.shape.borderRadius,
          borderTopLeftRadius: !me ? 0 : t.shape.borderRadius,
        })}
      >
        <Stack gap={1}>
          {metadataHasContacts && <SharedContacts contacts={contacts!} />}
          {metadataHasLocation && <SharedLocation location={location!} />}
          {ticketDialogMediaFiles && (
            <PhotoProvider maskOpacity={0.5} loop>
              <Grid
                container
                wrap={"wrap"}
                direction={"row"}
                spacing={1}
                flexBasis={"20%"}
                maxWidth={400}
              >
                {ticketDialogMediaFiles.map((t, i) => (
                  <Grid
                    item
                    key={i}
                    xs={
                      ticketDialogMediaFiles.length > 1
                        ? 12 / ticketDialogMediaFiles.length
                        : 12
                    }
                  >
                    <TicketDialogMediaFile {...t} key={t.id} me={me} />
                  </Grid>
                ))}
              </Grid>
            </PhotoProvider>
          )}
          {message && (
            <Typography
              variant="body1"
              paragraph={false}
              component={"span"}
              sx={(t) => ({
                color: me
                  ? t.palette.primary.contrastText
                  : t.palette.text.primary,

                whiteSpace: "break-spaces",
                wordWrap: "break-word",
                overflowWrap: "anywhere",
                fontSize: 14.2,
              })}
            >
              {FormatMessage(message)}
            </Typography>
          )}
        </Stack>

        <Typography
          variant="caption"
          alignSelf={"end"}
          ml={1}
          mt={1}
          sx={(t) => ({
            color: me ? t.palette.grey[200] : t.palette.grey[700],
          })}
        >
          {formatDate(dateSend)}
        </Typography>

        {me && (
          <Status
            id={id}
            dateSend={dateSend}
            deliveryDate={deliveryDate}
            isDelivered={isDelivered}
            isRead={isRead}
            readDate={readDate}
            deliveryReason={deliveryReason}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default memo(Message);
