import { Checkbox } from "@mui/material";
import { memo, useCallback } from "react";
interface ITagItemActionsProps {
  isChecked: boolean;
  tagId: string;
  addTagHandler: () => Promise<void>;
  removeHandler: () => Promise<void>;
}

const TagCheckBox = ({
  isChecked,
  addTagHandler,
  removeHandler,
}: ITagItemActionsProps) => {
  
  const handleChange = useCallback(() => {
    if (!isChecked) {
      addTagHandler();
    } else {
      removeHandler();
    }
  }, [isChecked, removeHandler, addTagHandler]);

  return (
    <Checkbox
      size="small"
      checked={isChecked}
      onChange={handleChange}
      sx={{ width: "fit-content", height: "fit-content", padding: 0.5 }}
    />
  );
};

export default memo(TagCheckBox);
