import { Skeleton, Stack } from "@mui/material";
import { memo } from "react";

export const ServiceListMessageSkeleton = memo(() => {
  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        sx={{
          marginBottom: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Stack justifyContent="center" alignItems="center">
          <Skeleton
            variant="rectangular"
            width={60}
            height={26}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 1,
              height: "35px",
              display: "flex",
              bgcolor: "grey.300",
            }}
          />
        </Stack>
      </Stack>
      {Array.from({ length: 2 }).map((_, index) => (
        <Stack
          key={index}
          direction="row"
          justifyContent={index % 2 === 0 ? "flex-start" : "flex-end"}
          sx={{ mb: 2 }}
        >
          <Skeleton
            variant="rectangular"
            width="60%"
            height={35}
            sx={{
              borderRadius: 1,
              bgcolor: "grey.300",
              ...(index % 2 === 0
                ? { marginRight: "auto" }
                : { marginLeft: "auto" }),
            }}
          />
        </Stack>
      ))}
    </>
  );
});
