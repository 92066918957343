import { createSignalRContext } from "react-signalr";
import { memo, useState } from "react";
import { AppLoading, useNotificationContext } from "@4uhub/lib4uhub";

import { GetActualToken } from "../../axios/axios-config";

export const SignalRContext = createSignalRContext({
  shareConnectionBetweenTab: true,
});

const SignalRProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [loading, setLoading] = useState(true);

  const { setMessage } = useNotificationContext();

  return (
    <SignalRContext.Provider
      automaticReconnect
      url={process.env.REACT_APP_SIGNALR + ""}
      onError={async (error) => {
        setMessage({
          message: error?.message || "Something went wrong",
          type: "error",
        });
      }}
      withCredentials={false}
      onBeforeClose={async (conection) => {
        conection.stop();
      }}
      onReconnect={async (conection) => {
        setMessage({
          message: "Reconectado com sucesso",
          type: "info",
        });
      }}
      onOpen={async (conection) => {
        try {
          await conection.invoke("Login", {
            token: GetActualToken(),
          });
          setLoading(false);
          setMessage({
            message: "Conectado com sucesso",
            type: "info",
          });
        } catch (error) {
          setMessage({
            message: "Something went wrong",
            type: "error",
          });
          setLoading(false);
        }
      }}
    >
      {loading ? <AppLoading /> : children}
    </SignalRContext.Provider>
  );
};

export default memo(SignalRProvider);
