import { ISelectCountry } from "@4uhub/lib4uhub";
import { useCallback } from "react";

export const usePhone = (countries: ISelectCountry[]) => {
  const getDDI = useCallback(
    (telefone: string): ISelectCountry | null => {
      for (let i = 3; i >= 1; i--) {
        const potentialDDI = telefone.slice(0, i);
        const foundDDI = countries.find(
          (entry) => entry.ddiCode === potentialDDI
        );
        if (foundDDI) {
          return foundDDI;
        }
      }
      return null;
    },
    [countries]
  );

  const getPhoneNumberWithoutDDI = useCallback(
    (telefone: string): string | null => {
      for (let i = 3; i >= 1; i--) {
        const potentialDDI = telefone.slice(0, i);
        const foundDDI = countries.find(
          (entry) => entry.ddiCode === potentialDDI
        );
        if (foundDDI) {
          return telefone.slice(i);
        }
      }
      return null;
    },
    [countries]
  );

  return { getDDI, getPhoneNumberWithoutDDI };
};
