import { memo } from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { EUserType } from "./TaskHeader";

const UserType: React.FC<{ type: EUserType }> = ({ type }) => {
  const { t } = useTranslation();

  let label = t("page.board.createCard.not_identified");

  switch (type) {
    case EUserType.PF:
      label = t("page.board.createCard.pf");
      break;
    case EUserType.PJ:
      label = t("page.board.createCard.pj");
      break;
  }

  return (
    <Typography
      variant="caption"
      color={(t) =>
        type === EUserType.NI ? t.palette.error.main : t.palette.primary.main
      }
    >
      {label}
    </Typography>
  );
};

export default memo(UserType);
