import { Typography } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const translationPath = "components.ticketTagsList.";

const CardTagTitle = () => {
  const { t } = useTranslation();

  return (
    <Typography
      variant="caption"
      fontWeight={"bold"}
      color={(t) => t.palette.text.primary}
      mb={0.2}
    >
      {t(translationPath + "tags")}
    </Typography>
  );
};

export default memo(CardTagTitle);
