import { Stack, Typography } from "@mui/material";
import { Button, DomainProvider } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";

import WorkspaceList from "../components/Home/WorkspaceList";
import ArchivedWorkspaces from "../components/Home/ArchivedWorkspaces/ArchivedWorkspaces";

const Home = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);


  const handleClose = useCallback(() => setOpen(false), []);
  return (
    <DomainProvider
      value={{ domainName: "ticketworkspace" }}
      actions={["Read"]}
    >
      <Stack
        sx={(t) => ({
          [t.breakpoints.up("md")]: {
            marginX: t.spacing(20),
          },
          marginY: t.spacing(5),
          gap: 2,
          marginX: 2,
        })}
      >
        <Typography
          variant="h6"
          fontWeight={"bold"}
          mb={1}
          color={(t) => t.palette.text.primary}
        >
          {t("page.home.Workspaces")}
        </Typography>
        <WorkspaceList />
        <Button
          sx={{ alignSelf: "flex-start", marginTop: 2.5 }}
          variant="contained"
          color="inherit"
          onClick={() => setOpen(true)}
        >
          {t("page.home.seeArchived")}
        </Button>
        <ArchivedWorkspaces open={open} onClose={handleClose} />
    
      </Stack>
    </DomainProvider>
  );
};

export default Home;
