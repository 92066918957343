import { Button, Form } from "@4uhub/lib4uhub";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";
import { zodResolver } from "@hookform/resolvers/zod";

import {
  TEditDefaultStatusForm,
  defaultStatusChema,
} from "./DefaultStatusSchema";
import SelectStatus from "../../../AddColumn/SelectStatus";
import useColumn from "../../../../../../hooks/useColumn";
import { useEffect } from "react";
interface IDefaultStatusFormProps {
  loading?: boolean;
  onSubmit: (data: TEditDefaultStatusForm) => void;
}

const DefaultStatusForm: React.FC<IDefaultStatusFormProps> = ({
  loading,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const {
    data: { status },
  } = useColumn();

  const methods = useForm<TEditDefaultStatusForm>({
    resolver: zodResolver(defaultStatusChema),
  });
  const { setValue } = methods;

  useEffect(() => {
    setValue("status", status);
  }, [status, setValue]);

  return (
    <Form {...methods}>
      <Stack gap={1} padding={1} minWidth={200}>
        <Typography>
          {t("components.column.menu.changeDefaultStatus.change")}
        </Typography>

        <SelectStatus />

        <Button
          variant="outlined"
          onClick={methods.handleSubmit(onSubmit)}
          loading={loading}
        >
          {t("components.column.menu.changeDefaultStatus.button")}
        </Button>
      </Stack>
    </Form>
  );
};

export default DefaultStatusForm;
