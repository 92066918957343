import { memo } from "react";

import ChatProvider from "../../store/context/chat-context";
import { ChatBody } from "./ChatBody";

const Chat = () => {
  return (
    <ChatProvider>
      <ChatBody />
    </ChatProvider>
  );
};

export default memo(Chat);
