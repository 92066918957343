import { Box, Typography } from "@mui/material";
import { memo } from "react";
import { ISearchResultAditionalDataProps } from "./models";

export const SearchResultAditionalData = memo(
  ({ boardName, stepName }: ISearchResultAditionalDataProps) => {
    return (
      <Box sx={{ ml: 4, display: "flex", gap: 0.5 }}>
        <Typography
          variant="body2"
          sx={(t) => ({ color: t.palette.grey[600] })}
        >
          {boardName ? boardName : ""}
        </Typography>
        <Typography
          variant="body2"
          sx={(t) => ({ color: t.palette.grey[600] })}
        >
          {stepName ? `- ${stepName}` : ""}
        </Typography>
      </Box>
    );
  }
);
