import { MouseEventHandler, useMemo } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Box, Button, Stack, Typography } from "@mui/material";

import Action from "../../../Kamban/Column/ColumnHeader/Action";
import useBoard from "../../../../hooks/useBoard";
import { useTranslation } from "react-i18next";

interface IFiltersButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const FiltersButton: React.FC<IFiltersButtonProps> = ({ onClick }) => {
  const {
    filter: { keyword, endDate, initialDate, membersIds, status, createdBy },
    clearAllFilters,
    cardsLength,
  } = useBoard();

  const { t } = useTranslation();

  const hasFilter = useMemo(
    () =>
      keyword !== "" ||
      membersIds.length > 0 ||
      initialDate ||
      endDate ||
      status !== "1" ||
      createdBy.length > 0,
    [keyword, membersIds, initialDate, endDate, status, createdBy]
  );

  return (
    <Stack direction={"row"}>
      <Action
        title={t("components.boards.filters.filters")}
        icon={<FilterAltIcon />}
        variant={hasFilter ? "contained" : "text"}
        color="inherit"
        sx={(t) => ({
          borderTopRightRadius: hasFilter ? 0 : undefined,
          borderBottomRightRadius: hasFilter ? 0 : undefined,
          color: hasFilter ? t.palette.text.primary : t.palette.primary.main,
        })}
        startIcon={<FilterAltIcon />}
        endIcon={
          hasFilter ? (
            <Box
              sx={(t) => ({
                backgroundColor: t.palette.primary.main,
                borderRadius: "60px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 25,
                width: 25,
                color: t.palette.common.white,
              })}
            >
              <Typography fontSize={12}>{cardsLength}</Typography>
            </Box>
          ) : undefined
        }
        onClick={onClick}
      />

      {hasFilter && (
        <Button
          variant="contained"
          sx={(t) => ({
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            color: t.palette.text.primary,
          })}
          color="inherit"
          onClick={clearAllFilters}
          size="small"
        >
          {t("components.boards.filters.clearAll")}
        </Button>
      )}
    </Stack>
  );
};

export default FiltersButton;
