import { useFetch } from "@4uhub/lib4uhub";
import { Box, Stack } from "@mui/material";
import { useCallback, useEffect } from "react";

import HistoryComments from "./HistoryComments";
import HistoriesList from "./HIstoriesList";
import { loadHistories } from "../../../services/cardHistories.service";
import { useTicketHistory } from "../../../store/context/card-histories-context";
import useTicket from "../../../hooks/useTask";

interface ICardHistoriesProps {
  detail?: boolean;
}

const CardHistories: React.FC<ICardHistoriesProps> = ({ detail = false }) => {
  const { ticket } = useTicket();

  const { loadTicketHistories } = useTicketHistory();

  const { sendRequest } = useFetch(loadHistories);

  const loadTicketHistory = useCallback(async () => {
    if (ticket?.id) {
      const { data, success } = await sendRequest({
        ticketId: ticket?.id,
        detail,
      });
      if (data && success) {
        loadTicketHistories(data);
      }
    }
  }, [sendRequest, ticket?.id, loadTicketHistories, detail]);

  useEffect(() => {
    loadTicketHistory();
  }, [loadTicketHistory]);

  return (
    <Box height={"100%"} overflow={"hidden"}>
      <Stack
        overflow={"auto"}
        sx={(t) => ({
          "&::-webkit-scrollbar": {
            width: "0.4em",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            backgroundColor:
              t.palette.mode === "light"
                ? t.palette.grey[400]
                : t.palette.grey[900],
            borderRadius: 5,
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor:
              t.palette.mode === "light"
                ? t.palette.grey[200]
                : t.palette.grey[700],
            borderRadius: 5,
          },
          margin: 2,
          paddingRight: 2,
        })}
        height={"calc(100% - 32px)"}
        gap={5}
      >
        {!detail && <HistoryComments />}

        <HistoriesList />
      </Stack>
    </Box>
  );
};

export default CardHistories;
