import { Box } from "@mui/material";
import { useProtocol } from "../../../../../../store/context/protocol-context";
import DetailBoxContainer from "../DetailBoxContainer/DetailBoxContainer";
import DetailFile from "./DetailFile";
import NoProtocolData from "../NoProtocolData.tsx/NoProtocolData";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const translationPath = "components.detailFiles.";

const DetailFiles = () => {
  const { t } = useTranslation();

  const { selectedProtocol } = useProtocol();

  const mediaFiles = selectedProtocol?.ticketDefaultDocMediaFiles ?? [];

  return (
    <DetailBoxContainer
      icon={<AttachFileIcon fontSize="small" />}
      title={t(translationPath + "files")}
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!selectedProtocol && (
          <NoProtocolData
            message={t(translationPath + "no_protocol_selected")}
          />
        )}
        {selectedProtocol && mediaFiles.length === 0 && (
          <NoProtocolData message={t(translationPath + "no_data")} />
        )}
        {selectedProtocol &&
          mediaFiles.map((media, index) => (
            <DetailFile
              index={index}
              media={media}
              mediaFilesLength={mediaFiles.length}
            />
          ))}
      </Box>
    </DetailBoxContainer>
  );
};

export default memo(DetailFiles);
