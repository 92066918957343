import { memo, useCallback, useState } from "react";
import { StatusDrawer } from "../StatusDrawer/StatusDrawer";
import { Box, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IStatusContainerProps } from "./models";

const translationPath = "components.statusMessage.";

export const StatusContainer = memo(
  ({
    dateSend,
    deliveryDate,
    readDate,
    deliveryReason,
    children,
  }: IStatusContainerProps) => {
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);

    const openHandler = useCallback(() => {
      setOpen(true);
    }, []);

    const closeHandler = useCallback(() => {
      setOpen(false);
    }, []);

    return (
      <>
        <StatusDrawer
          open={open}
          onClose={closeHandler}
          dateSend={dateSend}
          deliveryDate={deliveryDate}
          readDate={readDate}
          deliveryReason={deliveryReason}
        />
        <Tooltip title={t(translationPath + "tooltip")} placement="top">
          <Box
            onClick={openHandler}
            sx={{ ml: 1, alignSelf: "end", cursor: "pointer" }}
          >
            {children}
          </Box>
        </Tooltip>
      </>
    );
  }
);
