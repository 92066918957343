import LogoutIcon from "@mui/icons-material/Logout";
import { Tooltip } from "@mui/material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { removeUserData } from "../../../axios/axios-config";
import IconButton from "../IconButton";

const LogoutButton = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const logoutUser = useCallback(() => {
    removeUserData();
    navigate("/login", { replace: true });
  }, [navigate]);

  return (
    <Tooltip title={t("components.logout")} placement="right">
      <div>
        <IconButton aria-label="apps" onClick={logoutUser} color="inherit">
          <LogoutIcon fontSize="medium" />
        </IconButton>
      </div>
    </Tooltip>
  );
};

export default LogoutButton;
