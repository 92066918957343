import { motion } from "framer-motion";

import { useTicketHistory } from "../../../store/context/card-histories-context";
import History from "./History";

const HistoriesList = () => {
  const { ticketHistories } = useTicketHistory();

  return (
    <motion.div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, ease: [0.6, -0.05, 0.01, 0.99] }}
    >
      {ticketHistories.map((ticketHistory) => (
        <History
          message={ticketHistory.message}
          fullName={ticketHistory.user && ticketHistory.user.fullName}
          key={ticketHistory.id}
          detail={ticketHistory.detail}
          created={ticketHistory.created}
          historyTypeCode={ticketHistory.historyType.code || ""}
        />
      ))}
    </motion.div>
  );
};

export default HistoriesList;
