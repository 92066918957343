import ptBrTranslations from "./pt-br";
import enUsTranslations from "./en-us";
import esTranslations from "./es";

const translations = {
  "pt-BR": ptBrTranslations, // Atente-se a usar as abreviaturas corretas
  "en-US": enUsTranslations, // https://support.mozilla.org/pt-BR/kb/abreviacao-de-localizacao
  es: esTranslations,
};

export default translations;
