import { Button } from "@mui/material";
import { memo, useCallback } from "react";
import { useFetch } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";

import TicketChecklistService from "../../../../services/ticketChecklist.service";
import usePopover from "../../../../hooks/usePopover";
import PopoverDelete from "../../../UI/PopoverDelete";
import useTicket from "../../../../hooks/useTask";

const ticketChecklistService = new TicketChecklistService();

const translationPath = "components.deleteChecklist.";

interface ICardCheckListDeleteProps {
  checklistId: string;
}

const CardChecklistDelete: React.FC<ICardCheckListDeleteProps> = ({
  checklistId,
}) => {
  const { t } = useTranslation();

  const {
    ticket: { status },
  } = useTicket();

  const { anchorEl, id, handleClick, handleClose, open } = usePopover(
    "remove-board-popover"
  );

  const { sendRequest } = useFetch(ticketChecklistService.deleteChecklist);

  const deleteChecklistHandler = useCallback(
    (id: string) => {
      sendRequest(id);
    },
    [sendRequest]
  );

  return (
    <>
      <Button
        variant="contained"
        size="small"
        onClick={handleClick}
        color="inherit"
        disabled={status.code === "3"}
        sx={(t) => ({
          color: t.palette.text.primary,
        })}
      >
        {t(translationPath + "delete")}
      </Button>
      <PopoverDelete
        title={t(translationPath + "title")}
        description={t(translationPath + "description")}
        buttonDescription={t(translationPath + "delete")}
        onDelete={() => deleteChecklistHandler(checklistId)}
        onClose={handleClose}
        open={open}
        anchorEl={anchorEl}
        id={id}
      />
    </>
  );
};

export default memo(CardChecklistDelete);
