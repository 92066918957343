import { Button, useFormContext } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo, useCallback } from "react";
import { useWatch } from "react-hook-form";

import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { useTranslation } from "react-i18next";
import useEvents from "../../../hooks/useEvents";
import useTicket from "../../../hooks/useTask";
import { TEventMovementForm } from "./eventSchema";

const translationPath = "components.events.actions.";

export const EventActions = memo(({ loading }: { loading: boolean }) => {
  const { t } = useTranslation();

  const {
    ticket: { status },
  } = useTicket();

  const { id, onChangeMode } = useEvents();

  const { control } = useFormContext<TEventMovementForm>();

  const endDate = useWatch({ name: "endDate", control });

  const cancelHandler = useCallback(() => {
    onChangeMode("list");
  }, [onChangeMode]);

  return (
    <Grid item sm={12} sx={{ display: "flex", gap: 1 }}>
      <Button
        variant="outlined"
        size="small"
        startIcon={<CloseIcon />}
        onClick={cancelHandler}
      >
        {t(translationPath + "cancel")}
      </Button>
      <Button
        variant="contained"
        size="small"
        type="submit"
        startIcon={<DoneIcon />}
        disabled={!!(id && !endDate) || status.code === "3"}
        loading={loading}
      >
        {t(translationPath + "save")}
      </Button>
    </Grid>
  );
});
