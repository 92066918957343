import { Button } from "@4uhub/lib4uhub";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const BoardNotFound = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      width={"100%"}
      height={"100%"}
    >
      <Typography variant="h5">
        {t("components.BoardNotFound.title")}
      </Typography>
      <img
        src="/page-not-found.png"
        alt="woman looking through binoculars to represent a not found board"
      ></img>
      <Button
        onClick={() =>
          navigate("..", {
            replace: true,
          })
        }
        variant="contained"
      >
        {t("components.BoardNotFound.button")}
      </Button>
    </Stack>
  );
};

export default BoardNotFound;
