import {
  AutoComplete,
  Button,
  FormInput,
  ISelectType,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { TInviteMemberForm } from "./inviteMemberSchema";
import { listUsers } from "../../../../services/users.service";
import { useMembersContext } from "../../../../store/context/members-context";

const translationPath = "components.boardHeader.member.";

const InviteMemberForm = ({
  profilesOptions,
  handleSubmit,
  loading,
}: {
  profilesOptions: ISelectType[];
  handleSubmit: () => void;
  loading: boolean;
}) => {
  const { t } = useTranslation();

  const { setValue } = useFormContext<TInviteMemberForm>();

  const [users, setUsers] = useState<ISelectType[]>([]);

  const { members } = useMembersContext();

  const { sendRequest } = useFetch(listUsers);

  const loadFilteredUsers = useCallback(async () => {
    const { data, success } = await sendRequest(undefined);
    if (data && success) {
      setUsers(data.map((user) => ({ id: user.id, name: user.fullName })));
    }
  }, [sendRequest]);

  const usersList = users.filter(
    (obj1) => !members.some((obj2) => obj2.user.id === obj1.id)
  );

  useEffect(() => {
    loadFilteredUsers();
  }, [loadFilteredUsers]);

  useEffect(() => {
    setValue("profile", profilesOptions[0]);
  }, [setValue, profilesOptions]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={4}>
        <AutoComplete
          name="user"
          label={t(translationPath + "select_user")}
          getOptionLabel={(option) => option.name}
          size="small"
          options={usersList}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormInput
          name="nickName"
          label={t(translationPath + "nickname")}
          fullWidth
          size="small"
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <AutoComplete
          name="profile"
          getOptionLabel={(option) => option.name}
          size="small"
          clearIcon={false}
          options={profilesOptions}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <Button
          variant="contained"
          loading={loading}
          fullWidth
          onClick={handleSubmit}
        >
          {t(translationPath + "invite_user")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default InviteMemberForm;
