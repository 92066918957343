import { ISelectType, useFetch } from "@4uhub/lib4uhub";
import { memo, useCallback, useEffect, useState } from "react";
import { getTicketInternalTemplateByTicketId } from "../../../services/ticketAutomationInternalTemplateTemplate.service";
import useTicket from "../../../hooks/useTask";
import { ITicketInternalTemplateFields } from "../../../models/internal-template";
import { InternalTemplateSelect } from "./InternalTemplateSelect/InternalTemplateSelect";
import { CardInternalTemplateForm } from "./CardInternalTemplateForm/CardInternalTemplateForm";

export const CardInternalTemplate = memo(() => {
  const { ticket, isUserTicketMember } = useTicket();

  const [fields, setFields] = useState<ITicketInternalTemplateFields[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [hasTicketInternalTemplate, setHasTicketInternalTemplate] =
    useState(false);
  const [
    ticketAutomationInternalTemplate,
    setTicketAutomationInternalTemplate,
  ] = useState<ISelectType | null>(null);

  const { sendRequest, loading } = useFetch(
    getTicketInternalTemplateByTicketId
  );

  const loadTicketInternalTemplate = useCallback(async () => {
    if (ticket) {
      const { data, success } = await sendRequest(ticket?.id);
      if (success) {
        if (data?.ticketAutomationInternalTemplate) {
          setFields(data.ticketInternalTemplateFields);
          setHasTicketInternalTemplate(true);
          setTicketAutomationInternalTemplate(
            data.ticketAutomationInternalTemplate
          );
        }
      }
    }
  }, [sendRequest, ticket, setFields]);
  useEffect(() => {
    loadTicketInternalTemplate();
  }, [loadTicketInternalTemplate]);

  return (
    <>
      <InternalTemplateSelect
        ticketId={ticket.id}
        ticketAutomationInternalTemplate={ticketAutomationInternalTemplate}
        loadTicketInternalTemplate={loadTicketInternalTemplate}
        hasTicketInternalTemplate={hasTicketInternalTemplate}
        isUserTicketMember={isUserTicketMember}
        isSubmitting={isSubmitting}
      />
      <CardInternalTemplateForm
        fields={fields}
        loading={loading}
        hasTicketInternalTemplate={hasTicketInternalTemplate}
        isUserTicketMember={isUserTicketMember}
        onIsSubmitting={setIsSubmitting}
      />
    </>
  );
});
