import { useFetch } from "@4uhub/lib4uhub";
import { Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import useFlow from "../../hooks/useFlow";
import { getUserTickets } from "../../services/ticket.service";
import { AddTicketButton } from "./AddTicketButton/AddTicketButton";
import FlowCard from "./FlowCard/FlowCard";

const UserCards: React.FC = () => {
  const { isSelected, userTickets, setUserTickets, openTicket, boardId } =
    useFlow();

  const bottomRef = useRef<HTMLDivElement>(null);

  const { sendRequest } = useFetch(getUserTickets);

  const { t } = useTranslation();

  const fetch = useCallback(async () => {
    const { success, data } = await sendRequest({ TicketChannelId: boardId });

    if (success && data) {
      setUserTickets(data);
    }
  }, [sendRequest, setUserTickets, boardId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <Stack flexGrow={1} pb={2} overflow={"hidden"}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        flexWrap={"wrap"}
        gap={1}
      >
        <Typography
          variant="h6"
          fontSize={16}
          color={(t) => t.palette.text.primary}
        >
          {t("page.card.flow.userTickets")}
        </Typography>
        <Stack
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            gap: 1,
          }}
        >
          <AddTicketButton />
          <Typography
            variant="body2"
            color={(t) => t.palette.text.primary}
            sx={(t) => ({
              width: 30,
              height: 30,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              border: 1,
              borderColor: t.palette.divider,
              borderRadius: 15,
              transition: "ease-in-out 0.2s",
              "&:hover": {
                color: t.palette.text.primary,
                backgroundColor: t.palette.divider,
              },
            })}
          >
            {userTickets.length}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        gap={1}
        overflow={"auto"}
        paddingY={1}
        sx={(t) => ({
          "&::-webkit-scrollbar": {
            width: "0.4em",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            backgroundColor:
              t.palette.mode === "light"
                ? t.palette.grey[400]
                : t.palette.grey[900],
            borderRadius: 5,
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor:
              t.palette.mode === "light"
                ? t.palette.grey[200]
                : t.palette.grey[700],
            borderRadius: 5,
          },
          paddingRight: t.spacing(1),
        })}
      >
        {userTickets
          .sort((a, b) =>
            a.startDate < b.startDate ? -1 : a.startDate > b.startDate ? 1 : 0
          )
          .map((t, i) => (
            <FlowCard
              index={i}
              data={t}
              key={t.id}
              isDragDisabled
              selected={isSelected(t.id)}
              onClick={() => openTicket(t.id)}
            />
          ))}
        {userTickets.length === 0 && (
          <Typography color={(t) => t.palette.text.secondary}>
            {t("page.card.flow.noTicketFound")}
          </Typography>
        )}
        <div ref={bottomRef}></div>
      </Stack>
    </Stack>
  );
};

export default UserCards;
