import { Grid, Stack } from "@mui/material";
import { memo } from "react";

import Card from "../UI/Card";
import CardContentContainer from "./CardContentContainer";
import CardInfoHeader from "./CardInfo/CardInfoHeader/CardInfoHeader";
import CardMembers from "./CardMembers/CardMembers";
import CardOtherInfo from "./CardOtherInfo/CardOtherInfo";
import CardTagContainer from "./CardTags/CardTagContainer";

interface ICardInfosProps {
  removeBorder?: boolean;
}

const CardInfos: React.FC<ICardInfosProps> = ({ removeBorder }) => {
  return (
    <Card
      width={"100%"}
      height={"calc(100% - 102px)"}
      sx={[
        !!removeBorder && {
          border: "none",
          padding: 0,
          overflow: "auto",
          height: "100%",
        },
      ]}
    >
      <Stack
        gap={3}
        overflow={"auto"}
        sx={(t) => ({
          "&::-webkit-scrollbar": {
            width: "0.4em",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            backgroundColor:
              t.palette.mode === "light"
                ? t.palette.grey[400]
                : t.palette.grey[900],
            borderRadius: 5,
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor:
              t.palette.mode === "light"
                ? t.palette.grey[200]
                : t.palette.grey[700],
            borderRadius: 5,
          },
          height: "100%",
          paddingRight: 2,
        })}
      >
        <CardInfoHeader />
        <Grid container direction={"row"} gap={2}>
          <CardMembers />
          <CardTagContainer />
          <CardOtherInfo />
        </Grid>
        <CardContentContainer />
      </Stack>
    </Card>
  );
};

export default memo(CardInfos);
