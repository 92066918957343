const pt_BR = {
  translations: {
    page: {
      individuals: {
        individuals: "Pessoa Física",
        individual: "Pessoa Física",
        new_individual: "Nova Pessoa Física",
        search_individuals: "Pesquise por uma pessoa física",
        add_individual: "Adicionar Pessoa Física",
        foreigner: "Estrangeiro",
        identity_card: "RG",
        name: "Nome",
        document: "CPF",
        birth_date: "Data de nascimento",
        add_contact: "Adicionar Contato",
        contact_type: "Tipo de Contato",
        addresses: "Endereços",
        add_address: "Adicionar Endereço",
        address_type: "Tipo de Endereço",
        zip_code: "CEP",
        city: "Cidade",
        province: "Estado",
        country: "País",
        address_description: "Rua",
        neighborhood: "Bairro",
        number: "Número",
        complement: "Complemento",
        gender: "Gênero",
        confirm: {
          title: "Deletar pessoa física?",
          description: "Isso vai permanentemente deletar a pessoa física",
        },
        errors: {
          name: "O nome é obrigatório",
          document: "O documento é obrigatório",
          document_regex: "Precisa ser um CPF válido",
          birth_date: "A data de nascimento é obrigatória",
          country: "O país é obrigatório",
          gender: "O gênero é obrigatório",
          contact: {
            contactTypeId: "O tipo de contato é obrigatório",
            phone: "O número de telefone é obrigatório",
            email: "O e-mail é obrigatório",
            site: "O site é obrigatório",
            other: "O campo é obrigatório",
          },
          address: {
            addressTypeId: "O tipo de endereço é obrigatório",
            zipCode: "O CEP é obrigatório",
            city: "A cidade é obrigatória",
            provincy: "O estado é obrigatório",
            addressDescription: "A rua é obrigatória",
            neighborhood: "O bairro é obrigatório",
            number: "O número é obrigatório",
          },
        },
        establishments: {
          establishments: "Estabelecimentos",
        },
      },
      legalPerson: {
        new_legalPerson: "Nova pessoa Jurídica",
        legalPersons: "Pessoas Jurídicas",
        delete_legalPersons: "Deletar Pessoas Jurídicas",
        search_legalPerson:
          "Procure por Documento, Razão Social ou Nome Fantasia",
        add_legalPerson: "Adicionar Pessoa Jurídica",
        //
        document: "CNPJ",
        corporateName: "Razão social",
        tradingName: "Nome fantasia",
        taxRegime: "Regime tributário",
        fiscalContributor: "Contribuinte fiscal",
        stateRegistration: "Inscrição estadual",
        municipalInscription: "Inscrição municipal",
        foreigner: "Estrangeira",
        country: "País",
        //
        contactType: "Tipo de contato",
        addContact: "Adicionar contato",
        //
        addresses: "Endereços",
        add_address: "Adicionar endereço",
        addressType: "Tipo de endereço",
        zip_code: "CEP",
        city: "Cidade:",
        province: "Estado:",
        addressDescription: "Rua",
        establishmentId: "Estabelecimento",
        neighborhood: "Bairro",
        number: "Número",
        complement: "Complemento",
        //
        contacts: "Contatos",
        establishment_tooltip: "Ver estabelecimento",
        confirm: {
          title: "Deletar pessoa jurídica?",
          description: "Isso vai permanentemente deletar a pessoa jurídica",
          title_plural: "Deletar pessoas jurídicas?",
          description_plural:
            "Isso vai permanentemente deletar as pessoas jurídicas",
        },
        //
        errors: {
          document: {
            nomempty: "O documento é obrigatório",
            regex: "Número de CNPJ inválido",
          },
          corporateName: "O nome da empresa é obrigatório",
          country: "O País obrigatório",
          establishment: "O estabelecimento é obrigatório",
          fiscal_contribuitor: "O contribuinte fiscal é obrigatório",
          tax_regime: "O regime tributário é obrigatório",
          contact: {
            contactTypeId: "O tipo de contato é obrigatório",
            value: {
              phone: "Número de telefone inválido",
              email: "Email inválido",
              site: "Site inválido",
              other: "O valor é obrigatório",
            },
          },
          address: {
            zipCode: "Cep inválido",
            addressTypeId: "Selecione um tipo",
            city: "A cidade é obrigatória",
            province: "O estado é obrigatório",
            addressDescription: "A rua é obrigatória",
            neighborhood: "O bairro é obrigatório",
            number: "Número inválido",
          },
        },
      },
      noAccess: {
        message:
          "Ops! Parece que você não possui permissão para acessar essa página!",
        button: "Voltar para o início",
      },
      login: {
        login: "Acessar",
        accessing: "Acessando",
        welcome: "Bem-vindo de volta",
        rememberme: "Lembre de mim",
        forget_password: "Esqueceu a senha?",
        username: "CPF",
        password: "Senha",
      },
      home: {
        Workspaces: "Suas Áreas de trabalho",
        seeArchived: "Visualizar áreas de trabalho arquivadas",
        archived: {
          title: "Áreas de trabalho arquivadas",
          empty: "Não encontramos nenhuma área de trabalho arquivada",
        },
      },
      workspace: {
        title: "Seus quadros",
        archived: {
          button: "Mostrar quadros fechados",
          title: "Quadros fechados",
          empty: "Nenhum quadro fechado encontrado",
          unarchive: "Desarquivar",
          delete: "Deletar",
        },
        create: "Criar novo quadro",
      },
      board: {
        createCard: {
          modalTitle: "Adicionar ticket(card) à lista {{name}}",
          title: "Título",
          type: "Tipo",
          requester: "Solicitante",
          clientType: "Tipo de cliente",
          client: "Cliente",
          pf: "Pessoa Física",
          pj: "Pessoa Jurídica",
          not_identified: "Não identificado",
          not_identified_name: "Nome (opcional)",
          not_identified_phone: "Telefone (opcional)",
          pf_client: "Cliente Pessoa Física",
          pj_client: "Cliente Pessoa Jurídica",
          service: "Serviço",
          origin: "Origem",
          create: "Adicionar ticket(card)",
          noService: "Nenhum serviço de tickets(cards) disponível",
          inform_request: "Informar solicitante diferente",
          form: {
            client: "Selecione um cliente",
            requester: "Selecione um solicitante",
            service: "Selecione um serviço",
            title: "Digite um título",
            phoneValid: "Digite um número de telefone valido",
            phoneRequired: "Digite um número de telefone",
            origin: "Selecione uma origem",
          },
        },
        settings: {
          noSelected: "Nenhum menu selecionado",
          archived: {
            changeToCards: "Mudar para cards",
            changeToColumns: "Mudar para colunas",
            search: "Procure por arquivados",
            cards: {
              unarchive: "Desarquivar",
              delete: "Deletar",
              title: "Apagar ticket(card)",
              description:
                "Ao apagar o ticket(card), todos os dados dele são apagado. Essa ação não pode ser desfeita",
              secureLabel: "Digite o nome do ticket(card) para apagar",
            },
          },
        },
      },
      card: {
        internal_template: "Template Interno",
        events: "Eventos",
        activity: "Atividade",
        history: "Histórico",
        finish: "Finalizar",
        join: "Ingressar",
        type: "Tipo",
        detail: "Detalhes do ticket(card)",
        unreadMessage: "{{number}} mensagens não lidas",
        client_sales_contract: "Contrato de Vendas do Cliente",
        sales_contract: "Contratos",
        inList: "Na lista:  {{list}}",
        client: "Cliente",
        client_not_identified: "Cliente (Não identificado PF/PJ)",
        requester: "Solicitante",
        noClient: "Nenhum cliente encontrado",
        clientEntityHelperText:
          "Digite ao menos 3 caracteres para pesquisar empresas",
        clientIndividualHelperText:
          "Digite ao menos 3 caracteres para pesquisar indivíduos",
        selectStatus: "Selecione um status",
        selectOrigin: "Selecione uma origem",
        selectCustomerServiceType: "Selecione um tipo de atendimento",
        whatsappNumber: "Número do Whatsapp",
        whatsappName: "Nome do Whatsapp",
        description: "Descrição",
        edit: "Editar",
        save: "Salvar",
        cancel: "Cancelar",
        status: "Status",
        origin: "Origem",
        moreDetailedDescription: "Adicione uma descrição mais detalhada",
        protocol: "Protocolo",
        copied: "Protocolo copiado com sucesso",
        copyTooltip: "Copiar protocolo",
        number: "Número",
        customerServiceType: "Tipo de Atendimento",
        view_more: "Ver mais",
        view_less: "Ver menos",
        ticket_external_process_type: "Tipo de Processo Externo",
        ticket_external_identifier: "Identificador Processo Externo",
        services: "Atendimentos",
        ticket_external_identifier_error:
          "O identificador processo externo é obrigatório",
        ticket_external_process_type_error:
          "É preciso selecionar um tipo de processo externo primeiro",
        changeCustomer: {
          select: "Selecione um tipo de cliente",
          pf: "Pessoa física",
          pj: "Pessoa jurídica",
        },
        actions: {
          title: "Ações",
          archive: "Arquivar",
          leave: "Sair do Ticket",
          move_and_leave: "Mover e Sair do Ticket",
          move: "Mover",
          reopen: {
            reopen_successfully: "Ticket(card) reaberto com sucesso",
            button: "Reabrir",
          },
          moveTicket: "Mover ticket(card)",
          list: "Lista",
          board: "Board",
          order: "Ordem",
          workspace: "Área de Trabalho",
        },
        chat: {
          title: "Chat",
          file: "Anexar arquivo",
          emoji: "Emojis",
          refresh: "Recarregar",
          quickReplay: "Respostas rápidas",
          chatPlaceholder: "Escreva sua mensagem...",
          hasRequesterMessage:
            "Para enviar uma mensagem, o solicitante precisa enviar ao menos uma mensagem",
          noMessages: {
            startChat: {
              title: "Nenhuma conversa encontrada",
              subtitle: "Clique no botão abaixo para iniciar uma conversa",
              button: "Iniciar conversa",
            },
            whatsappNumber: {
              title: "Informe um número de WhatsApp",
              subtitle:
                "O número de WhatsApp será utilizado para enviar mensagens",
              country: "DDI",
              phone: "Número do WhatsApp",
              phone_options: "Selecione um número ou adicione um novo",
              errors: {
                country: "O DDI é obrigatório",
                phone: "O número do WhatsApp é obrigatório",
              },
            },
            selectAutomationRule: {
              title: "Informe uma regra de automação",
              subtitle:
                "A regra de automação será utilizada para enviar mensagens automáticas",
              restart_title: "Reiniciar conversa",
              restart_subtitle:
                "A regra de automação será utilizada para reiniciar a conversa",
              automation_rule: "Regra de automação",
              chat_started: "Conversa iniciada com sucesso",
              errors: {
                automation_rule: "A regra de automação é obrigatória",
              },
            },
            noMessagesActions: {
              back: "Voltar",
              next: "Próximo",
              finish: "Finalizar",
              restart: "Reiniciar",
            },
          },
        },
        flow: {
          openTickets: "Tickets(cards) em aberto",
          userTickets: "Meus tickets(cards)",
          noTickets: "Nenhum ticket(card) em aberto encontrado",
          filter: "Filtrar por Lista",
          noTicketFound:
            "Nenhum ticket(card) encontrado. Ingresse em algum ticket(card)",
          noTicketSelected:
            "Nenhum ticket(card) selecionado. Selecione um ticket(card)",
          addTicketButton: {
            btn_label: "Ticket(card)",
            tooltip:
              "Para adicionar um ticket(card) você precisa ter uma lista com status 'Aberto'",
          },
        },
      },
    },
    components: {
      dragScreen: {
        message: "Arraste o arquivo aqui",
      },
      table: {
        no_finish_date: "Sem data de finalização",
        status: "Estado",
        protocolNumber: "Número de protocolo",
        ticketService: "Serviço de tickets(card)",
        ticketSequential: "Id Interno",
        ticket: "Ticket(Card)",
        column: "Lista",
        individualRequester: "Solicitante",
        unreadMessages: "Mensagens não lidas",
        individual: "Indivíduo",
        created: "Criado em",
        finishDate: "Finalizado em",
        ticketChannelTags: "Tags",
        ticketMembers: "Membros",
        empty: "Nenhuma tarefa encontrada",
      },
      empty: {
        title: "Nada encontrado",
      },
      menuBoards: {
        your_boards: "Seus quadros",
        current_workspace: "Área de trabalho",
        minimize: "Minimizar",
        view_boards: "Visualização",
      },
      BoardNotFound: {
        title: "Não encontramos o quadro que você está procurando",
        button: "Voltar à listagem de quadros",
      },
      workspacesPopover: {
        current_workspace: "Área de trabalho atual",
        workspaces: "Áreas de trabalho",
        create_workspace: "Criar área de trabalho",
      },
      workspace: {
        workspaces: "Áreas de trabalho",
        title: "Criar nova área de trabalho",
        see_more: "Ver mais",
        title2: "Editar área de trabalho",
        edit: "Editar",
        create_workspace: "Criar área de trabalho",
        subtitle:
          "A área de trabalho foi desenvolvida exclusivamente para ajudar você a ter um dia mais produtivo. Crie uma nova área de trabalho e seja mais produtivo!",
        name: "Nome da área de trabalho",
        establishments: "Estabelecimentos",
        create: "Criar",
        errors: {
          name: "O nome da área de trabalho é obrigatória",
          establishments: "Ao menos um estabelecimento é obrigatório",
        },
        delete: {
          title:
            'Digite o nome da área de trabalho "{{ secureReferenceText }}" para deletar',
          description:
            "Ao deletar todos os quadros e tickets(cards) serão perdidos. Esta ação é irreversível",
          label: "Digite o nome da área de trabalho para Deletar",
        },
        archive: {
          title: "Arquivar àrea de trabalho",
          description:
            "Você pode achar e desarquivar as aŕeas de trabalho na parte inferior da sua tela de áreas de trabalho",
          button: "Arquivar",
        },
      },
      boards: {
        errors: {
          finishedColumn:
            "Não é possível finalizar um ticket(card) sem antes passar pelo status em processo",
        },
        filters: {
          filters: "Filtros",
          clearAll: "Limpar filtros",
          title: "Título",
          helperText: "Procure por títulos de tickets(cards)",
          alreadyParticipated: "Já participei",
          history: "Histórico",
          date: "Datas",
          endDate: "Data final",
          initialDate: "Data inicial",
          asignToMe: "Atribuído a mim",
          members: "Membros",
          createdBy: "Aberto por",
          createdByMe: "Aberto por mim",
          status: "Situação",
          status_helper: "Adicione a data inicial e final para este filtro",
          noMembers: "Sem membros",
          selectMembers: "Selecione membros",
          selectMember: "Selecione um membro",
          apply: "Aplicar filtros",
          invalid_endDate: "Data final inválida",
          invalid_initialDate: "Data inicial inválida",
          openedBySystem: "Aberto pelo sistema",
        },
        delete: {
          title:
            'Digite o nome do quadro "{{ secureReferenceText }}" para deletar',
          description:
            "Ao deletar todos os tickets(cards) serão perdidos. Esta ação é irreversível",
          label: "Digite o nome do quadro para Deletar",
        },
        form: {
          edit: "Editar quadro",
          create: "Criar quadro",
          title: "Título",
          type: "Tipo",
          parent: "Quadro pai",
        },
        settingsMenu: {
          archivedItem: "Items arquivados",
          closeBoard: "Fechar quadro",
          editBoard: "Editar quadro",
          pages: {
            menu: "Menu",
            archived: "Arquivados",
          },
          selectParent: "Selecionar Quadro de Visualização",
          select: "Selecionar",
          archived: {
            unarchive: "Desarquivar",
            remove: "Apagar",
            secureRemove: {
              title: 'Digite o nome da lista "{{reference}}" para apagar',
              description:
                "Ao apagar a lista todos as tarefas dela também serão excluídas.",
              button: "Fechar quadro",
              secureLabel: "Digite o nome da lista para apagar",
            },
          },
          closeBoardDescription:
            "Você pode encontrar e reabrir seus quadros na tela da área de trabalho",
        },
      },
      chat: {
        quickReplay: {
          send: "Enviar",
          delete: "Deletar",
          edit: "Editar",
          popover: {
            title: "Respostas rápidas (Digite pelo menos 3 letras)",
            empty: "Nenhuma resposta encontrada",
          },
          modal: {
            title: "Respostas rápidas",
          },
          list: {
            empty: "Nenhuma resposta rápida encontrada",
            register: "Cadastrar",
          },
          deleteModal: {
            title: "Deletar Respota rápida",
            description:
              "A resposta rápida será deletada. Essa ação não pode ser desfeita",
            delete: "Deletar",
          },
          form: {
            edit: "Editar resposta",
            create: "Criar resposta",
            cancel: "Cancelar",
            shareType: "Compartilhamento",
            shortCut: "Atalho",
            message: "Mensagem",
            title: "Título",
          },
        },
        restartMessagePopUp: {
          title: "Conversa expirada!",
          subtitle:
            "Essa conversa não teve interações nas últimas 24 horas. Clique no botão para reiniciá-la.",
          buttonRestart: "Reiniciar",
          buttonFinish: "Finalizar",
          finishConfirm: {
            modal_title: "Finalizar o Ticket?",
            cancel: "Cancelar",
            confirm: "Confirmar",
            finish_ticket:
              "Você tem certeza que deseja finalizar esse ticket(card)? Não será possível entregar a mensagem de finalização do mesmo, pois excedeu o período de 24 horas. Caso deseja continuar essa conversa clique no botão Reiniciar",
          },
        },
      },
      column: {
        transferred: "Lista de transferidos",
        minimize: "Minimizar",
        maximize: "Maximizar",
        menu: {
          title: "Lista de ações",
          transferred: {
            mark: "Marcar como lista de transferidos",
            unmark: "Desmarcar como lista de transferidos",
          },
          changeParent: {
            title: "Alterar coluna de visualização",
            change: "Alterar coluna",
            button: "Alterar",
          },
          changeDefaultStatus: {
            title: "Alterar status padrão",
            change: "Alterar status",
            button: "Alterar",
          },
          changeColor: {
            title: "Mudar cor",
            change_color: "Mudar cor",
            select: "Selecionar cor",
          },
          archive: {
            title: "Arquivar lista",
          },
        },
        task: {
          addTask: "Adicionar ticket(card)",
        },
        addColumn: {
          addColumn: "Adicionar Coluna",
          name: "Name",
          nameRequired: "O nome é obrigatório",
          parentRequired: "A coluna pai é obrigatória",
          parent: "Coluna pai",
        },
      },
      ticketTagsList: {
        tags: "Tags",
        add_tag: "Adicionar Tag",
      },
      cardTagSelector: {
        tags: "Tags",
        tag: "Tag",
        create_tag: "Criar tag",
      },
      tagDeleteButton: {
        delete: "Excluir Tag",
        description:
          "Essa ação fará com que a Tag seja removida de todos os cards a qual foi atribuida.",
        delete_button: "Excluir",
      },
      tagItemActions: {
        edit: "Editar Tag",
      },
      tagForm: {
        name: "Nome",
        enable: "Habilitar",
        display_order: "Ordem de exibição",
        ticket_channel: "Tipo do ticket(card)",
        color: "Selecione a cor",
        errors: {
          name: "O nome é obrigatório",
          display_order: "A ordem de exibição é obrigatória",
          ticket_channel: "O tipo do ticket(card) é obrigatório",
          color: "A cor é obrigatória",
        },
      },
      tagFormActions: {
        back: "Voltar",
        edit: "Editar",
        create: "Criar",
      },
      boardHeader: {
        invite: "Convidar",
        invite_member: "Convidar membro",
        boardMode: {
          table: "Tabela",
        },
        member: {
          user_added: "{{user}} adicionado(a) com sucesso.",
          function_update: "Função do(a) {{fullName}} alterada com sucesso.",
          remove_successfully: "{{fullName}} removido(a) com sucesso.",
          leave_board: "Você deixou o quadro com sucesso.",
          you: "(você)",
          removing_board: "Removendo quadro...",
          leave_board_option: "Deixar o quadro",
          remove_user_option: "Remover do quadro",
          select_user: "Selecionar usuário",
          invite_user: "Convidar",
          nickname: "Apelido",
          member_added: "Membro adicionado com sucesso",
          member_updated: "Membro atualizado com sucesso",
          member_removed: "Membro removido com sucesso",
          errors: {
            user: "O usuário é obrigatório",
            profile: "O perfil é obrigatório",
          },
        },
      },
      createChecklist: {
        checklist: "Checklist",
        create_checklist_button: "Criar checklist",
        name: "Nome",
        display_order: "Ordem",
        errors: {
          name: "O nome é obrigatório",
          display_order: "A ordem é obrigatória",
        },
      },
      deleteChecklist: {
        delete: "Deletar",
        title: "Deletar checklist",
        description: "Você tem certeza que deseja deletar o checklist?",
      },
      cardChecklistToggleItems: {
        hide: "Ocultar itens marcados",
        show: "Mostrar itens marcados",
      },
      cardChecklistAddItem: {
        button: "Adicionar item",
      },
      checklistAddItemForm: {
        title: "Adicionar item",
        description: "Item",
        forecastStartDate: "Data prevista para o início",
        estimatedTime: "Tempo estimado em minutos",
        button: "Adicionar",
        errors: {
          description: "O item é obrigatório",
          estimatedTime:
            "O tempo estimado não pode ser um valor negativo ou zero",
        },
      },
      checklistItemDelete: {
        title: "Remover item",
        description: "Essa ação irá remover o item do checklist",
        button: "Deletar",
        tooltip: "Deletar item",
      },
      chcecklistItemTime: {
        tooltip: "Definir tempos",
        title: "Definir tempos",
        forecast_start_date: "Data prevista para o início",
        estimated_time: "Tempo estimado em minutos",
        start_date: "Data de início",
        end_date: "Data de fim",
        save: "Salvar",
        errors: {
          estimated_time:
            "O tempo estimado não pode ser um número negativo ou zero",
        },
      },
      cardMembers: {
        members: "Membros",
        add_member: "Adicionar membro",
        join: "Entrar no ticket(card)",
      },
      cardMembersSelector: {
        members: "Membros",
        search: "Pesquisar membros",
        member_added: "Membro adicionado com sucesso",
        member_removed: "Membro removido com sucesso",
      },
      cardHistories: {
        activity: "Atividade",
        show_details: "Mostrar detalhes",
        hide_details: "Ocultar detalhes",
        internal: "Interno",
        external: "Externo",
      },
      historyComments: {
        placeholder: "Escreva um comentário...",
        save: "Salvar",
        cancel: "Cancelar",
        history_type: "Tipo de histórico",
        errors: {
          message: "A mensagem é obrigatória",
          history_type: "O tipo de histórico é obrigatório",
        },
      },
      cardPeriod: {
        created: "Data de criação",
        finish_date: "Data de finalização",
        started: "Data de início",
      },
      joinAction: {
        finish: "Finalizar",
        join: "Ingressar",
        no_lists:
          "Não é possível ingressar nesse ticket(card), pois não há listas com o status 'Em processo'",
        select: "Selecionar",
      },
      finishActionPopover: {
        no_list:
          "Não é possível finalizar esse ticket(card), pois não há uma lista com o status 'Finalizado'",
      },
      advancedSearchHeader: {
        search: "Pesquisa",
        protocol_or_id: "Protocolo ou Id interno",
        button: "Pesquisar",
        advanced_search: "Pesquisa avançada",
        errors: {
          protocol_or_id: "O protocolo ou id interno é obrigatório",
        },
      },
      advancedSearchViewMore: {
        button: "Ver mais",
      },
      searchResultCards: {
        no_access: "Você não possui acesso a este ticket(card)",
      },
      searchResults: {
        no_workspace_dates: {
          title: "Área de trabalho e datas não informados!",
          subtitle:
            "É necessário informar ao menos a área de trabalho e as datas de início e fim para realizar uma pesquisa",
        },
        no_workspace: {
          title: "Área de trabalho não selecionada!",
          subtitle:
            "Selecione ao menos a área de trabalho para realizar uma pesquisa",
        },
        no_dates: {
          title: "As datas de início e fim são obrigatórias",
          subtitle:
            "É necessário selecionar uma data de início e fim para iniciar uma pesquisa",
        },
        range_bigger: {
          title: "Verifique as datas selecionadas!",
          subtitle:
            "A data de fim não pode ter uma diferença maior do que 180 dias em relação a data de início",
        },
        filter_click: {
          title: "Agora falta só clicar em filtrar!",
          subtitle:
            "É necessário clicar em 'filtrar' para iniciar uma pesquisa",
        },
        no_results: {
          title: "Nenhum resultado encontrado!",
          subtitle:
            "Altere os parâmetros dos filtros para ter um resultado diferente",
        },
      },
      searchResultsList: {
        search_results: "Resultados da busca:",
      },
      advancedSearchFields: {
        filters: "Filtros",
        clean_filters: "Limpar filtros",
        filter: "Filtrar",
        workspace: "Área de trabalho",
        start_date: "Data de início",
        end_date: "Data de fim",
        protocol_or_id: "Protocolo ou Id interno",
        title: "Título",
        individual_requester_name: "Nome do solicitante",
        individual_client_name: "Nome do cliente",
        created_by: "Aberto por",
        created_by_no_options: "Sem opções",
        status: "Status",
        board: "Quadro",
        filter_by: "Filtrar por",
        create: "Criação",
        finish: "Finalização",
        errors: {
          range: "Busca máxima de 180 dias",
        },
      },
      protocolDefault: {
        protocol_default: "Protocolos de Orientações Padrão",
      },
      protocolsDefaultList: {
        no_results: "Nenhum resultado encontrado",
        viw_more: "Ver mais",
      },
      protocolDefaultModal: {
        search: "Pesquisar por um protocolo",
      },
      sharedContacts: {
        title: "Contato(s) compatilhado(s):",
        another_contact: "e mais um contato",
        another_contacts: "e outros contatos",
        copy_tooltip: "Copiar para área de transferência",
        copied: "Copiado para área de transferência",
        formatted_name: "Nome",
        first_name: "Primeiro Nome",
        last_name: "Último Nome",
        prefix_name: "Prefixo",
        sufix_name: "Sufixo",
        whatsapp: "WhatsApp",
        phone: "Telefone",
        email: "E-mail",
        address: "Endereço",
        address_type: "Tipo do Endereço",
        zip_code: "Código Postal",
        street: "Rua",
        city: "Cidade",
        state: "Estado",
        country: "País",
        url: "Link",
        birthday: "Data de Nascimento",
      },
      sharedLocation: {
        title: "Localização compartilhada:",
        show: "Visualizar localização",
      },
      detailFiles: {
        files: "Arquivos:",
        no_protocol_selected: "Nenhum protocolo selecionado",
        no_data: "Nenhum arquivo encontrado",
      },
      detailFile: {
        no_data: "Nenhum arquivo encontrado",
      },
      detailFileActions: {
        view_file: "Ver arquivo",
        view_more: "Ver mais",
      },
      detailMessage: {
        message: "Mensagem:",
        no_protocol_selected: "Nenhum protocolo selecionado",
        no_data: "Nenhuma mensagem encontrada",
      },
      detailSend: {
        send: "Enviar",
        sent_successfully: "Protocolo enviado com sucesso",
      },
      events: {
        event_type: "Tipo de evento",
        event: "Evento",
        initial_date: "Data de início",
        end_date: "Data de fim",
        created_by: "Operador",
        no_data: "Nenhum evento encontrado",
        event_created: "Evento criado com sucesso",
        event_updated: "Evento atualizado com sucesso",
        select_event_hint:
          "É preciso selecionar um tipo de evento para selecionar um evento",
        header: {
          search: "Pesquisar por um evento",
          add_event: "Adicionar evento",
          back: "Voltar",
        },
        actions: {
          save: "Salvar",
          cancel: "Cancelar",
        },
        errors: {
          event: "O evento é obrigatório",
          initial_date: "A data de início é obrigatória",
          end_date: "A data de fim não pode ser menor que a data de início",
        },
      },
      internalTemplate: {
        internal_template: "Template Interno",
        link_template: "Vincular Template",
        change_template: "Alterar Template",
        error: "Este campo é obrigatório",
        change: "Alterar",
        there_isnt_template: "Não há template interno vinculado.",
        modal_title: "Alterar o Template?",
        delete_template: "Isso irá excluir todos os valores das variáveis.",
        update_successfully: "Valores das variáveis atualizados com sucesso",
        actions: {
          save: "Salvar",
          cancel: "Cancelar",
        },
        errors: {
          event: "The event is mandatory",
          initial_date: "The initial date is mandatory",
          end_date: "The end date cannot be less than the start date",
        },
      },
      statusMessage: {
        tooltip: "Ver status",
      },
      statusDrawer: {
        message_status: "Status da mensagem",
        delivered: "Entregue",
        read: "Lida",
        sent: "Enviada",
        error: "Ops, ocorreu um erro!",
      },
      logout: "Sair",
      settings: "Configurações",
      cardServices: {
        whatsapp_number: "Número do WhatsApp",
        requester: "Solicitante",
        no_data_dialog: "Nenhuma mensagem encontrada para este ticket",
        client: "Cliente",
        no_data: "Nenhum ticket(card) encontrado",
        history: "Histórico",
        go_to_ticket: "Ir para o ticket(card)",
        view_more: "Ver mais",
      },
    },
    general: {
      login_expired: "Login expirado",
      archive: "Arquivar",
    },
  },
};

export default pt_BR;
