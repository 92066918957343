import { memo, useCallback, useEffect, useState } from "react";
import { AutoComplete, useFetch } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";
import { IGetColumn } from "../../../../../models/columns";
import { getColumnsByBoardId } from "../../../../../services/column.serivice";
import useBoard from "../../../../../hooks/useBoard";
import { Grid } from "@mui/material";

const ListSelector: React.FC = () => {
  const [lists, setLists] = useState<IGetColumn[]>([]);

  const { t } = useTranslation();

  const {
    board: { id },
  } = useBoard();

  const { sendRequest: getColumn } = useFetch(getColumnsByBoardId);

  const fetchColumns = useCallback(async () => {
    if (id) {
      const { data, success } = await getColumn({ bId: id });
      if (data && success) {
        setLists(data);
      }
    }
  }, [setLists, id, getColumn]);

  useEffect(() => {
    fetchColumns();
  }, [fetchColumns]);

  return (
    <Grid item xs={12} sm={12}>
      <AutoComplete
        name="list"
        options={lists}
        size="small"
        fullWidth
        label={t("page.card.actions.list")}
        getOptionLabel={(option) => {
          return option.name;
        }}
      />
    </Grid>
  );
};

export default memo(ListSelector);
