import { ISelectType } from "@4uhub/lib4uhub";
import { Dispatch, SetStateAction, useCallback } from "react";

import { ICardMember } from "../models/card-members";
import { IGetColumn } from "../models/columns";
import {
  IGetTicket,
  ILegalEntity,
  ITicketRelationship,
} from "../models/ticket";

export interface IEditTicketFunctions {
  onEditContent: (content: string) => void;
  onEditEntityCustomer: (entity: ILegalEntity) => void;
  onEditIndividualCustomer: (individual: ISelectType) => void;
  onEditOrigin: (origin: ISelectType) => void;
  onEditRequester: (requester: ISelectType) => void;
  onEditList: (list: IGetColumn) => void;
  onEditStatus: (status: ISelectType) => void;
  onEditTitle: (title: string) => void;
  onEditCustomerServiceType: (ticketCustomerServiceType: ISelectType) => void;
  onEditType: (type: ISelectType) => void;
  onEditMembers: (members: ICardMember[]) => void;
  onEditticketRelationships: (value: ITicketRelationship[] | null) => void;
  onGetWhatsappNumber: (value: string | null) => void;
}

const useEditTicket = (
  setCard: Dispatch<SetStateAction<IGetTicket | undefined>>
): IEditTicketFunctions => {
  const onEditTitle = useCallback(
    (title: string) => {
      setCard((c) => {
        if (c) {
          return { ...c, title };
        }
        return c;
      });
    },
    [setCard]
  );

  const onEditOrigin = useCallback(
    (origin: ISelectType) => {
      setCard((c) => {
        if (c) {
          return { ...c, ticketOriginType: origin };
        }
        return c;
      });
    },
    [setCard]
  );

  const onEditCustomerServiceType = useCallback(
    (ticketCustomerServiceType: ISelectType) => {
      setCard((c) => {
        if (c) {
          return { ...c, ticketCustomerServiceType: ticketCustomerServiceType };
        }
        return c;
      });
    },
    [setCard]
  );

  const onEditStatus = useCallback(
    (status: ISelectType) => {
      setCard((c) => {
        if (c) {
          return { ...c, status };
        }
        return c;
      });
    },
    [setCard]
  );

  const onEditType = useCallback(
    (type: ISelectType) => {
      setCard((c) => {
        if (c) {
          return { ...c, type: type };
        }
        return c;
      });
    },
    [setCard]
  );

  const onEditContent = useCallback(
    (content: string) => {
      setCard((c) => {
        if (c) {
          return { ...c, content };
        }
        return c;
      });
    },
    [setCard]
  );

  const onEditRequester = useCallback(
    (requester: ISelectType) => {
      setCard((c) => {
        if (c) {
          return {
            ...c,
            individualRequester: {
              ...requester,
              document: requester.code || "",
            },
          };
        }
        return c;
      });
    },
    [setCard]
  );

  const onEditIndividualCustomer = useCallback(
    (individual: ISelectType) => {
      setCard((c) => {
        if (c) {
          return {
            ...c,
            individualCustomer: {
              ...individual,
              document: individual.code || "",
            },
            legalEntityCustomer: null,
          };
        }
        return c;
      });
    },
    [setCard]
  );

  const onEditEntityCustomer = useCallback(
    (entity: ILegalEntity) => {
      setCard((c) => {
        if (c) {
          return {
            ...c,
            legalEntityCustomer: { ...entity },
            individualCustomer: null,
          };
        }
        return c;
      });
    },
    [setCard]
  );

  const onEditList = useCallback(
    (list: IGetColumn) => {
      setCard((c) => {
        if (c) {
          return {
            ...c,
            ticketChannelStep: list,
          };
        }
        return c;
      });
    },
    [setCard]
  );

  const onEditMembers = useCallback(
    (members: ICardMember[]) => {
      setCard((c) => {
        if (c) {
          return {
            ...c,
            ticketMembers: members,
          };
        }
        return c;
      });
    },
    [setCard]
  );
  const onEditticketRelationships = useCallback(
    (value: ITicketRelationship[] | null) => {
      setCard((c) => {
        if (c) {
          return {
            ...c,
            ticketRelationships: value,
          };
        }
        return c;
      });
    },
    [setCard]
  );

  const onGetWhatsappNumber = useCallback(
    (value: string | null) => {
      setCard((c) => {
        if (c) {
          return {
            ...c,
            whatsappNumber: value,
          };
        }
        return c;
      });
    },
    [setCard]
  );

  return {
    onEditContent,
    onEditEntityCustomer,
    onEditIndividualCustomer,
    onEditOrigin,
    onEditRequester,
    onEditStatus,
    onEditTitle,
    onEditType,
    onEditCustomerServiceType,
    onEditMembers,
    onEditList,
    onEditticketRelationships,
    onGetWhatsappNumber,
  };
};

export default useEditTicket;
