import { IconButton, Tooltip } from "@mui/material";
import ArchiveIcon from "@mui/icons-material/Archive";
import { useTranslation } from "react-i18next";

import PopoverDelete from "../../UI/PopoverDelete";
import usePopover from "../../../hooks/usePopover";
import { memo } from "react";

interface IWorkspaceArchive {
  onArchive: () => void;
  loading: boolean;
}

const WorkspaceArchive: React.FC<IWorkspaceArchive> = ({
  onArchive,
  loading,
}) => {
  const { anchorEl, handleClick, handleClose, open, id } = usePopover(
    "archive-workspace-popover"
  );

  const { t } = useTranslation();

  return (
    <div>
      <Tooltip title={t("general.archive")}>
        <IconButton size="small" aria-describedby={id} onClick={handleClick}>
          <ArchiveIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      {open && (
        <PopoverDelete
          title={t("components.workspace.archive.title")}
          description={t("components.workspace.archive.description")}
          buttonDescription={t("components.workspace.archive.button")}
          onDelete={onArchive}
          loading={loading}
          onClose={handleClose}
          open={open}
          anchorEl={anchorEl}
          id={id}
        />
      )}
    </div>
  );
};

export default memo(WorkspaceArchive);
