import { ISelectType } from "@4uhub/lib4uhub";
import { Typography } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";

import useBoardRole from "../../../../hooks/useBoardRole";
import useGeneric from "../../../../hooks/useGeneric";
import usePopover from "../../../../hooks/usePopover";
import useTicket from "../../../../hooks/useTask";
import HoverOpacity from "../../../UI/HoverOpacity";
import Property from "../../Property";
import CardOriginSelector from "./CardOriginSelector";

interface ICardOriginProps {
  id: string;
  ticketOriginType: ISelectType;
  loading?: boolean;
}

const CardOrigin: React.FC<ICardOriginProps> = ({
  id,
  ticketOriginType,
  loading,
}) => {
  const { t } = useTranslation();

  const {
    anchorEl,
    handleClick,
    handleClose,
    id: PopoverId,
    open,
  } = usePopover("ticket-status-popover");

  const permission = useBoardRole("2");

  const {
    isUserTicketMember,
    ticket: { status },
  } = useTicket();

  const { data } = useGeneric("TicketOriginType");

  const handleChange = (v?: ISelectType) => {
    handleClose();
  };

  return (
    <>
      <HoverOpacity
        onClick={handleClick}
        disabled={!isUserTicketMember || !permission || status.code === "3"}
      >
        <Property
          label={t("page.card.origin")}
          loading={loading}
          content={
            <Typography color={(t) => t.palette.text.primary} fontSize={14}>
              {ticketOriginType.name}
            </Typography>
          }
        />
      </HoverOpacity>

      <CardOriginSelector
        data={data}
        selected={ticketOriginType.id}
        ticketId={id}
        anchorEl={anchorEl}
        id={PopoverId}
        open={open}
        onChange={handleChange}
        onClose={handleClose}
      />
    </>
  );
};

export default memo(CardOrigin);
