import { Divider, Stack, useTheme } from "@mui/material";
import { Variants, motion } from "framer-motion";
import { DomainProvider } from "@4uhub/lib4uhub";
import { useMemo } from "react";

import useBoard from "../../../hooks/useBoard";
import EditBoard from "./Buttons/EditBoard";
import CloseBoard from "./Buttons/CloseBoard";
import SettingsMenuProvider, {
  IMenuPage,
} from "../../../store/context/settingsMenu-context";
import SettingsMenuBody from "./SettingsMenuBody";
import ArchivedItemsButton from "./Buttons/ArchivedItemsButton";
import ArchivedItems from "./Pages/ArchivedItems/ArchivedItems";
import SettingsMenuHeader from "./SettingsMenuHeader";
import EditParent from "./Buttons/EditParent/EditParent";

const variants: Variants = {
  open: { width: 350 },
  closed: { width: 0, display: "none" },
};

const BoardSettingsMenu = () => {
  const {
    settingsMenu,
    board: { isView },
  } = useBoard();

  const pages: IMenuPage[] = useMemo(
    () => [
      {
        title: "components.boards.settingsMenu.pages.menu",
        name: "menu",
        content: (
          <Stack>
            <ArchivedItemsButton />
            <EditBoard />
            <EditParent  isView={isView}/>
            <CloseBoard />
          </Stack>
        ),
      },
      {
        title: "components.boards.settingsMenu.pages.archived",
        name: "archived",
        content: <ArchivedItems />,
      },
    ],
    [isView]
  );

  const { palette, breakpoints } = useTheme();

  return (
    <DomainProvider redirect={false} value={{ domainName: "ticketchannel" }}>
      <motion.div
        variants={variants}
        initial={{
          width: 350,
          height: "100%",
          justifyContent: "space-between",
          backgroundColor: palette.background.default,
        }}
        style={{
          zIndex: breakpoints.down("md") ? 99 : 1,
          position: "relative",
          right: 0,
          overflow: "hidden",
        }}
        animate={settingsMenu ? "open" : "closed"}
      >
        <SettingsMenuProvider pages={pages} startPage="menu">
          <Stack
            width={"100%"}
            height={"100%"}
            p={2}
            boxShadow={2}
            ml={0.3}
            overflow={"hidden"}
          >
            <SettingsMenuHeader />
            <Divider style={{ marginBlock: 10 }} />
            <SettingsMenuBody />
          </Stack>
        </SettingsMenuProvider>
      </motion.div>
    </DomainProvider>
  );
};
export default BoardSettingsMenu;
