import { Form, useFetch } from "@4uhub/lib4uhub";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { memo, useCallback, useEffect } from "react";

import TagsService from "../../../../services/tags.service";
import { TTagForm, tagSchema } from "./TagSchema";
import TagFormFields from "./TagFormFields";
import TagFormActions from "./TagFormActions";
import { convertRgbToHex } from "../../../../utils/ConvertRgbToHex";

const tagsService = new TagsService();

const TagForm = ({
  onCloseForm,
  id,
}: {
  onCloseForm: () => void;
  id: string | null;
}) => {
  const methods = useForm<TTagForm>({
    resolver: zodResolver(tagSchema),
  });

  const { sendRequest: getTag } = useFetch(tagsService.getItemById);

  const getHandler = useCallback(async () => {
    if (id) {
      const { data, success } = await getTag(id);
      if (data && success) {
        methods.setValue("name", data.name);
        methods.setValue("enable", data.enable);
        methods.setValue("displayOrder", data.displayOrder.toString());
        methods.setValue(
          "color",
          data.color.includes("rgb") ? convertRgbToHex(data.color) : data.color
        );
      }
    }
  }, [getTag, id, methods]);

  useEffect(() => {
    getHandler();
  }, [getHandler]);

  return (
    <Form {...methods}>
      <Stack flexDirection={"column"} gap={2}>
        <TagFormFields />
        <TagFormActions id={id} onCloseForm={onCloseForm} methods={methods} />
      </Stack>
    </Form>
  );
};

export default memo(TagForm);
