import {
  AutoCompleteMulti,
  Button,
  Form,
  FormInput,
  ISelectType,
  Loading,
  useFetch,
} from "@4uhub/lib4uhub";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { memo, useCallback, useEffect } from "react";

import { TWorkspaceForm, workspaceSchema } from "./WoskspaceSchema";
import MainApiService from "../../../services/mainApi.service";
import {
  createWorkspace,
  getWorkspaceById,
  updateWorkspace,
} from "../../../services/workspace.service";
import { useAppDispatch } from "../../../store/store";
import { workspacesSliceActions } from "../../../store/slices/workspaces";

const translationPath = "components.workspace.";

const ESTABLISHMENT_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/Establishment/ByCompanyGroup/";

const establishmentService = new MainApiService<ISelectType>(
  ESTABLISHMENT_ROUTE
);

interface IWorkspaceProps {
  id?: string;
  handleClose: () => void;
}

const WorkspaceForm: React.FC<IWorkspaceProps> = ({ handleClose, id }) => {
  const isEdit = !!id;

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const methods = useForm<TWorkspaceForm>({
    resolver: zodResolver(workspaceSchema),
  });

  const { sendRequest, loading } = useFetch(createWorkspace);

  const { sendRequest: getRequest, loading: getLoading } =
    useFetch(getWorkspaceById);

  const { handleSubmit, formState, reset } = methods;

  const fetchWorkspace = useCallback(async () => {
    if (!id) return;
    const { data, success } = await getRequest(id);
    if (data && success) {
      reset({ establishments: data.establishments, name: data.name });
    }
  }, [reset, id, getRequest]);

  useEffect(() => {
    fetchWorkspace();
  }, [fetchWorkspace]);

  const { sendRequest: updateRequest, loading: updateLoading } =
    useFetch(updateWorkspace);

  const create = useCallback(
    async (form: TWorkspaceForm) => {
      const { success, data } = await sendRequest({
        name: form.name,
        establishmentIds: form.establishments.map((e) => e.id),
      });
      if (success && data) {
        handleClose();
      }
    },
    [handleClose, sendRequest]
  );

  const update = useCallback(
    async (form: TWorkspaceForm, id: string) => {
      const { success, data } = await updateRequest({
        item: {
          ...form,
          establishmentIds: form.establishments.map((e) => e.id),
        },
        id,
      });

      if (success && data) {
        dispatch(
          workspacesSliceActions.editWorskspace({
            id,
            data,
          })
        );
        handleClose();
      }
    },
    [handleClose, dispatch, updateRequest]
  );

  const onSubmit = (form: TWorkspaceForm) => {
    if (isEdit) {
      update(form, id);
    } else {
      create(form);
    }
  };

  if (getLoading) {
    return <Loading size={30} />;
  }

  return (
    <Form {...methods}>
      <Box
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        maxHeight={600}
        sx={(t) => ({
          paddingTop: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: t.spacing(2),
        })}
      >
        {!isEdit && (
          <Typography
            variant="subtitle2"
            lineHeight={1.2}
            sx={(theme) => ({
              color:
                theme.palette.mode === "light"
                  ? theme.palette.grey[700]
                  : theme.palette.grey[400],
              fontSize: 17,
            })}
          >
            {t(translationPath + "subtitle")}
          </Typography>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <FormInput
              name="name"
              size="small"
              label={t(translationPath + "name")}
              fullWidth
              autoFocus
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <AutoCompleteMulti
              name="establishments"
              label={t(translationPath + "establishments")}
              size="small"
              fullWidth
              request={establishmentService.list}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Button
              variant="contained"
              size="medium"
              fullWidth
              type="submit"
              disabled={!formState.isValid}
              loading={loading || updateLoading}
            >
              {isEdit
                ? t(translationPath + "edit")
                : t(translationPath + "create")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Form>
  );
};

export default memo(WorkspaceForm);
