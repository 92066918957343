import { useFetch, useNotificationContext } from "@4uhub/lib4uhub";
import {
  createContext,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { OnDragEndResponder } from "react-beautiful-dnd";

import {
  changeColumnsOrder,
  getColumnsByBoardId,
} from "../../../services/column.serivice";
import {
  transferTicket,
  updateTicketOrder,
} from "../../../services/ticket.service";
import { IGetColumn } from "../../../models/columns";
import { IKanbanContext, IKanbanContextProviderProps } from "./models";
import { filterColumns } from "./filter";
import useEditColumns from "./useEditColumns/useEditColumns";
import useSignalRColumns from "./useSignalRColumns/useSignalRColumns";
import { useTranslation } from "react-i18next";

export const KanbanContext = createContext<IKanbanContext | undefined>(
  undefined
);

const KanbanProvider: React.FC<IKanbanContextProviderProps> = ({
  boardId,
  filter,
  children,
  isView,
  onCardsLengthChange,
}) => {
  const [columnOrder, setColumnOrder] = useState<string[]>([]);

  const { t } = useTranslation();

  const { setMessage } = useNotificationContext();

  const [columns, setColumns] = useState<IGetColumn[]>([]);

  const { sendRequest: changeOrderRequest } = useFetch(changeColumnsOrder);

  const { sendRequest: transfer } = useFetch(transferTicket);

  const { sendRequest: updateTicketOrderRequest } = useFetch(updateTicketOrder);

  const { sendRequest, loading } = useFetch(getColumnsByBoardId);

  const props = useEditColumns({ setColumnOrder, setColumns });

  useSignalRColumns({
    ...props,
    columns,
    setColumnOrder,
    setColumns,
  });

  const changeOrder = useCallback(
    (order: string[]) => {
      changeOrderRequest({
        ticketChannelId: boardId,
        ticketChannelStepIds: order,
      });
    },
    [changeOrderRequest, boardId]
  );

  const onDragEnd: OnDragEndResponder = useCallback(
    async ({ destination, source, draggableId, type }) => {
      if (!destination) {
        return;
      }

      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }

      if (type === "column") {
        setColumnOrder((old) => {
          const newColumnOrder = Array.from(old);
          newColumnOrder.splice(source.index, 1);
          newColumnOrder.splice(destination.index, 0, draggableId);
          changeOrder(newColumnOrder);
          return newColumnOrder;
        });

        return;
      }

      const sourceColumn = columns.find((c) => c.id === source.droppableId);

      const destinationColumn = columns.find(
        (c) => c.id === destination.droppableId
      );

      if (sourceColumn?.tasks && destinationColumn) {
        const element = sourceColumn.tasks[source.index];

        if (
          destinationColumn.status.code === "3" &&
          element.status.code === "1"
        ) {
          setMessage({
            message: t("components.boards.errors.finishedColumn"),
            type: "error",
          });
          return;
        }

        const realOrder = destination.index + 1;

        element.displayOrder = realOrder;

        element.status = destinationColumn.status;

        sourceColumn.tasks.splice(source.index, 1);

        sourceColumn.tasks.forEach((t, i) => {
          t.displayOrder = i + 1;
        });

        if (destinationColumn.tasks) {
          destinationColumn.tasks.splice(destination.index, 0, element);

          destinationColumn.tasks.map((t, i) => {
            t.displayOrder = i + 1;
            return t;
          });
        } else {
          destinationColumn.tasks = [element];
        }

        if (sourceColumn !== destinationColumn) {
          transfer({
            tickets: [{ id: element.id, displayOrder: realOrder }],
            ticketChannelId: boardId,
            ticketChannelStepId: destinationColumn.id,
          });
        }

        if (sourceColumn === destinationColumn) {
          updateTicketOrderRequest({
            ticketChannelStepId: sourceColumn.id,
            ticketIds: sourceColumn.tasks.map((t) => t.id),
          });
        }

        setColumns((columns) =>
          columns.map((c) => {
            if (c.id === destinationColumn.id) {
              c.tasks = destinationColumn.tasks;
            }
            if (c.id === sourceColumn.id) {
              c.tasks = sourceColumn.tasks;
            }

            return c;
          })
        );
      }
    },
    [
      boardId,
      columns,
      changeOrder,
      transfer,
      updateTicketOrderRequest,
      setMessage,
      t,
    ]
  );

  useEffect(() => {
    const fetchColumns = async () => {
      const { data } = await sendRequest({ bId: boardId, all: true });
      if (data) {
        setColumns(data);
        setColumnOrder((old) => [
          ...data
            .sort((a, b) => a.displayOrder - b.displayOrder)
            .map((c) => c.id),
        ]);
      }
    };

    fetchColumns();
  }, [boardId, sendRequest]);

  const modifiedColumns = useMemo(
    () => filterColumns(columns, filter),
    [columns, filter]
  );

  useEffect(() => {
    const number = modifiedColumns.reduce(
      (acc, column) => acc + (column.tasks?.length || 0),
      0
    );
    onCardsLengthChange(number);
  }, [onCardsLengthChange, modifiedColumns]);

  const value = useMemo(
    () => ({
      columnOrder,
      columns: modifiedColumns,
      boardId,
      loading,
      filter,
      isView,
      onDragEnd,
      ...props,
    }),
    [
      columnOrder,
      boardId,
      loading,
      filter,
      modifiedColumns,
      isView,
      onDragEnd,
      props,
    ]
  );

  return (
    <KanbanContext.Provider value={value}>{children}</KanbanContext.Provider>
  );
};

export default memo(KanbanProvider);
