import { Box, Stack, Typography } from "@mui/material";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";

import { NavLink } from "react-router-dom";
import { memo } from "react";

interface IMenuBoard {
  to: string;
  name: string;
}

const MenuBoard: React.FC<IMenuBoard> = ({ name, to }) => {
  return (
    <NavLink to={to} style={{ textDecoration: "none" }}>
      {({ isActive }) => (
        <Box
          sx={(t) => ({
            padding: t.spacing(1),
            paddingX: t.spacing(2),
            backgroundColor: isActive
              ? t.palette.mode === "light"
                ? "#E9E9E9"
                : "#181818"
              : "transparent",
            color:
              t.palette.text.primary
          })}
        >
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <SpaceDashboardIcon fontSize="small" color="primary" />
            <Typography variant="body2">{name}</Typography>
          </Stack>
        </Box>
      )}
    </NavLink>
  );
};

export default memo(MenuBoard);
