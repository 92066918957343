import { Button, useFetch } from "@4uhub/lib4uhub";
import PersonIcon from "@mui/icons-material/Person";
import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import useTicket from "../../../../hooks/useTask";
import { IGetTicketChannelStep } from "../../../../models/ticket";
import {
  getTicketChannelStep,
  joinTicket,
} from "../../../../services/ticket.service";
import { useTicketHeader } from "../../../../store/context/card-header-context";
import FinishAction from "../FinishAction/FinishAction";
import JoinActionPopover from "./JoinActionPopover";

const translationPath = "components.joinAction.";

const JoinAction = () => {
  const { t } = useTranslation();

  const {
    ticket,
    isUserTicketMember,
    onEditStatus,
    onEditList,
    onEditMembers,
  } = useTicket();

  const { onJoin } = useTicketHeader();

  const { sendRequest, loading: tLoading } = useFetch(getTicketChannelStep);

  const { sendRequest: join, loading: jLoading } = useFetch(joinTicket);

  const divRef = useRef(null);

  const [open, setOpen] = useState(false);

  const [ticketChannelSteps, setTicketChannelSteps] = useState<
    IGetTicketChannelStep[]
  >([]);

  const handleClose = () => setOpen(false);

  const joinTicketHandler = useCallback(
    async (ticketChannelStepId: string) => {
      if (!ticket) return;

      const { data, success } = await join({
        id: ticket?.id,
        ticketChannelId: ticket.ticketChannelStep.ticketChannelId,
        ticketChannelStepId: ticketChannelStepId,
      });

      if (data && success) {
        setOpen(false);
        onJoin && onJoin(data);
        onEditStatus && onEditStatus(data.status);
        onEditList && onEditList(data.ticketChannelStep);
        onEditMembers && onEditMembers(data.ticketMembers);
      }
    },
    [join, ticket, onEditStatus, onEditList, onEditMembers, onJoin]
  );

  const getTicketChannelStepHandler = useCallback(async () => {
    if (!ticket) return;
    const { data, success } = await sendRequest({
      TicketChannelId: ticket?.ticketChannelStep.ticketChannelId,
    });
    if (data && success) {
      if (data.length === 0) {
        setOpen(true);
      }
      if (data.length === 1) {
        joinTicketHandler(data[0].id);
      }
      if (data.length >= 2) {
        setTicketChannelSteps(data);
        setOpen(true);
      }
    }
  }, [sendRequest, ticket, joinTicketHandler]);

  const loading = useMemo(() => tLoading || jLoading, [tLoading, jLoading]);

  return (
    <>
      <JoinActionPopover
        anchorEl={divRef.current}
        onClose={handleClose}
        open={open}
        ticketChannelSteps={ticketChannelSteps}
        joinTicketHandler={joinTicketHandler}
      />
      {isUserTicketMember && ticket?.status.code === "2" && <FinishAction />}
      {!isUserTicketMember && (
        <>
          <Button
            variant="contained"
            endIcon={<PersonIcon />}
            size="small"
            onClick={getTicketChannelStepHandler}
            loading={loading}
          >
            {t(translationPath + "join")}
          </Button>
          <div ref={divRef}></div>
        </>
      )}
    </>
  );
};

export default JoinAction;
