import { GlobalAxios } from "../axios/axios-config";
import { ITicketService } from "../models/ticketService";

const TICKET_SERVICE = process.env.REACT_APP_TICKETS + "/api/v1/TicketServices";

export const getTicketServiceList = () => {
  return GlobalAxios.get<ITicketService[]>(TICKET_SERVICE + `/list`);
};

export const getTicketServiceListByComapnyGroup = () => {
  return GlobalAxios.get<ITicketService[]>(TICKET_SERVICE + `/ByCompanyGroup/list`);
};
