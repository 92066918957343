import {
  DataGrid,
  GridColDef,
  ptBR,
  enUS,
  esES,
  GridRowParams,
} from "@mui/x-data-grid";
import { memo, useCallback } from "react";
import { IEvents } from "../../../models/events";
import { OverflowTooltip } from "@4uhub/lib4uhub";
import NoData from "../../UI/NoData/NoData";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import useEvents from "../../../hooks/useEvents";
import { format } from "date-fns";
import { ptBR as pt, enUS as en, es } from "date-fns/locale";
import useTicket from "../../../hooks/useTask";

const DEFAULT_PAGE_SIZE_OPTS = [5, 10, 20, 50];

const translationPath = "components.events.";

export const EventsList = memo(() => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const {
    rows,
    loading,
    rowCountState,
    paginationModel,
    setPaginationModel,
    onEditEvent,
  } = useEvents();

  const { isUserTicketMember } = useTicket();

  const columns: GridColDef<IEvents>[] = [
    {
      field: "ticketEventType",
      headerName: `${t(translationPath + "event_type")}`,
      flex: 1,
      renderCell: (params) => {
        return params.row.ticketEvent &&
          params.row.ticketEvent.ticketEventType ? (
          <OverflowTooltip
            text={params.row.ticketEvent.ticketEventType.name}
            tooltip={params.row.ticketEvent.ticketEventType.name}
            textTransform="none"
            variant="body2"
          />
        ) : (
          "-"
        );
      },
    },
    {
      field: "ticketEvent",
      headerName: `${t(translationPath + "event")}`,
      flex: 1,
      renderCell: (params) => {
        return params.value ? (
          <OverflowTooltip
            text={params.value.name}
            tooltip={params.value.name}
            textTransform="none"
            variant="body2"
          />
        ) : (
          "-"
        );
      },
    },
    {
      field: "initialDate",
      headerName: `${t(translationPath + "initial_date")}`,
      flex: 1,
      maxWidth: 150,
      renderCell: (params) => {
        return params.value
          ? format(new Date(params.value), "Pp", {
              locale:
                language === "pt-BR" ? pt : language === "en-US" ? en : es,
            }).replaceAll(",", "")
          : "-";
      },
    },
    {
      field: "endDate",
      headerName: `${t(translationPath + "end_date")}`,
      flex: 1,
      maxWidth: 150,
      renderCell: (params) => {
        return params.value
          ? format(new Date(params.value), "Pp", {
              locale:
                language === "pt-BR" ? pt : language === "en-US" ? en : es,
            }).replaceAll(",", "")
          : "-";
      },
    },
    {
      field: "createdByUserName",
      headerName: `${t(translationPath + "created_by")}`,
      flex: 1,
    },
  ];

  const noEventsFound = useCallback(() => {
    return (
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <NoData message={t(translationPath + "no_data")} />
      </Box>
    );
  }, [t]);

  const rowClickHandler = useCallback(
    (params?: GridRowParams<IEvents>) => {
      if (!params || !isUserTicketMember) return;

      onEditEvent(params?.row.id);
    },
    [onEditEvent, isUserTicketMember]
  );

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      disableColumnMenu
      disableColumnFilter
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      rowCount={rowCountState}
      onRowClick={rowClickHandler}
      hideFooterSelectedRowCount
      loading={loading}
      sx={(t) => ({
        height: "92%",
        backgroundColor: t.palette.background.paper,
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor:
            t.palette.mode === "dark"
              ? t.palette.grey[900]
              : t.palette.grey[200],
        },
      })}
      pageSizeOptions={DEFAULT_PAGE_SIZE_OPTS}
      slots={{
        noRowsOverlay: noEventsFound,
        noResultsOverlay: noEventsFound,
      }}
      localeText={
        language === "pt-BR"
          ? ptBR.components.MuiDataGrid.defaultProps.localeText
          : language === "en-US"
          ? enUS.components.MuiDataGrid.defaultProps.localeText
          : esES.components.MuiDataGrid.defaultProps.localeText
      }
    />
  );
});
