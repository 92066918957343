import { ListItemText, MenuItem, Popover } from "@mui/material";
import { useTranslation } from "react-i18next";

import usePopover from "../../../../../../hooks/usePopover";
import DefaultStatusForm from "./DefaultStatusForm";
import { useFetch } from "@4uhub/lib4uhub";
import { changeStatus } from "../../../../../../services/column.serivice";
import { TEditDefaultStatusForm } from "./DefaultStatusSchema";
import { useCallback } from "react";
import useColumn from "../../../../../../hooks/useColumn";

interface IDefaultStatusProps {
  id: string;
}

const EditListDefaultStatus: React.FC<IDefaultStatusProps> = ({
  id: cardId,
}) => {
  const { t } = useTranslation();

  const { editColumnStatus } = useColumn();

  const { sendRequest, loading } = useFetch(changeStatus);

  const { anchorEl, handleClick, handleClose, open, id } = usePopover(
    "column-edit-default-status-popover"
  );

  const handleSubmit = useCallback(
    async (formData: TEditDefaultStatusForm) => {
      const { data, success } = await sendRequest({
        id: cardId,
        statusId: formData.status.id,
      });

      if (data && success) {
        handleClose();
        editColumnStatus(formData.status);
      }
    },
    [sendRequest, handleClose, cardId, editColumnStatus]
  );

  return (
    <>
      <MenuItem aria-describedby={id} onClick={handleClick}>
        <ListItemText>
          {t("components.column.menu.changeDefaultStatus.title")}
        </ListItemText>
      </MenuItem>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {open && <DefaultStatusForm onSubmit={handleSubmit} loading={loading} />}
      </Popover>
    </>
  );
};

export default EditListDefaultStatus;
