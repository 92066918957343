import { useFetch } from "@4uhub/lib4uhub";
import { memo } from "react";

import useColumn from "../../../../hooks/useColumn";
import { editColumn } from "../../../../services/column.serivice";
import EditableTypography from "../../../UI/EditableTypography";

interface IColumnTitleProps {
  ColumnOpen?: boolean;
}

const ColumnTitle: React.FC<IColumnTitleProps> = ({ ColumnOpen }) => {
  const { data: columnData, contrastColor, editColumnName } = useColumn();

  const { sendRequest } = useFetch(editColumn);

  const editRequest = async (title: string) => {
    if (title && title.length > 0) {
      const { data, success } = await sendRequest({
        ...columnData,
        name: title,
      });
      if (data && success) {
        editColumnName(title);
      }
    }
  };

  return (
    <EditableTypography
      textfieldProps={{
        sx: (t) => ({
          input: {
            color: (contrastColor || t.palette.common.black),
          },
        }),
      }}
      typographyProps={{
        sx: !ColumnOpen
          ? {
              writingMode: "vertical-rl",
              textOrientation: "inherit",
            }
          : {
              maxWidth: "60%",
              textOverflow: "ellipsis",
              overflow: "hidden",
            },
        color: (t) => contrastColor || t.palette.common.black,
      }}
      onEdit={editRequest}
      value={columnData.name}
      disabled={!ColumnOpen}
    />
  );
};

export default memo(ColumnTitle);
