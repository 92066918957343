import { Loading } from "@4uhub/lib4uhub";
import { IconButton as IB, IconButtonProps } from "@mui/material";

const IconButton: React.FC<IconButtonProps & { loading?: boolean }> = ({
  loading,
  ...otherprops
}) => {
  let size = 20;

  switch (otherprops.size) {
    case "large": {
      size = 30;
      break;
    }
    case "medium": {
      size = 25;
      break;
    }
  }
  if (loading) {
    return <Loading sx={{ position: "relative" }} size={size}></Loading>;
  }

  return <IB {...otherprops}>{otherprops.children}</IB>;
};

export default IconButton;
