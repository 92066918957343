import { useFetch } from "@4uhub/lib4uhub";
import { ListItemText, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";

import { archiveColumnById } from "../../../../../services/column.serivice";

interface IArchiveListProps {
  id: string;
}

const ArchiveList: React.FC<IArchiveListProps> = ({ id }) => {

  const { sendRequest } = useFetch(archiveColumnById);

  const { t } = useTranslation();

  const archiveList = () => {
    sendRequest(id);
  };

  return (
    <MenuItem onClick={archiveList}>
      <ListItemText>{t("components.column.menu.archive.title")}</ListItemText>
    </MenuItem>
  );
};

export default ArchiveList;
