import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";

const BASE_URL = process.env.REACT_APP_TICKETS + "/api/v1/TicketChannels/";

export const ticketChannelsList = (workspaceId: string) => {
  return GlobalAxios.get<ISelectType[]>(
    BASE_URL + "TicketWorkspaces/all/" + workspaceId
  );
};
