import {
  Form,
  ISelectType,
  useFetch,
  useNotificationContext,
} from "@4uhub/lib4uhub";
import { Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";

import MainApiService from "../../../../services/mainApi.service";
import MembersList from "./MembersList";
import InviteMemberForm from "./InviteMemberForm";
import { ITicketChannelProfile } from "../../../../models/ticket-channels";
import { useMembersContext } from "../../../../store/context/members-context";
import { TInviteMemberForm, inviteMemberSchema } from "./inviteMemberSchema";
import { useTranslation } from "react-i18next";

const PROFILES_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketProfiles/";
const profilesService = new MainApiService<ISelectType>(PROFILES_ROUTE);

const TICKET_CHANNELS_PROFILE_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketChannelProfiles/";
const ticketChannelsProfilesService = new MainApiService<ITicketChannelProfile>(
  TICKET_CHANNELS_PROFILE_ROUTE
);

const InviteMemberModal = () => {
  const [profilesOptions, setProfilesOptions] = useState<ISelectType[]>([]);

  const { t } = useTranslation();

  const { setMessage } = useNotificationContext();

  const { boardId } = useParams();

  const { addMember } = useMembersContext();

  const { sendRequest } = useFetch(profilesService.list);

  const methods = useForm<TInviteMemberForm>({
    resolver: zodResolver(inviteMemberSchema),
  });

  const { resetField } = methods;

  const loadProfilesOptions = useCallback(async () => {
    const { data, success } = await sendRequest(undefined);
    if (data && success) {
      setProfilesOptions(data);
      if (data.length > 0) {
        resetField("profile", { defaultValue: data[0] });
      }
    }
  }, [sendRequest, resetField]);

  useEffect(() => {
    loadProfilesOptions();
  }, [loadProfilesOptions]);

  const { sendRequest: add, loading } = useFetch(
    ticketChannelsProfilesService.create
  );

  const submitHandler = useCallback(
    async (form: TInviteMemberForm) => {
      if (boardId) {
        const { data, success } = await add({
          ticketChannelId: boardId,
          ticketProfileId: form.profile.id,
          userId: form.user.id,
          nickName: form.nickName,
        });
        if (data && success) {
          setMessage({
            message: t("components.boardHeader.member.member_added"),
            type: "success",
          });
          addMember(data);
        }
      }
    },
    [boardId, addMember, setMessage, add, t]
  );

  const handleSubmit = () => {
    methods.handleSubmit(submitHandler)();
    methods.reset();
  };

  return (
    <Stack width={"100%"}>
      <Form {...methods}>
        <InviteMemberForm
          profilesOptions={profilesOptions}
          handleSubmit={handleSubmit}
          loading={loading}
        />
      </Form>
      <MembersList profilesOptions={profilesOptions} />
    </Stack>
  );
};

export default InviteMemberModal;
