import { createContext, memo, useMemo } from "react";

import { useUser } from "@4uhub/lib4uhub";
import { IEditTicketFunctions } from "../../hooks/useEditTicket";
import useSignalGroup from "../../hooks/useSignalGroup";
import { IGetTicket } from "../../models/ticket";

interface ITicketContext extends IEditTicketFunctions {
  ticket: IGetTicket;
  loading?: boolean;
  isUserTicketMember?: boolean;
}

export const TicketContext = createContext<ITicketContext | undefined>(
  undefined
);

interface ITicketProviderProps extends ITicketContext {
  children: any;
  loading?: boolean;
}

const TicketProvider: React.FC<ITicketProviderProps> = ({
  children,
  ticket,
  loading,
  onEditCustomerServiceType,
  onEditList,
  onEditContent,
  onEditEntityCustomer,
  onEditIndividualCustomer,
  onEditMembers,
  onEditOrigin,
  onEditRequester,
  onEditStatus,
  onEditTitle,
  onEditType,
  onEditticketRelationships,
  onGetWhatsappNumber,
}) => {
  const { user } = useUser();

  const { id } = ticket;

  const groupId = useMemo(() => `Ticket|${id}`, [id]);

  const isUserTicketMember = useMemo(() => {
    const { ticketMembers: members } = ticket || {};

    return members.some(({ user: { id: memberId } }) => memberId === user?.id);
  }, [ticket, user]);

  useSignalGroup(groupId);

  const value: ITicketContext = useMemo(
    () => ({
      loading,
      ticket,
      isUserTicketMember,
      onEditTitle,
      onEditStatus,
      onEditType,
      onEditContent,
      onEditRequester,
      onEditIndividualCustomer,
      onEditEntityCustomer,
      onEditOrigin,
      onEditCustomerServiceType,
      onEditMembers,
      onEditList,
      onEditticketRelationships,
      onGetWhatsappNumber,
    }),
    [
      loading,
      ticket,
      isUserTicketMember,
      onEditTitle,
      onEditStatus,
      onEditType,
      onEditContent,
      onEditRequester,
      onEditIndividualCustomer,
      onEditEntityCustomer,
      onEditOrigin,
      onEditCustomerServiceType,
      onEditMembers,
      onEditList,
      onEditticketRelationships,
      onGetWhatsappNumber,
    ]
  );

  return (
    <TicketContext.Provider value={value}>
      {value.ticket && children}
    </TicketContext.Provider>
  );
};

export default memo(TicketProvider);
