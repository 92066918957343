import { ISearchReturn } from "@4uhub/lib4uhub/dist/types/models";
import { GlobalAxios } from "../axios/axios-config";
import { IListQueryParams, IListReturn } from "../models/ListParams";
import { IGetColumn } from "../models/columns";
import {
  ICreateTicket,
  IExternalProcessTypeTicketParams,
  IFinishTicketReturn,
  IGetTableTicket,
  IGetTicket,
  IGetTicketById,
  ITicketServiceParams,
  ITransferTicket,
} from "../models/ticket";

const TICKETS_ROUTE = process.env.REACT_APP_TICKETS + "/api/v1/Ticket";

export const createTicket = (ticket: ICreateTicket) => {
  return GlobalAxios.post<IGetTicket>(TICKETS_ROUTE, ticket);
};

interface IGetTicketByChannelIdParams {
  TicketDateFilterCode?: string;
  StartDate?: string;
  FinishDate?: string;
  Id: string;
}

export const getTicketByChannelId = (params: IGetTicketByChannelIdParams) => {
  return GlobalAxios.get<IGetTicket[]>(
    TICKETS_ROUTE + `/TicketChannelStep/${params.Id}`,
    {
      params: {
        TicketDateFilterCode: params.TicketDateFilterCode,
        StartDate: params.StartDate,
        FinishDate: params.FinishDate,
      },
    }
  );
};

export const getTicketId = (ticketId: string) => {
  return GlobalAxios.get<IGetTicketById>(TICKETS_ROUTE + `/${ticketId}`);
};

export const archiveTicketById = (ticketId: string) => {
  return GlobalAxios.put(TICKETS_ROUTE + `/${ticketId}/Archive`);
};

export const getArchivedTicket = (
  params: IListQueryParams & { TicketChannelId: string }
) => {
  return GlobalAxios.get<IListReturn<IGetTicket>>(TICKETS_ROUTE + `/Archived`, {
    params,
  });
};

export const unarchiveTicketById = (ticketId: string) => {
  return GlobalAxios.put(TICKETS_ROUTE + `/${ticketId}/Unarchive`);
};

export const disableTicketById = (ticketId: string) => {
  return GlobalAxios.put(TICKETS_ROUTE + `/${ticketId}/Disable`);
};

export const transferTicket = (params: ITransferTicket) => {
  return GlobalAxios.put(TICKETS_ROUTE + `/Transfer`, params);
};

export const editTicketTitle = ({
  id,
  title,
}: {
  title: string;
  id: string;
}) => {
  return GlobalAxios.put(TICKETS_ROUTE + `/Title/${id}`, { id, title });
};

export const editTicketStatus = ({
  id,
  statusId,
}: {
  statusId: string;
  id: string;
}) => {
  return GlobalAxios.put(TICKETS_ROUTE + `/Status/${id}`, { id, statusId });
};

export const editTicketOrigin = ({
  id,
  ticketOriginTypeId,
}: {
  ticketOriginTypeId: string;
  id: string;
}) => {
  return GlobalAxios.put(TICKETS_ROUTE + `/Origin/${id}`, {
    id,
    ticketOriginTypeId,
  });
};

export const editTicketType = ({
  id,
  typeId,
}: {
  typeId: string;
  id: string;
}) => {
  return GlobalAxios.put(TICKETS_ROUTE + `/Type/${id}`, { id, typeId });
};

export const editContent = ({
  id,
  content,
}: {
  content: string;
  id: string;
}) => {
  return GlobalAxios.put(TICKETS_ROUTE + `/Content/${id}`, { id, content });
};

export const editRequester = ({
  id,
  individualRequesterId,
}: {
  individualRequesterId: string;
  id: string;
}) => {
  return GlobalAxios.put(TICKETS_ROUTE + `/IndividualRequester/${id}`, {
    id,
    individualRequesterId,
  });
};

export const editIndividualCustomer = ({
  id,
  individualCustomerId,
}: {
  individualCustomerId: string;
  id: string;
}) => {
  return GlobalAxios.put(TICKETS_ROUTE + `/IndividualCustomer/${id}`, {
    id,
    individualCustomerId,
  });
};

export const editLegalEntityCustomer = ({
  id,
  legalEntityCustomerId,
}: {
  legalEntityCustomerId: string;
  id: string;
}) => {
  return GlobalAxios.put(TICKETS_ROUTE + `/LegalEntityCustomer/${id}`, {
    id,
    legalEntityCustomerId,
  });
};

export const editTicketCustomerServiceType = ({
  id,
  ticketCustomerServiceTypeId,
}: {
  ticketCustomerServiceTypeId: string;
  id: string;
}) => {
  return GlobalAxios.put(TICKETS_ROUTE + `/TicketCustomerServiceType/${id}`, {
    id,
    ticketCustomerServiceTypeId,
  });
};

export const getOpenedTickets = ({
  TicketChannelId,
}: {
  TicketChannelId: string;
}) => {
  return GlobalAxios.get<IGetTicket[]>(TICKETS_ROUTE + `/Open`, {
    params: { TicketChannelId },
  });
};

export const finishTicket = ({ id }: { id: string }) => {
  return GlobalAxios.put<IFinishTicketReturn>(TICKETS_ROUTE + `/Finish`, {
    id,
  });
};

export const getUserTickets = ({
  TicketChannelId,
}: {
  TicketChannelId: string;
}) => {
  return GlobalAxios.get<IGetTicket[]>(TICKETS_ROUTE + `/InProcess`, {
    params: { TicketChannelId },
  });
};

export const joinTicket = ({
  ticketChannelId,
  ticketChannelStepId,
  id,
}: {
  ticketChannelId: string;
  ticketChannelStepId: string;
  id: string;
}) => {
  return GlobalAxios.put<IGetTicket>(TICKETS_ROUTE + `/Ingress`, {
    ticketChannelId,
    ticketChannelStepId,
    id,
  });
};

export const updateTicketOrder = ({
  ticketChannelStepId,
  ticketIds,
}: {
  ticketChannelStepId: string;
  ticketIds: string[];
}) => {
  return GlobalAxios.put<IGetTicket>(TICKETS_ROUTE + `/UpdateOrder`, {
    ticketChannelStepId,
    ticketIds,
  });
};

const TICKET_CHANNEL_STEPS_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketChannelSteps/";

export const getTicketChannelStep = ({
  TicketChannelId,
  StatusCode,
}: {
  TicketChannelId: string;
  StatusCode?: string;
}) => {
  return GlobalAxios.get<IGetColumn[]>(
    TICKET_CHANNEL_STEPS_ROUTE + `GetTicketChannelStepByStatusCode`,
    {
      params: {
        TicketChannelId,
        StatusCode,
      },
    }
  );
};

export const getTicketsByTicketChannelId = ({
  Id,
  FinishDate,
  StartDate,
  TicketDateFilterCode,
}: IGetTicketByChannelIdParams) => {
  return GlobalAxios.get<IGetTableTicket[]>(
    TICKETS_ROUTE + `/TicketChannel/${Id}`,
    {
      params: {
        TicketDateFilterCode: TicketDateFilterCode,
        StartDate: StartDate,
        FinishDate: FinishDate,
      },
    }
  );
};

export const reopenTicket = (id: string) => {
  return GlobalAxios.put(TICKETS_ROUTE + `/ReopenTicket/${id}`, {
    id,
  });
};

export const externalProcessTypeTicket = (
  params: IExternalProcessTypeTicketParams
) => {
  return GlobalAxios.put(
    TICKETS_ROUTE + `/ExternalProcessTypeTicket/${params.id}`,
    params
  );
};

export const sameWhatsappNumber = (params: ITicketServiceParams) => {
  return GlobalAxios.get<ISearchReturn<IGetTicket>>(
    TICKETS_ROUTE + `/sameWhatsappNumber`,
    { params }
  );
};

export const sameRequester = (params: ITicketServiceParams) => {
  return GlobalAxios.get<ISearchReturn<IGetTicket>>(
    TICKETS_ROUTE + `/sameRequester`,
    { params }
  );
};

export const sameCustomer = (params: ITicketServiceParams) => {
  return GlobalAxios.get<ISearchReturn<IGetTicket>>(
    TICKETS_ROUTE + `/sameCustomer`,
    { params }
  );
};
export const exitTicket = ({
  id,
  ticketChannelStepId,
}: {
  id: string;
  ticketChannelStepId?: string;
}) => {
  return GlobalAxios.put(`${TICKETS_ROUTE}/ExitTicket/${id}`, {
    id,
    ticketChannelStepId,
  });
};
