import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import WorkspaceForm from "./WorkspaceForm/WorkspaceForm";
import { IGetWorksSpace } from "../../models/workspaces";

interface IWorkspaceModalCreateProps {
  handleClose: () => void;
  workspace?: IGetWorksSpace;
}

const WorkspaceModalCreate: React.FC<IWorkspaceModalCreateProps> = ({
  handleClose,
  workspace,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Box maxHeight={600} overflow={"hidden"} minWidth={300}>
      <Grid container direction={!matches ? "column-reverse" : "row"} gap={2} width={'100%'}>
        <Grid item flex={1}>
          <WorkspaceForm handleClose={handleClose} id={workspace?.id} />
        </Grid>
        <Grid item alignSelf={"center"}>
          <img
            src="/kanban-board.png"
            width={!matches ? 200 : 300}
            height={"auto"}
            alt="kanban"
            style={{
              position: "relative",
              zIndex: 1,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default WorkspaceModalCreate;
