import { useContext } from "react";
import { EventsContext } from "../store/context/EventsContext/events-context";

const useEvents = () => {
  const board = useContext(EventsContext);

  if (board === undefined) {
    throw new Error("useEvents must be used within a ChecklistsProvider");
  }

  return board;
};

export default useEvents;
