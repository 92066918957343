import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import {
  AppLoading,
  ConfigProvider,
  IConfiguration,
  IPrivilege,
  IUser,
  PrivilegesContextProvider,
  UserProvider,
  useFetch,
} from "@4uhub/lib4uhub";

import {
  createUserConfig,
  getUserConfig,
} from "../services/configuration.service";
import { listEstablishments } from "../services/establishment.service";
import { listLanguages } from "../services/language.service";
import {
  savePermissions,
  saveUserName,
  userMe,
  userMePrivileges,
} from "../services/user.service";

import Menu from "../components/UI/Menu/Menu";
import Body from "../components/UI/Body";
import MembersProvider from "../store/context/members-context";
import useSignalGroup from "../hooks/useSignalGroup";
import { useParams } from "react-router-dom";
import { SignalRContext } from "../components/SignalR/SignalRProvider";
import { IGetWorksSpace } from "../models/workspaces";
import { useAppDispatch } from "../store/store";
import { workspacesSliceActions } from "../store/slices/workspaces";

const RootPage = () => {
  const [config, setConfig] = useState<IConfiguration>();

  const { loading: userConfigLoading, sendRequest } = useFetch(getUserConfig, {
    startLoadingTrue: true,
  });

  const { sendRequest: userPrivilegesRequest, loading: userPrivilegeLoading } =
    useFetch(userMePrivileges, {
      startLoadingTrue: true,
    });

  const { sendRequest: createConfig } = useFetch(createUserConfig);

  const dispatch = useAppDispatch();

  const fetchConfig = useCallback(async () => {
    const { data, success } = await sendRequest(null);
    if (success && data?.id) {
      setConfig((old) => ({
        ...data,
        webAppearance: {
          code: data.webAppearance.code,
          menuOpen: true,
        },
      }));
    }
  }, [sendRequest, setConfig]);

  const [user, setUser] = useState<IUser>();

  const [privileges, setPrivileges] = useState<IPrivilege[]>([]);

  const getPrivileges = useCallback(async () => {
    const { data } = await userPrivilegesRequest(null);
    if (data) {
      savePermissions(data);
      setPrivileges(data);
    }
  }, [userPrivilegesRequest]);

  const { sendRequest: userMeRequest, loading: userMeLoading } = useFetch(
    userMe,
    {
      startLoadingTrue: true,
    }
  );

  const getUserMe = useCallback(async () => {
    const { data, success } = await userMeRequest(null);
    if (success && data) {
      saveUserName(data.userName);
      setUser(data);
    }
  }, [userMeRequest]);

  useLayoutEffect(() => {
    getPrivileges();
  }, [getPrivileges]);

  useLayoutEffect(() => {
    fetchConfig();
    getUserMe();
  }, [fetchConfig, getUserMe]);

  const onConfigChangeHandler = useCallback(
    async (cnfig: IConfiguration) => {
      createConfig({
        deleteMediaFileIds: [],
        establishmentStandardId: cnfig.establishment.id,
        languageId: cnfig.language.id,
        mediaFileIds: [],
        parentName: "ApplicationUser",
        softwareAppearanceCode: cnfig.webAppearance.code,
      });
    },
    [createConfig]
  );
  const i18nProvider = useTranslation();

  const loading = useMemo(
    () => userMeLoading || userConfigLoading,
    [userMeLoading, userConfigLoading]
  );

  const { eI } = useParams();

  const crmGroup = `CRM|${eI}`;

  useSignalGroup(crmGroup);

  SignalRContext.useSignalREffect(
    "NewTicketWorkspaceCreated", // Your Event Key
    (userId: string, data: IGetWorksSpace) => {
      console.log("NewTicketWorkspaceCreated", data);
      dispatch(workspacesSliceActions.addWorkspace(data));
    },
    [dispatch]
  );

  SignalRContext.useSignalREffect(
    "ArchiveTicketWorkspace", // Your Event Key
    (userId: string, data: { id: string }) => {
      console.log("ArchiveTicketWorkspace", data);
      dispatch(workspacesSliceActions.removeWorkspace(data.id));
    },
    [dispatch]
  );

  SignalRContext.useSignalREffect(
    "UnArchiveTicketWorkspace", // Your Event Key
    (userId: string, data: IGetWorksSpace) => {
      console.log("UnArchiveTicketWorkspace", data);
      dispatch(workspacesSliceActions.addWorkspace(data));
    },
    [dispatch]
  );

  return (
    <ConfigProvider
      i18n={i18nProvider}
      configInitialState={config}
      onConfigChange={onConfigChangeHandler}
      listEstablishments={listEstablishments}
      languageRequest={listLanguages}
    >
      <>
        {(loading || userPrivilegeLoading) && <AppLoading />}
        {!userPrivilegeLoading && (
          <PrivilegesContextProvider privileges={privileges}>
            <>
              {user && (
                <UserProvider user={user}>
                  <MembersProvider>
                    <Stack
                      height={"100vh"}
                      overflow={"hidden"}
                      direction={"row"}
                      sx={(t) => ({
                        flexDirection: "row",
                        [t.breakpoints.down("lg")]: {
                          flexDirection: "column",
                        },
                      })}
                    >
                      <Menu />
                      <Body
                        sx={(t) => ({
                          width: "calc(100% - 65.5px)",
                          height: "100%",
                          [t.breakpoints.down("lg")]: {
                            width: "100%",
                            height: "calc(100% - 65.5px)",
                          },
                        })}
                      />
                    </Stack>
                  </MembersProvider>
                </UserProvider>
              )}
            </>
          </PrivilegesContextProvider>
        )}
      </>
    </ConfigProvider>
  );
};

export default RootPage;
