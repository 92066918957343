import { memo } from "react";
import { useTranslation } from "react-i18next";

import { ISelectType } from "@4uhub/lib4uhub";
import useGeneric from "../../../../hooks/useGeneric";
import usePopover from "../../../../hooks/usePopover";
import useTicket from "../../../../hooks/useTask";
import ServiceTypeIcon from "../../../Kamban/ServiceTypeIcon";
import HoverOpacity from "../../../UI/HoverOpacity";
import Property from "../../Property";
import CardTypeSelector from "./CardTypeSelector";

interface ICardTypeProps {
  id: string;
  type: ISelectType;
  loading?: boolean;
}
const CardType: React.FC<ICardTypeProps> = ({ id, type, loading }) => {
  const { t } = useTranslation();

  const {
    anchorEl,
    handleClick,
    handleClose,
    id: PopoverId,
    open,
  } = usePopover("ticket-type-popover");

  const { data } = useGeneric("TicketType");

  const {
    isUserTicketMember,
    ticket: { status },
  } = useTicket();

  const handleChange = () => {
    handleClose();
  };

  return (
    <>
      <HoverOpacity
        onClick={handleClick}
        disabled={!isUserTicketMember || status.code === "3"}
      >
        <Property
          label={t("page.card.type")}
          loading={loading}
          content={
            <ServiceTypeIcon
              type={type}
              iconProps={{
                fontSize: "medium",
                color: isUserTicketMember ? "inherit" : "disabled",
                opacity: isUserTicketMember ? 1 : 0.5,
              }}
            />
          }
        />
      </HoverOpacity>
      {open && (
        <CardTypeSelector
          anchorEl={anchorEl}
          ticketId={id}
          onChange={handleChange}
          onClose={handleClose}
          open={open}
          id={PopoverId}
          selected={type.id}
          data={data}
        />
      )}
    </>
  );
};

export default memo(CardType);
