import { Grid, Stack, Tooltip, Typography } from "@mui/material";
import { memo, MouseEvent, useCallback, useMemo, useState } from "react";

import { IGetTicket, ILegalEntity, Iindividual } from "../../../models/ticket";
import Status from "../../CardPage/CardOtherInfo/Status/Status";
import { OverflowTooltip } from "../../UI/TooltipOverflow/TooltipOverflow";
import CardContainer from "./../CardContainer";
import ServiceTypeIcon from "./../ServiceTypeIcon";
import TaskHeader, { EUserType } from "./TaskHeader";
import TaskMembersList from "./TaskMembersList";
import TaskTagsList from "./TaskTagsList";
import TicketServiceBadge from "./TicketServiceBadge";
import UnreadMessages from "./UnreadMessages";
import { Modal, Button } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";
import { useInstalledExtensions } from "../../../hooks/useInstalledExtensions";
import { Extension } from "../../../models/intalled-extensions";
import SalesContractInfoModal from "../../CardPage/CardOtherInfo/SalesContract/SalesContractInfoModal";

export const getType = (
  individualCustomer: Iindividual | null,
  legalEntityCustomer: ILegalEntity | null
) => {
  if (individualCustomer) {
    return EUserType.PF;
  }

  if (legalEntityCustomer) {
    return EUserType.PJ;
  }

  return EUserType.NI;
};
interface ITaskProps extends IGetTicket {
  children?: any;
  isDragging?: boolean;
  onClick?: (id: string) => void;
}

const Task: React.FC<ITaskProps> = ({
  id,
  title,
  protocolNumber,
  type,
  onClick,
  isDragging,
  ticketChannelTags,
  ticketMembers,
  legalEntityCustomer,
  individualCustomer,
  ticketOrigins,
  created,
  status,
  notIdentifiedName,
  unreadMessages,
  ticketService,
  ticketRelationships,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick(id);
    }
  };
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const closeHandler = useCallback(() => {
    setOpen(false);
  }, []);

  const openModal = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    e.stopPropagation();
    setOpen(true);
  };

  const [salesContractInstalled] = useInstalledExtensions(
    Extension.SALES_CONTRACT_MANAGEMENT
  );

  const name = useMemo(
    () =>
      individualCustomer
        ? individualCustomer.name
        : legalEntityCustomer
        ? legalEntityCustomer.corporateName
        : ticketOrigins && ticketOrigins.length > 0
        ? ticketOrigins[0].name
        : notIdentifiedName
        ? notIdentifiedName
        : undefined,
    [individualCustomer, legalEntityCustomer, ticketOrigins, notIdentifiedName]
  );

  return (
    <>
      <Modal
        open={open}
        onClose={closeHandler}
        title={`${t("page.card.sales_contract")}`}
      >
        <SalesContractInfoModal id={id} />
      </Modal>
      <CardContainer isDragging={isDragging}>
        <Stack onClick={handleClick} gap={0.5}>
          <TaskHeader
            created={created}
            name={name}
            type={getType(individualCustomer, legalEntityCustomer)}
          />
          <TaskTagsList ticketChannelTags={ticketChannelTags} />
          <Stack>
            <OverflowTooltip text={title} tooltip={title} />
            <Grid
              container
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Stack direction={"row"} gap={1} alignItems={"center"}>
                {type?.code && (
                  <ServiceTypeIcon
                    type={type}
                    iconProps={{
                      fontSize: "inherit",
                      sx: (t) => ({
                        color: t.palette.grey[500],
                      }),
                    }}
                  />
                )}
                <Typography
                  variant="caption"
                  fontSize={14}
                  color={(t) => t.palette.grey[500]}
                >
                  #{protocolNumber}
                </Typography>
              </Stack>
            </Grid>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            ></Stack>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              mt={0.5}
            >
              <Stack direction={"row"} gap={1} alignItems={"center"}>
                <Status data={status} expandable />
                {ticketRelationships &&
                  Array.isArray(ticketRelationships) &&
                  ticketRelationships.some(
                    (rel) => rel.salesContractBeneficiary
                  ) &&
                  salesContractInstalled && (
                    <Button
                      onClick={openModal}
                      sx={{ p: 0, m: 0, minWidth: 0 }}
                    >
                      <Tooltip title={t("page.card.client_sales_contract")}>
                        <img
                          src="/4uSalesContractFavicon.png"
                          alt="Sales Contract Info"
                          style={{ width: 21, height: 21 }}
                        />
                      </Tooltip>
                    </Button>
                  )}
                {unreadMessages > 0 && (
                  <UnreadMessages number={unreadMessages} />
                )}
              </Stack>
              <TaskMembersList ticketMembers={ticketMembers} />
            </Stack>
            <TicketServiceBadge ticketService={ticketService} />
          </Stack>
        </Stack>
      </CardContainer>
    </>
  );
};

export default memo(Task);
