import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import { Button, Grid } from "@mui/material";
import { memo, useState } from "react";
import { Modal } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";

import InviteMemberModal from "./InviteMemberModal";
import useBoardRole from "../../../../hooks/useBoardRole";

const translationPath = "components.boardHeader.";

const InviteMember = () => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const addPermission = useBoardRole("1");

  const onCloseModalHandler = () => setOpen(false);

  const onClickHandler = () => setOpen(true);

  return (
    <Grid item xs={"auto"}>
      <Modal
        open={open}
        onClose={onCloseModalHandler}
        title={`${t(translationPath + "invite_member")}`}
        maxDialogWidth={"md"}
      >
        <InviteMemberModal />
      </Modal>
      {addPermission && (
        <Button
          startIcon={<PersonAddAlt1Icon />}
          variant="contained"
          size="small"
          onClick={onClickHandler}
        >
          {t(translationPath + "invite")}
        </Button>
      )}
    </Grid>
  );
};

export default memo(InviteMember);
