import { memo } from "react";
import ResultCard from "../../../UI/ResultCard/ResultCard";
import { Box, CircularProgress } from "@mui/material";
import { Button } from "@4uhub/lib4uhub";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IProtocolsDefaultListProps } from "./models";
import NoData from "../../../UI/NoData/NoData";
import { useProtocol } from "../../../../store/context/protocol-context";
import { useTranslation } from "react-i18next";

const translationPath = "components.protocolsDefaultList.";

const ProtocolsDefaultList = ({
  protocols,
  loading,
  hasNextPage,
  onViewMore,
}: IProtocolsDefaultListProps) => {
  const { selectedProtocol, selectProtocolHandler } = useProtocol();

  const { t } = useTranslation();

  return (
    <Box
      sx={(t) => ({
        px: 1,
        display: "flex",
        flexDirection: "column",
        gap: 1,
      })}
    >
      {loading && (
        <Box width={"100%"} display={"flex"} justifyContent={"center"}>
          <CircularProgress />
        </Box>
      )}
      {!loading && protocols.length === 0 && (
        <NoData message={t(translationPath + "no_results")} />
      )}
      {!loading &&
        protocols.length !== 0 &&
        protocols.map((protocol) => (
          <ResultCard
            title={protocol.title}
            onClick={() => selectProtocolHandler(protocol)}
            active={selectedProtocol?.id === protocol.id}
            key={protocol.id}
          />
        ))}
      {!loading && hasNextPage && protocols.length !== 0 && (
        <Button startIcon={<ExpandMoreIcon />} onClick={onViewMore}>
          {t(translationPath + "view_more")}
        </Button>
      )}
    </Box>
  );
};

export default memo(ProtocolsDefaultList);
