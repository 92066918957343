import { Button, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import useColumn from "../../../../hooks/useColumn";
import { useTranslation } from "react-i18next";
import { memo } from "react";

interface IAddCardButtonProps {
  onClick: () => any;
}

const AddCardButton: React.FC<IAddCardButtonProps> = ({ onClick }) => {
  const { contrastColor } = useColumn();

  const { t } = useTranslation();

  return (
    <Stack mt={1}>
      <Button
        variant="text"
        startIcon={<AddIcon />}
        sx={(t) => ({
          justifyContent: "flex-start",
          color: contrastColor || t.palette.primary.main,
        })}
        onClick={onClick}
      >
        {t("components.column.task.addTask")}
      </Button>
    </Stack>
  );
};

export default memo(AddCardButton);
