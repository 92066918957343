import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import Property from "../Property";
import { memo } from "react";

interface ITicketSequentialProps {
  sequential: number;
}

const TicketSequential: React.FC<ITicketSequentialProps> = ({ sequential }) => {
  const { t } = useTranslation();
  return (
    <Property
      content={
        <Typography color={(t) => t.palette.text.primary} fontSize={14}>
          #{sequential}
        </Typography>
      }
      label={t("page.card.number")}
    />
  );
};

export default memo(TicketSequential);
