import { GlobalAxios } from "../axios/axios-config";
import { ICardMember } from "../models/card-members";

const BASE_URL = process.env.REACT_APP_TICKETS + "/api/v1/TicketMembers";

export class CardMembersService<T extends any> {
  route_path?: string;

  constructor(rp?: string) {
    this.route_path = rp;
  }

  joinCard = (item: T) => {
    return GlobalAxios.post<ICardMember>(BASE_URL + "/CreateJustTicket", item);
  };

  addMember = (item: T) => {
    return GlobalAxios.post<ICardMember>(BASE_URL, item);
  };

  removeMember = (id: string) => {
    return GlobalAxios.delete(BASE_URL + `/${id}`);
  };
}

export default CardMembersService;
