import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import { Loading } from "@4uhub/lib4uhub";
import { Box, IconButton, Tooltip } from "@mui/material";

import PopoverDelete from "../../../UI/PopoverDelete";
import usePopover from "../../../../hooks/usePopover";

interface IRemoveBoardProps {
  onDelete: () => void;
  secureReferenceText?: string;
  loading?: boolean;
}

const RemoveBoard: React.FC<IRemoveBoardProps> = ({
  onDelete,
  loading,
  secureReferenceText,
}) => {
  const { anchorEl, id, handleClick, handleClose, open } = usePopover(
    "remove-board-popover"
  );

  const { t } = useTranslation();

  const handleDelete = () => {
    onDelete();
    handleClose();
  };

  return (
    <>
      {loading && (
        <Box>
          <Loading size={15} sx={{ position: "relative" }} />
        </Box>
      )}
      {!loading && (
        <Tooltip title={t("components.chat.quickReplay.delete")}>
          <IconButton size="small" aria-describedby={id} onClick={handleClick}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      {open && (
        <PopoverDelete
          title={t("components.boards.delete.title", {
            secureReferenceText,
          })}
          description={t("components.boards.delete.description")}
          buttonDescription={t(
            "components.chat.quickReplay.deleteModal.delete"
          )}
          onDelete={handleDelete}
          onClose={handleClose}
          open={open}
          anchorEl={anchorEl}
          id={id}
          secureDelete
          secureLabel={t("components.workspace.delete.label") + ""}
          secureReferenceText={secureReferenceText}
        />
      )}
    </>
  );
};

export default RemoveBoard;
