import {
  createContext,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { IProtocolsDefault } from "../../models/protocol-default";
import { motion } from "framer-motion";

type TProtocolContext = {
  selectedProtocol: IProtocolsDefault | null;
  selectProtocolHandler: (protocol: IProtocolsDefault | null) => void;
};

export const ProtocolContext = createContext<TProtocolContext>({
  selectedProtocol: null,
  selectProtocolHandler: () => {},
});

interface IProtocolProvider {
  children: JSX.Element;
}

const ProtocolProvider: React.FC<IProtocolProvider> = ({ children }) => {
  const [selectedProtocol, setSelectedProtocol] =
    useState<IProtocolsDefault | null>(null);

  const selectProtocolHandler = useCallback(
    (protocol: IProtocolsDefault | null) => {
      setSelectedProtocol(protocol);
    },
    []
  );

  const value = useMemo(
    () => ({
      selectedProtocol,
      selectProtocolHandler,
    }),
    [selectedProtocol, selectProtocolHandler]
  );

  return (
    <ProtocolContext.Provider value={value}>
      <motion.div
        style={{
          height: "100%",
          width: "100%",
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99] }}
      >
        {children}
      </motion.div>
    </ProtocolContext.Provider>
  );
};

export const useProtocol = () => {
  return useContext(ProtocolContext);
};

export default memo(ProtocolProvider);
