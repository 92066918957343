import {
  Box,
  Button,
  IconButton,
  Popover,
  PopoverProps,
  Stack,
  Typography,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useTranslation } from "react-i18next";
import React, { useMemo, useState } from "react";

import useTags from "../../../hooks/useTags";
import TagItem from "./TagItem/TagItem";
import TagForm from "./TagForm/TagForm";
import useTicket from "../../../hooks/useTask";

const translationPath = "components.cardTagSelector.";

const CardTagSelector: React.FC<PopoverProps> = ({
  onClose,
  id,
  open,
  anchorEl,
}) => {
  const { t } = useTranslation();

  const {
    ticket: { id: ticketId },
  } = useTicket();

  const { tags, removeTicketTags, isChecked } = useTags();

  const [showForm, setShowForm] = useState(false);

  const [tagId, setTagId] = useState<string | null>(null);

  const closeFormHandler = () => {
    setTagId(null);
    setShowForm(false);
  };

  const editTagHandler = (id: string) => {
    setShowForm(true);
    setTagId(id);
  };

  const createHandler = () => {
    setTagId(null);
    setShowForm(true);
  };

  const sortedTags = useMemo(
    () => tags.sort((a, b) => a.displayOrder - b.displayOrder),
    [tags]
  );

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{
        mt: 1,
      }}
    >
      <Box p={1} minWidth={285}>
        <Stack flexDirection={"row"} alignItems={"start"} gap={2}>
          {showForm && (
            <IconButton onClick={closeFormHandler} size="small">
              <ArrowBackIosNewIcon fontSize="small" />
            </IconButton>
          )}
          <Typography variant="subtitle1">
            {!showForm
              ? `${t(translationPath + "tags")}`
              : `${t(translationPath + "tag")}`}
          </Typography>
        </Stack>
        {!showForm && (
          <>
            {sortedTags.map((t) => {
              const checked = isChecked(t.id);
              return (
                <TagItem
                  tag={t}
                  key={t.id}
                  onEditTag={editTagHandler}
                  ticketId={ticketId}
                  isChecked={checked}
                  removeTicketTags={removeTicketTags}
                />
              );
            })}
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 1 }}
              onClick={createHandler}
            >
              {t(translationPath + "create_tag")}
            </Button>
          </>
        )}
        {showForm && <TagForm onCloseForm={closeFormHandler} id={tagId} />}
      </Box>
    </Popover>
  );
};

export default CardTagSelector;
