import { Avatar, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { memo, useCallback, useMemo, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";

import WorkspaceArchive from "../WorkspaceArchive";
import { useTranslation } from "react-i18next";
import { stringToColor, useRole } from "@4uhub/lib4uhub";

interface IWorkspaceTitleProps {
  name: string;
  onArchive: () => Promise<void>;
  onEdit: () => void;
}

const WorkspaceTitle: React.FC<IWorkspaceTitleProps> = ({
  name,
  onArchive,
  onEdit,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const handleArchive = useCallback(async () => {
    setLoading(true);
    await onArchive();
    setLoading(false);
  }, [onArchive]);

  const backgroundColor = useMemo(() => stringToColor(name), [name]);

  const permission = useRole(["Update"]);

  return (
    <Stack
      sx={(t) => ({
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingY: t.spacing(2),
        paddingX: t.spacing(2),
        [t.breakpoints.up("md")]: {
          paddingY: t.spacing(4),
          paddingX: t.spacing(10),
        },
      })}
    >
      <Stack
        sx={(t) => ({
          flexDirection: "row",
          gap: 2,
          alignItems: "center",
        })}
      >
        <Avatar
          sx={(t) => ({
            width: 60,
            height: 60,
            fontSize: 40,
            color: t.palette.getContrastText(backgroundColor),
            borderRadius: 1,
            backgroundImage: `linear-gradient(180deg, ${backgroundColor},${
              t.palette.augmentColor({
                color: {
                  main: backgroundColor,
                },
              }).light
            })`,
          })}
        >
          {name.substring(0, 1)}
        </Avatar>
        <Typography
          variant="h5"
          sx={(t) => ({
            color: t.palette.text.primary,
          })}
        >
          {name}
        </Typography>
        {permission && (
          <Tooltip title={t("components.workspace.title2")}>
            <IconButton size="small" onClick={onEdit}>
              <EditIcon fontSize={"small"} />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      {permission && (
        <WorkspaceArchive onArchive={handleArchive} loading={loading} />
      )}
    </Stack>
  );
};

export default memo(WorkspaceTitle);
