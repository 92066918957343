import { AutoComplete, ISelectType, useFormContext } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";

import { TCardForm } from "./AddCardSchema";
import useGeneric from "../../../../../hooks/useGeneric";

const OriginSelector = () => {
  const { setValue } = useFormContext<TCardForm>();

  const { t } = useTranslation();

  const [options, setOptions] = useState<ISelectType[]>([]);

  const { data } = useGeneric("TicketOriginType");

  useEffect(() => {
    if (data) {
      setOptions(data);
      const ManualOption = data.find((option) => option.code === "1");

      if (ManualOption) {
        setValue("ticketOrigin", ManualOption);
      }
    }
  }, [data, setValue]);

  return (
    <Grid item xs={12}>
      <AutoComplete
        getOptionLabel={(option) => {
          return option.name || option.value || "";
        }}
        size="small"
        fullWidth
        options={options}
        label={t("page.board.createCard.origin")}
        name="ticketOrigin"
      />
    </Grid>
  );
};

export default OriginSelector;
