import { Loading, useFetch } from "@4uhub/lib4uhub";
import { Box, Card, IconButton, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getEIFromUrl } from "../../../../services/establishment.service";
import { ISalesContract } from "../../../../models/sales-contract";
import { getTicketRelationshipSalesContractByTicketId } from "../../../../services/ticketRelationship";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";

const SalesContractInfoModal = ({ id }: { id: string }) => {
  const { t } = useTranslation();

  const isLocalHost = window.location.hostname === "localhost";

  const [contracts, setContracts] = useState<ISalesContract[]>([]);

  const eI = getEIFromUrl();

  const { sendRequest, loading } = useFetch(
    getTicketRelationshipSalesContractByTicketId
  );

  const fetchData = useCallback(async () => {
    const { data, success } = await sendRequest(id);
    if (data && success) {
      setContracts(data);
    }
  }, [sendRequest, id]);

  const closeHandler = useCallback(
    (contractId: string) => {
      window.open(
        `${
          isLocalHost ? "http://localhost:3006" : process.env.REACT_APP_4UHUB
        }/e/${eI}/4uSalesContract/salesContract/SalesContractClubAbs/${contractId}/edit`
      );
    },
    [eI, isLocalHost]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {contracts.length > 0 ? (
        contracts.map((contract, index) => (
          <Card
            key={contract.id || index}
            sx={{
              p: 0,
              m: 0,
              height: "fit-content",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: 1,
              }}
            >
              <Typography
                sx={{
                  p: 0,
                  m: 0,
                }}
              >{`${contract.code} - ${contract.contractTitle}`}</Typography>
              <IconButton
                onClick={() => closeHandler(contract.id)}
                aria-label="close contract"
                sx={{
                  p: 0,
                  m: 0,
                }}
              >
                <OpenInNewRoundedIcon />
              </IconButton>
            </Box>
          </Card>
        ))
      ) : (
        <Typography>{t("No contracts available")}</Typography>
      )}
    </>
  );
};

export default SalesContractInfoModal;
