import { Grid } from "@mui/material";
import { Loading } from "@4uhub/lib4uhub";
import { memo } from "react";

import useWorkspace from "../../hooks/useWorkspace";
import WorspaceItem from "./WorkspaceItem";
import WorspaceAddItem from "./WorkspaceAddItem";

const WorkspaceList = () => {
  const { workspaces, loading } = useWorkspace();

  return (
    <Grid container flexDirection={"row"} spacing={2}>
      {!loading && (
        <>
          <WorspaceAddItem />
          {workspaces.map((w) => (
            <WorspaceItem {...w} key={w.id} />
          ))}
        </>
      )}
      {loading && <Loading sx={{ position: "relative" }} size={30} />}
    </Grid>
  );
};

export default memo(WorkspaceList);
