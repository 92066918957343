import { Loading } from "@4uhub/lib4uhub";
import { Stack } from "@mui/material";
import { memo } from "react";

export const ServiceListMessageLoading = memo(() => {
  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Loading />
    </Stack>
  );
});
