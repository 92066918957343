import { Popover, PopoverProps } from "@mui/material";

import MoveForm from "./MoveForm";

const MovePopover: React.FC<PopoverProps> = (props) => {
  return (
    <Popover
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      sx={{ mt: 1 }}
      {...props}
    >
      <MoveForm />
    </Popover>
  );
};

export default MovePopover;
