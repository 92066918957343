import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { ChangeEvent, memo } from "react";
import { Controller } from "react-hook-form";
import { useFormContext } from "@4uhub/lib4uhub";

interface IRadioOption {
  label: string;
  value: string;
}

interface IRadioFormProps {
  onChange?: (value: string) => void;
  label?: string | null;
  name: string;
  options: IRadioOption[];
}

const RadioForm: React.FC<IRadioFormProps> = ({
  onChange,
  label,
  name,
  options,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={""}
      render={({ fieldState: { error }, field }) => {

        const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value
            onChange && onChange(value)
            field.onChange(value)
        }

        return (
          <FormControl>
            <FormLabel id={`${name}-radio-buttons-group`}>{label}</FormLabel>
            <RadioGroup
              row
              aria-labelledby={`${name}-radio-buttons-group`}
              name={name}
              value={field.value}
              onChange={handleChange}
            >
              {options.map((o) => (
                <FormControlLabel
                  key={o.value}
                  value={o.value}
                  control={<Radio size="small" />}
                  label={o.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        );
      }}
    />
  );
};

export default memo(RadioForm);
