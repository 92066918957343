import { ISelectType, useUser } from "@4uhub/lib4uhub";
import { Box } from "@mui/material";
import Member from "./Member";
import { IMember } from "../../../../models/members";
import { useMembersContext } from "../../../../store/context/members-context";

const MembersList = ({
  profilesOptions,
}: {
  profilesOptions: ISelectType[];
}) => {
  const { user } = useUser();

  const { members } = useMembersContext();

  const compaire = (a: IMember, b: IMember) => {
    if (a.user.fullName === user.fullName) {
      return -1;
    } else {
      return +a.id - +b.id;
    }
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      sx={{ maxHeight: 500, overflowY: "auto" }}
    >
      {members.sort(compaire).map((user) => (
        <Member profilesOptions={profilesOptions} member={user} key={user.id} />
      ))}
    </Box>
  );
};

export default MembersList;
