import { FormInput, useFormContext } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";

import { useWatch } from "react-hook-form";
import { TCardForm } from "./AddCardSchema";

const PhoneInput = () => {
  const { control } = useFormContext<TCardForm>();

  const code = useWatch({
    name: "type.code",
    control,
  });

  if (code === "1")
    return (
      <Grid item xs={12} sm={12}>
        <FormInput
          name="phone"
          size="small"
          label={"Telefone"}
          fullWidth
          mask={"(00) 00000-0000"}
        />
      </Grid>
    );

  return <></>;
};

export default PhoneInput;
