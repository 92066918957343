import { Box, Typography } from "@mui/material";
import { memo } from "react";

import PaymentIcon from "@mui/icons-material/Payment";
import { ISearchResultMainDataProps } from "./models";

export const SearchResultMainData = memo(
  ({
    title,
    ticketId,
    individualCustomer,
    legalEntityCustomer,
    ticketOrigin,
    protocolNumber,
  }: ISearchResultMainDataProps) => {
    return (
      <Box
        minWidth={0}
        display={"flex"}
        gap={1}
        sx={(t) => ({ color: t.palette.text.primary })}
      >
        <PaymentIcon color="action" />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", gap: 0.5 }}>
            <Typography noWrap>
              {individualCustomer
                ? `${individualCustomer} -`
                : legalEntityCustomer
                ? `${legalEntityCustomer} -`
                : ticketOrigin
                ? `${ticketOrigin.name} -`
                : ""}
            </Typography>
            <Typography>{title}</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 0.5 }}>
            <Typography variant="body2">{ticketId}</Typography>
            <Typography variant="body2">
              {protocolNumber ? ` - #${protocolNumber}` : ""}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }
);
