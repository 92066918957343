import { memo, useState } from "react";
import { Stack, useTheme } from "@mui/material";
import { Variants, motion } from "framer-motion";

import MinimizeButton from "./MinimizeButton";
import MenuBoards from "./MenuBoards";

import CurrentWorkspace from "../../Workspaces/CurrentWorkspace";
import { DomainProvider } from "@4uhub/lib4uhub";
import Logo from "./Logo";

const variants: Variants = {
  open: { width: 300, justifyContent: "space-between" },
  closed: { width: 35, justifyContent: "end" },
};

const SideMenu = () => {
  const [open, setOpen] = useState(true);

  const { palette } = useTheme();

  const toggleOpen = () => setOpen((open) => !open);

  return (
    <motion.nav
      variants={variants}
      style={{
        borderTop: 0,
        height: "100%",
        color: palette.text.primary,
        display: "flex",
        flexDirection: "column",
        overflowY: "clip",
        backgroundColor: palette.mode === "light" ? "#FAFAFA" : "#242424",
        borderRight: "1px solid",
        borderRightColor: palette.divider,
      }}
      initial={{
        width: 300,
        justifyContent: "space-between",
      }}
      animate={open ? "open" : "closed"}
    >
      {open && (
        <Stack height={"calc(100% - 83px)"}>
          <Logo />
          <CurrentWorkspace />
          <DomainProvider
            value={{ domainName: "ticketchannel" }}
            actions={["Read"]}
            redirect={false}
          >
            <MenuBoards />
          </DomainProvider>
        </Stack>
      )}

      <MinimizeButton open={open} toggleOpen={toggleOpen} />
    </motion.nav>
  );
};

export default memo(SideMenu);
