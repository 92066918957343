import { IconButton, Tooltip } from "@mui/material";

import ArticleIcon from "@mui/icons-material/Article";
import { useTranslation } from "react-i18next";
import { FC, memo, useCallback, useState } from "react";
import { Modal } from "@4uhub/lib4uhub";
import ProtocolDefaultModal from "./ProtocolDefaultModal/ProtocolDefaultModal";

const translationPath = "components.protocolDefault.";

interface ProtocolDefaultProps {
  isDisabled?: boolean;
}

const ProtocolDefault: FC<ProtocolDefaultProps> = ({ isDisabled }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const openHandler = () => setOpen(true);
  const closeHandler = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={closeHandler}
        title={`${t(translationPath + "protocol_default")}`}
        maxDialogWidth="md"
      >
        <ProtocolDefaultModal onClose={closeHandler} />
      </Modal>
      <Tooltip title={t(translationPath + "protocol_default")}>
        <IconButton disabled={isDisabled} onClick={openHandler}>
          <ArticleIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default memo(ProtocolDefault);
