import { useFetch, useRole } from "@4uhub/lib4uhub";
import { IGetBoard } from "../../../models/Board";
import ArchivedItem from "../../UI/Archiveditem";
import {
  deleteBoardById,
  unArchiveBoardById,
} from "../../../services/board.service";
import RemoveBoard from "../SettingsMenu/Buttons/RemoveBoard";
import { memo } from "react";

interface IArchivedBoardProps {
  data: IGetBoard;
  onDelete: (id:string) => void;
}

const ArchivedBoard: React.FC<IArchivedBoardProps> = ({ data, onDelete }) => {
  const { loading, sendRequest } = useFetch(deleteBoardById);

  const updatePermission = useRole(["Update"]);

  const deletePermission = useRole(["Delete"]);

  const { loading: unarchiveLoading, sendRequest: unarchiveRequest } =
    useFetch(unArchiveBoardById);

  const { id } = data;

  const handleDelete = async () => {
    const { success } = await sendRequest(id);
    if (success) {
      onDelete(id);
    }
  };

  const handleUnArchive = () => {
    unarchiveRequest(id);
  };

  return (
    <ArchivedItem
      data={data}
      deletePermission={deletePermission}
      unarchivePermission={updatePermission}
      deleteComponent={
        <RemoveBoard
          onDelete={handleDelete}
          secureReferenceText={data.name}
          loading={loading}
        />
      }
      onUnArchived={handleUnArchive}
      unarchiveLoading={unarchiveLoading}
      deleteLoading={loading}
    />
  );
};

export default memo(ArchivedBoard);
