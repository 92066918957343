import { useCallback, useEffect, useRef, useState, memo } from "react";
import { Box, Skeleton, Stack } from "@mui/material";
import { Button, useFetch, useLoseFocus } from "@4uhub/lib4uhub";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

import EditorComponent from "../../../UI/TextEditor/Editor";
import { editContent } from "../../../../services/ticket.service";
import useBoardRole from "../../../../hooks/useBoardRole";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CardContentLabel from "./CardContentLabel";
import useTicket from "../../../../hooks/useTask";

interface ICardContentProps {
  content: string | null;
  loading?: boolean;
  id: string;
}

const CardContent: React.FC<ICardContentProps> = ({ content, loading, id }) => {
  const [text, setText] = useState<string | null>(content);

  const [viewMore, setViewMore] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);

  const permission = useBoardRole("2");

  const [editMode, setEditMode] = useState(false);

  const {
    isUserTicketMember,
    ticket: { status },
  } = useTicket();

  const ref = useRef(null);

  const { sendRequest } = useFetch(editContent);

  const { t } = useTranslation();

  useLoseFocus(ref, () => {
    save();
  });

  const cancelHanlder = () => {
    setEditMode(false);
  };

  const openEditMode = () => {
    setEditMode(true);
  };

  const save = useCallback(async () => {
    if (text === content || text === null) {
      setEditMode(false);
      return;
    }
    const { success } = await sendRequest({ content: text, id: id });
    if (success) {
      setEditMode(false);
    }
  }, [text, sendRequest, content, id]);

  const toggleViewMore = () =>
    setViewMore((oldState) => (oldState = !oldState));

  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (!contentRef.current) return;
    const resizeObserver = new ResizeObserver((data) => {
      // Do what you want to do when the size of the element changes
      setHeight(data[0].borderBoxSize[0].blockSize);
    });
    resizeObserver.observe(contentRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  const showViewMoreBtn = height > 90;

  const canEdit = permission && status.code !== "3" && isUserTicketMember;

  return (
    <Stack>
      <Stack
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={1}
      >
        <CardContentLabel />
        {!editMode && canEdit && (
          <Button
            size="small"
            variant="contained"
            onClick={openEditMode}
            color="inherit"
            sx={(t) => ({
              color: t.palette.text.primary,
            })}
            startIcon={<EditIcon />}
          >
            {t("page.card.edit")}
          </Button>
        )}
      </Stack>
      {editMode && (
        <Stack gap={1} ref={ref}>
          <EditorComponent
            onChange={(t) => setText(t)}
            value={text || ""}
            autoFocus
            maxHeight={"300px"}
          />
          <Stack direction={"row"} gap={1}>
            <Button variant="contained" size="small" onClick={save}>
              {t("page.card.save")}
            </Button>
            <Button size="small" onClick={cancelHanlder}>
              {t("page.card.cancel")}
            </Button>
          </Stack>
        </Stack>
      )}
      {!loading && (
        <Box>
          <Box
            onClick={() => {
              if (canEdit) {
                setEditMode(true);
              }
            }}
            ref={contentRef}
            sx={(t) => ({
              color: t.palette.text.primary,
              fontSize: 14,
              "&:hover": {
                cursor: canEdit ? "pointer" : "default",
                backgroundColor: canEdit
                  ? t.palette.mode === "light"
                    ? t.palette.grey[100]
                    : t.palette.grey[900]
                  : undefined,
              },
              paddingX: t.spacing(1),
              borderRadius: 1,
              overflow: "hidden",
              textOverflow: viewMore ? undefined : "ellipsis",
              display: viewMore ? undefined : "-webkit-box",
              WebkitLineClamp: viewMore ? undefined : "5",
              WebkitBoxOrient: viewMore ? undefined : "vertical",
            })}
          >
            {!editMode &&
              (content
                ? parse(content, { trim: true })
                : t("page.card.moreDetailedDescription") + "...")}
          </Box>
          {showViewMoreBtn && (
            <Button
              onClick={toggleViewMore}
              fullWidth
              startIcon={
                viewMore ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
              }
            >
              {viewMore ? t("page.card.view_less") : t("page.card.view_more")}
            </Button>
          )}
        </Box>
      )}
      {loading && (
        <Stack>
          <Skeleton variant="text" width={"100%"} height={30} />
          <Skeleton variant="text" width={"80%"} height={30} />
          <Skeleton variant="text" width={"100%"} height={30} />
          <Skeleton variant="text" width={"90%"} height={30} />
        </Stack>
      )}
    </Stack>
  );
};

export default memo(CardContent);
