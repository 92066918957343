import { Box, Grid, Stack, Tooltip } from "@mui/material";
import { memo, MouseEvent, useCallback, useState } from "react";

import { IGetTicket } from "../../../models/ticket";
import Status from "../../CardPage/CardOtherInfo/Status/Status";
import { getType } from "../../Kamban/Task/Task";
import UnreadMessages from "../../Kamban/Task/UnreadMessages";
import UserType from "../../Kamban/Task/UserType";
import AvatarImage from "../../UI/AvatarImage";
import { OverflowTooltip } from "../../UI/TooltipOverflow/TooltipOverflow";
import DateTime from "./DateTime";
import Members from "./Members";
import ProtocolNumber from "./ProtocolNumber";
import ServiceType from "./ServiceType";
import TicketServiceBadge from "../../Kamban/Task/TicketServiceBadge";
import { Button, Modal } from "@4uhub/lib4uhub";
import SalesContractInfoModal from "../../CardPage/CardOtherInfo/SalesContract/SalesContractInfoModal";
import { useTranslation } from "react-i18next";
import { useInstalledExtensions } from "../../../hooks/useInstalledExtensions";
import { Extension } from "../../../models/intalled-extensions";

interface ICardProps {
  data: IGetTicket;
  hoverColor?: string;
  onClick?: (task: IGetTicket) => void;
  selected?: boolean;
  index: number;
  isDragDisabled?: boolean;
  modalMode?: boolean;
}

const FlowCard: React.FC<ICardProps> = ({
  data,
  hoverColor,
  modalMode = false,
  selected,
  onClick,
}) => {
  const {
    id,
    protocolNumber,
    type,
    ticketMembers,
    created,
    individualCustomer,
    legalEntityCustomer,
    ticketOrigins,
    unreadMessages,
    status,
    ticketService,
    ticketRelationships,
  } = data;

  const handleClick = () => {
    if (!onClick || selected) return;
    onClick(data);
  };

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const closeHandler = useCallback(() => {
    setOpen(false);
  }, []);
  const openModal = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    e.stopPropagation();
    setOpen(true);
  };

  const [salesContractInstalled] = useInstalledExtensions(
    Extension.SALES_CONTRACT_MANAGEMENT
  );

  const name = individualCustomer
    ? individualCustomer.name
    : legalEntityCustomer
    ? legalEntityCustomer.corporateName
    : ticketOrigins[0]?.name;

  return (
    <>
      <Modal
        open={open}
        onClose={closeHandler}
        title={`${t("page.card.sales_contract")}`}
      >
        <SalesContractInfoModal id={id} />
      </Modal>
      <Box
        onClick={handleClick}
        sx={(t) => ({
          padding: t.spacing(1),
          "&:hover": {
            backgroundColor: hoverColor || t.palette.grey[200],
            cursor: "pointer",
          },
          boxShadow: modalMode ? undefined : t.shadows[1],
          border: modalMode ? 1 : 0,
          borderColor: t.palette.divider,
          backgroundColor: selected
            ? t.palette.grey[300]
            : t.palette.common.white,
          borderRadius: t.shape.borderRadius / 4,
        })}
      >
        <Grid
          container
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          overflow={"hidden"}
          gap={1}
        >
          <Grid item xs={12}>
            <Grid
              container
              direction={"row"}
              alignItems={"center"}
              gap={1}
              justifyContent={"space-between"}
            >
              {/** <OverflowTooltip text={title} tooltip={title} />*/}
              {name && (
                <Grid item xs={"auto"}>
                  <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                    <AvatarImage />
                    <Stack>
                      <OverflowTooltip text={name} tooltip={name} />
                      <UserType
                        type={getType(individualCustomer, legalEntityCustomer)}
                      />
                    </Stack>
                  </Stack>
                </Grid>
              )}
              <DateTime created={created} name={name} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid container item xs={"auto"} alignItems={"center"} gap={1}>
                {type && <ServiceType type={type} />}
                <ProtocolNumber protocolNumber={protocolNumber} />
                {unreadMessages > 0 && (
                  <UnreadMessages number={unreadMessages} />
                )}
                <Status data={status} expandable />
                {ticketRelationships &&
                  Array.isArray(ticketRelationships) &&
                  ticketRelationships.some(
                    (rel) => rel.salesContractBeneficiary
                  ) &&
                  salesContractInstalled && (
                    <Button
                      onClick={openModal}
                      sx={{ p: 0, m: 0, minWidth: 0 }}
                    >
                      <Tooltip title={t("page.card.client_sales_contract")}>
                        <img
                          src="/4uSalesContractFavicon.png"
                          alt="Sales Contract Info"
                          style={{ width: 21, height: 21 }}
                        />
                      </Tooltip>
                    </Button>
                  )}
              </Grid>
              <Members ticketMembers={ticketMembers} />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          overflow={"hidden"}
          gap={1}
        >
          <Grid item xs={12} sm={5} mt={0.5}>
            <TicketServiceBadge ticketService={ticketService} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default memo(FlowCard);
