import { Button, Loading, useFetch } from "@4uhub/lib4uhub";
import { Stack, Typography } from "@mui/material";
import { FC, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { IQuickAnswer } from "../../../../models/QuickAnswers";
import { getAnswers } from "../../../../services/quickReplay.service";
import { useQuickAnswer } from "../../../../store/context/quickAnswers-context";
import QuickReplay from "./QuickReplay";
import useWorkspace from "../../../../hooks/useWorkspace";

interface QuickReplayListProps {
  closeModal: () => void;
}

const QuickReplayList: FC<QuickReplayListProps> = ({ closeModal }) => {
  const { setMode, setAnswer, answers, setAnswers } = useQuickAnswer();
  const { actualWorkspace } = useWorkspace();

  const { loading, sendRequest } = useFetch(getAnswers);

  const { t } = useTranslation();

  const fetchAnswers = useCallback(async () => {
    const { data } = await sendRequest({
      TicketWorspaceId: actualWorkspace?.id,
    });
    if (data) {
      setAnswers(data.items);
    }
  }, [sendRequest, setAnswers, actualWorkspace]);

  useEffect(() => {
    fetchAnswers();
  }, [fetchAnswers]);

  const onClick = (a: IQuickAnswer) => {
    setAnswer(a);
    setMode("form");
  };

  const areAnswers = answers.length > 0;

  return (
    <Stack>
      {loading && (
        <Stack
          sx={{
            height: "200px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loading size={40} />
        </Stack>
      )}

      {areAnswers && (
        <Stack gap={1} maxHeight={400} overflow={"auto"} mb={1}>
          {answers.map((answer) => (
            <QuickReplay {...answer} closeModal={closeModal} onClick={onClick} key={answer?.id} />
          ))}
        </Stack>
      )}

      {!areAnswers && !loading && (
        <Typography>{t("components.chat.quickReplay.list.empty")}</Typography>
      )}
      
      <Button
        variant="contained"
        onClick={() => {
          setMode("form");
          setAnswer(undefined);
        }}
      >
        {t("components.chat.quickReplay.list.register")}
      </Button>
    </Stack>
  );
};

export default QuickReplayList;
