import FolderOff from "@mui/icons-material/FolderOff";
import { Grid, Typography } from "@mui/material";
import { INoDataProps } from "./models";

const NoData = ({ message, icon }: INoDataProps) => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      mt={2}
      sx={(t) => ({ color: t.palette.text.primary })}
    >
      <Grid item>{icon ? icon : <FolderOff fontSize="large" />}</Grid>
      <Grid item>
        <Typography variant="subtitle2">{message}</Typography>
      </Grid>
    </Grid>
  );
};

export default NoData;
