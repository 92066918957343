import {
  AutoCompleteUncontrolled,
  ISelectType,
  useFetch,
} from "@4uhub/lib4uhub";
import { memo, useCallback, useEffect, useState } from "react";
import MainApiService from "../../../services/mainApi.service";
import { useAdvancedSearchContext } from "../../../store/context/advanced-search-context";
import { useTranslation } from "react-i18next";

const STATUS_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Generic/";
const statusService = new MainApiService<ISelectType>(STATUS_ROUTE);

const translationPath = "components.advancedSearchFields.";

export const StatusSelect = memo(() => {
  const { t } = useTranslation();

  const [options, setOptions] = useState<ISelectType[]>([]);

  const { status, onChangeStatus } = useAdvancedSearchContext();

  const { sendRequest } = useFetch(statusService.list);

  const fetchStatusOptions = useCallback(async () => {
    const { data, success } = await sendRequest({ Identifier: "TicketStatus" });
    if (data && success) {
      setOptions(data);
    }
  }, [sendRequest]);

  useEffect(() => {
    fetchStatusOptions();
  }, [fetchStatusOptions]);

  return (
    <AutoCompleteUncontrolled
      label={t(translationPath + "status")}
      value={status}
      onValueSelected={onChangeStatus}
      size="small"
      options={options}
      fullWidth
    />
  );
});
