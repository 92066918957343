import { DomainProvider } from "@4uhub/lib4uhub";
import { Divider, Stack } from "@mui/material";

import { useWorkspaceContext } from "../store/context/workspace-context";

import WorkspacePageHeader from "../components/Workspaces/WorkspacePage/WorkspacePageHeader/WorkspacePageHeader";
import BoardList from "../components/Workspaces/WorkspacePage/BoardList";
import ClosedBoards from "../components/Workspaces/WorkspacePage/ClosedBoards";

const WorksPace = () => {
  const { actualWorkspace } = useWorkspaceContext();

  if (!actualWorkspace) {
    return <></>;
  }

  return (
    <DomainProvider
      value={{ domainName: "ticketworkspace" }}
      actions={["Read"]}
      redirect={false}
    >
      <Stack
        sx={(t) => ({
          [t.breakpoints.up("md")]: {
            marginX: t.spacing(10),
          },
        })}
      >
        <WorkspacePageHeader {...actualWorkspace} />
        <Divider />
        <Stack
          sx={(t) => ({
            padding: t.spacing(2),
            [t.breakpoints.up("md")]: {
              paddingX: t.spacing(5),
              paddingTop: t.spacing(4),
            },
          })}
        >
          <DomainProvider
            value={{ domainName: "ticketchannel" }}
            actions={["Read"]}
            redirect={false}
          >
            <>
              <BoardList />
              <ClosedBoards />
            </>
          </DomainProvider>
        </Stack>
      </Stack>
    </DomainProvider>
  );
};

export default WorksPace;
