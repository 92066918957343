import { memo, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import useTicket from "../../../../hooks/useTask";
import { useFetch } from "@4uhub/lib4uhub";

import { Button } from "@mui/material";
import MoveForm, { AfterSubmitParams } from "../MoveAction/MoveForm";
import LogoutIcon from "@mui/icons-material/Logout";

import MoveModal from "./MoveModal";
import { exitTicket } from "../../../../services/ticket.service";
import { IMoveModalRef } from "./models";

const MoveAction = ({ onMove }: { onMove?: () => void }) => {
  const modalRef = useRef<IMoveModalRef>(null);

  const { t } = useTranslation();
  const { sendRequest, loading } = useFetch(exitTicket);

  const {
    ticket: {
      id,
      ticketChannelStep: { id: ticketChannelStepId },
      ticketMembers,
    },
  } = useTicket();

  const membersCount = useMemo(() => ticketMembers?.length, [ticketMembers]);

  const leaveTicket = async (channelStepId?: string) => {
    const { data, success } = await sendRequest({
      id,
      ticketChannelStepId: channelStepId || ticketChannelStepId,
    });
    if (data && success) {
      if (onMove) onMove();
    }
  };

  const onExit = async () => {
    if (loading) return;

    if (membersCount === 1) {
      modalRef?.current?.open?.();
    } else {
      await leaveTicket();
    }
  };

  const afterSubmit = async ({
    ticketChannelStepId: channelStepId,
  }: AfterSubmitParams) => {
    await leaveTicket(channelStepId);

    modalRef?.current?.close?.();
  };

  return (
    <>
      <Button
        variant="contained"
        color="inherit"
        sx={(t) => ({
          color: t.palette.text.primary,
        })}
        startIcon={<LogoutIcon />}
        size="small"
        onClick={onExit}
      >
        {t("page.card.actions.leave")}
      </Button>

      <MoveModal ref={modalRef}>
        <MoveForm isModal afterSubmit={afterSubmit} />
      </MoveModal>
    </>
  );
};
export default memo(MoveAction);
