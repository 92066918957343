import { useFetch } from "@4uhub/lib4uhub";
import SpeakerNotesOffRoundedIcon from "@mui/icons-material/SpeakerNotesOffRounded";
import { Stack } from "@mui/material";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IGetTicketDialog } from "../../../../../models/ticketDialogs";
import { getMessages } from "../../../../../services/ticketDialog.service";
import NoData from "../../../../UI/NoData/NoData";
import { MessageBoxProps } from "../models";
import { ServiceListMessageLoading } from "../ServiceListMessageLoading/ServiceListMessageLoading";
import { ServiceListMessageSkeleton } from "../ServiceListMessageSkeleton/ServiceListMessageSkeleton";
import { ServiceListMessageTicketInfo } from "../ServiceListMessageTicketInfo/ServiceListMessageTicketInfo";
import ServiceListMessage from "./ServiceListMessage";

const translationPath = "components.cardServices.";

const ServiceListMessageBox = ({
  modalMode = false,
  tickets,
  loading,
}: MessageBoxProps) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [visibleTickets, setVisibleTickets] = useState(5);
  const [onSkeleton, setOnSkeleton] = useState(false);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [messagesByTicket, setMessagesByTicket] = useState<
    Record<string, IGetTicketDialog[]>
  >({});

  const { t } = useTranslation();

  const { sendRequest, loading: sloading } = useFetch(getMessages);

  const verificarScroll = useCallback(() => {
    if (chatContainerRef.current) {
      const temScroll =
        chatContainerRef.current.scrollHeight >
        chatContainerRef.current.clientHeight;
      if (temScroll) {
        console.log(
          "A div tem mais conteúdo do que a área visível e criou o scroll."
        );
      } else {
        setVisibleTickets((prev) => prev + 1);
      }
    }
  }, []);

  const fetchMessages = useCallback(
    async (ticketId: string) => {
      setOnSkeleton(true);
      if (messagesByTicket[ticketId]) return;
      const { success, data } = await sendRequest(ticketId);
      if (success && data) {
        const sortedMessages = [...data].sort(
          (a, b) =>
            new Date(b.dateSend).getTime() - new Date(a.dateSend).getTime()
        );
        setMessagesByTicket((prev) => ({
          [ticketId]: sortedMessages,
          ...prev,
        }));
        setOnSkeleton(false);
      }
    },
    [sendRequest, messagesByTicket]
  );

  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
      const el = e.target as HTMLDivElement;

      const isAtTop = el.scrollTop <= -(el.scrollHeight - el.clientHeight) + 1;

      if (isAtTop && visibleTickets < tickets.length && !isLoadingMore) {
        setIsLoadingMore(true);

        setVisibleTickets((prev) => prev + 1);

        setTimeout(() => {
          setIsLoadingMore(false);
        }, 1000);
      }
    },
    [visibleTickets, tickets.length, isLoadingMore]
  );

  useEffect(() => {
    tickets.slice(0, visibleTickets).forEach((ticket) => {
      fetchMessages(ticket.id);
    });
  }, [fetchMessages, tickets, visibleTickets]);

  useEffect(() => {
    verificarScroll();
  }, [verificarScroll]);

  return (
    <>
      {sloading && onSkeleton ? <ServiceListMessageSkeleton /> : null}
      <Stack
        ref={chatContainerRef}
        sx={(t) => ({
          "&::-webkit-scrollbar": {
            width: "0.4em",
            marginLeft: 20,
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            backgroundColor: t.palette.grey[400],
            borderRadius: 5,
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: t.palette.grey[300],
            borderRadius: 5,
          },
          flexDirection: "column-reverse",
          paddingRight: t.spacing(1),
          backgroundColor: modalMode
            ? t.palette.grey[t.palette.mode === "light" ? 100 : 900]
            : undefined,
          borderRadius: modalMode ? 2 : 0,
          position: "relative",
          overflowY: "auto",
          height: "100vh",
          width: "100%",
          p: 1,
          rowGap: 0.5,
        })}
        onScroll={handleScroll}
      >
        {(sloading || loading) && <ServiceListMessageLoading />}
        {tickets.slice(0, visibleTickets).map((ticket) => (
          <>
            {messagesByTicket[ticket.id]?.length > 0 ? (
              messagesByTicket[ticket.id]?.map((m, i) => (
                <ServiceListMessage key={`${ticket.id}-${i}`} {...m} />
              ))
            ) : sloading ? null : (
              <NoData
                icon={<SpeakerNotesOffRoundedIcon />}
                message={t(`${translationPath}no_data_dialog`)}
              />
            )}
            {sloading ? null : (
              <ServiceListMessageTicketInfo
                ticketCreated={ticket.created}
                ticketProtocol={ticket.protocolNumber}
                ticketId={ticket.id}
              />
            )}
          </>
        ))}
        <div ref={messagesEndRef} />
      </Stack>
    </>
  );
};
export default memo(ServiceListMessageBox);
