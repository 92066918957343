import { IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";

import PopoverDelete from "../../../UI/PopoverDelete";
import usePopover from "../../../../hooks/usePopover";

interface IQuickReplayDelete {
  onDelete: () => void;
}

const QuickReplayDelete: React.FC<IQuickReplayDelete> = ({ onDelete }) => {
  const { anchorEl, id, handleClick, handleClose, open } = usePopover(
    "delete-quickReplay-popover"
  );

  const { t } = useTranslation();

  return (
    <div>
      <Tooltip title={t("components.chat.quickReplay.delete")}>
        <IconButton size="small" aria-describedby={id} onClick={handleClick}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <PopoverDelete
        title={t("components.chat.quickReplay.deleteModal.title")}
        description={t("components.chat.quickReplay.deleteModal.description")}
        buttonDescription={t("components.chat.quickReplay.deleteModal.delete")}
        onDelete={onDelete}
        onClose={handleClose}
        open={open}
        anchorEl={anchorEl}
        id={id}
      />
    </div>
  );
};

export default QuickReplayDelete;
