import { z } from "zod";

const translationPath = "page.card.chat.noMessages.whatsappNumber.errors.";

const countrySchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string(),
    ddiCode: z.string(),
    phoneMask: z.string(),
  },
  { invalid_type_error: translationPath + "country" }
);

export const whatsappNumberSchema = z.object({
  phone: z.string().min(1, translationPath + "phone"),
  country: countrySchema,
  phoneOption: z.string().optional(),
});

export type TWhatsappNumberForm = z.infer<typeof whatsappNumberSchema>;
