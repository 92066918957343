import { useFetch } from "@4uhub/lib4uhub";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import { Skeleton, Stack } from "@mui/material";
import { memo, useCallback } from "react";

import useTicket from "../../../hooks/useTask";
import { editTicketTitle } from "../../../services/ticket.service";
import EditableTypography from "../../UI/EditableTypography";

interface ICardTitleProps {
  id: string;
  title: string;
  loading?: boolean;
}

const CardTitle: React.FC<ICardTitleProps> = ({ id, title, loading }) => {
  const { sendRequest } = useFetch(editTicketTitle);

  const { ticket, isUserTicketMember } = useTicket();

  const { status } = ticket;

  const handleEdit = (value: string) => {
    fetch(value);
  };

  const fetch = useCallback(
    (value: string) => {
      sendRequest({
        id: id,
        title: value,
      });
    },
    [id, sendRequest]
  );

  return (
    <Stack direction={"row"} alignItems={"center"} gap={1}>
      <ConfirmationNumberIcon color="primary" />

      {!loading && (
        <EditableTypography
          typographyProps={{
            variant: "h1",
            sx: { wordBreak: "normal", lineBreak: "normal" },
            fontSize: 16,
            color: (t) => t.palette.text.primary,
          }}
          disabled={!isUserTicketMember || status.code === "3"}
          textfieldProps={{ sx: (t) => ({ ...t.typography.h5 }) }}
          value={title}
          onEdit={handleEdit}
        />
      )}

      {loading && <Skeleton variant="text" width={"100%"} height={40} />}
    </Stack>
  );
};

export default memo(CardTitle);
