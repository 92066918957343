import { IconButton, Stack, Typography } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { memo } from "react";
import { useTranslation } from "react-i18next";

import useBoard from "../../../hooks/useBoard";
import { useSettingsMenu } from "../../../store/context/settingsMenu-context";

const SettingsMenuHeader = () => {
  const { SetSettingsMenu } = useBoard();

  const { t } = useTranslation();

  const { page, back, canGoBack } = useSettingsMenu();

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      {canGoBack && (
        <IconButton
          onClick={back}
          size="small"
          sx={{ justifySelf: "flex-end" }}
        >
          <ArrowBackIosNewIcon fontSize="small" />
        </IconButton>
      )}
      <Typography
        fontWeight={"bold"}
        textAlign={"center"}
        color={(t) => t.palette.text.primary}
      >
        {page && t(page.title)}
      </Typography>
      <IconButton
        onClick={() => SetSettingsMenu(false)}
        size="small"
        sx={{ justifySelf: "flex-end" }}
      >
        <GridCloseIcon fontSize="small" />
      </IconButton>
    </Stack>
  );
};

export default memo(SettingsMenuHeader);
