import { z } from "zod";

const translationPath = "components.historyComments.errors.";

export const historyCommentSchema = z.object({
  message: z.string().min(1, translationPath + "message"),
  historyType: z
    .string({ required_error: translationPath + "history_type" })
    .min(1, translationPath + "history_type"),
});

export type THistoryCommentForm = z.infer<typeof historyCommentSchema>;
