import { SignalRContext } from "../../../../components/SignalR/SignalRProvider";
import {
  ICustomerChannel,
  IMemberChannels,
  INewTicketChannelTag,
  ITransferTicketChannel,
} from "../../../../models/channels";
import { IGetColumn } from "../../../../models/columns";
import { IGetTicket } from "../../../../models/ticket";
import { IArchivedColumn } from "../../board-context";
import { IuseSignalRColumnsProps } from "./models";

const useSignalRColumns = ({
  onAddCard,
  setColumnOrder,
  setColumns,
  addColumn,
  onRemoveCard,
  removeColumn,
  columns,
}: IuseSignalRColumnsProps) => {
  SignalRContext.useSignalREffect(
    "NewTicketCreated", // Your Event Key
    (userId: string, data: IGetTicket) => {
      console.log("NewTicketCreated", data);

      onAddCard({ columnId: data.ticketChannelStep.id, ticket: data });
    },
    [onAddCard]
  );

  // Adicionar coluna
  SignalRContext.useSignalREffect(
    "NewTicketChannelStepCreated", // Your Event Key
    (userId: string, data: IGetColumn) => {
      console.log("NewTicketChannelStepCreated", data);
      addColumn(data);
    },
    [addColumn]
  );

  //Mover coluna
  SignalRContext.useSignalREffect(
    "TicketChannelStepOrderUpdated", // Your Event Key
    (userId: string, data: string[]) => {
      console.log("TicketChannelStepOrderUpdated", data);
      setColumnOrder(data);
    },
    [setColumnOrder]
  );
  //Mover card para outra lista
  SignalRContext.useSignalREffect(
    "TransferTicket", // Your Event Key
    (
      userId: string,
      {
        newTicketChannelStep,
        oldTicketChannelStep,
        ticket,
      }: ITransferTicketChannel
    ) => {
      console.log("TransferTicket - Kanabn", {
        newTicketChannelStep,
        oldTicketChannelStep,
        ticket,
      });

      const sourceColumn = columns.find(
        (c) => c.id === oldTicketChannelStep.id
      );

      const destinationColumn = columns.find(
        (c) => c.id === newTicketChannelStep.id
      );

      if (destinationColumn) {
        if (destinationColumn.tasks) {
          const alreadyHasThisTicket = !!destinationColumn.tasks.find(
            (t) => t.id === ticket.id
          );

          if (!alreadyHasThisTicket) {
            destinationColumn.tasks.splice(ticket.displayOrder, 0, ticket);
          }
        } else {
          destinationColumn.tasks = [ticket];
        }

        setColumns((columns) =>
          columns.map((c) => {
            if (c.id === destinationColumn.id) {
              c.tasks = destinationColumn.tasks;
            }

            return c;
          })
        );
      }

      if (sourceColumn) {
        if (sourceColumn.tasks) {
          const sourceIndex = sourceColumn.tasks.findIndex(
            (t) => t.id === ticket.id
          );

          if (sourceIndex !== -1) {
            sourceColumn.tasks.splice(sourceIndex, 1);
          }
        }

        setColumns((columns) =>
          columns.map((c) => {
            if (c.id === sourceColumn.id) {
              c.tasks = sourceColumn.tasks;
            }

            return c;
          })
        );
      }
    },
    [columns]
  );
  // Novo membro no ticket
  SignalRContext.useSignalREffect(
    "NewTicketMemberCreated", // Your Event Key
    (userId: string, data: IMemberChannels) => {
      console.log("NewTicketMemberCreated - usesignalrCOlumn", data);
      setColumns((columns) => {
        const newColumns = columns.map((c) => {
          if (c.id === data.ticketChanneSteplId) {
            c.tasks = c.tasks?.map((t) => {
              if (t.id === data.ticketId) {
                t.ticketMembers = [...t.ticketMembers, data.ticketMember];
              }
              return t;
            });
          }
          return c;
        });

        return newColumns;
      });
    },
    []
  );

  // Remover membro do ticket
  SignalRContext.useSignalREffect(
    "RemoveTicketMember", // Your Event Key
    (userId: string, data: IMemberChannels) => {
      console.log("RemoveTicketMember", data);
      setColumns((columns) => {
        const newColumns = columns.map((c) => {
          if (c.id === data.ticketChanneSteplId) {
            c.tasks = c.tasks?.map((t) => {
              if (t.id === data.ticketId) {
                t.ticketMembers = t.ticketMembers.filter(
                  (t) => t.id !== data.ticketMember.id
                );
              }
              return t;
            });
          }
          return c;
        });

        return newColumns;
      });
    },
    []
  );

  // Desarquivar card
  SignalRContext.useSignalREffect(
    "UnarchiveTicket", // Your Event Key
    (userId: string, data: IGetTicket) => {
      console.log("UnarchiveTicket", data);
      onAddCard({ columnId: data.ticketChannelStep.id, ticket: data });
    },
    [onAddCard]
  );

  // Arquivar Card
  SignalRContext.useSignalREffect(
    "ArchiveTicket", // Your Event Key
    (userId: string, data: any) => {
      console.log("ArchiveTicket", data);
      onRemoveCard({ cardId: data.id, columnId: data.ticketChannelStepId });
    },
    [onRemoveCard]
  );

  // Ticket channel step é desarquivado
  SignalRContext.useSignalREffect(
    "UnarchiveTicketChannelStep", // Your Event Key
    (userId: string, data: IGetColumn) => {
      console.log("UnarchiveTicketChannelStep", data);
      addColumn(data);
    },
    []
  );

  SignalRContext.useSignalREffect(
    "ArchiveTicketChannelStep", // Your Event Key
    (userId: string, data: IArchivedColumn) => {
      console.log("ArchiveTicketChannelStep", data);
      removeColumn(data.id);
    },
    []
  );

  SignalRContext.useSignalREffect(
    "NewTicketChannelTagTicketCreated", // Your Event Key
    (userId: string, data: INewTicketChannelTag) => {
      console.log("NewTicketChannelTagTicketCreated - Board", data);

      setColumns((columns) => {
        const newColumns = columns.map((c) => {
          if (c.id === data.ticketChannelStepId) {
            c.tasks = c.tasks?.map((t) => {
              if (t.id === data.ticketId) {
                t.ticketChannelTags = [...t.ticketChannelTags, { ...data }];
              }
              return t;
            });
          }
          return c;
        });

        return newColumns;
      });
    },
    []
  );

  SignalRContext.useSignalREffect(
    "DeleteTicketChannelTag", // Your Event Key
    (userId: string, data: { id: string }) => {
      setColumns((columns) => {
        const newColumns = columns.map((c) => {
          c.tasks = c.tasks?.map((t) => {
            t.ticketChannelTags = t.ticketChannelTags.filter(
              (t) => t.id !== data.id
            );
            return t;
          });
          return c;
        });

        return newColumns;
      });
    },
    []
  );

  SignalRContext.useSignalREffect(
    "RemoveTicketChannelTagTicket", // Your Event Key
    (userId: string, data: INewTicketChannelTag) => {
      console.log("RemoveTicketChannelTagTicket", data);
      setColumns((columns) => {
        const newColumns = columns.map((c) => {
          if (c.id === data.ticketChannelStepId) {
            c.tasks = c.tasks?.map((t) => {
              if (t.id === data.ticketId) {
                t.ticketChannelTags = t.ticketChannelTags.filter(
                  (t) => t.id !== data.id
                );
              }
              return t;
            });
          }
          return c;
        });

        return newColumns;
      });
    },
    []
  );

  SignalRContext.useSignalREffect(
    "TicketCustomerUpdated", // Your Event Key
    (userId: string, data: ICustomerChannel) => {
      console.log("TicketCustomerUpdated", data);
      setColumns((columns) => {
        const newColumns = columns.map((c) => {
          if (c.id === data.ticketChannelStep.id) {
            c.tasks = c.tasks?.map((t) => {
              if (t.id === data.id) {
                t.legalEntityCustomer = data.legalEntityCustomer;
                t.individualCustomer = data.individualCustomer;
                t.ticketRelationships = data.ticketRelationships;
              }
              return t;
            });
          }
          return c;
        });

        return newColumns;
      });
    },
    []
  );

  SignalRContext.useSignalREffect(
    "TicketUpdated", // Your Event Key
    (userId: string, data) => {
      console.log("TicketUpdated", data);

      setColumns((columns) => {
        const newColumns = columns.map((c) => {
          if (c.id === data.ticketChannelStep.id) {
            c.tasks = c.tasks?.map((t) => {
              if (t.id === data.id) {
                t = { ...t, ...data };
              }
              return t;
            });
          }
          return c;
        });

        return newColumns;
      });
    },
    []
  );

  // Mensagens não lidas ticket
  SignalRContext.useSignalREffect(
    "UpdateTicketIsRead", // Your Event Key
    (userId: string, data: { id: string; unreadCount: number }) => {
      console.log("UpdateTicketIsRead", data);
      setColumns((columns) => {
        const newColumns = columns.map((c) => {
          c.tasks = c.tasks?.map((t) => {
            if (t.id === data.id) {
              t.unreadMessages = data.unreadCount;
            }
            return t;
          });
          return c;
        });

        return newColumns;
      });
    },
    []
  );
};

export default useSignalRColumns;
