import CardCustomer from "./CardOtherInfo/Customer/CustomerSelector/CardCustomer";
import Card from "../UI/Card";

import { memo } from "react";

const Customer = () => {
  return (
    <Card sx={{ width: "100%" }}>
      <CardCustomer />
    </Card>
  );
};

export default memo(Customer);
