import { Stack, Typography, alpha } from "@mui/material";
import DetailBoxContainer from "./DetailBoxContainer/DetailBoxContainer";
import { useProtocol } from "../../../../../store/context/protocol-context";
import NoProtocolData from "./NoProtocolData.tsx/NoProtocolData";
import { FormatMessage } from "../../../../../utils/FormatMessage";

import MessageIcon from "@mui/icons-material/Message";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const translationPath = "components.detailMessage.";

const DetailMessage = () => {
  const { t } = useTranslation();

  const { selectedProtocol } = useProtocol();

  return (
    <DetailBoxContainer
      icon={<MessageIcon fontSize="small" />}
      title={t(translationPath + "message")}
    >
      <>
        {!selectedProtocol && (
          <NoProtocolData
            message={t(translationPath + "no_protocol_selected")}
          />
        )}
        {selectedProtocol && !selectedProtocol.message && (
          <NoProtocolData message={t(translationPath + "no_data")} />
        )}
        {selectedProtocol && selectedProtocol.message && (
          <Stack
            direction={"row"}
            alignSelf={"start"}
            sx={(t) => ({
              width: "fit-content",
              maxWidth: "80%",
              [t.breakpoints.down("md")]: {
                maxWidth: "100%",
              },
            })}
            gap={2}
          >
            <Stack
              direction={"row"}
              width={"fit-content"}
              sx={(t) => ({
                backgroundColor: alpha(t.palette.primary.main, 0.5),
                padding: 1,
                paddingBottom: 0.5,
                borderBottomLeftRadius: t.shape.borderRadius,
                boxShadow: t.shadows[1],
                borderBottomRightRadius: t.shape.borderRadius,
                borderTopRightRadius: t.shape.borderRadius,
                borderTopLeftRadius: 0,
              })}
            >
              <Stack gap={1}>
                <Typography
                  variant="body1"
                  paragraph={false}
                  component={"span"}
                  sx={(t) => ({
                    color: t.palette.primary.contrastText,
                    whiteSpace: "break-spaces",
                    wordWrap: "break-word",
                    overflowWrap: "anywhere",
                    fontSize: 14.2,
                  })}
                >
                  {FormatMessage(selectedProtocol.message)}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        )}
      </>
    </DetailBoxContainer>
  );
};

export default memo(DetailMessage);
