import { Box } from "@mui/material";
import { motion } from "framer-motion";

import useBoard from "../../hooks/useBoard";
import Kanban from "../Kamban/Kanban";
import Table from "../Table/Table";
import Flow from "../Flow/Flow";
import { memo } from "react";
import KanbanProvider from "../../store/context/KanbanContext/kanban-context";

const BoardBody = () => {
  const {
    mode,
    board: { id, isView },
    filter,
    setCardsLength,
  } = useBoard();

  const onCardsLengthChangeHandler = (length: number) => {
    setCardsLength(length);
  };

  return (
    <Box
      overflow={"auto"}
      height={"100%"}
      mt={2}
      ml={2}
      mb={1}
      sx={(t) => ({
        "&::-webkit-scrollbar": {
          width: "0.4em",
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          backgroundColor:
            t.palette.mode === "light"
              ? t.palette.grey[400]
              : t.palette.grey[900],
          borderRadius: 5,
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor:
            t.palette.mode === "light"
              ? t.palette.grey[200]
              : t.palette.grey[700],
          borderRadius: 5,
        },
      })}
    >
      <motion.div
        style={{
          height: "100%",
          width: "100%",
        }}
        key={mode}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, ease: [0.6, -0.05, 0.01, 0.99] }}
      >
        {mode === "board" && (
          <KanbanProvider
            isView={isView}
            boardId={id}
            filter={filter}
            onCardsLengthChange={onCardsLengthChangeHandler}
          >
            <Kanban />
          </KanbanProvider>
        )}
        {mode === "flow" && <Flow boardId={id} />}
        {mode === "table" && (
          <Table
            boardId={id}
            filter={filter}
            onCardsLengthChange={onCardsLengthChangeHandler}
          />
        )}
      </motion.div>
    </Box>
  );
};

export default memo(BoardBody);
