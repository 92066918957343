import { Skeleton, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { memo } from "react";

interface ICardInfoStepProps {
  list: string;
  loading?: boolean;
}

const CardInfoStep: React.FC<ICardInfoStepProps> = ({ list, loading }) => {
  const { t } = useTranslation();

  return (
    <Stack direction={"row"} gap={2} alignItems={"center"}>
      {loading && <Skeleton variant="text" width={30} height={10} />}
      {!loading && (
        <Typography variant="body2" color={(t) => t.palette.text.primary}>
          {t("page.card.inList", { list })}
        </Typography>
      )}
    </Stack>
  );
};

export default memo(CardInfoStep);
