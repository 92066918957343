import { Box, Link, Paper } from "@mui/material";

type TAuthContainer = {
  children: any;
  link?: string;
  textLink?: string;
};

const AuthContainer: React.FC<TAuthContainer> = ({
  children,
  link,
  textLink,
}) => {
  return (
    <Box
      sx={t=> ({
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: t.palette.background.default
      })}
    >
      <Paper
        sx={{
          width: "350px",
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          margin: "10px",
          padding: "30px",
          justifyContent: "center",
        }}
      >
        <img
          src="4uCRM.png"
          style={{ width: "80%", margin: "10px", marginBottom: "40px" }}
          alt="4uhub logo"
        />
        {children}
      </Paper>
      {link && (
        <Link href={link} underline="none">
          {textLink}
        </Link>
      )}
    </Box>
  );
};

export default AuthContainer;
