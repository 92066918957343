import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
  memo,
} from "react";
import { IQuickAnswer } from "../../models/QuickAnswers";

type QuickModalMode = "list" | "form";

type IQuickAnswerContext = {
  mode: QuickModalMode;
  setMode: (mode: QuickModalMode) => void;
  setAnswer: Dispatch<SetStateAction<IQuickAnswer | undefined>>;
  back: () => void;
  canGoBack: boolean;
  answer?: IQuickAnswer;
  answers: IQuickAnswer[];
  setAnswers: Dispatch<SetStateAction<IQuickAnswer[]>>;
};

export const QuickAnswer = createContext<IQuickAnswerContext>({
  mode: "list",
  setMode: () => {},
  back: () => {},
  canGoBack: false,
  setAnswer: () => {},
  answers: [],
  setAnswers: () => {},
});

interface IQuickAnswerProvider {
  children: any;
}

const QuickAnswerProvider: React.FC<IQuickAnswerProvider> = ({ children }) => {
  const [mode, setMode] = useState<QuickModalMode>("list");
  const [modeHistory, setModeHistory] = useState<QuickModalMode[]>([]);
  const [answer, setAnswer] = useState<IQuickAnswer>();
  const [answers, setAnswers] = useState<IQuickAnswer[]>([]);

  const HandleMode = useCallback(
    (m: QuickModalMode) => {
      setMode((lastmode) => {
        setModeHistory((m) => {
          m.push(lastmode);
          return m;
        });

        return m;
      });
    },
    [setModeHistory, setMode]
  );

  const canGoBack = !!(modeHistory.length > 0);

  const back = useCallback(() => {
    if (canGoBack) {
      setModeHistory((m) => {
        const mode = m.pop();
        if (mode) {
          setMode(mode);
        }

        return m;
      });
    }
  }, [canGoBack, setMode, setModeHistory]);

  return (
    <QuickAnswer.Provider
      value={{
        mode,
        setMode: HandleMode,
        back,
        canGoBack,
        answer,
        setAnswer,
        answers,
        setAnswers,
      }}
    >
      {children}
    </QuickAnswer.Provider>
  );
};

export const useQuickAnswer = () => {
  return useContext(QuickAnswer);
};

export default memo(QuickAnswerProvider);
