import { Button } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useTranslation } from "react-i18next";
import { memo } from "react";

import ChecklistPopover from "./ChecklistPopover";
import usePopover from "../../../hooks/usePopover";
import useTicket from "../../../hooks/useTask";

const translationPath = "components.createChecklist.";

interface ICreateChecklistActionProps {
  id: string;
}

const CreateChecklistAction: React.FC<ICreateChecklistActionProps> = ({
  id,
}) => {
  const { t } = useTranslation();

  const {
    ticket: { status },
  } = useTicket();

  const {
    anchorEl,
    id: PopoverId,
    handleClick,
    handleClose,
    open,
  } = usePopover("checklist-popover");

  return (
    <>
      <Button
        variant="contained"
        color="inherit"
        sx={(t) => ({
          color: t.palette.text.primary,
          alignSelf: "flex-end",
        })}
        startIcon={<CheckBoxIcon />}
        disabled={status.code === "3"}
        onClick={handleClick}
        disableFocusRipple
      >
        {t(translationPath + "create_checklist_button")}
      </Button>
      <ChecklistPopover
        onClose={handleClose}
        open={open}
        ticketId={id}
        id={PopoverId}
        anchorEl={anchorEl}
      />
    </>
  );
};

export default memo(CreateChecklistAction);
