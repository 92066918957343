import { Box, SxProps, Theme } from "@mui/material";
import React from "react";

interface IHoverOpacityProps {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  sx?: SxProps<Theme>;
  disabled?: boolean;
}

const HoverOpacity: React.FC<IHoverOpacityProps> = ({
  children,
  onClick,
  sx,
  disabled,
}) => {
  return (
    <Box
      onClick={(e) => {
        if (!disabled) {
          onClick && onClick(e);
        }
      }}
      sx={{
        "&:hover": {
          opacity: disabled ? 1 : 0.8,
          cursor: disabled ? "default" : "pointer",
        },
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default HoverOpacity;
