import TicketHistoryProvider from "../../../store/context/card-histories-context";
import CardHistories from "./CardHistories";

const CardLogs = () => {
  return (
    <TicketHistoryProvider detail>
      <CardHistories detail/>
    </TicketHistoryProvider>
  );
};
export default CardLogs;
