import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { useFetch } from "@4uhub/lib4uhub";
import { memo, useCallback } from "react";

import BoardPopOver from "./AddBoard/BoardPopOver";
import usePopover from "../../hooks/usePopover";
import { useWorkspaceContext } from "../../store/context/workspace-context";
import { createBoard } from "../../services/board.service";
import { TCreateBoardType } from "./AddBoard/AddBoardSchema";

const BoardListITemAdd: React.FC = () => {
  const { anchorEl, id, handleClick, handleClose, open } = usePopover(
    "board-add-workspace-popover"
  );

  const { actualWorkspace } = useWorkspaceContext();

  const { loading, sendRequest } = useFetch(createBoard);

  const { t } = useTranslation();

  const SubmitHandler = useCallback(
    async (formData: TCreateBoardType) => {
      if (!actualWorkspace) return;

      const { success, data } = await sendRequest({
        name: formData.name,
        enable: true,
        ticketWorkspaceId: actualWorkspace?.id,
        isView: formData.isView,
        parentId: formData.parent?.id,
      });

      if (success && data) {
        handleClose();
      }
    },
    [actualWorkspace, sendRequest, handleClose]
  );

  return (
    <>
      <Box
        sx={(t) => ({
          padding: t.spacing(4),
          borderRadius: 1,
          transition: "all .2s ease-in-out",
          cursor: "pointer",
          backgroundColor: t.palette.primary.main,
          color: t.palette.primary.contrastText,
          "&:hover": {
            backgroundColor: t.palette.primary.dark,
          },
        })}
        onClick={handleClick}
      >
        <Typography> {t("page.workspace.create")}</Typography>
      </Box>
      <BoardPopOver
        isGeneric
        isView={true}
        loading={loading}
        anchorEl={anchorEl}
        onClose={handleClose}
        onSubmit={SubmitHandler}
        open={open}
        id={id}
      />
    </>
  );
};

export default memo(BoardListITemAdd);
