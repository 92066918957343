import { Avatar, AvatarGroup, Tooltip } from "@mui/material";
import { getInitials, stringToColor } from "@4uhub/lib4uhub";

import { IMember } from "../../../models/Kanban";

interface IAvatarStackProps {
  members: IMember[];
  max: number;
  size: number;
}

const AvatarStack: React.FC<IAvatarStackProps> = ({ members, max, size }) => {
  return (
    <AvatarGroup
      max={max}
      slotProps={{
        additionalAvatar: {
          sx: { width: size, height: size, fontSize: size },
        },
      }}
      sx={{
        "& .MuiAvatar-root": {
          width: size,
          height: size,
          borderWidth: 0,
        },
      }}
    >
      {members.map(({ name, id, avatar }) => (
        <Tooltip title={name} key={id}>
          <Avatar
            alt={name}
            src={avatar}
            sx={{
              width: size,
              height: size,
              fontSize: 12,
              backgroundColor: !avatar ? stringToColor(name) : undefined,
            }}
          >
            {!avatar && getInitials(name)}
          </Avatar>
        </Tooltip>
      ))}
    </AvatarGroup>
  );
};

export default AvatarStack;
