import { Box } from "@mui/material";

interface ICardContainer {
  isDragging?: boolean;
  children: JSX.Element;
  ref?: any;
}

const CardContainer: React.FC<ICardContainer> = ({
  children,
  isDragging,
  ref,
}) => {
  return (
    <Box
      ref={ref}
      sx={(theme) => ({
        borderRadius: (theme.shape.borderRadius / 4),
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(1.5),
        marginY: theme.spacing(0.5),
        overflow: "hidden",
        textOverflow: "ellipsis",
        transform: isDragging ? "rotate(7deg)" : "rotate(0deg)",
        boxShadow: isDragging ? theme.shadows[4]:theme.shadows[1],
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: theme.palette.grey[100]
        }
      })}
    >
      {children}
    </Box>
  );
};

export default CardContainer;
