import { useContext } from "react";

import { BoardContext } from "../store/context/board-context";

const useBoard = () => {
  const board = useContext(BoardContext);

  if(!board){
    throw  new Error("useBoard must be used with BoardProvider")
  }

  return board;
};

export default useBoard;
