import { Draggable } from "react-beautiful-dnd";
import { memo, useCallback, useMemo, useState } from "react";
import { useTheme } from "@mui/material";

import { IGetColumn } from "../../../models/columns";
import ColumnProvider from "../../../store/context/column-context";
import ColumnContainer from "./ColumnContainer";
import ColumnHeader from "./ColumnHeader/ColumnHeader";
import AddCardButton from "./AddCard/AddCardButton";
import AddCardModal from "./AddCard/AddCardModal";
import useBoardRole from "../../../hooks/useBoardRole";
import TaskContainer from "./TaskContainer";
import { IBasicEditColumnFunctions } from "../../../store/context/KanbanContext/useEditColumns/useEditColumns";

export interface IColumnProps extends IBasicEditColumnFunctions {
  index: number;
  column: IGetColumn;
  isView: boolean;
}

const Column: React.FC<IColumnProps> = ({
  column,
  index,
  isView,
  onColumnColorChange,
  onColumnNameChange,
  onColumnStatusChange,
  onParentChange,
  onSetTasks,
}) => {
  const { id, backgroundColor } = column;

  const {
    palette: { getContrastText },
  } = useTheme();

  const addPermission = useBoardRole("2");

  const [open, SetOpen] = useState(true);

  const [addMode, setAddMode] = useState(false);

  const handleloseFocus = useCallback(() => {
    setAddMode(false);
  }, []);

  const addModeOn = useCallback(() => {
    setAddMode(true);
  }, []);

  const toggleOpen = useCallback(() => {
    SetOpen((o) => !o);
  }, []);

  const onAddHandler = useCallback(() => {
    setAddMode(false);
  }, []);

  const contrastColor = useMemo(
    () => column.backgroundColor && getContrastText(column.backgroundColor),
    [column, getContrastText]
  );

  return (
    <ColumnProvider
      columnData={column}
      onStatusChange={onColumnStatusChange}
      onColorChange={onColumnColorChange}
      onNameChange={onColumnNameChange}
      onSetTasks={onSetTasks}
      onParentChange={onParentChange}
    >
      <Draggable draggableId={id} index={index} isDragDisabled={!addPermission}>
        {({
          innerRef: columnInnerRef,
          dragHandleProps: columnHandleProps,
          draggableProps: columnDraggableProps,
        }) => (
          <div {...columnDraggableProps} ref={columnInnerRef}>
            <ColumnContainer open={open} backgroundColor={backgroundColor}>
              <ColumnHeader
                ColumnOpen={open}
                id={id}
                contrastColor={contrastColor}
                tasksLength={column.tasks?.length}
                columnHandleProps={columnHandleProps}
                onColumToggle={toggleOpen}
                isView={isView}
              />

              <TaskContainer
                id={id}
                isView={isView}
                open={open}
                backgroundColor={backgroundColor}
              />

              <AddCardModal
                open={addMode}
                onClose={handleloseFocus}
                onAdd={onAddHandler}
                listId={column.id}
                listName={column.name}
              />

              {open && !isView && addPermission && (
                <AddCardButton onClick={addModeOn} />
              )}
            </ColumnContainer>
          </div>
        )}
      </Draggable>
    </ColumnProvider>
  );
};

export default memo(Column);
