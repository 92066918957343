import { DateTime, useFormContext } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";
import useTicket from "../../../hooks/useTask";

const translationPath = "components.events.";

export const SelectEndDate = memo(() => {
  const { t } = useTranslation();

  const {
    ticket: { status },
  } = useTicket();

  const { control, setValue } = useFormContext();

  const endDate = useWatch({
    name: "endDate",
    control,
  });

  const focusHandler = useCallback(() => {
    if (endDate) return;
    setValue("endDate", new Date());
  }, [endDate, setValue]);

  return (
    <Grid item xs={12} sm={6}>
      <DateTime
        name="endDate"
        label={t(translationPath + "end_date")}
        isClearable
        disabled={status.code === "3"}
        onOpenFocus={focusHandler}
      />
    </Grid>
  );
});
