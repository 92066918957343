import { z } from "zod";

const boardSchema = z.object({
  id: z.string(),
  name: z.string(),
  enable: z.boolean(),
  isView: z.boolean(),
});

export const createBoardSchema = z
  .object({
    name: z.string().min(1, "O título é obrigatório"),
    isView: z.boolean(),
    isEdit: z.boolean(),
    parent: boardSchema.optional().nullable(),
  })
  .refine(
    (data) => {
      return data.isEdit || data.isView || !!data.parent;
    },
    {
      message: "O quadro pai deve ser informado",
      path: ["parent"],
    }
  );

export type TCreateBoardType = z.infer<typeof createBoardSchema>;
