import { Button, ButtonProps } from "@mui/material";
import { MouseEventHandler } from "react";

interface IAction extends ButtonProps {
  title: string;
  icon: JSX.Element;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const Action: React.FC<IAction> = ({ title, icon, onClick, ...props }) => {
  return (
    <Button
      startIcon={icon}
      variant="text"
      size="small"
      onClick={onClick}
      {...props}
    >
      {title}
    </Button>
  );
};

export default Action;
