import { createContext, useCallback, useContext, useState } from "react";

import { ITicketHistory } from "../../models/card-histories";
import { SignalRContext } from "../../components/SignalR/SignalRProvider";

type TTicketHistoryContext = {
  ticketHistories: ITicketHistory[];
  loadTicketHistories: (allMembers: ITicketHistory[]) => void;
  addTicketHistory: (member: ITicketHistory) => void;
};

export const TicketHistoryContext = createContext<TTicketHistoryContext>({
  ticketHistories: [],
  addTicketHistory: () => {},
  loadTicketHistories: () => {},
});

interface ITicketHistoryProvider {
  children: any;
  detail?: boolean;
}

const TicketHistoryProvider: React.FC<ITicketHistoryProvider> = ({
  children,
  detail = false,
}) => {
  const [ticketHistories, setTicketHistories] = useState<ITicketHistory[]>([]);

  const loadTicketHistories = useCallback((ticketHistory: ITicketHistory[]) => {
    setTicketHistories(ticketHistory);
  }, []);

  const addTicketHistory = useCallback((ticketHistory: ITicketHistory) => {
    setTicketHistories((old) => [ticketHistory, ...old]);
  }, []);

  //Card é arquivado
  SignalRContext.useSignalREffect(
    "NewTicketHistoryCreated", // Your Event Key
    (userId: string, data: ITicketHistory) => {
      console.log("NewTicketHistoryCreated", data);
      if (detail && data.detail) {
        addTicketHistory(data);
        return;
      }

      if (!detail && !data.detail) {
        addTicketHistory(data);
        return;
      }
    },
    [addTicketHistory, detail]
  );
  //

  return (
    <TicketHistoryContext.Provider
      value={{
        ticketHistories,
        addTicketHistory,
        loadTicketHistories,
      }}
    >
      {children}
    </TicketHistoryContext.Provider>
  );
};

export const useTicketHistory = () => {
  return useContext(TicketHistoryContext);
};

export default TicketHistoryProvider;
