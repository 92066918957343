import { memo, ReactElement, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useTicket from "../../../../../hooks/useTask";
import { useTicketHeader } from "../../../../../store/context/card-header-context";

import SelectList from "./SelectList";
import SelectBoard from "./SelectBoard";
import SelectWorkspace from "./SelectWorkspace";
import { Stack, Typography } from "@mui/material";
import { Button, Form, useFetch } from "@4uhub/lib4uhub";

import { zodResolver } from "@hookform/resolvers/zod";
import { moveSchema, TMoveSchema } from "./moveSchema";
import { transferTicket } from "../../../../../services/ticket.service";

export interface AfterSubmitParams {
  ticketId: string;
  ticketChannelId?: string;
  ticketChannelStepId?: string;
}

interface IMoveFormProps {
  open?: boolean;
  /** use this property with LeaveAction Component */
  isModal?: boolean;
  afterSubmit?: (options: AfterSubmitParams) => Promise<void>;
}

const MoveForm = memo<IMoveFormProps>(
  ({ open = true, isModal, afterSubmit }): ReactElement => {
    const { t } = useTranslation();
    const { onMove } = useTicketHeader();
    const { ticket: { id: ticketId } = {} } = useTicket();
    const { loading, sendRequest } = useFetch(transferTicket);

    const methods = useForm<TMoveSchema>({
      resolver: zodResolver(moveSchema),
      defaultValues: { list: null },
    });

    const move = useCallback(
      async ({ board, list }: TMoveSchema) => {
        if (ticketId) {
          const ticketChannelId = board.id;
          const ticketChannelStepId = list?.id;

          const { data, success } = await sendRequest({
            ticketChannelId,
            ticketChannelStepId,
            tickets: [{ id: ticketId }],
          });

          if (data && success) {
            onMove?.(ticketId);

            await afterSubmit?.({
              ticketId,
              ticketChannelId,
              ticketChannelStepId,
            });
          }
        }
      },
      [sendRequest, afterSubmit, onMove, ticketId]
    );

    const moveTicket = methods.handleSubmit(move);

    return (
      <Form {...methods}>
        <Stack p={1} minWidth={250} gap={1}>
          {!isModal && (
            <Typography mb={1}>{t("page.card.actions.moveTicket")}</Typography>
          )}

          <SelectWorkspace />

          <SelectBoard open={open} />

          <SelectList />

          <Stack
            mt={2}
            spacing={2}
            direction="row"
            alignItems="center"
            justifyContent={isModal ? "end" : "start"}
          >
            <Button
              variant="contained"
              loading={loading}
              onClick={moveTicket}
              disabled={!methods.formState.isValid}
            >
              {isModal
                ? t("page.card.actions.move_and_leave")
                : t("page.card.actions.move")}
            </Button>
          </Stack>
        </Stack>
      </Form>
    );
  }
);

export default MoveForm;
