import UnarchiveIcon from "@mui/icons-material/Unarchive";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { Loading } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";
import { memo } from "react";

interface GenericItem {
  name: string;
  id: string;
}

interface IArchivedItemProps<T extends GenericItem> {
  data: T;
  onUnArchived: (data: T) => void;
  unarchiveLoading: boolean;
  deleteLoading: boolean;
  deleteComponent: JSX.Element;
  deletePermission?: boolean;
  unarchivePermission?: boolean;
}

const ArchivedItem = <T extends GenericItem>({
  data,
  onUnArchived,
  unarchiveLoading,
  deleteComponent,
  deletePermission = true,
  unarchivePermission = true,
}: IArchivedItemProps<T>) => {
  const { name } = data;

  const { t } = useTranslation();

  const handleUnArchive = async () => {
    onUnArchived(data);
  };

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      padding={1}
      justifyContent={"space-between"}
    >
      <Typography>{name}</Typography>
      <Stack direction={"row"} alignItems={"center"} gap={1}>
        {deletePermission && deleteComponent}
        {unarchiveLoading && (
          <Loading size={15} sx={{ position: "relative" }} />
        )}
        {!unarchiveLoading && unarchivePermission && (
          <Tooltip title={t("page.workspace.archived.unarchive")}>
            <IconButton size="small" onClick={handleUnArchive}>
              <UnarchiveIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    </Stack>
  );
};

export default memo(ArchivedItem);
