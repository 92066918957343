import { IFilter } from "../../models/Board";
import { IGetTicket } from "../../models/ticket";

export const filterTickets = (tickets: IGetTicket[], filter: IFilter) => {
    let modifiedTickets = tickets;
  
    modifiedTickets = modifiedTickets?.filter((t) =>
      t.title.toLowerCase().includes(filter.keyword.toLowerCase() || "")
    );
  
    if (filter.membersIds.length > 0) {
      modifiedTickets = modifiedTickets?.filter((t) => {
        const members = t.ticketMembers;
  
        const noMember = members?.length === 0;
  
        const foundId = members?.some((m) =>
          filter.membersIds.includes(m.user.id)
        );
  
        const alreadyParticipated = t.viewUser;
  
        if (filter.membersIds.includes("none")) {
          return noMember || foundId;
        }
  
        if (filter.membersIds.includes("alreadyParticipated")) {
          return noMember || foundId || alreadyParticipated;
        }
  
        return foundId;
      });
    }
  
    return modifiedTickets;
  };