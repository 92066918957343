import AddIcon from "@mui/icons-material/Add";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";

import usePopover from "../../../hooks/usePopover";
import useTicket from "../../../hooks/useTask";
import { ICardMember } from "../../../models/card-members";
import CardMembersSelector from "./CardMembersSelector";
import JoinCardButton from "./JoinCardButton";

const translationPath = "components.cardMembers.";

interface ICardMembersActionsProps {
  cardMembers: ICardMember[];
  onLoading: (value: boolean) => void;
}

const CardMembersActions: React.FC<ICardMembersActionsProps> = ({
  cardMembers,
  onLoading,
}) => {
  const { t } = useTranslation();

  const { isUserTicketMember } = useTicket();

  const { handleClick, handleClose, id, open, anchorEl } = usePopover(
    "invite-members-popover"
  );

  return (
    <Stack flexDirection={"row"} gap={1}>
      <Tooltip title={t(translationPath + "add_member")}>
        <IconButton
          size="small"
          onClick={handleClick}
          disabled={!isUserTicketMember}
          sx={(t) => ({
            backgroundColor:
              t.palette.mode === "light"
                ? t.palette.grey[300]
                : t.palette.grey[700],
            height: 30,
            width: 30,
          })}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>

      <JoinCardButton isLoadingHandler={onLoading} />

      <CardMembersSelector
        anchorEl={anchorEl}
        id={id}
        open={open}
        onClose={handleClose}
      />
    </Stack>
  );
};

export default memo(CardMembersActions);
