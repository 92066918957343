import { Tooltip, Typography } from "@mui/material";
import { useRef, useEffect, useState, memo } from "react";

import { IToolTipOverFlowProps } from "./models";

/**
 * An componment to show tooltip with the inner text is overflowed
 */
export const OverflowTooltip = memo((props: IToolTipOverFlowProps) => {
  const textElementRef = useRef<HTMLInputElement | null>(null);

  const compareSize = () => {
    if (!textElementRef.current) return;
    const compare =
      textElementRef.current.scrollWidth > textElementRef.current.clientWidth;

    setHover(compare);
  };

  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);
  }, []);

  useEffect(
    () => () => {
      window.removeEventListener("resize", compareSize);
    },
    []
  );

  const [hoverStatus, setHover] = useState(false);

  return (
    <Tooltip title={props.tooltip} disableHoverListener={!hoverStatus}>
      <Typography
        variant="subtitle2"
        ref={textElementRef}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {props.text}
      </Typography>
    </Tooltip>
  );
});
