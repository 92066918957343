import { Box } from "@mui/material";
import { Loading } from "@4uhub/lib4uhub";

import { useWorkspaceContext } from "../store/context/workspace-context";
import BoardProvider from "../store/context/board-context";
import BoardSettingsMenu from "../components/Board/SettingsMenu/BoardSettingsMenu";
import BoardNotFound from "../components/Board/BoardNoFound";
import BoardContent from "../components/Board/BoardContent";

const Board: React.FC = () => {
  const { loading, actualBoard } = useWorkspaceContext();

  if (loading) {
    return <Loading size={40} />;
  }

  if (!actualBoard) {
    return <BoardNotFound />;
  }

  return (
    <BoardProvider board={actualBoard}>
      <Box
        height={"100%"}
        width={"100%"}
        overflow={"hidden"}
        display={"flex"}
        flexDirection={"row"}
        sx={(t) => ({ backgroundColor: t.palette.background.default })}
      >
        <BoardContent name={actualBoard.name} />
        <BoardSettingsMenu />
      </Box>
    </BoardProvider>
  );
};

export default Board;
