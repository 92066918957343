import { Popover, PopoverProps, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import Answers from "./Answers";
import { IQuickAnswer } from "../../../models/QuickAnswers";


const QuickReplayPopover: React.FC<
  PopoverProps & { answers: IQuickAnswer[] }
> = ({ answers, onClose, ...props }) => {
  const { t } = useTranslation();

  return (
    <Popover
      {...props}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      disableAutoFocus
    >
      <Stack sx={(t) => ({ padding: t.spacing(2), maxWidth: 500 })}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          marginBottom={1}
        >
          <Typography style={{ fontWeight: "bold" }}>
            {t("components.chat.quickReplay.popover.title")}
          </Typography>
        </Stack>
        <Stack maxHeight={500} overflow={"auto"}>
          <Answers answers={answers} onClose={onClose} />
        </Stack>
      </Stack>
    </Popover>
  );
};

export default QuickReplayPopover;
