import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { INoResultsProps } from "./models";
import SearchResultsListContainer from "../SearchResultsListContainer";
import { memo } from "react";

const NoResults = ({ title, subtitle }: INoResultsProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <SearchResultsListContainer justifyContent="center">
      <Box
        width={"100%"}
        height={"100%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        textAlign={"center"}
      >
        <img
          src="/no-search-result.png"
          width={matches ? 800 : 300}
          height={"auto"}
          alt="telescope"
        />
        <Typography variant="h4">{title}</Typography>
        <Typography
          variant="subtitle1"
          sx={(t) => ({
            color:
              t.palette.mode === "light"
                ? t.palette.grey["600"]
                : t.palette.grey["400"],
          })}
        >
          {subtitle}
        </Typography>
      </Box>
    </SearchResultsListContainer>
  );
};

export default memo(NoResults);
