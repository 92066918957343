import {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useFetch, useUser } from "@4uhub/lib4uhub";

import { SignalRContext } from "../../components/SignalR/SignalRProvider";
import useTicket from "../../hooks/useTask";
import { IGetTicketDialog } from "../../models/ticketDialogs";
import {
  getMessages,
  readMessages,
  sendMessage,
} from "../../services/ticketDialog.service";

export interface ISendMessageProps {
  mediaFileIds?: string[];
  deleteMediaFileIds?: string[];
  msg: string;
}

type IChatContext = {
  messages: IGetTicketDialog[];
  sendMessage: (props: ISendMessageProps) => void;
  loading: boolean;
};

export const Chat = createContext<IChatContext>({
  sendMessage: () => {},
  messages: [],
  loading: false,
});

interface IChatProvider {
  children: any;
  InitialMessages?: IGetTicketDialog[];
  ticketId?: string;
}

const ChatProvider: React.FC<IChatProvider> = ({ ticketId, children }) => {
  const [messages, setMessages] = useState<IGetTicketDialog[]>([]);

  const {
    user: { id: userId },
  } = useUser();

  const {
    ticket: { id, status, ticketMembers },
  } = useTicket();

  const { sendRequest, loading } = useFetch(getMessages);

  const { sendRequest: send } = useFetch(sendMessage);

  const { sendRequest: readMessagesRequest } = useFetch(readMessages);

  const fetchReadMessages = useCallback(async () => {
    if (
      status.code === "2" &&
      ticketMembers.find((t) => t.user.id === userId)
    ) {
      await readMessagesRequest(ticketId ?? id);
    }
  }, [readMessagesRequest, id, status, userId, ticketMembers, ticketId]);

  const fetchMessages = useCallback(async () => {
    const { success, data } = await sendRequest(ticketId ?? id);
    if (data && success) {
      setMessages(data);
    }
  }, [sendRequest, id, ticketId]);

  const handleMessageSend = useCallback(
    ({ msg, deleteMediaFileIds, mediaFileIds }: ISendMessageProps) => {
      if (msg.length === 0 && mediaFileIds?.length === 0) return;

      send({
        message: msg,
        ticketId: id,
        whoSendTypeCode: "1",
        deleteMediaFileIds,
        mediaFileIds,
      });
    },
    [send, id]
  );

  useEffect(() => {
    fetchMessages();
  }, [fetchMessages]);

  useEffect(() => {
    fetchReadMessages();
  }, [fetchReadMessages]);

  SignalRContext.useSignalREffect(
    "NewTicketDialog", // Your Event Key
    (_, message: IGetTicketDialog) => {
      console.log("NewTicketDialog", message);
      fetchReadMessages();
      setMessages((prev) => [...prev, message]);
    },
    [fetchReadMessages]
  );

  const value = useMemo(
    () => ({
      messages,
      sendMessage: handleMessageSend,
      loading,
    }),
    [handleMessageSend, messages, loading]
  );

  return <Chat.Provider value={value}>{children}</Chat.Provider>;
};

export const useChat = () => {
  return useContext(Chat);
};

export default memo(ChatProvider);
