import { z } from "zod";

/*
const boardParentSchema = z.object({
  id: z.string(),
  name: z.string(),
  enable: z.boolean(),
  isView: z.boolean(),
  ticketWorkspace: z.any(),
  channelType: z.any(),
});
*/

export const editBoardParentSchema = z.object({
  parent: z.any(),
});

export type TEditBoardParent = z.infer<typeof editBoardParentSchema>;
