import { getInitials, stringToColor } from "@4uhub/lib4uhub";
import {
  Avatar,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import useTicket from "../../../../../hooks/useTask";
import { EUserType } from "../../../../Kamban/Task/TaskHeader";
import UserType from "../../../../Kamban/Task/UserType";
import Property from "../../../Property";
import { ITicketService } from "../../../../../models/ticketService";
import TicketServiceBadge from "../../../../Kamban/Task/TicketServiceBadge";

interface ICustomerProps {
  individual?: string;
  hideLabel?: Boolean;
  isNotIdentified: boolean;
  notIdentifiedPhone?: string | null;
  ticketService: ITicketService;
  type: EUserType;
}

const Customer: React.FC<ICustomerProps> = ({
  individual,
  hideLabel = false,
  isNotIdentified,
  notIdentifiedPhone,
  ticketService,
  type,
}) => {
  const { t } = useTranslation();

  const { isUserTicketMember } = useTicket();

  const theme = useTheme();
  
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Stack direction={"row"} alignItems={"center"} gap={1}>
        {individual && (
          <Avatar
            sx={{
              width: 35,
              height: 35,
              backgroundColor: stringToColor(individual),
              fontSize: 15,
            }}
          >
            {getInitials(individual)}
          </Avatar>
        )}
        <Property
          content={
            individual ? (
              <Stack>
                <Stack flexDirection={"row"} gap={1}>
                  <Typography
                    color={(t) => t.palette.text.primary}
                    fontSize={14}
                  >
                    {individual}
                  </Typography>

                  {notIdentifiedPhone && (
                    <Typography
                      color={(t) => t.palette.text.primary}
                      fontSize={14}
                    >
                      {`- (${notIdentifiedPhone.slice(
                        0,
                        2
                      )})${notIdentifiedPhone.slice(2, 11)}`}
                    </Typography>
                  )}
                </Stack>
                <UserType type={type} />
              </Stack>
            ) : null
          }
          disable={!isUserTicketMember}
          label={
            !hideLabel
              ? !individual
                ? t("page.card.client_not_identified")
                : isNotIdentified
                ? t("page.card.client_not_identified")
                : t("page.card.client")
              : undefined
          }
        />
      </Stack>
      <Stack width={matches ? '100%' : '40%'}>
        <TicketServiceBadge ticketService={ticketService} />
      </Stack>
    </>
  );
};

export default Customer;
