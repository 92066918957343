import { z } from "zod";

const translationPath = "components.workspace.errors.";

const establishmentsSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string().optional().nullable(),
});

export const workspaceSchema = z.object({
  name: z.string().min(1, translationPath + "name"),
  establishments: z
    .array(establishmentsSchema)
    .nonempty(translationPath + "establishments"),
});

export type TWorkspaceForm = z.infer<typeof workspaceSchema>;
