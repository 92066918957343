import { Box, IconButton, Tooltip } from "@mui/material";
import { memo, useState } from "react";
import { DomainProvider, Modal } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";
import WorkspacesIcon from "@mui/icons-material/Workspaces";

import WorkspacesPopover from "./WorkspacePopover/WorkspacesPopover";
import WorkspaceModalCreate from "./WorkspaceModalCreate";

const Workspaces = () => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [openPopover, setOpenPopover] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenPopover(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenPopover(false);
  };

  const onOpenModal = () => setOpenModal(true);

  const onCloseModal = () => setOpenModal(false);

  return (
    <DomainProvider value={{ domainName: "ticketworkspace" }} redirect={false}>
      <Box>
        <Modal
          open={openModal}
          onClose={onCloseModal}
          title={`${t("components.workspace.title")}`}
        >
          <WorkspaceModalCreate handleClose={onCloseModal} />
        </Modal>
        <WorkspacesPopover
          open={openPopover}
          anchorEl={anchorEl}
          handleClose={handleClose}
          handleOpenModal={onOpenModal}
        />
        <Tooltip title={t("components.workspace.workspaces")}>
          <IconButton color="inherit" onClick={handleClick}>
            <WorkspacesIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </DomainProvider>
  );
};

export default memo(Workspaces);
