import { Dispatch, SetStateAction, useCallback } from "react";

import { IGetTicket } from "../../../models/ticket";
import { SignalRContext } from "../../../components/SignalR/SignalRProvider";
import { IArchivedColumn } from "../board-context";
import {
  ICustomerChannel,
  IMemberChannels,
  INewTicketChannelTag,
  ITransferTicketChannel,
  ITransferTicketColumn,
} from "../../../models/channels";
import { useUser } from "@4uhub/lib4uhub";

interface IuuseSignalRFlowProps {
  changeTicket: (
    ticketId: string,
    callback: (t: IGetTicket) => IGetTicket
  ) => void;
  setOpenedTickets: Dispatch<SetStateAction<IGetTicket[]>>;
  setUserTickets: Dispatch<SetStateAction<IGetTicket[]>>;
  boardId: string;
}

const useSignalRFlow = ({
  changeTicket,
  setOpenedTickets,
  setUserTickets,
  boardId,
}: IuuseSignalRFlowProps) => {
  const {
    user: { id },
  } = useUser();

  SignalRContext.useSignalREffect(
    "NewTicketCreated", // Your Event Key
    (userId: string, data: IGetTicket) => {
      console.log("NewTicketCreated", data);

      if (data.status.code === "1") {
        setOpenedTickets((tickets) => [...tickets, data]);
      }
    },
    []
  );

  const handleTicketPosition = useCallback(
    (ticket: IGetTicket, column?: ITransferTicketColumn) => {
      setUserTickets((tickets) => tickets.filter((t) => t.id !== ticket.id));

      setOpenedTickets((tickets) => tickets.filter((t) => t.id !== ticket.id));

      if (column && column?.ticketChannel.id !== boardId) {
        return;
      }

      if (
        ticket.status.code === "2" &&
        ticket.ticketMembers.some((m) => m.user.id === id)
      ) {
        setUserTickets((tickets) => [...tickets, ticket]);
        return;
      }

      if (ticket.status.code === "1") {
        setOpenedTickets((tickets) => [...tickets, ticket]);
        return;
      }
    },
    [setOpenedTickets, setUserTickets, id, boardId]
  );

  //Mover card para outra lista
  SignalRContext.useSignalREffect(
    "TransferTicket", // Your Event Key
    (
      userId: string,
      {
        newTicketChannelStep,
        oldTicketChannelStep,
        ticket,
      }: ITransferTicketChannel
    ) => {
      console.log("TransferTicket - flow", {
        newTicketChannelStep,
        oldTicketChannelStep,
        ticket,
      });
      handleTicketPosition(ticket, newTicketChannelStep);
    },
    [handleTicketPosition]
  );

  // Desarquivar card
  SignalRContext.useSignalREffect(
    "UnarchiveTicket", // Your Event Key
    (userId: string, ticket: IGetTicket) => {
      console.log("UnarchiveTicket", ticket);

      handleTicketPosition(ticket);
    },
    [handleTicketPosition]
  );

  // Arquivar Card
  SignalRContext.useSignalREffect(
    "ArchiveTicket", // Your Event Key
    (userId: string, data: any) => {
      setUserTickets((tickets) =>
        tickets.filter((t) => t.ticketChannelStep.id !== data.id)
      );

      setOpenedTickets((tickets) =>
        tickets.filter((t) => t.ticketChannelStep.id !== data.id)
      );
    },
    [setOpenedTickets, setUserTickets]
  );

  SignalRContext.useSignalREffect(
    "ArchiveTicketChannelStep", // Your Event Key
    (userId: string, data: IArchivedColumn) => {
      console.log("ArchiveTicketChannelStep", data);

      setUserTickets((tickets) =>
        tickets.filter((t) => t.ticketChannelStep.id !== data.id)
      );

      setOpenedTickets((tickets) =>
        tickets.filter((t) => t.ticketChannelStep.id !== data.id)
      );
    },
    [setUserTickets, setOpenedTickets]
  );

  SignalRContext.useSignalREffect(
    "DeleteTicketChannelTag", // Your Event Key
    (userId: string, data: { id: string }) => {
      changeTicket(data.id, (t) => {
        return {
          ...t,
          ticketChannelTags: t.ticketChannelTags.filter(
            (t: { id: string }) => t.id !== data.id
          ),
        };
      });
    },
    [changeTicket]
  );

  SignalRContext.useSignalREffect(
    "TicketCustomerUpdated", // Your Event Key
    (userId: string, data: ICustomerChannel) => {
      console.log("TicketCustomerUpdated", data);

      changeTicket(data.id, (t) => {
        return {
          ...t,
          legalEntityCustomer: data.legalEntityCustomer,
          individualCustomer: data.individualCustomer,
          ticketRelationships: data.ticketRelationships,
        };
      });
    },
    [changeTicket]
  );

  //ticket tags add
  SignalRContext.useSignalREffect(
    "NewTicketChannelTagTicketCreated", // Your Event Key
    (userId: string, data: INewTicketChannelTag) => {
      console.log("NewTicketChannelTagTicketCreated - Table", data);

      changeTicket(data.id, (t) => {
        return {
          ...t,
          ticketChannelTags: [...t.ticketChannelTags, { ...data }],
        };
      });
    },
    [changeTicket]
  );

  //ticket tags remove
  SignalRContext.useSignalREffect(
    "RemoveTicketChannelTagTicket", // Your Event Key
    (userId: string, data: INewTicketChannelTag) => {
      console.log("RemoveTicketChannelTagTicket - Table", data);
      changeTicket(data.id, (t) => {
        return {
          ...t,
          ticketChannelTags: t.ticketChannelTags.filter(
            (tag) => tag.id !== data.id
          ),
        };
      });
    },
    [changeTicket]
  );

  //////////////

  //SignalR

  SignalRContext.useSignalREffect(
    "NewTicketMemberCreated", // Your Event Key
    (userId: string, data: IMemberChannels) => {
      console.log("NewTicketMemberCreated - Flow", data);
      changeTicket(data.ticketId, (t) => {
        return { ...t, ticketMembers: [...t.ticketMembers, data.ticketMember] };
      });
    },
    [changeTicket]
  );

  SignalRContext.useSignalREffect(
    "RemoveTicketMember", // Your Event Key
    (userId: string, data: IMemberChannels) => {
      console.log("RemoveTicketMemberFlow", data);
      changeTicket(data.ticketId, (t) => {
        return {
          ...t,
          ticketMembers: t.ticketMembers.filter(
            (m) => m.id !== data.ticketMember.id
          ),
        };
      });
    },
    [changeTicket]
  );

  SignalRContext.useSignalREffect(
    "TicketUpdated", // Your Event Key
    (userId: string, data) => {
      console.log("TicketUpdatedFlow", data);

      changeTicket(data.id, (t) => {
        return {
          ...t,
          ...data,
        };
      });
    },
    [changeTicket]
  );

  SignalRContext.useSignalREffect(
    "CloseTicket", // Your Event Key
    (userId: string, data: { id: string }) => {
      setUserTickets((t) => t.filter((t) => t.id !== id));
    },
    [setUserTickets]
  );

  // Mensagens não lidas ticket
  SignalRContext.useSignalREffect(
    "UpdateTicketIsRead", // Your Event Key
    (userId: string, data: { id: string; unreadCount: number }) => {
      console.log("UpdateTicketIsRead", data);
      changeTicket(data.id, (t) => {
        return {
          ...t,
          unreadMessages: data.unreadCount,
        };
      });
    },
    []
  );
};

export default useSignalRFlow;
