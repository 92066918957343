import { z } from "zod";
import { ITicketInternalTemplateFields } from "../../../../models/internal-template";

const translationPath = "components.internalTemplate.error";
export const useSchema = () => {
  const getFieldSchema = (field: ITicketInternalTemplateFields) => {
    switch (
      field.ticketAutomationInternalTemplateField
        .ticketAutomationInternalTemplateVariable.dataType.code
    ) {
      case "1":
      case "2":
      case "3":
      case "7":
        if (!field.ticketAutomationInternalTemplateField.isRequired)
          return z.string().nullish();
        return z.string().min(1, translationPath);
      case "4":
      case "6":
      case "5":
        if (!field.ticketAutomationInternalTemplateField.isRequired)
          return z.date().nullish();
        return z.date({ invalid_type_error: translationPath });
      default:
        throw new Error(
          `Unsupported field type: ${field.ticketAutomationInternalTemplateField.ticketAutomationInternalTemplateVariable.dataType.name}`
        );
    }
  };

  return [getFieldSchema];
};
