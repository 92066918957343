import {
  Avatar,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import {
  getInitials,
  stringToColor,
  useFormContext,
  useUser,
} from "@4uhub/lib4uhub";
import { ChangeEvent, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { TFilterSchema } from "../filterSchema";

interface IActualUserProps {
  isCreatedBy?: boolean;
}

const ActualUser = ({ isCreatedBy = false }: IActualUserProps) => {
  const { user } = useUser();

  const { setValue, watch } = useFormContext<TFilterSchema>();

  const members = watch("membersIds");
  const userIds = watch("createdBy");

  const checked = useMemo(
    () => members.includes(user?.id),
    [user?.id, members]
  );

  const hasUserFullName = useMemo(
    () => userIds.includes(user.id),
    [userIds, user.id]
  );

  const { t } = useTranslation();

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (isCreatedBy) {
      if (checked) {
        return setValue("createdBy", [...userIds, user.id], {
          shouldDirty: true,
        });
      }
      if (!checked) {
        return setValue(
          "createdBy",
          userIds.filter((id) => id !== user.id),
          {
            shouldDirty: true,
          }
        );
      }
    } else {
      if (checked) {
        setValue("membersIds", [...members, user?.id], { shouldDirty: true });
      }
      if (!checked) {
        setValue(
          "membersIds",
          members?.filter((member) => member !== user?.id),
          { shouldDirty: true }
        );
      }
    }
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={isCreatedBy ? hasUserFullName : checked}
          onChange={handleChange}
        />
      }
      label={
        <Stack direction={"row"} gap={1} alignItems={"center"}>
          <Avatar
            sx={{
              width: 23,
              height: 23,
              backgroundColor: stringToColor(user.fullName),
              fontSize: 10,
            }}
          >
            {getInitials(user.fullName)}
          </Avatar>

          <Typography variant="body2">
            {isCreatedBy
              ? t("components.boards.filters.createdByMe")
              : t("components.boards.filters.asignToMe")}
          </Typography>
        </Stack>
      }
    />
  );
};

export default ActualUser;
