import { memo } from "react";

import useTicket from "../../hooks/useTask";
import CardContent from "./CardOtherInfo/CardContent/CardContent";

const CardContentContainer = () => {
  const {
    loading,
    ticket: { content, id },
  } = useTicket();

  if (content === undefined) {
    return <></>;
  }

  return <CardContent loading={loading} content={content} id={id} />;
};

export default memo(CardContentContainer);
