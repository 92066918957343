import { Typography } from "@mui/material";
import { motion } from "framer-motion";
import { memo } from "react";
import { useTranslation } from "react-i18next";

export const DragScreen = memo(() => {
  const {t} = useTranslation()
  return (
    <motion.div
      transition={{ duration: 0.2 }}
      initial={{ y: "100%", opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        zIndex: 3,
      }}
    >
      <Typography
        sx={(t) => ({
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          width: "100%",
          height: "100%",
          backgroundColor: t.palette.background.default,
          color: t.palette.text.primary,
        })}
      >
        {t("components.dragScreen.message")}
      </Typography>
    </motion.div>
  );
});
