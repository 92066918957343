import { Box } from "@mui/material";
import WorkspaceForm from "./WorkspaceForm/WorkspaceForm";
import { IGetWorksSpace } from "../../models/workspaces";
import { memo } from "react";

interface IWorkspaceModalEditProps {
  handleClose: () => void;
  workspace?: IGetWorksSpace;
}

const WorkspaceModalEdit: React.FC<IWorkspaceModalEditProps> = ({
  handleClose,
  workspace,
}) => {

  return (
    <Box overflow={"hidden"} >
      <WorkspaceForm handleClose={handleClose} id={workspace?.id} />
    </Box>
  );
};

export default memo(WorkspaceModalEdit);
