import {
  AddIdField,
  IIndividualList,
  ISelectType,
  QPFQuickRegister,
  useFetch,
} from "@4uhub/lib4uhub";
import { Box, Popover, Typography } from "@mui/material";
import { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import usePopover from "../../../../hooks/usePopover";
import HoverOpacity from "../../../UI/HoverOpacity";
import Property from "../../Property";

import useBoardRole from "../../../../hooks/useBoardRole";
import { Iindividual } from "../../../../models/ticket";
import { SignalRContext } from "../../../SignalR/SignalRProvider";

import useTicket from "../../../../hooks/useTask";
import { editRequester } from "../../../../services/ticket.service";

interface ICardRequestProps {
  id: string;
  loading?: boolean;
  onEditRequester?: (requester: ISelectType) => void;
  individualRequester: Iindividual | null;
  notIdentifiedName: string | null;
}

const CardRequester: React.FC<ICardRequestProps> = ({
  id,
  individualRequester,
  notIdentifiedName,
  loading,
  onEditRequester,
}) => {
  const props = usePopover("ticket-requester-popover");

  const [value, setValue] = useState<AddIdField<IIndividualList> | null>(null);

  const permission = useBoardRole("2");

  const {
    isUserTicketMember,
    ticket: { status },
  } = useTicket();

  const { t } = useTranslation();

  SignalRContext.useSignalREffect(
    "IndividualRequesterTicketUpdated", // Your Event Key
    (
      userId: string,
      data: { id: string; individualRequester: Iindividual }
    ) => {
      if (data.id === id) {
        console.log("IndividualRequesterTicketUpdated", data);
        onEditRequester && onEditRequester(data.individualRequester);
      }
    },
    [onEditRequester, id]
  );
  const { sendRequest } = useFetch(editRequester);

  const { handleClose } = props;

  const handleChange = useCallback(
    async (requester: AddIdField<IIndividualList> | null) => {
      setValue(requester);
      if (!requester) return;
      handleClose();

      await sendRequest({
        id: id,
        individualRequesterId: requester.id,
      });
    },
    [sendRequest, id, handleClose]
  );

  const isDisabled = !isUserTicketMember || !permission || status.code === "3";

  return (
    <>
      <HoverOpacity onClick={props.handleClick} disabled={isDisabled}>
        <Property
          content={
            individualRequester ? (
              <Typography color={(t) => t.palette.text.primary} fontSize={14}>
                {individualRequester.name}
              </Typography>
            ) : notIdentifiedName ? (
              <Typography color={(t) => t.palette.text.primary} fontSize={14}>
                {notIdentifiedName}
              </Typography>
            ) : null
          }
          disable={isDisabled}
          loading={loading}
          label={t("page.card.requester")}
        />
      </HoverOpacity>
      <Popover
        {...props}
        title={t("page.card.requester") + ""}
        onClose={props.handleClose}
      >
        <Box p={2}>
          <QPFQuickRegister
            onChange={handleChange}
            value={value}
            label={t("page.card.requester")}
          />
        </Box>
      </Popover>
    </>
  );
};
export default memo(CardRequester);
