import { FC, memo } from "react";
import useMessageInput from "../../../hooks/useMessageInput";
import { useTranslation } from "react-i18next";

import SendIcon from "@mui/icons-material/Send";
import { PopoverProps, Stack, Typography } from "@mui/material";

import IconButton from "../../UI/IconButton";

import { IQuickAnswer } from "../../../models/QuickAnswers";

export interface AnswersProps {
    answers: IQuickAnswer[];
    onClose: PopoverProps['onClose']
}

const Answers: FC<AnswersProps> = ({ answers = [], onClose }) => {
    const { t } = useTranslation();
    const { setMessage } = useMessageInput();

    const onPickMessage = (msg: string) => {
        setMessage(msg);
        onClose?.({}, "escapeKeyDown");
    };

    return <>
        {answers?.length === 0 
            ? <Typography>
                {t("components.chat.quickReplay.popover.empty")}
              </Typography> 
            : answers.map(({ id, shortCut, message }) => (
                <Stack direction="row" gap={1} key={id}>
                    <Typography
                        color={(t) => t.palette.text.disabled}
                        sx={{
                            overflow: "auto",
                            minWidth: "20%",
                        }}
                        >
                        {shortCut}
                    </Typography>

                    <Typography
                        sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                        }}
                        >
                        {message}
                    </Typography>

                    <IconButton
                        size="small"
                        sx={{ alignSelf: "center" }}
                        onClick={() => onPickMessage(message)}
                    >
                        <SendIcon fontSize="small" />
                    </IconButton>
                </Stack>
        ))}
    </>
};

export default memo(Answers);