import { Typography } from "@mui/material";
import { memo } from "react";

interface IProtocolNumberProps {
  protocolNumber: string;
}

const ProtocolNumber: React.FC<IProtocolNumberProps> = ({ protocolNumber }) => {
  return <Typography variant="caption" lineHeight={1}>#{protocolNumber}</Typography>;
};

export default memo(ProtocolNumber);
