import {
  ListItemText,
  MenuItem,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { MuiColorInput } from "mui-color-input";
import { useState } from "react";
import { Button, useFetch } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";

import useColumn from "../../../../../hooks/useColumn";
import { changeColumnColor } from "../../../../../services/column.serivice";
import usePopover from "../../../../../hooks/usePopover";

const ColorPicker: React.FC<{ id: string }> = ({ id: cardId }) => {
  const { data, setColor: setColumColor } = useColumn();

  const { anchorEl, handleClick, handleClose, open, id } = usePopover(
    "color-picker-popover"
  );

  const { t } = useTranslation();

  const [color, setColor] = useState<string>(data.backgroundColor || "");

  const { sendRequest, loading } = useFetch(changeColumnColor);

  const handleChange = (color: string) => {
    setColor(color);
  };

  const handleColumnColorChange = async () => {
    const { success } = await sendRequest({
      id: cardId,
      backgroundColor: color,
    });
    if (success) {
      setColumColor(color);
      handleClose();
    }
  };

  return (
    <>
      <MenuItem aria-describedby={id} onClick={handleClick}>
        <ListItemText>
          {t("components.column.menu.changeColor.title")}
        </ListItemText>
      </MenuItem>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Stack gap={1} padding={1}>
          <Typography>
            {" "}
            {t("components.column.menu.changeColor.select")}
          </Typography>
          <MuiColorInput value={color} onChange={handleChange} />
          <Button
            onClick={handleColumnColorChange}
            variant="outlined"
            loading={loading}
          >
            {t("components.column.menu.changeColor.change_color")}
          </Button>
        </Stack>
      </Popover>
    </>
  );
};

export default ColorPicker;
