import { Button, useFetch } from "@4uhub/lib4uhub";
import { memo, useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import DoneIcon from "@mui/icons-material/Done";

import {
  finishTicket,
  getTicketChannelStep,
} from "../../../../services/ticket.service";
import useTicket from "../../../../hooks/useTask";
import { useTicketHeader } from "../../../../store/context/card-header-context";
import FinishActionPopover from "./FinishActionPopover";

const translationPath = "components.joinAction.";

const FinishAction = () => {
  const [open, setOpen] = useState(false);

  const { sendRequest: getTicketChannelStepFinished, loading: tLoading } =
    useFetch(getTicketChannelStep);

  const { sendRequest, loading } = useFetch(finishTicket);

  const { ticket, onEditStatus, onEditList } = useTicket();

  const { onFinish } = useTicketHeader();

  const { t } = useTranslation();

  const divRef = useRef(null);

  const ticketFinishHandler = useCallback(async () => {
    if (!ticket) return;
    const { data, success } = await sendRequest({
      id: ticket.id,
    });
    if (data && success) {
      onFinish && onFinish(ticket.id);
      onEditStatus && onEditStatus(data.status);
      onEditList && onEditList(data.ticketChannelStep);
    }
  }, [ticket, sendRequest, onEditStatus, onEditList, onFinish]);

  const getTicketChannelStepHandler = useCallback(async () => {
    if (!ticket) return;
    const { data, success } = await getTicketChannelStepFinished({
      TicketChannelId: ticket.ticketChannelStep.ticketChannelId,
      StatusCode: "3",
    });
    if (data && success) {
      if (data.length === 0) {
        setOpen(true);
      }
      if (data.length !== 0) {
        ticketFinishHandler();
      }
    }
  }, [getTicketChannelStepFinished, ticket, ticketFinishHandler]);

  const isFinishing = loading || tLoading;

  const handleClose = () => setOpen(false);

  return (
    <>
      <FinishActionPopover
        open={open}
        anchorEl={divRef.current}
        onClose={handleClose}
      />
      <Button
        variant="contained"
        loading={isFinishing}
        size="small"
        onClick={getTicketChannelStepHandler}
        endIcon={<DoneIcon />}
      >
        {t(translationPath + "finish")}
      </Button>
      <div ref={divRef}></div>
    </>
  );
};

export default memo(FinishAction);
