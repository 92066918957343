import { IFilter } from "../../../models/Board";
import { IGetColumn } from "../../../models/columns";

export const filterColumns = (columns: IGetColumn[], filter: IFilter) => {
  return columns.map((c) => {
    const modifiedColumn = { ...c };

    modifiedColumn.tasks = modifiedColumn.tasks?.filter((t) =>
      t.title.toLowerCase().includes(filter.keyword.toLowerCase() || "")
    );

    if (filter.membersIds.length > 0 || filter.createdBy.length > 0) {
      modifiedColumn.tasks = modifiedColumn.tasks?.filter((t) => {
        const members = t.ticketMembers;
        const createdById = t.createdBy;

        const noMember = members?.length === 0;

        const foundId = members?.some((m) =>
          filter.membersIds.includes(m.user.id)
        );

        const foundCreatedBy = filter.createdBy.some(
          (id) => id === createdById
        );

        const alreadyParticipated = t.viewUser;

        if (filter.membersIds.includes("none")) {
          return noMember || foundId;
        }

        if (filter.membersIds.includes("alreadyParticipated")) {
          return noMember || foundId || alreadyParticipated;
        }

        if (foundCreatedBy) {
          return foundCreatedBy;
        }

        return foundId;
      });
    }

    return modifiedColumn;
  });
};
