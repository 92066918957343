const en_US = {
  translations: {
    page: {
      noAccess: {
        message:
          "Oops! It looks like you don't have permission to access this page!",
        button: "Back to home",
      },
      login: {
        login: "Access",
        accessing: "Accessing",
        welcome: "Welcome back",
        rememberme: "Remember me",
        forget_password: "Forgot password?",
        username: "CPF",
        password: "Password",
      },
      home: {
        Workspaces: "Your workspaces",
        seeArchived: "View Archived workspaces",
        archived: {
          title: "Archived workspaces",
          empty: "We did not find any archived workspaces",
        },
      },
      workspace: {
        title: "Your boards",
        archived: {
          button: "Show closed boards",
          title: "Closed boards",
          empty: "No closed frame found",
          unarchive: "Discover",
          delete: "Delete",
        },
        create: "Create new board",
      },
      board: {
        createCard: {
          modalTitle: "Add ticket(card) to list {{name}}",
          title: "Title",
          type: "Type",
          create: "Add ticket(card)",
          requester: "Requester",
          origin: "Origin",
          clientType: "Client type",
          client: "Client",
          pf: "Individual",
          pj: "Legal entity",
          not_identified: "Not Identified",
          not_identified_name: "Name (optional)",
          not_identified_phone: "Phone (optional)",
          pf_client: "Individual client",
          pj_client: "Legal entity client",
          service: "Service",
          noService: "No available ticket(card) service",
          inform_request: "Inform a different requester",
          form: {
            client: "Select a customer",
            requester: "Select a applicant",
            service: "Select a service",
            title: "Enter a title",
            phoneValid: "Enter a valuable phone number",
            phoneRequired: "Enter a phone number",
            origin: "Select a origin",
          },
        },
        settings: {
          noSelected: "No selected menu",
          archived: {
            changeToCards: "Change to Card",
            changeToColumns: "Change to columns ",
            search: "Search for archivates",
            cards: {
              unarchive: "Discover",
              delete: "Delete",
              title: "Ticket(card)",
              description:
                "When erasing the ticket(card), all its data is erased.This action cannot be undone",
              secureLabel: "Enter the name of the ticket(card) to delete",
            },
          },
        },
      },
      card: {
        events: "Events",
        internal_template: "Internal Template",
        activity: "Activity",
        unreadMessage: "{{number}} unread messages",
        client_sales_contract: "Client Sales Contract",
        sales_contract: "Contracts",
        inList: "In list:  {{list}}",
        noClient: "No clients found",
        history: "History",
        finish: "Finish",
        join: "Join",
        type: "Type",
        detail: "Ticket(card) details",
        client: "Client",
        client_not_identified: "Client (Not identified PF/PJ)",
        requester: "Requester",
        clientEntityHelperText:
          "Type at least 3 characters to search companies",
        clientIndividualHelperText:
          "Type at least 3 characters to search individuals",
        selectStatus: "Select a status",
        selectOrigin: "Select a origin",
        selectCustomerServiceType: "Select a service type",
        whatsappNumber: "WhatsApp number",
        whatsappName: "WhatsApp name",
        description: "Description",
        edit: "Edit",
        save: "Save",
        cancel: "Cancel",
        status: "Status",
        origin: "Origin",
        protocol: "Protocol",
        copied: "Protocol copied successfully",
        copyTooltip: "Copy protocol",
        number: "Number",
        customerServiceType: "Service Type",
        view_more: "View more",
        view_less: "View less",
        ticket_external_process_type: "External Process Type",
        ticket_external_identifier: "External Process Identifier",
        services: "Services",
        ticket_external_identifier_error:
          "The external process identifier is mandatory",
        ticket_external_process_type_error:
          "You need to select an external process type first",
        actions: {
          title: "Actions",
          archive: "Archive",
          move: "Move",
          leave: "Leave Ticket",
          move_and_leave: "Move and Leave Ticket",
          reopen: {
            reopen_successfully: "Ticket(card) reopened successfully",
            button: "Reopen",
          },
          moveTicket: "Move ticket(card)",
          list: "List",
          board: "Board",
          order: "Order",
          workspace: "Workspace",
        },
        chat: {
          title: "Chat",
          file: "Attach file",
          emoji: "Emojis",
          refresh: "Refresh",
          quickReplay: "Quick replies",
          chatPlaceholder: "Type your message...",
          hasRequesterMessage:
            "To send a message, the requester must send at least one message",
          noMessages: {
            startChat: {
              title: "No conversations found",
              subtitle: "Click the button below to start a conversation",
              button: "Start conversation",
            },
            whatsappNumber: {
              title: "Enter a WhatsApp number",
              subtitle: "The WhatsApp number will be used to send messages",
              country: "DDI",
              phone: "WhatsApp number",
              phone_options: "Select a number or add a new one",
              errors: {
                country: "The DDI is mandatory",
                phone: "The WhatsApp number is mandatory",
              },
            },
            selectAutomationRule: {
              title: "Enter an automation rule",
              subtitle:
                "The automation rule will be used to send automatic messages",
              restart_title: "Restart conversation",
              restart_subtitle:
                "The automation rule will be used to restart the conversation",
              automation_rule: "Automation rule",
              chat_started: "Conversation started successfully",
              errors: {
                automation_rule: "A regra de automação é obrigatória",
              },
            },
            noMessagesActions: {
              back: "Back",
              next: "Next",
              finish: "Finish",
              restart: "Restart",
            },
          },
        },
        flow: {
          openTickets: "Open tickets(cards)",
          userTickets: "My tickets(cards)",
          filter: "Filter by List",
          noTickets: "No open tickets(cards) found",
          noTicketFound: "No ticket(card) found.Enter any ticket(card)",
          noTicketSelected: "No selected ticket(card).Select a ticket(card)",
          addTicketButton: {
            btn_label: "Ticket(card)",
            tooltip:
              "To add a ticket(card) you need to have a list with 'Open' status",
          },
        },
      },
    },
    components: {
      dragScreen: {
        message: "Drag the file here",
      },
      table: {
        no_finish_date: "No finalization date",
        status: "State",
        protocolNumber: "Protocological number ",
        ticketService: "Ticket(card) Service",
        ticketSequential: "ID internal",
        ticket: "Task(Card)",
        column: "List",
        individualRequester: "Requester",
        unreadMessages: "Unadalled Messages",
        individual: "Individual",
        created: "Created in",
        finishDate: "Finished in",
        ticketChannelTags: "Tags",
        ticketMembers: "Members",
        empty: "No task found",
      },
      empty: {
        title: "Nothing found",
      },
      menuBoards: {
        your_boards: "Your boards",
        current_workspace: "Workspace",
        minimize: "Minimize",
        view_boards: "Viewing",
      },
      BoardNotFound: {
        title: "Board not found",
        button: "Back to board list",
      },
      workspacesPopover: {
        current_workspace: "Current Workspace",
        workspaces: "Workspaces",
        create_workspace: "Create workspace",
      },
      workspace: {
        workspaces: "Workspaces",
        title2: "Edit desktop",
        see_more: "See more",
        create_workspace: "Create workspace",
        edit: "Edit",
        title: "Create new workspace",
        subtitle:
          "The desktop was designed exclusively to help you have a more productive day. Create a new workspace and be more productive!",
        name: "Desktop name",
        establishments: "Establishments",
        create: "Create",
        delete: {
          title:
            'Enter the name of the desktop "{{secureReferenceText}}" to delete',
          description:
            "When deleting all tickets(cards) will be lost.This action is irreversible",
          label: "Enter the name of the desktop to delete",
        },
        archive: {
          title: "Archive",
          description:
            "You can find and unarchive the work and work at the bottom of your desktop screen",
          button: "To file",
          seeArchived: "Visualize archived work",
        },
      },
      boards: {
        errors: {
          finishedColumn:
            "It is not possible to finalize a ticket(card) without first going through the in-process status",
        },
        filters: {
          filters: "Filters",
          clearAll: "Clear filters",
          title: "Title",
          helperText: "Search for ticket(card) titles",
          alreadyParticipated: "Already participated",
          history: "History",
          status: "Situation",
          date: "Dates",
          endDate: "End date",
          initialDate: "Initial date",
          asignToMe: "Assigned to me",
          members: "Members",
          createdBy: "Opened by",
          createdByMe: "Opened by me",
          status_helper: "Add the start and end date for this filter",
          noMembers: "No members",
          selectMembers: "Select members",
          selectMember: "Select a member",
          apply: "Apply filters",
          invalid_endDate: "Invalid final date",
          invalid_initialDate: "Invalid inital date",
          openedBySystem: "Opened by system",
        },
        delete: {
          title:
            'Enter the name of the table "{{secureReferenceText}}" to delete',
          description:
            "When deleting all tickets(cards) will be lost. This action is irreversible",
          label: "Enter  the name of the board to delete",
        },
        form: {
          edit: "Edit frame",
          create: "Create",
          title: "Title",
          type: "Type",
          parent: "Parent frame",
        },
        settingsMenu: {
          archivedItem: "Archived items",
          closeBoard: "Close",
          editBoard: "Edit frame",
          selectParent: "Select View Board",
          select: "Select",
          pages: {
            menu: "Menu",
            archived: "Filed",
          },
          archived: {
            unarchive: "Discover",
            remove: "Remove",
            secureRemove: {
              title: 'Enter the name of the "{{reference}}" list to delete',
              description:
                "When erasing the list all her tasks will also be deleted.",
              button: "Close board",
              secureLabel: "Enter the name of the list to delete",
            },
          },
          closeBoardDescription:
            "You can find and reopen your frames on the desktop screen",
        },
      },
      chat: {
        quickReplay: {
          send: "Send",
          delete: "Delete",
          edit: "Edit",
          popover: {
            title: "Quick answers (type at least 3 letters)",
            empty: "No answer found",
          },
          modal: {
            title: "Quick Answers",
          },
          list: {
            empty: "No quick response found",
            register: "Register",
          },
          deleteModal: {
            title: "Rapid breath",
            description:
              "The quick answer will be deleted.This action cannot be undone",
            delete: "Delete",
          },
          form: {
            edit: "Edit reply",
            create: "Create Answer",
            cancel: "Cancel",
            shareType: "Sharing",
            shortCut: "Shortcut",
            message: "Message",
            title: "Title",
          },
        },
        restartMessagePopUp: {
          title: "Conversation expired!",
          subtitle:
            "This conversation has had no interactions in the last 24 hours. Click the button to restart it.",
          buttonRestart: "Restart",
          buttonFinish: "Finish",
          finishConfirm: {
            modal_title: "Finish the Ticket?",
            cancel: "Cancel",
            confirm: "Confirm",
            finish_ticket:
              "Are you sure you want to finish this ticket (card)? It will not be possible to deliver the completion message because the 24-hour period has been exceeded. If you wish to continue this conversation, click the Restart button.",
          },
        },
      },
      column: {
        transferred: "Transferred list",
        minimize: "Minimize",
        maximize: "Maximize",
        menu: {
          title: "Stock list",
          transferred: {
            mark: "Mark as a list of transferred",
            unmark: "Uncheck as a list of transferred",
          },
          changeParent: {
            title: "Change viewing column",
            change: "Change column",
            button: "Change",
          },
          changeDefaultStatus: {
            title: "Change standard status",
            change: "Change status",
            button: "Change",
          },
          changeColor: {
            title: "Change color",
            change_color: "Change color",
            select: "Select color",
          },
          archive: {
            title: "Archive",
          },
        },
        task: {
          addTask: "Add ticket(card)",
        },
        addColumn: {
          addColumn: "Add Column",
          name: "Name",
          nameRequired: "The name is required",
          parentRequired: "The parent column is required",
          parent: "Parent column",
        },
      },
      ticketTagsList: {
        tags: "Tags",
        add_tag: "Add Tag",
      },
      cardTagSelector: {
        tags: "Tags",
        tag: "Tag",
        create_tag: "Create tag",
      },
      tagDeleteButton: {
        delete: "Delete Tag",
        description:
          "This action will cause the Tag to be removed from all cards to which it was assigned.",
        delete_button: "Delete",
      },
      tagItemActions: {
        edit: "Edit Tag",
      },
      tagForm: {
        name: "Nome",
        enable: "Enable",
        display_order: "Display order",
        ticket_channel: "Ticket(Card) type",
        color: "Select a color",
        errors: {
          name: "The name is mandatory",
          display_order: "The display order é obrigatório",
          ticket_channel: "The ticket(card) type is mandatory",
          color: "The color is mandatory",
        },
      },
      tagFormActions: {
        back: "Back",
        edit: "Edit",
        create: "Create",
      },
      boardHeader: {
        invite: "Invite",
        boardMode: {
          table: "Table",
        },
        invite_member: "Invite member",
        member: {
          user_added: "{{user}} added successfully.",
          function_update: "Role of {{fullName}} changed successfully.",
          remove_successfully: "{{fullName}} removed successfully.",
          leave_board: "You leave the board successfully.",
          you: "(you)",
          removing_board: "Removing board...",
          leave_board_option: "Leave board",
          remove_user_option: "Remove from board",
          select_user: "Select user",
          invite_user: "Invite",
          nickname: "Nickname",
          member_added: "Member added successfully",
          member_updated: "Member updated successfully",
          member_removed: "Member removed successfully",
          errors: {
            user: "The user is mandatory",
            profile: "The profile is mandatory",
          },
        },
      },
      createChecklist: {
        checklist: "Checklist",
        create_checklist_button: "Create checklist",
        name: "Name",
        display_order: "Order",
        errors: {
          name: "The name is mandatory",
          display_order: "The order is mandatory",
        },
      },
      deleteChecklist: {
        delete: "Delete",
        title: "Delete checklist",
        description: "Are you sure you want to delete the checklist?",
      },
      cardChecklistToggleItems: {
        hide: "Hide checked items",
        show: "Show checked items",
      },
      cardChecklistAddItem: {
        button: "Add item",
      },
      checklistAddItemForm: {
        title: "Add item",
        description: "Item",
        forecastStartDate: "Expected start date",
        estimatedTime: "Estimated time in minutes",
        button: "Add",
        errors: {
          description: "The item is mandatory",
          estimatedTime:
            "The estimated time can't be a negative number or zero",
        },
      },
      checklistItemDelete: {
        title: "Remove item",
        description: "This action will remove the item from the checklist",
        button: "Delete",
        tooltip: "Delete item",
      },
      chcecklistItemTime: {
        tooltip: "Set times",
        title: "Set times",
        forecast_start_date: "Expected start date",
        estimated_time: "Estimated time in minutes",
        start_date: "Start date",
        end_date: "End date",
        save: "Save",
        errors: {
          estimated_time:
            "The estimated time can't be a negative number or zero",
        },
      },
      cardMembers: {
        members: "Members",
        add_member: "Add member",
        join: "Join in ticket(card)",
      },
      cardMembersSelector: {
        members: "Members",
        search: "Search members",
        member_added: "Member added successfully",
        member_removed: "Member removed successfully",
      },
      cardHistories: {
        activity: "Activity",
        show_details: "Show details",
        hide_details: "Hide details",
        internal: "Internal",
        external: "External",
      },
      historyComments: {
        placeholder: "Write a comment...",
        save: "Save",
        cancel: "Cancel",
        history_type: "History type",
        errors: {
          message: "The message is mandatory",
          history_type: "The history type is mandatory",
        },
      },
      cardPeriod: {
        created: "Creation date",
        finish_date: "End date",
        started: "Start date",
      },
      joinAction: {
        finish: "Finish",
        join: "Join",
        no_lists:
          "It is not possible to join this ticket(card), as there are no lists with the status 'In process'",
        select: "Select",
      },
      finishActionPopover: {
        no_list:
          "It is not possible to finish this ticket(card) as there is no list with the 'Finished' status",
      },
      advancedSearchHeader: {
        search: "Search",
        protocol_or_id: "Protocol or Internal id",
        button: "Search",
        advanced_search: "Advanced search",
        errors: {
          protocol_or_id: "The protocol or internal id is mandatory",
        },
      },
      advancedSearchViewMore: {
        button: "View more",
      },
      searchResultCards: {
        no_access: "You do not have access to this ticket(card)",
      },
      searchResults: {
        no_workspace_dates: {
          title: "Work area and dates not informed!",
          subtitle:
            "It is necessary to provide at least the work area and the start and end dates to perform a search",
        },
        no_workspace: {
          title: "Workspace not selected!",
          subtitle: "Select at least the workspace to perform a search",
        },
        no_dates: {
          title: "Start and end dates are mandatory",
          subtitle: "You must select a start and end date to start a search",
        },
        range_bigger: {
          title: "Check selected dates!",
          subtitle:
            "The end date cannot be more than 180 days different from the start date",
        },
        filter_click: {
          title: "Now all you have to do is click filter!",
          subtitle: "You need to click 'filter' to start a search",
        },
        no_results: {
          title: "No results found!",
          subtitle: "Change the filter parameters to get a different result",
        },
      },
      searchResultsList: {
        search_results: "Search results:",
      },
      advancedSearchFields: {
        filters: "Filters",
        clean_filters: "Clean filters",
        filter: "Filter",
        workspace: "Workspace",
        start_date: "Start date",
        end_date: "End date",
        protocol_or_id: "Protocol or Internal id",
        title: "Title",
        individual_requester_name: "Requester name",
        individual_client_name: "Client name",
        created_by: "Open by",
        created_by_no_options: "No options",
        status: "Status",
        board: "Board",
        filter_by: "Filter by",
        create: "Creation",
        finish: "Finish",
        errors: {
          range: "Maximum search of 180 days",
        },
      },
      protocolDefault: {
        protocol_default: "Standard Guidance Protocols",
      },
      protocolsDefaultList: {
        no_results: "No results found",
        viw_more: "View more",
      },
      protocolDefaultModal: {
        search: "Search for a protocol",
      },
      sharedContacts: {
        title: "Shared contact(s):",
        another_contact: "and one more contact",
        another_contacts: "and other contacts",
        copy_tooltip: "Copied to clipboard",
        copied: "Copy to clipboard",
        formatted_name: "Name",
        first_name: "First Name",
        last_name: "Last Name",
        prefix_name: "Prefix",
        sufix_name: "Suffix",
        whatsapp: "WhatsApp",
        phone: "Phone",
        email: "Email",
        address: "Address",
        address_type: "Address Type",
        zip_code: "Zip Code",
        street: "Street",
        city: "City",
        state: "State",
        country: "Country",
        url: "Link",
        birthday: "Date of Birth",
      },
      sharedLocation: {
        title: "Shared Location:",
        show: "View location",
      },
      detailFiles: {
        files: "Files:",
        no_protocol_selected: "No protocol selected",
        no_data: "No files found",
      },
      detailFile: {
        no_data: "No file found",
      },
      detailFileActions: {
        view_file: "View file",
        view_more: "View more",
      },
      detailMessage: {
        message: "Message:",
        no_protocol_selected: "No protocol selected",
        no_data: "No messages found",
      },
      detailSend: {
        send: "Send",
        sent_successfully: "Protocol sent successfully",
      },
      events: {
        event_type: "Event type",
        event: "Event",
        initial_date: "Initial date",
        end_date: "End date",
        created_by: "Operator",
        no_data: "No event found",
        event_created: "Event created successfully",
        event_updated: "Event updated successfully",
        select_event_hint: "You must select an event type to select an event",
        header: {
          search: "Search for an event",
          add_event: "Add event",
          back: "Back",
        },
        actions: {
          save: "Save",
          cancel: "Cancel",
        },
        errors: {
          event: "The event is mandatory",
          initial_date: "The initial date is mandatory",
          end_date: "The end date cannot be less than the start date",
        },
      },
      internalTemplate: {
        internal_template: "Internal Template",
        link_template: "Link Template",
        change_template: "Change Template",
        error: "This field is required",
        change: "Change",
        there_isnt_template: "There isn't internal template linked.",
        modal_title: "Change Template?",
        delete_template: "This will delete all variable values.",
        update_successfully: "Variable values ​​updated successfully",
        actions: {
          save: "Save",
          cancel: "Cancel",
        },
        errors: {
          event: "The event is mandatory",
          initial_date: "The initial date is mandatory",
          end_date: "The end date cannot be less than the start date",
        },
      },
      statusMessage: {
        tooltip: "View status",
      },
      statusDrawer: {
        message_status: "Message status",
        delivered: "Delivered",
        read: "Read",
        sent: "Sent",
        error: "Oops, an error occurred!",
      },
      logout: "Logout",
      settings: "Settings",
      cardServices: {
        whatsapp_number: "WhatsApp number",
        no_data_dialog: "No messages found for this ticket",
        requester: "Requester",
        client: "Client",
        no_data: "No ticket(card) found",
        history: "History",
        go_to_ticket: "Go to ticket(card)",
        view_more: "View more",
      },
    },
    general: {
      login_expired: "Expired login",
      archive: "To file",
    },
  },
};

export default en_US;
