import { MenuItem, Stack } from "@mui/material";
import { memo, useCallback } from "react";
import { useFetch } from "@4uhub/lib4uhub";

import Tag from "../Tag";
import { ITag } from "../../../../models/tags";
import TicketChannelTagService from "../../../../services/ticketChannelTag.service";
import TagCheckBox from "./TagCheckBox";
import TagEditButton from "./TagEditButton";

const tagTicketService = new TicketChannelTagService();

interface ITagItemProps {
  ticketId: string;
  tag: ITag;
  onEditTag: (tagId: string) => void;
  removeTicketTags: (id: string) => void;
  isChecked: boolean;
}

const TagItem: React.FC<ITagItemProps> = ({
  tag,
  onEditTag,
  ticketId,
  removeTicketTags,
  isChecked,
}) => {
  const { sendRequest: addTag } = useFetch(tagTicketService.create);

  const { sendRequest: removeTag } = useFetch(tagTicketService.deleteItemById);

  const addTagHandler = useCallback(async () => {
    if (ticketId) {
      await addTag({
        ticketChannelTagId: tag.id,
        ticketId: ticketId,
      });
    }
  }, [addTag, ticketId, tag.id]);

  const removeHandler = useCallback(async () => {
    if (ticketId) {
      const { data, success } = await removeTag({
        id: ticketId,
        item: {
          ticketChannelTagId: tag.id,
          ticketId: ticketId,
        },
      });
      if (data && success) {
        removeTicketTags(tag.id);
      }
    }
  }, [removeTag, removeTicketTags, tag.id, ticketId]);

  return (
    <Stack flexDirection={"row"} alignItems={"center"} width={"100%"}>
      <TagCheckBox
        isChecked={isChecked}
        tagId={tag.id}
        addTagHandler={addTagHandler}
        removeHandler={removeHandler}
      />
      <MenuItem
        value={tag.id}
        onClick={() => {
          if (!isChecked) {
            addTagHandler();
          } else {
            removeHandler();
          }
        }}
        sx={{ width: "100%", paddingX: 0.5, paddingY: 0.5 }}
      >
        <Tag data={tag} fullWidth />
      </MenuItem>
      <TagEditButton onEditTag={onEditTag} tagId={tag.id} />
    </Stack>
  );
};

export default memo(TagItem);
