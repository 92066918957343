import { createContext, memo, useContext } from "react";
import { IGetTicket } from "../../models/ticket";

interface ITickerHeaderContext {
  ticketId: string;
  onMove?: (tickedId: string) => void;
  onArchive?: (id: string) => void;
  onJoin?: (ticket: IGetTicket) => void;
  onFinish?: (id: string) => void;
}

export const TickerHeaderContext = createContext<ITickerHeaderContext>({
  ticketId: "",
});

interface ITickerHeaderProvider extends ITickerHeaderContext {
  children: any;
  ticketId: string;
}

const TicketHeaderProvider: React.FC<ITickerHeaderProvider> = ({
  children,
  ...props
}) => {
  return (
    <TickerHeaderContext.Provider value={props}>
      {children}
    </TickerHeaderContext.Provider>
  );
};

export const useTicketHeader = () => {
  return useContext(TickerHeaderContext);
};

export default memo(TicketHeaderProvider);
