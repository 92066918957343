import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Box, IconButton, Stack, Typography } from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const variants = {
  open: { opacity: 1, height: "fit-content" },
  closed: { height: 0, opacity: 0 },
};

const MenuSection: React.FC<{
  children: any;
  title: string;
  rightContent?: JSX.Element;
}> = ({ children, title, rightContent }) => {
  const [view, setView] = useState(true);

  return (
    <Box
      sx={(t) => ({
        padding: t.spacing(0.5),
        paddingX: t.spacing(1),
      })}
    >
      <Stack
        alignItems={"center"}
        justifyContent={"space-between"}
        direction={"row"}
      >
        <Stack alignItems={"center"} gap={1} direction={"row"}>
          <IconButton size="small" onClick={() => setView((view) => !view)}>
            {view ? (
              <KeyboardArrowDownIcon fontSize="small" />
            ) : (
              <KeyboardArrowRightIcon fontSize="small" />
            )}
          </IconButton>
          <Typography
            fontWeight={"bold"}
            sx={(t) => ({
              color: t.palette.text.primary,
            })}
          >
            {title}
          </Typography>
        </Stack>
        {rightContent}
      </Stack>
      <motion.nav animate={view ? "open" : "closed"} variants={variants}>
        <AnimatePresence>{view && children}</AnimatePresence>
      </motion.nav>
    </Box>
  );
};

export default MenuSection;
