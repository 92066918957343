import {
  AutoCompleteUncontrolled,
  Button,
  ISelectType,
  useFetch,
} from "@4uhub/lib4uhub";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  createTicketTemplateInternal,
  listTemplatesInternalTemplates,
} from "../../../../services/ticketAutomationInternalTemplateTemplate.service";
import { CardInternalTemplateSelectModal } from "../CardInternalTemplateSelectModal/CardInternalTemplateSelectModal";

const translationPath = "components.internalTemplate.";
export const InternalTemplateSelect = memo(
  ({
    ticketAutomationInternalTemplate,
    ticketId,
    loadTicketInternalTemplate,
    hasTicketInternalTemplate,
    isUserTicketMember,
    isSubmitting,
  }: {
    ticketAutomationInternalTemplate: ISelectType | null;
    ticketId: string;
    loadTicketInternalTemplate: () => void;
    hasTicketInternalTemplate: boolean;
    isUserTicketMember: boolean | undefined;
    isSubmitting: boolean;
  }) => {
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [
      selectedTicketAutomationInternalTemplate,
      setSelectedTicketAutomationInternalTemplate,
    ] = useState<ISelectType | null>(null);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));

    const onCloseModalHandler = () => setOpen(false);
    const onOpenModal = () => setOpen(true);

    const { sendRequest: save, loading } = useFetch(
      createTicketTemplateInternal
    );

    const handleClick = () => {
      onOpenModal();
    };
    const saveHandler = useCallback(async () => {
      if (!selectedTicketAutomationInternalTemplate) return;
      const { data, success } = await save({
        ticketId: ticketId,
        ticketAutomationInternalTemplateId:
          selectedTicketAutomationInternalTemplate.id,
      });
      if (data && success) {
        onCloseModalHandler();
        loadTicketInternalTemplate();
      }
    }, [
      save,
      loadTicketInternalTemplate,
      selectedTicketAutomationInternalTemplate,
      ticketId,
    ]);

    useEffect(() => {
      setSelectedTicketAutomationInternalTemplate(
        ticketAutomationInternalTemplate
      );
    }, [
      setSelectedTicketAutomationInternalTemplate,
      ticketAutomationInternalTemplate,
    ]);

    return (
      <Box
        sx={(t) => ({
          p: 2,
          m: 1,
          backgroundColor: t.palette.background.paper,
          borderRadius: 2,
        })}
      >
        <CardInternalTemplateSelectModal
          onCloseModalHandler={onCloseModalHandler}
          open={open}
          saveHandler={saveHandler}
        />

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            gap: 1,
            flexWrap: matches ? "wrap" : undefined,
          }}
        >
          <AutoCompleteUncontrolled
            value={selectedTicketAutomationInternalTemplate}
            onValueSelected={setSelectedTicketAutomationInternalTemplate}
            service={listTemplatesInternalTemplates}
            size="small"
            label={t(translationPath + "internal_template")}
            disabled={!isUserTicketMember}
            clearable={false}
          />

          <Button
            sx={{ whiteSpace: "nowrap", px: 3 }}
            onClick={!hasTicketInternalTemplate ? saveHandler : handleClick}
            variant="contained"
            color="primary"
            loading={loading}
            disabled={
              !isUserTicketMember ||
              isSubmitting ||
              selectedTicketAutomationInternalTemplate?.id ===
                ticketAutomationInternalTemplate?.id
            }
          >
            {!hasTicketInternalTemplate
              ? t(translationPath + "link_template")
              : t(translationPath + "change_template")}
          </Button>
        </Box>
      </Box>
    );
  }
);
