import { Stack } from "@mui/material";

import { ITag } from "../../../models/tags";
import { memo } from "react";
import CardTag from "./CardTag";
import AddTagButton from "./AddTagButton";
import CardTagTitle from "./CardTagTitle";

interface ITicketTagsListProps {
  loading: boolean;
  disabled?: boolean;
  handleClick: (event: React.MouseEvent<Element, MouseEvent>) => void;
  ticketTags: ITag[];
}

const TicketTagsList: React.FC<ITicketTagsListProps> = ({
  loading,
  handleClick,
  disabled,
  ticketTags,
}) => {
  return (
    <>
      <CardTagTitle />
      <Stack flexDirection={"row"} flexWrap={"wrap"} gap={1}>
        {ticketTags.map((tag) => (
          <CardTag
            disabled={disabled}
            onClick={handleClick}
            loading={loading}
            key={tag.id}
            tag={tag}
          />
        ))}

        <AddTagButton disabled={disabled} onClick={handleClick} />
      </Stack>
    </>
  );
};

export default memo(TicketTagsList);
