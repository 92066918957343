import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";
import { IListQueryParams, IListReturn } from "../models/ListParams";
import { IGetWorksSpace, INewWorkspace } from "../models/workspaces";
import { IMember } from "../models/members";

const WORKSPACE_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketWorkspaces";

const WORKSPACE_MEMBER_ROUTE =
  process.env.REACT_APP_TICKETS +
  "/api/v1/TicketChannelProfiles/TicketWorkspace/";

export const createWorkspace = (item: INewWorkspace) => {
  return GlobalAxios.post<IGetWorksSpace>(WORKSPACE_ROUTE, item);
};

export const getWorkspaces = (params?: IListQueryParams) => {
  return GlobalAxios.get<IListReturn<IGetWorksSpace>>(WORKSPACE_ROUTE, {
    params,
  });
};

export const getArchivedWorkspaces = (params?: IListQueryParams) => {
  return GlobalAxios.get<IListReturn<IGetWorksSpace>>(
    WORKSPACE_ROUTE + "/Archived",
    {
      params,
    }
  );
};

export const getWorkspaceById = (id: string) => {
  return GlobalAxios.get<IGetWorksSpace>(WORKSPACE_ROUTE + `/${id}`);
};

export const deleteWorkspaceById = (id: string) => {
  return GlobalAxios.put(WORKSPACE_ROUTE + `/${id}/Disable`);
};

export const unArchiveWorkspaceById = (id: string) => {
  return GlobalAxios.put(WORKSPACE_ROUTE + `/${id}/Unarchive`);
};

export const archiveWorkspaceById = (id: string) => {
  return GlobalAxios.put(WORKSPACE_ROUTE + `/${id}/Archive`);
};

export const workspaceList = (SearchBy?: string) => {
  return GlobalAxios.get<ISelectType[]>(WORKSPACE_ROUTE + "/List", {
    params: { SearchBy },
  });
};

export const updateWorkspace = ({
  id,
  item,
}: {
  item: INewWorkspace;
  id: string;
}) => {
  return GlobalAxios.put<IGetWorksSpace>(WORKSPACE_ROUTE + `/${id}`, {
    ...item,
    id,
    enable: true,
  });
};

export const workspaceMembers = (id?: string) => {
  return GlobalAxios.get<IMember[]>(WORKSPACE_MEMBER_ROUTE + id);
};
