import { memo, ReactElement, useCallback, useEffect, useState } from "react";
import { AutoComplete, useFetch, useFormContext } from "@4uhub/lib4uhub";
import { MenuItem, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { TMoveSchema } from "./moveSchema";
import { IGetBoard } from "../../../../../models/Board";
import { getAllBoards } from "../../../../../services/board.service";
import { useWorkspaceContext } from "../../../../../store/context/workspace-context";

interface ISelectBoardProps {
  open: boolean;
}

const SelectBoard = ({ open }: ISelectBoardProps): ReactElement => {
  const [boards, setBoards] = useState<IGetBoard[]>([]);

  const { t } = useTranslation();
  const { actualBoard } = useWorkspaceContext();
  const { sendRequest: findAllBoards } = useFetch(getAllBoards);
  const { setValue, watch, resetField } = useFormContext<TMoveSchema>();

  const { id: workspaceId } = watch("workspace") || {}; // por baixo dos panos esse campo já lê o actualWorkspace do contexto

  const fetchBoards = useCallback(async () => {
    if (!workspaceId) return;

    const { data, success } = await findAllBoards(workspaceId);

    if (data && success) {
      setBoards(data.filter(({ isView }) => !isView));

      // se a workspace escolhida for a mesma do board já salvo, então preenchemos o valor automaticamente
      if (actualBoard?.ticketWorkspace?.id === workspaceId) {
        setValue("board", actualBoard, { shouldValidate: true });
      } else {
        resetField("board"); // caso não seja, limpamos o valor
      }
    }
  }, [
    actualBoard,
    workspaceId,
    setValue,
    resetField,
    setBoards,
    findAllBoards,
  ]);

  useEffect(() => {
    if (open) fetchBoards();
  }, [open, fetchBoards]);

  return (
    <AutoComplete
      name="board"
      options={boards}
      getOptionLabel={({ name }) => name}
      label={t("page.card.actions.board")}
      clearIcon={false}
      size="small"
      renderOption={(params, option) => (
        <MenuItem {...params} key={option.id}>
          <Stack>
            <Typography>{option.name}</Typography>

            <Typography variant="caption">{option.name}</Typography>
          </Stack>
        </MenuItem>
      )}
    />
  );
};

export default memo(SelectBoard);
