import axios from "axios";
import {
  ILoginUser,
  IUsersRefreshTokenPost,
  IUsersTokenPost,
  IUsersTokenPostResponse,
  TForgotPassword,
  TResetPassword,
} from "../models/user";

import { GlobalAxios } from "../axios/axios-config";
import { getUserName } from "./user.service";
import { getTokens } from "./token.service";
import { jsonToFormData } from "@4uhub/lib4uhub";

const BASE_URL = process.env.REACT_APP_IDENTIY;

const queryString = window.location.search;

const urlParams = new URLSearchParams(queryString);

const siteDomain = window.location.hostname;

const parameterDomain = urlParams.get("domain");

const isLocalHost = siteDomain === "localhost";

let headers = {
  Authorization: `Basic ${btoa("dev.4uhub.com.br")}`,
};

if (isLocalHost && parameterDomain) {
  headers = {
    Authorization: `Basic ${btoa(parameterDomain)}`,
  };
}

if (!isLocalHost) {
  headers = {
    Authorization: `Basic ${btoa(
      parameterDomain ? parameterDomain : siteDomain
    )}`,
  };
}

export const login = (user: ILoginUser) => {
  const grant_type = "password";


  const request: IUsersTokenPost = {
    password: user.password,
    username: user.username,
    grant_type,
    scope: "1",
  };

  return GlobalAxios.post<IUsersTokenPostResponse>(
    BASE_URL + "/api/v1/Users/Token",
    jsonToFormData(request),
    {
      headers: headers,
    }
  );
};

export const refresh_token = (establishmentId?: string) => {
  const grant_type = "refresh_token";
  const refresh_token = getTokens().refresh_token;

  const request: IUsersRefreshTokenPost = {
    refresh_token: refresh_token || "",
    username: getUserName() || "",
    establishment_id: establishmentId || "",
    grant_type,
  };

  return axios.post<IUsersTokenPostResponse>(
    BASE_URL + "/api/v1/Users/Refresh",
    jsonToFormData(request),
    {
      headers: headers,
    }
  );
};

interface ITokenByToken {
  establishmentId: string;
  token?: string;
}
export const tokenByToken = ({ establishmentId, token }: ITokenByToken) => {
  return GlobalAxios.post<IUsersTokenPostResponse>(
    BASE_URL + "/api/v1/Users/TokenByToken",
    {
      establishmentId,
    },
    token
      ? {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      : {}
  );
};

export const sendEmail = (param: TForgotPassword) => {
  return axios.post<string>(
    BASE_URL + "/api/v1/Users/ForgotPassword",
    { param: param },
    {
      headers: headers,
    }
  );
};

export const resetPassword = (params: TResetPassword) => {
  return axios.post(BASE_URL + "/api/v1/Users/ResetPassword", params, {
    headers: headers,
  });
};
