import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { memo } from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

import CardChecklistTitle from "./CardChecklistTitle";
import CardChecklistDelete from "./CardChecklistDelete";
import CardChecklistToggleItems from "./CardChecklistToggleItems";

interface ICardChecklistHeaderProps {
  id: string;
  checklistName: string;
  hideItems: boolean;
  showButton: boolean;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  toggleHideItemsHandler: () => void;
}

const CardChecklistHeader: React.FC<ICardChecklistHeaderProps> = ({
  id,
  checklistName,
  hideItems,
  showButton,
  dragHandleProps,
  toggleHideItemsHandler,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stack
      mb={1}
      flexDirection={matches ? "column" : "row"}
      alignItems={"center"}
      justifyContent={matches ? "center" : "space-between"}
      {...dragHandleProps}
    >
      <CardChecklistTitle checklistId={id} checklistName={checklistName} />
      <Stack
        mt={matches ? 1 : 0}
        width={
          showButton && !matches
            ? "40%"
            : matches && showButton
            ? "100%"
            : "fit-content"
        }
        flexDirection={"row"}
        justifyContent={"end"}
        gap={1}
      >
        {showButton && (
          <CardChecklistToggleItems
            hideItems={hideItems}
            toggleHideItemsHandler={toggleHideItemsHandler}
          />
        )}
        <CardChecklistDelete checklistId={id} />
      </Stack>
    </Stack>
  );
};

export default memo(CardChecklistHeader);
