import { Grid } from "@mui/material";
import AvatarStack from "./AvatarStack";
import { IMember } from "../../../models/members";
import { memo } from "react";

interface IBoardMembers {
  members: IMember[];
}

const BoardMembers: React.FC<IBoardMembers> = ({ members }) => {
  return (
    <Grid item xs={"auto"}>
      <AvatarStack
        size={30}
        max={5}
        members={members.map(({ user }) => ({
          id: user.id,
          name: user.fullName,
        }))}
      />
    </Grid>
  );
};

export default memo(BoardMembers);
