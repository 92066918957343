import { Grid } from "@mui/material";
import { memo, useCallback } from "react";
import FormInformation from "../../../UI/FormInformation/FormInformation";
import { useFormContext } from "@4uhub/lib4uhub";
import { TEventMovementForm } from "../eventSchema";
import { useTranslation } from "react-i18next";
import { IEventDataProps } from "./models";

const translationPath = "components.events.";

export const EventData = memo(({ eventType }: IEventDataProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { getValues } = useFormContext<TEventMovementForm>();

  const event = getValues("event");

  const startDate = getValues("startDate");

  const formatDate = useCallback(
    (date: string) =>
      new Date(date)
        .toLocaleString(language, {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: undefined,
        })
        .replaceAll(",", ""),
    [language]
  );

  return (
    <>
      <Grid item xs={12} sm={6}>
        <FormInformation
          data={eventType ? eventType.name : "-"}
          label={t(translationPath + "event_type")}
          minHeight={40}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormInformation
          data={event ? event.name : "-"}
          label={t(translationPath + "event")}
          minHeight={40}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormInformation
          data={startDate ? formatDate(startDate.toISOString()) : "-"}
          label={t(translationPath + "initial_date")}
          minHeight={40}
        />
      </Grid>
    </>
  );
});
