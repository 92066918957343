import { ISelectCountry } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";

const COUNTRY_ROUTE = process.env.REACT_APP_MAIN + "/api/v1/Country/";

export const countriesList = (params?: any) => {
  return GlobalAxios.get<ISelectCountry[]>(COUNTRY_ROUTE + "list", {
    params: params,
  });
};
