import { useTranslation } from "react-i18next";

import { Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import useFlow from "../../hooks/useFlow";
import { IGetTicket } from "../../models/ticket";
import FlowCard from "./FlowCard/FlowCard";

interface IOpenedTicketsProps {
  handleMove: (ticket: IGetTicket) => void;
}

const OpenedTickets = ({ handleMove }: IOpenedTicketsProps) => {
  const { t } = useTranslation();
  const { openedTickets, filteredLists, isSelected } = useFlow();

  const tickets = useMemo(() => {
    const sortedTickets = openedTickets.sort((a, b) =>
      a.created < b.created ? -1 : a.created > b.created ? 1 : 0
    );

    if (filteredLists?.length > 0) {
      return sortedTickets.filter((ticket) =>
        filteredLists?.some(({ id }) => id === ticket?.ticketChannelStep?.id)
      );
    }

    return sortedTickets;
  }, [openedTickets, filteredLists]);

  return (
    <Stack gap={1}>
      {tickets.map((ticket, index) => (
        <FlowCard
          index={index}
          selected={isSelected(ticket?.id)}
          onClick={handleMove}
          data={ticket}
          key={ticket?.id}
        />
      ))}

      {tickets.length === 0 && (
        <Typography variant="body1" sx={{ my: 2, textAlign: "center" }}>
          {t("page.card.flow.noTickets")}
        </Typography>
      )}
    </Stack>
  );
};

export default OpenedTickets;
