import {
  AutoComplete,
  ISelectType,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";
import { memo, ReactElement, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  getWorkspaceById,
  workspaceList,
} from "../../../../../services/workspace.service";
import { useWorkspaceContext } from "../../../../../store/context/workspace-context";
import { TMoveSchema } from "./moveSchema";

const SelectWorkspace = memo((): ReactElement => {
  const [options, setOptions] = useState<ISelectType[]>([]);

  const { t } = useTranslation();
  const { setValue } = useFormContext<TMoveSchema>();
  const { actualWorkspace } = useWorkspaceContext();
  const { sendRequest: retrieveWorkspaces } = useFetch(workspaceList);
  const { sendRequest: retrieveWorkspace } = useFetch(getWorkspaceById);

  const getAvailableWorkspaces = useCallback(async () => {
    if (actualWorkspace && actualWorkspace.id) {
      const { data: workspace, success } = await retrieveWorkspace(
        actualWorkspace.id
      );

      if (workspace && success) setValue("workspace", workspace);
    }

    const { data: workspaces, success } = await retrieveWorkspaces(undefined);

    if (workspaces && success)
      setOptions(
        workspaces.map((workspace) => ({
          id: workspace.id,
          name: workspace.name,
        }))
      );
  }, [actualWorkspace, retrieveWorkspaces, retrieveWorkspace, setValue]);

  useEffect(() => {
    getAvailableWorkspaces();
  }, [getAvailableWorkspaces]);

  return (
    <AutoComplete
      name="workspace"
      size="small"
      clearIcon={false}
      label={t("page.card.actions.workspace")}
      options={options}
      getOptionLabel={({ name }) => name}
    />
  );
});

export default SelectWorkspace;
