import { Dispatch, SetStateAction } from "react";
import { SignalRContext } from "../../../components/SignalR/SignalRProvider";
import { IChecklist, IChecklistItem } from "../../../models/ticketChecklist";

interface IuseSignalRChecklistProps {
  addChecklist: (checklist: IChecklist) => void;
  setChecklists: Dispatch<SetStateAction<IChecklist[]>>;
  updateChecklist: ({ id, newName }: { id: string; newName: string }) => void;
  removeChecklist: (id: string) => void;
  addChecklistItem: (
    checklistItem: IChecklistItem,
    checklistId: string
  ) => void;
  updateIsChecked: ({
    id,
    isChecked,
    checklistId,
    endDate,
  }: {
    id: string;
    isChecked: boolean;
    checklistId: string;
    endDate: string | null;
  }) => void;
  updateChecklistItem: ({
    id,
    newDescription,
    checklistId,
  }: {
    id: string;
    newDescription: string;
    checklistId: string;
  }) => void;
  removeChecklistItem: (id: string, checklistId: string) => void;
}

const useSignalRChecklist = ({
  addChecklist,
  addChecklistItem,
  removeChecklist,
  setChecklists,
  updateChecklist,
  updateChecklistItem,
  updateIsChecked,
  removeChecklistItem,
}: IuseSignalRChecklistProps) => {
  //Eventos signalR

  SignalRContext.useSignalREffect(
    "NewTicketChecklistCreated", // Your Event Key
    (userId: string, data: IChecklist) => {
      console.log("NewTicketChecklistCreated", data);
      addChecklist(data);
    },
    [addChecklist]
  );

  //Mudar ordem dos checklists
  SignalRContext.useSignalREffect(
    "TicketChecklistOrderUpdated", // Your Event Key
    (userId: string, data: IChecklist[]) => {
      console.log("TicketChecklistOrderUpdated", data);
      setChecklists(data);
    },
    [setChecklists]
  );

  //Atualizar checklist
  SignalRContext.useSignalREffect(
    "TicketChecklistUpdated", // Your Event Key
    (userId: string, { id, name }: IChecklist) => {
      updateChecklist({ id, newName: name });
    },
    [updateChecklist]
  );

  //Deletar checklist
  SignalRContext.useSignalREffect(
    "DeleteTicketChecklist", // Your Event Key
    (userId: string, { id }: IChecklist) => {
      console.log("DeleteTicketChecklist", id);
      removeChecklist(id);
    },
    [removeChecklist]
  );

  //Mudar ordem dos itens do checklist
  SignalRContext.useSignalREffect(
    "TicketChecklistItemOrderUpdated",
    (userId: string, data: IChecklistItem[]) => {
      console.log("TicketChecklistItemOrderUpdated", data);
      setChecklists((prevData) => {
        return prevData.map((item) => {
          if (item.id === data[0].ticketChecklistId) {
            return {
              ...item,
              ticketChecklistItems: data,
            };
          }

          return item;
        });
      });
    },
    [setChecklists]
  );

  //Novo item do checklist criado
  SignalRContext.useSignalREffect(
    "NewTicketChecklistItemCreated", // Your Event Key
    (userId: string, data: IChecklistItem) => {
      console.log("NewTicketChecklistItemCreated:", data);
      addChecklistItem(data, data.ticketChecklistId);
    },
    [addChecklistItem]
  );

  //Atualizar checked do item do checlist
  SignalRContext.useSignalREffect(
    "TicketChecklistItemIsChecked", // Your Event Key
    (userId: string, data: IChecklistItem) => {
      updateIsChecked({
        id: data.id,
        isChecked: data.isChecked,
        checklistId: data.ticketChecklistId,
        endDate: data.endDate,
      });
    },
    [updateIsChecked]
  );

  //Atualizar item do checklist
  SignalRContext.useSignalREffect(
    "TicketChecklistItemUpdated", // Your Event Key
    (userId: string, data: IChecklistItem) => {
      console.log("TicketChecklistItemUpdated", data);
      updateChecklistItem({
        checklistId: data.ticketChecklistId,
        id: data.id,
        newDescription: data.description,
      });
    },
    [updateChecklistItem]
  );

  //Apagar item do checklist

  SignalRContext.useSignalREffect(
    "DeleteTicketChecklistItem", // Your Event Key
    (userId: string, data: IChecklistItem) => {
      console.log("DeleteTicketChecklistItem", data);
      removeChecklistItem(data.id, data.ticketChecklistId);
    },
    [removeChecklistItem]
  );
  //
};

export default useSignalRChecklist;
