import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Grid, Stack, TextField } from "@mui/material";
import { Button, useDebounce, useFetch, useRole } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";

import { getArchivedColumnsByBoardId } from "../../../../../services/column.serivice";
import useBoard from "../../../../../hooks/useBoard";
import ArchivedColumn from "./ArchivedColumn";
import { IGetTicket } from "../../../../../models/ticket";
import { getArchivedTicket } from "../../../../../services/ticket.service";
import ArchivedTicket from "./ArchivedTicket";
import useBoardRole from "../../../../../hooks/useBoardRole";
import useSignalRArchivedItems from "./useSignalRArchivedItems";

type TMode = "columns" | "tasks";

const ArchivedItems = () => {
  const {
    arquivedColumns,
    setArquivedColumns,
    board: { id },
  } = useBoard();

  const [archivedTasks, setArchivedTasks] = useState<IGetTicket[]>([]);

  const [mode, setMode] = useState<TMode>("columns");

  const [searchBy, setSearchBy] = useState<string>();

  const debouncedSearch = useDebounce(searchBy, 500);
  
  const updatePermission = useRole(["Update"]);
  
  const uPermission = useBoardRole("2");
  
  const deletePermission = useRole(["Delete"]);
  
  const dPermission = useBoardRole("1");
  
  const { t } = useTranslation();

  const isColumn = useMemo(() => mode === "columns", [mode]);
  
  const { sendRequest } = useFetch(getArchivedColumnsByBoardId);
  
  const { sendRequest: getTickets } = useFetch(getArchivedTicket);

  useSignalRArchivedItems({ setArchivedTasks, setArquivedColumns });

  const fetchColumns = useCallback(async () => {
    const { success, data } = await sendRequest(id);
    if (success && data) {
      setArquivedColumns(data);
    }
  }, [id, sendRequest, setArquivedColumns]);

  const fetchTasks = useCallback(async () => {
    const { success, data } = await getTickets({
      TicketChannelId: id,
      SearchBy: debouncedSearch,
    });

    if (data && success) {
      setArchivedTasks(data.items);
    }
  }, [id, getTickets, debouncedSearch]);


  useEffect(() => {
    if (isColumn) {
      fetchColumns();
    } else fetchTasks();
  }, [fetchColumns, fetchTasks, isColumn]);

  const switchMode = useCallback(() => {
    setMode((m) => {
      if (m === "columns") {
        return "tasks";
      }
      return "columns";
    });
  }, []);

  return (
    <Stack height={"100%"}>
      <Stack gap={1} flexDirection={"row"} mb={1}>
        <TextField
          placeholder={t("page.board.settings.archived.search") + "..."}
          style={{ width: "60%", height: 30, fontSize: 8 }}
          size="small"
          onChange={(e) => setSearchBy(e.target.value)}
        />
        <Button style={{ width: "40%" }} onClick={switchMode}>
          {t(
            isColumn
              ? "page.board.settings.archived.changeToCards"
              : "page.board.settings.archived.changeToColumns"
          )}
        </Button>
      </Stack>

      <Grid
        container
        flexDirection={"column"}
        gap={1}
        wrap="nowrap"
        overflow={"auto"}
        height={"calc(100% - 37px)"}
      >
        {isColumn &&
          arquivedColumns.map((c) => (
            <Grid item key={c.id}>
              <ArchivedColumn
                {...c}
                canUnarchive={uPermission && updatePermission}
                canDelete={dPermission && deletePermission}
              />
            </Grid>
          ))}
        {!isColumn &&
          archivedTasks.map((t) => (
            <Grid item key={t.id}>
              <ArchivedTicket
                {...t}
                canUnarchive={uPermission && updatePermission}
                canDelete={dPermission && deletePermission}
              />
            </Grid>
          ))}
      </Grid>
    </Stack>
  );
};

export default memo(ArchivedItems);
