import { IconButton, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { memo } from "react";

const translationPath = "components.ticketTagsList.";

interface IAddTagButtonProps {
  onClick: (event: React.MouseEvent<Element, MouseEvent>) => void;
  disabled?: boolean;
}

const AddTagButton: React.FC<IAddTagButtonProps> = ({ onClick, disabled }) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      title={t(translationPath + "add_tag")}
      sx={(t) => ({
        backgroundColor:
          t.palette.mode === "light"
            ? t.palette.grey[300]
            : t.palette.grey[700],
        borderRadius: 1,
      })}
    >
      <IconButton size="small" disabled={disabled} onClick={onClick}>
        <AddIcon />
      </IconButton>
    </Tooltip>
  );
};

export default memo(AddTagButton);
