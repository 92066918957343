import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useMemo,
  useState,
  memo,
} from "react";

import { IFilter, IGetBoard, TMode } from "../../models/Board";

import useFilter from "../../hooks/useFilter";
import useSignalGroup from "../../hooks/useSignalGroup";

export interface IArchivedColumn {
  id: string;
  name: string;
}

type IBoardContext = {
  mode: TMode;
  settingsMenu?: boolean;
  board: IGetBoard;
  cardsLength: number;
  filter: IFilter;
  arquivedColumns: IArchivedColumn[];
  SetSettingsMenu: Dispatch<SetStateAction<boolean>>;
  changeMode: (m: TMode) => void;
  archiveColumn: (column: IArchivedColumn) => void;
  setArquivedColumns: Dispatch<SetStateAction<IArchivedColumn[]>>;
  clearAllFilters: () => void;
  setFilter: (props: IFilter) => void;
  setCardsLength: Dispatch<SetStateAction<number>>;
};

export const BoardContext = createContext<IBoardContext | undefined>(undefined);

interface IBoardContextProvider {
  children: any;
  board: IGetBoard;
}

const BoardProvider: React.FC<IBoardContextProvider> = ({
  children,
  board,
}) => {
  const [mode, setMode] = useState<TMode>("board");

  const { filter, clearAllFilters, setFilter } = useFilter();

  const [settingsMenu, SetSettingsMenu] = useState(false);

  const [cardsLength, setCardsLength] = useState(0);

  const [arquivedColumns, setArquivedColumns] = useState<IArchivedColumn[]>([]);

  const groupId = useMemo(() => `TicketChannel|${board.id}`, [board.id]);

  useSignalGroup(groupId);

  const archiveColumn = useCallback((column: IArchivedColumn) => {
    setArquivedColumns((old) => [...old, column]);
  }, []);

  const value = useMemo(
    () => ({
      mode,
      settingsMenu,
      board,
      arquivedColumns,
      filter,
      cardsLength,
      changeMode: setMode,
      SetSettingsMenu,
      setArquivedColumns,
      archiveColumn,
      clearAllFilters,
      setFilter,
      setCardsLength,
    }),
    [
      mode,
      settingsMenu,
      board,
      arquivedColumns,
      cardsLength,
      filter,
      setMode,
      SetSettingsMenu,
      setArquivedColumns,
      archiveColumn,
      clearAllFilters,
      setFilter,
      setCardsLength,
    ]
  );

  return (
    <BoardContext.Provider value={value}>{children}</BoardContext.Provider>
  );
};

export default memo(BoardProvider);
