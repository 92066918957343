import {
  Dispatch,
  SetStateAction,
  createContext,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { IEvents, IPaginationModel, TEventsMode } from "../../../models/events";
import { pageState, useDebounce, useFetch } from "@4uhub/lib4uhub";
import { getAllEvents } from "../../../services/events.service";
import useTicket from "../../../hooks/useTask";
import { SignalRContext } from "../../../components/SignalR/SignalRProvider";

type TEventsContext = {
  mode: TEventsMode;
  rows: IEvents[];
  search: string;
  loading: boolean;
  rowCountState: number;
  paginationModel: IPaginationModel;
  currentPage: number;
  id: string;
  onChangeMode: (mode: TEventsMode) => void;
  onChangeSearch: (text: string) => void;
  onAddEvent: (event: IEvents) => void;
  onEditEvent: (id: string) => void;
  setPaginationModel: Dispatch<SetStateAction<IPaginationModel>>;
};

export const EventsContext = createContext<TEventsContext>({
  mode: "list",
  rows: [],
  search: "",
  loading: false,
  rowCountState: 0,
  paginationModel: {
    page: 0,
    pageSize: 20,
  },
  currentPage: 0,
  id: "",
  onChangeMode: () => {},
  onChangeSearch: () => {},
  onAddEvent: () => {},
  setPaginationModel: () => {},
  onEditEvent: () => {},
});

interface IEventsProvider {
  children: JSX.Element;
}

const EventsProvider: React.FC<IEventsProvider> = ({ children }) => {
  const [mode, setMode] = useState<TEventsMode>("list");

  const [rows, setRows] = useState<IEvents[]>([]);

  const [text, setText] = useState("");

  const [id, setId] = useState("");

  const search = useDebounce(text, 500);

  const onChangeSearch = useCallback((text: string) => {
    setText(text);
  }, []);

  const onChangeMode = useCallback((mode: TEventsMode) => {
    if (mode === "create") setId("");
    setMode(mode);
  }, []);

  const onEditEvent = useCallback(
    (id: string) => {
      setId(id);
      onChangeMode("update");
    },
    [onChangeMode]
  );

  const { sendRequest, loading } = useFetch(getAllEvents);

  const { ticket } = useTicket();

  const [pageInfo, setPageInfo] = useState<pageState>();

  const [currentPage, setCurrentPage] = useState<number>(0);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });

  const [rowCountState, setRowCountState] = useState<number>(
    pageInfo?.totalRowCount || 0
  );

  const onAddEvent = useCallback((event: IEvents) => {
    setRows((prev) => [event, ...prev]);
  }, []);

  const fetchEvents = useCallback(async () => {
    const { data, success } = await sendRequest({
      TicketId: ticket.id,
      SearchBy: search,
      PageSize: paginationModel.pageSize,
      PageNumber: paginationModel.page + 1,
    });
    if (data && success) {
      setRows(data.items);
      setRowCountState(data.totalCount);
      setPageInfo({
        totalRowCount: data.totalCount,
      });
      setCurrentPage(data.pageNumber);
    }
  }, [sendRequest, ticket.id, paginationModel, search]);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  SignalRContext.useSignalREffect(
    "UpdateTicketEventMovement",
    (data: any) => {
      if (currentPage === 1) {
        fetchEvents();
      }
    },
    [fetchEvents, currentPage]
  );

  SignalRContext.useSignalREffect(
    "NewTicketEventMovement",
    (data: any) => {
      if (currentPage === 1) {
        fetchEvents();
      }
    },
    [fetchEvents, currentPage]
  );

  SignalRContext.useSignalREffect(
    "FinishTicketEventMovement",
    (data: any) => {
      if (currentPage === 1) {
        fetchEvents();
      }
    },
    [fetchEvents, currentPage]
  );

  const value = useMemo(
    () => ({
      mode,
      rows,
      search,
      loading,
      rowCountState,
      paginationModel,
      currentPage,
      id,
      onChangeMode,
      onChangeSearch,
      onAddEvent,
      setPaginationModel,
      onEditEvent,
    }),
    [
      mode,
      rows,
      search,
      loading,
      rowCountState,
      paginationModel,
      currentPage,
      id,
      onChangeMode,
      onChangeSearch,
      onAddEvent,
      onEditEvent,
    ]
  );

  return (
    <EventsContext.Provider value={value}>{children}</EventsContext.Provider>
  );
};

export default memo(EventsProvider);
