import { useFetch } from "@4uhub/lib4uhub";
import { FocusEventHandler, memo, useCallback } from "react";

import TicketChecklistItemService from "../../../../services/ticketChecklistItem.service";
import { IChecklistItem } from "../../../../models/ticketChecklist";
import EditableTypography from "../../../UI/EditableTypography";
import useChecklists from "../../../../hooks/useChecklists";

const checklistItemService = new TicketChecklistItemService();

interface IChecklistItemNameProps {
  checklistItem: IChecklistItem;
  checklistId: string;
  onFocus: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur: () => void;
}

const ChecklistItemName: React.FC<IChecklistItemNameProps> = ({
  checklistItem,
  checklistId,
  onBlur,
  onFocus,
}) => {
  const { sendRequest: updateDescription } = useFetch(
    checklistItemService.updateChecklistItem
  );
  const { updateChecklistItem } = useChecklists();

  const handleUpdateChecklistItem = useCallback(
    async (description: string) => {
      updateDescription({
        id: checklistItem.id,
        item: {
          ...checklistItem,
          description,
        },
      });
      updateChecklistItem({
        id: checklistItem.id,
        checklistId: checklistId,
        newDescription: description,
      });
    },
    [updateDescription, updateChecklistItem, checklistId, checklistItem]
  );

  return (
    <EditableTypography
      typographyProps={{
        variant: "subtitle2",
        sx: (t) => ({
          width: "95%",
          textDecoration: checklistItem.isChecked ? "line-through" : "none",
          writingMode: "horizontal-tb",
          textOrientation: "inherit",
          fontWeight: "normal",
          opacity: checklistItem.isChecked ? 0.7 : 1,
          p: t.spacing(1),
          textOverflow: "ellipsis",
          wordBreak: "break-all",
        }),
        color: (t) => t.palette.text.primary,
      }}
      textfieldProps={{
        onFocus,
        multiline: true,
        onBlur,
        sx: {
          "& fieldset": { border: "none" },
        },
      }}
      onEdit={handleUpdateChecklistItem}
      value={checklistItem.description}
    />
  );
};

export default memo(ChecklistItemName);
