import { ISelectType, useFetch } from "@4uhub/lib4uhub";
import {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { search } from "../../services/advancedSearch.service";
import { EFilterBy, IAdvancedSearchResult } from "../../models/advanced-search";
import { differenceInDays } from "date-fns";

type TAdvancedSearchContext = {
  protocolInternalId: string;
  title: string;
  individualRequesterName: string;
  individualCustomerName: string;
  searchResult: IAdvancedSearchResult[] | null;
  loading: boolean;
  workspace: ISelectType | null;
  createdBy: ISelectType[] | null;
  startDate: Date | null;
  endDate: Date | null;
  isRangeBigger: boolean;
  hasNextPage: boolean;
  pageNumber: number;
  filterBy: EFilterBy;
  ticketChannel: ISelectType | null;
  status: ISelectType | null;
  onChangeProtolInternalId: (text: string) => void;
  onChangeTitle: (text: string) => void;
  onChangeIndividualRequesterName: (text: string) => void;
  onChangeIndividualCustomerName: (text: string) => void;
  onCleanFiltersHandler: () => void;
  onFilterHandler: (pgNumber?: number) => void;
  onInitialSearchHandler: () => void;
  onChangeWorkspace: (value: ISelectType | null) => void;
  onChangeStartDate: (value: Date | null) => void;
  onChangeEndDate: (value: Date | null) => void;
  onNextPage: () => void;
  onChangePageNumber: (pgNumber: number) => void;
  onChangeCreatedBy: (value: ISelectType[]) => void;
  onFilterByHandler: (value: EFilterBy) => void;
  onChangeTicketChannel: (value: ISelectType | null) => void;
  onChangeStatus: (value: ISelectType | null) => void;
};

export const AdvancedSearchContext = createContext<TAdvancedSearchContext>({
  protocolInternalId: "",
  title: "",
  individualRequesterName: "",
  individualCustomerName: "",
  searchResult: null,
  loading: false,
  workspace: null,
  startDate: null,
  endDate: null,
  isRangeBigger: false,
  hasNextPage: false,
  pageNumber: 1,
  createdBy: null,
  filterBy: EFilterBy.CREATE,
  ticketChannel: null,
  status: null,
  onChangeProtolInternalId: () => {},
  onChangeTitle: () => {},
  onChangeIndividualRequesterName: () => {},
  onChangeIndividualCustomerName: () => {},
  onCleanFiltersHandler: () => {},
  onFilterHandler: () => {},
  onInitialSearchHandler: () => {},
  onChangeWorkspace: () => {},
  onChangeStartDate: () => {},
  onChangeEndDate: () => {},
  onNextPage: () => {},
  onChangePageNumber: () => {},
  onChangeCreatedBy: () => {},
  onFilterByHandler: () => {},
  onChangeTicketChannel: () => {},
  onChangeStatus: () => {},
});

interface IAdvancedSearchContextProvider {
  children: any;
}

const AdvancedSearchProvider: React.FC<IAdvancedSearchContextProvider> = ({
  children,
}) => {
  const { protocolOrId, workspaceId } = useParams();

  const [isRangeBigger, setIsRangeBigger] = useState(false);

  const [hasNextPage, setHasNextPage] = useState(false);

  const [pageNumber, setPageNumber] = useState(1);

  const [filterBy, setFilterBy] = useState<EFilterBy>(EFilterBy.CREATE);

  const [ticketChannel, setTicketChannel] = useState<ISelectType | null>(null);

  const [status, setStatus] = useState<ISelectType | null>(null);

  const [searchResult, setSearchResult] = useState<
    IAdvancedSearchResult[] | null
  >(null);

  const [protocolInternalId, setProtocolInternalId] = useState<string>("");

  const [title, setTitle] = useState<string>("");

  const [individualRequesterName, setIndividualRequesterName] =
    useState<string>("");

  const [individualCustomerName, setIndividualCustomerName] =
    useState<string>("");

  const [workspace, setWorkspace] = useState<ISelectType | null>(null);

  const [createdBy, setCreatedBy] = useState<ISelectType[] | null>(null);

  const [startDate, setStartDate] = useState<Date | null>(
    new Date(new Date().setDate(new Date().getDate() - 180))
  );

  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const { sendRequest, loading } = useFetch(search);

  const onFilterByHandler = useCallback((value: EFilterBy) => {
    setFilterBy(value);
  }, []);

  const onChangeTicketChannel = useCallback((value: ISelectType | null) => {
    setTicketChannel(value);
  }, []);

  const onChangeStatus = useCallback((value: ISelectType | null) => {
    setStatus(value);
  }, []);

  const onFilterHandler = useCallback(
    async (pgNumber?: number) => {
      const { data, success } = await sendRequest({
        TicketWorkspaceId: workspace ? workspace.id : undefined,
        DateFilterType: filterBy,
        TicketChannelId: ticketChannel ? ticketChannel.id : undefined,
        StatusId: status ? status.id : undefined,
        StartDate: startDate
          ? startDate.toISOString().substring(0, 10)
          : undefined,
        FinishDate: endDate
          ? endDate.toISOString().substring(0, 10)
          : undefined,
        Title: title.length !== 0 ? title : undefined,
        IndividualRequesterName:
          individualRequesterName.length !== 0
            ? individualRequesterName
            : undefined,
        IndividualCustomerName:
          individualCustomerName.length !== 0
            ? individualCustomerName
            : undefined,
        SearchBy:
          protocolInternalId.length !== 0 ? protocolInternalId : undefined,
        PageSize: 50,
        PageNumber: pgNumber,
        CreatedBy: createdBy?.map((item) => item.id),
      });
      if (data && success) {
        setSearchResult((oldState) =>
          oldState && pgNumber !== 1 ? [...oldState, ...data.items] : data.items
        );
        setHasNextPage(data.hasNextPage);
      }
    },
    [
      sendRequest,
      startDate,
      endDate,
      title,
      individualRequesterName,
      individualCustomerName,
      protocolInternalId,
      workspace,
      createdBy,
      filterBy,
      ticketChannel,
      status,
    ]
  );

  const onNextPage = useCallback(() => {
    setPageNumber((oldState) => (oldState += 1));
    onFilterHandler(pageNumber + 1);
  }, [onFilterHandler, pageNumber]);

  const onInitialSearchHandler = useCallback(async () => {
    const { data, success } = await sendRequest({
      TicketWorkspaceId: workspaceId,
      SearchBy: protocolOrId,
      DateFilterType: filterBy,
      StartDate: startDate
        ? startDate.toISOString().substring(0, 10)
        : undefined,
      FinishDate: endDate ? endDate.toISOString().substring(0, 10) : undefined,
      PageSize: 50,
      PageNumber: 1,
    });
    if (data && success) {
      setSearchResult(data.items);
    }
  }, [sendRequest, protocolOrId, workspaceId, endDate, startDate, filterBy]);

  const onChangeEndDate = useCallback((value: Date | null) => {
    if (value) {
      setEndDate(value);
    } else {
      setEndDate(null);
    }
  }, []);

  const onChangeStartDate = useCallback((value: Date | null) => {
    if (value) {
      setStartDate(value);
    } else {
      setStartDate(null);
    }
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      const diffDates = differenceInDays(startDate, endDate);
      if (diffDates > 180) {
        setIsRangeBigger(true);
      } else {
        setIsRangeBigger(false);
      }
    } else {
      setIsRangeBigger(false);
    }
  }, [startDate, endDate]);

  const onCleanFiltersHandler = () => {
    setProtocolInternalId("");
    setTitle("");
    setIndividualRequesterName("");
    setIndividualCustomerName("");
    setSearchResult(null);
    setWorkspace(null);
    setStartDate(null);
    setEndDate(null);
    setCreatedBy([]);
  };

  useEffect(() => {
    if (protocolOrId) {
      setProtocolInternalId(protocolOrId);
    }
  }, [protocolOrId]);

  const value = useMemo(
    () => ({
      protocolInternalId,
      title,
      individualRequesterName,
      individualCustomerName,
      searchResult,
      loading,
      workspace,
      startDate,
      endDate,
      isRangeBigger,
      hasNextPage,
      pageNumber,
      createdBy,
      filterBy,
      ticketChannel,
      status,
      onChangeProtolInternalId: setProtocolInternalId,
      onChangeTitle: setTitle,
      onChangeIndividualRequesterName: setIndividualRequesterName,
      onChangeIndividualCustomerName: setIndividualCustomerName,
      onCleanFiltersHandler,
      onFilterHandler,
      onInitialSearchHandler,
      onChangeWorkspace: setWorkspace,
      onChangeEndDate,
      onChangeStartDate,
      onChangePageNumber: setPageNumber,
      onNextPage,
      onChangeCreatedBy: setCreatedBy,
      onFilterByHandler,
      onChangeTicketChannel,
      onChangeStatus,
    }),
    [
      protocolInternalId,
      title,
      individualRequesterName,
      individualCustomerName,
      searchResult,
      loading,
      workspace,
      createdBy,
      startDate,
      endDate,
      isRangeBigger,
      hasNextPage,
      pageNumber,
      filterBy,
      ticketChannel,
      status,
      onFilterHandler,
      onInitialSearchHandler,
      onChangeStartDate,
      onChangeEndDate,
      onNextPage,
      onFilterByHandler,
      onChangeTicketChannel,
      onChangeStatus,
    ]
  );

  return (
    <AdvancedSearchContext.Provider value={value}>
      {children}
    </AdvancedSearchContext.Provider>
  );
};

export const useAdvancedSearchContext = () => {
  return useContext(AdvancedSearchContext);
};

export default memo(AdvancedSearchProvider);
