import {
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useState,
  memo,
  useMemo,
} from "react";

type IQuickAnswerContext = {
  back: () => void;
  canGoBack: boolean;
  page?: IMenuPage;
  setPage: (page: string) => void;
};

export interface IMenuPage {
  title: string;
  name: string;
  content: JSX.Element;
}

export const SettingsMenuContext = createContext<IQuickAnswerContext>({
  back: () => {},
  canGoBack: false,
  setPage: () => {},
});

interface IQuickAnswerProvider {
  children: any;
  pages: IMenuPage[];
  startPage?: string;
}

const SettingsMenuProvider: React.FC<IQuickAnswerProvider> = ({
  children,
  pages,
  startPage,
}) => {
  const [page, setContent] = useState<IMenuPage>();

  const [contentHistory, setContentHistory] = useState<IMenuPage[]>([]);

  useLayoutEffect(() => {
    if (startPage) {
      setContent(pages.find((p) => p.name === startPage));
    }
  }, [startPage, pages]);

  const HandleContent = useCallback(
    (p: string) => {
      const page = pages.find((page) => page.name === p);

      setContent((lastmode) => {
        setContentHistory((m) => {
          if (lastmode) {
            m.push(lastmode);
          }
          return m;
        });

        return page;
      });
    },
    [setContentHistory, setContent, pages]
  );

  const canGoBack = useMemo(() => !!(contentHistory.length > 0),[contentHistory]);

  const back = useCallback(() => {
    if (canGoBack) {
      setContentHistory((m) => {
        const mode = m.pop();
        if (mode) {
          setContent(mode);
        }

        return m;
      });
    }
  }, [canGoBack, setContent, setContentHistory]);

  const value = useMemo(
    () => ({ setPage: HandleContent, back, canGoBack, page }),
    [HandleContent, back, canGoBack, page]
  );

  return (
    <SettingsMenuContext.Provider value={value}>
      {children}
    </SettingsMenuContext.Provider>
  );
};

export const useSettingsMenu = () => {
  return useContext(SettingsMenuContext);
};

export default memo(SettingsMenuProvider);
