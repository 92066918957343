import { Grid, Tooltip, Typography } from "@mui/material";
import { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import { ILocation } from "../../../../models/ticketDialogLocation";
import { Button } from "@4uhub/lib4uhub";
import LocationOnIcon from '@mui/icons-material/LocationOn';

const translationPath = "components.sharedLocation.";

interface LocationProps {
  location: ILocation;
}

const SharedLocation: FC<LocationProps> = ({ location }) => {
  const { t } = useTranslation();

  const clickHandler = () => {
    window.open(`https://www.google.com/maps?q=${location.Latitude},${location.Longitude}`, "_blank");
  };

  return (
    <Tooltip title={t(translationPath + "title")}>
        <Grid item xs={"auto"}>
          <Typography fontSize={10}>{t(translationPath + "title")}</Typography>
          <Button
            sx={(t) => ({
              marginTop: '2px'
            })}
            variant="contained"
            color="inherit"
            startIcon={<LocationOnIcon />}
            onClick={clickHandler}
          >
              <Typography>{t(translationPath + "show")}</Typography>
          </Button>
        </Grid>
      </Tooltip>
  );
};

export default memo(SharedLocation);
