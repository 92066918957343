import { UseFormReturn } from "react-hook-form";
import { Button, Stack } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "@4uhub/lib4uhub";
import { useParams } from "react-router-dom";

import { TTagForm } from "./TagSchema";
import TagsService from "../../../../services/tags.service";
import TagDeleteButton from "./TagDeleteButton";

const tagsService = new TagsService();

const translationPath = "components.tagFormActions.";

interface ITagFormActionsProps {
  id: string | null;
  onCloseForm: () => void;
  methods: UseFormReturn<TTagForm, any, undefined>;
}

const TagFormActions = ({ onCloseForm, methods, id }: ITagFormActionsProps) => {
  const { t } = useTranslation();

  const { boardId } = useParams();

  const { sendRequest: createTag } = useFetch(tagsService.create);

  const { sendRequest: updateTagById } = useFetch(tagsService.updateItemById);

  const submitHandler = useCallback(
    async (values: TTagForm) => {
      if (boardId) {
        if (id) {
          await updateTagById({
            item: {
              ...values,
              id: id,
              ticketChannelId: boardId,
              displayOrder: +values.displayOrder,
            },
            id,
          });
          return;
        }
        const { data, success } = await createTag({
          ...values,
          ticketChannelId: boardId,
          displayOrder: +values.displayOrder,
        });
        if (data && success) {
          onCloseForm();
        }
      }
    },
    [
      createTag,
      updateTagById,
      onCloseForm,
      id,
      boardId,
    ]
  );

  return (
    <Stack mt={1} flexDirection={"row"} gap={1}>
      {id && <TagDeleteButton id={id} onCloseForm={onCloseForm} />}
      <Button
        fullWidth
        variant="contained"
        onClick={methods.handleSubmit(submitHandler)}
      >
        {id
          ? `${t(translationPath + "edit")}`
          : `${t(translationPath + "create")}`}
      </Button>
    </Stack>
  );
};

export default TagFormActions;
