import { GlobalAxios } from "../axios/axios-config";

const FILE_MANAGER_ROUTE = process.env.REACT_APP_FILEMANAGER + "/api/v1/";

export const getFileUrl = (filePath: string) => {
  return GlobalAxios.post<string>(
    FILE_MANAGER_ROUTE + "Signed",
    { filePath },
    {
      headers: {
        "Content-Type": "multipart/form-data",
        accept: "text/plain",
      },
    }
  );
};

interface IUploadFileProps {
  type: string;
  ParentName: string;
  MediaTypecode: string;
  MediaTypeIdentifier: string;
  File: File
}

export const uploadFile = (props: IUploadFileProps) => {
  return GlobalAxios.post<{id: string}>(
    FILE_MANAGER_ROUTE + "Upload",
    props,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};