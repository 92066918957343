import { Popover, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const translationPath = "components.finishActionPopover.";

interface IFinishActionPopover {
  open: boolean;
  anchorEl: Element | null;
  onClose: () => void;
}

const FinishActionPopover = ({
  open,
  anchorEl,
  onClose,
}: IFinishActionPopover) => {
  const { t } = useTranslation();

  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={(t) => ({ marginTop: t.spacing(1) })}
    >
      <Stack p={1} width={250}>
        <Typography variant="body2">
          {t(translationPath + "no_list")}
        </Typography>
      </Stack>
    </Popover>
  );
};

export default FinishActionPopover;
