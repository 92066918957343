import { Stack } from "@mui/material";
import { Button, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { memo } from "react";
import StartIcon from "@mui/icons-material/Start";


interface IMinimizeButtonProps {
  open: boolean;
  toggleOpen: () => void;
}
const MinimizeButton: React.FC<IMinimizeButtonProps> = ({
  open,
  toggleOpen,
}) => {

  const { t } = useTranslation();
  
  return (
    <Stack
      mt={2}
      alignItems={"center"}
      sx={(t) => ({
        borderTop: `1px solid ${t.palette.divider}`,
      })}
      padding={2}
      direction={"row"}
      justifyContent={"center"}
    >
      {!open && (
        <IconButton onClick={toggleOpen} color="primary" size="small">
          <StartIcon
            fontSize="inherit"
            sx={{
              transform: "rotate(0deg)",
            }}
          />
        </IconButton>
      )}
      {open && (
        <Button
          onClick={toggleOpen}
          fullWidth
          startIcon={
            <StartIcon
              sx={{
                transform: "rotate(180deg)",
              }}
            />
          }
        >
          {t("components.menuBoards.minimize")}
        </Button>
      )}
    </Stack>
  );
};

export default memo(MinimizeButton);
