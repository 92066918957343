import { SearchInput, useFetch } from "@4uhub/lib4uhub";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import ProtocolsDefaultList from "./ProtocolsDefaultList";
import Detail from "./Detail/Detail";
import { useTranslation } from "react-i18next";
import { IProtocolsDefault } from "../../../../models/protocol-default";
import { ISearchParams } from "@4uhub/lib4uhub/dist/types/models";
import { getProtocolsDefault } from "../../../../services/protocolDefault.service";
import { IProtocolDefaultContentProps } from "./models";
import { useProtocol } from "../../../../store/context/protocol-context";
import useWorkspace from "../../../../hooks/useWorkspace";

const translationPath = "components.protocolDefaultModal.";

const ProtocolDefaultContent = ({ onClose }: IProtocolDefaultContentProps) => {
  const { t } = useTranslation();

  const { actualWorkspace } = useWorkspace();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const [protocols, setProtocols] = useState<IProtocolsDefault[]>([]);

  const [search, setSearch] = useState<string>("");

  const [hasNextPage, setHasNextPage] = useState(false);

  const [searchParams, setSearchParams] = useState<ISearchParams>({
    PageSize: 20,
    PageNumber: 1,
  });

  const { sendRequest, loading } = useFetch(getProtocolsDefault);

  const fetchProtocols = useCallback(async () => {
    if (!actualWorkspace) return;
    const { data, success } = await sendRequest({
      ...searchParams,
      TicketWorkspaceId: actualWorkspace.id,
      SearchBy: search,
    });
    if (data && success) {
      setProtocols((oldData) => [...oldData, ...data.items]);
      setHasNextPage(data.hasNextPage);
    }
  }, [sendRequest, searchParams, search, actualWorkspace]);

  useEffect(() => {
    fetchProtocols();
  }, [fetchProtocols]);

  const searchHandler = useCallback((text: string) => {
    setProtocols([]);
    setSearch(text);
  }, []);

  const viewMoreHandler = () => {
    setSearchParams((prev) => ({
      ...prev,
      PageNumber: prev.PageNumber ? prev.PageNumber + 1 : 1,
    }));
  };

  const { selectedProtocol } = useProtocol();

  return (
    <Box width={"100%"} display={matches ? undefined : "flex"}>
      <Box
        sx={(t) => ({
          width: matches ? "100%" : "60%",
          display: matches && selectedProtocol ? "none" : undefined,
          height: "70vh",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "0.4em",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            backgroundColor:
              t.palette.mode === "light"
                ? t.palette.grey[400]
                : t.palette.grey[900],
            borderRadius: 5,
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor:
              t.palette.mode === "light"
                ? t.palette.grey[200]
                : t.palette.grey[700],
            borderRadius: 5,
          },
        })}
      >
        <Box my={1} px={1} width={"100%"}>
          <SearchInput
            placeholder={t(translationPath + "search")}
            onSearch={searchHandler}
          />
        </Box>
        <ProtocolsDefaultList
          protocols={protocols}
          loading={loading}
          hasNextPage={hasNextPage}
          onViewMore={viewMoreHandler}
        />
      </Box>
      <Box
        sx={{
          width: matches ? "100%" : "40%",
          display: matches && !selectedProtocol ? "none" : undefined,
        }}
      >
        <Detail onClose={onClose} />
      </Box>
    </Box>
  );
};

export default memo(ProtocolDefaultContent);
