interface TokenToStorage {
  code: number;
  token: string;
  establishmentId?: string;
}

const ACCESS_TOKENS_ID = "access_tokens";
const REFRESH_TOKENS_ID = "refresh_tokens";

export const getAccessTokens = (): TokenToStorage[] => {
  const tokensJson = localStorage.getItem(ACCESS_TOKENS_ID);
  let tokens: TokenToStorage[] = [];
  if (tokensJson) {
    tokens = JSON.parse(tokensJson) as TokenToStorage[];
  }
  return tokens;
};

const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKENS_ID);
};

const saveAccessToken = (
  accessToken: string,
  establishmentcode: number,
  establishmentId?: string
) => {
  const findIndex = getAccessTokens().findIndex(
    (t) => t.code === establishmentcode
  );

  if (findIndex !== -1) {
    const newTokens = getAccessTokens().map((t) => {
      if (t.code === establishmentcode) {
        t.token = accessToken;
      }
      return t;
    });
    localStorage.setItem(ACCESS_TOKENS_ID, JSON.stringify(newTokens));

    return;
  }

  const tokens: TokenToStorage[] = [
    ...getAccessTokens(),
    {
      code: establishmentcode,
      token: accessToken,
      establishmentId: establishmentId,
    },
  ];

  localStorage.setItem(ACCESS_TOKENS_ID, JSON.stringify(tokens));
};

const saveRefreshToken = (resfreshToken: string) => {
  localStorage.setItem(REFRESH_TOKENS_ID, resfreshToken);
};

const removeRefreshToken = () => {
  localStorage.removeItem(REFRESH_TOKENS_ID);
};

const removeAccessToken = (code: number) => {
  const tokens: TokenToStorage[] = getAccessTokens().filter(
    (v) => v.code !== code
  );
  localStorage.setItem(ACCESS_TOKENS_ID, JSON.stringify(tokens));
};

export const getTokens = () => ({
  refresh_token: getRefreshToken(),
  access_tokens: getAccessTokens(),
});

export const saveTokens = (
  acessToken: string,
  refresh_token: string,
  establishmentIndex: number,
  establishmentId?: string
) => {
  saveAccessToken(acessToken, establishmentIndex, establishmentId);
  saveRefreshToken(refresh_token);
};

export const removeAllTokens = () => {
  localStorage.removeItem(REFRESH_TOKENS_ID);
  localStorage.removeItem(ACCESS_TOKENS_ID);
};

export const removeToken = (index: number) => {
  removeRefreshToken();
  removeAccessToken(index);
};

export const hasAccessTokens = () => getAccessTokens().length > 0;

export const hasRefreshTokens = () => !!getRefreshToken();

