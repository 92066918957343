import { Loading, useFetch } from "@4uhub/lib4uhub";
import { useCallback } from "react";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
import { useTranslation } from "react-i18next";

import { IQuickAnswer } from "../../../../models/QuickAnswers";
import { deleteAnswer } from "../../../../services/quickReplay.service";
import { useQuickAnswer } from "../../../../store/context/quickAnswers-context";
import QuickReplayDelete from "./QuickReplayDelete";
import useMessageInput from "../../../../hooks/useMessageInput";
import useTicket from "../../../../hooks/useTask";
import { useChat } from "../../../../store/context/chat-context";

interface QuickReplayProps extends IQuickAnswer {
  onClick: (a: IQuickAnswer) => void
  closeModal: () => void;
}

const QuickReplay: React.FC<QuickReplayProps> = (props) => {
  const { onClick, closeModal, shortCut, message, id } = props;

  const { t } = useTranslation();
  const { setAnswers } = useQuickAnswer();
  const { ticket: { status } } = useTicket();
  const { messages } = useChat();
  const { sendRequest, loading } = useFetch(deleteAnswer);
  const { setMessage, files } = useMessageInput();

  const hasRequesterMessages = !!messages.find(({ whoSendType }) => whoSendType?.code === "2");

  const handleClick = () => {
    onClick(props);
  };

  const onMessage = () => {
    setMessage(message);
    closeModal();
  };

  const onDelete = useCallback(async () => {
    const { success } = await sendRequest(id);

    if (success) {
      setAnswers((answers) => answers.filter((a) => a.id !== id));
    }
  }, [setAnswers, sendRequest, id]);

  const isDisabled = (message.length === 0 && files.length === 0) || status.code === "3" || !hasRequesterMessages;

  return (
    <Stack
      sx={(t) => ({
        padding: t.spacing(1),
      })}
    >
      <Stack
        flexDirection="row"
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
          <Typography>/{shortCut}</Typography>

          <Tooltip title={t("components.chat.quickReplay.edit")}>
            <IconButton size="small" onClick={handleClick}>
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>

          <Tooltip title={t("components.chat.quickReplay.send")}>
            <IconButton 
              size="small" 
              onClick={onMessage} 
              disabled={isDisabled} 
              >
                <SendIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>

        {loading && (
          <Box>
            <Loading
              size={20}
              sx={{ width: 20, height: 20, position: "relative" }}
            />
          </Box>
        )}

        {!loading && <QuickReplayDelete onDelete={onDelete} />}
      </Stack>

      <Typography
        color={(t) => t.palette.text.disabled}
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: "2",
          WebkitBoxOrient: "vertical",
        }}
      >
        {message}
      </Typography>
    </Stack>
  );
};

export default QuickReplay;
