import { useUser } from "@4uhub/lib4uhub";
import { useEffect, useState } from "react";

import { useMembersContext } from "../store/context/members-context";
import { IMember } from "../models/members";

type TBoardRoleCode = "1" | "2" | "3";

const useBoardRole = (type: TBoardRoleCode) => {
  const { user } = useUser();

  const [actualMember, setActualMember] = useState<IMember>();

  const { members } = useMembersContext();

  useEffect(() => {
    setActualMember(members.find((member) => member.user.id === user.id));
  }, [members, user.fullName, user.id]);

  const userRoleCode = actualMember?.ticketProfile.code;


  if (type === "1") {
    return userRoleCode === "1";
  }

  if (type === "2") {
    return userRoleCode === "1" || userRoleCode === "2";
  }

  if (type === "3") {
    return userRoleCode === "1" || userRoleCode === "2" || userRoleCode === "3";
  }

  return false;
};

export default useBoardRole;
