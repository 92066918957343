import { Typography } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const CardContentLabel = () => {
  const { t } = useTranslation();

  return (
    <Typography
      variant="h3"
      color={(t) => t.palette.text.primary}
      fontSize={16}
      fontWeight={"bold"}
    >
      {t("page.card.description")}
    </Typography>
  );
};

export default memo(CardContentLabel);
