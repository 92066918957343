import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const NoCardSelected = () => {
  const { t } = useTranslation();
  return (
    <Box
      height={"100%"}
      width={"100%"}
      alignItems={"center"}
      justifyContent={"center"}
      display={"flex"}
      flexDirection={"column"}
    >
      <img
        src="/toggle-button.png"
        alt="a finger toggling a switch"
        width={250}
      ></img>
      <Typography color={(t) => t.palette.text.primary} variant="h6">
        {t("page.card.flow.noTicketSelected")}
      </Typography>
    </Box>
  );
};

export default NoCardSelected;
