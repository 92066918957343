import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment, { isMoment } from "moment";
import { useEffect, useMemo, useState } from "react";
import { URL_FORMAT_DATE } from "../../../../../hooks/useFilter";
import { ptBR, enUS, esES } from "@mui/x-date-pickers/locales";
import { useTranslation } from "react-i18next";
import { useFormContext } from "@4uhub/lib4uhub";
import { TFilterSchema } from "../filterSchema";

interface IDateInputProps {
  label?: string | null;
  name: keyof TFilterSchema;
}

const getLocaleText = (language: string) => {
  switch (language) {
    case "pt-BR":
      return ptBR.components.MuiLocalizationProvider.defaultProps.localeText;
    case "en-US":
      return esES.components.MuiLocalizationProvider.defaultProps.localeText;
    case "es":
      return enUS.components.MuiLocalizationProvider.defaultProps.localeText;
  }
};

const DateInput: React.FC<IDateInputProps> = ({ label, name }) => {
  const [cleared, setCleared] = useState<boolean>(false);

  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<TFilterSchema>();

  const value = watch(name);

  const { i18n, t } = useTranslation();

  useEffect(() => {
    if (cleared) {
      const timeout = setTimeout(() => {
        setCleared(false);
      }, 1500);

      setValue(name, null, { shouldValidate: true, shouldDirty: true });

      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [cleared, setValue, name]);

  const localetext = useMemo(() => getLocaleText(i18n.language), [i18n]);

  const errorMessage = useMemo(() => errors[name]?.message, [errors, name]);

  return (
    <LocalizationProvider localeText={localetext}>
      <DatePicker
        label={label}
        onChange={(e) => {
          if (isMoment(e)) {
            setValue(name, e ? e.format(URL_FORMAT_DATE) : null, {
              shouldValidate: true,
              shouldDirty: true,
            });
          }
        }}
        format="DD/MM/YYYY"
        value={value ? moment.utc(value, URL_FORMAT_DATE) : null}
        slotProps={{
          textField: {
            size: "small",
            error: !!errorMessage,
            helperText: t(errorMessage || ""),
          },
          field: {
            clearable: true,
            onClear: () => setCleared(true),
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default DateInput;
