import {
  Dispatch,
  SetStateAction,
  createContext,
  createRef,
  useState,
  memo,
  useCallback,
  useMemo,
} from "react";
import { IQuickAnswer } from "../../models/QuickAnswers";
import { ISendMessageProps } from "./chat-context";
export interface IFile {
  file: File;
  id: string;
}

type IMessageContext = {
  message: string;
  inputRef: React.RefObject<HTMLInputElement>;
  cursorPosition: number;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  setCursorPosition: React.Dispatch<React.SetStateAction<number>>;
  onMessageSend: (msg: string) => void;
  answers: IQuickAnswer[];
  setAnswers: React.Dispatch<React.SetStateAction<IQuickAnswer[]>>;
  files: IFile[];
  filesDeleteIds: string[];
  deleteFileId: (id: string) => void;
  setFiles: Dispatch<SetStateAction<IFile[]>>;
  addFileId: (file: IFile) => void;
  loading: boolean;
  setLoading: (b: boolean) => void;
};

export const MessageInputContext = createContext<IMessageContext>({
  message: "",
  cursorPosition: 0,
  inputRef: createRef<HTMLInputElement>(),
  setMessage: () => {},
  setCursorPosition: () => {},
  onMessageSend: () => {},
  answers: [],
  setAnswers: () => {},
  files: [],
  filesDeleteIds: [],
  deleteFileId: () => {},
  setFiles: () => {},
  addFileId: () => {},
  loading: false,
  setLoading: () => {},
});

interface IMessageInputContextProvider {
  children: any;
  onMessageSend: (props: ISendMessageProps) => void;
}

const MessageInputProvider: React.FC<IMessageInputContextProvider> = ({
  children,
  onMessageSend,
}) => {
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const inputRef = createRef<HTMLInputElement>();

  const [cursorPosition, setCursorPosition] = useState<number>(0);

  const [answers, setAnswers] = useState<IQuickAnswer[]>([]);

  const [files, setFiles] = useState<IFile[]>([]);

  const [filesDeleteIds, setFilesDeleteIds] = useState<string[]>([]);

  const handleMessageSend = useCallback(
    (msg: string) => {
      const a = answers.find((a) => a.shortCut === msg.substring(1));
      const mediaFileIds = files.map((f) => f.id);
      if (a) {
        onMessageSend({
          msg: a.message,
          mediaFileIds,
          deleteMediaFileIds: filesDeleteIds,
        });
      } else {
        onMessageSend({
          msg: message,
          mediaFileIds,
          deleteMediaFileIds: filesDeleteIds,
        });
      }
      setMessage("");
      setFiles([]);
      setFilesDeleteIds([]);
    },
    [onMessageSend, answers, files, filesDeleteIds, message]
  );

  const deleteFileId = useCallback((id: string) => {
    setFiles((files) => files.filter((f) => f.id !== id));
    setFilesDeleteIds((i) => [...i, id]);
  }, []);

  const addFileId = useCallback((file: IFile) => {
    setFiles((f) => [...f, file]);
  }, []);

  const value = useMemo(
    () => ({
      message,
      cursorPosition,
      inputRef,
      setMessage,
      setAnswers,
      answers,
      setCursorPosition,
      onMessageSend: handleMessageSend,
      files,
      filesDeleteIds,
      setFiles,
      deleteFileId,
      addFileId,
      loading,
      setLoading,
    }),
    [
      message,
      cursorPosition,
      inputRef,
      setMessage,
      setAnswers,
      answers,
      setCursorPosition,
      handleMessageSend,
      files,
      filesDeleteIds,
      setFiles,
      deleteFileId,
      addFileId,
      loading,
      setLoading,
    ]
  );

  return (
    <MessageInputContext.Provider value={value}>
      {children}
    </MessageInputContext.Provider>
  );
};

export default memo(MessageInputProvider);
