import { memo } from "react";
import { Grid } from "@mui/material";

import DynamicArea from "./DynamicArea";
import CardArea from "./CardArea";
import FlowProvider from "../../store/context/FlowContext/flow-context";

interface IFlowProps {
  boardId: string;
}

const Flow: React.FC<IFlowProps> = ({ boardId }) => {
  return (
    <FlowProvider boardId={boardId}>
      <Grid container columnSpacing={2} height={"100%"} pr={2} pb={2}>
        <DynamicArea />
        <CardArea />
      </Grid>
    </FlowProvider>
  );
};

export default memo(Flow);
