import { Button, DateTime, Form, FormInput, useFetch } from "@4uhub/lib4uhub";
import { Box, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IChecklistItem } from "../../../../../models/ticketChecklist";
import TicketChecklistItemService from "../../../../../services/ticketChecklistItem.service";
import useChecklists from "../../../../../hooks/useChecklists";

const translationPath = "components.chcecklistItemTime.";

const ticketChecklistItemService = new TicketChecklistItemService();

const timeSchema = z.object({
  forecastStartDate: z.date().nullable(),
  estimatedTime: z
    .string()
    .nullable()
    .refine(
      (val) => {
        if (Number(val) < 0 || val === "0") {
          return false;
        } else {
          return true;
        }
      },
      { message: translationPath + "errors.estimated_time" }
    ),
  startDate: z.date().nullable().optional(),
  endDate: z.date().nullable().optional(),
});

export type TTimeForm = z.infer<typeof timeSchema>;

interface ITimeFormProps {
  checklistItem: IChecklistItem;
  onCloseHandler: () => void;
}

const TimeForm = ({ checklistItem, onCloseHandler }: ITimeFormProps) => {
  const { t } = useTranslation();
  const { checklistItems, updateChecklistItemTimes, updateIsChecked } =
    useChecklists();

  const methods = useForm<TTimeForm>({
    resolver: zodResolver(timeSchema),
  });

  const { sendRequest } = useFetch(
    ticketChecklistItemService.updateChecklistItem
  );

  const addItemHandler = useCallback(
    async (form: TTimeForm) => {
      const { data, success } = await sendRequest({
        item: {
          id: checklistItem.id,
          description: checklistItem.description,
          displayOrder:
            checklistItems.length === 0
              ? 1
              : checklistItems[checklistItems.length - 1].displayOrder + 1,
          isChecked: false,
          ticketChecklistId: checklistItem.ticketChecklistId,
          forecastStartDate: form.forecastStartDate
            ? form.forecastStartDate.toISOString()
            : null,
          estimatedTime: form.estimatedTime ? +form.estimatedTime : null,
          startDate: form.startDate ? form.startDate.toISOString() : null,
          endDate: form.endDate ? form.endDate.toISOString() : null,
        },
        id: checklistItem.id,
      });
      if (data && success) {
        updateChecklistItemTimes({
          id: checklistItem.id,
          checklistId: checklistItem.ticketChecklistId,
          forecastStartDate: form.forecastStartDate
            ? form.forecastStartDate?.toISOString()
            : null,
          estimatedTime: form.estimatedTime ? +form.estimatedTime : null,
          startDate: form.startDate ? form.startDate.toISOString() : null,
          endDate: form.endDate ? form.endDate?.toISOString() : null,
        });
        updateIsChecked({
          id: checklistItem.id,
          checklistId: checklistItem.ticketChecklistId,
          endDate: form.endDate ? form.endDate.toISOString() : null,
          isChecked: form.endDate ? true : false,
        });
        methods.reset();
        onCloseHandler();
      }
    },
    [
      sendRequest,
      checklistItems,
      methods,
      onCloseHandler,
      checklistItem.id,
      checklistItem.ticketChecklistId,
      checklistItem.description,
      updateChecklistItemTimes,
      updateIsChecked,
    ]
  );

  const submitHandler = useCallback(
    (form: TTimeForm) => {
      addItemHandler(form);
    },
    [addItemHandler]
  );

  useEffect(() => {
    if (checklistItem.forecastStartDate) {
      methods.setValue(
        "forecastStartDate",
        new Date(checklistItem.forecastStartDate)
      );
    }
    methods.setValue(
      "estimatedTime",
      checklistItem.estimatedTime
        ? checklistItem.estimatedTime.toString()
        : null
    );
    methods.setValue(
      "startDate",
      checklistItem.startDate ? new Date(checklistItem.startDate) : null
    );
    methods.setValue(
      "endDate",
      checklistItem.endDate ? new Date(checklistItem.endDate) : null
    );
  }, [methods, checklistItem]);

  return (
    <Box p={1} maxWidth={350}>
      <Typography fontWeight={"bold"} mb={2}>
        {t(translationPath + "title")}
      </Typography>
      <Form {...methods}>
        <Box
          component="form"
          onSubmit={methods.handleSubmit(submitHandler)}
          display={"flex"}
          flexDirection={"column"}
          gap={1}
        >
          <DateTime
            name="forecastStartDate"
            label={t(translationPath + "forecast_start_date")}
            isClearable
          />

          <FormInput
            name="estimatedTime"
            size="small"
            label={t(translationPath + "estimated_time")}
            type="number"
            autoComplete="off"
          />

          <DateTime
            name="startDate"
            label={t(translationPath + "start_date")}
            isClearable
          />

          <DateTime
            name="endDate"
            label={t(translationPath + "end_date")}
            isClearable
          />

          <Button variant="contained" size="small" type="submit">
            {t(translationPath + "save")}
          </Button>
        </Box>
      </Form>
    </Box>
  );
};

export default TimeForm;
