import {
  DragDropContext,
  Droppable,
} from "react-beautiful-dnd";
import { Stack } from "@mui/material";
import { Loading } from "@4uhub/lib4uhub";
import { memo } from "react";

import AddColumn from "./Column/AddColumn/AddColumn";
import TagsProvider from "../../store/context/tags-context";
import useBoardRole from "../../hooks/useBoardRole";
import ColumnList from "./Column/ColumnList";
import useKanban from "../../hooks/useKanban";

const Kanban: React.FC = () => {
  const { onDragEnd, loading } = useKanban();
  
  const addPermission = useBoardRole("1");

  return (
    <TagsProvider>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="all-columns"
          direction="horizontal"
          type="column"
          ignoreContainerClipping={false}
        >
          {({ droppableProps, innerRef, placeholder }) => (
            <Stack
              {...droppableProps}
              ref={innerRef}
              direction={"row"}
              sx={(t) => ({
                backgroundColor: t.palette.background.default,
                height: "100%",
                width: "100%",
                paddingBottom: t.spacing(2),
              })}
            >
              {loading && <Loading size={80} />}

              {!loading && <ColumnList />}

              {placeholder}

              {addPermission && <AddColumn />}
            </Stack>
          )}
        </Droppable>
      </DragDropContext>
    </TagsProvider>
  );
};
export default memo(Kanban);
