import { memo, useCallback, useMemo } from "react";
import { Stack } from "@mui/material";
import { QPFQuickRegisterForm, QPJQuickRegisterForm, useFormContext } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";

import { TCardForm } from "../AddCardSchema";
import RadioForm from "../../../../../UI/RadioForm";

const ClientSelector = () => {
  const { resetField, control } = useFormContext<TCardForm>();

  const { t } = useTranslation();

  const handleChange = useCallback(
    (value: string) => {
      resetField("client");
    },
    [resetField]
  );

  const value = useWatch({ control, name: "clientType" });

  const isPF = useMemo(() => value === "pf", [value]);
  const isPJ = useMemo(() => value === "pj", [value]);
  
  return (
    <Stack width={"100%"} gap={1}>
      <RadioForm
        onChange={handleChange}
        name="clientType"
        label={t("page.board.createCard.clientType")}
        options={[
          { label: t("page.board.createCard.pf"), value: "pf" },
          { label: t("page.board.createCard.pj"), value: "pj" },
          { label: t("page.board.createCard.not_identified"), value: "none" },
        ]}
      />

      {isPF && (
        <QPFQuickRegisterForm
          name={"client"}
          label={t("page.board.createCard.pf_client")}
        />
      )}

      {isPJ && (
        <QPJQuickRegisterForm
          name={"client"}
          label={t("page.board.createCard.pj_client")}
        />
      )}
    </Stack>
  );
};

export default memo(ClientSelector);
