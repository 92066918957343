import {
  ISelectType,
  useFetch,
  useNotificationContext,
  useUser,
} from "@4uhub/lib4uhub";
import { Stack, TextField, useMediaQuery, useTheme } from "@mui/material";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import MainApiService from "../../../../services/mainApi.service";
import { ITicketChannelProfile } from "../../../../models/ticket-channels";
import { IMember } from "../../../../models/members";
import AutoCompleteProfile from "./AutoCompleteProfile";
import { useWorkspaceContext } from "../../../../store/context/workspace-context";
import MemberData from "./MemberData";
import { useMembersContext } from "../../../../store/context/members-context";
import { useTranslation } from "react-i18next";

const TICKET_CHANNELS_PROFILE_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketChannelProfiles/";
const ticketChannelsProfilesService = new MainApiService<ITicketChannelProfile>(
  TICKET_CHANNELS_PROFILE_ROUTE
);

const Member = ({
  profilesOptions,
  member,
}: {
  profilesOptions: ISelectType[];
  member: IMember;
}) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const [nickname, setNickname] = useState<string | null>(null);

  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const { setMessage } = useNotificationContext();

  const { user } = useUser();

  const { sendRequest: update } = useFetch(
    ticketChannelsProfilesService.updateItemById
  );

  const { sendRequest: remove, loading: dLoading } = useFetch(
    ticketChannelsProfilesService.deleteItemById
  );

  const { boardId, eI, ticketWorkspaceSequence } = useParams();

  const navigate = useNavigate();

  const { leaveBoard } = useWorkspaceContext();

  const { removeMember, updateMember } = useMembersContext();

  const updateHandler = useCallback(
    async ({
      ticketProfileId,
      nickname,
    }: {
      ticketProfileId?: string;
      nickname?: string;
    }) => {
      if (boardId) {
        const { data, success } = await update({
          item: {
            ticketChannelId: boardId,
            ticketProfileId: ticketProfileId ?? member.ticketProfile.id,
            userId: member.user.id,
            id: member.id,
            nickName: nickname ?? member.nickName ?? "",
          },
          id: member.id,
        });
        if (data && success) {
          setMessage({
            message: t("components.boardHeader.member.member_updated"),
            type: "success",
          });
          updateMember({
            id: data.id,
            member: data,
          });
        }
      }
    },
    [boardId, update, updateMember, setMessage, t, member]
  );

  const removeHandler = useCallback(
    async (id: string) => {
      const { success } = await remove(id);
      if (success) {
        removeMember(member.id);
        if (member.user.id === user.id) {
          navigate(`/e/${eI}/w/${ticketWorkspaceSequence}/boards`);
          leaveBoard();
        } else {
          setMessage({
            message: t("components.boardHeader.member.member_removed"),
            type: "success",
          });
        }
      }
    },
    [
      member,
      user,
      eI,
      ticketWorkspaceSequence,
      navigate,
      removeMember,
      leaveBoard,
      remove,
      setMessage,
      t,
    ]
  );

  const onChangeNickname = useCallback(
    (e?: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (!e) return;
      setNickname(e.target.value);
    },
    []
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      if (nickname || nickname === "") {
        updateHandler({ nickname });
        setNickname(null);
      }
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [updateHandler, nickname]);

  return (
    <Stack
      mt={3}
      display={"flex"}
      flexDirection={matches ? "row" : "column"}
      justifyContent={"space-between"}
      alignItems={matches ? "center" : undefined}
      gap={1}
    >
      <MemberData member={member} userFullname={user.fullName} />
      <TextField
        defaultValue={nickname || member.nickName}
        label={t("components.boardHeader.member.nickname")}
        onChange={onChangeNickname}
        size="small"
      />
      <AutoCompleteProfile
        dLoading={dLoading}
        member={member}
        profilesOptions={profilesOptions}
        removeHandler={removeHandler}
        updateHandler={updateHandler}
        user={user}
      />
    </Stack>
  );
};

export default Member;
