import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import privilegeReducer from "./slices/privilege";
import workSpacesReducer from "./slices/workspaces";
import installedExtensionsReducer from "./slices/installedExtensions";

const combinedReducer = combineReducers({
  privilege: privilegeReducer,
  workSpaces: workSpacesReducer,
  installedExtensions: installedExtensionsReducer,
});

const store = configureStore({
  reducer: combinedReducer,
});

export type RootState = ReturnType<typeof combinedReducer>;

type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
