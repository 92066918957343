import { GlobalAxios } from "../axios/axios-config";
import { IGetColumn, IPostColumn } from "../models/columns";

const BOARDS_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketChannelSteps";

export const getColumnsByBoardId = ({
  bId,
  all = false,
  statusCode,
}: {
  bId: string;
  all?: boolean;
  statusCode?: string;
}) => {
  return GlobalAxios.get<IGetColumn[]>(BOARDS_ROUTE + `/TicketChannel/${bId}`, {
    params: {
      all,
      statusCode,
    },
  });
};

export const changeColumnColor = ({
  backgroundColor,
  id,
}: {
  id: string;
  backgroundColor: string;
}) => {
  return GlobalAxios.put(BOARDS_ROUTE + `/BackgroundColor/${id}`, {
    id,
    backgroundColor,
  });
};

export const changeParent = ({
  parentId,
  id,
}: {
  id: string;
  parentId: string;
}) => {
  return GlobalAxios.put(BOARDS_ROUTE + `/Parent/${id}`, {
    id,
    parentId,
  });
};

export const changeStatus = ({
  statusId,
  id,
}: {
  id: string;
  statusId: string;
}) => {
  return GlobalAxios.put(BOARDS_ROUTE + `/Status/${id}`, {
    id,
    statusId,
  });
};

export const changeColumnOrder = ({
  displayOrder,
  id,
}: {
  id: string;
  displayOrder: number;
}) => {
  return GlobalAxios.put(BOARDS_ROUTE + `/DisplayOrder/${id}`, {
    id,
    displayOrder,
  });
};

export const createColumn = (props: IPostColumn) => {
  return GlobalAxios.post<IGetColumn>(BOARDS_ROUTE, props);
};

export const editColumn = (props: IPostColumn) => {
  return GlobalAxios.put<IGetColumn>(BOARDS_ROUTE + `/${props.id}`, props);
};

export const archiveColumnById = (id: string) => {
  return GlobalAxios.put(BOARDS_ROUTE + `/${id}/Archive`);
};

export const unarchiveColumnById = (id: string) => {
  return GlobalAxios.put(BOARDS_ROUTE + `/${id}/Unarchive`);
};

export const disableColumnById = (id: string) => {
  return GlobalAxios.put(BOARDS_ROUTE + `/${id}/Disable`);
};

export const setTransferdColumn = (props: {
  id: string;
  isTransferred: boolean;
}) => {
  return GlobalAxios.put(BOARDS_ROUTE + `/IsTransferred/${props.id}`, props);
};

export const getArchivedColumnsByBoardId = (bId: string) => {
  return GlobalAxios.get<IGetColumn[]>(
    BOARDS_ROUTE + `/TicketChannel/${bId}/Archived`
  );
};

export const changeColumnsOrder = ({
  ticketChannelId,
  ticketChannelStepIds,
}: {
  ticketChannelId: string;
  ticketChannelStepIds: string[];
}) => {
  return GlobalAxios.post(BOARDS_ROUTE + `/Order`, {
    ticketChannelId,
    ticketChannelStepIds,
  });
};
