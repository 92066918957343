import { GlobalAxios } from "../axios/axios-config";
import {
  IChecklist,
  IChecklistOrder,
  INewChecklist,
  IUpdateChecklist,
} from "../models/ticketChecklist";

const TICKET_CHECKLISTS_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketChecklists/";

export class TicketChecklistService {
  getAllChecklists = (id: string) => {
    return GlobalAxios.get<IChecklist[]>(
      TICKET_CHECKLISTS_ROUTE + "Ticket/" + id
    );
  };

  createChecklist = (item: INewChecklist) => {
    return GlobalAxios.post<IChecklist>(TICKET_CHECKLISTS_ROUTE, item);
  };

  updateChecklist = ({ item, id }: { item: IUpdateChecklist; id: string }) => {
    return GlobalAxios.put<IChecklist>(TICKET_CHECKLISTS_ROUTE + id, item);
  };

  updateChecklistOrder = ({ item }: { item: IChecklistOrder }) => {
    return GlobalAxios.put<IChecklist>(
      TICKET_CHECKLISTS_ROUTE + "UpdateOrder",
      item
    );
  };

  deleteChecklist = (id: string) => {
    return GlobalAxios.delete(TICKET_CHECKLISTS_ROUTE + id);
  };
}

export default TicketChecklistService;
