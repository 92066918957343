import { Modal } from "@4uhub/lib4uhub";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import { motion } from "framer-motion";

import { useQuickAnswer } from "../../../../store/context/quickAnswers-context";

import QuickReplayList from "./QuickReplayList";
import QuickReplayForm from "./QuickReplayForm";
export interface IQuickReplayModalProps {
  open: boolean;
  onClose: () => void;
}

const QuickReplayModal: React.FC<IQuickReplayModalProps> = ({
  onClose,
  open,
}) => {
  const { mode, back } = useQuickAnswer();

  const { t } = useTranslation();

  const onCloseHandler = () => {
    onClose();
    setTimeout(back, 200);
  };

  return (
    <Modal
      title={
        t("components.chat.quickReplay.modal.title") || "Respostas rápidas"
      }
      open={open}
      onClose={onCloseHandler}
      maxDialogWidth="xs"
    >
      <motion.div
        style={{
          height: "100%",
          overflow: "auto",
          width: "100%",
        }}
        key={mode}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, ease: [0.6, -0.05, 0.01, 0.99] }}
      >
        <Stack>
          {mode === "list" && <QuickReplayList closeModal={onClose} />}
          {mode !== "list" && <QuickReplayForm />}
        </Stack>
      </motion.div>
    </Modal>
  );
};

export default memo(QuickReplayModal);
