import { BackButton, RadioGroupInputUncontrolled } from "@4uhub/lib4uhub";
import {
  Box,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useAdvancedSearchContext } from "../../../store/context/advanced-search-context";
import SearchFilterActions from "./SearchFilterActions";
import WorkspaceSelect from "./WorkspaceSelect";
import SearchFilterRange from "./SearchFilterRange";
import { useTranslation } from "react-i18next";
import { memo } from "react";
import SearchFilterCreatedBy from "./SearchFilterCreatedBy";
import { EFilterBy } from "../../../models/advanced-search";
import { BoardSelect } from "./BoardSelect";
import { StatusSelect } from "./StatusSelect";

const translationPath = "components.advancedSearchFields.";

interface ISearchFilterFieldsProps {
  onMinimizeMenu: () => void;
}

const SearchFilterFields = ({ onMinimizeMenu }: ISearchFilterFieldsProps) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const {
    protocolInternalId,
    title,
    individualRequesterName,
    individualCustomerName,
    filterBy,
    onChangeProtolInternalId,
    onChangeTitle,
    onChangeIndividualRequesterName,
    onChangeIndividualCustomerName,
    onFilterByHandler,
  } = useAdvancedSearchContext();

  return (
    <Box
      height={"100%"}
      width={matches ? undefined : "100vw"}
      overflow={"auto"}
      mt={matches ? 5 : 0}
      paddingInline={2}
      sx={(t) => ({
        "&::-webkit-scrollbar": {
          width: "0.4em",
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          backgroundColor:
            t.palette.mode === "light"
              ? t.palette.grey[400]
              : t.palette.grey[900],
          borderRadius: 5,
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor:
            t.palette.mode === "light"
              ? t.palette.grey[200]
              : t.palette.grey[700],
          borderRadius: 5,
        },
      })}
    >
      <BackButton />
      <Typography my={1} variant="h6" fontWeight={"bold"}>
        {t(translationPath + "filters")}
      </Typography>
      <Box display={"flex"} flexDirection={"column"} gap={1}>
        <RadioGroupInputUncontrolled
          value={filterBy}
          onChange={onFilterByHandler}
          groupLabel={t(translationPath + "filter_by")}
          disableBorder
          inline
          radios={[
            { label: t(translationPath + "create"), value: EFilterBy.CREATE },
            { label: t(translationPath + "finish"), value: EFilterBy.FINISH },
          ]}
        />
        <SearchFilterRange />
        <WorkspaceSelect />
        <BoardSelect />
        <StatusSelect />
        <SearchFilterCreatedBy />
        <TextField
          label={t(translationPath + "protocol_or_id")}
          value={protocolInternalId}
          onChange={(e) => {
            onChangeProtolInternalId(e.target.value);
          }}
          size="small"
          fullWidth
          disabled={
            !!title || !!individualRequesterName || !!individualCustomerName
          }
        />
        <TextField
          label={t(translationPath + "title")}
          value={title}
          onChange={(e) => onChangeTitle(e.target.value)}
          size="small"
          disabled={!!protocolInternalId}
          fullWidth
        />
        <TextField
          label={t(translationPath + "individual_requester_name")}
          value={individualRequesterName}
          onChange={(e) => onChangeIndividualRequesterName(e.target.value)}
          size="small"
          disabled={!!protocolInternalId}
          fullWidth
        />
        <TextField
          label={t(translationPath + "individual_client_name")}
          value={individualCustomerName}
          onChange={(e) => onChangeIndividualCustomerName(e.target.value)}
          size="small"
          disabled={!!protocolInternalId}
          fullWidth
        />
      </Box>
      <SearchFilterActions onMinimizeMenu={onMinimizeMenu} />
    </Box>
  );
};

export default memo(SearchFilterFields);
