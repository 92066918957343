import { Grid } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";

import useTicket from "../../hooks/useTask";
import MessageActions from "./MessageActions";
import MessageInput from "./MessageInput";
import InputFiles from "./files/InputFiles";

const ChatMessageInput: React.FC = () => {
  const { t } = useTranslation();

  const {
    isUserTicketMember,
    ticket: { status },
  } = useTicket();

  const isDisabled = !isUserTicketMember || status.code === "3";

  return (
    <Grid
      sx={(t) => ({
        backgroundColor: t.palette.mode === "light" ? "#FAFAFA" : "#242424",
        borderTop: `1px solid ${t.palette.divider}`,
        borderRadius: t.shape.borderRadius / 4,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      })}
    >
      <InputFiles />

      <Grid
        container
        direction={"row"}
        justifyContent={"space-between"}
        padding={1}
        alignItems={"center"}
      >
        <Grid item xs={12} md={9}>
          <MessageInput
            disabled={isDisabled}
            placeholder={t("page.card.chat.chatPlaceholder")}
          />
        </Grid>

        <Grid item xs={12} md={"auto"}>
          <MessageActions />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(ChatMessageInput);
