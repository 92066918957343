import { Button } from "@4uhub/lib4uhub";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { useAdvancedSearchContext } from "../../../store/context/advanced-search-context";
import { useTranslation } from "react-i18next";
import { memo } from "react";

const translationPath = "components.advancedSearchFields.";

interface ISearchFilterActionsProps {
  onMinimizeMenu: () => void;
}

const SearchFilterActions = ({ onMinimizeMenu }: ISearchFilterActionsProps) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const {
    loading,
    individualCustomerName,
    individualRequesterName,
    title,
    workspace,
    protocolInternalId,
    isRangeBigger,
    searchResult,
    pageNumber,
    onCleanFiltersHandler,
    onFilterHandler,
    onChangePageNumber,
  } = useAdvancedSearchContext();

  const canCleanFilters =
    individualCustomerName.length !== 0 ||
    individualRequesterName.length !== 0 ||
    title.length !== 0 ||
    !!workspace ||
    protocolInternalId.length !== 0;

  return (
    <Box
      mt={4}
      width={"100%"}
      display={"flex"}
      flexDirection={"column"}
      gap={1}
    >
      <Button
        variant="outlined"
        disabled={canCleanFilters ? false : true}
        fullWidth
        startIcon={<FilterAltOffIcon />}
        onClick={onCleanFiltersHandler}
      >
        {t(translationPath + "clean_filters")}
      </Button>
      <Button
        variant="contained"
        disabled={workspace && !isRangeBigger ? false : true}
        fullWidth
        startIcon={<FilterAltIcon />}
        loading={loading && pageNumber === 1}
        onClick={() => {
          onChangePageNumber(1);
          onFilterHandler(1);
          if (matches) {
            onMinimizeMenu();
          }
        }}
      >
        {t(translationPath + "filter")}
      </Button>
      {!!searchResult && isRangeBigger && (
        <Typography variant="body2" color="error">
          {t(translationPath + "errors.range")}
        </Typography>
      )}
    </Box>
  );
};

export default memo(SearchFilterActions);
