import { Apps, Apps4uHub, useConfig } from "@4uhub/lib4uhub";
import { useMediaQuery, useTheme } from "@mui/material";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { getEstToken } from "../../../services/establishment.service";

const isLocalHost = window.location.hostname === "localhost";

const clickHandler = (link: string) => {
  window.open(link, "_blank");
};

const AppsSelector = () => {
  const theme = useTheme();

  const { eI } = useParams();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  const { establishments } = useConfig();

  const actualEstablishment = useMemo(
    () => establishments.find((e) => e.tenantSequential === Number(eI)),
    [establishments, eI]
  );

  const domain = actualEstablishment?.domainAdmin;

  const APP_4UHUB_LINK = isLocalHost
    ? "http://localhost:3006"
    : domain
    ? domain
    : process.env.REACT_APP_4UHUB;

  return (
    <Apps4uHub
      Apps={[
        {
          logo: "/4uhubApps/4uChatBot.png",
          link: `${APP_4UHUB_LINK}/login/?token=${
            getEstToken()?.token
          }&establishment=${actualEstablishment?.id}&code=${eI}&app=chatbot`,
          name: Apps.APP_4UCHATBOT,
          onClick: clickHandler,
        },
        {
          link: `${APP_4UHUB_LINK}/login/?token=${
            getEstToken()?.token
          }&establishment=${actualEstablishment?.id}&code=${eI}&app=telemed`,
          logo: "/4uhubApps/4uTelemedicina.png",
          name: Apps.APP_4UTELEMED,
          onClick: clickHandler,
        },
        {
          logo: "/4uhubApps/4uHub.png",
          link: `${APP_4UHUB_LINK}/login/?token=${
            getEstToken()?.token
          }&establishment=${actualEstablishment?.id}&code=${eI}&app=dashboard`,
          name: Apps.APP_4UHUB,
          onClick: clickHandler,
        },
        {
          logo: "/4uhubApps/logo_4uSiteApp.png",
          link: `${APP_4UHUB_LINK}/login/?token=${
            getEstToken()?.token
          }&establishment=${actualEstablishment?.id}&code=${eI}&app=4usiteapp`,
          name: Apps.APP_4USITEAPP,
          onClick: clickHandler,
        },
        {
          logo: "/4uhubApps/4uHcp.png",
          link: `${APP_4UHUB_LINK}/login/?token=${
            getEstToken()?.token
          }&establishment=${actualEstablishment?.id}&code=${eI}&app=4uhcp`,
          name: Apps.APP_4UHCP,
          onClick: clickHandler,
        },
        {
          logo: "/4uhubApps/4uBus.png",
          link: `${APP_4UHUB_LINK}/login/?token=${
            getEstToken()?.token
          }&establishment=${actualEstablishment?.id}&code=${eI}&app=4ubus`,
          name: Apps.APP_4UBUS,
          onClick: clickHandler,
        },
        {
          logo: "/4uhubApps/4uSched.png",
          link: `${APP_4UHUB_LINK}/login/?token=${
            getEstToken()?.token
          }&establishment=${actualEstablishment?.id}&code=${eI}&app=4usched`,
          name: Apps.APP_4USCHED,
          onClick: clickHandler,
        },
        {
          logo: "/4uhubApps/4ue-sign.png",
          link: `${APP_4UHUB_LINK}/login/?token=${
            getEstToken()?.token
          }&establishment=${actualEstablishment?.id}&code=${eI}&app=4uESign`,
          name: Apps.APP_4UESIGN,
          onClick: clickHandler,
        },
      ]}
      popOverProps={{
        transformOrigin: {
          vertical: matches ? "top" : "bottom",
          horizontal: "left",
        },
        anchorOrigin: {
          vertical: matches ? "bottom" : "top",
          horizontal: matches ? "left" : "right",
        },
      }}
      iconProps={{
        size: "medium",
        color: "inherit",
      }}
    />
  );
};

export default AppsSelector;
