import axios, { InternalAxiosRequestConfig } from "axios";
import { removePermissions, removeUserName } from "../services/user.service";
import { getEIFromUrl, getEstToken } from "../services/establishment.service";
import {
  getAccessTokens,
  hasRefreshTokens,
  removeAllTokens,
  saveTokens,
} from "../services/token.service";
import { refresh_token } from "../services/auth.service";
import { getDomain } from "../services/domain.service";
import { clearEstablishmentCode } from "@4uhub/lib4uhub";

const softwareCode = 1;

export const GlobalAxios = axios.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
    client_id: process.env.CLIENT_ID,
  },
  data: { softwareCode },
});

GlobalAxios.interceptors.request.use((config) => {
  config.headers.Domain = getDomain();

  return config;
});

GlobalAxios.interceptors.request.use(function (config) {
  let token = getEstToken();

  if (token) {
    config.headers.Authorization = "Bearer " + token.token;
  }

  return config;
});

export const GetActualToken = () => {
  const establishmentcode = getEIFromUrl();

  let token = getAccessTokens().find((t) => t.code === establishmentcode);

  return token?.token || "";
};

export const InterceptorRefresh = async (
  config: InternalAxiosRequestConfig<any>
) => {
  if (!hasRefreshTokens()) {
    throw new Error("Authentication Error. No refresh token");
  }

  const establishmentcode = getEIFromUrl();

  let token = getAccessTokens().find((t) => t.code === establishmentcode);

  try {
    const tokens = await refreshToken(token?.establishmentId);

    if (tokens && establishmentcode !== undefined) {
      saveTokens(
        tokens.access_token,
        tokens.refresh_token,
        establishmentcode,
        token?.establishmentId
      );
      config.headers.Authorization = "Bearer " + tokens.access_token;
    }
  } catch (error) {
    throw new Error("Authentication Error.Error resfreshing token ");
  }

  return config;
};

export const removeUserData = () => {
  removeAllTokens();
  removePermissions();
  clearEstablishmentCode();
  removeUserName();
};

const refreshToken = async (establishmentId?: string) => {
  const response = await refresh_token(establishmentId);
  return response.data;
};
