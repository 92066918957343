import { Grid, Stack } from "@mui/material";

import Card from "../UI/Card";
import UserCards from "./UserCards";
import CardsList from "./CardsList";

const DynamicArea = () => {
  return (
    <Grid item container md={4} xs={12} height={"100%"}>
      <Card width={"100%"} height={"100%"}>
        <Stack height={"100%"} justifyContent={"space-between"}>
          <UserCards />
          <CardsList />
        </Stack>
      </Card>
    </Grid>
  );
};

export default DynamicArea;
