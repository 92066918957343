import {
  Button,
  Form,
  useFetch,
  useNotificationContext,
} from "@4uhub/lib4uhub";
import { Box, Grid, Skeleton } from "@mui/material";
import { CardInternalTemplateContent } from "../CardInternalTemplateContent/CardInternalTemplateContent";
import { memo, useCallback, useEffect } from "react";
import {
  ITicketInternalTemplateFields,
  IUpdatePayload,
} from "../../../../models/internal-template";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { useSchema } from "./card-internal-template-form.schema";
import { updateTicketTemplateInternalFieldById } from "../../../../services/ticketAutomationInternalTemplateTemplate.service";
import NoData from "../../../UI/NoData/NoData";

const translationPath = "components.internalTemplate.";

export const CardInternalTemplateForm = memo(
  ({
    fields,
    loading,
    hasTicketInternalTemplate,
    isUserTicketMember,
    onIsSubmitting,
  }: {
    fields: ITicketInternalTemplateFields[];
    loading: boolean;
    hasTicketInternalTemplate: boolean;
    isUserTicketMember: boolean | undefined;
    onIsSubmitting: (value: boolean) => void;
  }) => {
    const { t } = useTranslation();
    const [getFieldSchema] = useSchema();

    const { setMessage } = useNotificationContext();

    const { sendRequest: update, loading: uLoading } = useFetch(
      updateTicketTemplateInternalFieldById
    );

    const dynamicSchema = z.object(
      Object.assign(
        {},
        ...fields.map((field) => ({
          [field.id]: getFieldSchema(field),
        }))
      )
    );

    type TForm = z.infer<typeof dynamicSchema>;

    const methods = useForm<TForm>({
      resolver: zodResolver(dynamicSchema),
    });

    const getHandler = useCallback(async () => {
      const newData: TForm = fields.reduce((acc, field) => {
        const fieldType =
          field.ticketAutomationInternalTemplateField
            .ticketAutomationInternalTemplateVariable.dataType.code;
        const fieldId = field.id;
        const fieldValue = field.value;

        switch (fieldType) {
          case "1":
          case "2":
          case "7":
          case "3":
            acc[fieldId] = fieldValue ?? "";
            break;

          case "4":
          case "5":
          case "6":
            acc[fieldId] = fieldValue ? new Date(fieldValue) : null;
            break;
          default:
            acc[fieldId] = fieldValue;
        }

        return acc;
      }, {} as TForm);
      methods.reset(newData);
    }, [fields, methods]);

    const updateHandler = useCallback(
      async (values: TForm) => {
        const formattedValues: IUpdatePayload = {
          ticketInternalTemplateFields: Object.entries(values).map(
            ([id, value]) => ({
              id,
              value:
                value instanceof Date ? value.toISOString() : (value as string),
            })
          ),
        };
        update({ item: formattedValues });
        setMessage({
          message: t(translationPath + "update_successfully"),
          type: "success",
        });
      },
      [update, setMessage, t]
    );
    useEffect(() => {
      getHandler();
    }, [getHandler]);

    useEffect(() => {
      onIsSubmitting(uLoading);
    }, [onIsSubmitting, uLoading]);

    const { handleSubmit } = methods;
    if (loading) {
      return (
        <>
          <Skeleton variant="rounded" height={40} sx={{ m: 2 }} />
          <Skeleton variant="rounded" height={40} sx={{ m: 2 }} />
          <Skeleton variant="rounded" height={40} sx={{ m: 2 }} />
          <Skeleton variant="rounded" height={40} sx={{ m: 2 }} />
        </>
      );
    }
    return (
      <Form {...methods}>
        <Box
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit(updateHandler)}
          sx={(t) => ({
            p: 2,
            m: 1,
            backgroundColor: t.palette.background.paper,
            borderRadius: 2,
          })}
        >
          <Grid container spacing={2}>
            {!hasTicketInternalTemplate ? (
              <Grid item xs={12} sm={12}>
                <Box
                  sx={(t) => ({
                    color: t.palette.text.primary,
                  })}
                >
                  <NoData
                    message={t(translationPath + "there_isnt_template")}
                  />
                </Box>
              </Grid>
            ) : (
              <>
                <Grid item xs={12} sm={12}>
                  <CardInternalTemplateContent
                    isUserTicketMember={isUserTicketMember}
                    fields={fields}
                  />
                </Grid>
                {isUserTicketMember ? (
                  <Grid item sx={{ display: "flex", gap: 1 }}>
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      fullWidth
                      loading={uLoading}
                    >
                      {t(translationPath + "actions.save")}
                    </Button>
                  </Grid>
                ) : null}
              </>
            )}
          </Grid>
        </Box>
      </Form>
    );
  }
);
