import { Stack, Typography } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";

import { OverflowTooltip } from "../../UI/TooltipOverflow/TooltipOverflow";
import AvatarImage from "../../UI/AvatarImage";
import UserType from "./UserType";

export const formatTime = (date: string, locale: string) => {
  const hour = new Date(date).toLocaleTimeString(locale, {
    timeStyle: "short",
  });
  return hour;
};

export enum EUserType {
  PF = "Pessoa física",
  PJ = "Pessoa jurídica",
  NI = "Não identificado",
}

interface ITaskHeaderProps {
  created: string;
  name?: string;
  type: EUserType;
}

const TaskHeader = ({ created, name, type }: ITaskHeaderProps) => {
  const { i18n } = useTranslation();

  return (
    <Stack flexDirection={"row"} alignItems={"start"} width={"100%"}>
      <Stack
        flexDirection={"row"}
        alignItems={"start"}
        justifyContent={name ? "space-between" : "end"}
        flexGrow={1}
      >
        {name && (
          <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
            <AvatarImage />
            <Stack maxWidth={100}>
              <OverflowTooltip text={name} tooltip={name} />
              <UserType type={type} />
            </Stack>
          </Stack>
        )}
        <Stack
          width={"fit-content"}
          flexDirection={name ? "column" : "row"}
          columnGap={1}
          alignItems={"end"}
        >
          <Typography variant="caption">
            {formatTime(created, i18n.language)}
          </Typography>
          <Typography variant="caption" color={(t) => t.palette.grey[600]}>
            {new Date(created).toLocaleDateString(i18n.language)}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default memo(TaskHeader);
