import { useCallback, useEffect } from "react";
import { FormInput, Modal, Form, Button, useFetch } from "@4uhub/lib4uhub";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { TColumnForm, columnSchema } from "./AddColumnSchema";
import SelectParentColumn from "./SelectParentColumn";
import { createColumn } from "../../../../services/column.serivice";
import SelectStatus from "./SelectStatus";
import useKanban from "../../../../hooks/useKanban";

interface IAddColumnModalProps {
  open: boolean;
  onClose: () => void;
}

const AddColumnModal: React.FC<IAddColumnModalProps> = ({ onClose, open }) => {
  const { columns, isView, boardId } = useKanban();

  const { t } = useTranslation();

  const methods = useForm<TColumnForm>({
    resolver: zodResolver(columnSchema),
  });

  const { setValue } = methods;

  const { sendRequest } = useFetch(createColumn);

  useEffect(() => {
    if (open) {
      setValue("isView", isView);
    }
  }, [isView, setValue, open]);

  const handleSubmit = useCallback(
    async (form: TColumnForm) => {
      const { data, success } = await sendRequest({
        enable: true,
        name: form.title,
        ticketChannelId: boardId,
        displayOrder: columns.length,
        parentId: form.parent?.id,
        statusId: form.status?.id,
      });

      if (data && success) {
        methods.reset();
        onClose();
      }
    },
    [methods, onClose, boardId, columns, sendRequest]
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t("components.column.addColumn.addColumn") + ""}
      maxDialogWidth={"xs"}
    >
      {open && (
        <Form {...methods}>
          <FormInput
            name="title"
            size="small"
            label={t("components.column.addColumn.name")}
            fullWidth
          />

          {!isView && <SelectParentColumn />}

          {!isView && <SelectStatus />}

          <Button
            disabled={!methods.formState.isValid}
            onClick={methods.handleSubmit(handleSubmit)}
            variant="contained"
          >
            {t("components.column.addColumn.addColumn")}
          </Button>
        </Form>
      )}
    </Modal>
  );
};

export default AddColumnModal;
