import { z } from "zod";

const translationPath = "components.boardHeader.member.errors.";

const userSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "user" }
);

const profileSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "profile" }
);

export const inviteMemberSchema = z.object({
  user: userSchema,
  profile: profileSchema,
  nickName: z.string().optional(),
});

export type TInviteMemberForm = z.infer<typeof inviteMemberSchema>;
