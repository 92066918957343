import { Button } from "@mui/material";
import { ReactNode, memo } from "react";

import { TMode } from "../../../../models/Board";

interface IModeProps {
  mode: TMode;
  onClick: (mode: TMode) => void;
  label: string;
  startIcon: ReactNode;
  selected: boolean;
}

const Mode: React.FC<IModeProps> = ({
  mode,
  onClick,
  label,
  startIcon,
  selected,
}) => {
  const handleClick = () => {
    onClick(mode);
  };

  return (
    <Button
      startIcon={startIcon}
      variant={selected ? "contained" : "text"}
      size="small"
      onClick={handleClick}
    >
      {label}
    </Button>
  );
};

export default memo(Mode);
