import { Box, useMediaQuery, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { memo } from "react";

type TJustifyContent =
  | "flex-start"
  | "flex-end"
  | "center"
  | "space-between"
  | "space-around"
  | "space-evenly";

const SearchResultsListContainer = ({
  justifyContent,
  children,
}: {
  children: JSX.Element;
  justifyContent?: TJustifyContent;
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box
      overflow={"auto"}
      height={"100%"}
      py={10}
      sx={(t) => ({
        "&::-webkit-scrollbar": {
          width: "0.4em",
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          backgroundColor:
            t.palette.mode === "light"
              ? t.palette.grey[400]
              : t.palette.grey[900],
          borderRadius: 5,
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor:
            t.palette.mode === "light"
              ? t.palette.grey[200]
              : t.palette.grey[700],
          borderRadius: 5,
        },
      })}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={justifyContent}
    >
      <Box width={matches ? "50%" : "95%"}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, ease: [0.6, -0.05, 0.01, 0.99] }}
        >
          {children}
        </motion.div>
      </Box>
    </Box>
  );
};

export default memo(SearchResultsListContainer);
