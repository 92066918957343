import { Box } from "@mui/material";
import { memo } from "react";

import AddButton from "./AddButton";
import useBoardRole from "../../hooks/useBoardRole";

const CustomFooter: React.FC = () => {
  const addPermission = useBoardRole("2");


  return (
    <Box justifyContent={"start"} padding={1}>
      {addPermission && <AddButton />}
    </Box>
  );
};

export default memo(CustomFooter);
