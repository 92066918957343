import { Stack, Tooltip, Typography } from "@mui/material";
import { memo } from "react";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import { useTranslation } from "react-i18next";
interface IUnreadMessagesProps {
  number: number;
}

const UnreadMessages: React.FC<IUnreadMessagesProps> = ({ number }) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t("page.card.unreadMessage", { number })}>
      <Stack
        flexDirection={"row"}
        gap={0.5}
        alignItems={"center"}
        sx={{ mt: 0.4 }}
      >
        <Typography variant="caption" color={(t) => t.palette.error.light}>
          {number}
        </Typography>
        <MarkUnreadChatAltIcon
          fontSize="small"
          sx={(t) => ({ color: t.palette.error.light })}
        />
      </Stack>
    </Tooltip>
  );
};

export default memo(UnreadMessages);
