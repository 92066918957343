import { Grid, Typography } from "@mui/material";
import { memo } from "react";

interface IBoardTitleProps {
  title: string;
}

const BoardTitle: React.FC<IBoardTitleProps> = ({ title }) => {
  return (
    <Grid item xs={"auto"}>
      <Typography variant="h5" color={(t) => t.palette.text.primary}>
        {title}
      </Typography>
    </Grid>
  );
};

export default memo(BoardTitle);
