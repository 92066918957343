import { memo, useCallback, useState } from "react";
import { StartChat } from "./StartChat/StartChat";
import { WhatsAppNumber } from "./WhatsAppNumber/WhatsAppNumber";
import { SelectAutomationRule } from "./SelectAutomationRule/SelectAutomationRule";
import { ISelectCountry } from "@4uhub/lib4uhub";

export const NoMessages = memo(() => {
  const [step, setStep] = useState(0);

  const [whatsappNumber, setWhatsappNumber] = useState("");

  const [selectedCountry, setSelectedCountry] = useState<ISelectCountry | null>(
    null
  );

  const getWhatsappNumber = useCallback((number: string) => {
    setWhatsappNumber(number);
  }, []);

  const getSelectedCountry = useCallback((value: ISelectCountry | null) => {
    setSelectedCountry(value);
  }, []);

  const changeStepHandler = useCallback((value: number) => {
    setStep(value);
  }, []);

  if (step === 0) {
    return <StartChat onNextStep={changeStepHandler} />;
  }

  if (step === 1) {
    return (
      <WhatsAppNumber
        selectedCountry={selectedCountry}
        whatsappNumberTexted={whatsappNumber}
        onChangeStep={changeStepHandler}
        onGetWhatsappNumber={getWhatsappNumber}
        onGetSelectedCountry={getSelectedCountry}
      />
    );
  }

  if (step === 2) {
    return (
      <SelectAutomationRule
        whatsappNumber={whatsappNumber}
        onChangeStep={changeStepHandler}
        onBack={getWhatsappNumber}
      />
    );
  }

  return null;
});
