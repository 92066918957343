import { Box } from "@mui/material";
import { IAdvancedSearchResult } from "../../models/advanced-search";

import { memo } from "react";
import useWorkspace from "../../hooks/useWorkspace";
import { useNavigate } from "react-router-dom";
import { getEIFromUrl } from "../../services/establishment.service";
import { SearchResultMainData } from "./SearchResultMainData/SearchResultMainData";
import { SearchResultPeriod } from "./SearchResultPeriod/SearchResultPeriod";
import { SearchResultAditionalData } from "./SearchResultAditionalData/SearchResultAditionalData";
interface ISearchResultCardProps {
  data: IAdvancedSearchResult;
}

const SearchResultCard = ({ data }: ISearchResultCardProps) => {
  const navigate = useNavigate();

  const eI = getEIFromUrl();

  const { workspaces } = useWorkspace();

  const workspaceSequence = workspaces.find(
    (w) => w.id === data.board.ticketWorkspaceId
  )?.ticketWorkspaceSequence;

  const clickHandler = () => {
    navigate(
      `/e/${eI}/w/${workspaceSequence}/boards/${data.board.id}/card/${data.id}`
    );
  };

  return (
    <Box
      sx={(t) => ({
        p: 1,
        cursor: data.hasAccess ? "pointer" : undefined,
        backgroundColor:
          t.palette.mode === "light"
            ? t.palette.grey["100"]
            : t.palette.grey["900"],
        borderRadius: 1,
        "&:hover": data.hasAccess
          ? {
              backgroundColor:
                t.palette.mode === "light"
                  ? t.palette.grey["200"]
                  : t.palette.grey["800"],
            }
          : undefined,
      })}
      onClick={data.hasAccess ? clickHandler : undefined}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <SearchResultMainData
          individualCustomer={
            data.individualCustomer ? data.individualCustomer.name : undefined
          }
          legalEntityCustomer={
            data.legalEntityCustomer ? data.legalEntityCustomer.name : undefined
          }
          ticketOrigin={data.ticketOrigin}
          ticketId={data.cardNumber.toString()}
          title={data.title}
          protocolNumber={data.protocolNumber}
        />
        <SearchResultPeriod
          created={data.created}
          finishDate={data.finishDate ?? undefined}
        />
      </Box>
      <SearchResultAditionalData
        stepName={data.step ? data.step.name : undefined}
        boardName={data.board ? data.board.name : undefined}
      />
    </Box>
  );
};

export default memo(SearchResultCard);
