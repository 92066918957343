import {
  AutoComplete,
  ISelectType,
  useFetch,
  useFormContext,
} from "@4uhub/lib4uhub";
import { FormHelperText, Grid } from "@mui/material";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useTicket from "../../../../hooks/useTask";
import MainApiService from "../../../../services/mainApi.service";

const EVENT_ROUTE = process.env.REACT_APP_TICKETS + "/api/v1/TicketEvent/";
const eventService = new MainApiService<ISelectType>(EVENT_ROUTE);

const EVENT_TYPE_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketEventType/";
const eventTypeService = new MainApiService<ISelectType>(EVENT_TYPE_ROUTE);

const translationPath = "components.events.";

export const EventsFields = memo(() => {
  const { t } = useTranslation();

  const [eventOptions, setEventOptions] = useState<ISelectType[]>([]);

  const { ticket } = useTicket();

  const { ticketCustomerServiceType } = ticket;

  console.log(ticketCustomerServiceType);

  const { control, setValue } = useFormContext();

  const { sendRequest } = useFetch(eventService.list);

  const eventType = useWatch({
    name: "eventType",
    control,
  });

  const fetchEvents = useCallback(async () => {
    if (!eventType) {
      setEventOptions([]);
      return;
    }
    const { data, success } = await sendRequest({
      TicketEventTypeId: eventType.id,
    });
    if (data && success) {
      setEventOptions(data);
    }
  }, [sendRequest, eventType]);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  const eventTypeProps = useMemo(() => {
    return {
      TicketCustomerServiceTypeCode: ticketCustomerServiceType.code,
    };
  }, [ticketCustomerServiceType]);

  return (
    <>
      <Grid item xs={12} sm={6}>
        <AutoComplete
          params={eventTypeProps}
          name="eventType"
          getOptionLabel={(option) => option.name}
          label={t(translationPath + "event_type")}
          size="small"
          fullWidth
          onValueChange={() => setValue("event", null)}
          request={eventTypeService.list}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AutoComplete
          params={{
            TicketEventTypeId: eventType ? eventType.id : undefined,
            TicketCustomerServiceTypeCode: ticketCustomerServiceType.code,
          }}
          name="event"
          getOptionLabel={(option) => option.name}
          label={t(translationPath + "event")}
          size="small"
          fullWidth
          disable={!eventType}
          options={eventOptions}
        />
        {!eventType && (
          <FormHelperText sx={{ pl: 2 }}>
            {t(translationPath + "select_event_hint")}
          </FormHelperText>
        )}
      </Grid>
    </>
  );
});
