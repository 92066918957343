import { GlobalAxios } from "../axios/axios-config";
import { IInstalledExtension } from "../models/intalled-extensions";

const BASE_URL = process.env.REACT_APP_APP;

export const getInstalledExtensions = () => {
  return GlobalAxios.get<IInstalledExtension[]>(
    BASE_URL + "/api/v1/SiteApp/installed-extensions"
  );
};
