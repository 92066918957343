import {
  AutoComplete,
  Form,
  useFetch,
  useNotificationContext,
} from "@4uhub/lib4uhub";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Grid } from "@mui/material";
import { motion } from "framer-motion";
import { memo, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useTicket from "../../../../hooks/useTask";
import MainApi from "../../../../services/mainApi.service";
import { startAutomation } from "../../../../services/ticketAutomation.service";
import { MessageSvg } from "../../../UI/Illustrations/MessageSvg";
import { NoMessagesActions } from "../NoMessagesActions/NoMessagesActions";
import { NoMessagesHeader } from "../NoMessagesHeader/NoMessagesHeader";
import { ISelectAutomationProps } from "./models";
import {
  TSelectAutomationRuleForm,
  selectAutomationRuleSchema,
} from "./selectAutomationRuleSchema";

const translationPath = "page.card.chat.noMessages.selectAutomationRule.";

const TICKET_AUTOMATION_RULE_ROUTE =
  process.env.REACT_APP_AUTOMATIONS + "/api/v1/TicketAutomationRules/";
const ticketAutomationRuleService = new MainApi(TICKET_AUTOMATION_RULE_ROUTE);

export const SelectAutomationRule = memo(
  ({
    whatsappNumber,
    restartChat = false,
    onBack,
    onSucces,
    onChangeStep,
  }: ISelectAutomationProps) => {
    const { t } = useTranslation();

    const { setMessage } = useNotificationContext();

    const { ticket } = useTicket();

    const { sendRequest, loading } = useFetch(startAutomation);

    const methods = useForm<TSelectAutomationRuleForm>({
      resolver: zodResolver(selectAutomationRuleSchema),
    });

    const { handleSubmit } = methods;

    const backHandler = useCallback(() => {
      if (onBack) {
        onBack(whatsappNumber);
      }
      if (onChangeStep) {
        onChangeStep(1);
      }
    }, [onChangeStep, onBack, whatsappNumber]);

    const onSubmit = useCallback(
      async (form: TSelectAutomationRuleForm) => {
        const whatsappNumberFomated = whatsappNumber
          .replaceAll("(", "")
          .replaceAll(")", "")
          .replaceAll("+", "");
        const { data, success } = await sendRequest({
          whatsappNumber: whatsappNumberFomated,
          ticketAutomationRuleId: form.ticketAutomationRule.id,
          ticketId: ticket.id,
        });
        if (data && success) {
          setMessage({
            message: t(translationPath + "chat_started"),
            type: "success",
          });
          if (whatsappNumber && onBack) {
            onBack(whatsappNumber);
          }
          if (onSucces) {
            onSucces();
          }
          if (!onChangeStep) return;
          onChangeStep(3);
        }
      },
      [
        onChangeStep,
        setMessage,
        sendRequest,
        onBack,
        onSucces,
        t,
        ticket.id,
        whatsappNumber,
      ]
    );

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, ease: [0.6, -0.05, 0.01, 0.99] }}
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Form {...methods}>
          <Box
            component="form"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
            sx={(t) => ({
              p: 2,
              width: "50%",
              height: "fit-content",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: t.palette.background.default,
              borderRadius: 2,
            })}
          >
            {restartChat && <MessageSvg height={250} />}
            <NoMessagesHeader
              title={
                restartChat
                  ? t(translationPath + "restart_title")
                  : t(translationPath + "title")
              }
              subtitle={
                restartChat
                  ? t(translationPath + "restart_subtitle")
                  : t(translationPath + "subtitle")
              }
            />
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <AutoComplete
                  params={{ TicketAutomationCode: "8", TicketServiceId: ticket.ticketService.id }}
                  name="ticketAutomationRule"
                  label={t(translationPath + "automation_rule")}
                  getOptionLabel={(option) => option.name}
                  request={ticketAutomationRuleService.list}
                  size="small"
                  fullWidth
                />
              </Grid>
            </Grid>
            <NoMessagesActions
              restartChat={restartChat}
              onCancel={backHandler}
              loading={loading}
            />
          </Box>
        </Form>
      </motion.div>
    );
  }
);
