import { Box, Divider, Popover, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Button, useFetch, useRole } from "@4uhub/lib4uhub";
import AddIcon from "@mui/icons-material/Add";
import { useCallback, useEffect } from "react";

import { getWorkspaces } from "../../../services/workspace.service";
import { useAppDispatch } from "../../../store/store";
import { workspacesSliceActions } from "../../../store/slices/workspaces";

import CurrentWorkspacePopover from "./CurrentWorkspacePopover";
import OthersWorkspacesPopover from "./OthersWorkspacesPopover";

const translationPath = "components.workspacesPopover.";

const WorkspacesPopover = ({
  open,
  anchorEl,
  handleClose,
  handleOpenModal,
}: {
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
  handleOpenModal: () => void;
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { sendRequest } = useFetch(getWorkspaces);

  const readPermission = useRole(["Read"]);

  const createPermission = useRole(["Create"]);

  const fetchWorkSpaces = useCallback(async () => {
    dispatch(workspacesSliceActions.setLoadinState(true));
    const { data, success } = await sendRequest(undefined);
    if (data && success) {
      dispatch(workspacesSliceActions.saveWorkspaces(data.items));
    }
    dispatch(workspacesSliceActions.setLoadinState(false));
  }, [sendRequest, dispatch]);

  useEffect(() => {
    if (readPermission) {
      fetchWorkSpaces();
    }
  }, [fetchWorkSpaces, readPermission]);

  const handleClick = () => {
    handleClose();
    handleOpenModal();
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Box minWidth={200} maxWidth={250}>
        {readPermission && <CurrentWorkspacePopover />}
        <Divider />
        {readPermission && <OthersWorkspacesPopover onClick={handleClose} />}

        {createPermission && (
          <Stack m={1}>
            <Button startIcon={<AddIcon />} onClick={handleClick}>
              {t(translationPath + "create_workspace")}
            </Button>
          </Stack>
        )}
      </Box>
    </Popover>
  );
};

export default WorkspacesPopover;
