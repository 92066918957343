import { useFormContext, useUser } from "@4uhub/lib4uhub";
import { IMember } from "../../../../../models/members";
import SelectMembers from "../../../../UI/SelectMembers";
import { TFilterSchema } from "../filterSchema";
import { useMembersContext } from "../../../../../store/context/members-context";
import { useMemo } from "react";

interface IMultiSelectMembersProps {
  isCreatedBy?: boolean;
}

const SYSTEM_ID = "00000000-0000-0000-0000-000000000000";

const MultiSelectMembers = ({ isCreatedBy }: IMultiSelectMembersProps) => {
  const { setValue, watch } = useFormContext<TFilterSchema>();

  const { members } = useMembersContext();

  const formMembers = watch("membersIds");
  const createdBy = watch("createdBy");

  const {
    user: { id },
  } = useUser();

  const handleChange = (m: IMember[]) => {
    const ids = m.map((m) => m.user.id);
   

    const includeMemberHandler = (currentIds: string[], idToCheck: string) => {
      if (currentIds.includes(idToCheck) && !ids.includes(idToCheck)) {
        ids.push(idToCheck);
      }
    };

    if (isCreatedBy) {
      includeMemberHandler(createdBy, id);
      includeMemberHandler(createdBy, SYSTEM_ID);
      setValue("createdBy", ids, { shouldDirty: true });
    } else {
      includeMemberHandler(formMembers, id);
      includeMemberHandler(formMembers, "none");
      setValue("membersIds", ids, { shouldDirty: true });
    }
  };

  const filteredMembers = useMemo(
    () => members.filter((m) => m.user.id !== id),
    [members, id]
  );

  const value = useMemo(
    () => filteredMembers.filter((m) => formMembers.includes(m.user.id)),
    [formMembers, filteredMembers]
  );

  const createdByValue = useMemo(
    () => filteredMembers.filter((m) => createdBy.includes(m.user.id)),
    [createdBy, filteredMembers]
  );

  return (
    <SelectMembers<IMember>
      options={filteredMembers}
      value={isCreatedBy ? createdByValue : value}
      getOptionLabel={(option) => option.user.fullName}
      onChange={handleChange}
    />
  );
};

export default MultiSelectMembers;
