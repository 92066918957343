import { ISelectType } from "@4uhub/lib4uhub";
import {
  createContext,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import useTicket from "../../hooks/useTask";

interface IExternalProcessContext {
  externalProcessType: ISelectType | null;
  externalProcessTypeControl: string | null;
  onChangeExternalProcessType: (value: ISelectType | null) => void;
  onChangeExternalProcessTypeControl: (value: string) => void;
}

export const ExternalProcessContext = createContext<IExternalProcessContext>({
  externalProcessType: null,
  externalProcessTypeControl: null,
  onChangeExternalProcessType: () => {},
  onChangeExternalProcessTypeControl: () => {},
});

interface IExternalProcessProvider {
  children: JSX.Element;
}

const ExternalProcessProvider: React.FC<IExternalProcessProvider> = ({
  children,
}) => {
  const { ticket } = useTicket();

  const {
    ticketExternalProcessType,
    externalProcessTypeControl: ticketExternalProcessTypeControl,
  } = ticket;

  const [externalProcessType, setExternalProcessType] =
    useState<ISelectType | null>(ticketExternalProcessType);

  const [externalProcessTypeControl, setExternalProcessTypeControl] = useState<
    string | null
  >(ticketExternalProcessTypeControl);

  const onChangeExternalProcessType = useCallback(
    (value: ISelectType | null) => {
      setExternalProcessType(value);
    },
    []
  );

  const onChangeExternalProcessTypeControl = useCallback((value: string) => {
    setExternalProcessTypeControl(value);
  }, []);

  const value = useMemo(
    () => ({
      externalProcessType,
      externalProcessTypeControl,
      onChangeExternalProcessType,
      onChangeExternalProcessTypeControl,
    }),
    [
      externalProcessType,
      externalProcessTypeControl,
      onChangeExternalProcessType,
      onChangeExternalProcessTypeControl,
    ]
  );

  return (
    <ExternalProcessContext.Provider value={value}>
      {children}
    </ExternalProcessContext.Provider>
  );
};

export const useExternalProcessContext = () => {
  return useContext(ExternalProcessContext);
};

export default memo(ExternalProcessProvider);
