import { Button, useFetch } from "@4uhub/lib4uhub";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import TagsService from "../../../../services/tags.service";
import useTags from "../../../../hooks/useTags";
import PopoverDelete from "../../../UI/PopoverDelete";

const tagsService = new TagsService();

const translationPath = "components.tagDeleteButton.";

const TagDeleteButton = ({
  id,
  onCloseForm,
}: {
  id: string;
  onCloseForm: () => void;
}) => {
  const { t } = useTranslation();

  const { removeTicketTags, removeTag } = useTags();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { sendRequest: deleteTag, loading } = useFetch(
    tagsService.deleteItemById
  );

  const deleteHandler = useCallback(async () => {
    const { data, success } = await deleteTag(id);
    if (data && success) {
      removeTicketTags(id);
      removeTag(id);
      onCloseForm();
    }
  }, [deleteTag, removeTicketTags, onCloseForm, removeTag, id]);

  const open = Boolean(anchorEl);

  const idPopover = open ? "delete-popover" : undefined;

  return (
    <>
      <Button
        size="small"
        onClick={handleClick}
        color="error"
        fullWidth
        variant="contained"
      >
        {t(translationPath + "delete")}
      </Button>

      {open && (
        <PopoverDelete
          title={t(translationPath + "delete")}
          description={t(translationPath + "description")}
          buttonDescription={t(translationPath + "delete_button")}
          onDelete={deleteHandler}
          onClose={handleClose}
          open={open}
          anchorEl={anchorEl}
          id={idPopover}
          loading={loading}
        />
      )}
    </>
  );
};

export default TagDeleteButton;
