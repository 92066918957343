import { useNavigate } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../store/store";
import WorkspacesItem from "../WorkspacesItem";
import { IGetWorksSpace } from "../../../models/workspaces";
import SeeMoreButton from "./SeeMoreButton";

const translationPath = "components.workspacesPopover.";

interface IOthersWorkspacesPopover {
  onClick: () => void;
}

const OthersWorkspacesPopover: React.FC<IOthersWorkspacesPopover> = ({
  onClick,
}) => {
  const { t } = useTranslation();

  const workspaces = useAppSelector((state) => state.workSpaces.workspaces);

  const navigate = useNavigate();

  const handleClick = (workspace: IGetWorksSpace) => {
    onClick();
    navigate(`w/${workspace.ticketWorkspaceSequence}/boards`);
  };

  if (workspaces.length > 0) {
    return (
      <Stack sx={(t) => ({ padding: t.spacing(2) })}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography
            sx={(theme) => ({
              marginBottom: theme.spacing(1),
              color:
                theme.palette.mode === "light"
                  ? theme.palette.grey[700]
                  : theme.palette.grey[400],
            })}
            variant="subtitle2"
          >
            {t(translationPath + "workspaces")}
          </Typography>
            <SeeMoreButton onClick={onClick}/>
        </Stack>

        <Stack
          maxHeight={350}
          overflow={"auto"}
          mt={1}
          pr={1}
          display={"flex"}
          flexDirection={"column"}
          sx={(t) => ({
            "&::-webkit-scrollbar": {
              width: "0.4em",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              backgroundColor:
                t.palette.mode === "light"
                  ? t.palette.grey[400]
                  : t.palette.grey[900],
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor:
                t.palette.mode === "light"
                  ? t.palette.grey[200]
                  : t.palette.grey[700],
              borderRadius: 5,
            },
          })}
        >
          {workspaces.map((workspace) => (
            <WorkspacesItem
              name={workspace.name}
              key={workspace.id}
              onClick={() => handleClick(workspace)}
            />
          ))}
        </Stack>
      </Stack>
    );
  }

  return <></>;
};

export default OthersWorkspacesPopover;
