import { Button } from "@4uhub/lib4uhub";
import SearchIcon from "@mui/icons-material/Search";
import { Popover } from "@mui/material";
import usePopover from "../../../../hooks/usePopover";
import AdvancedSearchForm from "./AdvancedSearchForm";
import { useTranslation } from "react-i18next";
import { memo } from "react";

const translationPath = "components.advancedSearchHeader.";

const AdvancedSearch = () => {
  const { t } = useTranslation();

  const { anchorEl, id, handleClick, handleClose, open } = usePopover(
    "advanced-search-popover"
  );

  return (
    <>
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        sx={{
          mt: 2,
        }}
      >
        <AdvancedSearchForm />
      </Popover>
      <Button
        startIcon={<SearchIcon />}
        variant={"text"}
        size="small"
        onClick={handleClick}
      >
        {t(translationPath + "search")}
      </Button>
    </>
  );
};

export default memo(AdvancedSearch);
