import { GlobalAxios } from "../axios/axios-config";
import { ISalesContract } from "../models/sales-contract";

const TICKET_SERVICE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketRelationship";

export const getTicketRelationshipSalesContractByTicketId = (
  ticketId: string
) => {
  return GlobalAxios.get<ISalesContract[]>(TICKET_SERVICE + `/${ticketId}`);
};
