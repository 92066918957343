import { Button, Form } from "@4uhub/lib4uhub";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";

import SelectParentColumn from "../../../AddColumn/SelectParentColumn";
import { TEditColumnForm, editColumnSchema } from "./editListSchema";
import useColumn from "../../../../../../hooks/useColumn";

interface IEditListParentFormProps {
  onSubmit: (data: TEditColumnForm) => void;
  loading?: boolean;
}

const EditListParentForm: React.FC<IEditListParentFormProps> = ({
  onSubmit,
  loading,
}) => {
  const { t } = useTranslation();

  const {
    data: { parent },
  } = useColumn();

  const methods = useForm<TEditColumnForm>({
    resolver: zodResolver(editColumnSchema),
  });

  const { setValue } = methods;

  useEffect(() => {
    setValue("parent", parent);
  }, [parent, setValue]);

  return (
    <Form {...methods} >
      <Stack gap={1} padding={1} minWidth={200}>
        <Typography>
          {t("components.column.menu.changeParent.change")}
        </Typography>

        <SelectParentColumn />

        <Button
          variant="outlined"
          onClick={methods.handleSubmit(onSubmit)}
          loading={loading}
        >
          {t("components.column.menu.changeParent.button")}
        </Button>
      </Stack>
    </Form>
  );
};

export default EditListParentForm;
