import { Grid, Skeleton, Stack } from "@mui/material";
import { memo } from "react";

import useTicket from "../../../../hooks/useTask";
import CardTitle from "../CardTItle";
import CardInfoStep from "./CardInfoStep";

const CardInfoHeader = () => {
  const {
    loading,
    ticket: { id, title, ticketChannelStep },
  } = useTicket();

  return (
    <Grid
      container
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Stack width={"100%"}>
        <CardTitle id={id} title={title} loading={loading} />

        <CardInfoStep list={ticketChannelStep.name} loading={loading} />
      </Stack>
      {loading && <Skeleton variant="text" width={100} height={20} />}
    </Grid>
  );
};

export default memo(CardInfoHeader);
