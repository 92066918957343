import { memo } from "react";

import Column from "./Column";
import useKanban from "../../../hooks/useKanban";

const ColumnList = () => {
  const {
    columns,
    isView,
    columnOrder,
    onColumnColorChange,
    onColumnNameChange,
    onSetTasks,
    onParentChange,
    onColumnStatusChange,
  } = useKanban();

  return (
    <>
      {columnOrder.map((id, index) => {
        const column = columns.find((c) => c.id === id);

        if (!column) {
          return null;
        }

        return (
          <Column
            key={column.id}
            index={index}
            column={column}
            isView={isView}
            onColumnColorChange={onColumnColorChange}
            onColumnNameChange={onColumnNameChange}
            onSetTasks={onSetTasks}
            onParentChange={onParentChange}
            onColumnStatusChange={onColumnStatusChange}
          />
        );
      })}
    </>
  );
};

export default memo(ColumnList);
