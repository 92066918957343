import { Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import MovePopover from "./MovePopover";
import usePopover from "../../../../hooks/usePopover";
import { useTranslation } from "react-i18next";
import { memo } from "react";

const MoveAction: React.FC = () => {
  const { anchorEl, id, handleClick, handleClose, open } = usePopover(
    "move-ticket-popover"
  );

  const { t } = useTranslation();

  return (
    <>
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        color="inherit"
        sx={(t) => ({
          color: t.palette.text.primary,
        })}
        startIcon={<ArrowForwardIcon />}
        size="small"
      >
        {t("page.card.actions.move")}
      </Button>

      <MovePopover
        onClose={handleClose}
        open={open}
        id={id}
        anchorEl={anchorEl}
      />
    </>
  );
};
export default memo(MoveAction);
