import { AutoComplete, useFetch } from "@4uhub/lib4uhub";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { getColumnsByBoardId } from "../../../../services/column.serivice";
import { useWorkspaceContext } from "../../../../store/context/workspace-context";
import { IGetColumn } from "../../../../models/columns";

const SelectParentColumn = () => {
  const { actualBoard } = useWorkspaceContext();

  const { t } = useTranslation();

  const [columns, setColumns] = useState<IGetColumn[]>([]);

  const { sendRequest } = useFetch(getColumnsByBoardId);

  const fetchColumns = useCallback(async () => {
    if (actualBoard && actualBoard.parent) {
      const { data, success } = await sendRequest({
        bId: actualBoard.parent?.id,
      });

      if (success && data) {
        setColumns(data);
      }
    }
  }, [actualBoard, sendRequest]);

  useEffect(() => {
    fetchColumns();
  }, [fetchColumns]);

  return (
    <AutoComplete
      name={"parent"}
      label={t("components.column.addColumn.parent")}
      getOptionLabel={(option) => {
        return option.name || option.value || "";
      }}
      size="small"
      options={columns}
      fullWidth
    />
  );
};
export default SelectParentColumn;
