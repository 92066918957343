import { Stack } from "@mui/material";
import { Droppable } from "react-beautiful-dnd";
import { memo } from "react";

import CardChecklistItem from "./CardChecklistItem";
import { IChecklistItem } from "../../../../models/ticketChecklist";

const CardChecklistItems = ({
  checklistItemsList,
  checklistId,
  hideCheckedItems,
}: {
  checklistItemsList: IChecklistItem[];
  checklistId: string;
  hideCheckedItems: boolean;
}) => {
  return (
    <Droppable
      droppableId={checklistId}
      direction="vertical"
      type="checklist-item"
      ignoreContainerClipping={false}
    >
      {({ droppableProps, innerRef: checklistItemsRef, placeholder }) => (
        <Stack
          sx={{ mt: 1, mb: 1, minHeight: 20 }}
          {...droppableProps}
          ref={checklistItemsRef}
        >
          {checklistItemsList.map((checklistItem, index) => (
            <CardChecklistItem
              checklistItem={checklistItem}
              index={index}
              key={checklistItem.id}
              checklistId={checklistId}
              hideCheckedItem={hideCheckedItems}
            />
          ))}
          {placeholder}
        </Stack>
      )}
    </Droppable>
  );
};

export default memo(CardChecklistItems);
