import { ControlAccess, getEstablishmentCode } from "@4uhub/lib4uhub";
import { Navigate, Outlet, RouteObject } from "react-router-dom";

import { hasAccessTokens } from "./services/token.service";

import Board from "./pages/Board";
import RootPage from "./pages/RootPage";
import Card from "./pages/Card";
import LoginPage from "./pages/LoginPage/LoginPage";
import Boards from "./pages/Boards";
import WorksPace from "./pages/Workspace";
import WorkspaceProvider from "./store/context/workspace-context";
import Home from "./pages/Home";
import NoAccessPage from "./pages/NoAccessPage";
import SignalRProvider from "./components/SignalR/SignalRProvider";
import SearchPage from "./pages/SearchPage";

const routes: RouteObject[] = [
  {
    path: "/",
    element: <Navigate to={`/e/${getEstablishmentCode()}`} />,
  },
  {
    path: "/login",
    element: (
      <ControlAccess canAccess={() => !hasAccessTokens()} redirectUrl="/">
        <LoginPage />
      </ControlAccess>
    ),
  },
  {
    path: "/e/:eI",
    element: (
      <ControlAccess canAccess={() => hasAccessTokens()} redirectUrl="/login">
        <SignalRProvider>
          <RootPage />
        </SignalRProvider>
      </ControlAccess>
    ),
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "no-access",
        element: <NoAccessPage />,
      },
      {
        path: "search/:workspaceId?/:protocolOrId?",
        element: <SearchPage />,
      },
      {
        path: "w/:ticketWorkspaceSequence",
        element: (
          <WorkspaceProvider>
            <Outlet />
          </WorkspaceProvider>
        ),
        children: [
          {
            index: true,
            element: <Navigate to={"boards"} />,
          },
          {
            path: "boards",
            element: <Boards />,
            children: [
              {
                index: true,
                element: <WorksPace />,
              },
              {
                path: ":boardId",
                element: <Board />,
              },
              {
                path: ":boardId/card/:cardId",
                element: <Card />,
              },
            ],
          },
        ],
      },
    ],
  },
];

export default routes;
