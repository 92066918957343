import { Grid } from "@mui/material";
import { memo } from "react";
import ExternalProcessProvider from "../../../store/context/external-process-context";
import { TicketExternalIdentifier } from "./TicketExternalIdentifier/TicketExternalIdentifier";
import { TicketExternalProcessType } from "./TicketExternalProcessType/TicketExternalProcessType";

export const CardExternalInfo = memo(() => {
  return (
    <ExternalProcessProvider>
      <>
        <Grid item xs={12} sm={5}>
          <TicketExternalProcessType />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TicketExternalIdentifier />
        </Grid>
      </>
    </ExternalProcessProvider>
  );
});
