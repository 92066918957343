import { IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import { memo, useCallback } from "react";

const translationPath = "components.tagItemActions.";
interface ITagEditButton {
  onEditTag: (tagId: string) => void;
  tagId: string;
}

const TagEditButton: React.FC<ITagEditButton> = ({ onEditTag, tagId }) => {
  const { t } = useTranslation();

  const onClickHandler = useCallback(() => {
    onEditTag(tagId);
  }, [onEditTag, tagId]);

  return (
    <Tooltip title={t(translationPath + "edit")}>
      <IconButton
        size="small"
        sx={{ width: "fit-content", height: "fit-content", padding: 0.5 }}
        onClick={onClickHandler}
      >
        <EditIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

export default memo(TagEditButton);
