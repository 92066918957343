import { Stack } from "@mui/material";
import { memo, useCallback, useEffect, useRef } from "react";
import { useFetch } from "@4uhub/lib4uhub";

import CardTagSelector from "./CardTagSelector";
import { ITag } from "../../../models/tags";
import usePopover from "../../../hooks/usePopover";
import TagsService from "../../../services/tags.service";
import useTags from "../../../hooks/useTags";
import TicketChannelTagService from "../../../services/ticketChannelTag.service";
import TicketTagsList from "./TicketTagsList";
import useBoardRole from "../../../hooks/useBoardRole";
import useTicket from "../../../hooks/useTask";

const tagsService = new TagsService<ITag>();

const ticketTagsService = new TicketChannelTagService();

const CardTags = () => {
  const {
    isUserTicketMember,
    ticket: {
      id: cardId,
      ticketChannelStep: { ticketChannelId },
      status,
    },
  } = useTicket();

  const { loadTagsList, loadTicketTags, ticketTags } = useTags();

  const { handleClick, handleClose, id, open } = usePopover(
    "ticket-status-popover"
  );

  const { sendRequest, loading } = useFetch(tagsService.getTagsTicketId);

  const { sendRequest: ticketTagsList } = useFetch(
    ticketTagsService.getItemById
  );

  const loadTicketTagsHandler = useCallback(async () => {
    if (cardId) {
      const { data, success } = await ticketTagsList(cardId);
      if (data && success) {
        loadTicketTags(data);
      }
    }
  }, [loadTicketTags, ticketTagsList, cardId]);

  const loadTags = useCallback(async () => {
    if (ticketChannelId) {
      const { data, success } = await sendRequest(ticketChannelId);
      if (data && success) {
        loadTagsList(data);
      }
    }
  }, [sendRequest, ticketChannelId, loadTagsList]);

  useEffect(() => {
    loadTags();
    loadTicketTagsHandler();
  }, [loadTags, loadTicketTagsHandler]);

  const permission = useBoardRole("2");

  const divRef = useRef(null);

  return (
    <Stack>
      <TicketTagsList
        loading={loading}
        handleClick={handleClick}
        disabled={!permission || status.code === "3" || !isUserTicketMember}
        ticketTags={ticketTags}
      />
      <CardTagSelector
        anchorEl={divRef.current}
        id={id}
        open={open}
        onClose={handleClose}
      />
      <div ref={divRef}></div>
    </Stack>
  );
};

export default memo(CardTags);
