import { GlobalAxios } from "../axios/axios-config";
import {
  IGetUserConfig,
  IUpdateUserConfig,
} from "../models/userConfigurations";

const CREATE_ROUTE =
  process.env.REACT_APP_IDENTIY +
  "/api/v1/UserConfigurations/CreateUpdateUserConfigurationAdmin";

const GET_ROUTE =
  process.env.REACT_APP_IDENTIY +
  "/api/v1/UserConfigurations/GetUserConfigurationAdmin";

export const createUserConfig = (item: IUpdateUserConfig) => {
  return GlobalAxios.post(CREATE_ROUTE, item);
};

export const getUserConfig = () => {
  return GlobalAxios.get<IGetUserConfig | null>(GET_ROUTE);
};
