import { GlobalAxios } from "../axios/axios-config";
import { IChecklistItem, IChecklistItemOrder } from "../models/ticketChecklist";
import {
  INewChecklistItem,
  IUpdateChecklistItem,
  IUpdateIsChecked,
} from "../models/ticketChecklistItem";

const TICKET_CHECKLISTS_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketChecklistItems/";

export class TicketChecklistItemService {
  createChecklistItem = (item: INewChecklistItem) => {
    return GlobalAxios.post<IChecklistItem>(TICKET_CHECKLISTS_ROUTE, item);
  };

  updateChecklistItem = ({
    item,
    id,
  }: {
    item: IUpdateChecklistItem;
    id: string;
  }) => {
    return GlobalAxios.put<IChecklistItem>(TICKET_CHECKLISTS_ROUTE + id, item);
  };

  updateChecklistItemOrder = ({ item }: { item: IChecklistItemOrder }) => {
    return GlobalAxios.put<IChecklistItem>(
      TICKET_CHECKLISTS_ROUTE + "UpdateOrder",
      item
    );
  };

  updateIsChecked = ({ item }: { item: IUpdateIsChecked }) => {
    return GlobalAxios.put<IChecklistItem>(
      TICKET_CHECKLISTS_ROUTE + "UpdateIsChecked",
      item
    );
  };

  deleteChecklistItem = (id: string) => {
    return GlobalAxios.delete(TICKET_CHECKLISTS_ROUTE + id);
  };
}

export default TicketChecklistItemService;
