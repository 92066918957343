import { Divider, IconButton, Menu, MenuList, Typography } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";

import ColorPicker from "./ColorPicker";
import useColumn from "../../../../../hooks/useColumn";
import ArchiveList from "./ArchiveList";
import useBoardRole from "../../../../../hooks/useBoardRole";
import EditListParent from "./EditListParent/EditListParent";
import EditListDefaultStatus from "./EditListDefaultStatus/EditListDefaultStatus";

const ColumMenu: React.FC<{ id: string; isView?: boolean }> = ({
  id,
  isView,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const permission = useBoardRole("2");

  const open = Boolean(anchorEl);

  const { t } = useTranslation();

  const {
    contrastColor,
  } = useColumn();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="column-menu"
        size="small"
        onClick={handleClick}
      >
        <MoreHorizIcon
          fontSize="inherit"
          sx={(t) => ({
            color: contrastColor || t.palette.primary.main,
          })}
        />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            sx: {
              minWidth: 200,
            },
          },
        }}
        MenuListProps={{
          "aria-labelledby": "column-menu",
        }}
      >
        <Typography
          variant="subtitle2"
          textAlign={"center"}
          fontWeight={"bold"}
        >
          {t("components.column.menu.title")}
        </Typography>
        <MenuList dense>
          {permission && <ColorPicker id={id} />}
          {permission && !isView && <EditListParent id={id} />}
          {permission &&  !isView && <EditListDefaultStatus id={id}/>}
          <Divider />
          {permission && <ArchiveList id={id} />}
        </MenuList>
      </Menu>
    </div>
  );
};

export default memo(ColumMenu);
