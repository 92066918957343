import { Box, Tooltip, Typography } from "@mui/material";

import { IDetailFileProps } from "./models";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import DetailFileActions from "./DetailFileActions";
import DetailFileDescription from "./DetailFileDescription";
import NoData from "../../../../../UI/NoData/NoData";
import { useTranslation } from "react-i18next";

const translationPath = "components.detailFile.";

const DetailFile = ({ media, index, mediaFilesLength }: IDetailFileProps) => {
  const { t } = useTranslation();

  const [showDescription, setShowDescription] = useState<number | null>(null);

  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const fileNameRef = useRef(null);

  const handleOverflow = useCallback(() => {
    if (fileNameRef.current) {
      const { scrollWidth, clientWidth } = fileNameRef.current;
      setShowTooltip(scrollWidth > clientWidth);
    }
  }, []);

  useEffect(() => {
    handleOverflow();
  }, [handleOverflow]);

  const toggleDescription = (index: number) => {
    if (showDescription === index) {
      setShowDescription(null);
    } else {
      setShowDescription(index);
    }
  };

  return (
    <Box
      key={media.id}
      sx={(t) => ({
        p: 0.5,
        borderBottom:
          mediaFilesLength - 1 === index
            ? "none"
            : `1px solid ${t.palette.divider}`,
        borderRadius: 0.5,
        "&:hover": {
          backgroundColor: t.palette.action.hover,
          cursor: "pointer",
        },
      })}
      onClick={() => toggleDescription(index)}
    >
      {mediaFilesLength === 0 && (
        <NoData message={t(translationPath + "no_data")} />
      )}
      {mediaFilesLength !== 0 && (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Tooltip
              title={media.fileName}
              placement="top"
              disableHoverListener={!showTooltip}
            >
              <Typography variant="subtitle2" noWrap ref={fileNameRef}>
                {media.fileName}
              </Typography>
            </Tooltip>
            <DetailFileActions
              media={media}
              isExpanded={showDescription === index}
              onClickExpand={() => toggleDescription(index)}
            />
          </Box>
          {showDescription === index && (
            <DetailFileDescription description={media.description} />
          )}
        </>
      )}
    </Box>
  );
};

export default memo(DetailFile);
