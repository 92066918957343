import { Box, Typography } from "@mui/material";
import { memo, useCallback } from "react";
import { motion } from "framer-motion";
import { Button, SearchInput } from "@4uhub/lib4uhub";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useEvents from "../../../hooks/useEvents";
import { useTranslation } from "react-i18next";
import useTicket from "../../../hooks/useTask";

const translationPath = "components.events.header.";

export const EventsHeader = memo(() => {
  const { t } = useTranslation();

  const {
    isUserTicketMember,
    ticket: { status },
  } = useTicket();

  const { mode, onChangeSearch, onChangeMode } = useEvents();

  const goToCreate = useCallback(() => {
    onChangeMode("create");
  }, [onChangeMode]);

  const goToList = useCallback(() => {
    onChangeMode("list");
  }, [onChangeMode]);

  const isDisabled = !isUserTicketMember || status?.code === "3";

  return (
    <Box
      sx={(t) => ({
        py: 2,
        px: mode === "list" ? 1 : 2.5,
        mb: 1,
        height: 58,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: t.palette.background.paper,
        borderRadius: 2,
      })}
    >
      {mode === "list" && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, ease: [0.6, -0.05, 0.01, 0.99] }}
        >
          <SearchInput
            placeholder={t(translationPath + "search")}
            onSearch={onChangeSearch}
          />
        </motion.div>
      )}

      {mode !== "list" && (
        <>
          <Typography
            fontWeight="bold"
            sx={(t) => ({ color: t.palette.text.primary })}
          >
            {t("components.events.event")}
          </Typography>

          <Button
            size="small"
            color="inherit"
            startIcon={<ArrowBackIcon />}
            onClick={goToList}
            sx={(t) => ({
              color: t.palette.text.primary,
            })}
          >
            {t(translationPath + "back")}
          </Button>
        </>
      )}

      {mode === "list" && (
        <Button
          variant="contained"
          size="small"
          color="inherit"
          startIcon={<AddIcon />}
          onClick={goToCreate}
          disabled={isDisabled}
          sx={(t) => ({
            color: t.palette.text.primary,
          })}
        >
          {t(translationPath + "add_event")}
        </Button>
      )}
    </Box>
  );
});
