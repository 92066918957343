import { QPFQuickRegisterForm, useFormContext } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { useWatch } from "react-hook-form";
import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { TCardForm } from "./AddCardSchema";

const RequestSelector = () => {
  const { control } = useFormContext<TCardForm>();

  const { t } = useTranslation();

  const clientType = useWatch({
    name: "clientType",
    control,
  });

  const informRequester = useWatch({
    name: "informRequest",
    control,
  });

  const disabled = useMemo(
    () => clientType === "pf" && !informRequester,
    [clientType, informRequester]
  );

  return (
    <Grid item xs={12} sm={12}>
      <QPFQuickRegisterForm
        name={"requester"}
        disabled={disabled}
        label={t("page.board.createCard.requester")}
      />
    </Grid>
  );
};

export default memo(RequestSelector);
