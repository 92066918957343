import { AutoCompleteUncontrolled } from "@4uhub/lib4uhub";
import { useAdvancedSearchContext } from "../../../store/context/advanced-search-context";
import { workspaceList } from "../../../services/workspace.service";
import { useParams } from "react-router-dom";
import useWorkspace from "../../../hooks/useWorkspace";
import { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";

const translationPath = "components.advancedSearchFields.";

const WorkspaceSelect = () => {
  const { t } = useTranslation();

  const { workspace, onChangeWorkspace, onChangeCreatedBy } =
    useAdvancedSearchContext();

  const { workspaceId } = useParams();

  const { workspaces } = useWorkspace();

  useEffect(() => {
    if (workspaceId) {
      onChangeWorkspace(workspaces.find((w) => w.id === workspaceId) ?? null);
    }
  }, [workspaceId, workspaces, onChangeWorkspace]);

  return (
    <AutoCompleteUncontrolled
      label={t(translationPath + "workspace")}
      value={workspace}
      onValueSelected={(value) => {
        onChangeWorkspace(value);
        onChangeCreatedBy([]);
      }}
      clearable={false}
      size="small"
      fullWidth
      service={workspaceList}
    />
  );
};

export default memo(WorkspaceSelect);
