import { useForm } from "react-hook-form";
import { Box, Popover, PopoverProps, Stack, Typography } from "@mui/material";
import { Button, Form, FormInput, useFetch } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { zodResolver } from "@hookform/resolvers/zod";

import { TChecklistForm, checklistSchema } from "./ChecklistSchema";
import TicketChecklistService from "../../../services/ticketChecklist.service";
import useChecklists from "../../../hooks/useChecklists";

const translationPath = "components.createChecklist.";

const ticketChecklistService = new TicketChecklistService();

interface ICheckListPopoverProps extends PopoverProps {
  ticketId: string;
}

const ChecklistPopover: React.FC<ICheckListPopoverProps> = ({
  ticketId,
  ...props
}) => {
  const { onClose } = props;

  const { t } = useTranslation();

  const { checklists } = useChecklists();

  const methods = useForm<TChecklistForm>({
    resolver: zodResolver(checklistSchema),
  });

  const { sendRequest } = useFetch(ticketChecklistService.createChecklist);

  const addChecklistHandler = useCallback(
    async (v: TChecklistForm) => {
      if (ticketId) {
        const { data, success } = await sendRequest({
          name: v.name,
          ticketId: ticketId,
          displayOrder:
            checklists.length === 0
              ? 1
              : checklists[checklists.length - 1].displayOrder + 1,
        });
        if (data && success) {
          if (onClose) onClose(() => {}, "escapeKeyDown");
        }
      }
    },
    [sendRequest, onClose, ticketId, checklists]
  );

  const submitHandler = (v: TChecklistForm) => {
    addChecklistHandler(v);
    methods.reset({ name: "" });
  };

  return (
    <Popover
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      sx={{ mt: 1 }}
      {...props}
    >
      <Form {...methods}>
        <Box component="form" onSubmit={methods.handleSubmit(submitHandler)}>
          <Stack p={1} minWidth={250} gap={1}>
            <Typography mb={1}>{t(translationPath + "checklist")}</Typography>

            <FormInput
              name="name"
              label={t(translationPath + "name")}
              size="small"
              fullWidth
              autoFocus
            />

            <Button
              variant="contained"
              type="submit"
              disabled={!methods.formState.isValid}
            >
              {t(translationPath + "create_checklist_button")}
            </Button>
          </Stack>
        </Box>
      </Form>
    </Popover>
  );
};

export default ChecklistPopover;
