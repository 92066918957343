import { useTranslation } from "react-i18next";
import { Loading, useRole } from "@4uhub/lib4uhub";
import { memo } from "react";

import MenuSection from "../../UI/MenuSection";
import BoardAdd from "../AddBoard/BoardAdd";
import MenuBoard from "./MenuBoard";
import { useWorkspaceContext } from "../../../store/context/workspace-context";

const translationPath = "components.menuBoards.";

const ViewBoards: React.FC = () => {
  const { viewboards, loading: boardsLoading } = useWorkspaceContext();

  const createPermission = useRole(["Create"]);

  const { t } = useTranslation();

  return (
    <MenuSection
      title={t(translationPath + "view_boards")}
      rightContent={createPermission ? <BoardAdd isView={true} /> : <></>}
    >
      {!boardsLoading &&
        viewboards.map((b) => <MenuBoard name={b.name} to={b.id} key={b.id} />)}
      {boardsLoading && <Loading sx={{ position: "relative" }} size={30} />}
    </MenuSection>
  );
};

export default memo(ViewBoards);
