import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import { Box, IconButton, Popover, Tooltip, useTheme } from "@mui/material";
import { memo, useState } from "react";
import Picker from "@emoji-mart/react";
import { useTranslation } from "react-i18next";
import data from "@emoji-mart/data";

export type Emoji = {
  id: string;
  keywords: string[];
  name: string;
  native: string;
  shortcodes: string[];
  unified: string;
};

interface IEmojiPickerComponentProps {
  disabled?: boolean;
  onEmojiClick: (emoji: Emoji) => any;
  onEmojiOpen: () => any;
}

const formatLanguage = (l: string) => {
  if (l === "pt-BR") return "pt";

  return l;
};

const EmojiPickerComponent: React.FC<IEmojiPickerComponentProps> = ({
  disabled = false,
  onEmojiClick,
  onEmojiOpen,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { palette } = useTheme();

  const { t } = useTranslation();

  const { i18n } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    onEmojiOpen();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const id = open ? "emoji-picker-popper" : undefined;

  return (
    <>
      <Tooltip title={t("page.card.chat.emoji")}>
        <>
          <IconButton
            aria-describedby={id}
            disabled={disabled}
            onClick={handleClick}
          >
            <EmojiEmotionsIcon />
          </IconButton>
        </>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handleClose}
      >
        <Box width={352} height={435}>
          <Picker
            data={data}
            theme={palette.mode}
            locale={formatLanguage(i18n.language)}
            onEmojiSelect={onEmojiClick}
            previewPosition="none"
          />
        </Box>
      </Popover>
    </>
  );
};

export default memo(EmojiPickerComponent);
