import { useFetch } from "@4uhub/lib4uhub";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Collapse, Stack, Typography } from "@mui/material";

import useFlow from "../../hooks/useFlow";
import ExpandMore from "./ExpandMore";
import OpenedTickets from "./OpenedTickets";

import { IGetTicket } from "../../models/ticket";
import { getOpenedTickets } from "../../services/ticket.service";
import TicketsFilterSelect from "./TicketsFilterSelect";

const CardsList: React.FC = () => {
  const { sendRequest } = useFetch(getOpenedTickets);

  const { t } = useTranslation();

  const {
    expanded,
    openedTickets,
    boardId,
    openTicket,
    switchExpand,
    setOpenedTickets,
  } = useFlow();

  const fetch = useCallback(async () => {
    const { success, data } = await sendRequest({ TicketChannelId: boardId });

    if (success && data) {
      setOpenedTickets(data);
    }
  }, [sendRequest, setOpenedTickets, boardId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const handleMove = useCallback(
    (ticket: IGetTicket) => {
      openTicket(ticket.id);
    },
    [openTicket]
  );

  const count = useMemo(() => openedTickets?.length, [openedTickets]);

  return (
    <Stack
      sx={(t) => ({
        padding: t.spacing(1),
        borderRadius: t.shape.borderRadius / 4,
        maxHeight: "50%",
        backgroundColor: t.palette.grey[t.palette.mode === "light" ? 200 : 900],
      })}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography
          variant="h6"
          sx={(t) => ({ color: t.palette.text.primary })}
        >
          {t("page.card.flow.openTickets")}
        </Typography>

        <Stack alignItems={"center"} direction={"row"} gap={1}>
          <Typography
            variant="body2"
            color={(t) => t.palette.text.primary}
            sx={(t) => ({
              width: 30,
              height: 30,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              border: 1,
              borderColor: t.palette.divider,
              borderRadius: 15,
              transition: "ease-in-out 0.2s",
              "&:hover": {
                color: t.palette.text.primary,
                backgroundColor: t.palette.divider,
              },
            })}
          >
            {count}
          </Typography>

          <ExpandMore
            expand={expanded}
            onClick={switchExpand}
            aria-expanded={expanded}
            aria-label="show more"
            sx={(t) => ({
              color: t.palette.text.primary,
            })}
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Stack>
      </Stack>

      {expanded && <TicketsFilterSelect />}

      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        sx={(t) => ({
          overflow: "auto",
          "&::-webkit-scrollbar": {
            width: "0.4em",
            marginLeft: 20,
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            backgroundColor: t.palette.grey[400],
            borderRadius: 5,
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: t.palette.grey[300],
            borderRadius: 5,
          },
          paddingRight: t.spacing(1),
        })}
      >
        <OpenedTickets handleMove={handleMove} />
      </Collapse>
    </Stack>
  );
};

export default CardsList;
