import { useForm } from "react-hook-form";
import { Form, Button, AutoComplete } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";
import { Popover, PopoverProps, Stack, Typography } from "@mui/material";
import { memo, useCallback, useEffect, useRef } from "react";
import { zodResolver } from "@hookform/resolvers/zod";

import { TEditBoardParent, editBoardParentSchema } from "./EditParentSchema";
import { useWorkspaceContext } from "../../../../../store/context/workspace-context";

interface IBoardPopOver {
  onSubmit: (data: TEditBoardParent) => void;
  loading?: boolean;
  open: boolean;
  anchorEl: Element | null;
  id?: string;
  onClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
  popoverProps?: Partial<PopoverProps>;
  isView?: boolean;
  isGeneric?: boolean;
}

const EditParentPopover: React.FC<IBoardPopOver> = ({
  onSubmit,
  loading,
  anchorEl,
  open,
  onClose,
  id,
  popoverProps,
}) => {
  const methods = useForm<TEditBoardParent>({
    resolver: zodResolver(editBoardParentSchema),
  });

  const { t } = useTranslation();

  const ref = useRef<HTMLInputElement>();

  const { viewboards } = useWorkspaceContext();
  
  const focusInInput = useCallback(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [ref]);

  useEffect(() => {
    setTimeout(focusInInput, 100);
  }, [focusInInput]);

  const handleSubmit = () => {
    methods.handleSubmit((t) => {
      onSubmit(t);
      methods.reset();
    })();
  };

  return (
    <>
      <Popover
        id={id}
        open={open}
        onClose={onClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        {...popoverProps}
      >
        <Form {...methods}>
          <Stack
            sx={(t) => ({
              padding: t.spacing(1),
              gap: t.spacing(2),
              minWidth: 200,
            })}
          >
            <Typography
              style={{
                textAlign: "center",
              }}
            >
              {t("components.boards.settingsMenu.selectParent")}
            </Typography>
            <AutoComplete
              getOptionLabel={(option) => {
                return option.name;
              }}
              size="small"
              options={viewboards}
              label={t("components.boards.form.parent")}
              name="parent"
            />
            <Button
              variant="contained"
              disabled={!methods.formState.isValid}
              loading={loading}
              onClick={handleSubmit}
            >
              {t("components.boards.settingsMenu.select")}
            </Button>
          </Stack>
        </Form>
      </Popover>
    </>
  );
};

export default memo(EditParentPopover);
