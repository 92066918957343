import { ptBR, enUS, esES } from "@mui/x-data-grid/locales";

export const getLocaleText = (language: string) => {
    switch (language) {
      case "pt-BR":
        return ptBR.components.MuiDataGrid.defaultProps.localeText;
      case "en-US":
        return esES.components.MuiDataGrid.defaultProps.localeText;
      case "es":
        return enUS.components.MuiDataGrid.defaultProps.localeText;
    }
  };
  
  