import { Stack, Typography, useTheme } from "@mui/material";

import { ISelectType } from "../../../../models/selects";

import { motion } from "framer-motion";
import { memo, useMemo, useState } from "react";

interface IStatusProps {
  data: ISelectType;
  expandable?: boolean;
}

const Status: React.FC<IStatusProps> = ({ data, expandable = false }) => {
  const [show, setShow] = useState(expandable ? false : true);

  const { palette } = useTheme();

  let backgroundColor = "";

  switch (data?.code) {
    case "1":
      backgroundColor = palette.success.light;
      break;
    case "2":
      backgroundColor = palette.warning.light;
      break;
    case "3":
      backgroundColor = palette.error.light;
      break;
  }

  const motionProps = useMemo(
    () => ({
      whileHover: {
        width: "auto",
        height: "auto",
        transition: { duration: 0.2 },
      },
      onHoverStart: () => setShow(true),
      onHoverEnd: () => setShow(false),
      style: {
        backgroundColor: backgroundColor,
        opacity: 1,
        scale: 1,
        width: 15,
        height: 15,
        overflow: "hidden",
        borderRadius: 10,
      },
    }),
    [backgroundColor]
  );

  return (
    <motion.div
      {...(expandable
        ? motionProps
        : { style: { backgroundColor: backgroundColor, borderRadius: 10 } })}
    >
      <Stack
        padding={0.7}
        width={"100%"}
        overflow={"hidden"}
        paddingX={1.4}
        sx={(t) => {
          return {
            backgroundColor: backgroundColor,
            borderRadius: 1,
            width: "fit-content",
          };
        }}
      >
        <Typography
          variant="body2"
          color={(t) => t.palette.common.black}
          fontSize={expandable ? 12 : 14}
          sx={{ opacity: 0.9 }}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            wordBreak: "keep-all",
            visibility: show ? "visible" : "hidden",
          }}
        >
          {data?.name}
        </Typography>
      </Stack>
    </motion.div>
  );
};

export default memo(Status);
