import { Box, FormHelperText } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useAdvancedSearchContext } from "../../../store/context/advanced-search-context";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ptBR } from "@mui/x-date-pickers/locales";
import { enUS } from "@mui/x-date-pickers/locales";
import { esES } from "@mui/x-date-pickers/locales";
import { ptBR as portuguese } from "date-fns/locale";
import { enUS as english } from "date-fns/locale";
import { es as spanish } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import { memo, useCallback, useEffect } from "react";

const translationPath = "components.advancedSearchFields.";

const SearchFilterRange = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { startDate, endDate, onChangeStartDate, onChangeEndDate } =
    useAdvancedSearchContext();

  const adapterLocale =
    language === "pt-BR"
      ? portuguese
      : language === "en-US"
      ? english
      : spanish;

  const maxDate = useCallback(() => {
    const date = new Date(JSON.parse(JSON.stringify(startDate))) ?? new Date();
    date.setDate(date.getDate() + 180);
    return date;
  }, [startDate]);

  useEffect(() => {
    if (endDate && endDate > maxDate()) {
      onChangeEndDate(null);
    }
  }, [endDate, maxDate, onChangeEndDate]);

  return (
    <Box display={"flex"} flexDirection={"column"} gap={1}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={adapterLocale}
      >
        <DatePicker
          label={t(translationPath + "start_date")}
          value={startDate ? new Date(startDate) : null}
          onChange={(v) => onChangeStartDate(v)}
          format="dd/MM/yyyy"
          disableFuture
          localeText={
            language === "pt-BR"
              ? ptBR.components.MuiLocalizationProvider.defaultProps.localeText
              : language === "en-US"
              ? enUS.components.MuiLocalizationProvider.defaultProps.localeText
              : esES.components.MuiLocalizationProvider.defaultProps.localeText
          }
          slotProps={{
            textField: {
              size: "small",
            },
            field: {
              clearable: true,
            },
          }}
        />
      </LocalizationProvider>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={adapterLocale}
      >
        <DatePicker
          label={t(translationPath + "end_date")}
          value={endDate ? new Date(endDate) : null}
          onChange={(v) => onChangeEndDate(v)}
          format="dd/MM/yyyy"
          minDate={startDate ?? null}
          maxDate={(() => maxDate())()}
          localeText={
            language === "pt-BR"
              ? ptBR.components.MuiLocalizationProvider.defaultProps.localeText
              : language === "en-US"
              ? enUS.components.MuiLocalizationProvider.defaultProps.localeText
              : esES.components.MuiLocalizationProvider.defaultProps.localeText
          }
          slotProps={{
            textField: {
              size: "small",
            },
            field: {
              clearable: true,
            },
          }}
        />
        <FormHelperText
          sx={(t) => ({
            color:
              t.palette.mode === "light"
                ? t.palette.grey["600"]
                : t.palette.grey["400"],
            marginLeft: t.spacing(2),
            marginTop: 0,
          })}
        >
          {t(translationPath + "errors.range")}
        </FormHelperText>
      </LocalizationProvider>
    </Box>
  );
};

export default memo(SearchFilterRange);
