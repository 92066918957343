import { useContext, useMemo } from "react";
import { useTheme } from "@mui/material";

import { ColumnContext } from "../store/context/column-context";

const useColumn = () => {
  const {
    palette: { getContrastText },
  } = useTheme();

  const column = useContext(ColumnContext);

  if (!column) {
    throw new Error("UseColumn must be used with ColumnProvider");
  }

  const {
    data: { backgroundColor },
  } = column;

  const contrastColor = useMemo(
    () => backgroundColor && getContrastText(backgroundColor),
    [backgroundColor, getContrastText]
  );

  return {
    ...column,
    contrastColor,
  };
};

export default useColumn;
