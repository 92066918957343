import { useCallback, useContext } from "react";

import { FlowContext } from "../store/context/FlowContext/flow-context";

const useFlow = () => {
  const flow = useContext(FlowContext);

  if(!flow){
    throw new Error("useFlor must be used with FlowProvider")
  }
  
  const { selectedTicket } = flow;

  const isSelected = useCallback((id: string) => {
    return selectedTicket=== id;
  }, [selectedTicket]);

  return {
    ...flow,
    isSelected,
  };
};

export default useFlow;
