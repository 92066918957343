import { Typography } from "@mui/material";
import { MouseEventHandler, memo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

interface ISeeMoreButtonProps {
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

const SeeMoreButton: React.FC<ISeeMoreButtonProps> = ({ onClick }) => {
  const { eI } = useParams();

  const { t } = useTranslation();

  return (
    <Link to={`/e/${eI}`} style={{ textDecoration: "none" }} onClick={onClick}>
      <Typography variant="caption" color={(t) => t.palette.primary.main}>
        {t("components.workspace.see_more")}
      </Typography>
    </Link>
  );
};

export default memo(SeeMoreButton);
