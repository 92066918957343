import { useProtocol } from "../../../../../store/context/protocol-context";
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const DetailBack = () => {
  const { selectProtocolHandler } = useProtocol();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  if (!matches) return null;

  return (
    <Box>
      <Button
        startIcon={<ChevronLeftIcon />}
        onClick={() => selectProtocolHandler(null)}
      >
        Voltar
      </Button>
    </Box>
  );
};

export default DetailBack;
