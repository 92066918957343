import { Stack, Typography } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";

import AlreadyParticipated from "./AlreadyParticipated";

const HistoryFilters = () => {
  const { t } = useTranslation();
  
  return (
    <Stack width={"100%"}>
      <Typography variant="subtitle2">
        {t("components.boards.filters.history")}
      </Typography>
      <AlreadyParticipated />
    </Stack>
  );
};

export default memo(HistoryFilters);
