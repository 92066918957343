import { useNavigate, useParams } from "react-router-dom";
import HoverOpacity from "../../UI/HoverOpacity";
import { memo } from "react";

const Logo = () => {
  const navigate = useNavigate();

  const { eI } = useParams();
  
  return (
    <HoverOpacity>
      <img
        style={{
          margin: 10,
          marginTop: 20,
        }}
        src="/4uCRM.png"
        width={"120px"}
        height={"auto"}
        alt="4uhubu mini icon"
        onClick={() => navigate(`/e/${eI}`)}
      ></img>
    </HoverOpacity>
  );
};

export default memo(Logo)