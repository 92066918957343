import { memo, useState } from "react";
import { useTranslation } from "react-i18next";

import ArchivedBoards from "../../Board/ArchivedBoards/ArchivedBoards";
import { Button } from "@4uhub/lib4uhub";

const ClosedBoards = () => {
  const [open, setOpen] = useState(false);
  
  const { t } = useTranslation();

  return (
    <>
      <Button
        sx={{ alignSelf: "start", marginTop: 2.5 }}
        variant="contained"
        color="inherit"
        onClick={() => setOpen(true)}
      >
        {t("page.workspace.archived.button")}
      </Button>

      <ArchivedBoards open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default memo(ClosedBoards);
