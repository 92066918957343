import { Form, FormInput } from "@4uhub/lib4uhub";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Typography } from "@mui/material";
import { memo, useCallback } from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getEIFromUrl } from "../../../../services/establishment.service";
import AdvancedSearchActions from "./AdvancedSearchActions";
import useWorkspace from "../../../../hooks/useWorkspace";

const translationPath = "components.advancedSearchHeader.";

const advancedSearchSchema = z.object({
  search: z.string().min(1, translationPath + "errors.protocol_or_id"),
});

export type TAdvancedSearchForm = z.infer<typeof advancedSearchSchema>;

const AdvancedSearchForm = () => {
  const { t } = useTranslation();

  const { actualWorkspace } = useWorkspace();

  const eI = getEIFromUrl();

  const navigate = useNavigate();

  const startDate = new Date();
  startDate.setMonth(new Date().getMonth() - 6);

  const methods = useForm<TAdvancedSearchForm>({
    resolver: zodResolver(advancedSearchSchema),
  });

  const submitHandler = useCallback(
    (form: TAdvancedSearchForm) => {
      if (!actualWorkspace) return;
      navigate(`/e/${eI}/search/${actualWorkspace.id}/${form.search}`);
    },
    [navigate, eI, actualWorkspace]
  );

  return (
    <Box p={2}>
      <Typography>{t(translationPath + "search")}</Typography>
      <Form {...methods}>
        <Box
          mt={1}
          component="form"
          onSubmit={methods.handleSubmit(submitHandler)}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <FormInput
              name="search"
              label={t(translationPath + "protocol_or_id")}
              size="small"
              fullWidth
            />
          </Box>
          <AdvancedSearchActions />
        </Box>
      </Form>
    </Box>
  );
};

export default memo(AdvancedSearchForm);
