import { Button, useFormContext } from "@4uhub/lib4uhub";
import { Box, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getEIFromUrl } from "../../../../services/establishment.service";
import { TAdvancedSearchForm } from "./AdvancedSearchForm";
import { useWatch } from "react-hook-form";
import useWorkspace from "../../../../hooks/useWorkspace";
import { memo } from "react";

const translationPath = "components.advancedSearchHeader.";

const AdvancedSearchActions = () => {
  const { t } = useTranslation();

  const { control } = useFormContext<TAdvancedSearchForm>();

  const search = useWatch<TAdvancedSearchForm>({
    name: "search",
    control,
  });

  const eI = getEIFromUrl();

  const navigate = useNavigate();

  const { actualWorkspace } = useWorkspace();

  const advancedSearchHandler = () => {
    navigate(`/e/${eI}/search/${actualWorkspace?.id}`);
  };

  return (
    <Box
      mt={1}
      width={"100%"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      gap={0.5}
    >
      <Button
        type="submit"
        variant="contained"
        size="small"
        fullWidth
        disabled={!search}
      >
        {t(translationPath + "button")}
      </Button>
      <Link
        variant="subtitle2"
        sx={{ cursor: "pointer" }}
        onClick={advancedSearchHandler}
      >
        {t(translationPath + "advanced_search")}
      </Link>
    </Box>
  );
};

export default memo(AdvancedSearchActions);
