import { useCallback } from "react";
import { Stack, Tooltip, Typography } from "@mui/material";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import { useFetch } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";

import {
  disableColumnById,
  unarchiveColumnById,
} from "../../../../../services/column.serivice";
import IconButton from "../../../../UI/IconButton";
import DeleteColumn from "./DeleteColumn";
import { IArchivedColumn } from "../../../../../store/context/board-context";

interface IArchivedColumnProps {
  canUnarchive?: boolean;
  canDelete?: boolean;
}

const ArchivedColumn: React.FC<IArchivedColumn & IArchivedColumnProps> = ({
  canUnarchive,
  canDelete,
  ...otherProps
}) => {
  const { id, name } = otherProps;

  const { sendRequest, loading } = useFetch(unarchiveColumnById);

  const { t } = useTranslation();

  const { sendRequest: deleteRequest, loading: deleteLoadin } =
    useFetch(disableColumnById);

  const unarchiveColumn = useCallback(async () => {
    sendRequest(id);
  }, [sendRequest, id]);

  const deleteColumn = useCallback(() => {
    deleteRequest(id);
  }, [deleteRequest,id]);

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Typography
        sx={{
          width: "70%",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {name}
      </Typography>
      <Stack direction={"row"} alignItems={"center"} gap={1} width={"30%"}>
        {canUnarchive && (
          <IconButton onClick={unarchiveColumn} loading={loading}>
            <Tooltip
              title={t("components.boards.settingsMenu.archived.unarchive")}
            >
              <UnarchiveIcon />
            </Tooltip>
          </IconButton>
        )}

        {canDelete && (
          <DeleteColumn
            loading={deleteLoadin}
            onDelete={deleteColumn}
            reference={name}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default ArchivedColumn;
