import { Loading } from "@4uhub/lib4uhub";
import { Stack } from "@mui/material";
import { memo, useCallback, useEffect, useRef } from "react";

import { useChat } from "../../store/context/chat-context";
import Message from "./Message/Message";

const MessageBox = ({ modalMode = false }: { modalMode?: boolean }) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const { messages, loading } = useChat();

  const scrolltoBottom = useCallback(() => {
    const scroll = () => messagesEndRef.current?.scrollIntoView();

    if (messages) {
      if (messages[messages.length - 1]?.ticketDialogMediaFiles.length > 0) {
        setTimeout(scroll, 1000);
      } else {
        scroll();
      }
    }
  }, [messages]);

  useEffect(() => {
    scrolltoBottom();
  }, [scrolltoBottom]);

  return (
    <>
      {loading && (
        <Loading
          sx={{ position: "absolute", left: 0, right: 0, bottom: 0 }}
          size={40}
        />
      )}
      <Stack
        height={"100%"}
        width={"100%"}
        p={2}
        overflow={"auto"}
        rowGap={0.5}
        sx={(t) => ({
          "&::-webkit-scrollbar": {
            width: "0.4em",
            marginLeft: 20,
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            backgroundColor: t.palette.grey[400],
            borderRadius: 5,
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: t.palette.grey[300],
            borderRadius: 5,
          },
          paddingRight: t.spacing(1),
          backgroundColor: modalMode
            ? t.palette.grey[t.palette.mode === "light" ? 100 : 900]
            : undefined,
          borderRadius: modalMode ? 2 : 0,
          position: "relative",
        })}
      >
        {messages?.map((m, i) => {
          return <Message key={i} {...m} />;
        })}
        <div ref={messagesEndRef} />
      </Stack>
    </>
  );
};

export default memo(MessageBox);
