import { AutoComplete } from "@4uhub/lib4uhub";
import { getGenericList } from "../../../../services/generic.service";

const SelectStatus = () => {
  return (
    <AutoComplete
      params={{ identifier: "TicketStatus" }}
      name={"status"}
      label={"Status"}
      getOptionLabel={(option) => {
        return option.name || option.value || "";
      }}
      size="small"
      fullWidth
      request={getGenericList}
    />
  );
};
export default SelectStatus;
