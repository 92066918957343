import {
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import { FocusEventHandler, memo, useEffect, useState } from "react";

interface IEditableTypographyProps {
  onEdit: (value: string) => void;
  value: string;
  typographyProps?: TypographyProps;
  textfieldProps?: TextFieldProps;
  disabled?: boolean;
  validation?: (text: string) => boolean;
}

const EditableTypography: React.FC<IEditableTypographyProps> = ({
  onEdit,
  value,
  textfieldProps,
  typographyProps,
  disabled,
  validation,
}) => {
  const [editMode, setEditMode] = useState(false);

  const [inputed, setInputed] = useState(false);

  const [title, setTitle] = useState<string>("");

  useEffect(() => {
    if (editMode) {
      setTitle(value);
    }
  }, [value, editMode]);

  const handleEdit = async (v: string) => {
    if (v !== value) {
      onEdit(v);
    }
  };

  const onEnterPress = () => {
    if (inputed) {
      handleEdit(title);
      setEditMode(false);
    }
  };

  useEffect(() => {
    if (disabled) {
      setEditMode(false);
    }
  }, [disabled]);

  const handleBlur: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    textfieldProps?.onBlur && textfieldProps?.onBlur(e);
    setEditMode(false);
    if (inputed) {
      handleEdit(title);
    }
  };

  const handleInput = () => {
    setInputed(true);
  };

  const handleClick = () => {
    if (!disabled) {
      setEditMode(true);
    }
  };

  return (
    <>
      {editMode && !disabled && (
        <TextField
          {...textfieldProps}
          autoFocus
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setTitle(event.target.value);
          }}
          value={title}
          onInput={handleInput}
          onBlur={handleBlur}
          onKeyDown={(ev) => {
            if (ev.key === "Enter") {
              onEnterPress();
              ev.preventDefault();
            }
          }}
          fullWidth
          size="small"
        ></TextField>
      )}
      {!editMode && (
        <Typography
          fontWeight={"bold"}
          onClick={handleClick}
          variant="body2"
          {...typographyProps}
        >
          {value}
        </Typography>
      )}
    </>
  );
};

export default memo(EditableTypography);
