import {
  createContext,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { IMember } from "../../models/members";

type TMembersContext = {
  members: IMember[];
  loading?: boolean;
  loadMembers: (allMembers: IMember[]) => void;
  addMember: (member: IMember) => void;
  updateMember: ({ member, id }: { member: IMember; id: string }) => void;
  removeMember: (id: string) => void;
};

export const MembersContext = createContext<TMembersContext>({
  members: [],
  loading: false,
  loadMembers: () => {},
  addMember: () => {},
  updateMember: () => {},
  removeMember: () => {},
});

interface IMembersProvider {
  children: any;
}

const MembersProvider: React.FC<IMembersProvider> = ({ children }) => {
  const [members, setMembers] = useState<IMember[]>([]);

  const loadMembers = useCallback((allMembers: IMember[]) => {
    setMembers(allMembers);
  }, []);

  const updateMember = useCallback(
    ({ member, id }: { member: IMember; id: string }) => {
      setMembers((old) =>
        old.map((b) => {
          if (b.id === id) {
            return { ...b, ...member };
          }
          return b;
        })
      );
    },
    []
  );

  const removeMember = useCallback((id: string) => {
    setMembers((old) => old.filter((o) => o.id !== id));
  }, []);

  const addMember = useCallback((member: IMember) => {
    setMembers((old) => [...old, member]);
  }, []);

  const value = useMemo(
    () => ({
      members,
      loadMembers,
      addMember,
      updateMember,
      removeMember,
    }),
    [members, loadMembers, addMember, updateMember, removeMember]
  );

  return (
    <MembersContext.Provider value={value}>{children}</MembersContext.Provider>
  );
};

export const useMembersContext = () => {
  return useContext(MembersContext);
};

export default memo(MembersProvider);
