import { Stack } from "@mui/material";
import { DroppableProvidedProps } from "react-beautiful-dnd";
import { ReactNode, memo } from "react";

import CardChecklistContent from "./CardChecklistContent";
import useChecklists from "../../../../hooks/useChecklists";

interface ICardChecklistProps {
  droppableProps: DroppableProvidedProps;
  checklistRef: (element: HTMLElement | null) => void;
  placeholder: ReactNode;
}

const CardChecklist = ({
  droppableProps,
  checklistRef,
  placeholder,
}: ICardChecklistProps) => {
  const { checklists } = useChecklists();

  return (
    <Stack
      {...droppableProps}
      ref={checklistRef}
      sx={{
        width: "100%",
      }}
    >
      {checklists.map((checklist, index) => (
        <CardChecklistContent
          checklist={checklist}
          index={index}
          key={checklist.id}
        />
      ))}
      {placeholder}
    </Stack>
  );
};

export default memo(CardChecklist);
