import { ILanguageType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";

const BASE_URL = process.env.REACT_APP_MAIN;

const LANGUAGES_ROUTE = BASE_URL + "/api/v1/Language/";

export const listLanguages = () => {
  return GlobalAxios.get<ILanguageType[]>(LANGUAGES_ROUTE + "list");
};
