import { IconButton, Tooltip } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useFilePicker } from "use-file-picker";
import { Loading, useFetch } from "@4uhub/lib4uhub";
import { FileContent } from "use-file-picker/dist/interfaces";
import { memo } from "react";
import { useTranslation } from "react-i18next";

import { uploadFile } from "../../../services/fileManager.service";
import useMessageInput from "../../../hooks/useMessageInput";

interface Props {
  plainFiles: File[];
  filesContent: FileContent<string>[];
}

interface IFilePickerProps {
  disabled?: boolean;
}

const FilePicker = ({ disabled = false }: IFilePickerProps) => {
  const { setFiles, loading, setLoading } = useMessageInput();

  const { t } = useTranslation();

  const { sendRequest } = useFetch(uploadFile);

  const { openFilePicker } = useFilePicker({
    multiple: true,
    onFilesSelected: () => {
      setLoading(true);
    },
    onFilesSuccessfullySelected: async ({ plainFiles }: Props) => {
      // this callback is called when there were no validation errors
      await Promise.all(
        plainFiles.map(async (f) => {
          const { data, success } = await sendRequest({
            File: f,
            type: "Private",
            ParentName: "TicketDialog",
            MediaTypeIdentifier: "SiteAppMediaType",
            MediaTypecode: "2",
          });
          if (data && success) {
            setFiles((files) => [...files, { file: f, id: data.id }]);
          }
        })
      );
      setLoading(false);
    },
  });

  if (loading) {
    return (
      <Loading
        sx={{ position: "relative", display: "inline-block", width: 20 }}
        size={20}
      />
    );
  }

  return (
    <Tooltip title={t("page.card.chat.file")}>
      <>
        <IconButton disabled={disabled} onClick={openFilePicker}>
          <AttachFileIcon sx={{ transform: "rotate(20deg)" }} />
        </IconButton>
      </>
    </Tooltip>
  );
};
export default memo(FilePicker);
