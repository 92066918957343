import { useNavigate } from "react-router-dom";
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  gridClasses,
} from "@mui/x-data-grid";
import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { GridInitialStateCommunity } from "@mui/x-data-grid/models/gridStateCommunity";
import { useFetch } from "@4uhub/lib4uhub";

import CustomFooter from "./CustomFooter";
import Status from "../CardPage/CardOtherInfo/Status/Status";
import AvatarStack from "../Board/Header/AvatarStack";
import TableTags from "./TableTags/TableTags";
import { formatTime } from "../Kamban/Task/TaskHeader";
import { IGetTableTicket } from "../../models/ticket";
import { getTicketsByTicketChannelId } from "../../services/ticket.service";
import CellTypografy from "./CellTypography";
import { filterTickets } from "./filter";
import { getLocaleText } from "./locale";
import { ITableProps } from "./model";
import useSignalRTable from "./useSignalRTable";

const Table: React.FC<ITableProps> = ({
  boardId,
  filter,
  onCardsLengthChange,
}) => {
  const [rows, setRows] = useState<IGetTableTicket[]>([]);

  const { endDate, initialDate, status } = filter;

  const { loading, sendRequest } = useFetch(getTicketsByTicketChannelId);

  const navigate = useNavigate();

  const {
    i18n: { language },
    t,
  } = useTranslation();

  const getRows = useCallback(async () => {
    const { data, success } = await sendRequest({
      Id: boardId,
      TicketDateFilterCode: status || undefined,
      FinishDate: endDate || undefined,
      StartDate: initialDate || undefined,
    });

    if (success && data) {
      setRows(data);
      onCardsLengthChange(data.length);
    }
  }, [boardId, status, endDate, initialDate, sendRequest, onCardsLengthChange]);

  useEffect(() => {
    getRows();
  }, [getRows]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "protocolNumber",
        flex: 1.5,
        minWidth: 100,
        headerName: t("components.table.protocolNumber") + "",
        renderCell: ({ value }) => <CellTypografy value={"#" + value} />,
      },
      {
        field: "ticketService",
        minWidth: 300,
        headerName: t("components.table.ticketService") + "",
        valueGetter: (params) => {
          const ticketService =
            params.row?.ticketService?.name;
          return ticketService;
        },
        renderCell: ({ value }) => <CellTypografy value={value} />,
      },
      {
        field: "title",
        minWidth: 200,
        headerName: t("components.table.ticket") + "",
        flex: 1,
        renderCell: ({ value }) => <CellTypografy value={value} />,
      },
      {
        field: "ticketSequential",
        headerName: t("components.table.ticketSequential") + "",
        renderCell: ({ value }) => <CellTypografy value={value} />,
      },
      {
        field: "created",
        flex: 1.5,
        minWidth: 200,
        headerName: t("components.table.created") + "",
        valueGetter: (params) => {
          const time = formatTime(params.value, language);
          const date = new Date(params.value).toLocaleDateString(language);

          return `${time} ${date}`;
        },
        renderCell: ({ value }) => <CellTypografy value={value} />,
      },
      {
        field: "finishDate",
        flex: 1.5,
        minWidth: 200,
        headerName: t("components.table.finishDate") + "",
        valueGetter: (params) => {
          const time = formatTime(params.value, language);
          const date = new Date(params.value).toLocaleDateString(language);

          return params.value
            ? `${time} ${date}`
            : t("components.table.no_finish_date");
        },
        renderCell: ({ value }) => <CellTypografy value={value} />,
      },
      {
        field: "ticketChannelStep",
        valueGetter: (params) => params.value.name,
        headerName: t("components.table.column") + "",
        minWidth: 200,
        renderCell: ({ value }) => <CellTypografy value={value} />,
      },
      {
        field: "status",
        headerName: t("components.table.status") + "",
        flex: 1.5,
        minWidth: 200,
        renderCell: (params) => {
          if (!params.value) {
            return null;
          }
          return <Status data={params.value} />;
        },
      },
      {
        field: "unreadMessages",
        headerName: t("components.table.unreadMessages") + "",
        flex: 1.5,
        minWidth: 80,
      },
      {
        field: "individualRequester",
        headerName: t("components.table.individualRequester") + "",
        flex: 1,
        minWidth: 100,
        valueGetter: (params) => params.value?.name,
        renderCell: ({ value }) => <CellTypografy value={value} />,
      },
      {
        field: "individual",
        minWidth: 100,
        headerName: t("components.table.individual") + "",
        valueGetter: (params) => {
          const individual =
            params.row?.individualCustomer?.name ||
            params.row?.legalEntityCustomer?.corporateName ||
            params.row?.ticketOrigins[0]?.name;
          return individual;
        },
        renderCell: ({ value }) => <CellTypografy value={value} />,
      },

      {
        field: "ticketChannelTags",
        minWidth: 200,
        flex: 1.5,
        headerName: t("components.table.ticketChannelTags") + "",
        renderCell: (params) => {
          if (!params.value) {
            return null;
          }
          return <TableTags tags={params.value} wrap={true} />;
        },
      },
      {
        field: "ticketMembers",
        flex: 1,
        minWidth: 100,
        headerName: t("components.table.ticketMembers") + "",
        renderCell: (params) => {
          if (!params.value) {
            return null;
          }
          return (
            <AvatarStack
              members={params.value.map((user: any) => ({
                id: user.user.id,
                name: user.user.fullName,
              }))}
              max={4}
              size={30}
            />
          );
        },
      },
    ],
    [language, t]
  );

  const handleRowClick: GridEventListener<"rowClick"> = useCallback(
    ({ row }) => {
      navigate(`card/${row.id}`);
    },
    [navigate]
  );

  const localetext = useMemo(() => getLocaleText(language), [language]);

  const initialState: GridInitialStateCommunity = useMemo(
    () => ({
      sorting: {
        sortModel: [{ field: "number", sort: "asc" }],
      },
    }),
    []
  );

  const slots = useMemo(
    () => ({
      footer: CustomFooter,
      /**      row: (row) =>{
      console.log(row)
      return  <GridRow {...row}></GridRow>
    }*/
      noRowsOverlay: () => (
        <Stack
          width={"100%"}
          height={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <img
            src="/empty-box.png"
            width={"20%"}
            style={{ marginBottom: 20 }}
            alt="ilustration to represent empty list"
          ></img>
          <Typography>{t("components.table.empty")}</Typography>
        </Stack>
      ),
    }),
    [t]
  );

  const filteredRows = useMemo(
    () => filterTickets(rows, filter),
    [rows, filter]
  );

  //SignalR
  useSignalRTable({ setRows });

  return (
    <Box sx={{ height: "100%" }} pr={2} pb={2}>
      <DataGrid
        initialState={initialState}
        loading={loading}
        localeText={localetext}
        getRowHeight={() => "auto"}
        getRowId={(row) => {
          return row.id;
        }}
        sx={(theme) => ({
          height: "100%",
          width: "100%",
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor:
              theme.palette.mode === "dark"
                ? theme.palette.grey[900]
                : theme.palette.grey[200],
          },
        })}
        onRowClick={handleRowClick}
        rows={filteredRows}
        columns={columns}
        slots={slots}
      />
    </Box>
  );
};

export default memo(Table);
