import { Button } from "@4uhub/lib4uhub";
import { useAdvancedSearchContext } from "../../store/context/advanced-search-context";
import { Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { memo } from "react";

const translationPath = "components.advancedSearchViewMore.";

const ViewMoreButton = () => {
  const { t } = useTranslation();

  const { hasNextPage, onNextPage, loading } = useAdvancedSearchContext();

  const clickHandler = () => {
    onNextPage();
  };

  if (!hasNextPage) return null;

  return (
    <Box mt={1} width={"100%"} textAlign={"center"}>
      <Button
        onClick={clickHandler}
        loading={loading}
        startIcon={<ExpandMoreIcon />}
      >
        {t(translationPath + "button")}
      </Button>
    </Box>
  );
};

export default memo(ViewMoreButton);
