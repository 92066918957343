import { OverflowTooltip, stringToColor } from "@4uhub/lib4uhub";
import { Avatar, Stack } from "@mui/material";

const WorkspacesItem = ({
  name,
  onClick,
}: {
  name: string;
  onClick?: () => void;
}) => {

  const backgroundColor = stringToColor(name)

  return (
    <Stack
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      gap={1}
      sx={(theme) => ({
        paddingBlock: theme.spacing(1),
        paddingInline: theme.spacing(1),
        ...(onClick && {
          borderRadius: 1,
          transition: "all .2s ease-in-out",
          cursor: "pointer",
          "&:hover": {
            backgroundColor:
              theme.palette.mode === "light"
                ? theme.palette.grey[200]
                : theme.palette.grey[800],
          },
        }),
      })}
      onClick={onClick}
    >
      <Avatar
        sx={t => ({
          width: 30,
          height: 30,
          backgroundImage: `linear-gradient(180deg, ${backgroundColor},${
            t.palette.augmentColor({
              color: {
                main: backgroundColor,
              },
            }).light
          })`,
          color: t.palette.getContrastText(stringToColor(name)),
          borderRadius: 1,
        })}
      >
        {name.substring(0, 1)}
      </Avatar>
      <OverflowTooltip text={name} tooltip={name} />
    </Stack>
  );
};

export default WorkspacesItem;
