import { AutoComplete, ISelectType, useFetch, useFormContext } from "@4uhub/lib4uhub";
import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

import { TCardForm } from "./AddCardSchema";
import { getGenericList } from "../../../../../services/generic.service";

const TypeSelector = memo(() => {
    const { setValue } = useFormContext<TCardForm>();
  
    const { t } = useTranslation();
  
    const [otps, setOtpions] = useState<ISelectType[]>([]);
  
    const { sendRequest } = useFetch(getGenericList);
  
    const fetchOpts = useCallback(async () => {
      const { data, success } = await sendRequest({
        identifier: "TicketType",
        codes: ["2"],
      });
  
      if (data && success) {
        setOtpions(data);
        const opt = data.find((o) => o.code === "2");
        if (opt) {
          setValue("type", opt);
        }
      }
    }, [sendRequest, setValue]);
  
    useEffect(() => {
      fetchOpts();
    }, [fetchOpts]);
  
    return (
      <Grid item xs={12} sm={12}>
        <AutoComplete
          name={"type"}
          label={t("page.board.createCard.type")}
          getOptionLabel={(option) => {
            return option.name || option.value || "";
          }}
          options={otps}
          size="small"
          itemProp=""
          autoFocus
          fullWidth
        />
      </Grid>
    );
  });

  export default TypeSelector