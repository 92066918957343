import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { IFilter } from "../models/Board";

export const FILTER_INITIAL_STATE = {
  keyword: "",
  membersIds: [],
  initialDate: null,
  endDate: null,
  status: "1",
  createdBy: [],
};

export const URL_FORMAT_DATE = "YYYY-MM-DD";

const setFilterParam = (
  o: URLSearchParams,
  value: string | null,
  name: string
) => {
  if (value) {
    o.set(name, value);
  } else {
    o.delete(name);
  }
};

const useFilter = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  const filter: IFilter = useMemo(
    () => ({
      keyword: searchParams.get("keyword") || "",
      membersIds: searchParams.getAll("member") || [],
      alreadyParticipated: Boolean(searchParams.get("alreadyParticipated")),
      initialDate: searchParams.get("initialDate") || null,
      endDate: searchParams.get("endDate") || null,
      status: searchParams.get("status") || "1",
      createdBy: searchParams.getAll("createdBy") || [],
    }),
    [searchParams]
  );

  const setFilter = useCallback(
    ({
      membersIds,
      endDate,
      initialDate,
      keyword,
      status,
      createdBy,
    }: IFilter) => {
      setSearchParams((o) => {
        o.delete("member");
        o.delete("createdBy");

        membersIds.forEach((id) => {
          o.append("member", id);
        });

        setFilterParam(o, endDate, "endDate");
        setFilterParam(o, initialDate, "initialDate");
        setFilterParam(o, status, "status");

        createdBy.forEach((id) => {
          o.append("createdBy", id);
        });

        if (keyword.length > 0) {
          o.set("keyword", keyword);
        } else {
          o.delete("keyword");
        }

        return o;
      });
    },
    [setSearchParams]
  );

  const clearAllFilters = useCallback(() => {
    setSearchParams(
      (o) => {
        o.delete("member");
        o.delete("filter");
        o.delete("alreadyParticipated");
        o.delete("initialDate");
        o.delete("endDate");
        o.delete("keyword");
        o.delete("createdBy");
        o.set("status", "1");
        return o;
      },
      {
        replace: true,
      }
    );
  }, [setSearchParams]);

  return {
    filter,
    clearAllFilters,
    setFilter,
  };
};

export default useFilter;
