import { GlobalAxios } from "../axios/axios-config";
import { IUsers } from "../models/users";

const USERS_ROUTE = process.env.REACT_APP_IDENTIY + "/api/v1/Users/";

export const listUsers = (params?: any) => {
  return GlobalAxios.get<IUsers[]>(USERS_ROUTE + "list", {
    params: params,
  });
};

