import { memo } from "react";
import { Box, Stack } from "@mui/material";

import useTags from "../../../hooks/useTags";
import { ITag } from "../../../models/tags";
import Tag from "../../CardPage/CardTags/Tag";

const TaskTagsList = ({ ticketChannelTags }: { ticketChannelTags: ITag[] }) => {
  const { toggleShowTags, showTags } = useTags();

  if (ticketChannelTags.length === 0) {
    return <></>;
  }

  return (
    <Stack
      width={"fit-content"}
      flexDirection={"row"}
      flexWrap={"wrap"}
      gap={0.5}
      mb={0.5}
    >
      {ticketChannelTags.map((tag) => (
        <Box
          onClick={(e) => {
            e.stopPropagation();
            toggleShowTags();
          }}
          key={tag.id}
          sx={{
            "&:hover": {
              opacity: 0.8,
              cursor: "pointer",
            },
          }}
        >
          <Tag data={tag} padding={0} fontSize={14} showTagName={showTags} />
        </Box>
      ))}
    </Stack>
  );
};

export default memo(TaskTagsList);
