import { Box } from "@mui/material";
import DetailMessage from "./DetailMessage";
import DetailFiles from "./DetailFiles/DetailFiles";
import DetailSend from "./DetailSend";
import DetailBack from "./DetailBack";
import { IDetailProps } from "./models";
import { memo } from "react";

const Detail = ({ onClose }: IDetailProps) => {
  return (
    <Box
      sx={(t) => ({
        p: 1,
        display: "flex",
        flexDirection: "column",
        gap: 1,
        backgroundColor:
          t.palette.mode === "light"
            ? t.palette.grey[200]
            : t.palette.grey[900],
        borderRadius: 1,
      })}
    >
      <DetailBack />
      <DetailMessage />
      <DetailFiles />
      <DetailSend onClose={onClose} />
    </Box>
  );
};

export default memo(Detail);
