import { Box, Stack, StackProps } from "@mui/material";
import { memo } from "react";

import { Outlet } from "react-router-dom";

const Body: React.FC<StackProps> = (props) => {
  return (
    <Stack direction={"row"} {...props}>
      <Box
        sx={(t) => ({
          height: "100%",
          overflow: "auto",
          width: "100%",
          backgroundColor: t.palette.background.default,
        })}
      >
        <Outlet></Outlet>
      </Box>
    </Stack>
  );
};

export default memo(Body);
