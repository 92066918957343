import { useTranslation } from "react-i18next";
import usePopover from "../../../../../../hooks/usePopover";
import { ListItemText, MenuItem, Popover } from "@mui/material";
import { useFetch } from "@4uhub/lib4uhub";
import { changeParent } from "../../../../../../services/column.serivice";
import EditListParentForm from "./EditListParentForm";
import { TEditColumnForm } from "./editListSchema";
import useColumn from "../../../../../../hooks/useColumn";
import { useCallback } from "react";

interface IEditListParentProps {
  id: string;
}
const EditListParent: React.FC<IEditListParentProps> = ({ id: cardId }) => {
  const { editColumnParent } = useColumn();

  const { anchorEl, handleClick, handleClose, open, id } = usePopover(
    "column-editparent--popover"
  );

  const { t } = useTranslation();

  const { sendRequest, loading } = useFetch(changeParent);

  const handleSubmit = useCallback(
    async (data: TEditColumnForm) => {
      const { success } = await sendRequest({
        id: cardId,
        parentId: data.parent.id,
      });

      if (success) {
        handleClose();
        editColumnParent(data.parent);
      }
    },
    [handleClose, editColumnParent, cardId, sendRequest]
  );

  return (
    <>
      <MenuItem aria-describedby={id} onClick={handleClick}>
        <ListItemText>
          {t("components.column.menu.changeParent.title")}
        </ListItemText>
      </MenuItem>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <EditListParentForm onSubmit={handleSubmit} loading={loading} />
      </Popover>
    </>
  );
};

export default EditListParent;
