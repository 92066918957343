import TicketHistoryProvider from "../../../store/context/card-histories-context";
import CardHistories from "./CardHistories";

const CardHistoriesContainer = () => {
  return (
    <TicketHistoryProvider>
      <CardHistories />
    </TicketHistoryProvider>
  );
};

export default CardHistoriesContainer;
