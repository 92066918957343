import { Button, Modal } from "@4uhub/lib4uhub";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import { Grid, Tooltip, Typography } from "@mui/material";
import { FC, memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { IContact } from "../../../../models/ticketDialogContacts";
import AvatarStack from "../../../Board/Header/AvatarStack";
import SharedContactItem from "./SharedContactItem";

const translationPath = "components.sharedContacts.";

interface SharedContactProps {
  contacts: IContact[];
}

const SharedContacts: FC<SharedContactProps> = ({ contacts }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const openHandler = () => setOpen(true);
  const closeHandler = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={closeHandler}
        title={`${t(translationPath + "title")}`}
        maxDialogWidth="xs"
      >
        {contacts.map((contact) => (
          <SharedContactItem contact={contact} />
        ))}
      </Modal>
      <Tooltip title={t(translationPath + "title")}>
        <Grid item xs={"auto"}>
          <Typography fontSize={10}>{t(translationPath + "title")}</Typography>
          <Button
            sx={(t) => ({
              marginTop: "2px",
            })}
            variant="contained"
            color="inherit"
            startIcon={<ContactPhoneIcon />}
            onClick={openHandler}
          >
            <AvatarStack
              size={30}
              max={5}
              members={contacts.map(({ name }) => ({
                id: name.formatted_name,
                name: name.formatted_name,
              }))}
            />
            <Typography ml={1}>
              {contacts[0].name.formatted_name}
              {contacts.length === 2 && (
                <> {t(translationPath + "another_contact")}</>
              )}
              {contacts.length > 2 && (
                <> {t(translationPath + "another_contacts")}</>
              )}
            </Typography>
          </Button>
        </Grid>
      </Tooltip>
    </>
  );
};

export default memo(SharedContacts);
