import { z } from "zod";

export const columnTableSchema = z
  .object({
    title: z.string().min(1, "components.column.addColumn.nameRequired"),
    isView: z.boolean(),
    parent: z.object({ id: z.string() }).optional(),
    status: z.object({ id: z.string() }).optional(),
  })
  .refine((data) => !(!data.isView && !data.parent), {
    message: "components.column.addColumn.parentRequired",
    path: ["parent"],
  })
  .refine((data) => !(!data.isView && !data.status), {
    message: "O status é obrigatório",
    path: ["status"],
  });

export type TTableColumnForm = z.infer<typeof columnTableSchema>;
