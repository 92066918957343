import { DateInput, DateTime, FormInput, Time } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo } from "react";
import { ITicketInternalTemplateFields } from "../../../../models/internal-template";

export const CardInternalTemplateContent = memo(
  ({
    isUserTicketMember,
    fields,
  }: {
    isUserTicketMember: boolean | undefined;
    fields: ITicketInternalTemplateFields[] | undefined;
  }) => {
    return (
      <Grid container spacing={2}>
        {fields
          ? fields
              .slice()
              .sort(
                (a, b) =>
                  a.ticketAutomationInternalTemplateField.screenOrder -
                  b.ticketAutomationInternalTemplateField.screenOrder
              )
              .map((field) => {
                const { dataType } =
                  field.ticketAutomationInternalTemplateField
                    .ticketAutomationInternalTemplateVariable;
                const { label } = field.ticketAutomationInternalTemplateField;
                const { code } = dataType;

                let fieldComponent;

                switch (code) {
                  case "1":
                    fieldComponent = (
                      <FormInput
                        name={field.id}
                        label={label}
                        size="small"
                        fullWidth
                        disabled={!isUserTicketMember}
                      />
                    );
                    break;
                  case "2":
                  case "3":
                    fieldComponent = (
                      <FormInput
                        name={field.id}
                        label={label}
                        type="number"
                        size="small"
                        fullWidth
                        disabled={!isUserTicketMember}
                      />
                    );
                    break;
                  case "4":
                    fieldComponent = (
                      <DateInput
                        name={field.id}
                        label={label}
                        isClearable
                        disabled={!isUserTicketMember}
                      />
                    );
                    break;
                  case "5":
                    fieldComponent = (
                      <Time
                        name={field.id}
                        label={label}
                        views={["hours", "minutes"]}
                        isClearable
                        disabled={!isUserTicketMember}
                      />
                    );
                    break;
                  case "6":
                    fieldComponent = (
                      <DateTime
                        name={field.id}
                        label={label}
                        isClearable
                        disabled={!isUserTicketMember}
                      />
                    );
                    break;
                  case "7":
                    fieldComponent = (
                      <FormInput
                        name={field.id}
                        label={label}
                        multiline
                        fullWidth
                        rows={3}
                        disabled={!isUserTicketMember}
                      />
                    );
                    break;
                  default:
                    fieldComponent = null;
                }

                return (
                  <Grid item xs={12} sm={12} key={field.id}>
                    {fieldComponent}
                  </Grid>
                );
              })
          : null}
      </Grid>
    );
  }
);
