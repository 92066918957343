import { Modal, useFetch } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";
import { memo, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { IGetWorksSpace } from "../../../../models/workspaces";
import WorkspaceModalEdit from "../../WorkspaceModalEdit";
import { archiveWorkspaceById } from "../../../../services/workspace.service";
import WorkspaceTitle from "./WorkspaceTitle";

const WorkspacePageHeader: React.FC<IGetWorksSpace> = (props) => {
  const [openModal, setOpenModal] = useState(false);

  const { eI } = useParams();

  const navigate = useNavigate();

  const { sendRequest } = useFetch(archiveWorkspaceById);

  const { t } = useTranslation();

  const { name, id } = props;

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const handleArchive = useCallback(async () => {
    const { success } = await sendRequest(id);
    if (success && eI) {
      navigate(`/e/${eI}`);
    }
  }, [navigate, sendRequest, eI, id]);

  const handleEdit = useCallback(() => {
    setOpenModal(true);
  }, []);
  

  return (
    <>
      <Modal
        open={openModal}
        onClose={onCloseModal}
        maxDialogWidth="xs"
        title={`${t(
          props.id
            ? "components.workspace.title2"
            : "components.workspace.title"
        )}`}
      >
        <WorkspaceModalEdit handleClose={onCloseModal} workspace={props} />
      </Modal>
      <WorkspaceTitle
        name={name}
        onArchive={handleArchive}
        onEdit={handleEdit}
      />
    </>
  );
};

export default memo(WorkspacePageHeader);
