import { ISelectType, useFetch } from "@4uhub/lib4uhub";
import { useCallback, useEffect, useState } from "react";

import { getGenericList } from "../services/generic.service";

const useGeneric = (identifier: string) => {
  const { sendRequest, loading } = useFetch(getGenericList);

  const [data, setData] = useState<ISelectType[]>([]);

  const fetchData = useCallback(async () => {
    const { data, success } = await sendRequest({ identifier });

    if (data && success) {
      setData(data);
    }
  }, [sendRequest, identifier]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, loading };
};

export default useGeneric;
