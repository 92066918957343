import {
  DateTime,
  Form,
  ISelectType,
  useFetch,
  useNotificationContext,
} from "@4uhub/lib4uhub";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Grid } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useEvents from "../../../hooks/useEvents";
import useTicket from "../../../hooks/useTask";
import {
  createEvent,
  getEventById,
  updateEventById,
} from "../../../services/events.service";
import { EventActions } from "./EventActions";
import { EventData } from "./EventData/EventData";
import { TEventMovementForm, eventMovementSchema } from "./eventSchema";
import { EventsFields } from "./EventsFields/EventsFields";
import { SelectEndDate } from "./SelectEndDate";

const translationPath = "components.events.";

export const Event = memo(() => {
  const { t } = useTranslation();

  const { ticket } = useTicket();

  const { setMessage } = useNotificationContext();

  const { id, mode, currentPage, onChangeMode, onAddEvent } = useEvents();

  const { sendRequest: save, loading: sLoading } = useFetch(createEvent);

  const { sendRequest: update, loading: uLoading } = useFetch(updateEventById);

  const { sendRequest: get, loading: gLoading } = useFetch(getEventById);

  const [eventType, setEventType] = useState<ISelectType | null>(null);

  const methods = useForm<TEventMovementForm>({
    resolver: zodResolver(eventMovementSchema),
  });

  const saveHandler = useCallback(
    async (form: TEventMovementForm) => {
      const { data, success } = await save({
        ticketId: ticket.id,
        ticketEventId: form.event.id,
        initialDate: form.startDate.toISOString(),
        endDate: form.endDate ? form.endDate.toISOString() : undefined,
      });
      if (data && success) {
        onChangeMode("list");
        setMessage({
          message: t(translationPath + "event_created"),
          type: "success",
        });
        if (currentPage === 1) {
          onAddEvent(data);
        }
      }
    },
    [save, onChangeMode, setMessage, t, onAddEvent, ticket.id, currentPage]
  );

  const updateHandler = useCallback(
    async (form: TEventMovementForm) => {
      const { data, success } = await update({
        id,
        item: {
          id,
          endDate: form.endDate ? form.endDate.toISOString() : "",
        },
      });
      if (data && success) {
        onChangeMode("list");
        setMessage({
          message: t(translationPath + "event_updated"),
          type: "success",
        });
      }
    },
    [id, update, onChangeMode, setMessage, t]
  );

  const submitHandler = useCallback(
    (form: TEventMovementForm) => {
      if (mode === "create") {
        saveHandler(form);
      } else {
        updateHandler(form);
      }
    },
    [mode, saveHandler, updateHandler]
  );

  const getHandler = useCallback(async () => {
    const { data, success } = await get(id);
    if (data && success) {
      setEventType(data.ticketEvent.ticketEventType);

      methods.reset({
        event: data.ticketEvent,
        startDate: new Date(data.initialDate),
        endDate: data.endDate ? new Date(data.endDate) : null,
      });
    }
  }, [methods, id, get]);

  const handleSubmit = useCallback(
    (e: any) => {
      e.preventDefault();
      methods.handleSubmit(submitHandler)();
    },
    [methods, submitHandler]
  );

  useEffect(() => {
    if (mode === "update") {
      getHandler();
    }
  }, [mode, getHandler]);

  const loading = sLoading || uLoading || gLoading;

  return (
    <Form {...methods}>
      <Box
        sx={(t) => ({
          p: 2,
          backgroundColor: t.palette.background.paper,
          borderRadius: 2,
        })}
        component="form"
        onSubmit={handleSubmit}
      >
        <Grid container spacing={mode === "create" ? 2 : undefined}>
          {mode === "create" && (
            <>
              <EventsFields />

              <Grid item xs={12} sm={6}>
                <DateTime
                  name="startDate"
                  label={t(translationPath + "initial_date")}
                  defaultValue={new Date()}
                />
              </Grid>
            </>
          )}

          {mode === "update" && <EventData eventType={eventType} />}

          <SelectEndDate />

          <EventActions loading={loading} />
        </Grid>
      </Box>
    </Form>
  );
});
