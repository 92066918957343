import { memo, useState } from "react";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { Variants, motion } from "framer-motion";

import Logo from "../../Board/SideMenu/Logo";
import MinimizeButton from "../../Board/SideMenu/MinimizeButton";
import SearchFilterFields from "./SearchFilterFields";

const variantsMobile: Variants = {
  open: { width: "100vw", justifyContent: "space-between" },
  closed: { width: 35, justifyContent: "end" },
};

const variants: Variants = {
  open: { width: 300, justifyContent: "space-between" },
  closed: { width: 35, justifyContent: "end" },
};

const SearchFilterContainer = () => {
  const [open, setOpen] = useState(true);

  const { palette } = useTheme();

  const toggleOpen = () => setOpen((open) => !open);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const minimizeHandler = () => setOpen(false);

  return (
    <motion.nav
      variants={matches ? variants : variantsMobile}
      style={{
        borderTop: 0,
        height: "100%",
        color: palette.text.primary,
        display: "flex",
        flexDirection: "column",
        overflowY: "clip",
        backgroundColor: palette.mode === "light" ? "#FAFAFA" : "#242424",
        borderRight: "1px solid",
        borderRightColor: palette.divider,
      }}
      initial={{
        width: 300,
        justifyContent: "space-between",
      }}
      animate={open ? "open" : "closed"}
    >
      {open && (
        <Stack height={"calc(100% - 83px)"}>
          <Logo />
          <SearchFilterFields onMinimizeMenu={minimizeHandler} />
        </Stack>
      )}
      <MinimizeButton open={open} toggleOpen={toggleOpen} />
    </motion.nav>
  );
};

export default memo(SearchFilterContainer);
