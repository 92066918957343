import { useContext } from "react";
import { ChecklistsContext } from "../store/context/ChecklistContext/checklist-context";

const useChecklists = () => {
  const board = useContext(ChecklistsContext);

  if (board === undefined) {
    throw new Error("useChecklists must be used within a ChecklistsProvider");
  }

  return board;
};

export default useChecklists;
