import { ISearchReturn } from "@4uhub/lib4uhub/dist/types/models";
import { GlobalAxios } from "../axios/axios-config";
import {
  IGetProcolsDefaultParams,
  IProtocolSendParams,
  IProtocolsDefault,
} from "../models/protocol-default";

const PROTOCOL_DEFAULT_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketDefaultDoc/";

export const getProtocolsDefault = (params: IGetProcolsDefaultParams) => {
  return GlobalAxios.get<ISearchReturn<IProtocolsDefault>>(
    PROTOCOL_DEFAULT_ROUTE + "list/pagination",
    { params }
  );
};

export const sendProtocolDefault = (params: IProtocolSendParams) => {
  return GlobalAxios.post(PROTOCOL_DEFAULT_ROUTE + "Send", params);
};
