import { memo } from "react";

import { ITag } from "../../../models/tags";
import HoverOpacity from "../../UI/HoverOpacity";
import Property from "../Property";
import Tag from "./Tag";

interface ICardTagProps {
  disabled?: boolean;
  tag: ITag;
  loading: boolean;
  onClick: (event: React.MouseEvent<Element, MouseEvent>) => void;
}

const CardTag: React.FC<ICardTagProps> = ({
  disabled,
  onClick,
  loading,
  tag,
}) => {
  return (
    <HoverOpacity onClick={onClick} disabled={disabled}>
      <Property loading={loading} content={<Tag data={tag} />} />
    </HoverOpacity>
  );
};

export default memo(CardTag);
