import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

import Property from "../Property";
import { memo, MouseEvent, useCallback, useState } from "react";
import { Button, Modal } from "@4uhub/lib4uhub";
import { useInstalledExtensions } from "../../../hooks/useInstalledExtensions";
import { Extension } from "../../../models/intalled-extensions";
import { ITicketRelationship } from "../../../models/ticket";
import SalesContractInfoModal from "./SalesContract/SalesContractInfoModal";

interface ITicketSequentialProps {
  id: string;
  ticketRelationships: ITicketRelationship[] | null;
}

const SalesContractInfo: React.FC<ITicketSequentialProps> = ({
  id,
  ticketRelationships,
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const [salesContractInstalled] = useInstalledExtensions(
    Extension.SALES_CONTRACT_MANAGEMENT
  );

  const closeHandler = useCallback(() => {
    setOpen(false);
  }, []);

  const openModal = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    e.stopPropagation();
    setOpen(true);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={closeHandler}
        title={`${t("page.card.sales_contract")}`}
      >
        <SalesContractInfoModal id={id} />
      </Modal>
      {ticketRelationships &&
        Array.isArray(ticketRelationships) &&
        ticketRelationships.some((rel) => rel.salesContractBeneficiary) &&
        salesContractInstalled && (
          <Property
            content={
              <Button onClick={openModal} sx={{ p: 0, m: 0, minWidth: 0 }}>
                <Tooltip title={t("page.card.client_sales_contract")}>
                  <img
                    src="/4uSalesContractFavicon.png"
                    alt="Sales Contract Info"
                    style={{ width: 21, height: 21 }}
                  />
                </Tooltip>
              </Button>
            }
            label={t("page.card.sales_contract")}
          />
        )}
    </>
  );
};

export default memo(SalesContractInfo);
