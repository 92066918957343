import { Stack } from "@mui/material";

import QuickReplayButton from "./QuickReplay/QuickReplayButton";
import { memo } from "react";
import ProtocolDefault from "./ProtocolDefault/ProtocolDefault";
import useTicket from "../../hooks/useTask";

const ChatHeader = () => {

  const {
    isUserTicketMember,
    ticket: { status },
  } = useTicket();

  const isDisabled = status?.code === "3" || !isUserTicketMember;

  return (
    <Stack
      direction={"row"}
      p={1}
      justifyContent={"flex-end"}
      sx={(t) => ({
        borderBottom: `1px solid ${t.palette.divider}`,
        backgroundColor: t.palette.mode === "light" ? "#FAFAFA" : "#242424",
        borderRadius: t.shape.borderRadius / 4,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      })}
    >
      {/**<Stack alignItems={"center"} direction={"row"} gap={1}>
        <Box
          sx={(t) => ({
            backgroundColor: t.palette.success.main,
            width: 10,
            height: 10,
            borderRadius: 10,
          })}
        ></Box>
        <Typography color={(t) => t.palette.text.primary}>
          Ativo agora
        </Typography>
      </Stack> */}

      <Stack direction={"row"}>
        <ProtocolDefault isDisabled={isDisabled} />
        <QuickReplayButton isDisabled={isDisabled} />
        {/**
         *     <IconButton>
          <MoreVertIcon />
        </IconButton>
         */}
      </Stack>
    </Stack>
  );
};

export default memo(ChatHeader);
