import { memo, useCallback, useMemo, useState } from "react";
import { Grid, Tab, Tabs } from "@mui/material";

import CustomTabPanel from "./CustomTabPanel";

interface ITabsComponents {
  tabs: (ITab | undefined)[];
}

type ITab = {
  icon: JSX.Element;
  label: string;
  content?: JSX.Element;
};

const TabsComponent: React.FC<ITabsComponents> = ({ tabs }) => {
  const [value, setValue] = useState(0);

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    },
    []
  );

  const filtestedTabs = useMemo(
    () => tabs.filter((tab) => tab !== undefined),
    [tabs]
  );

  return (
    <Grid container width={"100%"} height={"100%"}>
      <Grid item xs={12}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          allowScrollButtonsMobile
          aria-label="icon label tabs example"
          sx={(t) => ({
            height: "35px",
            minHeight: "35px",
            marginBottom: t.spacing(1),
          })}
        >
          {filtestedTabs.map((tab, index) => {
            if (!tab) return null;

            return (
              <Tab
                icon={tab.icon}
                key={index}
                label={tab.label}
                iconPosition="start"
                sx={{
                  height: "35px",
                  minHeight: "35px",
                }}
              />
            );
          })}
        </Tabs>
      </Grid>

      <Grid
        item
        xs={12}
        height={"calc(100% - 43.750px)"}
        overflow={"hidden"}
        sx={(t) => ({
          border: `1px solid ${t.palette.divider}`,
          borderRadius: t.shape.borderRadius / 4,
          backgroundColor:
            t.palette.mode === "light"
              ? t.palette.grey[100]
              : t.palette.grey[900],
        })}
      >
        {filtestedTabs.map((t, i) => {
          if (!t) {
            return null;
          }
          if (t.content) {
            return (
              <CustomTabPanel index={i} value={value} key={i}>
                {t.content}
              </CustomTabPanel>
            );
          }
          return null;
        })}
      </Grid>
    </Grid>
  );
};

export default memo(TabsComponent);
