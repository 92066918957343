import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Loading, useRole } from "@4uhub/lib4uhub";

import BoardListITemAdd from "../../Board/BoardListItemAdd";
import { useWorkspaceContext } from "../../../store/context/workspace-context";
import BoardListITem from "../../Board/BoardListItem";

const BoardList = () => {
  const { boards, loading } = useWorkspaceContext();

  const { t } = useTranslation();

  const createPermission = useRole(["Create"]);

  return (
    <>
      <Typography
        variant="h6"
        sx={(t) => ({
          marginBottom: t.spacing(4),
          color: t.palette.text.primary,
        })}
      >
        {t("page.workspace.title")}
      </Typography>
      <Grid container spacing={2} flexDirection={"row"}>
        {!loading && (
          <>
            {createPermission && (
              <Grid item xs={12} md={6} lg={3}>
                <BoardListITemAdd />
              </Grid>
            )}

            {boards.map((b) => (
              <Grid item key={b.id} xs={12} md={6} lg={3}>
                <BoardListITem {...b} navigatePath={b.id + "?status=1"} />
              </Grid>
            ))}
          </>
        )}
        {loading && <Loading sx={{ position: "relative" }} size={30} />}
      </Grid>
    </>
  );
};

export default BoardList;
