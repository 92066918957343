import { Button } from "@4uhub/lib4uhub";
import { Popover, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { memo } from "react";

import ChecklistAddItemForm from "./ChecklistAddItemForm";
import usePopover from "../../../../hooks/usePopover";
import useTicket from "../../../../hooks/useTask";

const translationPath = "components.cardChecklistAddItem.";

const CardChecklistAddItem = ({
  ticketChecklistId,
}: {
  ticketChecklistId: string;
}) => {
  const { t } = useTranslation();

  const {
    ticket: { status },
  } = useTicket();

  const { anchorEl, handleClick, handleClose, id, open } = usePopover(
    "ticket-status-popover"
  );

  return (
    <Stack mt={1} flexDirection={"row"} justifyContent={"start"}>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ mt: 1 }}
      >
        <ChecklistAddItemForm
          ticketChecklistId={ticketChecklistId}
          onCloseHandler={handleClose}
        />
      </Popover>
      <Button
        size="small"
        variant="contained"
        onClick={handleClick}
        color="inherit"
        sx={(t) => ({
          color: t.palette.text.primary,
        })}
        disabled={status.code === "3"}
        disableFocusRipple
      >
        {t(translationPath + "button")}
      </Button>
    </Stack>
  );
};

export default memo(CardChecklistAddItem);
