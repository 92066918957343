import { Button, useFetch, useNotificationContext } from "@4uhub/lib4uhub";
import { Box } from "@mui/material";
import { sendProtocolDefault } from "../../../../../services/protocolDefault.service";
import { useProtocol } from "../../../../../store/context/protocol-context";
import { memo, useCallback } from "react";
import SendIcon from "@mui/icons-material/Send";
import { IDetailSendProps } from "./models";
import { useTranslation } from "react-i18next";
import useTicket from "../../../../../hooks/useTask";

const translationPath = "components.detailSend.";

const DetailSend = ({ onClose }: IDetailSendProps) => {
  const { t } = useTranslation();

  const { selectedProtocol } = useProtocol();

  const {
    ticket: { id: cardId },
  } = useTicket();

  const { setMessage } = useNotificationContext();

  const { sendRequest, loading } = useFetch(sendProtocolDefault);

  const sendProtocol = useCallback(async () => {
    if (!cardId || !selectedProtocol) return;
    const { success } = await sendRequest({
      ticketId: cardId,
      ticketDefaultDocId: selectedProtocol.id,
    });
    if (success) {
      onClose();
      setMessage({
        message: t(translationPath + "sent_successfully"),
        type: "success",
      });
    }
  }, [sendRequest, setMessage, onClose, t, cardId, selectedProtocol]);

  return (
    <Box sx={{ width: "100%", display: "flex", gap: 1 }}>
      {/* {matches && (
        <Button
          variant="outlined"
          fullWidth
          onClick={() => selectProtocolHandler(null)}
        >
          Voltar
        </Button>
      )} */}
      <Button
        fullWidth
        variant="contained"
        startIcon={<SendIcon />}
        loading={loading}
        disabled={!selectedProtocol}
        onClick={sendProtocol}
      >
        {t(translationPath + "send")}
      </Button>
    </Box>
  );
};

export default memo(DetailSend);
