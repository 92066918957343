import {
  Box,
  Popover,
  PopoverProps,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useFetch } from "@4uhub/lib4uhub";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import CardMember from "./CardMember";
import CardMembersService from "../../../services/cardMembers.service";
import { IAddMember, ICardMember } from "../../../models/card-members";
import TicketChannelProfilesService from "../../../services/ticketChannelProfiles.service";
import { IMember } from "../../../models/members";
import useTicket from "../../../hooks/useTask";
import { SignalRContext } from "../../SignalR/SignalRProvider";
import { IMemberChannels } from "../../../models/channels";

const addMemberService = new CardMembersService<IAddMember>();

const usersService = new TicketChannelProfilesService();

const translationPath = "components.cardMembersSelector.";

const CardMembersSelector: React.FC<PopoverProps> = ({
  onClose,
  id,
  open,
  anchorEl,
}) => {
  const { t } = useTranslation();

  const {
    ticket: { ticketChannelStep, id: TicketId, ticketMembers },
    onEditMembers,
  } = useTicket();

  const addCardMember = useCallback(
    (member: ICardMember) => {
      const notFind = ticketMembers.findIndex((m) => m.id === member.id) === -1;
      if (notFind) {
        onEditMembers && onEditMembers([...ticketMembers, member]);
      }
    },
    [ticketMembers, onEditMembers]
  );

  const removeCardMember = useCallback(
    (id: string) => {
      onEditMembers &&
        onEditMembers(ticketMembers.filter((tm) => tm.id !== id));
    },
    [ticketMembers, onEditMembers]
  );

  const [boardMembers, setBoardMembers] = useState<IMember[]>([]);

  const [search, setSearch] = useState("");

  const { sendRequest: requestUsers } = useFetch(usersService.getItemById);

  const { sendRequest: addNewMember } = useFetch(addMemberService.addMember);

  const { sendRequest: removeMember } = useFetch(addMemberService.removeMember);

  SignalRContext.useSignalREffect(
    "NewTicketMemberCreated", // Your Event Key
    (userId: string, data: IMemberChannels) => {
      if (TicketId === data.ticketId) {
        addCardMember(data.ticketMember);
      }
    },
    [addCardMember, TicketId]
  );

  // Remover membro do ticket
  SignalRContext.useSignalREffect(
    "RemoveTicketMember", // Your Event Key
    (userId: string, data: IMemberChannels) => {
      if (TicketId === data.ticketId) {
        removeCardMember(data.ticketMember.id);
      }
    },
    [removeCardMember, TicketId]
  );

  const loadUsers = useCallback(async () => {
    if (ticketChannelStep.ticketChannelId) {
      const { data, success } = await requestUsers(
        ticketChannelStep.ticketChannelId
      );
      if (data && success) {
        setBoardMembers(data);
      }
    }
  }, [requestUsers, ticketChannelStep.ticketChannelId]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  const addMemberHandler = useCallback(
    async (userId: string) => {
      if (TicketId) {
        await addNewMember({
          ticketId: TicketId,
          userId: userId,
        });
      }
    },
    [addNewMember, TicketId]
  );

  const removeMemberHandler = useCallback(
    async (id: string) => {
      await removeMember(id);
    },
    [removeMember]
  );

  const filtered = useMemo(
    () =>
      boardMembers.filter((members) =>
        members.user.fullName.toLowerCase().includes(search.toLowerCase())
      ),
    [boardMembers, search]
  );

  const onClick = useCallback(
    async (id: string) => {
      const finded = ticketMembers.find((m) => m.user.id === id);

      if (!!finded) {
        await removeMemberHandler(finded.id);
      } else {
        await addMemberHandler(id);
      }
    },
    [ticketMembers, removeMemberHandler, addMemberHandler]
  );

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{ mt: 1 }}
    >
      <Box p={1} width={285}>
        <Stack flexDirection={"column"} alignItems={"start"} gap={1}>
          <Typography variant="subtitle1">
            {t(translationPath + "members")}
          </Typography>
          <TextField
            label={t(translationPath + "search")}
            size="small"
            fullWidth
            onChange={(e) => setSearch(e.target.value)}
          />
          <Stack flexDirection={"row"} gap={1} flexWrap={"wrap"} mt={2}>
            {filtered.map((member) => {
              const isAdded = !!ticketMembers.find(
                (tm) => tm.user.id === member.user.id
              );
              return (
                <CardMember
                  tooltip={member.user.fullName}
                  name={member.user.fullName}
                  key={member.id}
                  isAdded={isAdded}
                  id={member.user.id}
                  onClick={onClick}
                />
              );
            })}
          </Stack>
        </Stack>
      </Box>
    </Popover>
  );
};

export default memo(CardMembersSelector);
