import { Box, BoxProps } from "@mui/material";

type TCard = {
  children: any;
} & BoxProps;

const Card: React.FC<TCard> = ({ children, ...otherProps }) => {
  return (
    <Box
      p={2}
      {...otherProps}
      sx={[
        (t) => ({
          border: `1px solid ${t.palette.divider}`,
          borderRadius: t.shape.borderRadius / 4,
        }),
        ...(Array.isArray(otherProps.sx) ? otherProps.sx : [otherProps.sx]),
      ]}
    >
      {children}
    </Box>
  );
};

export default Card;
