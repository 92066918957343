import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  memo,
  useMemo,
} from "react";
import { useFetch } from "@4uhub/lib4uhub";
import { useParams } from "react-router-dom";

import { useAppSelector } from "../store";
import { getBoardsByWorkspaceId } from "../../services/board.service";
import { IGetWorksSpace } from "../../models/workspaces";
import { IGetBoard } from "../../models/Board";
import useSignalGroup from "../../hooks/useSignalGroup";
import { SignalRContext } from "../../components/SignalR/SignalRProvider";

type IWorskpaceContext = {
  boards: IGetBoard[];
  viewboards: IGetBoard[];
  actualWorkspace?: IGetWorksSpace;
  setBoards: Dispatch<SetStateAction<IGetBoard[]>>;
  actualBoard?: IGetBoard;
  editBoardParent: ({ parent, id }: { parent: IGetBoard; id: string }) => void;
  addBoard: (board: IGetBoard) => void;
  leaveBoard: () => void;
  loading?: boolean;
};

export const WorskpaceContext = createContext<IWorskpaceContext>({
  boards: [],
  viewboards: [],
  setBoards: () => {},
  addBoard: () => {},
  leaveBoard: () => {},
  editBoardParent: () => {},
});

interface IWorskpaceContextProvider {
  children: any;
}

const WorkspaceProvider: React.FC<IWorskpaceContextProvider> = ({
  children,
}) => {
  const [boards, setBoards] = useState<IGetBoard[]>([]);

  const [viewboards, setViewBoards] = useState<IGetBoard[]>([]);

  const [loading, setLoading] = useState(true);

  const workspaces = useAppSelector((state) => state.workSpaces.workspaces);

  const { sendRequest: getBoard } = useFetch(getBoardsByWorkspaceId, {
    startLoadingTrue: true,
  });

  const { ticketWorkspaceSequence, boardId } = useParams();

  const actualWorkspace = useMemo(
    () =>
      workspaces.find(
        (w) => w.ticketWorkspaceSequence === Number(ticketWorkspaceSequence)
      ),
    [ticketWorkspaceSequence, workspaces]
  );

  const actualBoard = useMemo(
    () => boards.concat(viewboards).find((b) => b.id === boardId),
    [boards, viewboards, boardId]
  );

  const workspaceGroup = useMemo(
    () =>
      actualWorkspace?.id
        ? `TicketWorkspace|${actualWorkspace?.id}`
        : undefined,
    [actualWorkspace]
  );

  useSignalGroup(workspaceGroup);

  const fetchBoards = useCallback(
    async (isView: boolean) => {
      if (!actualWorkspace) {
        setLoading(false);
        return;
      }
      setLoading(true);

      const { data, success } = await getBoard({
        wkId: actualWorkspace.id,
        isView: isView,
      });

      if (data && success) {
        if (isView) {
          setViewBoards(data);
        } else {
          setBoards(data);
        }
        setLoading(false);
      }
    },
    [getBoard, actualWorkspace]
  );

  const editBoard = useCallback((data: IGetBoard) => {
    if (data.isView) {
      setViewBoards((old) =>
        old.map((b) => {
          if (b.id === data.id) {
            return { ...b, ...data };
          }
          return b;
        })
      );
    } else {
    }
    setBoards((old) =>
      old.map((b) => {
        if (b.id === data.id) {
          return { ...b, ...data };
        }
        return b;
      })
    );
  }, []);

  const editBoardParent = useCallback(
    ({ parent, id }: { parent: IGetBoard; id: string }) => {
      setBoards((old) =>
        old.map((b) => {
          if (b.id === id) {
            return { ...b, parent: parent };
          }
          return b;
        })
      );
    },
    []
  );

  const addBoard = useCallback((board: IGetBoard) => {
    if (board.isView) {
      setViewBoards((old) => [...old, board]);
    } else {
      setBoards((old) => [...old, board]);
    }
  }, []);

  const leaveBoard = useCallback(() => {
    setBoards((old) => old.filter((o) => o.id !== actualBoard?.id));
  }, [actualBoard]);

  const archiveBoard = useCallback((id: string) => {
    setBoards((boards) => boards.filter((b) => b.id !== id));
  }, []);

  useEffect(() => {
    fetchBoards(false);
    fetchBoards(true);
  }, [fetchBoards]);

  //SignalR

  //Canal para quando quadro é criado
  SignalRContext.useSignalREffect(
    "CreateTicketChannel", // Your Event Key
    (userId: string, data: IGetBoard) => {
      console.log("CreateTicketChannel", data);
      addBoard(data);
    },
    [addBoard]
  );

  //Canal para quando quadro é editado
  SignalRContext.useSignalREffect(
    "UpdateTicketChannel", // Your Event Key
    (userId: string, data: IGetBoard) => {
      console.log("UpdateTicketChannel", data);
      editBoard(data);
    },
    [editBoard]
  );
  //

  //Canal para quando quadro é arquivado
  SignalRContext.useSignalREffect(
    "ArchiveTicketChannel", // Your Event Key
    (userId: string, data: string) => {
      console.log("ArchiveTicketChannel", data);

      archiveBoard(data);
    },
    [archiveBoard]
  );
  //

  //Canal para quando quadro é desarquivado
  SignalRContext.useSignalREffect(
    "UnArchiveTicketChannel", // Your Event Key
    (userId: string, data: IGetBoard) => {
      console.log("UnArchiveTicketChannel", data);
      addBoard(data);
    },
    [addBoard]
  );
  //

  const value = useMemo(
    () => ({
      boards,
      actualWorkspace,
      viewboards,
      actualBoard,
      loading,
      editBoardParent,
      setBoards,
      editBoard,
      addBoard,
      leaveBoard,
    }),
    [
      boards,
      actualWorkspace,
      viewboards,
      actualBoard,
      loading,
      editBoardParent,
      setBoards,
      editBoard,
      addBoard,
      leaveBoard,
    ]
  );

  return (
    <WorskpaceContext.Provider value={value}>
      {children}
    </WorskpaceContext.Provider>
  );
};

export const useWorkspaceContext = () => {
  return useContext(WorskpaceContext);
};

export default memo(WorkspaceProvider);
