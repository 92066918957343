import { motion } from "framer-motion";
import HistoryCommentInput from "./HistoryCommentForm/HistoryCommentInput";

const HistoryComment = ({ onCancel }: { onCancel: () => void }) => {
  return (
    <motion.div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "start",
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, ease: [0.6, -0.05, 0.01, 0.99] }}
    >
      <HistoryCommentInput onCancel={onCancel} />
    </motion.div>
  );
};

export default HistoryComment;
