import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { memo } from "react";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import Property from "../Property";
import { useNotificationContext } from "@4uhub/lib4uhub";

interface ITicketProtocolProps {
  protocolNumber: string;
}

const translationPath = "page.card.";

const TicketProtocol: React.FC<ITicketProtocolProps> = ({ protocolNumber }) => {
  const { t } = useTranslation();

  const { setMessage } = useNotificationContext();

  const copyHandler = () => {
    navigator.clipboard.writeText(protocolNumber);
    setMessage({
      message: t(translationPath + "copied"),
      notificationKey: new Date().getMilliseconds(),
      type: "success",
    });
  };

  return (
    <Property
      content={
        <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
          <Typography color={(t) => t.palette.text.primary} fontSize={14}>
            {protocolNumber}
          </Typography>
          <Tooltip title={t(translationPath + "copyTooltip")}>
            <IconButton
              size="small"
              sx={{ p: 0, "&.hover": { opacity: 0.5 },fontSize: 15 }}
              onClick={copyHandler}
            >
              <ContentCopyIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Stack>
      }
      label={t(translationPath + "protocol")}
    />
  );
};

export default memo(TicketProtocol);
