import {
  Autocomplete,
  Avatar,
  Checkbox,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { getInitials, stringToColor } from "@4uhub/lib4uhub";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useTranslation } from "react-i18next";

import { IFiltersProps } from "../Board/Header/Filter/Members/models";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SelectMembers = <T extends object>({
  options,
  value,
  label,
  placeholder,
  showPlaceholder = true,
  marginTop = true,
  getOptionLabel,
  onChange,
}: IFiltersProps<T>) => {
  const { t } = useTranslation();

  return (
    <Autocomplete
      multiple
      sx={(t) => ({
        marginTop: marginTop ? t.spacing(1) : undefined,
      })}
      id="members-select"
      options={options}
      value={value}
      fullWidth
      limitTags={1}
      onChange={(_, v) => onChange(v)}
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Stack direction={"row"} gap={1} alignItems={"center"}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            <Avatar
              sx={{
                width: 23,
                height: 23,
                backgroundColor: stringToColor(getOptionLabel(option)),
                fontSize: 10,
              }}
            >
              {getInitials(getOptionLabel(option))}
            </Avatar>

            <Typography variant="body2">{getOptionLabel(option)}</Typography>
          </Stack>
        </li>
      )}
      size="small"
      renderInput={(params) => (
        <TextField
          {...params}
          label={label ?? t("components.boards.filters.selectMembers")}
          placeholder={
            showPlaceholder
              ? placeholder ??
                (t("components.boards.filters.selectMember") || undefined)
              : undefined
          }
          size="small"
        />
      )}
    />
  );
};

export default SelectMembers;
