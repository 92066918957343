import {
  createContext,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { OnDragEndResponder } from "react-beautiful-dnd";
import { useFetch } from "@4uhub/lib4uhub";

import { IChecklist, IChecklistItem } from "../../../models/ticketChecklist";
import TicketChecklistService from "../../../services/ticketChecklist.service";
import TicketChecklistItemService from "../../../services/ticketChecklistItem.service";
import useSignalRChecklist from "./useSignalRCheclist";

const ticketChecklistService = new TicketChecklistService();
const ticketChecklistItemService = new TicketChecklistItemService();

type IChecklistsContext = {
  checklists: IChecklist[];
  loadChecklists: (checklists: IChecklist[]) => void;
  addChecklist: (checklist: IChecklist) => void;
  removeChecklist: (id: string) => void;
  updateChecklist: ({ id, newName }: { id: string; newName: string }) => void;
  onDragEnd: OnDragEndResponder;
  checklistItems: IChecklistItem[];
  addChecklistItem: (
    checklistItem: IChecklistItem,
    checklistId: string
  ) => void;
  updateChecklistItem: ({
    id,
    newDescription,
    checklistId,
  }: {
    id: string;
    newDescription: string;
    checklistId: string;
  }) => void;
  updateIsChecked: ({
    id,
    isChecked,
    checklistId,
    endDate,
  }: {
    id: string;
    isChecked: boolean;
    checklistId: string;
    endDate: string | null;
  }) => void;
  removeChecklistItem: (id: string, checklistId: string) => void;
  updateChecklistItemTimes: ({
    id,
    checklistId,
    estimatedTime,
    forecastStartDate,
    endDate,
    startDate,
  }: {
    id: string;
    checklistId: string;
    estimatedTime: number | null;
    forecastStartDate: string | null;
    startDate: string | null;
    endDate: string | null;
  }) => void;
};

export const ChecklistsContext = createContext<IChecklistsContext>({
  checklists: [],
  loadChecklists: () => {},
  addChecklist: () => {},
  removeChecklist: () => {},
  updateChecklist: () => {},
  onDragEnd: () => {},
  checklistItems: [],
  addChecklistItem: () => {},
  updateChecklistItem: () => {},
  updateIsChecked: () => {},
  removeChecklistItem: () => {},
  updateChecklistItemTimes: () => {},
});

interface IChecklistsProvider {
  children: any;
  ticketId?: string;
}

const ChecklistsProvider: React.FC<IChecklistsProvider> = ({
  children,
  ticketId,
}) => {
  const [checklists, setChecklists] = useState<IChecklist[]>([]);

  const [checklistItems] = useState<IChecklistItem[]>([]);

  const { sendRequest } = useFetch(ticketChecklistService.getAllChecklists);

  const { sendRequest: updateChecklistItemOrder } = useFetch(
    ticketChecklistItemService.updateChecklistItemOrder
  );

  const { sendRequest: updateChecklistOrder } = useFetch(
    ticketChecklistService.updateChecklistOrder
  );

  const loadChecklists = useCallback(async () => {
    if (ticketId) {
      const { data, success } = await sendRequest(ticketId);
      if (data && success) {
        setChecklists(data);
      }
    }
  }, [sendRequest, ticketId]);

  useEffect(() => {
    loadChecklists();
  }, [loadChecklists]);

  const addChecklist = useCallback((checklist: IChecklist) => {
    setChecklists((prevState) => [...prevState, checklist]);
  }, []);

  const updateChecklist = useCallback(
    ({ id, newName }: { id: string; newName: string }) => {
      setChecklists((prevState) =>
        prevState.map((prev) => {
          if (prev.id === id) {
            return { ...prev, name: newName };
          }
          return prev;
        })
      );
    },
    []
  );

  const removeChecklist = useCallback((id: string) => {
    setChecklists((prevState) => prevState.filter((old) => old.id !== id));
  }, []);

  const addChecklistItem = useCallback(
    (checklistItem: IChecklistItem, checklistId: string) => {
      setChecklists((prevState) =>
        prevState.map((prev) => {
          if (prev.id === checklistId) {
            return {
              ...prev,
              ticketChecklistItems: [
                ...prev.ticketChecklistItems,
                checklistItem,
              ],
            };
          }
          return prev;
        })
      );
    },
    []
  );

  const updateChecklistItem = useCallback(
    ({
      id,
      newDescription,
      checklistId,
    }: {
      id: string;
      newDescription: string;
      checklistId: string;
    }) => {
      setChecklists((prevState) => {
        return prevState.map((oldState) => {
          if (oldState.id === checklistId) {
            return {
              ...oldState,
              ticketChecklistItems: oldState.ticketChecklistItems.map(
                (item) => {
                  if (item.id === id) {
                    return {
                      ...item,
                      description: newDescription,
                    };
                  }
                  return item;
                }
              ),
            };
          }
          return oldState;
        });
      });
    },
    []
  );

  const updateChecklistItemTimes = useCallback(
    ({
      id,
      checklistId,
      estimatedTime,
      forecastStartDate,
      endDate,
      startDate,
    }: {
      id: string;
      checklistId: string;
      estimatedTime: number | null;
      forecastStartDate: string | null;
      startDate: string | null;
      endDate: string | null;
    }) => {
      setChecklists((prevState) => {
        return prevState.map((oldState) => {
          if (oldState.id === checklistId) {
            return {
              ...oldState,
              ticketChecklistItems: oldState.ticketChecklistItems.map(
                (item) => {
                  if (item.id === id) {
                    return {
                      ...item,
                      estimatedTime: estimatedTime,
                      forecastStartDate: forecastStartDate,
                      startDate: startDate,
                      endDate: endDate,
                    };
                  }
                  return item;
                }
              ),
            };
          }
          return oldState;
        });
      });
    },
    []
  );

  const updateIsChecked = useCallback(
    ({
      id,
      isChecked,
      checklistId,
      endDate,
    }: {
      id: string;
      isChecked: boolean;
      checklistId: string;
      endDate: string | null;
    }) => {
      setChecklists((prevState) => {
        return prevState.map((oldState) => {
          if (oldState.id === checklistId) {
            return {
              ...oldState,
              ticketChecklistItems: oldState.ticketChecklistItems.map(
                (item) => {
                  if (item.id === id) {
                    return {
                      ...item,
                      isChecked,
                      endDate,
                    };
                  }
                  return item;
                }
              ),
            };
          }
          return oldState;
        });
      });
    },
    []
  );

  const removeChecklistItem = useCallback((id: string, checklistId: string) => {
    setChecklists((prevState) => {
      return prevState.map((oldState) => {
        if (oldState.id === checklistId) {
          return {
            ...oldState,
            ticketChecklistItems: oldState.ticketChecklistItems.filter(
              (item) => item.id !== id
            ),
          };
        }
        return oldState;
      });
    });
  }, []);

  const changeItemsOrder = useCallback(
    (order: string[], checklistId: string) => {
      updateChecklistItemOrder({
        item: {
          ticketChecklistId: checklistId,
          ticketChecklistItemIds: order,
        },
      });
    },
    [updateChecklistItemOrder]
  );

  const changeOrder = useCallback(
    (order: string[]) => {
      updateChecklistOrder({
        item: {
          ticketId: ticketId || "",
          ticketChecklistIds: order,
        },
      });
    },
    [updateChecklistOrder, ticketId]
  );

  const onDragEnd: OnDragEndResponder = useCallback(
    async ({ destination, source, draggableId, type }) => {
      if (!destination) {
        return;
      }

      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }

      if (type === "checklist") {
        const newChecklistsOrder = Array.from(checklists);
        newChecklistsOrder.splice(source.index, 1);
        newChecklistsOrder.splice(
          destination!.index,
          0,
          checklists.find((o) => o.id === draggableId)!
        );

        changeOrder(newChecklistsOrder.map((newOrder) => newOrder.id));

        setChecklists(newChecklistsOrder);

        return;
      }

      if (type === "checklist-item") {
        checklists.forEach((item) => {
          if (item.id === destination.droppableId) {
            const updatedItems = [...item.ticketChecklistItems];

            const itemIndex = updatedItems.findIndex(
              (item) => item.id === draggableId
            );

            if (itemIndex >= 0 && itemIndex !== destination?.index) {
              const [removedItem] = updatedItems.splice(itemIndex, 1);
              updatedItems.splice(destination?.index!, 0, removedItem);
            }

            changeItemsOrder(
              updatedItems.map((item) => item.id),
              destination.droppableId
            );

            setChecklists((prevData) => {
              return prevData.map((item) => {
                if (item.id === destination.droppableId) {
                  return {
                    ...item,
                    ticketChecklistItems: updatedItems,
                  };
                }

                return item;
              });
            });
          }
          return;
        });
      }
    },
    [changeItemsOrder, changeOrder, checklists]
  );

  useSignalRChecklist({
    addChecklist,
    addChecklistItem,
    removeChecklist,
    removeChecklistItem,
    setChecklists,
    updateChecklist,
    updateChecklistItem,
    updateIsChecked,
  });

  const value = useMemo(
    () => ({
      checklists,
      loadChecklists,
      addChecklist,
      updateChecklist,
      removeChecklist,
      onDragEnd,
      checklistItems,
      addChecklistItem,
      updateChecklistItem,
      updateIsChecked,
      removeChecklistItem,
      updateChecklistItemTimes,
    }),
    [
      checklists,
      loadChecklists,
      addChecklist,
      updateChecklist,
      removeChecklist,
      onDragEnd,
      checklistItems,
      addChecklistItem,
      updateChecklistItem,
      updateIsChecked,
      removeChecklistItem,
      updateChecklistItemTimes,
    ]
  );

  return (
    <ChecklistsContext.Provider value={value}>
      {children}
    </ChecklistsContext.Provider>
  );
};

export default memo(ChecklistsProvider);
