import { FormInput } from "@4uhub/lib4uhub";

import { useTranslation } from "react-i18next";

const GeneralFilter = () => {
  const { t } = useTranslation();

  return (
    <FormInput
      autoFocus
      name="keyword"
      id="standard-basic"
      label={t("components.boards.filters.title")}
      variant="outlined"
      size="small"
      helperText={t("components.boards.filters.helperText")}
    />
  );
};

export default GeneralFilter;
