import { Grid, Stack, Typography } from "@mui/material";
import { Modal, useRole } from "@4uhub/lib4uhub";
import WorkspaceModalCreate from "../Workspaces/WorkspaceModalCreate";
import { useTranslation } from "react-i18next";
import { memo, useState } from "react";

const WorspaceAddItem: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const onOpenModal = () => setOpenModal(true);
  const onCloseModal = () => setOpenModal(false);

  const createPermission = useRole(["Create"]);

  const handleClick = () => {
    onOpenModal();
  };

  if (!createPermission) {
    return null;
  }

  return (
    <Grid item sm={2.5} xs={6}>
      <Modal
        open={openModal}
        onClose={onCloseModal}
        title={`${t("components.workspace.title")}`}
      >
        <WorkspaceModalCreate handleClose={onCloseModal} />
      </Modal>
      <Stack
        p={5}
        onClick={handleClick}
        sx={(t) => ({
          borderRadius: t.shape.borderRadius / 10,
          backgroundColor: t.palette.primary.main,
          cursor: "pointer",
          color: t.palette.primary.contrastText,
          "&:hover": {
            backgroundColor: t.palette.primary.dark,
          },
        })}
      >
        <Typography
          color={"inherit"}
          width={"100%"}
          textOverflow={"ellipsis"}
          overflow={"hidden"}
        >
          {t("components.workspace.create_workspace")}
        </Typography>
      </Stack>
    </Grid>
  );
};

export default memo(WorspaceAddItem);
