import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useMemo,
  useState,
  memo,
} from "react";

import { IGetTicket } from "../../../models/ticket";
import useSignalRFlow from "./useSignalRFlow";
import { ISelectType } from "@4uhub/lib4uhub";

interface IFlow {
  selectedTicket?: string;
  openedTickets: IGetTicket[];
  userTickets: IGetTicket[];
  filteredLists: ISelectType[];
  expanded: boolean;
  boardId: string;
  setSelectedTicket: Dispatch<SetStateAction<string | undefined>>;
  setUserTickets: (tickets: IGetTicket[]) => void;
  setOpenedTickets: (tickets: IGetTicket[]) => void;
  openTicket: (id: string) => void;
  joinTicket: (t: IGetTicket) => void;
  finishTicket: (id: string) => void;
  archiveTicket: (id: string) => void;
  switchExpand: () => void;
  setFilteredLists: (workspaces: ISelectType[]) => void;
}

export const FlowContext = createContext<IFlow | undefined>(undefined);

interface IFlowContextProvider {
  children: any;
  boardId: string;
}

const FlowProvider: React.FC<IFlowContextProvider> = ({
  children,
  boardId,
}) => {
  const [selectedTicket, setSelectedTicket] = useState<string>();

  const [userTickets, setUserTickets] = useState<IGetTicket[]>([]);

  const [expanded, setExpanded] = useState(false);

  const [openedTickets, setOpenedTickets] = useState<IGetTicket[]>([]);

  const [filteredLists, setFilteredLists] = useState<ISelectType[]>([]);

  const openTicket = useCallback((id: string) => {
    setSelectedTicket(id);
  }, []);

  const finishTicket = useCallback((id: string) => {
    setUserTickets((t) => t.filter((t) => t.id !== id));
    setSelectedTicket(undefined);
  }, []);

  const switchExpand = useCallback(() => {
    setExpanded((e) => !e);
  }, []);

  const joinTicket = useCallback((getTicket: IGetTicket) => {
    setOpenedTickets((t) => t.filter((ticket) => ticket.id !== getTicket.id));
    setUserTickets((tickets) => [...tickets, getTicket]);
  }, []);

  const archiveTicket = useCallback((id: string) => {
    setOpenedTickets((t) => t.filter((ticket) => ticket.id !== id));
    setUserTickets((t) => t.filter((ticket) => ticket.id !== id));
    setSelectedTicket(undefined);
  }, []);

  const changeTicket = useCallback(
    (ticketId: string, callback: (t: IGetTicket) => IGetTicket) => {
      const mapFunction = (tickets: IGetTicket[]) => {
        const newTickets = tickets.map((t) => {
          let newTicket = t;

          if (t.id === ticketId) {
            newTicket = callback(t);
          }

          return newTicket;
        });

        return newTickets;
      };

      setOpenedTickets(mapFunction);
      setUserTickets(mapFunction);
    },
    []
  );

  useSignalRFlow({ changeTicket, setOpenedTickets, setUserTickets, boardId });

  const value = useMemo(
    () => ({
      expanded,
      openedTickets,
      selectedTicket,
      userTickets,
      filteredLists,
      boardId,
      setSelectedTicket,
      openTicket,
      finishTicket,
      joinTicket,
      setOpenedTickets,
      archiveTicket,
      setUserTickets,
      switchExpand,
      setFilteredLists,
    }),
    [
      userTickets,
      expanded,
      selectedTicket,
      openedTickets,
      filteredLists,
      boardId,
      setSelectedTicket,
      openTicket,
      finishTicket,
      joinTicket,
      setOpenedTickets,
      archiveTicket,
      setUserTickets,
      switchExpand,
      setFilteredLists,
    ]
  );

  return <FlowContext.Provider value={value}>{children}</FlowContext.Provider>;
};

export default memo(FlowProvider);
