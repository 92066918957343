import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { Button, Form, FormInput } from "@4uhub/lib4uhub";
import { useForm } from "react-hook-form";
import { getDomain } from "../../services/domain.service";

const loginFormSchema = z.object({
  username: z.string().nonempty("O login é obrigatório"),
  password: z.string().min(6, "A senha precisa de no mínimo 6 caracteres"),
});

export type TLoginFormData = z.infer<typeof loginFormSchema>;

interface ILoginForm {
  onSubmit: (values: TLoginFormData) => Promise<void>;
  loading: boolean;
}

const isLocalHost = window.location.hostname === "localhost";

const LoginForm: React.FC<ILoginForm> = ({ onSubmit, loading }) => {
  const crmDomain = getDomain().split("-");

  const adminDomain = crmDomain[0].includes("dev")
    ? "https://dev.4uhub.com.br"
    : crmDomain[0].includes("hml")
    ? "https://admin.hml.4uhub.com.br"
    : "https://admin.4uhub.com.br";

  const APP_4UHUB_LINK = isLocalHost
    ? "http://localhost:3006"
    : crmDomain.length === 1
    ? adminDomain
    : `https://${crmDomain[0]}${crmDomain[1].substring(3)}`;

  const methods = useForm<TLoginFormData>({
    resolver: zodResolver(loginFormSchema),
  });
  const { t } = useTranslation();

  const { handleSubmit } = methods;

  return (
    <Form {...methods}>
      <Box
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Typography variant="h6" align="center" mb={3}>
          {t("page.login.welcome")}
        </Typography>
        <FormInput
          size="small"
          autoFocus={true}
          name="username"
          autoComplete="username"
          fullWidth
          label={t("page.login.username")}
          sx={{ mb: 2 }}
        />
        <FormInput
          size="small"
          name="password"
          fullWidth
          autoComplete="current-password"
          type="password"
          label={t("page.login.password")}
          sx={{ mb: 2 }}
        />
        <Stack
          justifyContent={"space-between"}
          direction={"row"}
          mb={2}
          alignItems={"center"}
        >
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label={
              <Typography variant="body2">
                {" "}
                {t("page.login.rememberme")}
              </Typography>
            }
          />
          <Link
            href={`${APP_4UHUB_LINK}/forget-password`}
            underline="none"
            textAlign={"right"}
          >
            <Typography variant="body2">
              {t("page.login.forget_password")}
            </Typography>
          </Link>
        </Stack>
        <Button variant="contained" type="submit" loading={loading}>
          {t("page.login.login")}
        </Button>
      </Box>
    </Form>
  );
};

export default LoginForm;
