import { Stack, Typography } from "@mui/material";

interface INumberContianerProps {
  children: number;
}
const NumberContainer: React.FC<INumberContianerProps> = ({ children }) => {
  return (
    <Stack
      sx={(theme) => ({
        background: theme.palette.common.white,
        borderRadius: 40,
        width: 25,
        height: 25,
      })}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Typography fontSize={14}>{children}</Typography>
    </Stack>
  );
};

export default NumberContainer;
