import { GlobalAxios } from "../axios/axios-config";
import { IMember } from "../models/members";

const TICKET_CHANNEL_PROFILES_ROUTE =
  process.env.REACT_APP_TICKETS +
  "/api/v1/TicketChannelProfiles/TicketChannel/";

export class TicketChannelProfilesService {
  getItemById = (id: string) => {
    return GlobalAxios.get<IMember[]>(TICKET_CHANNEL_PROFILES_ROUTE + id);
  };
}

export default TicketChannelProfilesService;
