import { Stack } from "@mui/material";
import { useState } from "react";
import HistoryComment from "./HitoryComment";
import { useUser } from "@4uhub/lib4uhub";
import OpenCommentInput from "./OpenCommentInput";
import ProfileImage from "./ProfileImage";

const HistoryComments = () => {
  const [showComment, setShowComment] = useState(false);
  const { user } = useUser();

  const showCommentHandler = () => setShowComment(true);
  const hiddenCommentHandler = () => setShowComment(false);

  return (
    <Stack
      mt={1}
      width={"100%"}
      flexDirection={"row"}
      alignItems={showComment ? "start" : "center"}
      gap={1}
    >
      <ProfileImage fullName={user.fullName} />

      {!showComment && (
        <OpenCommentInput openCommentInput={showCommentHandler} />
      )}

      {showComment && <HistoryComment onCancel={hiddenCommentHandler} />}
    </Stack>
  );
};

export default HistoryComments;
