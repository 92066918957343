import {
  Box,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const NoAccessPage = () => {
  const { t } = useTranslation();

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <main style={{ width: !matches ? "50%" : "100%" }}>
        <Stack spacing={1} alignItems={"center"}>
          <img
            src="/falling-down.png"
            width={"60%"}
            style={{ marginBottom: 20 }}
            alt="ilustration to represent no access to content"
          ></img>
          <Typography variant="h5" mb={2}>
            {t("page.noAccess.message")}
          </Typography>
        </Stack>
      </main>
    </Box>
  );
};

export default NoAccessPage;
