import { ISearchParams } from "@4uhub/lib4uhub/dist/types/models/requests";

export enum EFilterBy {
  CREATE = "0",
  FINISH = "1",
}

export interface IAdvancedSearchBoard {
  id: string;
  ticketWorkspaceId: string;
  name: string;
  enable: boolean;
}

interface IStep {
  id: string;
  status: string | null;
  name: string;
}

export interface ICustomer {
  id: string;
  document: string;
  name: string;
}

export interface ITicketOrigin {
  name: string;
}

export interface IAdvancedSearchResult {
  id: string;
  title: string;
  protocolNumber: string;
  cardNumber: number;
  hasAccess: boolean;
  created: string;
  createdBy: string;
  startDate: string;
  finishDate: string | null;
  board: IAdvancedSearchBoard;
  step: IStep;
  individualCustomer: ICustomer | null;
  legalEntityCustomer: ICustomer | null;
  ticketOrigin: ITicketOrigin | null;
}

export interface IAdvancedSearchParams extends ISearchParams {
  TicketWorkspaceId?: string;
  DateFilterType: EFilterBy;
  TicketChannelId?: string;
  StatusId?: string;
  StartDate?: string;
  FinishDate?: string;
  Title?: string;
  IndividualRequesterName?: string;
  IndividualCustomerName?: string;
  SearchBy?: string;
  CreatedBy?: string[];
}
