import { Box, Typography } from "@mui/material";
import { memo, useCallback } from "react";
import { IStatusDataProps } from "./models";
import { useTranslation } from "react-i18next";

export const StatusData = memo(({ label, data, icon }: IStatusDataProps) => {
  const {
    i18n: { language },
  } = useTranslation();

  const formatDate = useCallback(
    (date: string) =>
      new Date(date)
        .toLocaleString(language, {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        })
        .replaceAll(",", ""),
    [language]
  );

  return (
    <Box
      sx={(t) => ({
        p: 1,
        display: "flex",
        flexDirection: "column",
        borderBottom: 1,
        borderBottomColor: t.palette.divider,
        borderRadius: 1,
        transition: "ease-in-out 0.2s",
        "&:hover": {
          backgroundColor:
            t.palette.grey[t.palette.mode === "light" ? 200 : 800],
        },
      })}
    >
      <Typography fontWeight="bold" variant="subtitle2">
        {label}:
      </Typography>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
        }}
      >
        {icon}
        <Typography sx={{ height: 21 }} variant="subtitle2">
          {data ? formatDate(data) : "-"}
        </Typography>
      </Box>
    </Box>
  );
});
