import { z } from "zod";

const translationPath = "components.tagForm.errors.";

export const tagSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, translationPath + "name"),
  enable: z.boolean(),
  displayOrder: z.string().min(1, translationPath + "display_order"),
  color: z.string().min(1, translationPath + "color"),
});

export type TTagForm = z.infer<typeof tagSchema>;
