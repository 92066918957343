import { Avatar } from "@mui/material";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";

interface IAvatarImageProps {
  imageSrc?: string;
  width?: number;
  height?: number;
}

const AvatarImage = ({ imageSrc, height, width }: IAvatarImageProps) => {
  return (
    <Avatar
      sx={{ width: width ? width : 25, height: height ? height : 25,color: 'white' }}
      src={imageSrc}
    >
      {!imageSrc && <PersonRoundedIcon fontSize="small" />}
    </Avatar>
  );
};

export default AvatarImage;
