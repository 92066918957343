import { Button, useFetch } from "@4uhub/lib4uhub";
import { memo, useCallback, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import AddCardModal from "../../Kamban/Column/AddCard/AddCardModal";
import { getColumnsByBoardId } from "../../../services/column.serivice";
import { IGetColumn } from "../../../models/columns";
import { Box, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import useFlow from "../../../hooks/useFlow";
import { IGetTicket } from "../../../models/ticket";

const translationPath = "page.card.flow.addTicketButton.";

export const AddTicketButton = memo(() => {
  const { t } = useTranslation();

  const { openTicket, boardId } = useFlow();

  const [column, setColumn] = useState<IGetColumn | null>(null);

  const [open, setOpen] = useState(false);

  const openHandler = useCallback(() => {
    setOpen(true);
  }, []);

  const closeHandler = useCallback(() => {
    setOpen(false);
  }, []);

  const { sendRequest } = useFetch(getColumnsByBoardId);

  const fetchColumns = useCallback(async () => {
    if (!boardId) return;
    const { data, success } = await sendRequest({
      bId: boardId,
      statusCode: "1",
    });
    if (data && success) {
      if (data.length > 0) {
        setColumn(data[0]);
      }
    }
  }, [sendRequest, boardId]);

  useEffect(() => {
    fetchColumns();
  }, [fetchColumns]);

  const addTicketHandler = useCallback(
    (card: IGetTicket) => {
      openTicket(card.id);
    },
    [openTicket]
  );

  return (
    <>
      <AddCardModal
        open={open}
        onClose={closeHandler}
        onOpenTicket={addTicketHandler}
        listId={column ? column.id : ""}
        listName={column ? column.name : ""}
      />
      <Tooltip
        title={t(translationPath + "tooltip")}
        disableHoverListener={!!column}
        disableFocusListener={!!column}
      >
        <Box>
          <Button
            variant="contained"
            size="small"
            startIcon={<AddIcon />}
            disabled={!column}
            onClick={openHandler}
          >
            {t(translationPath + "btn_label")}
          </Button>
        </Box>
      </Tooltip>
    </>
  );
});
