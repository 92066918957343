import {
  Avatar,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { IMember } from "../../../../models/members";
import { useTranslation } from "react-i18next";
import { getInitials, stringToColor } from "@4uhub/lib4uhub";

const translationPath = "components.boardHeader.member.";

const MemberData = ({
  member,
  userFullname,
}: {
  member: IMember;
  userFullname: string;
}) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Stack
      width={matches ? "60%" : "100%"}
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      gap={2}
    >
      <Avatar sx={{ backgroundColor: stringToColor(member.user.fullName) }}>
        {getInitials(member.user.fullName)}
      </Avatar>
      <Stack display={"flex"} flexDirection={"column"}>
        <Typography>
          {member.user.fullName}{" "}
          {member.user.fullName === userFullname
            ? t(translationPath + "you")
            : undefined}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={(theme) => ({
            color:
              theme.palette.mode === "light"
                ? theme.palette.grey[600]
                : theme.palette.grey[800],
          })}
        >
          {member.user.userName}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default MemberData;
