import { useFetch } from "@4uhub/lib4uhub";
import ArchiveIcon from "@mui/icons-material/Archive";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";

import { archiveTicketById } from "../../../services/ticket.service";
import { useTicketHeader } from "../../../store/context/card-header-context";

const ArchiveAction: React.FC = () => {
  const { ticketId, onArchive } = useTicketHeader();

  const { sendRequest } = useFetch(archiveTicketById);

  const { t } = useTranslation();

  const onClick = useCallback(async () => {
    if (!ticketId) return;
    await sendRequest(ticketId);
    onArchive && onArchive(ticketId);
  }, [sendRequest, ticketId, onArchive]);

  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <ArchiveIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText> {t("page.card.actions.archive")}</ListItemText>
    </MenuItem>
  );
};

export default ArchiveAction;
