import { Button } from "@4uhub/lib4uhub";
import AccessTimeFilledRoundedIcon from "@mui/icons-material/AccessTimeFilledRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { IRestartMessagePopUpProps } from "./models";
import DoneIcon from "@mui/icons-material/Done";

const translationPath = "components.chat.restartMessagePopUp.";

export const RestartMessagePopUp = memo(
  ({ onClickRestart, onClickFinish }: IRestartMessagePopUpProps) => {
    const { t } = useTranslation();

    const theme = useTheme();

    const matches = useMediaQuery(theme.breakpoints.down("sm"));

    return (
      <motion.div
        initial={{ opacity: 0, translateY: 5 }}
        animate={{ opacity: 1, translateY: 0 }}
        transition={{ duration: 0.5, ease: [0.6, -0.05, 0.01, 0.99] }}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          left: 0,
          bottom: matches ? 90 : 65,
          zIndex: 10,
        }}
      >
        <Box
          sx={(t) => ({
            width: "90%",
            p: 1,
            display: "flex",
            flexDirection: matches ? "column" : "row",
            alignItems: matches ? "end" : "center",
            justifyContent: "space-between",
            gap: matches ? 2 : 0,
            backgroundColor: t.palette.background.default,
            borderRadius: 1,
            boxShadow: 1,
          })}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: matches ? "start" : "center",
              gap: 2,
            }}
          >
            <AccessTimeFilledRoundedIcon fontSize="large" color="error" />
            <Box>
              <Typography
                fontWeight="bold"
                sx={(t) => ({ color: t.palette.text.primary })}
              >
                {t(`${translationPath}title`)}
              </Typography>
              <Typography
                sx={(t) => ({
                  color: t.palette.grey[t.palette.mode === "light" ? 600 : 400],
                })}
                variant="body2"
              >
                {t(`${translationPath}subtitle`)}
              </Typography>
            </Box>
          </Box>
          <Button
            variant="contained"
            endIcon={<ArrowForwardRoundedIcon />}
            onClick={onClickRestart}
            color="inherit"
            sx={(t) => ({
              mr: 1,
              color: t.palette.text.primary,
            })}
            fullWidth={matches}
          >
            {t(`${translationPath}buttonRestart`)}
          </Button>
          <Button
            variant="contained"
            onClick={onClickFinish}
            fullWidth={matches}
            endIcon={<DoneIcon />}
          >
            {t(`${translationPath}buttonFinish`)}
          </Button>
        </Box>
      </motion.div>
    );
  }
);
