import { Stack } from "@mui/material";

import useMessageInput from "../../../hooks/useMessageInput";
import InputFile from "./InputFile";

const InputFiles = () => {
  const { files } = useMessageInput();

  return (
    <Stack
      flexDirection={"row"}
      gap={1}
      alignItems={"flex-start"}
      pl={1}
      overflow={"auto"}
      width={"100%"}
    >
      {files.map((f, i) => (
        <InputFile key={i} {...f} />
      ))}
    </Stack>
  );
};

export default InputFiles;
