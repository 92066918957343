import { memo } from "react";

import BoardHeader from "./Header/BoardHeader";
import BoardBody from "./BoardBody";
import BoardContainer from "./BoardContainer";

const BoardContent: React.FC<{ name: string }> = ({ name }) => {
  return (
    <BoardContainer>
      <BoardHeader title={name} />
      <BoardBody />
    </BoardContainer>
  );
};
export default memo(BoardContent);
