import { Stack } from "@mui/material";
import { memo } from "react";

import UserBoards from "./UserBoards";
import ViewBoards from "./ViewBords";

const MenuBoards = () => {
  return (
    <Stack
      overflow={"auto"}
      sx={(t) => ({
        "&::-webkit-scrollbar": {
          width: "0.4em",
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          backgroundColor:
            t.palette.mode === "light"
              ? t.palette.grey[400]
              : t.palette.grey[900],
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor:
            t.palette.mode === "light"
              ? t.palette.grey[200]
              : t.palette.grey[700],
          borderRadius: 5,
        },
      })}
    >
      <UserBoards />
      <ViewBoards />
    </Stack>
  );
};

export default memo(MenuBoards);
