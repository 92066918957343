import { Box, Typography } from "@mui/material";
import { memo } from "react";
import { INoMessagesHeaderProps } from "./models";

export const NoMessagesHeader = memo(
  ({ title, subtitle }: INoMessagesHeaderProps) => {
    return (
      <Box
        sx={{
          mb: 2,
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          {title}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={(t) => ({
            color: t.palette.grey[t.palette.mode === "light" ? 600 : 400],
          })}
        >
          {subtitle}
        </Typography>
      </Box>
    );
  }
);
