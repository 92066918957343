import { ISelectType } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";
import { memo } from "react";

import Property from "../../Property";
import Status from "./Status";
import CardStatusSelector from "./CardStatusSelector";
import useGeneric from "../../../../hooks/useGeneric";
import usePopover from "../../../../hooks/usePopover";
import HoverOpacity from "../../../UI/HoverOpacity";

interface ICardStatusProps {
  loading?: boolean;
  status: ISelectType;
  id: string;
}

const CardStatus: React.FC<ICardStatusProps> = ({ status, loading, id }) => {
  const {
    anchorEl,
    handleClick,
    handleClose,
    id: PopoverId,
    open,
  } = usePopover("ticket-status-popover");

  const { t } = useTranslation();

  const { data } = useGeneric("ticketStatus");

  const handleChange = () => {
    handleClose();
  };

  return (
    <>
      <HoverOpacity onClick={handleClick} disabled={true}>
        <Property
          label={t("page.card.status")}
          loading={loading}
          content={<Status data={status} />}
        />
      </HoverOpacity>
      {open && (
        <CardStatusSelector
          data={data}
          selected={status.id}
          ticketId={id}
          anchorEl={anchorEl}
          id={PopoverId}
          open={open}
          onChange={handleChange}
          onClose={handleClose}
        />
      )}
    </>
  );
};

export default memo(CardStatus);
