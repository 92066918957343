import ReactDOM from "react-dom/client";
import "./index.css";
import 'react-photo-view/dist/react-photo-view.css';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import { NotificationProvider } from "@4uhub/lib4uhub";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <NotificationProvider notificationDuration={2000}>
    <App />
  </NotificationProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
