import { Checkbox } from "@mui/material";
import TicketChecklistItemService from "../../../../services/ticketChecklistItem.service";
import { useFetch } from "@4uhub/lib4uhub";
import { memo, useCallback } from "react";
import useChecklists from "../../../../hooks/useChecklists";
import { IChecklistItem } from "../../../../models/ticketChecklist";
import useTicket from "../../../../hooks/useTask";

const checklistItemService = new TicketChecklistItemService();

interface IChecklistItemCheck {
  isChecked: boolean;
  checklistItem: IChecklistItem;
  checklistId: string;
  actualHeight: number;
}

const ChecklistItemCheck: React.FC<IChecklistItemCheck> = ({
  isChecked,
  checklistItem,
  actualHeight,
}) => {
  const { sendRequest: handleUpdateIsChecked } = useFetch(
    checklistItemService.updateIsChecked
  );
  const { updateIsChecked } = useChecklists();

  const {
    ticket: { status },
  } = useTicket();

  const toggleIsCheckedHandler = useCallback(
    async (value: boolean) => {
      const { success } = await handleUpdateIsChecked({
        item: {
          id: checklistItem.id,
          isChecked: value,
        },
      });
      if (success) {
        updateIsChecked({
          id: checklistItem.id,
          checklistId: checklistItem.ticketChecklistId,
          endDate: value ? new Date().toISOString() : null,
          isChecked: value,
        });
      }
    },
    [handleUpdateIsChecked, checklistItem, updateIsChecked]
  );

  return (
    <Checkbox
      size="small"
      checked={isChecked}
      disabled={status.code === "3"}
      onChange={(e) => toggleIsCheckedHandler(e.target.checked)}
      sx={{ padding: 0, pr: 0.5 }}
    />
  );
};

export default memo(ChecklistItemCheck);
