import { PaletteColor, ThemeOptions } from "@mui/material";

const primaryColor: PaletteColor = {
  main: "#2d398f",
  light: "#5760A5",
  dark: "#181f57",
  contrastText: "#FFFFFF",
};
export const themeOptions = (mode: "light" | "dark"): ThemeOptions => {
  return {
    components: {
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
      },
    },
    typography: {
      button: {
        textTransform: "none",
      },
    },
    palette: {
      mode: mode,
      primary: primaryColor,
      common: {
        black: "#121212",
      },
      contrastThreshold: 4.5,
      background: {
        default: mode === "dark" ? "#121212" : "#FFFFFF",
      },
    },
    shape: {
      borderRadius: 8,
    },
  };
};