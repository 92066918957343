import { CircularProgress, Stack, Typography } from "@mui/material";

import { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import CardMember from "./CardMember";
import useBoardRole from "../../../hooks/useBoardRole";
import CardMembersActions from "./CardMembersActions";
import useTicket from "../../../hooks/useTask";

const translationPath = "components.cardMembers.";

const CardMembers: React.FC = () => {
  const { t } = useTranslation();

  const {
    isUserTicketMember,
    ticket: { ticketMembers, status },
  } = useTicket();

  const [isLoading, setIsLoading] = useState(false);

  const permission = useBoardRole("2");

  const isLoadingHandler = useCallback((value: boolean) => {
    setIsLoading(value);
  }, []);

  return (
    <Stack>
      <Typography
        variant="caption"
        fontWeight={"bold"}
        color={(t) => t.palette.text.primary}
        mb={0.2}
      >
        {t(translationPath + "members")}
      </Typography>
      <Stack flexDirection={"row"} flexWrap={"wrap"} gap={0.5}>
        {isLoading && (
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress size={20} />
          </Stack>
        )}

        {!isLoading &&
          ticketMembers.map((member, key) => (
            <CardMember
              tooltip={member.user.fullName}
              name={member.user.fullName}
              key={member.id + key}
              disabled={!permission || !isUserTicketMember}
              id={member.user.id}
            />
          ))}

        {permission && status.code !== "3" && (
          <CardMembersActions
            cardMembers={ticketMembers}
            onLoading={isLoadingHandler}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default memo(CardMembers);
