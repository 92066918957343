import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IGetBoard } from "../../models/Board";
import { memo } from "react";

interface IBoardListITemProps {
  showWorkspace?: boolean;
  navigatePath: string;
}

const BoardListITem: React.FC<IGetBoard & IBoardListITemProps> = ({
  id,
  name,
  showWorkspace,
  ticketWorkspace,
  navigatePath,
}) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(navigatePath);
  };

  return (
    <Stack
      sx={(t) => ({
        padding: t.spacing(showWorkspace ? 2 : 4),
        borderRadius: 1,
        transition: "all .2s ease-in-out",
        cursor: "pointer",
        backgroundColor: t.palette.action.disabledBackground,
        "&:hover": {
          backgroundColor: t.palette.action.hover,
        },
      })}
      gap={2}
      onClick={onClick}
    >
      <Typography color={(t) => t.palette.text.primary} fontWeight={"bold"} textOverflow={'ellipsis'} overflow={'hidden'}>
        {name}
      </Typography>
      {showWorkspace && (
        <Typography color={(t) => t.palette.text.primary} variant="button" textOverflow={'ellipsis'} overflow={'hidden'}>
          {ticketWorkspace.name}
        </Typography>
      )}
    </Stack>
  );
};

export default memo(BoardListITem);
