import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";

export const getGenericList = (params: any) => {
  return GlobalAxios.get<ISelectType[]>(
    process.env.REACT_APP_MAIN + "/api/v1/Generic/list",
    {
      params,
      paramsSerializer: {
        indexes: null,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const getGenericById = (genericId: string) => {
  return GlobalAxios.get<ISelectType>(
    process.env.REACT_APP_MAIN + "/api/v1/Generic/" + genericId
  );
};
