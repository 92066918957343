import {
  IRequestItem,
  ISearchParams,
  ISearchReturn,
} from "@4uhub/lib4uhub/dist/types/models";
import { GlobalAxios } from "../axios/axios-config";
import { ISelectType } from "../models/selects";
import { INewTag } from "../models/tags";
import { TTagForm } from "../components/CardPage/CardTags/TagForm/TagSchema";

const TAGS_BASIC_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketChannelTags/";

export class TagsService<T extends IRequestItem> {
  create = (item: INewTag) => {
    return GlobalAxios.post(TAGS_BASIC_ROUTE, item);
  };

  getAll = (serchParams?: ISearchParams) => {
    return GlobalAxios.get<ISearchReturn<T>>(TAGS_BASIC_ROUTE, {
      params: serchParams,
    });
  };

  getItemById = (id: string) => {
    return GlobalAxios.get<TTagForm>(TAGS_BASIC_ROUTE + id);
  };

  getTagsTicketId = (id: string) => {
    return GlobalAxios.get<T[]>(TAGS_BASIC_ROUTE + "TicketChannel/" + id);
  };

  updateItemById = ({ item, id }: { item: INewTag; id: string }) => {
    return GlobalAxios.put(TAGS_BASIC_ROUTE + id, item);
  };

  deleteItemById = (id: string) => {
    return GlobalAxios.delete(TAGS_BASIC_ROUTE + id);
  };

  list = (params?: any) => {
    return GlobalAxios.get<ISelectType[]>(TAGS_BASIC_ROUTE + "list", {
      params: params,
    });
  };
}

export default TagsService;
