import { Box, Tooltip } from "@mui/material";
import IconButton from "../../../../../UI/IconButton";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IDetailFileActionsProps } from "./models";
import { memo, useCallback } from "react";
import { useFetch } from "@4uhub/lib4uhub";
import { getFileUrl } from "../../../../../../services/fileManager.service";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const translationPath = "components.detailFileActions.";

const DetailFileActions = ({
  media,
  isExpanded,
  onClickExpand,
}: IDetailFileActionsProps) => {
  const { t } = useTranslation();

  const { sendRequest, loading } = useFetch(getFileUrl);

  const fetchFileUrl = useCallback(async () => {
    const { data, success } = await sendRequest(media.filePath);
    if (data && success) {
      window.open(data, "_blank");
    }
  }, [sendRequest, media.filePath]);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Tooltip title={t(translationPath + "view_file")} placement="left">
        <Box>
          <IconButton size="small" loading={loading} onClick={fetchFileUrl}>
            <OpenInNewIcon fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>
      <Tooltip title={t(translationPath + "view_more")} placement="right">
        <Box>
          <IconButton size="small" onClick={onClickExpand}>
            <motion.div
              animate={{ rotate: !isExpanded ? 0 : 180 }}
              transition={{ type: "tween", duration: 0.2, ease: "easeOut" }}
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ExpandMoreIcon fontSize="small" />
            </motion.div>
          </IconButton>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default memo(DetailFileActions);
