import { ISelectType } from "@4uhub/lib4uhub";
import { memo } from "react";
import ServiceTypeIcon from "../../Kamban/ServiceTypeIcon";

interface IServiceTypeProps {
  type: ISelectType;
}

const ServiceType: React.FC<IServiceTypeProps> = ({ type }) => {
  return (
    <ServiceTypeIcon
      type={type}
      iconProps={{
        fontSize: "small",
        sx: (t) => ({
          color: t.palette.grey[500],
        }),
      }}
    />
  );
};

export default memo(ServiceType);
