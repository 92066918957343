import { Box } from "@mui/material";
import { Variants, motion } from "framer-motion";
import { Droppable } from "react-beautiful-dnd";

import TaskList from "./TaskList/TaskList";
import { memo } from "react";

const variants: Variants = {
  open: { width: 240 },
  closed: { width: 0, display: "none" },
};

interface ITaskContainerProps {
  open: boolean;
  backgroundColor?: string | null;
  id: string;
  isView: boolean;
}

const TaskContainer: React.FC<ITaskContainerProps> = ({
  id,
  isView,
  open,
  backgroundColor,
}) => {
  return (
    <motion.div
      variants={variants}
      animate={open ? "open" : "closed"}
      initial={{ width: 240 }}
      className="column-content"
      style={{
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={(t) => ({
          overflowY: "auto",
          paddingRight: t.spacing(0.5),
          "&::-webkit-scrollbar": {
            width: "0.4em",
            marginLeft: 20,
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            backgroundColor: t.palette.augmentColor({
              color: {
                main: backgroundColor || t.palette.grey[500],
              },
            }).dark,
            borderRadius: 5,
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: t.palette.augmentColor({
              color: {
                main: backgroundColor || t.palette.grey[500],
              },
            }).light,
            borderRadius: 5,
          },
        })}
      >
        <Droppable
          droppableId={id}
          type="task"
          direction="vertical"
          ignoreContainerClipping={false}
        >
          {({ droppableProps, innerRef, placeholder }) => (
            <div ref={innerRef} {...droppableProps} style={{ minHeight: 1 }}>
              <TaskList id={id} disable={isView} />
              {placeholder}
            </div>
          )}
        </Droppable>
      </Box>
    </motion.div>
  );
};

export default memo(TaskContainer);
