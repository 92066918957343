import { Box, CircularProgress } from "@mui/material";
import NoResults from "./NoResults/NoResults";
import { useAdvancedSearchContext } from "../../store/context/advanced-search-context";
import SearchResultsList from "./SearchResultsList";
import { memo, useEffect, useLayoutEffect, useState } from "react";
import { INoResultsProps } from "./NoResults/models";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const translationPath = "components.searchResults.";

const SearchResults = () => {
  const { t } = useTranslation();

  const {
    searchResult,
    workspace,
    startDate,
    endDate,
    isRangeBigger,
    loading,
    pageNumber,
    onInitialSearchHandler,
  } = useAdvancedSearchContext();

  const { protocolOrId } = useParams();

  const [noResult, setNoResult] = useState<INoResultsProps>();

  useEffect(() => {
    if (protocolOrId) {
      onInitialSearchHandler();
    }
  }, [protocolOrId, onInitialSearchHandler]);

  useLayoutEffect(() => {
    if (!searchResult) {
      if (!workspace && !startDate && !endDate) {
        return setNoResult({
          title: t(translationPath + "no_workspace_dates.title"),
          subtitle: t(translationPath + "no_workspace_dates.subtitle"),
        });
      }
      if (!workspace && startDate && endDate) {
        return setNoResult({
          title: t(translationPath + "no_workspace.title"),
          subtitle: t(translationPath + "no_workspace.subtitle"),
        });
      }
      if (workspace && (!startDate || !endDate) && !searchResult) {
        return setNoResult({
          title: t(translationPath + "no_dates.title"),
          subtitle: t(translationPath + "no_dates.subtitle"),
        });
      }
      if (workspace && isRangeBigger && !searchResult) {
        return setNoResult({
          title: t(translationPath + "range_bigger.title"),
          subtitle: t(translationPath + "range_bigger.subtitle"),
        });
      }
      if (workspace && startDate && endDate && !searchResult) {
        return setNoResult({
          title: t(translationPath + "filter_click.title"),
          subtitle: t(translationPath + "filter_click.subtitle"),
        });
      }
    } else {
      if (searchResult && searchResult.length === 0) {
        return setNoResult({
          title: t(translationPath + "no_results.title"),
          subtitle: t(translationPath + "no_results.subtitle"),
        });
      }
    }
  }, [endDate, isRangeBigger, searchResult, startDate, workspace, t]);

  if (loading && pageNumber === 1) {
    return (
      <Box
        width={"100%"}
        height={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <CircularProgress size={40} />
      </Box>
    );
  }

  if (!searchResult && noResult) {
    return <NoResults title={noResult.title} subtitle={noResult.subtitle} />;
  }

  if (searchResult && searchResult.length === 0 && noResult) {
    return <NoResults title={noResult.title} subtitle={noResult.subtitle} />;
  }

  return <SearchResultsList />;
};

export default memo(SearchResults);
