import {  IconButton, Stack, Tooltip, Typography } from "@mui/material";
import Property from "../../../CardPage/Property";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { FC, memo, ReactNode } from "react";
import { useNotificationContext } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";

const translationPath = "components.sharedContacts.";

interface SharedContactItemPropertyProps {
  propertyName: string;
  propertyValue: string;
  icon?:  ReactNode;
}

const SharedContactItemProperty: FC<SharedContactItemPropertyProps> = ({
  propertyName,
  propertyValue,
  icon
}) => {
  const { t } = useTranslation();
  const { setMessage } = useNotificationContext();

  const copyHandler = () => {
    navigator.clipboard.writeText(propertyValue);
    setMessage({
      message: t(translationPath + "copied"),
      notificationKey: new Date().getMilliseconds(),
      type: "success",
    });
  };
  return (
    <Property
      content={
        <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
          {icon && (icon)}
          <Typography color={(t) => t.palette.text.primary} fontSize={14}>
            {propertyValue}
          </Typography>
          <Tooltip title={t(translationPath + "copy_tooltip")}>
            <IconButton
              size="small"
              sx={{ p: 0, "&.hover": { opacity: 0.5 }, fontSize: 15 }}
              onClick={copyHandler}
            >
              <ContentCopyIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Stack>
      }
      label={propertyName}
    />
  );
};

export default memo(SharedContactItemProperty);
