import { Box, Popover, Stack, Typography } from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";

import { ITag } from "../../../models/tags";
import Tag from "../../CardPage/CardTags/Tag";

interface ITableMembersProps {
  tags: ITag[];
  maxSize?: number;
  wrap?: boolean
}

const TableTags: React.FC<ITableMembersProps> = ({ tags, maxSize = 2,wrap=true }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const showTags = tags.slice(0, maxSize);

  const PopoverTags = tags.slice(maxSize);

  const restantLength = PopoverTags.length;

  const disabled = restantLength === 0;

  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (disabled) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    if (disabled) {
      return;
    }
    setAnchorEl(null);
  };

  return (
    <>
      <Stack
        flexDirection={"row"}
        flexWrap={wrap ? "wrap" : 'nowrap'}
        overflow={'hidden'}
        gap={0.5}
        mb={0.5}
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        alignItems={"center"}
      >
        {showTags.map((tag: any) => (
          <Box key={tag.id}>
            <Tag data={tag} padding={0} fontSize={14} showTagName={true} />
          </Box>
        ))}
        {!disabled && (
          <Stack
            justifyItems={"center"}
            alignItems={"center"}
            width={30}
            height={30}
            borderRadius={16}
            direction={"row"}
            padding={0.5}
            sx={(t) => ({ backgroundColor: t.palette.divider })}
          >
            <AddIcon sx={{ width: 12, height: 15,marginLeft: -0.4 }} fontSize="small" />
            <Typography variant="caption" mt={0.2}>
              {restantLength}
            </Typography>
          </Stack>
        )}
      </Stack>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
          mt: 1,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Stack
          flexDirection={"row"}
          flexWrap={"wrap"}
          gap={0.5}
          maxWidth={200}
          p={2}
        >
          {PopoverTags.map((tag: any) => (
            <Box key={tag.id}>
              <Tag data={tag} padding={0} fontSize={14} showTagName={true} />
            </Box>
          ))}
        </Stack>
      </Popover>
    </>
  );
};

export default TableTags;
