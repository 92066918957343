import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { memo } from "react";

import useChecklists from "../../../../hooks/useChecklists";
import CardChecklist from "./CardChecklist";

const CardChecklistContainer = () => {
  const { onDragEnd } = useChecklists();

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId={"all-checklists"}
        direction="vertical"
        type="checklist"
        ignoreContainerClipping={false}
      >
        {({ droppableProps, innerRef: checklistRef, placeholder }) => (
          <CardChecklist
            checklistRef={checklistRef}
            droppableProps={droppableProps}
            placeholder={placeholder}
          />
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default memo(CardChecklistContainer);
