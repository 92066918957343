import { memo, useMemo } from "react";

import useTicket from "../../../../../hooks/useTask";
import CustomerChange from "../CustomerChange/CustomerChange";
import { SignalRContext } from "../../../../SignalR/SignalRProvider";
import { ICustomerChannel } from "../../../../../models/channels";
import { getType } from "../../../../Kamban/Task/Task";

const CardCustomer = () => {
  const {
    ticket: {
      individualCustomer,
      legalEntityCustomer,
      ticketOrigins,
      id,
      notIdentifiedName,
      notIdenfiedPhone,
      ticketService,
    },
    onEditEntityCustomer,
    onEditIndividualCustomer,
    onEditticketRelationships,
  } = useTicket();

  SignalRContext.useSignalREffect(
    "TicketCustomerUpdated", // Your Event Key
    (userId: string, data: ICustomerChannel) => {
      if (data.id === id) {
        console.log("TicketCustomerUpdated", data);
        onEditticketRelationships(data.ticketRelationships);
        if (data.individualCustomer) {
          onEditIndividualCustomer(data.individualCustomer);
        }
        if (data.legalEntityCustomer) {
          onEditEntityCustomer(data.legalEntityCustomer);
        }
      }
    },
    [id]
  );

  const individual = useMemo(
    () =>
      individualCustomer?.name ||
      legalEntityCustomer?.corporateName ||
      ticketOrigins[0]?.name ||
      notIdentifiedName ||
      "",
    [individualCustomer, legalEntityCustomer, ticketOrigins, notIdentifiedName]
  );

  return (
    <CustomerChange
      type={getType(individualCustomer, legalEntityCustomer)}
      individual={individual}
      isNotIdentified={!!notIdentifiedName}
      notIdentifiedPhone={notIdenfiedPhone}
      ticketService={ticketService}
      ticketId={id}
    />
  );
};

export default memo(CardCustomer);
