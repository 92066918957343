import {
  AutoCompleteUncontrolled,
  ISelectType,
  useFetch,
} from "@4uhub/lib4uhub";
import { memo, useCallback, useEffect, useState } from "react";
import { useAdvancedSearchContext } from "../../../store/context/advanced-search-context";
import { useTranslation } from "react-i18next";
import { ticketChannelsList } from "../../../services/ticketChannels.service";

const translationPath = "components.advancedSearchFields.";

export const BoardSelect = memo(() => {
  const { t } = useTranslation();

  const [options, setOptions] = useState<ISelectType[]>([]);

  const { ticketChannel, workspace, onChangeTicketChannel } =
    useAdvancedSearchContext();

  const { sendRequest } = useFetch(ticketChannelsList);

  const fetchTicketChannels = useCallback(async () => {
    if (!workspace) return;
    const { data, success } = await sendRequest(workspace.id);
    if (data && success) {
      setOptions(data);
    }
  }, [sendRequest, workspace]);

  useEffect(() => {
    fetchTicketChannels();
  }, [fetchTicketChannels]);

  useEffect(() => {
    onChangeTicketChannel(null);
  }, [workspace, onChangeTicketChannel]);

  return (
    <AutoCompleteUncontrolled
      label={t(translationPath + "board")}
      value={ticketChannel}
      onValueSelected={onChangeTicketChannel}
      options={options}
      size="small"
      fullWidth
    />
  );
});
