import { Box, Typography } from "@mui/material";
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ISearchResultPeriodProps } from "./models";

export const SearchResultPeriod = memo(
  ({ created, finishDate }: ISearchResultPeriodProps) => {
    const {
      i18n: { language },
    } = useTranslation();

    const formatDate = useCallback(
      (date: string) =>
        new Date(date)
          .toLocaleString(language, {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: undefined,
          })
          .replaceAll(",", ""),
      [language]
    );

    return (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography
          variant="body2"
          sx={(t) => ({
            color: t.palette.grey[600],
          })}
        >
          {formatDate(created)}
        </Typography>
        <Typography
          variant="body2"
          sx={(t) => ({
            color: t.palette.grey[600],
          })}
        >
          {finishDate ? formatDate(finishDate) : ""}
        </Typography>
      </Box>
    );
  }
);
