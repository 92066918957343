import { Stack } from "@mui/material";
import { memo } from "react";

import useBoard from "../../hooks/useBoard";

const BoardContainer: React.FC<{ children: any }> = ({ children }) => {
  const { settingsMenu } = useBoard();

  return (
    <Stack
      sx={(t) => ({
        width: settingsMenu ? "calc(100% - 350px)" : "100%",
      })}
    >
      {children}
    </Stack>
  );
};
export default memo(BoardContainer);
