import { createContext, useCallback, memo, useMemo } from "react";
import { IGetColumn } from "../../models/columns";
import { IGetTicket } from "../../models/ticket";
import { ISelectType } from "@4uhub/lib4uhub";
import {
  IOnColorChangeProps,
  IOnNameChangeProps,
  IOnParentChangeProps,
  IOnSetTasksProps,
  IOnStatusChangeProps,
} from "./KanbanContext/useEditColumns/models";

type IColumnContext = {
  data: IGetColumn;
  setColor: (color: string) => void;
  editColumnName: (name: string) => void;
  setColumnTasks: (tasks: IGetTicket[]) => void;
  editColumnParent: (parent: IGetColumn) => void;
  editColumnStatus: (parent: ISelectType) => void;
};

export const ColumnContext = createContext<IColumnContext | undefined>(undefined);

interface IBoardContextProvider {
  children: any;
  columnData: IGetColumn;
  onColorChange: (props: IOnColorChangeProps) => any;
  onNameChange: (props: IOnNameChangeProps) => any;
  onSetTasks: (props: IOnSetTasksProps) => any;
  onParentChange: (props: IOnParentChangeProps) => any;
  onStatusChange: (props: IOnStatusChangeProps) => any;
}

const ColumnProvider: React.FC<IBoardContextProvider> = ({
  children,
  columnData,
  onColorChange,
  onNameChange,
  onSetTasks,
  onStatusChange,
  onParentChange,
}) => {
  const { id } = columnData;

  const setColumnTasks = useCallback(
    (tasks: IGetTicket[]) => {
      onSetTasks({ tasks, id });
    },
    [onSetTasks, id]
  );

  const setColor = useCallback(
    (color: string) => {
      onColorChange({ color, id });
    },
    [onColorChange, id]
  );

  const editColumnName = useCallback(
    (name: string) => {
      onNameChange({ name, id });
    },
    [id, onNameChange]
  );

  const editColumnParent = useCallback(
    (parent: IGetColumn) => {
      onParentChange({ parent, id });
    },
    [id, onParentChange]
  );

  const editColumnStatus = useCallback(
    (status: ISelectType) => {
      onStatusChange({ status, id });
    },
    [id, onStatusChange]
  );

  const value = useMemo(
    () => ({
      data: columnData,
      setColor,
      editColumnStatus,
      editColumnName,
      setColumnTasks,
      editColumnParent,
    }),
    [
      columnData,
      setColor,
      editColumnStatus,
      editColumnName,
      setColumnTasks,
      editColumnParent,
    ]
  );

  return (
    <ColumnContext.Provider value={value}>{children}</ColumnContext.Provider>
  );
};

export default memo(ColumnProvider);
