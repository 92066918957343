import { Popover, Tooltip } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useTranslation } from "react-i18next";
import { memo, useMemo } from "react";

import IconButton from "../../../../UI/IconButton";
import usePopover from "../../../../../hooks/usePopover";
import TimeForm from "./TimeForm";
import { IChecklistItem } from "../../../../../models/ticketChecklist";
import useTicket from "../../../../../hooks/useTask";

interface IChecklistItemTimeProps {
  checklistItem: IChecklistItem;
}

const translationPath = "components.chcecklistItemTime.";

const CardChecklistItemTime = ({ checklistItem }: IChecklistItemTimeProps) => {
  const { t } = useTranslation();

  const {
    ticket: { status },
  } = useTicket();

  const { anchorEl, id, handleClick, handleClose, open } =
    usePopover("time-popover");

  const actualDate = useMemo(() => new Date(), []);

  const startDate = useMemo(
    () => new Date(checklistItem.startDate || ""),
    [checklistItem]
  );

  const estimatedTime = useMemo(
    () => checklistItem.estimatedTime ?? 0,
    [checklistItem]
  );

  const itemInProgress = useMemo(
    () =>
      checklistItem.estimatedTime &&
      checklistItem.startDate &&
      !checklistItem.endDate,
    [checklistItem]
  );

  const isPast = useMemo(
    () =>
      itemInProgress
        ? (actualDate.getTime() - startDate.getTime()) / (1000 * 60) >
          estimatedTime
          ? true
          : false
        : false,
    [actualDate, startDate, itemInProgress, estimatedTime]
  );

  return (
    <>
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          mt: 1,
        }}
      >
        <TimeForm checklistItem={checklistItem} onCloseHandler={handleClose} />
      </Popover>
      <Tooltip
        title={t(translationPath + "tooltip")}
        disableHoverListener={status.code === "3"}
      >
        <div>
          <IconButton
            size="small"
            disabled={status.code === "3"}
            onClick={handleClick}
          >
            <AccessTimeIcon
              fontSize="small"
              color={isPast ? "error" : "inherit"}
            />
          </IconButton>
        </div>
      </Tooltip>
    </>
  );
};

export default memo(CardChecklistItemTime);
