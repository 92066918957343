import { Stack, Typography } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";

interface IListEmptyProps {
  message: string;
}

const ListEmpty: React.FC<IListEmptyProps> = ({ message }) => {
  const { t } = useTranslation();

  return (
    <Stack alignItems={"center"}>
      <img
        src="/empty.png"
        width={"60%"}
        style={{ marginBottom: 20 }}
        alt="ilustration to represent empty list"
      ></img>
      <Typography
        textAlign={"center"}
        variant="h6"
        fontWeight={"bold"}
        width={"80%"}
      >
        {t("components.empty.title")}
      </Typography>
      <Typography textAlign={"center"} width={"80%"}>
        {message}
      </Typography>
    </Stack>
  );
};

export default memo(ListEmpty);
