import { IconButton, Menu, MenuList } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArchiveAction from "./ArchiveAction";
import useBoardRole from "../../../hooks/useBoardRole";
import usePopover from "../../../hooks/usePopover";
import { memo } from "react";

const MoreActions: React.FC = () => {
  const { anchorEl, handleClick, handleClose, id, open } = usePopover(
    "card-more-actions-menu"
  );

  const permission = useBoardRole("2");


  return (
    <>
      <IconButton size="small" onClick={handleClick}>
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{ mt: 1 }}
      >
        <MenuList dense>{permission && <ArchiveAction />}</MenuList>
      </Menu>
    </>
  );
};

export default memo(MoreActions);
