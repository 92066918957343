import { Loading, useFetch } from "@4uhub/lib4uhub";
import { memo, useCallback, useEffect, useMemo } from "react";

import { getTicketByChannelId } from "../../../../services/ticket.service";
import useColumn from "../../../../hooks/useColumn";
import TaskDraggable from "../../Task/TaskDraggable";
import useBoard from "../../../../hooks/useBoard";
import moment from "moment";

interface ITaskListProps {
  id: string;
  disable?: boolean;
}

const TaskList: React.FC<ITaskListProps> = ({ id, disable }) => {
  const { sendRequest, loading } = useFetch(getTicketByChannelId);

  const {
    filter: { status, endDate, initialDate },
  } = useBoard();

  const {
    data: { tasks },
    setColumnTasks,
  } = useColumn();

  const fetchTasks = useCallback(async () => {
    const iValid = moment(initialDate).isValid();

    const eValid = moment(endDate).isValid();

    if (
      ((status === "3" || status === "4") && iValid && eValid) ||
      status === "1" ||
      status === "2" ||
      !status
    ) {
      const { data, success } = await sendRequest({
        Id: id,
        TicketDateFilterCode: status || undefined,
        FinishDate: endDate || undefined,
        StartDate: initialDate || undefined,
      });

      if (data && success) {
        setColumnTasks(data);
      }
    }
  }, [sendRequest, id, setColumnTasks, status, endDate, initialDate]);

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  const orderedTasks = useMemo(
    () => tasks?.sort((a, b) => a.displayOrder - b.displayOrder),
    [tasks]
  );

  if (loading) {
    return <Loading sx={{ position: "relative" }} size={30} />;
  }

  return (
    <>
      {orderedTasks?.map((ticket, index) => {
        return (
          <TaskDraggable
            key={ticket.id}
            index={index}
            {...ticket}
            disableDrag={disable || ticket.status.code === "3"}
          >
            {ticket.content}
          </TaskDraggable>
        );
      })}
    </>
  );
};

export default memo(TaskList);
