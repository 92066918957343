import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { IFile } from "../../../store/context/messageInput-context";
import useMessageInput from "../../../hooks/useMessageInput";
import { memo } from "react";

const InputFile: React.FC<IFile> = ({ file, id }) => {
  const { deleteFileId } = useMessageInput();
  const isPdf = file.type === "application/pdf";
  const isImg = file.type.startsWith("image/");

  return (
    <Box maxWidth={150}>
      <Stack
        p={1}
        pb={0}
        alignItems={"center"}
        justifyContent={"center"}
        overflow={"hidden"}
      >
        <IconButton
          onClick={() => deleteFileId(id)}
          size="small"
          color="error"
          sx={{ alignSelf: "flex-end" }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        {isImg && (
          <img
            src="/documents/image.png"
            width={40}
            style={{ objectFit: "contain" }}
            alt="pdf"
          />
        )}
        {isPdf && (
          <img
            src="/documents/pdf.png"
            width={40}
            style={{ objectFit: "contain" }}
            alt="pdf"
          />
        )}
        {!isPdf && !isImg && (
          <img
            src="/documents/text.png"
            width={40}
            style={{ objectFit: "contain" }}
            alt="generic document"
          />
        )}
        <Tooltip title={file.name}>
          <Typography
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              wordBreak: 'break-all',
              WebkitBoxOrient: "vertical",
            }}
            color={(t) => ({ color: t.palette.text.primary })}
            mt={1}
          >
            {file.name}
          </Typography>
        </Tooltip>
      </Stack>
    </Box>
  );
};

export default memo(InputFile);
