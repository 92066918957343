import { Button, Stack } from "@mui/material";
import { useCallback, useEffect } from "react";
import { Form, FormInput, Modal, useFetch } from "@4uhub/lib4uhub";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";

import useBoard from "../../../hooks/useBoard";
import { createColumn } from "../../../services/column.serivice";
import SelectStatus from "../../Kamban/Column/AddColumn/SelectStatus";
import SelectParentColumn from "../../Kamban/Column/AddColumn/SelectParentColumn";
import { TTableColumnForm, columnTableSchema } from "./AddColumnSchema";

interface IAddColumnModalProps {
  open: boolean;
  onClose: () => any;
}

const AddColumnModal: React.FC<IAddColumnModalProps> = ({ open, onClose }) => {
  const {
    board: { isView, id },
  } = useBoard();

  const { t } = useTranslation();

  const { sendRequest } = useFetch(createColumn);

  const methods = useForm<TTableColumnForm>({
    resolver: zodResolver(columnTableSchema),
  });

  const { setValue, formState } = methods;

  useEffect(() => {
    if (open) {
      setValue("isView", isView);
    }
  }, [isView, setValue, open]);

  const handleSubmit = useCallback(
    (form: TTableColumnForm) => {
      sendRequest({
        enable: true,
        statusId: form.status?.id,
        parentId: form.parent?.id,
        displayOrder: 0,
        name: form.title,
        ticketChannelId: id,
      });
    },
    [sendRequest, id]
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t("components.column.addColumn.addColumn") + ""}
      maxDialogWidth={"xs"}
    >
      <Form {...methods}>
        <Stack gap={2}>
          <FormInput
            name="title"
            size="small"
            label={t("components.column.addColumn.name")}
            fullWidth
          />

          {!isView && <SelectParentColumn />}

          {!isView && <SelectStatus />}

          <Button
            fullWidth
            disabled={!formState.isValid}
            variant="contained"
            onClick={methods.handleSubmit(handleSubmit)}
          >
            {t("components.column.addColumn.addColumn")}
          </Button>
        </Stack>
      </Form>
    </Modal>
  );
};

export default AddColumnModal;
