import { Button } from "@4uhub/lib4uhub";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const translationPath = "components.cardChecklistToggleItems.";

interface ICardChecklistToggleItemsProps {
  toggleHideItemsHandler: () => void;
  hideItems: boolean;
}

const CardChecklistToggleItems = ({
  toggleHideItemsHandler,
  hideItems,
}: ICardChecklistToggleItemsProps) => {
  const { t } = useTranslation();

  return (
    <Button
      variant="contained"
      size="small"
      onClick={toggleHideItemsHandler}
      color="inherit"
      sx={(t) => ({
        color: t.palette.text.primary,
        lineHeight: 1.2,
      })}
    >
      {!hideItems && t(translationPath + "hide")}
      {hideItems && t(translationPath + "show")}
    </Button>
  );
};

export default memo(CardChecklistToggleItems);
