import { useCallback, useEffect, useState } from "react";
import { useFetch } from "@4uhub/lib4uhub";
import { PhotoView } from "react-photo-view";

import { ITicketDialogMediaFile } from "../../../models/ticketDialogs";
import { getFileUrl } from "../../../services/fileManager.service";
import FileMessage from "./FileMessage";

const TicketDialogMediaFile: React.FC<ITicketDialogMediaFile & {me: boolean}> = (props) => {
  const { mimeType, filePath, fileName, id } = props;

  const [src, setSRC] = useState<string>();

  const isImg = mimeType.startsWith("image/");

  const isSticker = mimeType === "image/webp";

  const isFile =
    mimeType.startsWith("application/") || mimeType.startsWith("text");

  const isGif = mimeType === "video/mp4";

  const isAudio = mimeType.startsWith("audio/");

  const { sendRequest } = useFetch(getFileUrl);

  const fetchFile = useCallback(async () => {
    const { data } = await sendRequest(filePath);
    if (data) {
      setSRC(data);
    }
  }, [sendRequest, filePath]);

  useEffect(() => {
    if (isImg || isGif || isAudio) {
      fetchFile();
    }
  }, [isImg, fetchFile, isGif, isAudio]);

  if (isImg && !isSticker && src) {
    return (
      <PhotoView key={id} src={src}>
        <img
          src={src}
          style={{ objectFit: "contain", width: "100%",backgroundColor: 'white',borderRadius: 5 }}
          alt={fileName}
        />
      </PhotoView>
    );
  }

  if (isSticker) {
    return (
      <img
        src={src}
        style={{ objectFit: "contain", width: 200 }}
        alt={fileName}
      />
    );
  }

  if (isGif) {
    return <video src={src} style={{ width: 200 }} autoPlay controls loop />;
  }

  if (isAudio) {
    return (
      <audio
        src={src}
        typeof={mimeType}
        style={{ minWidth: 200, maxWidth: 400, width: "30vw" }}
        controls
      />
    );
  }

  if (isFile) {
    return <FileMessage {...props} me={props.me}/>;
  }

  return <></>;
};

export default TicketDialogMediaFile;
