import {
  forwardRef,
  memo,
  PropsWithChildren,
  ReactElement,
  useImperativeHandle,
  useState,
} from "react";

import { Modal } from "@4uhub/lib4uhub";
import { IMoveModalRef } from "./models";
import { useTranslation } from "react-i18next";

const MoveModal = forwardRef<IMoveModalRef, PropsWithChildren>(
  ({ children }, ref): ReactElement => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const { t } = useTranslation();

    const open = () => {
      setIsOpen(true);
    };

    const close = () => {
      setIsOpen(false);
    };

    useImperativeHandle(ref, () => ({ close, open }), []);

    return (
      <Modal
        title={t("page.card.actions.moveTicket")!}
        open={isOpen}
        onClose={close}
      >
        {children}
      </Modal>
    );
  }
);

export default memo(MoveModal);
