import { Tooltip, Typography } from "@mui/material";

const CellTypografy: React.FC<{ value: string }> = ({ value }) => {
  return (
    <Tooltip title={value}>
      <Typography
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          wordBreak: "keep-all",
        }}
        variant="body2"
      >
        {value}
      </Typography>
    </Tooltip>
  );
};

export default CellTypografy;
