import { Button, Modal } from "@4uhub/lib4uhub";
import { Grid, Typography } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";

const translationPath = "components.internalTemplate.";
export const CardInternalTemplateSelectModal = memo(
  ({
    open,
    onCloseModalHandler,
    saveHandler,
  }: {
    open: boolean;
    onCloseModalHandler: () => void;
    saveHandler: () => void;
  }) => {
    const { t } = useTranslation();
    return (
      <Modal
        onClose={onCloseModalHandler}
        open={open}
        title={t(translationPath + "modal_title") || "Change Template?"}
      >
        <Typography sx={(t) => ({ color: t.palette.grey[600] })}>
          {t(translationPath + "delete_template")}
        </Typography>

        <Grid
          item
          sm={12}
          sx={{ display: "flex", gap: 1, justifyContent: "end" }}
        >
          <Grid item xs={12} sm={2}>
            <Button variant="outlined" onClick={onCloseModalHandler}>
              {t(translationPath + "actions.cancel")}
            </Button>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              onClick={saveHandler}
              variant="contained"
              type="submit"
              color="primary"
              fullWidth
            >
              {t(translationPath + "change")}
            </Button>
          </Grid>
        </Grid>
      </Modal>
    );
  }
);
