import { GlobalAxios } from "../axios/axios-config";
import { IBoardList, ICreateBoard, IGetBoard } from "../models/Board";
import { IListQueryParams, IListReturn } from "../models/ListParams";

const BOARDS_ROUTE = process.env.REACT_APP_TICKETS + "/api/v1/TicketChannels";

export const createBoard = (board: ICreateBoard) => {
  return GlobalAxios.post<IGetBoard>(BOARDS_ROUTE, board);
};

export const editBoard = ({
  board,
  id,
}: {
  board: ICreateBoard;
  id: string;
}) => {
  return GlobalAxios.put<IGetBoard>(BOARDS_ROUTE + `/${id}`, board);
};

export const editBoardParent = ({
  parentId,
  id,
}: {
  parentId: string;
  id: string;
}) => {
  return GlobalAxios.put<{ id: string }>(BOARDS_ROUTE + `/Parent/${id}`, {
    parentId,
    id,
  });
};

export const closeBoardById = (id: string) => {
  return GlobalAxios.put(BOARDS_ROUTE + `/${id}/Archive`);
};

export const getArchivedBoards = (
  params?: IListQueryParams & { TicketWorkspaceId: string }
) => {
  return GlobalAxios.get<IListReturn<IGetBoard>>(BOARDS_ROUTE + "/Archived", {
    params,
  });
};

export const deleteBoardById = (id: string) => {
  return GlobalAxios.put(BOARDS_ROUTE + `/${id}/Disable`);
};

export const boardList = () => {
  return GlobalAxios.get<IBoardList[]>(BOARDS_ROUTE + `/List`);
};

export const unArchiveBoardById = (id: string) => {
  return GlobalAxios.put(BOARDS_ROUTE + `/${id}/Unarchive`);
};

export const getBoardsByWorkspaceId = ({
  isView,
  wkId,
}: {
  wkId: string;
  isView: boolean;
}) => {
  return GlobalAxios.get<IGetBoard[]>(
    BOARDS_ROUTE + `/TicketWorkspaces/${wkId}/${isView}`
  );
};

export const getBoards = () => {
  return GlobalAxios.get<IListReturn<IGetBoard>>(BOARDS_ROUTE);
};

export const getAllBoards = (workspaceId: string) => {
  return GlobalAxios.get<IGetBoard[]>(
    BOARDS_ROUTE + `/TicketWorkspaces/all/${workspaceId}`
  );
};
