import { Box } from "@mui/material";
import NoData from "../../../../../UI/NoData/NoData";
import { INoProtocolDataProps } from "./models";
import { memo } from "react";

const NoProtocolData = ({ message }: INoProtocolDataProps) => {
  return (
    <Box
      width={"100%"}
      height={"100%"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <NoData message={message} />
    </Box>
  );
};

export default memo(NoProtocolData);
