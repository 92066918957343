import { memo, useCallback, useEffect } from "react";

import SendIcon from "@mui/icons-material/Send";
import { Button, Grid } from "@mui/material";

import useMessageInput from "../../hooks/useMessageInput";
import useTicket from "../../hooks/useTask";
import { useChat } from "../../store/context/chat-context";
import EmojiPickerComponent, { Emoji } from "./EmojiPicker";
import FilePicker from "./files/FilePicker";

const MessageActions: React.FC = () => {
  const {
    isUserTicketMember,
    ticket: { status },
  } = useTicket();

  const {
    message,
    cursorPosition,
    inputRef,
    files,
    setCursorPosition,
    setMessage,
    onMessageSend,
  } = useMessageInput();

  const { messages } = useChat();

  const hasRequesterMessages = !!messages.find(
    ({ whoSendType }) => whoSendType.code === "2"
  );

  const statusCode = String(status?.code);

  const isDisabled =
    !isUserTicketMember || !hasRequesterMessages || statusCode === "3";

  const noContent = message.length === 0 && files.length === 0;

  const handleEmojiClick = useCallback(
    (emoji: Emoji) => {
      const ref = inputRef.current;
      if (ref) {
        ref.focus();
        if (ref.selectionStart) {
          const start = message.substring(0, ref.selectionStart);
          const end = message.substring(ref.selectionStart);
          const text = start + emoji.native + end;
          setMessage(text);
          setCursorPosition(start.length + emoji.native.length);
        } else {
          setMessage((o) => o + emoji.native);
        }
      }
    },
    [inputRef, message, setMessage, setCursorPosition]
  );

  const handleEmojiOpen = useCallback(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  useEffect(() => {
    if (inputRef && inputRef.current && cursorPosition) {
      inputRef.current.selectionEnd = cursorPosition;
    }
  }, [cursorPosition, inputRef]);

  const handleSend = useCallback(() => {
    onMessageSend(message);
  }, [onMessageSend, message]);

  return (
    <Grid container direction={"row"} alignItems={"center"} gap={1}>
      <EmojiPickerComponent
        disabled={isDisabled}
        onEmojiClick={handleEmojiClick}
        onEmojiOpen={handleEmojiOpen}
      />

      <FilePicker disabled={isDisabled} />

      <Button
        variant="contained"
        size="small"
        sx={{ height: 40 }}
        onClick={handleSend}
        disabled={noContent || isDisabled}
      >
        <SendIcon fontSize="small" />
      </Button>
    </Grid>
  );
};

export default memo(MessageActions);
