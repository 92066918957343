import { memo } from "react";
import ViewKanbanIcon from "@mui/icons-material/ViewKanban";
import TableRowsIcon from "@mui/icons-material/TableRows";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { useTranslation } from "react-i18next";

import { TMode } from "../../../../models/Board";
import Mode from "./Mode";

interface IBoardModeProps {
  mode: TMode;
  changeMode: (m: TMode) => void;
}

const BoardMode: React.FC<IBoardModeProps> = ({ changeMode, mode }) => {
  const { t } = useTranslation();

  const handleClick = (m: TMode) => {
    changeMode(m);
  };

  return (
    <>
      <Mode
        startIcon={<ViewKanbanIcon />}
        mode="board"
        label="Board"
        onClick={handleClick}
        selected={mode === "board"}
      />
      <Mode
        startIcon={<TableRowsIcon />}
        mode="table"
        label={t("components.boardHeader.boardMode.table")}
        onClick={handleClick}
        selected={mode === "table"}
      />
      <Mode
        startIcon={<AccountTreeIcon />}
        mode="flow"
        label="Flow"
        onClick={handleClick}
        selected={mode === "flow"}
      />
    </>
  );
};

export default memo(BoardMode);
