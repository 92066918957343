import { Loading, getInitials, stringToColor } from "@4uhub/lib4uhub";
import CheckIcon from "@mui/icons-material/Check";
import { Avatar, Badge, Box, Tooltip } from "@mui/material";
import { memo, useCallback, useState } from "react";
import HoverOpacity from "../../UI/HoverOpacity";

interface IAvatarProps {
  height?: number;
  width?: number;
  fontSize?: number;
}

interface ICardMemberProps {
  name: string;
  onClick?: (id: string) => Promise<void>;
  isAdded?: boolean;
  tooltip?: string;
  avatarProps?: IAvatarProps;
  disabled?: boolean;
  id: string;
}

const CardMember = ({
  name,
  isAdded = false,
  tooltip,
  avatarProps,
  onClick,
  disabled,
  id,
}: ICardMemberProps) => {
  const avatarBackgroundColor = stringToColor(name);

  const [loading, setLoading] = useState(false);

  const handleClick = useCallback(async () => {
    setLoading(true);
    if (onClick) {
      await onClick(id);
    }
    setLoading(false);
  }, [onClick, id]);

  if (loading) {
    return (
      <Box>
        <Loading size={20} sx={{ position: "relative" }} />
      </Box>
    );
  }

  return (
    <HoverOpacity disabled={disabled} onClick={handleClick}>
      <Tooltip title={tooltip}>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={
            isAdded && (
              <Avatar
                sx={(t) => ({
                  width: 15,
                  height: 15,
                  backgroundColor: t.palette.success.main,
                })}
              >
                <CheckIcon sx={{ fontSize: 12 }} />
              </Avatar>
            )
          }
        >
          <Avatar
            sx={(t) => ({
              width: avatarProps?.width ? avatarProps.width : 30,
              height: avatarProps?.height ? avatarProps.height : 30,
              fontSize: avatarProps?.fontSize ? avatarProps.fontSize : 14,
              backgroundColor: avatarBackgroundColor,
              color: t.palette.getContrastText(avatarBackgroundColor),
              transition: "all 0.1s ease-in-out",
            })}
          >
            {getInitials(name)}
          </Avatar>
        </Badge>
      </Tooltip>
    </HoverOpacity>
  );
};

export default memo(CardMember);
