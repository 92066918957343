import { memo, useState } from "react";
import {
  IDelivered,
  IMessageStatus,
  IRead,
} from "../../../../models/ticketDialogs";
import { StatusContainer } from "./StatusContainer/StatusContainer";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckIcon from "@mui/icons-material/Check";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ErrorIcon from "@mui/icons-material/Error";
import { SignalRContext } from "../../../SignalR/SignalRProvider";

export const Status = memo(
  ({
    id,
    dateSend,
    deliveryDate,
    isDelivered,
    isRead,
    readDate,
    deliveryReason,
  }: IMessageStatus) => {
    const [delivered, setDelivered] = useState(isDelivered);

    const [deliveredDate, setDeliveredDate] = useState<string | null>(
      deliveryDate
    );

    const [read, setRead] = useState(isRead);

    const [wasReadDate, setWasReadDate] = useState<string | null>(readDate);

    const [deliveryReasonMessage, setDeliveryReasonMessage] = useState<
      string | null
    >(deliveryReason);

    SignalRContext.useSignalREffect(
      "UpdateTicketIsDelivered", // Your Event Key
      (userId: string, data: IDelivered) => {
        console.log("UpdateTicketIsDelivered", data);
        if (data.ticketDialogId === id) {
          setDelivered(data.isDelivered);
          setDeliveredDate(data.deliveryDate);
          setDeliveryReasonMessage(data.deliveredReason);
        }
      },
      []
    );

    SignalRContext.useSignalREffect(
      "UpdateTicketIsRead", // Your Event Key
      (userId: string, data: IRead) => {
        console.log("UpdateTicketIsRead", data);
        const readMessage = data.dialogs.find((d) => d.id === id);
        if (!readMessage) return;
        setRead(readMessage.isRead);
        setWasReadDate(readMessage.readDate);
      },
      []
    );

    return (
      <StatusContainer
        dateSend={dateSend}
        deliveryDate={deliveredDate}
        readDate={wasReadDate}
        deliveryReason={deliveryReasonMessage}
      >
        {deliveryReason ? (
          <ErrorIcon
            sx={(t) => ({
              fontSize: 16,
              color: t.palette.error.main,
            })}
          />
        ) : read ? (
          <DoneAllIcon
            fontSize="small"
            sx={(t) => ({
              fontSize: 16,
              color: t.palette.info.main,
            })}
          />
        ) : delivered ? (
          <DoneAllIcon
            fontSize="small"
            sx={(t) => ({
              alignSelf: "end",
              fontSize: 16,
              color:
                t.palette.mode === "light"
                  ? t.palette.primary.contrastText
                  : t.palette.text.primary,
            })}
          />
        ) : !dateSend && !isDelivered && !isRead ? (
          <AccessTimeIcon
            fontSize="small"
            sx={(t) => ({
              alignSelf: "end",
              fontSize: 16,
              cursor: "pointer",
              color:
                t.palette.mode === "light"
                  ? t.palette.primary.contrastText
                  : t.palette.text.primary,
            })}
          />
        ) : (
          <CheckIcon
            fontSize="small"
            sx={(t) => ({
              alignSelf: "end",
              fontSize: 16,
              color:
                t.palette.mode === "light"
                  ? t.palette.primary.contrastText
                  : t.palette.text.primary,
            })}
          />
        )}
      </StatusContainer>
    );
  }
);
