import { Grid } from "@mui/material";
import { memo, useCallback, useEffect } from "react";
import { useFetch } from "@4uhub/lib4uhub";
import { useParams } from "react-router-dom";

import InviteMember from "./InviteMember/InviteMember";
import BoardMode from "./BoardMode/BoardMode";
import Actions from "./Actions";
import TicketChannelProfilesService from "../../../services/ticketChannelProfiles.service";
import { useMembersContext } from "../../../store/context/members-context";
import useBoard from "../../../hooks/useBoard";
import BoardTitle from "./BoardTitle";
import BoardMembers from "./BoardMembers";
import AdvancedSearch from "./AdvancedSearch/AdvancedSearch";

interface IBoardHeader {
  title: string;
}

const usersService = new TicketChannelProfilesService();

const BoardHeader: React.FC<IBoardHeader> = ({ title }) => {
  const { boardId } = useParams();

  const { loadMembers, members } = useMembersContext();

  const { mode, changeMode, SetSettingsMenu } = useBoard();

  const { sendRequest: requestUsers } = useFetch(usersService.getItemById);

  const loadUsers = useCallback(async () => {
    if (boardId) {
      const { data, success } = await requestUsers(boardId);
      if (data && success) {
        loadMembers(data);
      }
    }
  }, [requestUsers, boardId, loadMembers]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  return (
    <Grid
      container
      direction={"row"}
      sx={{
        borderLeft: 0,
      }}
      justifyContent={"space-between"}
      alignItems={"center"}
      gap={1}
      padding={1}
      paddingX={2}
    >
      <Grid item>
        <Grid container direction={"row"} alignItems={"center"} gap={2}>
          <BoardTitle title={title} />
          <BoardMembers members={members} />
          <InviteMember />
        </Grid>
      </Grid>

      <Grid item>
        <Grid container direction={"row"} gap={1}>
          <AdvancedSearch />
          <BoardMode changeMode={changeMode} mode={mode} />
          <Actions SetSettingsMenu={SetSettingsMenu} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(BoardHeader);
