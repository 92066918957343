import {
  AutoCompleteUncontrolled,
  ISelectType,
  useFetch,
  usePopover,
} from "@4uhub/lib4uhub";
import { Box, Popover, Tooltip, Typography } from "@mui/material";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useTicket from "../../../../hooks/useTask";
import MainApi from "../../../../services/mainApi.service";
import { externalProcessTypeTicket } from "../../../../services/ticket.service";
import { useExternalProcessContext } from "../../../../store/context/external-process-context";
import { SignalRContext } from "../../../SignalR/SignalRProvider";
import HoverOpacity from "../../../UI/HoverOpacity";
import Property from "../../Property";

const TICKET_EXTERNAL_PROCESS_TYPE_ROUTE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketExternalProcessType/";
const ticketExternalProcessTypeService = new MainApi(
  TICKET_EXTERNAL_PROCESS_TYPE_ROUTE
);

const translationPath = "page.card.";

export const TicketExternalProcessType = memo(() => {
  const { t } = useTranslation();

  const {
    externalProcessType,
    externalProcessTypeControl,
    onChangeExternalProcessType,
  } = useExternalProcessContext();

  const [hasEllipsis, setHasEllipsis] = useState(false);

  const textRef = useRef<HTMLDivElement>(null);

  const props = usePopover("ticket-external-process-type-popover");

  const { ticket, isUserTicketMember } = useTicket();

  const { sendRequest } = useFetch(externalProcessTypeTicket);

  SignalRContext.useSignalREffect(
    "TicketUpdated", // Your Event Key
    (userId: string, data) => {
      if (data.id === ticket?.id) {
        if (data.ticketExternalProcessType) {
          onChangeExternalProcessType(data.ticketExternalProcessType);
        }
      }
    },
    [ticket?.id]
  );

  const { handleClose } = props;

  const valueChangeHandler = useCallback(
    async (value: ISelectType | null) => {
      const { data, success } = await sendRequest({
        id: ticket.id,
        externalProcessTypeControl: externalProcessTypeControl ?? undefined,
        ticketExternalProcessTypeId: value ? value.id : undefined,
      });
      if (data && success) {
        onChangeExternalProcessType(value);
        handleClose();
      }
    },
    [
      sendRequest,
      handleClose,
      onChangeExternalProcessType,
      externalProcessTypeControl,
      ticket.id,
    ]
  );

  useEffect(() => {
    if (textRef.current) {
      setHasEllipsis(textRef.current.scrollWidth > textRef.current.clientWidth);
    }
  }, [externalProcessType]);

  return (
    <>
      <HoverOpacity onClick={props.handleClick} disabled={!isUserTicketMember}>
        <Property
          content={
            <Tooltip
              title={externalProcessType?.name}
              disableHoverListener={!hasEllipsis}
              disableFocusListener={!hasEllipsis}
            >
              <Typography
                ref={textRef}
                color={(t) => t.palette.text.primary}
                fontSize={14}
                noWrap
              >
                {externalProcessType ? externalProcessType.name : "-"}
              </Typography>
            </Tooltip>
          }
          label={t(translationPath + "ticket_external_process_type")}
        />
      </HoverOpacity>
      <Popover
        {...props}
        onClose={props.handleClose}
        title={"Tipo de Processo Externo"}
      >
        <Box p={2} minWidth={250}>
          <AutoCompleteUncontrolled
            value={externalProcessType}
            onValueSelected={valueChangeHandler}
            service={ticketExternalProcessTypeService.list}
            clearable={false}
            size="small"
            fullWidth
          />
        </Box>
      </Popover>
    </>
  );
});
