import { SetStateAction } from "react";

import { IGetTableTicket, IGetTicket } from "../../models/ticket";
import { SignalRContext } from "../SignalR/SignalRProvider";
import { IArchivedColumn } from "../../store/context/board-context";
import {
  ICustomerChannel,
  IMemberChannels,
  INewTicketChannelTag,
  ITransferTicketChannel,
} from "../../models/channels";

interface IuseSignalRTableProps {
  setRows: (value: SetStateAction<IGetTableTicket[]>) => void;
}

const useSignalRTable = ({ setRows }: IuseSignalRTableProps) => {
  SignalRContext.useSignalREffect(
    "NewTicketCreated", // Your Event Key
    (userId: string, data: IGetTicket) => {
      console.log("NewTicketCreated", data);
      setRows((tickets) => {
        return [...tickets, data];
      });
    },
    [setRows]
  );

  //Mover card para outra lista
  SignalRContext.useSignalREffect(
    "TransferTicket", // Your Event Key
    (
      userId: string,
      {
        newTicketChannelStep,
        oldTicketChannelStep,
        ticket,
      }: ITransferTicketChannel
    ) => {
      console.log("TransferTicket - table", {
        newTicketChannelStep,
        oldTicketChannelStep,
        ticket,
      });

      setRows((o) => {
        const newRows = o.map((t) => {
          if (t.id === ticket.id) {
            t = { ...t, ...ticket };
          }

          return t;
        });

        return newRows;
      });
    },
    []
  );

  // Novo membro no ticket
  SignalRContext.useSignalREffect(
    "NewTicketMemberCreated", // Your Event Key
    (userId: string, data: IMemberChannels) => {
      console.log("NewTicketMemberCreated - table", data);

      setRows((o) => {
        const newRows = o.map((t) => {
          if (t.id === data.ticketId) {
            t.ticketMembers = [...t.ticketMembers, data.ticketMember];
          }

          return t;
        });

        return newRows;
      });
    },
    []
  );

  // Remover membro do ticket
  SignalRContext.useSignalREffect(
    "RemoveTicketMember", // Your Event Key
    (userId: string, data: IMemberChannels) => {
      console.log("RemoveTicketMember", data);

      setRows((o) => {
        const newRows = o.map((t) => {
          if (t.id === data.ticketId) {
            t.ticketMembers = t.ticketMembers.filter(
              (t: { id: any }) => t.id !== data.ticketMember.id
            );
          }

          return t;
        });

        return newRows;
      });
    },
    []
  );

  // Desarquivar card
  SignalRContext.useSignalREffect(
    "UnarchiveTicket", // Your Event Key
    (userId: string, data: IGetTicket) => {
      console.log("UnarchiveTicket", data);
      setRows((tickets) => {
        return [...tickets, data];
      });
    },
    [setRows]
  );

  // Arquivar Card
  SignalRContext.useSignalREffect(
    "ArchiveTicket", // Your Event Key
    (userId: string, data: any) => {
      console.log("ArchiveTicket", data);
      setRows((tickets) => tickets.filter((t) => t.id !== data.id));
    },
    [setRows]
  );

  SignalRContext.useSignalREffect(
    "ArchiveTicketChannelStep", // Your Event Key
    (userId: string, data: IArchivedColumn) => {
      console.log("ArchiveTicketChannelStep", data);
      setRows((tickets) =>
        tickets.filter((t) => t.ticketChannelStep.id !== data.id)
      );
    },
    [setRows]
  );

  SignalRContext.useSignalREffect(
    "DeleteTicketChannelTag", // Your Event Key
    (userId: string, data: { id: string }) => {
      setRows((o) => {
        const newRows = o.map((t) => {
          if (t.id === data.id) {
            t.ticketChannelTags = t.ticketChannelTags.filter(
              (t: { id: string }) => t.id !== data.id
            );
          }

          return t;
        });

        return newRows;
      });
    },
    []
  );

  SignalRContext.useSignalREffect(
    "TicketCustomerUpdated", // Your Event Key
    (userId: string, data: ICustomerChannel) => {
      console.log("TicketCustomerUpdated", data);

      setRows((o) => {
        const newRows = o.map((t) => {
          if (t.id === data.id) {
            t.ticketRelationships = data.ticketRelationships;
            t.legalEntityCustomer = data.legalEntityCustomer;
            t.individualCustomer = data.individualCustomer;
          }

          return t;
        });

        return newRows;
      });
    },
    [setRows]
  );

  //ticket updated
  SignalRContext.useSignalREffect(
    "TicketUpdated", // Your Event Key
    (userId: string, data: any) => {
      console.log("TicketUpdated", data);
      setRows((o) => {
        const newRows = o.map((t) => {
          if (t.id === data.id) {
            t = { ...t, ...data };
          }

          return t;
        });

        return newRows;
      });
    },
    [setRows]
  );

  //ticket tags add
  SignalRContext.useSignalREffect(
    "NewTicketChannelTagTicketCreated", // Your Event Key
    (userId: string, data: INewTicketChannelTag) => {
      console.log("NewTicketChannelTagTicketCreated - Table", data);

      setRows((o) => {
        const newRows = o.map((t) => {
          if (t.id === data.ticketId) {
            t.ticketChannelTags = [...t.ticketChannelTags, { ...data }];
          }

          return t;
        });

        return newRows;
      });
    },
    [setRows]
  );

  //ticket tags remove
  SignalRContext.useSignalREffect(
    "RemoveTicketChannelTagTicket", // Your Event Key
    (userId: string, data: INewTicketChannelTag) => {
      console.log("RemoveTicketChannelTagTicket - Table", data);

      setRows((o) => {
        const newRows = o.map((t) => {
          if (t.id === data.ticketId) {
            t.ticketChannelTags = t.ticketChannelTags.filter(
              (tag) => tag.id !== data.id
            );
          }

          return t;
        });

        return newRows;
      });
    },
    [setRows]
  );

  //ticket unreadMessages
  SignalRContext.useSignalREffect(
    "UpdateTicketIsRead", // Your Event Key
    (userId: string, data: { id: string; unreadCount: number }) => {
      console.log("UpdateTicketIsRead", data);
      setRows((o) => {
        const newRows = o.map((t) => {
          if (t.id === data.id) {
            t = { ...t, unreadMessages: data.unreadCount };
          }

          return t;
        });

        return newRows;
      });
    },
    [setRows]
  );
};

export default useSignalRTable;
