import { IconButton, Tooltip } from "@mui/material";
import QuickreplyIcon from "@mui/icons-material/Quickreply";
import { useTranslation } from "react-i18next";
import { FC, useState } from "react";

import QuickReplayModal from "./QuickReplayModal/QuickReplayModal";
import QuickAnswerProvider from "../../../store/context/quickAnswers-context";

interface ProtocolDefaultProps {
  isDisabled?: boolean;
}

const QuickReplayButton: FC<ProtocolDefaultProps> = ({ isDisabled }) => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const onClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={t("page.card.chat.quickReplay")}>
        <IconButton disabled={isDisabled} onClick={onClick}>
          <QuickreplyIcon />
        </IconButton>
      </Tooltip>
      <QuickAnswerProvider>
        <QuickReplayModal open={open} onClose={onClose} />
      </QuickAnswerProvider>
    </>
  );
};

export default QuickReplayButton;
