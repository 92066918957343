import { Stack } from "@mui/material";
import CardMember from "../../CardPage/CardMembers/CardMember";
import { ICardMember } from "../../../models/card-members";
import { memo } from "react";

const TaskMembersList = ({
  ticketMembers,
  marginTop,
}: {
  ticketMembers: ICardMember[];
  marginTop?: number;
}) => {
  return (
    <Stack
      mt={marginTop}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"end"}
      flexWrap={"wrap"}
      gap={0.5}
    >
      {ticketMembers.map((member) => (
        <CardMember
          tooltip={member.user.fullName}
          name={member.user.fullName}
          avatarProps={{ height: 22, width: 22, fontSize: 12 }}
          key={member.user.id}
          id={member.user.id}
        />
      ))}
    </Stack>
  );
};

export default memo(TaskMembersList);
