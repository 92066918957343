import { memo } from "react";
import TagsProvider from "../../../store/context/tags-context";
import CardTags from "./CardTags";
import useTicket from "../../../hooks/useTask";

const CardTagContainer = () => {
  const {
    ticket: { id },
  } = useTicket();

  return (
    <TagsProvider cardId={id}>
      <CardTags />
    </TagsProvider>
  );
};

export default memo(CardTagContainer);
