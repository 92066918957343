import AddIcon from "@mui/icons-material/Add";
import { Skeleton, Stack, Typography } from "@mui/material";
import { memo } from "react";
interface IPropertyProps {
  label?: string | null;
  content?: JSX.Element | null;
  loading?: boolean;
  disable?: boolean;
}
const Property: React.FC<IPropertyProps> = ({
  label,
  content,
  loading,
  disable,
}) => {
  if (loading) {
    return (
      <Stack width={60}>
        <Skeleton variant="text" />
        <Skeleton variant="rectangular" />
      </Stack>
    );
  }

  return (
    <Stack>
      <Typography
        variant="caption"
        fontWeight={"bold"}
        color={(t) => t.palette.text.primary}
      >
        {label}
      </Typography>
      {content && content}
      {!content && (
        <AddIcon color="action" sx={{ opacity: disable ? 0.5 : 1 }} />
      )}
    </Stack>
  );
};

export default memo(Property);
