import {
  EstablishmentChange,
  LanguagePicker,
  SideMenu,
  ThemeSwitch,
  useUser,
} from "@4uhub/lib4uhub";

import { memo, useCallback } from "react";
import { tokenByToken } from "../../../services/auth.service";
import { saveTokens } from "../../../services/token.service";
import Workspaces from "../../Workspaces/Workspaces";
import AppsSelector from "./AppsSelector";
import { SettingsButton } from "./SettingsButton/SettingsButton";
import { UserPopover } from "./UserPopover/UserPopover";

const Menu = () => {
  const handleGetEstablishment = useCallback(
    async (establishmentId: string) => {
      return await tokenByToken({ establishmentId });
    },
    []
  );

  const { user } = useUser();

  return (
    <SideMenu
      showMenuIcon={false}
      sx={(theme) => ({
        padding: "5px",
        overflowY: "auto",
        overflowX: "hidden",
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        backgroundColor: theme.palette.mode === "light" ? "#E9E9E9" : "#181818",
        color: theme.palette.text.primary,
      })}
    >
      <AppsSelector />
      <Workspaces />
      <EstablishmentChange
        getEstablishmentToken={handleGetEstablishment}
        onSaveToken={(access_token, refresh_token, index, id) => {
          saveTokens(access_token, refresh_token, index, id);
        }}
        IconButtonProps={{
          color: "inherit",
        }}
      />
      <ThemeSwitch
        iconButtonProps={{
          size: "medium",
          color: "inherit",
        }}
      />
      <LanguagePicker />
      <SettingsButton />
      <UserPopover
        userName={user.fullName}
        userEmail={user.email}
        userPhoto={user.fullName}
      />
    </SideMenu>
  );
};

export default memo(Menu);
