import { useTranslation } from "react-i18next";
import { Form, Modal, useFetch } from "@4uhub/lib4uhub";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { TCardForm, cardSchema } from "./AddCardForm/AddCardSchema";
import AddCardForm from "./AddCardForm/AddCardForm";
import { createTicket } from "../../../../services/ticket.service";
import { IOnAddCardProps } from "../../../../store/context/KanbanContext/useEditColumns/models";
import { IGetTicket } from "../../../../models/ticket";

interface IAddCardModalProps {
  open: boolean;
  isTable?: boolean;
  listId?: string;
  listName?: string;
  onClose: () => void;
  onAdd?: (card: IOnAddCardProps) => void;
  onOpenTicket?: (card: IGetTicket) => void;
}

const AddCardModal: React.FC<IAddCardModalProps> = ({
  onClose,
  open,
  onAdd,
  isTable = false,
  listId,
  listName,
  onOpenTicket,
}) => {
  const { sendRequest, loading } = useFetch(createTicket);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const methods = useForm<TCardForm>({
    resolver: zodResolver(cardSchema),
    defaultValues: {
      clientType: "pf",
      isTable: isTable,
    },
    mode: "onChange",
  });

  const { reset } = methods;

  const handleSubmit = useCallback(
    async (formData: TCardForm) => {
      if (formData.type.code && formData.ticketOrigin.code) {
        const { data } = await sendRequest({
          title: formData.title,
          typeCode: formData.type.code,
          ticketServiceId: formData.ticketService.id,
          content: "",
          individualRequesterId: formData.requester
            ? formData.requester.id
            : undefined,
          individualCustomerId:
            formData.clientType === "pf"
              ? formData.client
                ? formData.client.id
                : undefined
              : undefined,
          legalEntityCustomerId:
            formData.clientType === "pj"
              ? formData.client
                ? formData.client.id
                : undefined
              : undefined,
          ticketOrigin: formData.phone
            ? {
                phoneNumber: formData.phone,
              }
            : undefined,
          ticketChannelStepId: (formData.list?.id || listId)!,
          ticketOriginCode: formData.ticketOrigin.code,
          notIdentifiedName: formData.notIdentifiedName,
          notIdenfiedPhone: formData.notIdenfiedPhone
            ? formData.notIdenfiedPhone.replaceAll("(", "").replaceAll(")", "")
            : undefined,
        });

        if (data) {
          if (onAdd) {
            onAdd({ ticket: data, columnId: (formData.list?.id || listId)! });
          }
          if (onOpenTicket) {
            onClose();
            onOpenTicket(data);
          } else {
            navigate(`card/${data.id}`);
          }
          reset();
        }
      }
    },
    [listId, onAdd, onClose, onOpenTicket, sendRequest, reset, navigate]
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t("page.board.createCard.modalTitle", { name: listName }) + ""}
      maxDialogWidth={"xs"}
    >
      {open && (
        <Form {...methods}>
          <AddCardForm
            onSubmit={handleSubmit}
            isTable={isTable}
            loading={loading}
          />
        </Form>
      )}
    </Modal>
  );
};

export default memo(AddCardModal);
