import { FormTabs } from "@4uhub/lib4uhub";
import { Box } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import {
  sameCustomer,
  sameRequester,
  sameWhatsappNumber,
} from "../../../services/ticket.service";
import { ServicesList } from "./ServicesList/ServicesList";

const translationPath = "components.cardServices.";

export const CardServices = memo(() => {
  const { t } = useTranslation();

  return (
    <Box
      sx={(t) => ({
        m: 1,
        p: 2,
        height: "calc(100% - 15px)",
        backgroundColor: t.palette.background.default,
        borderRadius: 2,
      })}
    >
      <FormTabs
        sx={{ height: "101.5%" }}
        tabs={[
          {
            label: t(`${translationPath}whatsapp_number`),
            component: <ServicesList request={sameWhatsappNumber} />,
          },
          {
            label: t(`${translationPath}requester`),
            component: <ServicesList request={sameRequester} />,
          },
          {
            label: t(`${translationPath}client`),
            component: <ServicesList request={sameCustomer} />,
          },
        ]}
      />
    </Box>
  );
});
