import { useContext } from "react";
import { TicketContext } from "../store/context/task-context";

const useTicket = () => {
  const props = useContext(TicketContext);

  if(!props){
    throw new Error("useTicket must be used with TicketProvider")
  }

  return props;
};

export default useTicket;
