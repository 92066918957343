import { z } from "zod";

const translationPath = "components.events.errors.";

const eventSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().optional().nullable(),
  },
  { invalid_type_error: translationPath + "event" }
);

export const eventMovementSchema = z
  .object({
    id: z.string().optional(),
    event: eventSchema,
    startDate: z.date({
      invalid_type_error: translationPath + "initial_date",
    }),
    endDate: z.date().nullish(),
  })
  .superRefine((val, ctx) => {
    if (val.startDate && val.endDate) {
      if (val.startDate > val.endDate) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_date,
          message: translationPath + "end_date",
          path: ["endDate"],
        });
      }
    }
  });

export type TEventMovementForm = z.infer<typeof eventMovementSchema>;
