import { useTheme } from "@mui/material";
import { Variants, motion } from "framer-motion";

import { memo } from "react";

interface IColumContainerProps {
  children: any;
  open?: boolean;
  refProp?: any;
  backgroundColor?: string | null;
}

const variants: Variants = {
  open: { width: 272 },
  closed: { width: 30 },
};

const ColumnContainer: React.FC<IColumContainerProps> = ({
  children,
  refProp,
  open,
  backgroundColor,
}) => {
  const theme = useTheme();

  return (
    <motion.div
      variants={variants}
      animate={open ? "open" : "closed"}
      initial={{ width: 272 }}
      ref={refProp}
      style={{
        padding: theme.spacing(2),
        backgroundColor: backgroundColor || theme.palette.grey[100],
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[1],
        marginRight: theme.spacing(2),
        maxHeight: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {children}
    </motion.div>
  );
};

export default memo(ColumnContainer);
