import { Button, useFetch, useNotificationContext } from "@4uhub/lib4uhub";
import { memo, useCallback } from "react";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useTranslation } from "react-i18next";
import useTicket from "../../../hooks/useTask";
import { reopenTicket } from "../../../services/ticket.service";

export const CardReopen = memo(() => {
  const { t } = useTranslation();

  const { setMessage } = useNotificationContext();

  const { ticket } = useTicket();

  const { sendRequest, loading } = useFetch(reopenTicket);

  const reopenHandler = useCallback(async () => {
    const { data, success } = await sendRequest(ticket.id);
    if (data && success) {
      setMessage({
        message: t("page.card.actions.reopen.reopen_successfully"),
        type: "success",
      });
    }
  }, [ticket.id, sendRequest, setMessage, t]);

  return (
    <>
      <Button
        variant="contained"
        size="small"
        onClick={reopenHandler}
        color="inherit"
        sx={(t) => ({
          color: t.palette.text.primary,
        })}
        disabled={loading}
        loading={loading}
        endIcon={<LockOpenIcon />}
      >
        {t("page.card.actions.reopen.button")}
      </Button>
    </>
  );
});
