import { memo } from "react";

import useBoardRole from "../../../../../hooks/useBoardRole";
import usePopover from "../../../../../hooks/usePopover";
import useTicket from "../../../../../hooks/useTask";
import { EUserType } from "../../../../Kamban/Task/TaskHeader";
import HoverOpacity from "../../../../UI/HoverOpacity";
import Customer from "./Customer";
import CustomerChangePopover from "./CustomerChangePopover";
import { ITicketService } from "../../../../../models/ticketService";

interface ICustomerChange {
  individual?: string;
  image?: string;
  ticketId: string;
  isNotIdentified: boolean;
  notIdentifiedPhone?: string | null;
  ticketService: ITicketService;
  type: EUserType;
}

const CustomerChange: React.FC<ICustomerChange> = ({
  individual,
  ticketId,
  isNotIdentified,
  notIdentifiedPhone,
  ticketService,
  type,
}) => {
  const permission = useBoardRole("2");

  const {
    isUserTicketMember,
    ticket: { status },
  } = useTicket();

  const props = usePopover("ticket-individual-customer-change-popover");

  const isDisabled = !isUserTicketMember || !permission || status.code === "3";

  return (
    <>
      <HoverOpacity onClick={props.handleClick} disabled={isDisabled}>
        <Customer
          type={type}
          individual={individual}
          isNotIdentified={isNotIdentified}
          notIdentifiedPhone={notIdentifiedPhone}
          ticketService={ticketService}
        />
      </HoverOpacity>

      <CustomerChangePopover
        {...props}
        onClose={props.handleClose}
        ticketId={ticketId}
      />
    </>
  );
};

export default memo(CustomerChange);
