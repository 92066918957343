import {
  Avatar,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { useTranslation } from "react-i18next";
import { useFormContext } from "@4uhub/lib4uhub";
import { ChangeEvent, useMemo } from "react";

import { TFilterSchema } from "../filterSchema";

const NoMembers = () => {
  const { setValue, watch } = useFormContext<TFilterSchema>();

  const membersIds = watch("membersIds");

  const checked = useMemo(() => membersIds.includes("none"), [membersIds]);

  const { t } = useTranslation();

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      setValue("membersIds", [...membersIds, "none"], { shouldDirty: true });
    }
    if (!checked) {
      setValue(
        "membersIds",
        membersIds?.filter((member) => member !== "none"),
        { shouldDirty: true }
      );
    }
  };

  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={handleChange} />}
      label={
        <Stack direction={"row"} gap={1} alignItems={"center"}>
          <Avatar sx={{ width: 23, height: 23 }}>
            <PersonIcon fontSize="small" />
          </Avatar>
          <Typography variant="body2">
            {t("components.boards.filters.noMembers")}
          </Typography>
        </Stack>
      }
    />
  );
};

export default NoMembers;
