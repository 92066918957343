import { Stack, useTheme } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import { motion } from "framer-motion";

import { memo } from "react";

import SideMenu from "../components/Board/SideMenu/SideMenu";

const Boards = () => {
  const { pathname } = useLocation();
  const {
    palette: { background },
  } = useTheme();
  return (
    <Stack direction={"row"} width={"100%"} height={"100%"}>
      <SideMenu />
      <motion.div
        style={{
          height: "100%",
          overflow: "auto",
          width: "100%",
          backgroundColor: background.default,
        }}
        key={pathname}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99] }}
      >
        <Outlet></Outlet>
      </motion.div>
    </Stack>
  );
};

export default memo(Boards);
