import { useFetch, useRole } from "@4uhub/lib4uhub";

import {
  deleteWorkspaceById,
  unArchiveWorkspaceById,
} from "../../../services/workspace.service";
import ArchivedItem from "../../UI/Archiveditem";
import { IGetWorksSpace } from "../../../models/workspaces";
import WorkspaceDelete from "../workspaceDelete";

interface IArchivedWorkspaceProps {
  data: IGetWorksSpace;
}

const ArchivedWorkspace: React.FC<IArchivedWorkspaceProps> = ({ data }) => {
  const { loading, sendRequest } = useFetch(deleteWorkspaceById);

  const updatePermission = useRole(["Update"]);

  const deletePermission = useRole(["Delete"]);

  const { loading: unarchiveLoading, sendRequest: unarchiveRequest } = useFetch(
    unArchiveWorkspaceById
  );

  const { id } = data;

  const handleDelete = () => {
    sendRequest(id);
  };

  const handleUnArchive = () => {
    unarchiveRequest(id);
  };

  return (
    <ArchivedItem
      deleteLoading={loading}
      deletePermission={deletePermission}
      unarchivePermission={updatePermission}
      unarchiveLoading={unarchiveLoading}
      deleteComponent={
        <WorkspaceDelete
          onDelete={handleDelete}
          secureReferenceText={data.name}
          loading={loading}
        />
      }
      onUnArchived={handleUnArchive}
      data={data}
    />
  );
};

export default ArchivedWorkspace;
