import { Box, Typography } from "@mui/material";
import { IDetailBoxContainerProps } from "./models";
import { memo } from "react";

const DetailBoxContainer = ({
  title,
  icon,
  children,
}: IDetailBoxContainerProps) => {
  return (
    <Box
      sx={(t) => ({
        p: 1,
        width: "100%",
        height: "32vh",
        border: `1px solid ${t.palette.divider}`,
        borderRadius: 1,
        backgroundColor: t.palette.background.default,
      })}
    >
      <Box
        sx={(t) => ({
          pb: 1,
          display: "flex",
          alignItems: "center",
          gap: 1,
          color:
            t.palette.mode === "light"
              ? t.palette.grey[600]
              : t.palette.grey[400],
        })}
      >
        {icon}
        <Typography variant="subtitle2">{title}</Typography>
      </Box>
      <Box
        sx={(t) => ({
          pr: 0.5,
          width: "100%",
          height: "90%",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "0.4em",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            backgroundColor:
              t.palette.mode === "light"
                ? t.palette.grey[400]
                : t.palette.grey[900],
            borderRadius: 5,
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor:
              t.palette.mode === "light"
                ? t.palette.grey[200]
                : t.palette.grey[700],
          },
        })}
      >
        {children}
      </Box>
    </Box>
  );
};

export default memo(DetailBoxContainer);
