import { useCallback, useEffect, useState } from "react";
import { Loading, Modal, useFetch } from "@4uhub/lib4uhub";
import { Box, Stack } from "@mui/material";

import ListEmpty from "../../UI/ListEmpty";
import { IGetBoard } from "../../../models/Board";
import { getArchivedBoards } from "../../../services/board.service";
import ArchivedBoard from "./ArchivedBoard";
import { useTranslation } from "react-i18next";
import { SignalRContext } from "../../SignalR/SignalRProvider";
import { useWorkspaceContext } from "../../../store/context/workspace-context";

interface IArchivedBoardsProps {
  open: boolean;
  onClose: () => void;
}

const ArchivedBoards: React.FC<IArchivedBoardsProps> = ({ onClose, open }) => {
  const [boards, setboards] = useState<IGetBoard[]>([]);

  const { actualWorkspace } = useWorkspaceContext();
  const { t } = useTranslation();

  const { loading, sendRequest } = useFetch(getArchivedBoards);

  const fetch = useCallback(async () => {
    if (!actualWorkspace) return;

    const { data } = await sendRequest({
      TicketWorkspaceId: actualWorkspace?.id,
    });
    if (data) {
      setboards(data.items);
    }
  }, [sendRequest, actualWorkspace]);

  useEffect(() => {
    if (open) {
      fetch();
    }
  }, [fetch, open]);

  const deleteFromList = useCallback((id: string) => {
    setboards((w) => w.filter((w) => w.id !== id));
  }, []);

  SignalRContext.useSignalREffect(
    "UnArchiveTicketChannel", // Your Event Key
    (userId: string, data: IGetBoard) => {
      console.log("UnArchiveTicketChannel", data);
      deleteFromList(data.id);
    },
    [deleteFromList]
  );

  SignalRContext.useSignalREffect(
    "DisableTicketChannel", // Your Event Key
    (userId: string, data: { id: string }) => {
      console.log("DisableTicketChannel", data);

      deleteFromList(data.id);
    },
    []
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t("page.workspace.archived.title") + ""}
      maxDialogWidth="sm"
    >
      <Stack>
        {loading && (
          <Box>
            <Loading size={30} sx={{ position: "relative" }} />
          </Box>
        )}
        {!loading && (
          <Stack gap={1}>
            {boards.map((b) => (
              <ArchivedBoard key={b.id} data={b} onDelete={deleteFromList} />
            ))}
            {boards.length === 0 && !loading && (
              <ListEmpty message={t("page.workspace.archived.empty")} />
            )}
          </Stack>
        )}
      </Stack>
    </Modal>
  );
};

export default ArchivedBoards;
