import { IconButton, Tooltip } from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useTranslation } from "react-i18next";
import { memo, useCallback } from "react";
import { useFetch } from "@4uhub/lib4uhub";

import CardMembersService from "../../../services/cardMembers.service";
import useTicket from "../../../hooks/useTask";

const translationPath = "components.cardMembers.";

const cardMembersService = new CardMembersService<{ ticketId: string }>();

interface IJoinCardButtonProps {
  isLoadingHandler: (value: boolean) => void;
}

const JoinCardButton: React.FC<IJoinCardButtonProps> = ({
  isLoadingHandler,
}) => {
  const { t } = useTranslation();

  const {
    ticket: { id },
  } = useTicket();

  const { sendRequest } = useFetch(cardMembersService.joinCard);

  const joinCardHandler = useCallback(async () => {
    isLoadingHandler(true);
    await sendRequest({ ticketId: id });
    isLoadingHandler(false);
  }, [sendRequest, isLoadingHandler, id]);

  return (
    <Tooltip title={t(translationPath + "join")}>
      <IconButton
        size="small"
        onClick={joinCardHandler}
        sx={{ width: 30, height: 30 }}
      >
        <PersonAddIcon />
      </IconButton>
    </Tooltip>
  );
};

export default memo(JoinCardButton);
