import { useFetch } from "@4uhub/lib4uhub";
import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useTicket from "../../../../hooks/useTask";
import { IGetTicket } from "../../../../models/ticket";
import NoData from "../../../UI/NoData/NoData";
import ChatProvider from "../../../../store/context/chat-context";
import { IServicesListProps } from "./models";
import { Stack } from "@mui/material";
import ServiceListMessageBox from "./ServiceListMessage/ServiceListMessageBox";

const translationPath = "components.cardServices.";

export const ServicesList = memo(({ request }: IServicesListProps) => {
  const { t } = useTranslation();

  const { ticket } = useTicket();

  const { id } = ticket;

  const [tickets, setTickets] = useState<IGetTicket[]>([]);

  const { sendRequest, loading } = useFetch(request);

  const fetchCards = useCallback(async () => {
    const { data, success } = await sendRequest({
      TicketId: id,
    });
    if (data && success) {
      setTickets((prev) => [...prev, ...data.items]);
    }
  }, [id, sendRequest]);

  useEffect(() => {
    fetchCards();
  }, [fetchCards]);

  if (!loading && tickets.length === 0) {
    return <NoData message={t(`${translationPath}no_data`)} />;
  }

  return (
    <>
      <ChatProvider ticketId={ticket.id ?? undefined}>
        <Stack sx={{ maxHeight: "100%", overflowY: "auto" }}>
          <ServiceListMessageBox
            modalMode
            tickets={tickets}
            loading={loading}
          />
        </Stack>
      </ChatProvider>
    </>
  );
});
