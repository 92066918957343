import { useCallback, useEffect } from "react";
import { SignalRContext } from "../components/SignalR/SignalRProvider";

const useSignalGroup = (groupId?: string) => {
  const enterGroup = useCallback(async () => {
    if (!groupId) return;
    if (SignalRContext.connection?.state === "Connected") {
      SignalRContext.invoke("AddToGroup", groupId);
      console.log("entrou no grupo ", groupId);
    }
  }, [groupId]);

  const leaveGroup = useCallback(() => {
    if (!groupId) return;
    if (SignalRContext.connection?.state === "Connected") {
      SignalRContext.invoke("RemoveFromGroup", groupId);
      console.log("saiu do grupo ", groupId);
    }
  }, [groupId]);

  useEffect(() => {
    enterGroup();
    return leaveGroup;
  }, [enterGroup, leaveGroup]);
};

export default useSignalGroup;
