import { useContext } from "react";
import { KanbanContext } from "../store/context/KanbanContext/kanban-context";

const useKanban = () => {
  const value = useContext(KanbanContext);

  if (!value) {
    throw new Error("'useKanban must be used within a KanbanProvider'");
  }

  return value;
};

export default useKanban;
