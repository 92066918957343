import { useFetch, useRole } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";

import usePopover from "../../../../../hooks/usePopover";
import { useWorkspaceContext } from "../../../../../store/context/workspace-context";
import MenuItem from "../../MenuItem";
import useBoardRole from "../../../../../hooks/useBoardRole";
import { editBoardParent } from "../../../../../services/board.service";
import EditParentPopover from "./EditParentPopover";
import { TEditBoardParent } from "./EditParentSchema";

interface IEditParentProps {
  isView?: boolean;
}

const EditParent: React.FC<IEditParentProps> = ({isView}) => {
  const { anchorEl, id, handleClick, handleClose, open } = usePopover(
    "edit-board-parent-popover"
  );

  const {
    actualWorkspace,
    editBoardParent: eBP,
    actualBoard,
  } = useWorkspaceContext();

  const { sendRequest, loading } = useFetch(editBoardParent);

  const updatePermission = useRole(["Update"]);

  const permission = useBoardRole("2");

  const { t } = useTranslation();

  const onSubmit = async (data: TEditBoardParent) => {
    if (!actualBoard || !actualWorkspace) return;

    const value = {
      id: actualBoard.id,
      parentId: data.parent.id,
    };

    const { success } = await sendRequest(value);

    const { parent } = data;

    if (success) {
      handleClose();
      eBP({
        parent: parent,
        id: actualBoard.id,
      });
    }
  };

  if (actualBoard?.parent || isView) return <></>;

  return (
    <>
      {(updatePermission || permission) && (
        <MenuItem
          onClick={handleClick}
          title={t("components.boards.settingsMenu.selectParent")}
          icon={<EditIcon fontSize="small" color="primary" />}
        />
      )}

      {open && actualBoard && (
        <EditParentPopover
          isView={actualBoard.isView}
          popoverProps={{
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          }}
          anchorEl={anchorEl}
          onClose={handleClose}
          onSubmit={onSubmit}
          open={open}
          id={id}
          loading={loading}
        />
      )}
    </>
  );
};

export default EditParent;
