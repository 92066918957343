import { useParams } from "react-router-dom";
import { useMemo } from "react";

import { useAppSelector } from "../store/store";

const useWorkspace = () => {
  const { ticketWorkspaceSequence } = useParams();

  const { loading, workspaces } = useAppSelector((state) => state.workSpaces);

  const actualWorkspace = useMemo(
    () =>
      workspaces.find(
        (w) => w.ticketWorkspaceSequence === Number(ticketWorkspaceSequence)
      ),
    [ticketWorkspaceSequence, workspaces]
  );

  return { workspaces, actualWorkspace, loading };
};

export default useWorkspace;
