import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import useTicket from "../../../hooks/useTask";

const translationPath = "components.historyComments.";

const OpenCommentInput = ({
  openCommentInput,
}: {
  openCommentInput: () => void;
}) => {
  const { t } = useTranslation();

  const {
    isUserTicketMember,
    ticket: { status },
  } = useTicket();

  const isDisabled = !isUserTicketMember || status?.code === "3";

  return (
    <Stack
      width={"100%"}
      height={30}
      sx={(t) => ({
        p: 1,
        justifyContent: "center",
        backgroundColor:
          t.palette.mode === "light"
            ? t.palette.grey[200]
            : t.palette.grey[800],
        color: t.palette.mode === "dark" ? "white" : undefined,
        cursor: isDisabled ? undefined : "pointer",
        borderRadius: 1,
        transition: "all .2s ease-in-out",
        "&:hover": isDisabled
          ? undefined
          : {
              backgroundColor:
                t.palette.mode === "light"
                  ? t.palette.grey[100]
                  : t.palette.grey[700],
            },
      })}
      onClick={isDisabled ? undefined : openCommentInput}
    >
      <Typography variant="body2" sx={{ opacity: isDisabled ? 0.4 : 1 }}>
        {t(translationPath + "placeholder")}
      </Typography>
    </Stack>
  );
};

export default OpenCommentInput;
