import { createContext, memo, useCallback, useMemo, useState } from "react";
import { ITag } from "../../models/tags";
import { SignalRContext } from "../../components/SignalR/SignalRProvider";

type ITagContext = {
  tags: ITag[];
  ticketTags: ITag[];
  showTags: boolean;
  toggleShowTags: () => void;
  loadTicketTags: (ticketTags: ITag[]) => void;
  addTicketTags: (tag: ITag) => void;
  updateTicketTags: (tag: ITag) => void;
  removeTicketTags: (id: string) => void;
  loadTagsList: (tags: ITag[]) => void;
  addTag: (tag: ITag) => void;
  updateTag: (tag: ITag) => void;
  removeTag: (id: string) => void;
  isChecked: (tagId: string) => boolean;
};

export const TagsContext = createContext<ITagContext>({
  tags: [],
  ticketTags: [],
  showTags: true,
  toggleShowTags: () => {},
  loadTicketTags: () => {},
  addTicketTags: () => {},
  updateTicketTags: () => {},
  removeTicketTags: () => {},
  loadTagsList: () => {},
  addTag: () => {},
  updateTag: () => {},
  removeTag: () => {},
  isChecked: () => true,
});

interface ITagsProvider {
  children: any;
  cardId?: string;
}

const TagsProvider: React.FC<ITagsProvider> = ({ children, cardId }) => {
  const [tags, setTags] = useState<ITag[]>([]);

  const [ticketTags, setTicketTags] = useState<ITag[]>([]);

  const [showTags, setShowTags] = useState(true);

  const toggleShowTags = useCallback(() => {
    setShowTags((state) => (state = !state));
  }, []);

  const loadTicketTags = useCallback((ticketTags: ITag[]) => {
    setTicketTags(ticketTags);
  }, []);

  const addTicketTags = useCallback((tag: ITag) => {
    setTicketTags((oldTags) => {
      const i = oldTags.findIndex((t) => t.id === tag.id);
      if (i === -1) {
        return [...oldTags, tag];
      }
      return oldTags;
    });
  }, []);

  const updateTicketTags = useCallback((tag: ITag) => {
    setTicketTags((oldTags) =>
      oldTags.map((old) => {
        if (old.id === tag.id) {
          return { ...old, ...tag };
        }
        return old;
      })
    );
  }, []);

  const removeTicketTags = useCallback((id: string) => {
    setTicketTags((oldTags) => oldTags.filter((t) => t.id !== id));
  }, []);

  const loadTagsList = useCallback((tags: ITag[]) => {
    setTags(tags);
  }, []);

  const addTag = useCallback((tag: ITag) => {
    setTags((oldTags) => [...oldTags, tag]);
  }, []);

  const updateTag = useCallback((tag: ITag) => {
    setTags((oldTags) =>
      oldTags.map((old) => {
        if (old.id === tag.id) {
          return { ...old, ...tag };
        }
        return old;
      })
    );
  }, []);

  const isChecked = useCallback(
    (tagId: string) => !!ticketTags.find((t) => t.id === tagId),
    [ticketTags]
  );

  const removeTag = useCallback((id: string) => {
    setTags((oldTags) => oldTags.filter((old) => old.id !== id));
  }, []);

  SignalRContext.useSignalREffect(
    "NewTicketChannelTagTicketCreated", // Your Event Key
    (userId: string, tag: ITag) => {
      console.log("NewTicketChannelTagTicketCreated", tag);

      if (tag.ticketId === cardId) {
        addTicketTags(tag);
      }
    },
    [addTicketTags, cardId]
  );

  SignalRContext.useSignalREffect(
    "RemoveTicketChannelTagTicket", // Your Event Key
    (userId: string, tag: ITag) => {
      console.log("RemoveTicketChannelTagTicket", tag);
      if (tag.ticketId === cardId) {
        removeTicketTags(tag.id);
      }
    },
    [removeTicketTags, cardId]
  );

  SignalRContext.useSignalREffect(
    "DeleteTicketChannelTag", // Your Event Key
    (userId: string, data: { id: string }) => {
      removeTag(data.id);
      removeTicketTags(data.id);
    },
    [removeTag, removeTicketTags]
  );

  SignalRContext.useSignalREffect(
    "NewTicketChannelTagCreated", // Your Event Key
    (userId: string, data: ITag) => {
      console.log("NewTicketChannelTagCreated", data);
      addTag(data);
    },
    []
  );

  SignalRContext.useSignalREffect(
    "TicketChannelTagUpdated", // Your Event Key
    (userId: string, tag: ITag) => {
      updateTag(tag);
      updateTicketTags(tag);
    },
    [updateTicketTags, updateTag]
  );

  const value = useMemo(
    () => ({
      tags,
      ticketTags,
      showTags,
      toggleShowTags,
      loadTicketTags,
      addTicketTags,
      updateTicketTags,
      removeTicketTags,
      loadTagsList,
      addTag,
      updateTag,
      removeTag,
      isChecked,
    }),
    [
      tags,
      ticketTags,
      showTags,
      toggleShowTags,
      loadTicketTags,
      addTicketTags,
      updateTicketTags,
      removeTicketTags,
      loadTagsList,
      addTag,
      updateTag,
      removeTag,
      isChecked,
    ]
  );

  return <TagsContext.Provider value={value}>{children}</TagsContext.Provider>;
};

export default memo(TagsProvider);
