import { useContext } from "react";
import { TagsContext } from "../store/context/tags-context";

const useTags = () => {
  const tags = useContext(TagsContext);

  return tags;
};

export default useTags;
