import { FormInput, SwitchInput, FormColorPicker } from "@4uhub/lib4uhub";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

const translationPath = "components.tagForm.";

const TagFormFields = () => {
  const { t } = useTranslation();

  return (
    <>
      <Stack alignItems={"end"}>
        <SwitchInput label={t(translationPath + "enable")} name="enable" />
      </Stack>

      <FormInput label="Nome" name="name" fullWidth size="small" />

      <FormInput
        label={t(translationPath + "display_order")}
        name="displayOrder"
        fullWidth
        size="small"
      />

      <FormColorPicker name="color" label={t(translationPath + "color")} />
    </>
  );
};

export default TagFormFields;
