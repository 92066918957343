import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { useFetch } from "@4uhub/lib4uhub";

import { login } from "../../services/auth.service";
import { saveTokens } from "../../services/token.service";
import { saveUserName } from "../../services/user.service";

import LoginForm, { TLoginFormData } from "./LoginForm";
import AuthContainer from "./AuthContainer";

const LoginPage = () => {
  const navigate = useNavigate();

  const { sendRequest, loading } = useFetch(login);

  const onSubmitHandler = useCallback(
    async (values: TLoginFormData) => {
      const { data, success } = await sendRequest(values);
      if (success && data) {
        saveUserName(values.username);
        saveTokens(
          data.access_token,
          data.refresh_token,
          data.establishmentTenantSequential
        );
        navigate(`/e/${data.establishmentTenantSequential}`, {
          replace: true,
        });
      }
    },
    [sendRequest, navigate]
  );

  return (
    <AuthContainer>
      <LoginForm loading={loading} onSubmit={onSubmitHandler} />
    </AuthContainer>
  );
};

export default LoginPage;
