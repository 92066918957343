import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFlow from "../../hooks/useFlow";

import {
  AutoCompleteMultiUncontrolled,
  ISelectType,
  useFetch,
} from "@4uhub/lib4uhub";
import { Stack } from "@mui/material";

import FilterAltIcon from "@mui/icons-material/FilterAlt";

import { getColumnsByBoardId } from "../../services/column.serivice";

import { hexToRgbA } from "../../utils/ConvertRgbToHex";

const TicketsFilterSelect = () => {
  const [options, setOptions] = useState<ISelectType[]>([]);

  const { t } = useTranslation();
  const { sendRequest } = useFetch(getColumnsByBoardId);
  const { boardId, filteredLists, setFilteredLists } = useFlow();

  const getLists = useCallback(async () => {
    const { data, success } = await sendRequest({
      all: true,
      bId: boardId,
      statusCode: "1",
    });

    if (data && success) setOptions(data);
  }, [boardId, setOptions, sendRequest]);

  const hasValue = filteredLists?.length > 0;

  useEffect(() => {
    getLists();
  }, [getLists]);

  return (
    <Stack
      width="100%"
      direction="row"
      alignItems="center"
      mb={1}
      mt={1}
      spacing={0.5}
      borderRadius={1}
      sx={(t) => ({
        p: 1,
        width: "98.5%",
        transition: "all 200ms ease-in-out",
        color: t.palette.text.primary,
        background:
          t.palette.mode === "light"
            ? hasValue
              ? hexToRgbA("#ffd900", 0.2)
              : "transparent"
            : hasValue
            ? hexToRgbA("#ffd900", 0.3)
            : "transparent",
      })}
    >
      <FilterAltIcon />

      <Stack
        width="100%"
        sx={{
          borderRadius: 1,
          transition: "all 200ms ease-in-out",
        }}
      >
        <AutoCompleteMultiUncontrolled
          options={options}
          size="small"
          label={t("page.card.flow.filter")}
          limitTags={1}
          value={filteredLists}
          onValueSelected={(values) => setFilteredLists(values || [])}
        />
      </Stack>
    </Stack>
  );
};

export default TicketsFilterSelect;
