import { Stack, Typography } from "@mui/material";
import { MouseEventHandler } from "react";

interface IMenuItem {
  title: string;
  icon: JSX.Element;
  onClick: MouseEventHandler<HTMLDivElement> | undefined;
}

const MenuItem: React.FC<IMenuItem> = ({ title, icon, onClick }) => {
  return (
    <Stack
      direction={"row"}
      justifyContent={"flex-start"}
      gap={1}
      sx={(t) => ({
        "&:hover": {
          backgroundColor:
            t.palette.mode === "light"
              ? t.palette.grey[200]
              : t.palette.grey[800],
          cursor: "pointer",
        },
        borderRadius: t.shape.borderRadius / 10,
      })}
      padding={0.5}
      px={1}
      onClick={onClick}
      alignItems={"center"}
    >
      {icon}
      <Typography variant="button" color={(t) => t.palette.text.primary}>
        {title}
      </Typography>
    </Stack>
  );
};

export default MenuItem;
