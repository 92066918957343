import { memo, useRef, useState } from "react";
import { Stack } from "@mui/material";
import { Variants, motion } from "framer-motion";
import { Button } from "@4uhub/lib4uhub";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";

import ColumnContainer from "../ColumnContainer";
import AddColumnModal from "./AddColumnModal";

const variants: Variants = {
  open: { height: 120 },
  closed: { height: 70 },
};

const AddColumn: React.FC = () => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const openModal = () => {
    setOpen(true);
  };

  const closeHandler = () => {
    setOpen(false);
  };

  return (
    <motion.div
      animate={open ? "open" : "closed"}
      variants={variants}
      transition={{ type: "tween" }}
      style={{ paddingRight: 15 }}
    >
      <Stack style={{ width: 272 }}>
        <ColumnContainer refProp={ref} open={true}>
          <Button
            variant="text"
            startIcon={<AddIcon />}
            sx={{ justifyContent: "flex-start" }}
            onClick={openModal}
            fullWidth
          >
            {t("components.column.addColumn.addColumn")}
          </Button>
          <AddColumnModal onClose={closeHandler} open={open} />
        </ColumnContainer>
      </Stack>
    </motion.div>
  );
};

export default memo(AddColumn);
