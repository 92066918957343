import { Divider, Stack } from "@mui/material";
import { Draggable } from "react-beautiful-dnd";
import { memo, useMemo, useState } from "react";

import { IChecklist } from "../../../../models/ticketChecklist";
import ChecklistItemsContainer from "../CardChecklistItems/ChecklistItemsContainer";
import CardChecklistHeader from "./CardChecklistHeader";
import ChecklistProgress from "./ChecklistProgress";
import useTicket from "../../../../hooks/useTask";

interface ICardChecklistContentProps {
  checklist: IChecklist;
  index: number;
}

const CardChecklistContent: React.FC<ICardChecklistContentProps> = ({
  checklist: { id, ticketChecklistItems, name },
  index,
}) => {
  const [hideItems, setHideItems] = useState(false);

  const {
    ticket: { status },
  } = useTicket();

  const toggleHideItemsHandler = () =>
    setHideItems((prevState) => (prevState = !prevState));

  const showButton = useMemo(
    () => !!ticketChecklistItems.find((item) => item.isChecked === true),
    [ticketChecklistItems]
  );

  return (
    <div>
      <Draggable
        draggableId={id}
        index={index}
        isDragDisabled={status.code === "3"}
      >
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.draggableProps}>
            <Stack
              mb={2}
              key={id}
              sx={(t) => ({
                p: 2,
                backgroundColor: t.palette.background.default,
                boxShadow: snapshot.isDragging
                  ? "0px 0px 11px -1px rgba(0,0,0,0.35)"
                  : undefined,
                borderRadius: 1,
              })}
            >
              <CardChecklistHeader
                dragHandleProps={provided.dragHandleProps}
                checklistName={name}
                hideItems={hideItems}
                id={id}
                toggleHideItemsHandler={toggleHideItemsHandler}
                showButton={showButton}
              />
              <ChecklistProgress ticketChecklistItems={ticketChecklistItems} />
              <Divider />
              <ChecklistItemsContainer
                checklistItems={ticketChecklistItems}
                checklistId={id}
                hideCheckedItems={hideItems}
              />
            </Stack>
          </div>
        )}
      </Draggable>
    </div>
  );
};

export default memo(CardChecklistContent);
