import {
  Autocomplete,
  Popover,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { IGetTicketChannelStep } from "../../../../models/ticket";
import { useEffect, useState } from "react";
import { Button } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";

const translationPath = "components.joinAction.";

interface IJoinActionPopoverProps {
  open: boolean;
  anchorEl: Element | null;
  ticketChannelSteps: IGetTicketChannelStep[];
  onClose: () => void;
  joinTicketHandler: (ticketChannelStepId: string) => Promise<void>;
}

const JoinActionPopover = ({
  open,
  anchorEl,
  ticketChannelSteps,
  onClose,
  joinTicketHandler,
}: IJoinActionPopoverProps) => {
  const { t } = useTranslation();
  const [ticketChannelStepId, setTicketChannelStepId] = useState("");

  useEffect(() => {
    if (ticketChannelSteps.length !== 0) {
      setTicketChannelStepId(ticketChannelSteps[0].id);
    }
  }, [ticketChannelSteps]);

  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={(t) => ({ marginTop: t.spacing(1) })}
    >
      <Stack p={1} width={250}>
        {ticketChannelSteps.length === 0 && (
          <Typography variant="body2">
            {t(translationPath + "no_lists")}
          </Typography>
        )}
        {ticketChannelSteps.length !== 0 && (
          <>
            <Typography mb={1}>Selecione uma lista</Typography>
            <Autocomplete
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              size="small"
              options={ticketChannelSteps}
              fullWidth
              defaultValue={ticketChannelSteps[0]}
              onChange={(event, value) => {
                if (value) {
                  setTicketChannelStepId(value.id);
                }
              }}
              clearIcon={false}
              renderInput={(params) => <TextField {...params} fullWidth />}
              sx={{ mb: 1 }}
            />
            <Button
              variant="contained"
              onClick={() => joinTicketHandler(ticketChannelStepId)}
            >
              {t(translationPath + "select")}
            </Button>
          </>
        )}
      </Stack>
    </Popover>
  );
};

export default JoinActionPopover;
