import { z } from "zod";

const translationPath =
  "page.card.chat.noMessages.selectAutomationRule.errors.";

const ticketAutomationRuleSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    code: z.string().nullish(),
  },
  { invalid_type_error: translationPath + "automation_rule" }
);

export const selectAutomationRuleSchema = z.object({
  ticketAutomationRule: ticketAutomationRuleSchema,
});

export type TSelectAutomationRuleForm = z.infer<
  typeof selectAutomationRuleSchema
>;
