import { memo, useCallback } from "react";
import { useFetch } from "@4uhub/lib4uhub";
import { IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";

import usePopover from "../../../../hooks/usePopover";
import PopoverDelete from "../../../UI/PopoverDelete";
import TicketChecklistItemService from "../../../../services/ticketChecklistItem.service";
import useTicket from "../../../../hooks/useTask";

const checklistItemService = new TicketChecklistItemService();

const translationPath = "components.checklistItemDelete.";

interface IChecklistItemDeleteProps {
  checklistId: string;
  checklistItemId: string;
}

const ChecklistItemDelete: React.FC<IChecklistItemDeleteProps> = ({
  checklistId,
  checklistItemId,
}) => {
  const { t } = useTranslation();

  const {
    ticket: { status },
  } = useTicket();

  const { sendRequest: deleteItem } = useFetch(
    checklistItemService.deleteChecklistItem
  );

  const handleDeleteItem = useCallback(async () => {
    deleteItem(checklistItemId);
  }, [deleteItem, checklistItemId]);

  const { anchorEl, handleClick, handleClose, open } = usePopover(
    "remove-item-popover"
  );

  return (
    <>
      <PopoverDelete
        title={t(translationPath + "title")}
        description={t(translationPath + "description")}
        buttonDescription={t(translationPath + "button")}
        onDelete={handleDeleteItem}
        onClose={handleClose}
        open={open}
        anchorEl={anchorEl}
      />
      <Tooltip
        title={t(translationPath + "tooltip")}
        disableHoverListener={status.code === "3"}
      >
        <IconButton
          size="small"
          disabled={status.code === "3"}
          onClick={handleClick}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default memo(ChecklistItemDelete);
