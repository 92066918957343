import { AvatarComponent } from "@4uhub/lib4uhub";
import {
  Box,
  Popover,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { memo, useState } from "react";
import LogoutButton from "../LogoutButton";

interface IUserPopoverProps {
  userName?: string;
  userEmail?: string;
  userPhoto?: string;
}

export const UserPopover = memo(
  ({ userName, userEmail, userPhoto }: IUserPopoverProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const theme = useTheme();

    const matches = useMediaQuery(theme.breakpoints.down("lg"));

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
      <>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={
            matches
              ? { vertical: "bottom", horizontal: "right" }
              : {
                  vertical: "center",
                  horizontal: "right",
                }
          }
          transformOrigin={
            matches
              ? { vertical: "top", horizontal: "right" }
              : {
                  vertical: "center",
                  horizontal: "left",
                }
          }
        >
          <Box
            sx={{
              p: 1,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Box sx={{ pl: 1, display: "flex", flexDirection: "column" }}>
              <Typography variant="subtitle2" fontWeight="bold">
                {userName}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={(t) => ({
                  color: t.palette.grey[t.palette.mode === "light" ? 700 : 300],
                })}
              >
                {userEmail}
              </Typography>
            </Box>
            <LogoutButton />
          </Box>
        </Popover>
        <AvatarComponent
          userName={userName}
          userPhoto={userPhoto}
          iconButtonProps={{ onClick: handleClick, "aria-describedby": id }}
        />
      </>
    );
  }
);
