import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useFormContext } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

import useGeneric from "../../../../../hooks/useGeneric";
import { TFilterSchema } from "../filterSchema";

interface IDateTypeSelectorProps {}

const DateTypeSelector: React.FC<IDateTypeSelectorProps> = () => {
  const { data } = useGeneric("TicketDateFilter");

  const { setValue, watch } = useFormContext<TFilterSchema>();

  const status = watch("status");

  const { t } = useTranslation();

  const findedValue = useMemo(
    () => data.find((d) => d.code === status),
    [data, status]
  );

  const showHelper = useMemo(() => status === "3" || status === "4", [status]);

  return (
    <FormControl>
      <Typography variant="subtitle2">
        {t("components.boards.filters.status")}
      </Typography>
      <RadioGroup
        aria-labelledby="form-type-radio-group"
        name="radio-buttons-group"
        value={findedValue?.code || null}
        onChange={(event: React.ChangeEvent<HTMLInputElement>, value) =>
          setValue("status", value || null, {
            shouldValidate: true,
            shouldDirty: true 
          })
        }
      >
        {data.map((d) => (
          <FormControlLabel
            value={d.code}
            control={<Radio size="small" />}
            label={d.name}
            key={d.id}
            componentsProps={{ typography: { fontSize: 14 } }}
          />
        ))}
      </RadioGroup>
      {showHelper && (
        <Typography variant="caption">
          {t("components.boards.filters.status_helper")}
        </Typography>
      )}
    </FormControl>
  );
};

export default DateTypeSelector;
