import { Stack, Typography, useTheme } from "@mui/material";
import ProfileImage from "./ProfileImage";
import { useTranslation } from "react-i18next";

const History = ({
  message,
  fullName,
  detail,
  created,
  historyTypeCode,
}: {
  message: string;
  fullName?: string;
  detail: boolean;
  created: string;
  historyTypeCode: string;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { i18n } = useTranslation();

  return (
    <Stack width={"100%"} flexDirection={"row"} alignItems={"start"} gap={1}>
      <ProfileImage fullName={fullName} />
      <Stack width={"100%"} gap={!detail ? 0.5 : 0}>
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Stack
            flexDirection={"row"}
            alignItems={!detail ? "center" : "start"}
            gap={1}
          >
            {!detail && (
              <Typography
                variant="subtitle2"
                color={(t) => (t.palette.mode === "dark" ? "white" : undefined)}
                fontWeight={"bold"}
              >
                {fullName}
              </Typography>
            )}
            <Typography
              variant="body2"
              sx={(t) => ({
                fontSize: 12,
                color:
                  t.palette.mode === "light"
                    ? t.palette.grey[700]
                    : t.palette.grey[500],
              })}
            >
              {new Date(created)
                .toLocaleString(i18n.language)
                .slice(0, -3)
                .replaceAll(",", "")}
            </Typography>
          </Stack>

          <Typography
            variant="body2"
            sx={(t) => ({
              fontSize: 12,
              color:
                t.palette.mode === "light"
                  ? t.palette.grey[800]
                  : t.palette.grey[500],
            })}
          >
            {historyTypeCode === "1" || historyTypeCode === "4"
              ? t("components.cardHistories.internal")
              : t("components.cardHistories.external")}
          </Typography>
        </Stack>

        <Stack
          sx={(t) => ({
            minHeight: detail ? 25 : 40,
            width: "100%",
            justifyContent: "center",
            backgroundColor: detail
              ? "transparent"
              : t.palette.mode === "dark"
              ? t.palette.grey[800]
              : t.palette.grey[200],
            fontSize: 12,
            borderRadius: 1,
            lineHeight: 1.4,
          })}
        >
          <div
            style={{
              color: theme.palette.mode === "dark" ? "white" : undefined,
              paddingLeft: detail ? 0 : 10,
            }}
            className="render-html"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default History;
