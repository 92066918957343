import { Stack } from "@mui/material";
import { memo } from "react";

import { IChecklistItem } from "../../../../models/ticketChecklist";
import CardChecklistAddItem from "./CardChecklistAddItem";
import CardChecklistItems from "./CardChecklistItems";

const ChecklistItemsContainer = ({
  checklistItems,
  checklistId,
  hideCheckedItems,
}: {
  checklistItems: IChecklistItem[];
  checklistId: string;
  hideCheckedItems: boolean;
}) => {
  return (
    <Stack>
      <CardChecklistItems
        checklistItemsList={checklistItems}
        checklistId={checklistId}
        hideCheckedItems={hideCheckedItems}
      />
      <CardChecklistAddItem ticketChecklistId={checklistId} />
    </Stack>
  );
};

export default memo(ChecklistItemsContainer);
