import { Stack, Tooltip, Typography } from "@mui/material";
import { memo, useEffect, useRef, useState } from "react";
import { ITicketService } from "../../../models/ticketService";

const TicketServiceBadge: React.FC<{ ticketService: ITicketService }> = ({
  ticketService,
}) => {
  const [hover, setHover] = useState(true);

  const labelRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!labelRef.current) return;
    if (labelRef.current.clientWidth < labelRef.current.scrollWidth) {
      setHover(false);
    }
  }, []);

  if (!ticketService || !ticketService.showBadgeNameColor) return null;
  return (
    <Stack
      sx={{
        backgroundColor: ticketService.badgeBackgroundNameColor, // custom background color
        color: ticketService.badgeNameColor, // custom text color
        borderRadius: "10px",
        maxHeight: "15px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        mt: 0.5,
      }}
    >
      <Tooltip
        disableHoverListener={hover}
        disableFocusListener={hover}
        title={ticketService.name}
      >
        <Typography
          ref={labelRef}
          fontSize="10px"
          noWrap
          sx={{ width: "90%", textAlign: "center" }}
        >
          {ticketService.name}
        </Typography>
      </Tooltip>
    </Stack>
  );
};

export default memo(TicketServiceBadge);
