import { RadioGroupInput, useFormContext } from "@4uhub/lib4uhub";
import { Grid } from "@mui/material";
import { memo, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TWhatsappNumberForm } from "../WhatsAppNumber/whatsappNumberSchema";
import { IPhoneNumbersProps } from "./models";

const translationPath = "page.card.chat.noMessages.whatsappNumber.";

export const PhoneNumbers = memo(({ phoneNumbers }: IPhoneNumbersProps) => {
  const { t } = useTranslation();

  const { setValue } = useFormContext<TWhatsappNumberForm>();

  const numbers = useMemo(
    () =>
      phoneNumbers.length > 0
        ? phoneNumbers.map((phoneNumber) => ({
            value: phoneNumber.type + "</>" + phoneNumber.value,
            label: phoneNumber.type + " - " + phoneNumber.value,
          }))
        : [],
    [phoneNumbers]
  );

  const options = useMemo(
    () => [...numbers, { label: "Outro", value: "1" }],
    [numbers]
  );

  useEffect(() => {
    if (options.length === 1) {
      setValue("phoneOption", "1");
    }
  }, [options, setValue]);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      mb={2}
      sx={(t) => ({
        maxHeight: 165,
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: "0.4em",
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          backgroundColor:
            t.palette.mode === "light"
              ? t.palette.grey[400]
              : t.palette.grey[900],
          borderRadius: 5,
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor:
            t.palette.mode === "light"
              ? t.palette.grey[200]
              : t.palette.grey[700],
          borderRadius: 5,
        },
      })}
    >
      <RadioGroupInput
        name="phoneOption"
        groupLabel={t(translationPath + "phone_options")}
        disableBorder
        radios={options}
      />
    </Grid>
  );
});
