import InventoryIcon from "@mui/icons-material/Inventory";
import MenuItem from "../MenuItem";
import { useSettingsMenu } from "../../../../store/context/settingsMenu-context";
import { useTranslation } from "react-i18next";
import { memo } from "react";

const ArchivedItemsButton = () => {
  const { setPage } = useSettingsMenu();
  
  const { t } = useTranslation();

  const handleClick = () => {
    setPage("archived");
  };

  return (
    <MenuItem
      onClick={handleClick}
      title={t("components.boards.settingsMenu.archivedItem")}
      icon={<InventoryIcon fontSize="small" color="primary" />}
    />
  );
};

export default memo(ArchivedItemsButton);
