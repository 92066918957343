import { memo, useCallback, useEffect, useState } from "react";
import { Loading, Modal, useFetch } from "@4uhub/lib4uhub";
import { Box, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

import { getArchivedWorkspaces } from "../../../services/workspace.service";
import { IGetWorksSpace } from "../../../models/workspaces";
import ListEmpty from "../../UI/ListEmpty";
import { useAppDispatch } from "../../../store/store";
import ArchivedWorkspace from "./ArchivedWorkspace";
import { SignalRContext } from "../../SignalR/SignalRProvider";

interface IArchivedWorkspacesProps {
  open: boolean;
  onClose: () => void;
}

const ArchivedWorkspaces: React.FC<IArchivedWorkspacesProps> = ({
  onClose,
  open,
}) => {
  const [workspaces, setWorkspaces] = useState<IGetWorksSpace[]>([]);
  const dispatch = useAppDispatch();
  const { loading, sendRequest } = useFetch(getArchivedWorkspaces);

  const { t } = useTranslation();

  const fetch = useCallback(async () => {
    const { data } = await sendRequest(undefined);
    if (data) {
      setWorkspaces(data.items);
    }
  }, [sendRequest]);

  useEffect(() => {
    if (open) {
      fetch();
    }
  }, [fetch, open]);

  const deleteFromList = (id: string) => {
    setWorkspaces((w) => w.filter((w) => w.id !== id));
  };

  SignalRContext.useSignalREffect(
    "UnArchiveTicketWorkspace", // Your Event Key
    (userId: string, data: IGetWorksSpace) => {
      console.log("UnArchiveTicketWorkspace", data);
      deleteFromList(data.id);
    },
    [dispatch]
  );

  SignalRContext.useSignalREffect(
    "DisabledTicketWorkspace", // Your Event Key
    (userId: string, data: { id: string }) => {
      console.log("DisabledTicketWorkspace", data);
      deleteFromList(data.id);
    },
    [dispatch]
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t("page.home.archived.title") + ""}
      maxDialogWidth="sm"
    >
      <Stack>
        {loading && (
          <Box>
            <Loading size={30} sx={{ position: "relative" }} />
          </Box>
        )}
        <Stack gap={1}>
          {workspaces.map((w) => (
            <ArchivedWorkspace key={w.id} data={w} />
          ))}
          {workspaces.length === 0 && !loading && (
            <ListEmpty message={t("page.home.archived.empty")} />
          )}
        </Stack>
      </Stack>
    </Modal>
  );
};

export default memo(ArchivedWorkspaces);
